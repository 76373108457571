import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const rootStyle = {
  // outline: "1px solid red",
  color: "rgba(42,43,44,0.7)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: 14,
  fontWeight: 600,
  // height: 60,
  // boxShadow: "0 1px 0 0 #E6E7E9",
  fill: "#E6E7E9"
};

const connectorStyle = {
  position: "relative",
  top: 20,
  right: "50%",
  height: 3,
  backgroundColor: "#AAADB6",
  width: "calc(100% - 38px)"
};

const labelStyle = {
  padding: "0 10px",
  textAlign: "center"
};
const linkStyle = {
  ...labelStyle,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
};

const stepStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 10,

  width: 32,
  height: 32,
  borderRadius: 19,
  border: "3px solid #AAADB6",

  color: "#AAADB6",
  fontSize: 14,
  lineHeight: "14px"
};

const styles = {
  root: {
    ...rootStyle
  },
  rootActive: {
    ...rootStyle,
    color: "#2A2B2C",
    //fontWeight: 600,
    fill: "#398DD3"
  },
  connector: {
    ...connectorStyle
  },
  connectorCompleted: {
    ...connectorStyle,
    backgroundColor: "#6592CC"
  },
  connectorHidden: {
    ...connectorStyle,
    width: 0
  },
  icon: {},
  iconActive: {
    fill: "#398DD3"
  },
  iconCompleted: {
    fill: "#94C684",
    marginRight: 8
  },
  label: {
    ...labelStyle
  },
  labelActive: {
    ...labelStyle,
    color: "#2A2B2C"
  },
  labelCompleted: {
    ...labelStyle,
    textDecoration: "line-through"
  },
  link: {
    ...linkStyle
  },
  linkCompleted: {
    ...linkStyle,
    textDecoration: "line-through"
  },
  step: {
    ...stepStyle
  },
  stepActive: {
    ...stepStyle,
    border: "3px solid #6592CC",
    color: "#6592CC"
  },
  stepCompleted: {
    ...stepStyle,
    border: "none",
    backgroundColor: "#6592CC",
    color: "white",
    width: 38,
    height: 38,

    fontSize: 20,
    lineHeight: "20px"
  }
};

const getClasses = props => {
  const { active, classes, completed, visited } = props;

  const root = active ? classes.rootActive : classes.root;

  const connector = active
    ? classes.connectorCompleted
    : completed && visited
    ? classes.connectorCompleted
    : classes.connector;

  const step = active
    ? classes.stepActive
    : completed && visited
    ? classes.stepCompleted
    : classes.step;

  const label = active
    ? classes.labelActive
    : completed && visited
    ? classes.labelCompleted
    : classes.label;

  const link = completed ? classes.linkCompleted : classes.link;

  return { ...classes, root, connector, step, label, link };
};

const isCompleted = props => {
  return props.completed && props.visited;
};

const FormStep = props => {
  const { connector, label, onClick, showLink, step, width } = props;

  const classes = getClasses(props);

  return (
    <div
      onClick={showLink ? onClick : null}
      className={classes.root}
      style={{ width }}
    >
      {connector ? (
        <div className={classes.connector} />
      ) : (
        <div
          className={classes.connectorHidden}
          style={{ backgroundColor: "none" }}
        />
      )}
      <div className={classes.step}>{isCompleted(props) ? "\u2713" : step}</div>
      <div className={showLink ? classes.link : classes.label}>{label}</div>
    </div>
  );
};

FormStep.propTypes = {
  active: PropTypes.bool,
  connector: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  completed: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  showLink: PropTypes.bool,
  step: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired
};

FormStep.defaultProps = {
  connector: true,
  step: 8
};

export { FormStep, styles };
export default withStyles(styles, { name: "FormStep" })(FormStep);
