import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import AppointmentDeadline from "../../Meetup/AppointmentDeadline";
import LocalLink from "../../common/LocalLink";
import PulseIcon from "../../common/PulseIcon";

const styles = {
  root: {
    paddingBottom: 25,
    paddingTop: 25,
    borderTop: "1px solid #E6E7E9",
    "&:first-child": {
      paddingTop: 0,
      borderTop: 0
    },
    "&:last-child": {
      paddingBottom: 0
    }
  },
  name: {
    color: "#398DD3",
    fontSize: 15,
    fontWeight: 600
  },
  deadline: {
    color: "#2A2B2C",
    fontSize: 15,
    fontWeight: 600,
    marginTop: 10
  },
  details: {
    color: "#2A2B2C",
    fontSize: 15,
    marginTop: 10
  }
};

const formatDeadline = deadline_datetime => {
  return deadline_datetime ? (
    <AppointmentDeadline
      countdown={true}
      countdownText="to apply"
      deadline_datetime={deadline_datetime}
    />
  ) : (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PulseIcon
        color="#85C97C"
        pulse={false}
        style={{ marginRight: 0, marginBottom: 1 }}
      />{" "}
      <div style={{ color: "#2A2B2C", fontSize: 14, fontWeight: 600 }}>
        Open
      </div>
    </div>
  );
};

const formatDuration = document => {
  const { is_ongoing, start_datetime } = document;

  if (is_ongoing) return "Open year-round";

  if (start_datetime) new Date(start_datetime).toDateString();

  return null;
};

const formatLocation = (location, countries) => {
  if (!location) return null;

  const country = countries.find(
    country => country.id === location.country
  ) || { code: "" };

  return [location.city, country.code].filter(s => s).join(", ");
};

const ProgramCard = props => {
  const { classes, countries, document } = props;
  const { doctype, id } = document;
  const application = document.application || {};

  const deadline = formatDeadline(application.deadline_datetime);
  const location = formatLocation(document.location, countries);
  const duration = formatDuration(document);

  return (
    <div className={classes.root}>
      <div className={classes.name}>
        <LocalLink label={document.name} link={`/${doctype}/${id}`} />
      </div>
      <div className={classes.deadline}>{deadline}</div>
      {duration && <div className={classes.details}>{duration}</div>}
      <div className={classes.details}>{location}</div>
    </div>
  );
};

ProgramCard.propTypes = {
  classes: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  document: PropTypes.shape({
    application: PropTypes.shape({
      deadline_datetime: PropTypes.string
    }),
    doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
    id: PropTypes.number.isRequired,
    location: PropTypes.shape({
      city: PropTypes.string.isRequired,
      country: PropTypes.number.isRequired
    }),
    name: PropTypes.string.isRequired,
    start_datetime: PropTypes.string
  })
};

const mapStateToProps = state => ({ countries: state.countries });
export default connect(mapStateToProps)(withStyles(styles)(ProgramCard));
