import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import LocalLink from "../common/LocalLink";
import EmptyState from "./EmptyState";
import ErrorPanel from "../common/Errors/ErrorPanel";
import LoadingPanel from "../common/LoadingPanel";

import ConnectionsTable from "./ConnectionsTable";

const styles = {
  root: {
    marginTop: 40,
    width: "100%"
    //overflowX: "auto"
  },
  title: {
    backgroundColor: "white",
    borderBottom: "1px solid #E6E7E9",
    color: "#2A2B2C",
    fontSize: 15,
    fontWeight: 700,
    height: 60,
    lineHeight: "60px",
    paddingLeft: 30
  },
  footerLink: {
    paddingLeft: 15,
    color: "#398DD3",
    fontSize: 15,
    fontWeight: 700
  }
};

const FooterLink = props => {
  const { classes, connections } = props;

  return (
    <div className={classes.footerLink}>
      <LocalLink
        label={`View all ${connections.length} Connections`}
        link="/connections"
      />
    </div>
  );
};

const getFooterLinkCurried = props => () => <FooterLink {...props} />;

const renderTable = props => {
  const { company, limit } = props;

  const connections = limit
    ? props.connections.slice(0, limit)
    : props.connections;

  return (
    <ConnectionsTable
      company={company}
      connections={connections}
      FooterComponent={limit ? getFooterLinkCurried(props) : null}
    />
  );
};

const ConnectionsList = props => {
  const { classes, connections, error, hideTitle, style } = props;

  if (error) return <ErrorPanel error={error} />;

  if (connections.loading && !connections.length)
    return <LoadingPanel loading={connections.loading} />;

  const body = connections.length === 0 ? <EmptyState /> : renderTable(props);

  return (
    <div className={classes.root} style={style}>
      {!hideTitle && (
        <div className={classes.title}>
          {`Recent Connections (${connections.length})`}
        </div>
      )}

      {body}
    </div>
  );
};

ConnectionsList.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  connections: PropTypes.array.isRequired,
  error: PropTypes.shape({
    messages: PropTypes.string.isRequired
  }),
  hideTitle: PropTypes.bool,
  limit: PropTypes.number,
  style: PropTypes.object.isRequired
};

ConnectionsList.defaultProps = {
  limit: 5,
  style: {}
};

export { ConnectionsList, styles };

export default withStyles(styles)(ConnectionsList);
