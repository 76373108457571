import React from "react";
import { PropTypes } from "prop-types";

import { throttle } from "underscore";

import { withStyles } from "@material-ui/core/styles";

import Controls from "./Controls";
import { styles as baseStyles } from "./BaseFrame";

const styles = {
  ...baseStyles,
  content: {
    height: 40,
    lineHeight: "40px",
    borderRadius: 3,
    border: "none",
    backgroundColor: "#EDEDEF",
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    outline: "none",
    paddingLeft: 15,
    paddingRight: 15,
    "&:focus": {
      backgroundColor: "#E8EFF8"
    }
  }
};

class Heading extends React.Component {
  static propTypes = {
    content: PropTypes.string,
    deleteFrame: PropTypes.func,
    moveDown: PropTypes.func,
    moveUp: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  };

  static defaultProps = {
    content: "",
    placeholder: "Please provide a header"
  };

  constructor(props) {
    super(props);

    this.state = {
      content: props.content
    };

    this.onChange = throttle(props.onChange, 400).bind(this);
  }

  _onChange = event => {
    const content = event.target.value;

    this.setState({ content }, () => this.onChange({ content }));
  };

  render() {
    const {
      classes,
      deleteFrame,
      moveDown,
      moveUp,
      placeholder,
      type
    } = this.props;
    const { content } = this.state;

    const controlProps = {
      deleteFrame,
      moveDown,
      moveUp,
      type
    };

    return (
      <div className={classes.root}>
        <Controls {...controlProps} />

        <div className={classes.type}>{type}</div>
        <input
          className={classes.content}
          onChange={this._onChange}
          value={content}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Heading);
