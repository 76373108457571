import React from "react";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    //backgroundColor: theme.palette.blue.light
    //padding: 8,
    marginTop: 48,
    "&:nth-child(1)": {
      marginTop: 0
    }
  },
  label: {
    fontWeight: 600,
    marginBottom: 10
  }
});

const FormFieldSeparator = props => {
  const { borderTop, children, classes, helperText } = props;

  const style = borderTop
    ? {
        marginLeft: -30,
        marginRight: -30,
        marginTop: 20,
        padding: "0 30px",
        paddingTop: 20,
        borderTop: "1px solid #E6E7E9",
        ...props.style
      }
    : { ...props.style };

  return (
    <div className={classes.root} style={style}>
      <div className={classes.label}>{children}</div>
      {helperText && <div className="body1">{helperText}</div>}
    </div>
  );
};

FormFieldSeparator.defaultProps = {
  style: {}
};

export default withStyles(styles)(FormFieldSeparator);
