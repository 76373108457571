import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  videoContainer: {
    position: "relative",
    width: "100%",
    paddingBottom: "56%",
    float: "left",
    height: 0,
    maxHeight: 480
  },
  video: {
    width: "100%",
    height: "100%",
    maxWidth: 854,
    position: "absolute",
    left: 0,
    backgroundColor: theme.palette.primary[50]
  }
});

const VideoContainer = props => {
  const { classes, controls, poster, src, ...other } = props;

  return src === "" ? (
    <div className={classes.videoContainer}>
      <div className={classes.video}>No video</div>
    </div>
  ) : (
    <div>
      <video controls={controls} src={src} poster={poster} {...other}>
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  );
};

VideoContainer.propTypes = {
  controls: PropTypes.bool,
  poster: PropTypes.string,
  src: PropTypes.string
};

VideoContainer.defaultProps = {
  width: "100%",
  controls: true,
  src: ""
};

export default withStyles(styles)(VideoContainer);
