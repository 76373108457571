import {
  validateTags,
  validateURL,
  validateURLOptional
} from "../../Form/validate";
import * as options from "../config";

const pages = [
  {
    name: "About your Company",
    hash: "#about",
    formFields: [
      {
        id: "about_the_program_sep",
        label: "About your Company",
        helperText:
          "It’s optional, but completing the fields below will help Founder teams to learn more about your company.",
        type: "header"
      },
      {
        label: "Company Name",
        id: "name",
        limit: 80,
        required: true,
        type: "text",
        value: "",
        width: 440
      },
      {
        label: "Company Description",
        id: "description",
        limit: 1000,
        limitErrorText:
          "1000 character max! Try to keep your description short and snappy.",
        placeholder:
          "Tell others about your company's experience and expertise",
        required: true,
        rows: 4,
        type: "text",
        value: "",
        width: 440
      },
      {
        id: "website",
        label: "Company Website URL",
        limit: 120,
        type: "text",
        width: 440,
        validate: validateURL,
        placeholder: "https://",
        helperText: "Must begin with 'http://' or 'https://'"
      },

      {
        id: "company_type",
        label: "Company type",
        type: "idselect",
        options: options.company_type.options,
        labels: options.company_type.labels,
        width: 440
      },
      {
        id: "size",
        label: "Company Size",
        type: "idselect",
        options: options.company_size.options,
        labels: options.company_size.labels,
        width: 440
      },
      {
        id: "active_in",
        label: "Territories Active In",
        type: "countries",
        helperText:
          "Search for and select the markets and territories where you operate.",
        value: [],
        width: 660
      },
      {
        id: "markets",
        label: "Markets, interests and areas of expertise",
        type: "tags",
        helperText:
          "Search for and select tags that apply. Tags help you connect with relevant founder teams.",
        required: true,
        validate: validateTags(1, "markets"),
        value: [],
        width: 660
      },

      // location
      {
        id: "location_sep",
        label: "Company Address",
        type: "header"
      },
      {
        id: "location",
        label: "",
        type: "location",
        showDisableLocation: false,
        width: 440
      },

      // social links
      {
        id: "social_sep",
        label: "Social Media",
        type: "header"
      },
      {
        label: "LinkedIn Profile URL",
        id: "linkedin",
        limit: 140,
        type: "text",
        width: 440,
        value: "",
        helperText:
          "Sharing your LinkedIn profile allows others to connect, and verify who you are. Must begin with 'https://'",
        validate: validateURLOptional,
        placeholder: "https://linkedin.com/"
      },
      {
        label: "Twitter Profile URL",
        id: "twitter",
        type: "text",
        limit: 140,
        width: 440,
        value: "",
        helperText:
          "Sharing your Twitter profile allows others to connect, and verify who you are. Must begin with 'https://'",
        validate: validateURLOptional,
        placeholder: "https://twitter.com/@"
      }
    ],
    nextButtonLabel: "Next: Branding"
  },
  {
    name: "Branding",
    hash: "#branding",
    formFields: [
      {
        id: "branding_header",
        label: "Branding",
        helperText:
          "Add a brief description, logo and optional banner image to help your company stand out.",
        type: "header"
      },
      {
        id: "mission_statement",
        label: "Company Mission Statement",
        helperText:
          "This one-line description is displayed at the beginning of your company’s profile page. Try a short, snappy description to help others quickly learn about your company. Max 140 characters",
        limit: 140,
        limitErrorText:
          "Your mission statement should be less than 140 characters!",
        required: true,
        type: "text",
        value: "",
        width: 660
      },
      {
        id: "logo_image_url",
        label: "Upload your company logo",
        helperText:
          "Company logos should be square and a minimum 400 x 400 pixels. Max 2mb",
        required: true,
        type: "image",
        width: 200,
        height: 200
      }
    ],
    nextButtonLabel: "Next: Ethos"
  },

  {
    name: "What you're looking for",
    hash: "#ethos",
    formFields: [
      {
        id: "header_ethos",
        label: "What you're looking for",
        helperText:
          "What are you looking for and what kind of companies do you typically engage with?",
        type: "header"
      },
      {
        id: "ethos",
        label: "",
        type: "richtext",
        width: 660
      }
    ],
    nextButtonLabel: "Next: Team"
  },

  {
    name: "Team",
    hash: "#team",
    formFields: [
      {
        id: "header_application",
        label: "Team",
        helperText: "Invite and manage your core team at your company",
        type: "header"
      },
      {
        id: "managers",
        label: "_team editor",
        section: true,
        type: "team",
        // do not send back updates to company object
        updateParent: false
      }
    ]
  }
];

export default pages;
