import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import {
  loadCompanyDetails,
  updateCompany,
  COMPANY_TYPE_CORP,
  COMPANY_TYPE_FOUNDER
} from "../../Reducers/Companies";

import CompanyProfilePage from "./CompanyProfilePage";
import * as api from "./api";

class CompanyProfilePageContainer extends React.Component {
  static propTypes = {
    companies: PropTypes.array.isRequired,
    loadCompanyDetails: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({ companyId: PropTypes.string.isRequired })
        .isRequired
    }).isRequired,
    profile: PropTypes.object.isRequired,
    updateCompany: PropTypes.func.isRequired
  };

  state = {
    programs: [],
    challenges: []
  };

  componentDidMount() {
    const { companies, location, match } = this.props;
    const companyId = parseInt(match.params.companyId, 10);

    const company = companies.find(c => c.id === companyId);

    if (!company) {
      const is_founder = location.pathname.startsWith("/startup");
      const _company = { id: companyId, is_founder, is_corporate: !is_founder };

      this.loadCompanyDetails(_company);
    } else if (!company._details) {
      this.loadCompanyDetails(company);
    }
  }

  loadCompanyDetails = company => {
    this.props.loadCompanyDetails(company);

    if (!company.is_corporate) return;

    api.loadPrograms(company.id).then(json => {
      const { error, results } = json;
      if (!error) {
        this.props.updateCompany({ id: company.id, programs: results });
      }
    });

    api.loadThinkTanks(company.id).then(json => {
      const { error, results } = json;
      if (!error) {
        this.props.updateCompany({ id: company.id, challenges: results });
      }
    });
  };

  render() {
    const { companies, location, match, profile } = this.props;
    const companyId = parseInt(match.params.companyId, 10);

    const company = companies.find(c => c.id === companyId);
    const companyType = location.pathname.startsWith("/startup")
      ? COMPANY_TYPE_FOUNDER
      : COMPANY_TYPE_CORP;

    if (!company) return <div>TODO: load profile</div>;

    return (
      <CompanyProfilePage
        {...this.state}
        company={company}
        companyType={companyType}
        profile={profile}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    companies: state.companies,
    profile: state.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCompanyDetails: company => dispatch(loadCompanyDetails(company)),
    updateCompany: company => dispatch(updateCompany(company))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyProfilePageContainer);
