import React from "react";
import { PropTypes } from "prop-types";

import Icons from "../../Icons";

const renderBenefitInput = (benefit, classes, onChange, removeOther) => {
  return (
    <div key={benefit.label} style={{ display: "flex", alignItems: "center" }}>
      <input
        className={classes.otherInput}
        value={benefit.value}
        onChange={event =>
          onChange({
            label: benefit.label,
            value: event.target.value
          })
        }
      />
      <Icons.TrashCanOutline
        className={classes.removeOtherIcon}
        onClick={() => removeOther(benefit.label)}
      />
    </div>
  );
};

const OtherBenefits = props => {
  const { addOther, classes, onChange, removeOther } = props;

  const benefits = props.benefits.filter(benefit =>
    benefit.label.startsWith("Other")
  );
  const checked = Boolean(benefits.filter(b => b.value).length);
  const style = checked ? { backgroundColor: "#E8EFF8" } : {};

  return (
    <div key="Other" className={classes.amountRoot} style={style}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {checked && false ? (
          <Icons.CheckboxMarkedOutline
            style={{
              fontSize: 24,
              margin: "12px 17px",
              fill: "rgba(0,0,0,0.54)"
            }}
          />
        ) : (
          <div
            style={{
              width: 48,
              height: 48,
              marginLeft: 5,
              marginRight: 5
            }}
          />
        )}
        Other Benefits (Define your own)
      </div>

      {benefits.map(benefit =>
        renderBenefitInput(benefit, classes, onChange, removeOther)
      )}

      <div
        className={classes.addOther}
        style={{ display: "flex", alignItems: "center" }}
        onClick={addOther}
      >
        <Icons.Plus className={classes.addOtherIcon} />
        Add Benefit
      </div>
    </div>
  );
};

OtherBenefits.propTypes = {
  benefits: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  addOther: PropTypes.func.isRequired,
  removeOther: PropTypes.func.isRequired
};

export default OtherBenefits;
