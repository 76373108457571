import {
  ADD_REFERRAL,
  REMOVE_REFERRAL,
  SET_REFERRALS,
  UPDATE_REFERRAL
} from "./referralActions";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_REFERRAL:
      return state.concat([action.payload]);

    case REMOVE_REFERRAL:
      return state.filter(ref => ref.id !== action.payload.id);

    case SET_REFERRALS:
      return [...action.payload];

    case UPDATE_REFERRAL:
      return state.map(
        ref =>
          ref.id === action.payload.id ? { ...ref, ...action.payload } : ref
      );

    default:
      return state;
  }
};
