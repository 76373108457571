import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { company_size, company_type } from "../../Company/config";

import IdSelector from "../IdSelector";
import { FormTagsFieldContainer } from "../Tags";
import ImageUploadField from "../ImageUploadField";
import LocationField from "../LocationField";
import TextInputField from "../TextInputField";

import { validateCompanyAttribute } from "../validate";

const styles = {
  root: {
    width: "100%",
    //overflowX: "auto",
    marginTop: 30,
    marginBottom: 80
  },
  label: {
    fontSize: 15,
    marginTop: 20,
    marginBottom: 5
  },
  input: {
    boxSizing: "border-box",
    padding: "15px 10px",
    backgroundColor: "#F2F3F4",
    width: 220,
    marginBottom: 20
  }
};

class CompanyDetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // update from form state/draft
      ...props.company
    };

    this.validateCompanyAttribute = validateCompanyAttribute(
      props.requiredFields
    );
  }

  isValid = () => {
    const { requiredFields } = this.props;
    const { markets } = this.state;

    if (!markets || !markets.length) return false;

    const valid = requiredFields
      .map(key => this.state[key])
      .every(v => Boolean(v));
    return valid;
  };

  onBlur = id => () => {
    const value = this.state[id];

    this.validate(id, value);
  };

  onChange = id => value => {
    this.setState({ [id]: value });
  };

  onChangeAndValidate = id => value => {
    this.setState({ [id]: value }, this.onBlur(id));
  };

  onChangeLocation = id => value => {
    this.validate(id, value);
    const location = { ...this.state.location, [id]: value };

    this.setState({ location }, this.update);
  };

  removeValidationError = id => {
    const { validationErrors } = this.props;
    const companyErrors = { ...validationErrors };
    delete companyErrors[id];

    if (Object.keys(companyErrors).length) {
      this.props.setValidationError(this.props.id, companyErrors);
    } else {
      this.props.clearValidationError(this.props.id);
    }

    this.props.onChange(this.state);
    if (this.isValid()) {
      this.props.onBlur();
    }
  };

  setValidationError = (id, value) => {
    const validationErrors = { ...this.props.validationErrors, [id]: value };

    this.props.setValidationError(this.props.id, validationErrors);
  };

  sharedFieldProps = (id, options = {}) => {
    const { validationErrors, width } = this.props;

    return {
      error: Boolean(validationErrors[id]),
      helperText: validationErrors[id] || options.helperText,
      onBlur: this.onBlur(id),
      onChange: this.onChange(id),
      required: this.props.requiredFields.indexOf(id) !== -1,
      value: this.state[id],
      width: width
    };
  };

  update = () => {
    this.props.onChange(this.state);
  };

  validate = (id, value) => {
    const error = this.validateCompanyAttribute(id, value);

    if (error) {
      this.setValidationError(id, error);
    } else {
      this.removeValidationError(id);
    }
  };

  renderSelector = (id, label, selection, other) => {
    return (
      <IdSelector
        id={id}
        label={label}
        {...this.sharedFieldProps(id)}
        options={selection.options}
        labels={selection.labels}
        {...other}
      />
    );
  };

  renderTextField = (id, label, width = 440, options = {}) => {
    return (
      <TextInputField
        {...options}
        id={id}
        label={label}
        {...this.sharedFieldProps(id, options)}
        value={this.state[id] || ""}
        width={width}
      />
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderTextField("name", "Company Name", 440, {
          style: { marginTop: 0 },
          required: true,
          limit: 79,
          limitErrorText: "Your company name must be less than 80 characters"
        })}

        <LocationField
          id="location"
          location={this.state.location}
          onBlur={this.onBlur("location")}
          onChange={this.onChange("location")}
          showDisableLocation={false}
          clearValidationError={this.removeValidationError}
          setValidationError={this.setValidationError}
          validationErrors={this.props.validationErrors["location"] || {}}
        />

        {this.renderTextField("description", "Brief Company Description", 660, {
          limit: 1000,
          limitErrorText:
            "1000 character max! Try to keep your description short and snappy.",
          required: true,
          rows: 4,
          showLimit: true
        })}

        {this.renderTextField("website", "Website", 440, {
          helperText: "The URL must begin with 'http://' or 'https://'",
          placeholder: "https://",
          required: true
        })}

        {this.renderTextField("year_founded", "Year Founded", 220, {
          placeholder: "YYYY",
          type: "number"
        })}

        {this.renderSelector("company_type", "Company type", company_type, {
          required: true,
          width: 220,
          onChange: this.onChangeAndValidate("company_type")
        })}

        {this.renderSelector("company_size", "Company Size", company_size, {
          required: true,
          width: 220,
          onChange: this.onChangeAndValidate("company_size")
        })}

        <ImageUploadField
          id="logo_image_url"
          label="Upload your company logo"
          {...this.sharedFieldProps("logo_image_url", {
            helperText:
              "Company logos should be square and a minimum 400 x 400 pixels. Thats the same as your company Twitter profile image. Max file size 2mb 😉"
          })}
          height={100}
          onChange={this.onChangeAndValidate("logo_image_url")}
          width={100}
        />

        <FormTagsFieldContainer
          id="markets"
          label="Markets, Interests and areas of expertise"
          {...this.sharedFieldProps("markets", {
            helperText:
              "Search for and select all tags that apply. Tags help you connect with relevants founder teams."
          })}
          onChange={this.onChangeAndValidate("markets")}
          value={this.state.markets || []}
          width={660}
        />
      </div>
    );
  }
}

CompanyDetailsForm.propTypes = {
  company: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  requiredFields: PropTypes.array.isRequired,

  clearValidationError: PropTypes.func.isRequired,
  setValidationError: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired
};

CompanyDetailsForm.defaultProps = {
  company: {},
  requiredFields: [
    "location",
    "name",
    "website",
    "markets",
    "logo_image_url",
    "company_type",
    "company_size"
  ]
};

export { CompanyDetailsForm, styles };
export default withStyles(styles)(CompanyDetailsForm);
