import React from "react";
import { PropTypes } from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import ExpansionSection from "./ExpansionSection";
import { FormTagsFieldContainer } from "./Tags";

const defaultOptions = doctype => [
  //{ option: "is_public", label: `Make this ${doctype} public` },
  { option: "is_anonymous", label: `Post ${doctype} question anonymously` },
  {
    option: "is_corporate_only",
    label: "Limit applications to Corporates only"
  }
];

// TODO split out into component
const VisibilitySettings = props => {
  const { doctype, companyNames, onChange, state } = props;

  const options = props.options.length || defaultOptions(doctype);

  const controls = options.map(spec => {
    const { option, label } = spec;
    return (
      <FormControlLabel
        style={{
          minWidth: "60%"
        }}
        key={option}
        control={
          <Switch
            checked={state[option]}
            onChange={event => {
              onChange({ [option]: event.target.checked });
            }}
            value={option}
          />
        }
        label={label}
      />
    );
  });

  const blacklist = state.blacklist || [];

  // TODO: get company names from server

  // eslint-disable-next-line no-unused-vars
  const blacklistControl = (
    <FormTagsFieldContainer
      id="blacklist"
      category="blacklist"
      label={`Hide this ${doctype} from the following companies:`}
      placeholder="Select companies ..."
      value={blacklist.join(",")}
      onChange={value => {
        console.log(value, typeof value);
        if (Array.isArray(value)) {
          onChange({ blacklist: value });
        } else {
          onChange({ blacklist: value.split(",") });
        }
      }}
      options={companyNames}
      width="100%"
    />
  );

  const helptext = options
    .map(spec => {
      const { option } = spec;

      return state[option] ? option.replace("_", " ") : null;
    })
    .filter(text => text)
    .join(", ");

  return (
    <ExpansionSection
      title="Visibility"
      subtitle={helptext}
      panelProps={{ defaultExpanded: true }}
      showOverflow={true}
    >
      <div style={{ flexGrow: 1 }}>
        {controls}
        {/* blacklistControl */}
      </div>
    </ExpansionSection>
  );
};

VisibilitySettings.propTypes = {
  doctype: PropTypes.string.isRequired,
  companyNames: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  state: PropTypes.object.isRequired
};

VisibilitySettings.defaultProps = {
  options: [],
  // TODO: create container to load from server
  companyNames: []
};

export default VisibilitySettings;
