import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";
import { formatSearchString } from "../../api/search";

const NEW_PROFILE = [
  { type: "heading", content: "" },
  { type: "richtext", content: "" }
];

const stripNullValues = json => {
  return Object.keys(json).reduce((data, key) => {
    if (json[key] !== null) {
      data[key] = json[key];
    }

    return data;
  }, {});
};

const fixMinimumData = json => {
  return {
    doctype: "challenge",
    link: `/challenge/${json.id}`,
    ...challengeApiFixes(json)
  };
};

export const fixMiniumDataArray = response => {
  if (!response.results) return response;

  return {
    ...response,
    results: response.results.map(fixMinimumData)
  };
};

const challengeApiFixes = json => {
  const { application, criteria, profile, ...challenge } = stripNullValues(
    json
  );

  return {
    // defaults
    ...challengeTemplate,

    // values from api challenge
    ...challenge,

    // fixes for temp api changes
    application: {
      ...challengeTemplate.application,
      ...application
    },
    criteria: Array.isArray(criteria) ? criteria : [],
    profile: profile && profile.length ? profile : NEW_PROFILE
  };
};

const challengeTemplate = {
  name: "undefined",
  doctype: "challenge",
  meeting_type: "phone",
  application: {
    open_to_founders: false,
    open_to_corporates: true,
    open_to_guests: false,
    nda_required: false,
    deadline_datetime: ""
  },

  criteria: [],
  description: "",
  is_archived: false,

  is_ongoing: false,
  is_published: false,

  profile: [
    { type: "heading", content: "" },
    { type: "richtext", content: "" }
  ],
  questions: [
    {
      is_optional: true,
      options: null,
      type: "text_input",
      label: "Add a note"
    }
  ],
  topics: [],
  start_datetime: ""
};

const _callThinkTanksAPI = (data, method) => {
  const url = data.id ? `/api/think_tanks/${data.id}/` : "/api/think_tanks/";

  return fetchWithToken(url, {
    body: JSON.stringify(data),
    cache: "no-cache",
    credentials: "same-origin",
    headers: {},
    method
  }).then(responseJsonOrError);
};

export const createNewChallenge = () => {
  return fetchWithToken(`/api/think_tanks/`, {
    method: "POST",
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(fixMinimumData)
    .then(data => {
      // fix missing settings for new challenges
      return {
        ...data,
        application: {
          ...data.application,
          open_to_corporates: true
        },
        start_datetime: new Date().toISOString(),
        // reset name to empty string
        name: ""
      };
    });
};

export const deleteChallenge = id => {
  return _callThinkTanksAPI({ id }, "DELETE");
};

export const duplicateChallenge = id => {
  return Promise.all([loadChallenge(id), createNewChallenge()])
    .then(results => {
      const [old, _new] = results;

      return {
        ..._new,
        ...old,
        id: _new.id,
        link: `/challenge/${_new.id}`,
        name: `${old.name} (copy)`,
        is_approved: 1
      };
    })
    .then(challenge => {
      // apparently need to strip logo_image_url because of BE validation ...
      const { company, doctype, logo_image_url, ...data } = challenge;

      return updateChallenge(data).then(result => {
        if (result.error) return result;

        return challenge;
      });
    })
    .catch(error => ({ error }));
};

export const loadChallenge = id => {
  return fetchWithToken(`/api/think_tanks/${id}/`, {})
    .then(responseJsonOrError)
    .then(fixMinimumData);
};

export const loadChallenges = (options = []) => {
  const searchString = formatSearchString(options);
  const url = searchString
    ? `/api/think_tanks/?${searchString}`
    : "/api/think_tanks/";

  return fetchWithToken(url, {
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(fixMiniumDataArray);
};

export const loadChallengesFromUrl = url => {
  return fetchWithToken(url, {
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(fixMiniumDataArray);
};

export const loadMyChallenges = id => (options = []) => {
  return loadChallenges([...options, { company: id }]).then(fixMiniumDataArray);
};

export const loadThinkTanks = (options = []) => {
  return loadChallenges(options);
};

export const submitApplication = (challengeId, data) => {
  const url = `/api/think_tanks/${challengeId}/register/`;

  return fetchWithToken(url, {
    method: "POST",
    body: JSON.stringify(data),
    cache: "no-cache",
    credentials: "same-origin"
  }).then(responseJsonOrError);
};

export const updateChallenge = data => {
  const { doctype, link, ...apiData } = data;

  return _callThinkTanksAPI(apiData, "PUT");
};
