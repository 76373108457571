import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import formStyles from "../../styles/formStyles";

import { CORPORATES, FOUNDERS } from "./constants";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 25,
    minHeight: 40
  },
  link: {
    fontSize: 16,
    fontWeight: 700,
    color: "rgb(34, 34, 34)",
    marginLeft: 25,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  search: {
    ...formStyles.input,
    fontWeight: 400,
    lineHeight: "20px",
    width: 220
  }
};

const FAQMenu = props => {
  const { audience, classes, filter, setAudience, setFilter } = props;

  return (
    <div className={classes.root}>
      <input
        className={classes.search}
        onChange={evt => setFilter(evt.target.value)}
        placeholder="Search"
        value={filter}
      />
      <div
        className={classes.link}
        onClick={() => setAudience("")}
        style={audience === "" ? {} : { opacity: 0.6 }}
      >
        All
      </div>
      <div
        className={classes.link}
        onClick={() => setAudience(CORPORATES)}
        style={audience === CORPORATES ? {} : { opacity: 0.6 }}
      >
        For Corporates
      </div>
      <div
        className={classes.link}
        onClick={() => setAudience(FOUNDERS)}
        style={audience === FOUNDERS ? {} : { opacity: 0.6 }}
      >
        For Founders
      </div>
    </div>
  );
};

FAQMenu.propTypes = {
  audience: PropTypes.oneOf(["", CORPORATES, FOUNDERS]),
  classes: PropTypes.object.isRequired,
  filter: PropTypes.string,
  setAudience: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired
};

FAQMenu.defaultProps = {
  audience: "",
  filter: ""
};

export default withStyles(styles)(FAQMenu);
