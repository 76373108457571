import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icons from "../Icons";
import Switch from "@material-ui/core/Switch";

import { withStyles } from "@material-ui/core/styles";

import FormInputField from "./FormInputField";

const styles = theme => ({
  root: {
    marginTop: 24,
    paddingTop: 12,
    borderTop: "1px solid lightgrey",
    "&:nth-of-type(1)": {
      marginTop: 0,
      paddingTop: 0,
      borderTop: "none"
    }
  },
  buttonRoot: {
    height: 36,
    flexGrow: 0
  },
  buttonLabel: {
    //color: theme.palette.text.secondary
    color: "darkred"
  },
  buttonIcon: {
    marginRight: 4
  },
  controls: {
    display: "flex",
    justifyContent: "space-between"
  }
});

const OptionalSection = props => {
  const { classes, index, section, onChange, onRemove } = props;
  const { title, content, participants_only } = section;

  const titleInput = (
    <FormInputField
      id={`custom_${index}`}
      label="Section Title"
      value={title}
      onChange={value => onChange(value, content, participants_only)}
      width="100%"
      helperText={`For example: "Who Should apply?" or "What will the Think Tank involve?"`}
      style={{ flexGrow: 1 }}
    />
  );
  const contentInput = (
    <FormInputField
      id={`custom_${index}_content`}
      label="Section content"
      value={content}
      onChange={value => onChange(title, value, participants_only)}
      rows={8}
      width="100%"
    />
  );
  const visibility = (
    <FormControlLabel
      classes={{ root: classes.buttonRoot }}
      control={
        <Switch
          checked={participants_only}
          onChange={event => {
            onChange(title, content, event.target.checked);
          }}
          value="participants_only"
        />
      }
      label="show this section to confirmed participants only"
    />
  );
  const removeSectionButton = (
    <Button
      size="small"
      onClick={onRemove}
      classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
    >
      <Icons.CloseCircle className={classes.buttonIcon} />
      Remove Section
    </Button>
  );

  return (
    <div className={classes.root}>
      <div className={classes.controls}>
        {false && visibility}
        <div style={{ flexGrow: 1 }} />
        {removeSectionButton}
      </div>
      {titleInput}
      {contentInput}
    </div>
  );
};

OptionalSection.propTypes = {
  index: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default withStyles(styles)(OptionalSection);
