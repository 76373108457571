import React from "react";

import CreatableSelect from "react-select/lib/Creatable";

import FormSelectField from "./FormSelectField";

const FormSelectFieldCreatable = props => {
  return <FormSelectField {...props} SelectComponent={CreatableSelect} />;
};

export default FormSelectFieldCreatable;
