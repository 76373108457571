import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const MONTH_NAMES_SHORT = "Jan Feb March April May June July Aug Sep Oct Nov Dec".split(
  " "
);

const styles = theme => ({
  root: {
    marginBottom: "12px",
    "&:last-child": {
      marginBottom: 0
    }
  },
  card: {
    display: "flex",
    backgroundColor: "#F5EDDC"
  },
  detail: {
    fontSize: 13,
    lineHeight: "16px",
    marginTop: 8
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto",
    "&:last-child": {
      paddingBottom: "8px"
    },
    color: "#2A2B2C",
    fontSize: 13,
    fontWeigth: 300
  },
  date: {
    color: "white",
    width: 90,
    minHeight: 100,
    backgroundColor: "#CEA751"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  },
  day: {
    width: 90,
    fontWeight: 600,
    fontSize: "42px",
    lineHeight: "42px",
    textAlign: "center",
    paddingTop: "22px"
  },
  month: {
    color: "white",
    width: 90,
    textAlign: "center",
    fontWeight: 600,
    fontSize: 14
  },
  name: {
    fontSize: 14,
    lineHeight: "17px",
    paddingTop: 0,
    marginTop: 0,
    fontWeight: 600,
    color: "#2A2B2C"
  }
});

const formatTime = date => {
  return moment(date).format("LT");
};

const Event = props => {
  const { classes, start_datetime, location, name } = props;
  const eventDate = new Date(start_datetime);

  return (
    <div className={classes.root}>
      <Card className={classes.card} elevation={0}>
        <div className={classes.date}>
          <div className={classes.day}>{eventDate.getDate()}</div>
          <div className={classes.month}>
            {MONTH_NAMES_SHORT[eventDate.getMonth()]}
          </div>
        </div>

        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography
              className={classes.name}
              variant="subheading"
              noWrap
              gutterBottom
            >
              {name}
            </Typography>
            <Typography className={classes.detail} variant="body1" noWrap>
              {formatTime(eventDate)}
            </Typography>
            <Typography
              className={classes.detail}
              variant="body1"
              color={location ? "inherit" : "textSecondary"}
              noWrap
            >
              {location ? location : "(no location specified)"}
            </Typography>
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

Event.propTypes = {
  classes: PropTypes.object,
  start_datetime: PropTypes.string.isRequired,
  location: PropTypes.string,
  name: PropTypes.string.isRequired
};

Event.defaultProps = {
  location: ""
};

export default withStyles(styles)(Event);
