import { connect } from "react-redux";

import ApplicationsManageViewContainer from "../ApplicationsManagement/ApplicationsManageViewContainer";

import { loadProgram } from "./api.js";
import { loadProgramApplications } from "../../Reducers/Applications";

const mapStateToProps = state => ({
  doctype: "program",

  loadDocument: loadProgram,
  applications: state.applications.applications
});

const mapDispatchToProps = dispatch => {
  return {
    loadApplications: id => dispatch(loadProgramApplications(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsManageViewContainer);
