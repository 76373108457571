import React from "react";
import { PropTypes } from "prop-types";

import FormHelperText from "@material-ui/core/FormHelperText";

const renderHelperText = value => {
  let msg = "Optional - Leave blank if not applicable";
  let error = false;

  if (parseFloat(value) > 20) {
    msg = "Values above 20% are unusual";
    error = true;
  }

  return <FormHelperText error={error}>{msg}</FormHelperText>;
};

const EquityInput = props => {
  const { classes, onChange } = props;

  const equity = props.benefit;
  const value = (equity && equity.value) || "";

  return (
    <div key="Equity" className={classes.equityRoot}>
      <div>
        If you take equity in participant companies, what percentage is
        expected?
      </div>

      <input
        className={classes.equityInput}
        type="number"
        style={{
          width: 66,
          paddingRight: 30
        }}
        value={value}
        onChange={event => {
          onChange({
            label: "Equity",
            unit: "%",
            value: event.target.value
          });
        }}
      />
      <div className={classes.equityDecorator}>%</div>
      {renderHelperText(value)}
    </div>
  );
};

EquityInput.propTypes = {
  benefit: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default EquityInput;
