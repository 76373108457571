const RESET_REDIRECT = "RESET_REDIRECT";
const SET_REDIRECT = "SET_REDIRECT";

export const resetRedirect = () => {
  return {
    type: SET_REDIRECT,
    payload: ""
  };
};

export const setRedirect = path => {
  return {
    type: SET_REDIRECT,
    payload: path
  };
};

export default (state = "", action) => {
  switch (action.type) {
    case RESET_REDIRECT:
      return "";

    case SET_REDIRECT:
      return action.payload;

    default:
      return state;
  }
};
