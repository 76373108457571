import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 600
  },
  amount: {
    fontSize: 22
  },
  list: {
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 20
  },
  listItem: {},
  panel: {
    borderBottom: "1px solid #E6E7E9",
    minHeight: 48,
    padding: "15px 30px",
    "&:last-child": {
      borderBottom: "none",
      paddingBottom: 30
    }
  },
  title: {
    fontWeight: 300,
    marginBottom: 5
  }
};

const CURRENCY_SYMBOLS = {
  "GBP (£)": "£",
  "USD ($)": "$",
  "EUR (\u20AC)": "\u20AC"
};
const FUNDING_LABELS = ["Funding", "Prize Money", "Cash", "Offer"];

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
const numberWithCommas = value => {
  if (!value) return "";

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const renderAmount = (text, title, classes) => {
  return (
    <div className={classes.panel}>
      <div className={classes.title}>{title}</div>
      <div className={classes.amount}>{text}</div>
    </div>
  );
};

const renderEquity = (benefit, classes) => {
  const text = `${benefit.value}${benefit.unit}`;

  return renderAmount(text, "Takes Equity stake", classes);
};

const renderFunding = (benefit, classes) => {
  if (!benefit.value) return null;

  const symbol = CURRENCY_SYMBOLS[benefit.unit] || benefit.unit;
  const text = `${symbol}${numberWithCommas(benefit.value)}`;
  const title = `${benefit.label} per team`;

  return renderAmount(text, title, classes);
};

const renderOtherBenefits = (benefits, classes) => {
  const options = benefits.filter(b => !b.value).map(b => b.label);
  const other = benefits.filter(b => b.label === "Other").map(b => b.value);

  const _benefits = [...options, ...other].map((text, index) => (
    <li key={index}>{text}</li>
  ));

  return (
    <div className={classes.panel}>
      <div className={classes.title}>Additional Benefits</div>
      <ul className={classes.list}>{_benefits}</ul>
    </div>
  );
};

const DocumentBenefits = props => {
  const { benefits, classes } = props;

  const equity = benefits.find(benefit => benefit.label === "Equity");
  const funding = benefits.find(
    benefit => FUNDING_LABELS.indexOf(benefit.label) !== -1
  );
  const other = benefits.filter(
    benefit => benefit.label === "Other" || !benefit.value
  );

  return (
    <div className={classes.root}>
      <div className={classes.panel}>
        <span style={{ lineHeight: "48px" }}>About the program</span>
      </div>
      {equity && renderEquity(equity, classes)}
      {funding && renderFunding(funding, classes)}
      {other.length ? renderOtherBenefits(other, classes) : null}
    </div>
  );
};

DocumentBenefits.propTypes = {
  benefits: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

export { DocumentBenefits, styles };
export default withStyles(styles)(DocumentBenefits);
