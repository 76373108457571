import React from "react";
import { withRouter } from "react-router-dom";

import { responseJsonOrError } from "../../api/response";

import ResetPasswordPage from "./ResetPasswordPage";

const URL = "/api/users/account/reset_password/new/";

class ResetPasswordPageContainer extends React.Component {
  state = {
    error: null,
    loading: false,
    success: "",
    token: ""
  };

  componentDidMount() {
    const { token } = this.props.match.params;
    //"reset_token=1a84d900-83b8-4f2e-baa9-018edbdd1827"
    // todo: validate token
    if (token.indexOf("=") !== -1) {
      this.setState({ token: token.split("=")[1] });
    } else {
      this.setState({ token: token.slice(6) });
    }
  }

  resetError = () => {
    this.setState({ error: null });
  };

  submitPassword = (new_password, confirm_password) => {
    const options = {
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        reset_token: this.state.token,
        new_password,
        confirm_password
      })
    };

    fetch(URL, options)
      .then(responseJsonOrError)
      .then(json => {
        const { error } = json;
        if (error) {
          this.setState({ error, loading: false });
        } else {
          this.setState({
            success: "Success! your password has been reset.",
            loading: false
          });
        }
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  };

  render() {
    return (
      <ResetPasswordPage
        resetError={this.resetError}
        submitPassword={this.submitPassword}
        {...this.state}
      />
    );
  }
}

export default withRouter(ResetPasswordPageContainer);
