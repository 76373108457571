const styles = {
  root: {
    color: "#2A2B2C",
    width: "auto",
    height: "40px"
  },
  badge: {
    // top: -2,
    right: -10,
    color: "white",
    backgroundColor: "#CEA751",
    fontSize: 12,
    fontWeight: 600,
    width: 21,
    height: 21
  },
  flatPrimary: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0)"
    }
  },
  icon: {
    fill: "#2A2B2C"
  },
  iconInactive: {
    fill: "#AAADB6"
  },
  label: {
    //borderBottom: "1.5px solid #3E4E5A",
    padding: 0,
    width: "auto"
  },
  sizeSmall: {
    width: "auto",
    minWidth: 0,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0)"
    }
  }
};

export default styles;
