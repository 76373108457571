import React from "react";

import MarketplaceBuilderContainer from "../components/Marketplace/MarketplaceBuilderContainer";

import PageTemplate from "./PageTemplate";

const MarketplaceBuilder = props => {
  return (
    <PageTemplate>
      <MarketplaceBuilderContainer {...props} />
    </PageTemplate>
  );
};

export default MarketplaceBuilder;
