import React from "react";

import PrivacyPolicy from "../components/StandAlonePages/PrivacyPolicy";

import PageTemplate from "./PageTemplate";

const Program = props => {
  return (
    <PageTemplate>
      <PrivacyPolicy />
    </PageTemplate>
  );
};

export default Program;
