import queryString from "query-string";

import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

import { COMPANY_TYPE_CORP, COMPANY_TYPE_FOUNDER } from "./constants";

//
// loadCompanies: request list of companies from type specific url
//
export const loadCompanies = (url, options = { limit: 24 }) => {
  const queryOpts = queryString.stringify(options);

  return fetchWithToken(`${url}?${queryOpts}`).then(responseJsonOrError);
};

//
// loadCompany: request details of a single company
//
export const loadCompany = (type, id) => {
  const url =
    type === COMPANY_TYPE_FOUNDER
      ? "/api/companies/founders/"
      : type === COMPANY_TYPE_CORP
      ? "/api/companies/corporations/"
      : "";

  if (!url)
    return Promise.resolve({ error: new Error("no company type specified") });

  return fetchWithToken(`${url}${id}`).then(responseJsonOrError);
};

//
// loadCorporate: request details for specific corporate
//
export const loadCorporate = id => loadCompany(COMPANY_TYPE_CORP, id);

//
// loadCorporates: request list of corporates from api
//
export const loadCorporates = options =>
  loadCompanies("/api/companies/corporations/", options);

//
// loadCorporate: request details for a specific founder company
//
export const loadFounder = id => loadCompany(COMPANY_TYPE_FOUNDER, id);

//
// loadFounders: request list of founder companies from api
//
export const loadFounders = options =>
  loadCompanies("/api/companies/founders/", options);
