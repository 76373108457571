const formStyles = {
  error: {
    backgroundColor: "#F9EFED",
    border: "1px solid #DB6E53",
    color: "#DB6E53"
  },
  helperText: {
    color: "#555B6E",
    fontSize: 13,
    fontWeight: 300,
    marginTop: 4,
    lineHeight: "18px"
  },
  label: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "17px",
    marginBottom: 6
  },
  input: {
    boxSizing: "border-box",
    backgroundColor: "#EDEDEF",
    border: "none",
    borderRadius: 3,
    color: "#2A2B2C",
    fontFamily: "Nunito, sans-serif",
    fontSize: 14,
    minHeight: 40,

    outline: "none",
    padding: "10px 15px",
    width: "100%",
    "&:disabled": {
      color: "rgba(85, 91, 110, 0.7)"
    },
    "&:focus": {
      backgroundColor: "#E8EFF8"
      // boxShadow: "0 0 0 1px #555B6E"
    }
  }
};

export default formStyles;
