import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import TabbedContainer from "../common/TabbedContainer";

import MemberCompany from "../Form/ApplicationReview/MemberCompany";
import QuestionsSummary from "../Form/ApplicationReview/QuestionsSummary";

import EligibilityTab from "./EligibilityTab";

const styles = {
  root: {
    marginBottom: 30
  },
  memberCompanyRoot: {
    //outline: "1px solid red",
    padding: "0 30px"
  },
  sectionRoot: {
    "&:first-child": {
      borderTop: "none"
    }
  },
  tabsRoot: {
    padding: "0 30px"
  }
};

const ApplicationDetailsTabs = props => {
  const { classes, company, criteria, _pending, questions } = props;

  const labels = [
    criteria.length ? "Eligibility" : null,
    questions.length ? "Application Responses" : null,
    "About the Company"
  ].filter(s => s);

  const style = _pending ? { opacity: 0.6 } : {};

  return (
    <TabbedContainer
      fullwidth={false}
      labels={labels}
      style={style}
      classes={{ root: classes.root, tabsRoot: classes.tabsRoot }}
    >
      {criteria.length ? (
        <EligibilityTab company={company} criteria={criteria} />
      ) : null}

      {questions.length ? <QuestionsSummary questions={questions} /> : null}

      <MemberCompany
        classes={{
          root: classes.memberCompanyRoot,
          sectionRoot: classes.sectionRoot
        }}
        company={company}
        openCompany={true}
        openFunding={true}
        openTeam={true}
      />
    </TabbedContainer>
  );
};

ApplicationDetailsTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  criteria: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired
};

ApplicationDetailsTabs.defaultProps = {
  company: {},
  criteria: [],
  questions: []
};

export default withStyles(styles)(ApplicationDetailsTabs);
