import TableCell from "@material-ui/core/TableCell";

import { withStyles } from "@material-ui/core/styles";

import { BODY_FONT_BLACK, GREY_MEDIUM } from "../../../styles/colors";

const styles = {
  head: {
    backgroundColor: GREY_MEDIUM,
    color: "white",
    fontSize: 14,
    fontWeight: 700,
    padding: "15px 30px"
    // "&:first-child": {
    //   paddingLeft: 30
    // },
    // "&:last-child": {
    //   paddingRight: 30
    // }
  },
  body: {
    color: BODY_FONT_BLACK,
    border: "none",
    fontSize: 14,
    padding: "15px 30px"
    // "&:first-child": {
    //   paddingLeft: 30
    // },
    // "&:last-child": {
    //   paddingRight: 30
    // }
  }
};

export default withStyles(styles)(TableCell);
