import { mergeListsById, updateElementById } from "../utils";

import {
  SET_COMPANIES_LIST_LOADING,
  UPDATE_COMPANIES_LIST,
  UPDATE_COMPANY
} from "./constants";

const addLoadingState = arr => {
  const newState = [...arr];
  newState.loading = true;

  return newState;
};

export default (state = [], action) => {
  switch (action.type) {
    case UPDATE_COMPANIES_LIST:
      return mergeListsById(state, action.payload);

    case SET_COMPANIES_LIST_LOADING:
      return addLoadingState(state);

    case UPDATE_COMPANY:
      return updateElementById(state, action.payload);

    default:
      return state;
  }
};
