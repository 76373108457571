import React from "react";

import ProgramApplicationsManagement from "../components/Programs/ProgramApplicationsManagement";

import PageTemplate from "./PageTemplate";

const ProgramApplications = props => {
  return (
    <PageTemplate>
      <ProgramApplicationsManagement {...props} />
    </PageTemplate>
  );
};

export default ProgramApplications;
