import React from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core/styles";

// const styles = theme => ({
//   root: {
//     width: "100%",
//     //marginTop: theme.spacing.unit * 3,
//     overflowX: "auto"
//   },
//   table: {},
//   row: {
//     "&:nth-of-type(even)": {
//       backgroundColor: theme.palette.grey[200]
//     }
//   },
//   td: {
//     "&:first-child": {
//       paddingLeft: "24px"
//     },
//     "&:last-child": {
//       maxWidth: 300,
//       paddingRight: "24px"
//     }
//   }
// });

// const CustomTableCell = withStyles(theme => ({
//   head: {
//     backgroundColor: theme.palette.grey[200],
//     padding: "4px 0px"
//   },
//   body: {
//     fontSize: 14,
//     padding: "4px 0px"
//   }
// }))(TableCell);

const styles = {
  root: {
    width: "100%",
    //marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {},
  row: {
    backgroundColor: "white",
    boxShadow: "0 1px 0 0 #EDEDEF"
  }
};

const cellStyle = {
  color: "#2A2B2C",
  fontSize: 14,
  fontWeight: 300,
  padding: 15,
  "&:first-child": {
    paddingLeft: 30
  },
  "&:last-child": {
    paddingRight: 30
  }
};

const CustomTableCell = withStyles({
  head: {
    ...cellStyle,
    backgroundColor: "#AAADB6",
    color: "white",
    fontSize: 13,
    fontWeight: 600
  },
  body: {
    ...cellStyle
  }
})(TableCell);

const renderHeaders = headers => {
  return (
    <TableHead>
      <TableRow>
        {headers.map(header => (
          <CustomTableCell key={header}>{header}</CustomTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const renderFooter = footer => {
  return (
    <TableFooter>
      <TableRow>{footer}</TableRow>
    </TableFooter>
  );
};

const StyledCell = props => {
  const { data } = props;

  return <CustomTableCell>{data}</CustomTableCell>;
};

const StyledRow = props => {
  const { classes, CellComponent, data } = props;

  return (
    <TableRow className={classes.row}>
      {data.map((cell, index) => (
        <CellComponent key={index} classes={classes} data={cell} />
      ))}
    </TableRow>
  );
};

const StyledTable = props => {
  const { classes, data, headers, footer, RowComponent } = props;

  return (
    <Table className={classes.table}>
      {!!headers.length && renderHeaders(headers, classes)}
      <TableBody>
        {data.map((row, idx) => (
          <RowComponent {...props} key={data.id || idx} data={row} />
        ))}
      </TableBody>
      {footer && renderFooter(footer)}
    </Table>
  );
};

StyledTable.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array.isRequired,
  footer: PropTypes.node,
  CellComponent: PropTypes.func.isRequired,
  RowComponent: PropTypes.func.isRequired
};

StyledTable.defaultProps = {
  CellComponent: StyledCell,
  RowComponent: StyledRow
};

export { styles, CustomTableCell, StyledTable };
export default withStyles(styles)(StyledTable);
