import React from "react";
import { PropTypes } from "prop-types";
import { Link, withRouter } from "react-router-dom";

import { withStyles } from "material-ui";

import { PrimaryButton } from "../common/Buttons";
import EmptyListState from "../common/EmptyListState";
import DocumentProfile from "../Document/DocumentDetailsView/DocumentProfile";

const styles = {
  root: {
    backgroundColor: "white",
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.05)",
    position: "relative"
  },
  editLink: {
    position: "absolute",
    top: 30,
    right: 30
  }
};

const GoToPitchEditor = withRouter(props => {
  const { children, history, staticContext, ...other } = props;

  return (
    <PrimaryButton
      {...other}
      onClick={() => history.push("/mycompany/edit#pitch")}
    >
      {children}
    </PrimaryButton>
  );
});

const renderPlaceholder = props => {
  const { company, profile } = props;

  return company.id === profile.company ? (
    <EmptyListState
      style={{ padding: 60 }}
      title="You haven't created your company pitch yet"
      subtitle="Your pitch is your way to promote your company’s background and what your company offers corporates and other founders."
    >
      <GoToPitchEditor>Add your company pitch</GoToPitchEditor>
    </EmptyListState>
  ) : (
    <EmptyListState
      style={{ padding: 60 }}
      title="This founder team has not shared their company pitch yet."
    />
  );
};

const renderProfile = props => {
  const { classes, company, profile } = props;
  const companyProfile = <DocumentProfile profile={company.profile} />;

  if (company.id !== profile.company) {
    return companyProfile;
  }

  return (
    <React.Fragment>
      <Link className={classes.editLink} to="/mycompany/edit#pitch">
        Edit
      </Link>
      {companyProfile}
    </React.Fragment>
  );
};

const CompanyPitch = props => {
  const { classes, company } = props;
  const pitch = company.profile || [];

  if (!company.is_founder) return null;

  return (
    <div className={classes.root}>
      {pitch.length !== 0 ? renderProfile(props) : renderPlaceholder(props)}
    </div>
  );
};

CompanyPitch.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

export { CompanyPitch };
export default withStyles(styles)(CompanyPitch);
