import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import CompanyPanel from "../common/CompanyPanel";
import ErrorPanel from "../common/Errors/ErrorPanel";

import ApplicationsPanel from "./ApplicationsPanel";

import styles from "./styles";

const renderHeaderSM = classes => {
  return (
    <div className={classes.listHeader}>
      <div className={classes.companyHeader} style={{ flexGrow: 1 }}>
        Company
      </div>
      <div className={classes.statusHeader}>Status</div>
    </div>
  );
};

const renderHeader = classes => {
  return (
    <div className={classes.listHeader}>
      <div className={classes.companyHeader}>Company</div>
      <div className={classes.headerCell}>Reason for application</div>
      <div className={classes.statusHeader}>Status</div>
    </div>
  );
};

const renderPublicView = props => {
  const { classes, applications } = props;

  if (applications.length === 0) return null;

  return (
    <div className={classes.root}>
      <div className={classes.header}>Participants</div>
      {applications.map(application => (
        <div className={classes.row} key={application.id}>
          <CompanyPanel company={application.company} />
        </div>
      ))}
    </div>
  );
};

const renderPrivateView = props => {
  const { classes, applications, doctype, document, width } = props;

  if (!applications.length) {
    return <div style={{ padding: 24 }}>no current applications</div>;
  }

  const header_text = `${applications.length} applications`;

  return (
    <div className={classes.root}>
      {props.error && (
        <ErrorPanel error={props.error} classes={{ root: classes.errorRoot }} />
      )}
      <div className={classes.header}>{header_text}</div>

      {width === "sm" && renderHeaderSM(classes)}

      {width !== "xs" && width !== "sm" && renderHeader(classes)}

      {applications.map(application => (
        <div className={classes.row} key={application.url}>
          <ApplicationsPanel
            application={application}
            doctype={doctype}
            document={document}
            width={width}
          />
        </div>
      ))}
    </div>
  );
};

const ApplicationsListView = props => {
  return props.viewtype === "private"
    ? renderPrivateView(props)
    : renderPublicView(props);
};

ApplicationsListView.propTypes = {
  applications: PropTypes.array.isRequired,
  document: PropTypes.object.isRequired,
  doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
  viewtype: PropTypes.oneOf(["public", "private"]).isRequired
};

ApplicationsListView.defaultProps = {
  applications: [],
  viewtype: "public"
};

export default withWidth()(withStyles(styles)(ApplicationsListView));
