import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import { renderLayout, renderLayoutSmall } from "./layouts";

import styles from "./styles";

const DocumentDetailsView = props => {
  const { width } = props;

  const STYLE_OVERRIDES = {
    xl: { main: {}, row: {}, sidebar: {} },
    lg: { main: {}, row: {}, sidebar: {} },
    md: {
      main: { minWidth: 610, width: 610 },
      row: { minWidth: 1000, width: 1000 },
      sidebar: { maxWidth: 360, width: 360 }
    },
    sm: {
      main: { width: 610 },
      row: { width: 610, marginBottom: 30 },
      sidebar: { width: 610, marginLeft: 0 }
    },
    xs: {
      main: { width: "100%" },
      row: { width: "100%", marginBottom: 30 },
      sidebar: { width: "100%", marginLeft: 0 }
    }
  };
  const styles = STYLE_OVERRIDES[width];

  const _props = {
    ...props,
    styles
  };

  if (width === "sm" || width === "xs") {
    return renderLayoutSmall(_props);
  }

  return renderLayout(_props);
};

DocumentDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  preview: PropTypes.bool,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

DocumentDetailsView.defaultProps = {
  preview: false
};

const mapStateToProps = state => ({ company: state.company });

export { DocumentDetailsView, styles };
export default connect(mapStateToProps)(
  withStyles(styles)(DocumentDetailsView)
);
