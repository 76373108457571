import React from "react";

import ThinkTankApplicationForm from "../components/Challenges/ThinkTankApplicationForm";

import PageTemplate from "./PageTemplate";

const ProgramApplication = props => {
  return (
    <PageTemplate>
      <ThinkTankApplicationForm {...props} />
    </PageTemplate>
  );
};

export default ProgramApplication;
