import { connect } from "react-redux";

import { login, resetLoginError } from "../../Reducers/Profile";

import SignInPage from "./SignInPage";

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => {
      dispatch(login(username, password));
    },
    resetLoginError: () => dispatch(resetLoginError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInPage);
