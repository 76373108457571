import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { PrimaryButton, SecondaryButton } from "../../../common/Buttons";
import SimpleDialog from "../../../common/SimpleDialog";
import TextInputField from "../../../Form/TextInputField";

const styles = {
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20
  },
  comment: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    marginTop: -5,
    marginBottom: 10
  },
  textInputRoot: {
    marginTop: 0
  },
  textInputLabel: {
    fontSize: 15,
    lineHeight: "18px",
    marginBottom: 8
  },
  textInputHelperText: {
    fontSize: 13,
    fontStyle: "italic",
    marginTop: 0
  }
};

class ConnectWithDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: `Hello ${
        props.company.name
      }\n\nWe would like to get in contact with you.`,
      message_error: null,
      pending: false
    };
  }

  isDisabled = (id, value) => {
    const state = { ...this.state, [id]: value };

    if (!state.message) return true;

    return false;
  };

  onBlur = id => () => {
    const errorId = `${id}_error`;

    if (!this.state[id]) {
      this.setState({ [errorId]: "This field is required." });
    } else {
      this.setState({ [errorId]: null });
    }
  };

  onSubmit = () => {
    const { company, myCompany, postURL } = this.props;
    const { message } = this.state;

    this.props.requestConnection(postURL, message, company, myCompany);
    this.props.onClose();
  };

  updateState = id => value => {
    const disabled = this.isDisabled(id, value);

    this.setState({ [id]: value, disabled });
  };

  render() {
    const { classes, company, title } = this.props;

    const _title = title || `Connect with ${company.name}`;

    return (
      <SimpleDialog title={_title} width={440}>
        <TextInputField
          id="message"
          classes={{
            root: classes.textInputRoot,
            label: classes.textInputLabel,
            helperText: classes.textInputHelperText
          }}
          error={Boolean(this.state.message_error)}
          helperText={this.state.message_error}
          label="Include a note you would like to send with your connection request:"
          limit={300}
          onBlur={this.onBlur("message")}
          onChange={this.updateState("message")}
          rows={6}
          showLimit={true}
          type="text"
          value={this.state.message}
          width={440}
        />

        <div className={classes.buttons}>
          <PrimaryButton
            disabled={this.state.disabled}
            onClick={() => this.setState({ pending: true }, this.onSubmit)}
          >
            Connect
          </PrimaryButton>
          <SecondaryButton onClick={() => this.props.onClose()}>
            Cancel
          </SecondaryButton>
        </div>
      </SimpleDialog>
    );
  }
}

ConnectWithDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  comment: PropTypes.string,
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  myCompany: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  requestConnection: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  postURL: PropTypes.string.isRequired,
  title: PropTypes.string
};

ConnectWithDialog.defaultProps = {
  onClose: () => {},
  postURL: "/api/connections/"
};

export { ConnectWithDialog, styles };
export default withStyles(styles)(ConnectWithDialog);
