import React from "react";
import { PropTypes } from "prop-types";

import Checkbox from "@material-ui/core/Checkbox";

const OptionalBenefit = props => {
  const { benefits, classes, label, toggleBenefit } = props;

  const checked = Boolean(benefits.find(b => b.label === label));
  const style = checked ? { backgroundColor: "#E8EFF8" } : {};

  return (
    <div key={label} className={classes.benefit} style={style}>
      <Checkbox
        className={classes.checkbox}
        checked={checked}
        onChange={() => toggleBenefit(label)}
        value="value"
      />
      {label}
    </div>
  );
};

OptionalBenefit.propTypes = {
  benefits: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  toggleBenefit: PropTypes.func.isRequired
};

export default OptionalBenefit;
