import { ADD_STARTUPS, SET_STARTUP_DATA } from "./startupsActions";

const mergeLists = (list1, list2) => {
  const byId = [...list1, ...list2].reduce((byId, startup) => {
    byId[startup.id] = startup;
    return byId;
  }, {});

  return Object.values(byId).sort((a, b) => a.name < b.name);
};

export default (state = [], action) => {
  switch (action.type) {
    case ADD_STARTUPS:
      return mergeLists(state, action.payload);

    case SET_STARTUP_DATA:
      return state.map(
        startup =>
          startup.id === action.payload.id
            ? { ...startup, ...action.payload }
            : startup
      );

    default:
      return state;
  }
};
