import React from "react";

import ThinkTankApplicationsManagement from "../components/Challenges/ThinkTankApplicationsManagement";

import PageTemplate from "./PageTemplate";

const ThinkTankApplications = props => {
  return (
    <PageTemplate>
      <ThinkTankApplicationsManagement {...props} />
    </PageTemplate>
  );
};

export default ThinkTankApplications;
