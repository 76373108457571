import React from "react";
import { PropTypes } from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { GREY_LIGHT } from "../../../styles/colors";
import TextInputField from "../../Form/TextInputField";

import CompanyBranding from "./CompanyBranding";
import MessagePanel from "./MessagePanelContainer";

const styles = {
  root: {},
  header: {
    fontSize: 18,
    fontWeight: 700,
    padding: 30,
    paddingBottom: 0
  },

  companyRoot: {
    padding: "0px 30px 20px 30px",
    borderBottom: `1px solid ${GREY_LIGHT}`
  },
  disclaimer: {
    fontSize: 13,
    lineHeight: "17px",
    padding: "20px 30px"
  },
  messageRoot: {
    paddingTop: 20,
    borderBottom: `1px solid ${GREY_LIGHT}`
  }
};

TextInputField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  limit: PropTypes.number.isRequired,
  limitErrorText: PropTypes.string,
  type: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.any
};

class EnquirePanel extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    document: PropTypes.object.isRequired
  };

  render() {
    const { classes, document } = this.props;

    return (
      <div className={classes.root}>
        <h3 className={classes.header}>Enquire</h3>
        <CompanyBranding
          classes={{ root: classes.companyRoot }}
          company={{
            logo_image_url: document.company_logo_url,
            mission_statement: document.company_mission_statement,
            name: document.company_name,
            website: document.company_url
          }}
        />

        <MessagePanel
          classes={{ root: classes.messageRoot }}
          message={"Hi, please tell me more about this and how best to proceed"}
          url={`/api${document.link}/responses/`}
        />

        <div className={classes.disclaimer}>
          Please note that Whitespace is not responsible for any content or
          information for this service provider.
        </div>
      </div>
    );
  }
}

export { styles, EnquirePanel };
export default withStyles(styles)(EnquirePanel);
