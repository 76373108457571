import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ButtonGroup from "../../common/ButtonGroup";

import ApplicationCriteria from "./ApplicationCriteriaQuestionContainer";

const rowStyle = {
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: 60,
  padding: "15px 30px"
};

const styles = {
  root: {
    //marginTop: 40,
    width: "100%",
    overflowX: "auto",
    marginBottom: 80
  },
  buttons: {
    width: 170,
    flexGrow: 0,
    flexShrink: 0
  },
  criterion: {
    ...rowStyle,
    borderBottom: "1px solid #E6E7E9"
  },
  criterionText: {
    flexGrow: 1,
    marginRight: 20
  },
  empty: {
    padding: 30,
    textAlign: "center"
  },
  header: {
    ...rowStyle,
    backgroundColor: "#AAADB6",
    color: "white",
    fontSize: 13,
    fontWeight: 600
  }
};

class ApplicationCriteriaForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      criteria: props.criteria.map(criterion => ({
        ...criterion
      }))
    };
  }

  onChange = (index, value) => {
    const { criteria } = this.state;
    const criterion = { ...criteria[index], response: value };

    const _criteria = criteria.map((_c, idx) =>
      idx === index ? criterion : _c
    );

    this.setState({ criteria: _criteria }, () =>
      this.props.onChange(_criteria)
    );
  };

  renderCriterion = (criterion, index) => {
    const { classes } = this.props;
    const { response } = criterion;

    return (
      <div key={index} className={classes.criterion}>
        <div className={classes.criterionText}>
          <ApplicationCriteria question={criterion} />
        </div>
        <div className={classes.buttons}>
          <ButtonGroup
            options={["Yes", "No"]}
            onChange={selection => this.onChange(index, selection[0])}
            state={response}
          />
        </div>
      </div>
    );
  };

  render() {
    const { classes, criteria } = this.props;

    if (!criteria.length) {
      return (
        <div className={classes.empty}>
          The organiser has not specified any participation requirements.
        </div>
      );
    }

    return (
      <div>
        <div className={classes.header}>
          <div>Criteria</div>
          <div className={classes.buttons}>Do you meet this criteria?</div>
        </div>

        <div className={classes.root}>
          {/* TODO: Audience (founder and corporation) */}
          {criteria.map((criterion, index) =>
            this.renderCriterion(criterion, index)
          )}
        </div>
      </div>
    );
  }
}

ApplicationCriteriaForm.propTypes = {
  application: PropTypes.object.isRequired,
  criteria: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired
};

export { ApplicationCriteriaForm, styles };
export default withStyles(styles)(ApplicationCriteriaForm);
