import React from "react";
import { PropTypes } from "prop-types";

import ReactPlayer from "react-player";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    marginTop: 20,
    position: "relative",
    paddingTop: "56.25%" // Player ratio: 100 / (1280 / 720)
  },
  player: {
    position: "absolute",
    top: 0,
    left: 0
  }
};

const VideoPlayer = props => {
  const { classes, controls, playing, style, url, ...other } = props;

  return (
    <div className={classes.root} style={style}>
      <ReactPlayer
        className={classes.player}
        controls={controls}
        url={url}
        playing={playing}
        {...other}
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired
};

VideoPlayer.defaultProps = {
  controls: true,
  playing: false,
  style: {},
  width: "100%",
  height: "100%"
};

export { VideoPlayer, styles };
export default withStyles(styles)(VideoPlayer);
