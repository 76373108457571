import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",

    fontSize: 15,
    fontWeight: 700,
    marginRight: 30,
    color: "#555B6E",

    cursor: "pointer",
    "&:hover": {
      color: "#2A2B2C",
      textDecoration: "underline"
    }
  }
};

const NavigationLink = props => {
  const { children, classes, onClick, style } = props;

  return (
    <div className={classes.root} onClick={onClick} style={style}>
      {children}
    </div>
  );
};

NavigationLink.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object.isRequired
};

NavigationLink.defaultProps = {
  classes: {},
  style: {}
};

export default withStyles(styles)(NavigationLink);
