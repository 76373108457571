import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ServiceLinks from "./ServiceLinksContainer";

const ServiceChart = React.lazy(() => import("../ServiceChart"));

const panelStyle = {
  boxSizing: "border-box",
  backgroundColor: "white",
  padding: 30,
  height: 280
};

const styles = {
  root: {
    boxSizing: "border-box",
    backgroundColor: "#AAADB6",
    padding: 30
  },
  container: {
    display: "flex"
  },
  panelChart: {
    ...panelStyle,
    padding: 0,
    flexGrow: 1
  },
  panelLinks: {
    ...panelStyle,
    width: 320,
    marginRight: 30
  }
};

const shouldRender = (company, document) => {
  if (!company || !company.id) return false;

  // allow __service__ management for superadmin users only
  if (document.doctype === "service" && company.id === 69) {
    return true;
  }

  if (!document.company || !document.company.id) return false;

  // allow program/TT management for owner
  if (company.id === document.company.id) return true;

  return false;
};

const ManagePanel = props => {
  const { classes, company, document } = props;

  if (!shouldRender(company, document)) return null;

  return (
    <div className={classes.root}>
      <div className={`body ${classes.container}`}>
        <div className={classes.panelLinks}>
          <ServiceLinks document={{ ...document }} />
        </div>
        <div className={classes.panelChart}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <ServiceChart document={{ ...document }} />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
};

ManagePanel.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  document: PropTypes.shape({
    doctype: PropTypes.oneOf(["program", "challenge", "service"])
  }).isRequired
};

export { ManagePanel, styles };
export default withStyles(styles)(ManagePanel);
