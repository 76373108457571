import { connect } from "react-redux";

const findTagById = (id, tags) => {
  return tags.find(tag => tag.id === id);
};

const MarketsList = props => {
  const { tags, markets } = props;

  if (!markets || !markets.length) return null;

  if (markets[0].name) {
    return markets.map(m => m.name).join(", ");
  }

  if (typeof parseInt(markets[0], 10) === "number") {
    return markets
      .map(market => parseInt(market, 10))
      .map(id => findTagById(id, tags) || id)
      .filter(tag => tag)
      .map(tag => tag.name)
      .join(", ");
  }

  return markets.join(", ");
};

const mapStateToProps = state => {
  return {
    tags: state.tags
  };
};

export default connect(
  mapStateToProps,
  null
)(MarketsList);
