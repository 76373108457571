import { validate } from "../Form/validate";

export const compareDocuments = (document, state) => {
  return Object.keys(document).some(id => document[id] !== state[id]);
};

export const getCompletedPages = (pages, document) => {
  return pages.map(page => {
    const validationErrors = validate(document, page.formFields);
    const isComplete = !Object.keys(validationErrors).length;

    return isComplete;
  });
};

export const getFirstPageWithErrors = (validationErrors, pages) => {
  const page = getPageErrors(validationErrors, pages).findIndex(p => p);

  return page !== undefined ? page : pages.length - 1;
};

export const getPageErrors = (validationErrors = {}, pages = []) => {
  const keys = Object.keys(validationErrors);

  return pages.map(page => {
    const ids = page.formFields.map(field => field.id);

    return ids.some(id => keys.indexOf(id) !== -1);
  });
};

export const getRequiredFields = pages => {
  return pages
    .map(page => page.formFields)
    .reduce((fields, perPage) => {
      return [...fields, ...perPage];
    }, [])
    .filter(field => field.required)
    .map(field => field.id);
};

export const getTitleFromDocument = (document, preview = false) => {
  const { doctype } = document;

  if (document.isApplication) {
    if (doctype === "program") return "Apply";
    if (doctype === "challenge") return "Register interest";
  }

  if (doctype === "registration") return "Welcome to Colinked";

  if (preview) return document.name || "";

  if (!document._isUnsavedDraft) return "Edit";

  if (doctype === "program") return "Create a new program";

  if (doctype === "challenge") return "Create a think tank challenge";

  return document.name;
};

export const notEmpty = value => {
  // TODO: check complex objects like location and program profile
  if (!value) return false;

  if (Array.isArray(value)) return Boolean(value.length);

  if (typeof value === "object") return Boolean(Object.keys(value).length);

  return true;
};

export const scrollElementIntoView = id => {
  const element = document.querySelector(`#${id}`);
  element && element.scrollIntoView();

  return Boolean(element);
};

export const state2document = state => {
  const data = Object.keys(state)
    .filter(key => !key.startsWith("_"))
    .filter(key => key !== "doctype")
    .filter(key => key !== "error")
    .filter(key => key !== "validationErrors")
    .reduce((data, key) => {
      data[key] = state[key];
      return data;
    }, {});

  // replace company object with id for programs and challenges
  if (state.doctype === "program" || state.doctype === "challenge") {
    return { ...data, company: data.company && data.company.id };
  }

  return data;
};
