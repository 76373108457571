const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

    fontSize: 14,
    //fontStyle: "italic",
    fontWeight: 300,
    color: "#DB6E53",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  icon: {
    marginRight: 5,
    fill: "#DB6E53"
  }
};

export default styles;
