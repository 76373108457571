import { fetchWithToken } from "../../api";
import { responseJsonOrError } from "../../api/response";

export const ADD_STARTUPS = "ADD_STARTUPS";
export const SET_STARTUP_DATA = "SET_STARTUP_DATA";

const FOUNDERS_URL = "/api/companies/founders";

const expandData = string => {
  return process.env.NODE_ENV === "development"
    ? string +
        "&_expand=corporation&_expand=referral&_expand=contact&_expand=activity&_expand=contact"
    : string;
};

const cleanStartupData = data => {
  return [
    "id",
    "name",
    "address-line-1",
    "address-line-2",
    "city",
    "zip",
    "country",
    "website",
    "company-type",
    "company-size",
    "stage",
    "founded",
    "company-logo",
    "company-logo-small",
    "company-pitch",
    "company-pitch-headline",
    "company-pitch-video",
    "company-revenue",
    "company-description",
    "company-technologies",
    "markets",
    "company-active-countries"
  ].reduce((startup, key) => {
    if (data.hasOwnProperty(key)) {
      startup[key] = data[key];
    }
    return startup;
  }, {});
};

export const addStartupsList = startups => {
  //console.log("addStartupsList", startups.length);
  return {
    type: ADD_STARTUPS,
    payload: startups
  };
};

export const setStartupData = data => {
  //console.log("setStartupData", data);
  return {
    type: SET_STARTUP_DATA,
    payload: data
  };
};

export const loadStartup = id => {
  //console.log(`loadStartup(id=${id})`);
  return dispatch => {
    _loadStartupDatafromAPI(id)
      .then(startup => {
        dispatch(addStartupsList([startup]));
      })
      .catch(error => {
        dispatch(addStartupsList([{ id, error }]));
        console.warn(error);
      });
  };
};

const _loadStartupDatafromAPI = id => {
  const queryString = expandData("");

  return fetchWithToken(`${FOUNDERS_URL}/${id}/?${queryString}`, {
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(response => responseJsonOrError(response))
    .then(json => {
      if (json.error) throw json.error;

      return json;
    });
};

export const loadStartupsList = options => {
  return dispatch => {
    _loadStartupsListFromAPI(options)
      .then(json => json.results)
      .then(list => {
        dispatch(addStartupsList(list));
      })
      .catch(error => console.warn(error));
  };
};

const _loadStartupsListFromAPI = (options = {}) => {
  // TODO: apply search options

  return fetchWithToken(`${FOUNDERS_URL}/`, {
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(json => {
      if (json.error) throw json.error;

      return json;
    });
};

export const updateStartup = data => {
  //console.log("updateStartup", data.id);
  const { id } = data;
  if (!id) return;

  return dispatch => {
    _updateStartupViaAPI(data)
      .then(startup => {
        dispatch(setStartupData(startup));
      })
      .catch(error => {
        dispatch(setStartupData({ id, error }));
        console.warn(error);
      });
  };
};

const _updateStartupViaAPI = data => {
  return fetchWithToken(`${FOUNDERS_URL}/${data.id}/`, {
    body: JSON.stringify(cleanStartupData(data)),
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "content-type": "application/json"
    },
    method: "PATCH"
  })
    .then(response => responseJsonOrError(response))
    .then(json => {
      if (json.error) throw json.error;

      return json;
    });
};
