import React from "react";
import { PropTypes } from "prop-types";

import Dropzone from "react-dropzone";
import { throttle } from "underscore";

import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";

import { uploadFile } from "../../../api/tokenApi";
import { responseJsonOrError } from "../../../api/response";

import ErrorPanel from "../../common/Errors/ErrorPanelSmall";
import Icons from "../../Icons";

import FormInputField from "../FormInputField";
import { formatFileSize } from "../utils";

import Controls from "./Controls";
import { styles as baseStyles } from "./BaseFrame";

const clickFrame = {
  height: 120,
  margin: -20,
  marginTop: 0,

  // font style
  color: "#2A2B2C",
  fontSize: 12,
  fontWeight: 300,

  // cursor
  cursor: "pointer",

  // center content
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
};

const styles = {
  ...baseStyles,

  rootContent: {
    ...baseStyles.root,
    border: "none"
  },
  clearIcon: {
    fill: "#AAADB6",
    marginLeft: 8
  },
  contentFile: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 5
  },
  contentIcon: {
    fill: "#AAADB6",
    marginRight: 8
  },
  contentRow: {
    // outline: "1px solid #AAADB6",
    display: "flex",
    alignItems: "center"
  },

  iconUpload: {
    fill: "#E6E7E9",
    marginBottom: 5
  },
  clickFrame: {
    ...clickFrame,
    "&:hover": {
      backgroundColor: "#F2F3F4"
    }
  },
  dropFrame: {
    ...clickFrame,
    margin: 0,
    width: "100%"
  },
  dropFrameActive: {
    ...clickFrame,
    backgroundColor: "#F2F3F4",
    margin: 0
  },

  errorRoot: {
    marginBottom: 10
  },
  errorTitle: {
    padding: 5
  },
  errorMessage: {
    padding: 5
  },

  blue: {
    color: "#398DD3",
    marginLeft: "0.2em",
    marginRight: "0.2em",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  image: {
    minHeight: 80,
    margin: -20,
    marginTop: -50
  },

  spinner: {
    // color: "white",
    // fontSize: 30,
    // height: "100%",

    // center content
    display: "flex",
    alignItems: "center"
    //justifyContent: "center"
  },
  spinnerIcon: {
    fontSize: 36,
    marginRight: 10,
    animation: "buttonspin infinite 2s linear"
  },
  "@keyframes buttonspin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" }
  },

  typeContent: {
    ...baseStyles.type,
    color: "white"
  }
};

class FileUpload extends React.Component {
  static propTypes = {
    allowedFileTypes: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    content: PropTypes.string,
    description: PropTypes.string,
    displayFileTypes: PropTypes.array.isRequired,
    maxFileSize: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    showControls: PropTypes.bool,
    showDescriptionInput: PropTypes.bool,
    size: PropTypes.number,
    type: PropTypes.string
  };

  static defaultProps = {
    allowedFileTypes: ["application/pdf"],
    displayFileTypes: ["PDF"],
    maxFileSize: 15,
    showControls: true,
    showDescriptionInput: true,
    size: 0
  };

  constructor(props) {
    super(props);

    this.id = `id_${Math.random()}`;

    this.state = {
      content: props.content,
      description: props.description || "",
      error: null,
      loading: null,
      height: 80,
      size: props.size
    };

    this.updateDescription = throttle(this._updateDescription, 400).bind(this);
  }

  handleDrop = files => {
    if (!files.length) return;

    const file = files[0];
    const { error } = this.validateFile(file);

    if (error) {
      this.setState({ error }, () =>
        setTimeout(() => this.removeError(error.message), 6000)
      );
    } else {
      this.setState({ loading: file.name, size: file.size }, () => {
        this.uploadFile(file);
      });
    }
  };

  removeError = message => {
    const { error } = this.state;

    if (error && error.message === message) {
      this.setState({ error: null });
    }
  };

  startFileSelector = id => {
    const input = document.getElementById(id);
    if (!input) return;

    input.addEventListener("change", event =>
      this.handleDrop(event.target.files)
    );
    input.click();
  };

  _updateDescription = value => {
    this.props.onChange({ description: value });
  };

  uploadFile = file => {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("name", file.name);
    formData.append("size", file.size);
    formData.append("type", file.type);

    uploadFile("/api/uploads/", {
      method: "POST",
      credentials: "same-origin",
      body: formData
    })
      .then(responseJsonOrError)
      .then(json => {
        const { error, url_filepath } = json;

        if (error) {
          throw error;
        }
        if (!url_filepath) {
          throw new Error("No url received from server.");
        }

        this.setState(
          {
            content: url_filepath,
            loading: null
          },
          () => {
            this.props.onChange({ content: url_filepath, size: file.size });
          }
        );
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error,
          loading: null,
          size: 0
        });
      });
  };

  validateFile = file => {
    const { allowedFileTypes, maxFileSize } = this.props;

    if (allowedFileTypes.indexOf(file.type) === -1) {
      return {
        error: new Error(`Files of type '${file.type}' are not allowed.`)
      };
    }

    if (maxFileSize * 1024 * 1024 < file.size) {
      return {
        error: new Error(`File is too big. Max file size: ${maxFileSize} MB.`)
      };
    }

    return { error: null };
  };

  renderFileSize = (size, classes) => {
    if (size === 0) return null;

    return (
      <span className={classes.contentFile} style={{ fontWeight: 300 }}>
        ({formatFileSize(size)})
      </span>
    );
  };

  renderContentFrame = () => {
    const { classes, showDescriptionInput } = this.props;
    const { content, description, size } = this.state;

    return (
      <React.Fragment>
        <div className={classes.contentRow}>
          <Icons.CloudUpload className={classes.contentIcon} />

          <span className={classes.contentFile}>
            {content.split("/").slice(-1)}
          </span>

          {this.renderFileSize(size, classes)}
          <div style={{ flexGrow: 1 }} />

          <Icons.TrashCanOutline
            className={classes.clearIcon}
            onClick={() =>
              this.setState({ content: "" }, () =>
                this.props.onChange({ content: "" })
              )
            }
          />
        </div>

        {showDescriptionInput && (
          <FormInputField
            id={this.id + "_description"}
            label="Add a descriptive name for this file"
            onChange={value =>
              this.setState({ description: value }, () =>
                this.updateDescription(value)
              )
            }
            style={{ marginBottom: 0 }}
            value={description}
          />
        )}
      </React.Fragment>
    );
  };

  renderControls = (style = {}) => {
    const { deleteFrame, moveDown, moveUp } = this.props;

    const controlProps = {
      deleteFrame,
      moveDown,
      moveUp
    };

    return <Controls {...controlProps} style={style} />;
  };

  renderLoadingFrame = () => {
    const { classes } = this.props;
    const { loading, size } = this.state;

    return (
      <div className={classes.spinner}>
        <RefreshIcon className={classes.spinnerIcon} />
        uploading file
        <span style={{ margin: 4, fontWeight: 600 }}>{loading}</span>
        {formatFileSize(size)} ...
      </div>
    );
  };

  renderUploadForm = () => {
    const { classes } = this.props;
    const { error } = this.state;

    return (
      <React.Fragment>
        <div
          className={classes.clickFrame}
          style={error ? { height: "auto" } : {}}
          onClick={() => this.startFileSelector(`${this.id}-selector`)}
        >
          <Dropzone
            className={classes.dropFrame}
            activeClassName={classes.dropFrameActive}
            onDrop={this.handleDrop}
          >
            {error && (
              <ErrorPanel
                error={error}
                onClear={evt => {
                  evt.stopPropagation();

                  this.setState({ error: null });
                }}
              />
            )}
            <Icons.CloudUpload className={classes.iconUpload} />
            <div>
              Drag here or <span className={classes.blue}>click to select</span>{" "}
              a file to upload.
            </div>
            <div>{`(max file size: ${
              this.props.maxFileSize
            } MB, allowed file types: ${this.props.displayFileTypes} )`}</div>
          </Dropzone>
        </div>
        <input
          id={`${this.id}-selector`}
          type="file"
          style={{ display: "none" }}
        />
      </React.Fragment>
    );
  };

  render() {
    const { content, loading } = this.state;
    const { classes, showControls, type } = this.props;

    let body = null;
    if (content) {
      body = this.renderContentFrame();
    } else if (loading) {
      body = this.renderLoadingFrame();
    } else {
      body = this.renderUploadForm();
    }

    return (
      <div className={classes.root} id={this.id}>
        {showControls && this.renderControls()}

        {type ? <div className={classes.type}>{type}</div> : null}

        {body}
      </div>
    );
  }
}

export { FileUpload, styles };
export default withStyles(styles)(FileUpload);
