import React from "react";
import { PropTypes } from "prop-types";

import DocumentsListView from "./DocumentsListView";
import InfiniteScrollView from "./InfiniteScrollView";

class DocumentsListViewContainer extends React.Component {
  static propTypes = {
    ListViewComponent: PropTypes.func.isRequired,
    doctype: PropTypes.string.isRequired,
    loadDocuments: PropTypes.func.isRequired,
    loadDocumentsFromUrl: PropTypes.func,
    limit: PropTypes.number.isRequired
  };

  static defaultProps = {
    ListViewComponent: DocumentsListView,
    limit: 10
  };

  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      error: false,
      loading: false,
      count: 0,
      next: null,
      previous: null,
      limit: props.limit,
      searchOptions: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, this.load);
  }

  componentWillUnmount() {
    this._UNLOADING = true;
  }

  load = () => {
    const { searchOptions, limit } = this.state;
    const options = [...searchOptions, { limit }];

    this.props
      .loadDocuments(options)
      .then(json => this.setStateFromJson(json))
      .catch(error => {
        if (this._UNLOADING) return;

        this.setState({ error, loading: false });
      });
  };

  loadDocumentsFromUrl = url => {
    // remove host part for testing environment
    const _url = url.slice(url.indexOf("/api/"));

    this.props
      .loadDocumentsFromUrl(_url)
      .then(json => {
        const { error, results, ...other } = json;

        if (this._UNLOADING) return;

        if (error) {
          this.setState({ error, loading: false });
        } else {
          this.setState({
            documents: [...this.state.documents, ...results],
            loading: false,
            ...other
          });
        }
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  };

  loadNext = () => {
    if (!this.state.next) {
      return;
    }

    if (this.state.loading) {
      return;
    }

    if (!this.props.loadDocumentsFromUrl) {
      return;
    }

    this.setState({ loading: true }, () =>
      this.loadDocumentsFromUrl(this.state.next)
    );
  };

  handleChangeRowsPerPage = event => {
    const limit = event.target.value;

    if (limit && limit !== this.state.limit) {
      this.setState({ limit, loading: true }, this.load);
    }
  };

  setStateFromJson = json => {
    const { error, results, count, next, previous } = json;

    if (this._UNLOADING) return;

    if (error) {
      this.setState({ error, loading: false });
      return;
    }

    // set loadNextPage and loadPreviousPage for table pagination
    const loadNextPage =
      next && this.props.loadDocumentsFromUrl
        ? () => this.loadDocumentsFromUrl(next)
        : () => {};
    const loadPreviousPage =
      previous && this.props.loadDocumentsFromUrl
        ? () => this.loadDocumentsFromUrl(previous)
        : () => {};

    this.setState({
      documents: results,
      loading: false,
      count,
      next,
      previous,
      loadNextPage,
      loadPreviousPage
    });
  };

  updateSearchOptions = options => {
    console.warn("TODO: update search options", options);
  };

  render() {
    const { doctype, loadDocuments, ...otherProps } = this.props;

    return (
      <InfiniteScrollView
        doctype={doctype}
        {...otherProps}
        {...this.state}
        loadNext={this.loadNext}
        showPagination={false}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        updateSearchOptions={this.updateSearchOptions}
      />
    );
  }
}

export default DocumentsListViewContainer;
