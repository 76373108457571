import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import NewsArticle from "./NewsArticle";
import Section from "../common/Section";

const styles = {
  root: {
    marginTop: 0
  },
  newsList: {},
  articles: {}
};

const NewsList = props => {
  const { classes, title, articles, ...other } = props;

  return (
    <Section fullwidth={false} className={classes.root}>
      <div className={classes.articles}>
        {articles.map((article, idx) => (
          <NewsArticle key={idx} {...other} {...article} />
        ))}
      </div>
    </Section>
  );
};

NewsList.propTypes = {
  articles: PropTypes.array,
  title: PropTypes.string
};

NewsList.defaultProps = {
  articles: []
};

export default withStyles(styles)(NewsList);
