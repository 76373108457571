import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { updateNotifications } from "../../../Reducers/Notifications";

import DashboardLink from "./DashboardLink";

export class DashboardLinkContainer extends React.Component {
  static propTypes = {
    updateNotifications: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { notifications, profile } = this.props;

    // don't request update while loading request is pending
    if (notifications.loading) return;

    // no action for guests or when user is signing out
    if (!profile.isSignedIn || profile.signingOut) return;

    // update notifications
    this.props.updateNotifications();
  }

  render() {
    return <DashboardLink {...this.props} />;
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications,
  profile: state.profile
});

const mapDispatchToProps = dispatch => ({
  updateNotifications: () => dispatch(updateNotifications())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardLinkContainer));
