import ChevronDown from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import MoreIcon from "@material-ui/icons/MoreHoriz";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { PrimaryButtonGreen } from "../../../common/Buttons";
import {
  BLUE_PRIMARY,
  BODY_FONT_BLACK,
  GREEN,
  GREY_MEDIUM
} from "../../../../styles/colors";
import SubMenuButton from "../../../common/Buttons/SubMenuButton";

import ConnectionLink from "./ConnectionLink";
import ConnectWithDialogContainer from "./ConnectWithDialogContainer";
import RemoveConnectionDialogContainer from "./RemoveConnectionDialogContainer";

const styles = {
  connection: {
    //outline: "1px solid red",
    display: "flex",
    alignItems: "center",
    color: BODY_FONT_BLACK,
    fontSize: 15,
    fontWeight: 700,
    marginLeft: 20
  },
  link: {
    color: BLUE_PRIMARY,
    marginLeft: 6,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  },
  loading: {
    color: GREY_MEDIUM
  },
  icon: {
    fill: GREEN,
    marginRight: 6
  },
  submenu: {
    marginRight: 15
  },
  submenuButton: {
    paddingLeft: 5,
    paddingRight: 0
  }
};

const findConnection = (connections, id) => {
  return (
    connections.find(connection => connection.connection_company.id === id) ||
    connections.find(connection => connection.request_company.id === id)
  );
};

const getConnectionDate = connection => {
  const d = connection.updated_at;

  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear() - 2000}`;
};

class ConnectWithCompany extends React.Component {
  state = {
    connectWithDialog: false,
    removeConnectionDialog: false
  };

  shouldRender = () => {
    const { company, myCompany } = this.props;

    // don't display for Guests
    if (!myCompany || !myCompany.id) return false;

    // don't display during loading
    if (!company || !company.id) return false;

    // don't display for user's own company
    if (myCompany.id === company.id) return false;

    return true;
  };

  renderConnectButton = () => {
    return (
      <div>
        {this.state.connectWithDialog && this.renderDialog()}
        <PrimaryButtonGreen
          onClick={() => this.setState({ connectWithDialog: true })}
        >
          Connect
        </PrimaryButtonGreen>
      </div>
    );
  };

  renderDialog = () => {
    const { company } = this.props;

    return (
      <ConnectWithDialogContainer
        key={`${Math.random()}`}
        company={company}
        onClose={() => {
          this.setState({ connectWithDialog: false });
        }}
      />
    );
  };

  renderRemoveConnectionDialog = () => {
    const { company } = this.props;
    const connection = this.state.removeConnectionDialog;

    return (
      <RemoveConnectionDialogContainer
        key={`${Math.random()}`}
        company={company}
        connection={connection}
        onClose={() => {
          this.setState({ removeConnectionDialog: false });
        }}
      />
    );
  };

  renderMessage = connection => {
    const { is_approved } = connection;

    if (is_approved === 0) return this.renderMessageRequest(connection);
    if (is_approved === 1) return this.renderMessageRequest(connection);
    if (is_approved === 2) return this.renderMessageConnected(connection);

    return "";
  };

  renderMessageConnected = connection => {
    return (
      <React.Fragment>
        Connected on {getConnectionDate(connection)}.
      </React.Fragment>
    );
  };

  renderMessageRequest = connection => {
    const { myCompany } = this.props;

    // request_company is the company __requesting__ the connection
    // connection_company is __receiving__ the request for connection
    const { request_company: fromCompany } = connection;

    if (fromCompany.id === myCompany.id) {
      return "Connection request sent.";
    } else {
      const title = `${fromCompany.name} wants to connect.`;
      return (
        <ConnectionLink connection={connection} title={title}>
          {title}
        </ConnectionLink>
      );
    }
  };

  renderSubMenu = connection => {
    const { classes } = this.props;

    const accountActions = [
      {
        label: "Remove Connection",
        action: () => this.setState({ removeConnectionDialog: connection })
      }
    ];

    return (
      <React.Fragment>
        {this.state.removeConnectionDialog &&
          this.renderRemoveConnectionDialog()}
        <SubMenuButton
          classes={{ root: classes.submenu }}
          actions={accountActions}
          buttonProps={{
            classes: { root: classes.submenuButton }
          }}
        >
          <MoreIcon />
          <ChevronDown />
        </SubMenuButton>
      </React.Fragment>
    );
  };

  render() {
    const { classes, company, connections } = this.props;

    if (!this.shouldRender()) return null;

    // while connections are still loading
    if (connections.loading)
      return (
        <div className={classes.connection}>
          <span className={classes.loading}>loading status ...</span>
        </div>
      );

    // check if we have an existing connection
    const connection = findConnection(connections, company.id);

    if (connection)
      return (
        <div className={classes.connection}>
          {connection.is_approved === 2 && this.renderSubMenu(connection)}
          <PersonAddIcon className={classes.icon} />
          {this.renderMessage(connection)}
        </div>
      );

    // if there is no existing connection, show "Connect with ..."
    return this.renderConnectButton();
  }
}

ConnectWithCompany.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  connections: PropTypes.array.isRequired,
  myCompany: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    myCompany: state.company,
    connections: state.connections,
    profile: state.profile
  };
};

export { ConnectWithCompany, styles };
export default connect(mapStateToProps)(withStyles(styles)(ConnectWithCompany));
