import memoizeOne from "memoize-one";

import IconDone from "@material-ui/icons/Done";
import IconBranding from "@material-ui/icons/AccountBalance";
import IconEthos from "@material-ui/icons/FormatAlignLeft";
import IconDetails from "@material-ui/icons/LocationCity";
import IconInvite from "@material-ui/icons/PersonAdd";
import IconFinance from "@material-ui/icons/AttachMoney";
import IconPitch from "@material-ui/icons/FormatAlignLeft";

export const brandingIsComplete = company => {
  const { logo_image_url, mission_statement } = company;

  return Boolean(logo_image_url && mission_statement);
};

export const detailsAreComplete = company => {
  const { description, location, markets, website } = company;

  const city = location && location.city;
  const _markets = markets && markets.length > 0;

  // do not require size check for corporates
  const size = company.is_corporate || company.size;

  return Boolean(city && description && _markets && size && website);
};

export const fundingIsComplete = company => {
  const { funding_rounds, revenue_range, capital_to_date } = company;

  return Boolean(funding_rounds && revenue_range && capital_to_date);
};

export const hasMultipleManagers = company => {
  const managers = company.managers || [];

  return managers.length > 1;
};

export const hasMultipleProfileSections = company => {
  const profile = company.profile || [];

  return profile.length > 1;
};

export const _corporateProfileCompletion = company => {
  return [
    {
      label: "Create Profile",
      link: "/mycompany/edit",
      icon: IconDone,
      completed: true
    },
    brandingIsComplete(company)
      ? null
      : {
          label: "Complete branding",
          link: "/mycompany/edit#branding",
          icon: IconBranding,
          completed: false
        },
    {
      label: "Add ethos to innovation",
      link: "/mycompany/edit#ethos",
      icon: IconEthos,
      completed: Boolean(company.ethos)
    },
    {
      label: "Complete company details",
      link: "/mycompany/edit",
      icon: IconDetails,
      completed: detailsAreComplete(company)
    },
    {
      label: "Invite a team member",
      link: "/mycompany/edit#team",
      icon: IconInvite,
      completed: hasMultipleManagers(company)
    }
  ].filter(section => section);
};

export const _founderProfileCompletion = company => {
  return [
    {
      label: "Create Profile",
      link: "/mycompany/edit",
      icon: IconDone,
      completed: true
    },
    brandingIsComplete(company)
      ? null
      : {
          label: "Complete Branding",
          link: "/mycompany/edit#branding",
          icon: IconBranding,
          completed: false
        },
    {
      label: "Add company pitch",
      link: "/mycompany/edit#pitch",
      icon: IconPitch,
      completed: hasMultipleProfileSections(company)
    },
    {
      label: "Invite a team member",
      link: "/mycompany/edit#team",
      icon: IconInvite,
      completed: hasMultipleManagers(company)
    },
    {
      label: "Complete company details",
      link: "/mycompany/edit",
      icon: IconDetails,
      completed: detailsAreComplete(company)
    },
    {
      label: "Add funding information",
      link: "/mycompany/edit#funding",
      icon: IconFinance,
      completed: fundingIsComplete(company)
    }
  ].filter(section => section);
};

// export memoized functions for efficiency
export const corporateProfileCompletion = memoizeOne(
  _corporateProfileCompletion
);
export const founderProfileCompletion = memoizeOne(_founderProfileCompletion);

// check for completeness of company profile
export const getProfileCompletionState = company => {
  if (company.is_corporate) return corporateProfileCompletion(company);
  if (company.is_founder) return founderProfileCompletion(company);

  return [];
};
