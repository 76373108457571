export const benefitTypes = {
  other: "Other",
  funding: "Funding",
  training: "Training",
  mentorship: "Mentorship",
  office_space: "Office Space"
};

export const benefitsKeyMap = {
  "Collaboration / Partnership": "collaboration",
  Equity: "equity",
  Other: "other",
  Funding: "funding",
  Training: "training",
  Mentorship: "mentorship",
  Offer: "offer",
  "Office Space": "office_space",
  "Prize Money": "prize_money"
};

export const documentTypeLabels = {
  accelerator: "Accelerator programme",
  challenge: "Business challenge",
  competition: "Competition",
  hackathon: "Hackathon",
  incubator: "Incubator",
  mentorship: "Mentorship",
  proposal: "Request for Proposal (RFP)",
  // open_inno: "Open Innovation Platform",
  other: "Other"
  // phone: "Conference Call",
  // physical: "Location",
  // video: "Video Conference Call"
};

export const documentTypeLabelsShort = {
  accelerator: "Accelerator",
  challenge: "Challenge",
  competition: "Competition",
  hackathon: "Hackathon",
  incubator: "Incubator",
  mentorship: "Mentorship",
  proposal: "RFP",
  // open_inno: "Open Innovation",
  other: "Other",
  phone: "Conf. Call",
  physical: "Location",
  video: "Video Conf."
};

export const program_type_options = {
  options: [
    "accelerator",
    "challenge",
    "competition",
    "hackathon",
    "incubator",
    "proposal",
    "other"
  ],
  labels: [
    "Accelerator programme",
    "Business challenge",
    "Competition",
    "Hackathon",
    "Incubator",
    "Request for Proposal (RFP)",
    "Other"
  ]
};

export const think_tank_type_options = {
  options: ["phone", "physical", "video"],
  labels: ["Conference Call", "Meet Up", "Video Conference Call"]
};
