import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  clearValidationError,
  clearValidationErrors,
  setValidationError,
  setValidationErrors
} from "../../Reducers/ValidationErrors";

import ErrorPanel from "../common/Errors/ErrorPanel";
import LoadingPanel from "../common/LoadingPanel";

import FormBuilder from "./FormBuilder";

class _FormBuilderContainer extends React.Component {
  static propTypes = {
    createFlashNote: PropTypes.func.isRequired,

    // doctype specific functions
    createNewDocument: PropTypes.func.isRequired,
    duplicateDocument: PropTypes.func,
    deleteDocument: PropTypes.func,
    loadDocument: PropTypes.func.isRequired,
    updateDocument: PropTypes.func.isRequired,
    documentProp: PropTypes.string,

    // router props
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      hash: PropTypes.string.isRequired
    }).isRequired,
    match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired,

    // form pages config
    menuId: PropTypes.string.isRequired,
    pages: PropTypes.array.isRequired,

    // validationErrors display
    setValidationErrors: PropTypes.func.isRequired,
    validationErrors: PropTypes.object.isRequired
  };

  static defaultProps = {
    menuId: "formbuilder-menu",
    validationErrors: {}
  };

  state = {
    document: null,
    error: null,
    loading: true
  };

  componentDidMount() {
    return this.getBaseDocument()
      .then(json => {
        const { error, ...document } = json;

        if (error) throw error;

        return document;
      })
      .then(document => {
        const hideStepperIcons = document.is_approved === 1 ? false : true;

        this.setState({ document, hideStepperIcons, loading: false });
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  }

  componentWillReceiveProps(nextProps) {
    const { documentProp } = this.props;

    if (!documentProp) return;

    if (nextProps[documentProp] !== this.props[documentProp]) {
      const document = nextProps[documentProp];

      this.setState({ document });
    }
  }

  deleteDocument = () => {
    const { createFlashNote, history } = this.props;
    const { document } = this.state;

    if (!this.props.deleteDocument || !document) return;

    // return promise here just to be able to test it ...
    return this.props
      .deleteDocument(document.id)
      .then(result => {
        const { error } = result;

        if (error) {
          createFlashNote(error.message, "error");
          return;
        }

        createFlashNote(`${document.doctype} deleted`);
        history.push(`/${document.doctype}s`);
      })
      .catch(error => {
        createFlashNote(error.message, "error");
      });
  };

  getBaseDocument = () => {
    const { pathname } = this.props.location;
    const { documentId } = this.props.match.params;

    if (pathname.endsWith("new") || pathname.endsWith("build")) {
      return this.props.createNewDocument().then(json => {
        return { _isUnsavedDraft: true, ...json };
      });
    } else {
      return this.props.loadDocument(documentId);
    }
  };

  updateDocument = data => {
    const { document } = this.state;

    if (!document) return;

    // return promise here just to be able to test it ...
    return this.props
      .updateDocument(data)
      .then(result => {
        const { error, validationErrors } = result;

        if (error) {
          this.props.createFlashNote(error.message, "error");
        } else {
          const message = `The ${document.doctype} has been saved.`;
          this.props.createFlashNote(message, "success");
        }

        this.props.setValidationErrors(validationErrors || {});
        return result;
      })
      .catch(error => {
        this.props.createFlashNote(error.message, "error");
        return { error };
      });
  };

  render() {
    const { createNewDocument, loadDocument, ...otherProps } = this.props;
    const { error, loading, document, hideStepperIcons } = this.state;

    if (error) {
      return <ErrorPanel error={error} />;
    }

    if (loading) {
      return <LoadingPanel />;
    }

    const deleteDocument = this.props.deleteDocument
      ? this.deleteDocument
      : null;

    return (
      <FormBuilder
        {...otherProps}
        hideStepperIcons={hideStepperIcons}
        document={document}
        deleteDocument={deleteDocument}
        updateDocument={this.updateDocument}
        redirectOnPublish={true}
      />
    );
  }
}

const mapStateToProps = state => ({ validationErrors: state.validationErrors });
const mapDispatchToProps = dispatch => {
  return {
    clearValidationError: (id, msg) => dispatch(clearValidationError(id, msg)),
    clearValidationErrors: () => dispatch(clearValidationErrors()),
    setValidationError: (id, msg) => dispatch(setValidationError(id, msg)),
    setValidationErrors: errors => dispatch(setValidationErrors(errors))
  };
};

const FormBuilderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(_FormBuilderContainer);

export { _FormBuilderContainer };
export default FormBuilderContainer;
