import React from "react";

import { withStyles } from "@material-ui/core/styles";

import ConnectionsListContainer from "../Connections/ConnectionsListContainer";

import FounderActivities from "./FounderActivities";

const styles = {
  card: {
    backgroundColor: "white",
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
    marginTop: 40,
    "&:last-child": {
      marginBottom: 60
    }
  }
};

const FounderDashboard = withStyles(styles)(props => {
  const { classes } = props;

  return (
    <React.Fragment>
      <div className={`body ${classes.card}`}>
        <FounderActivities />
      </div>

      <div className={`body ${classes.card}`}>
        <ConnectionsListContainer />
      </div>
    </React.Fragment>
  );
});

export default FounderDashboard;
