import React from "react";
import PropTypes from "prop-types";

import GroupButton from "./GroupButton";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center"
  },
  button: {},
  disabled: {},
  selected: {}
};

class ButtonGroup extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    maxSelections: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    state: PropTypes.string,
    style: PropTypes.object
  };

  static defaultProps = {
    maxSelections: 1,
    style: {}
  };

  constructor(props) {
    super(props);

    const { options, state } = props;

    this.state = {
      selected: options.map(o => Boolean(state) && o === state)
    };
  }

  toggleOption = index => {
    const { maxSelections } = this.props;
    let { selected } = this.state;

    if (maxSelections === 1) {
      // toggle selected and reset all other options
      selected = this.state.selected.map(
        (s, idx) => (idx === index ? !s : false)
      );
    } else {
      selected = this.state.selected.map((s, idx) => (idx === index ? !s : s));
    }

    this.setState({ selected }, this.onChange);
  };

  onChange = () => {
    const selection = this.props.options.filter(
      (o, index) => this.state.selected[index]
    );

    this.props.onChange(selection);
  };

  render() {
    const { classes, maxSelections, options, style } = this.props;
    const { selected } = this.state;
    const nSelected = selected.filter(s => s).length;

    // check if unselected buttons should be disabled
    const disabledIfUnselected =
      maxSelections !== 1 && nSelected === maxSelections;

    return (
      <div className={classes.root} style={style}>
        {options.map((option, index) => {
          const _selected = Boolean(selected[index]);

          const onClick =
            _selected || !disabledIfUnselected
              ? () => this.toggleOption(index)
              : null;

          return (
            <GroupButton
              key={option}
              classes={classes}
              disabled={!selected[index] && disabledIfUnselected}
              onClick={onClick}
              selected={_selected}
            >
              {option}
            </GroupButton>
          );
        })}
      </div>
    );
  }
}

export { ButtonGroup, GroupButton, styles };
export default withStyles(styles)(ButtonGroup);
