/* eslint jsx-a11y/anchor-has-content: 0 */
import React from "react";

const TermsOfUse = props => {
  return (
    <div>
      <section className="page-header-aligned-left">
        <h1>
          Colinked <span>Terms of Use</span>
        </h1>
      </section>

      <section className="content-padding-small">
        <section className="content">
          <h2>
            Please read these terms and conditions carefully before using this
            site
          </h2>

          <h3>What's in these terms?</h3>

          <p>
            These terms tell you the rules for using our website colinked.io{" "}
            <strong>(our site)</strong>. Click on the links below to go straight
            to more information on each area:
          </p>

          <ul>
            <li>
              <a href="#who-we-are">Who we are and how to contact us</a>
            </li>
            <li>
              <a href="#accept-these-terms">
                By using our site you accept these terms
              </a>
            </li>
            <li>
              <a href="#other-terms">
                There are other terms that may apply to you
              </a>
            </li>
            <li>
              <a href="#we-may-change-terms">
                We may make changes to these terms
              </a>
            </li>
            <li>
              <a href="#we-may-change-site">We may make changes to our site</a>
            </li>
            <li>
              <a href="#we-may-withdraw-site">
                We may suspend or withdraw our site
              </a>
            </li>
            <li>
              <a href="#you-must-keep-details-safe">
                You must keep your account details safe
              </a>
            </li>
            <li>
              <a href="#how-to-use-site-material">
                How you may use material on our site
              </a>
            </li>
            <li>
              <a href="#do-not-rely-on-site-info">
                Do not rely on information on our site
              </a>
            </li>
            <li>
              <a href="#user-content-is-not-approved">
                User-generated content is not approved by us
              </a>
            </li>
            <li>
              <a href="#loss-or-damage-suffered">
                When we are responsible for loss or damage suffered by you
              </a>
            </li>
            <li>
              <a href="#using-personal-info">
                How we may use your personal information
              </a>
            </li>
            <li>
              <a href="#uploading-content">
                Rules about uploading content to our site
              </a>
            </li>
            <li>
              <a href="#upload-rights-you-are-giving">
                Rights you are giving us to use material you upload
              </a>
            </li>
            <li>
              <a href="#viruses-introduced">
                We are not responsible for viruses and you must not introduce
                them
              </a>
            </li>
            <li>
              <a href="#linking-to-our-site">Rules about linking to our site</a>
            </li>
            <li>
              <a href="#country-disputes">
                Which country's laws apply to any disputes
              </a>
            </li>
            <li>
              <a href="#trade-marks">Our trade marks are registered</a>
            </li>
          </ul>

          <h2 id="who-we-are">Who we are and how to contact us</h2>

          <p>
            colinked.io is a site operated by Whitespace Ventures Limited
            ("We"). We are registered in England and Wales under company number
            09354350 and have our registered office at 58 City Road, Runway
            East, London, England, EC1Y 2AL. Our VAT number is 205970020.
          </p>

          <p>We are a limited company.</p>

          <p>
            To contact us, please email{" "}
            <a href="mailto:support@colinked.io">support@colinked.io</a>
          </p>

          <h2 id="accept-these-terms">
            By using our site you accept these terms
          </h2>

          <p>
            By using our site, you confirm that you accept these terms of use
            and that you agree to comply with them.
          </p>

          <p>If you do not agree to these terms, you must not use our site.</p>

          <p>
            We recommend that you print a copy of these terms for future
            reference.
          </p>

          <h2 id="other-terms">There are other terms that may apply to you</h2>

          <p>
            These terms of use refer to the following additional terms, which
            also apply to your use of our site:
          </p>

          <ul>
            <li>
              Our <a href="/privacy_policy">Privacy Policy</a>
            </li>
            <li>
              Our <a href="/terms_of_use">Acceptable Use Policy</a>, which sets
              out the permitted uses and prohibited uses of our site. When using
              our site, you must comply with this Acceptable Use Policy.
            </li>
            <li>
              This site does not use cookies however it does include links to
              third party websites which may use cookies. If you click on these
              links you should ensure that you read the cookie policy relating
              to that site.{" "}
            </li>
          </ul>

          <p>
            If you purchase services from our site, our{" "}
            <a href="/terms_of_use">Terms and Conditions</a> of supply will
            apply to the sales.
          </p>

          <h2 id="we-may-change-terms">We may make changes to these terms</h2>

          <p>
            We amend these terms from time to time. Every time you wish to use
            our site, please check these terms to ensure you understand the
            terms that apply at that time. These terms were most recently
            updated on 26th June 2018.
          </p>

          <h2 id="we-may-change-site">We may make changes to our site</h2>

          <p>
            We may update and change our site from time to time to reflect
            changes to our products and services, our users' needs and our
            business priorities. We will try to give you reasonable notice of
            any major changes.
          </p>

          <h2 id="we-may-withdraw-site">We may suspend or withdraw our site</h2>

          <p>Our site is made available free of charge.</p>

          <p>
            We do not guarantee that our site, or any content on it, will always
            be available or be uninterrupted. We may suspend or withdraw or
            restrict the availability of all or any part of our site for
            business and operational reasons. We will try to give you reasonable
            notice of any suspension or withdrawal.
          </p>

          <p>
            You are also responsible for ensuring that all persons who access
            our site through your internet connection are aware of these terms
            of use and other applicable terms and conditions, and that they
            comply with them.
          </p>

          <h2 id="you-must-keep-details-safe">
            You must keep your account details safe
          </h2>

          <p>
            If you choose, or you are provided with, a user identification code,
            password or any other piece of information as part of our security
            procedures, you must treat such information as confidential. You
            must not disclose it to any third party.
          </p>

          <p>
            We have the right to disable any user identification code or
            password, whether chosen by you or allocated by us, at any time, if
            in our reasonable opinion you have failed to comply with any of the
            provisions of these terms of use.
          </p>

          <p>
            If you know or suspect that anyone other than you knows your user
            identification code or password, you must promptly notify us at{" "}
            <a href="mailto:gdpr@colinked.io">gdpr@colinked.io</a>
          </p>

          <h2 id="how-to-use-site-material">
            How you may use material on our site
          </h2>

          <p>
            We are the owner or the licensee of all intellectual property rights
            in our site, and in the material published on it. Those works are
            protected by copyright laws and treaties around the world. All such
            rights are reserved.
          </p>

          <p>
            You may print off one copy, and may download extracts, of any
            page(s) from our site for your personal use and you may draw the
            attention of others within your organisation to content posted on
            our site.
          </p>

          <p>
            You must not modify the paper or digital copies of any materials you
            have printed off or downloaded in any way, and you must not use any
            illustrations, photographs, video or audio sequences or any graphics
            separately from any accompanying text.
          </p>

          <p>
            Our status (and that of any identified contributors) as the authors
            of content on our site must always be acknowledged.
          </p>

          <p>
            You must not use any part of the content on our site for commercial
            purposes without obtaining a licence to do so from us or our
            licensors.
          </p>

          <p>
            If you print off, copy or download any part of our site in breach of
            these terms of use, your right to use our site will cease
            immediately and you must, at our option, return or destroy any
            copies of the materials you have made.
          </p>

          <h2 id="do-not-rely-on-site-info">
            Do not rely on information on this site
          </h2>

          <p>
            The content on our site is provided for general information only. It
            is not intended to amount to advice on which you should rely. You
            must obtain professional or specialist advice before taking, or
            refraining from, any action on the basis of the content on our site.
          </p>

          <p>
            Although we make reasonable efforts to update the information on our
            site, we make no representations, warranties or guarantees, whether
            express or implied, that the content on our site is accurate,
            complete or up to date.
          </p>

          <h3>We are not responsible for websites we link to</h3>

          <p>
            Where our site contains links to other sites and resources provided
            by third parties, these links are provided for your information
            only. Such links should not be interpreted as approval by us of
            those linked websites or information you may obtain from them.
          </p>

          <p>
            We have no control over the contents of those sites or resources.
          </p>

          <h2 id="user-content-is-not-approved">
            User-generated content is not approved by us
          </h2>

          <p>
            This website may include information and materials uploaded by other
            users of the site, including to bulletin boards and chat rooms. This
            information and these materials have not been verified or approved
            by us. The views expressed by other users on our site do not
            represent our views or values.
          </p>

          <p>
            If you wish to complain about information and materials uploaded by
            other users please contact us by emailing{" "}
            <a href="mailto:support@colinked.io">support@colinked.io</a>
          </p>

          <h2 id="loss-or-damage-suffered">
            Our responsibility for loss or damage suffered by you
          </h2>

          <p>Whether you are a consumer or a business user:</p>

          <ul>
            <li>
              We do not exclude or limit in any way our liability to you where
              it would be unlawful to do so. This includes liability for death
              or personal injury caused by our negligence or the negligence of
              our employees, agents or subcontractors and for fraud or
              fraudulent misrepresentation.
            </li>

            <li>
              Different limitations and exclusions of liability will apply to
              liability arising as a result of the supply of any products to
              you, which will be set out in our Terms and Conditions of supply
            </li>
          </ul>

          <p>If you are a business user:</p>

          <ul>
            <li>
              We exclude all implied conditions, warranties, representations or
              other terms that may apply to our site or any content on it.
            </li>

            <li>
              We will not be liable to you for any loss or damage, whether in
              contract, tort (including negligence), breach of statutory duty,
              or otherwise, even if foreseeable, arising under or in connection
              with
            </li>

            <ul>
              <li>use of, or inability to use, our site; or</li>

              <li>use of or reliance on any content displayed on our site.</li>
            </ul>

            <li>In particular, we will not be liable for:</li>
            <ul>
              <li>loss of profits, sales, business, or revenue;</li>
              <li>business interruption;</li>
              <li>loss of anticipated savings;</li>
              <li>loss of business opportunity, goodwill or reputation; or</li>
              <li>any indirect or consequential loss or damage.</li>
            </ul>
          </ul>

          <p>If you are a consumer user:</p>

          <ul>
            <li>
              Please note that this site is not intended for use by consumer
              users and consumer users should not use this website.
            </li>
          </ul>

          <h2 id="using-personal-info">
            How we may use your personal information
          </h2>

          <p>
            We will only use your personal information as set out in our{" "}
            <a target="_blank" href="/privacy_policy">
              privacy policy
            </a>
            .
          </p>

          <h2 id="uploading-content">Uploading content to our site</h2>

          <p>
            Whenever you make use of a feature that allows you to upload content
            to our site, or to make contact with other users of our site, you
            must comply with the content standards set out in our{" "}
            <a target="_blank" href="/terms_of_use">
              Acceptable Use Policy
            </a>
            .
          </p>

          <p>
            You warrant that any such contribution does comply with those
            standards, and you will be liable to us and indemnify us for any
            breach of that warranty. This means you will be responsible for any
            loss or damage we suffer as a result of your breach of warranty.
          </p>

          <p>
            Any content you upload to our site will be considered
            non-confidential and non-proprietary. You retain all of your
            ownership rights in your content, but you are required to grant us
            and other users of our site a limited licence to use, store and copy
            that content and to distribute and make it available to third
            parties.
          </p>

          <p>
            We also have the right to disclose your identity to any third party
            who is claiming that any content posted or uploaded by you to our
            site constitutes a violation of their intellectual property rights,
            or of their right to privacy.
          </p>

          <p>
            We have the right to remove any posting you make on our site if, in
            our opinion, your post does not comply with the content standards
            set out in our{" "}
            <a target="_blank" href="/acceptable_use_policy">
              Acceptable Use Policy
            </a>
            .
          </p>

          <p>
            You are solely responsible for securing and backing up your content.
          </p>

          <h2 id="upload-rights-you-are-giving">
            Rights you are giving us to use material you upload
          </h2>

          <p>
            When you upload or post content to our site, you grant us and other
            users of our site, a non-exclusive, worldwide, transferable and
            sublicensable right to use, copy, modify, distribute, publish, and
            process, information and content that you provide through our
            services and the services of others, without any further consent,
            notice and/or compensation to you or others. These rights are
            limited in the following ways:
          </p>

          <ul>
            <li>
              You can end this license for specific content by deleting such
              content from site, or generally by closing your account, except
              (a) to the extent you shared it with others through the site and
              they copied, re-shared it or stored it and (b) for the reasonable
              time it takes to remove from backup and other systems.
            </li>

            <li>
              We will not include your content in advertisements for the
              products and services of third parties to others without your
              separate consent (including sponsored content). However, we have
              the right, without payment to you or others, to serve adverts near
              your content and information, and your social actions may be
              visible and included with ads, as noted in the{" "}
              <a target="_blank" href="/privacy_policy">
                Privacy Policy
              </a>
              .
            </li>

            <li>
              We will get your consent if we want to give others the right to
              publish your content beyond the site.
            </li>

            <li>
              We will not modify your content but reserve the right to delete it
              if it breaches out{" "}
              <a target="_blank" href="/acceptable_use_policy">
                acceptable use policy
              </a>
              .
            </li>
          </ul>

          <p>
            If content includes personal data, it is subject to our{" "}
            <a target="_blank" href="/privacy_policy">
              Privacy Policy
            </a>
            .
          </p>

          <p>
            We may access, store, process and use any information and personal
            data that you provide in accordance with the terms of the{" "}
            <a target="_blank" href="/privacy_policy">
              Privacy Policy
            </a>{" "}
            and your choices (including settings).
          </p>

          <p>
            By submitting suggestions or other feedback regarding the site to
            Whitespace, you agree that We can use and share (but does not have
            to) such feedback for any purpose without compensation to you.
          </p>

          <p>
            You agree that you will only share information and content on the
            site if you have the right to share it.
          </p>

          <p id="viruses-introduced">
            You agree to only provide content or information that does not
            breach the law nor anyone’s rights (including intellectual property
            rights). You also agree that information you provide will be
            truthful. We may be required by law to remove certain information or
            content in certain countries.{" "}
            <strong>
              We are not responsible for viruses and you must not introduce them
            </strong>
            .
          </p>

          <p>
            We do not guarantee that our site will be secure or free from bugs
            or viruses.
          </p>

          <p>
            You are responsible for configuring your information technology,
            computer programmes and platform to access our site. You should use
            your own virus protection software.
          </p>

          <p>
            You must not misuse our site by knowingly introducing viruses,
            trojans, worms, logic bombs or other material that is malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to our site, the server on which our site is stored or any
            server, computer or database connected to our site. You must not
            attack our site via a denial-of-service attack or a distributed
            denial-of service attack. We will report any such breach to the
            relevant law enforcement authorities and we will cooperate with
            those authorities by disclosing your identity to them. In the event
            of such a breach, your right to use our site will cease immediately.
          </p>

          <h2 id="linking-to-our-site">Rules about linking to our site</h2>

          <p>
            You may link to our home page, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it.
          </p>

          <p>
            You must not establish a link in such a way as to suggest any form
            of association, approval or endorsement on our part where none
            exists.
          </p>

          <p>
            You must not establish a link to our site in any website that is not
            owned by you.
          </p>

          <p>
            Our site must not be framed on any other site, nor may you create a
            link to any part of our site other than the home page.
          </p>

          <p>
            We reserve the right to withdraw linking permission without notice.
          </p>

          <p>
            The website in which you are linking must comply in all respects
            with the content standards set out in our{" "}
            <a target="_blank" href="/acceptable_use_policy">
              Acceptable Use Policy
            </a>
            .
          </p>

          <p>
            If you wish to link to or make any use of content on our site other
            than that set out above, please contact{" "}
            <a href="mailto:support@colinked.io">support@colinked.io</a>
          </p>

          <h2 id="country-disputes">
            Which country's laws apply to any disputes?
          </h2>

          <p>
            If you are a consumer, please note that these terms of use, their
            subject matter and their formation, are governed by English law. You
            and we both agree that the courts of England and Wales will have
            exclusive jurisdiction.
          </p>

          <p>
            If you are a business, these terms of use, their subject matter and
            their formation (and any non-contractual disputes or claims) are
            governed by English law. We both agree to the exclusive jurisdiction
            of the courts of England and Wales.
          </p>

          <h2 id="trade-marks">Our trademarks are registered</h2>

          <p>
            "Whitespace" and “Whitespace Ventures” are UK registered trademarks
            of Whitespace Ventures Limited. You are not permitted to use them
            without our approval. You may request approval at{" "}
            <a href="mailto:support@colinked.io">support@colinked.io</a>
          </p>
        </section>
      </section>

      <section className="call-to-action">
        <div className="container">
          <div className="content">
            <h2>Our Valued Members</h2>
            <p>
              In our beta, we signed up some of the biggest corporates and
              brightest founder teams, you can join our movement here:
            </p>
            <button className="btn">For Corporates</button>{" "}
            <button className="btn">For Founders</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsOfUse;
