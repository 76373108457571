import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import LocalLink from "../common/LocalLink";
import LogoImage from "../common/LogoImage";
import PlaceholderImage from "../../assets/corp_logo_placeholder.png";

const styles = {
  root: {
    //outline: "1px solid orange",
    display: "flex",
    marginTop: 50,
    "&:last-child": {
      marginBottom: 50
    }
  },
  article: {
    width: "100%",
    marginRight: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  about: {
    color: "#2A2B2C",
    fontSize: 13,
    fontWeight: 600,
    marginTop: 10
  },
  articleText: {
    maxWidth: 800,
    marginTop: 15,
    fontSize: 14,
    lineHeight: "17px",
    color: "#2A2B2C"
  },
  large: {},
  link: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  small: {
    height: 34,
    overflow: "hidden"
  },
  title: {
    maxWidth: 800,
    color: "#3D5A80",
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: 600,
    marginBottom: 0,
    paddingTop: 0
  },
  author: {
    display: "inline-block",
    marginRight: "8px"
  },
  published: {
    display: "inline-block",
    marginLeft: "8px"
  },
  logoRoot: {
    marginRight: 30
  }
};

const getInnerHTML = text => {
  return { __html: text };
};

const NewsArticle = props => {
  const {
    classes,
    name,
    author,
    thumbnail_image_url,
    published_at,
    description,
    // size, // TODO
    width
  } = props;

  const size = "large"; // TODO: removed fixed article size
  const publishedDate = new Date(published_at);
  const articleClass = `${classes.articleText} ${classes[size]}`;

  const title =
    size === "large" ? (
      name
    ) : (
      <LocalLink label={name} link="/news" className={classes.link} />
    );

  return (
    <div className={classes.root}>
      {width !== "xs" && (
        <LogoImage
          classes={{ root: classes.logoRoot }}
          url={thumbnail_image_url || PlaceholderImage}
          size={100}
          margin={0}
        />
      )}
      <div className={classes.article}>
        <Typography variant="display2" className={classes.title}>
          {title}
        </Typography>
        <div
          className={articleClass}
          dangerouslySetInnerHTML={getInnerHTML(description)}
        />
        <Typography variant="caption" component="p" className={classes.about}>
          <span className={classes.author}>{author}</span>
          &bull;
          <span className={classes.published}>
            {publishedDate.toDateString()}
          </span>
        </Typography>
      </div>
    </div>
  );
};

NewsArticle.propTypes = {
  name: PropTypes.string,
  author: PropTypes.string,
  published_at: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"])
};

NewsArticle.defaultProps = {
  name: "News Article Headline is large",
  author: { name: "Issam Abu-Aisheh" },
  published: new Date().toISOString(),
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.. ",
  size: "large"
};

export default withWidth()(withStyles(styles)(NewsArticle));
