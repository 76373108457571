import React from "react";
import { PropTypes } from "prop-types";

import Dropzone from "react-dropzone";

import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";

import { uploadImage } from "../../../api/tokenApi";
import { responseJsonOrError } from "../../../api/response";

import ErrorPanel from "../../common/Errors/ErrorPanelSmall";

import Controls from "./Controls";
import { styles as baseStyles } from "./BaseFrame";

const clickFrame = {
  height: 120,
  margin: -20,
  marginTop: 0,

  // font style
  color: "#2A2B2C",
  fontSize: 12,
  fontWeight: 300,

  // cursor
  cursor: "pointer",

  // center content
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
};

const styles = {
  ...baseStyles,
  rootContent: {
    ...baseStyles.root,
    border: "none"
  },

  clickFrame: {
    ...clickFrame,
    "&:hover": {
      backgroundColor: "#F2F3F4"
    }
  },
  dropFrame: {
    ...clickFrame,
    margin: 0,
    width: "100%"
  },
  dropFrameActive: {
    ...clickFrame,
    backgroundColor: "#F2F3F4",
    margin: 0
  },

  errorRoot: {
    marginBottom: 10
  },
  errorTitle: {
    padding: 5
  },
  errorMessage: {
    padding: 5
  },

  blue: {
    color: "#398DD3",
    marginLeft: "0.2em",
    marginRight: "0.2em",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  image: {
    minHeight: 80,
    margin: -20,
    marginTop: -50
  },

  spinner: {
    color: "white",
    fontSize: 30,
    height: "100%",

    // center content
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  spinnerIcon: {
    fontSize: 48,
    marginRight: 10,
    animation: "buttonspin infinite 2s linear"
  },
  "@keyframes buttonspin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" }
  },

  typeContent: {
    ...baseStyles.type,
    color: "white"
  }
};

class ImageFrame extends React.Component {
  static propTypes = {
    allowedFileTypes: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    content: PropTypes.string,
    displayFileTypes: PropTypes.array.isRequired,
    maxFileSize: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
  };

  static defaultProps = {
    allowedFileTypes: ["image/jpeg", "image/png", "image/gif"],
    displayFileTypes: ["JPEG", "PNG", "GIF"],
    maxFileSize: 2
  };

  constructor(props) {
    super(props);

    this.id = `id_${Math.random()}`;

    this.state = {
      content: props.content,
      error: null,
      loading: null,
      height: 80
    };
  }

  componentDidMount() {
    const { content } = this.props;

    content && this.getImageHeight(content);
  }

  getImageHeight = url => {
    const frame = document.getElementById(this.id);

    if (!frame) return;

    const { offsetWidth } = frame;

    const img = new Image();
    img.onload = () => {
      const height = (offsetWidth / img.width) * img.height;

      this.setState({ height });
    };
    img.src = url;
  };

  handleDrop = files => {
    if (!files.length) return;

    const { error } = this.validateFile(files[0]);

    if (error) {
      this.setState({ error }, () =>
        setTimeout(() => this.removeError(error.message), 6000)
      );
    } else {
      this.readFileData(files[0]);
    }
  };

  readFileData = file => {
    const reader = new FileReader();

    reader.onload = event => {
      const dataURL = reader.result;

      this.setState({ loading: dataURL }, () => {
        this.getImageHeight(dataURL);
        this.uploadFile(dataURL, file);
      });
    };

    reader.readAsDataURL(file);
  };

  removeError = message => {
    const { error } = this.state;

    if (error && error.message === message) {
      this.setState({ error: null });
    }
  };

  startFileSelector = id => {
    const input = document.getElementById(id);
    if (!input) return;

    input.addEventListener("change", event =>
      this.handleDrop(event.target.files)
    );
    input.click();
  };

  uploadFile = (dataURL, file) => {
    const formData = new FormData();

    formData.append("image", file);
    formData.append("data", dataURL);
    formData.append("name", file.name);
    formData.append("type", file.type);

    uploadImage("/api/uploads/", {
      method: "POST",
      credentials: "same-origin",
      body: formData
    })
      .then(responseJsonOrError)
      .then(json => {
        const { error, url_filepath } = json;

        if (error) {
          throw error;
        }
        if (!url_filepath) {
          throw new Error("No url received from server.");
        }

        this.setState({ loading: null, content: url_filepath }, () => {
          this.getImageHeight(url_filepath);
          this.props.onChange({ content: url_filepath });
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error,
          loading: null
        });
      });
  };

  validateFile = file => {
    const { allowedFileTypes, maxFileSize } = this.props;

    if (allowedFileTypes.indexOf(file.type) === -1) {
      return {
        error: new Error(`Files of type '${file.type}' are not allowed.`)
      };
    }

    if (maxFileSize * 1024 * 1024 < file.size) {
      return {
        error: new Error(`File is too big. Max file size: ${maxFileSize} MB.`)
      };
    }

    return { error: null };
  };

  renderContentFrame = () => {
    const { classes, type } = this.props;
    const { height, loading } = this.state;

    const data = loading || this.state.content;

    const style = {
      backgroundImage: `url("${data}")`,
      backgroundSize: "cover",
      height,
      opacity: loading ? 0.5 : 1.0
    };

    return (
      <div className={classes.rootContent} id={this.id} style={style}>
        {this.renderControls({ backgroundColor: "white" })}

        <div className={classes.typeContent}>{type}</div>
        {loading && this.renderSpinner()}
      </div>
    );
  };

  renderControls = (style = {}) => {
    const { deleteFrame, moveDown, moveUp } = this.props;

    const controlProps = {
      deleteFrame,
      moveDown,
      moveUp
    };

    return <Controls {...controlProps} style={style} />;
  };

  renderSpinner = () => {
    const { classes } = this.props;

    return (
      <div className={classes.spinner}>
        <RefreshIcon className={classes.spinnerIcon} />
        uploading image ...
      </div>
    );
  };

  render() {
    const { classes, type } = this.props;
    const { content, error, loading } = this.state;

    if (content) return this.renderContentFrame();
    if (loading) return this.renderContentFrame();

    return (
      <div className={classes.root} id={this.id}>
        {this.renderControls()}

        <div className={classes.type}>{type}</div>
        <div
          className={classes.clickFrame}
          onClick={() => this.startFileSelector(`${this.id}-selector`)}
        >
          <Dropzone
            className={classes.dropFrame}
            activeClassName={classes.dropFrameActive}
            onDrop={this.handleDrop}
          >
            {error && (
              <ErrorPanel
                error={error}
                onClear={evt => {
                  evt.stopPropagation();

                  this.setState({ error: null });
                }}
              />
            )}

            <div>
              Drag here or <span className={classes.blue}>click to select</span>{" "}
              an image.
            </div>
            <div>{`(max file size: ${
              this.props.maxFileSize
            } MB, allowed file types: ${this.props.displayFileTypes} )`}</div>
          </Dropzone>
        </div>
        <input
          id={`${this.id}-selector`}
          type="file"
          style={{ display: "none" }}
        />
      </div>
    );
  }
}

export { ImageFrame as Image, styles };
export default withStyles(styles)(ImageFrame);
