import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import IconClose from "@material-ui/icons/PowerSettingsNew";
import IconGroup from "@material-ui/icons/People";
import IconPen from "@material-ui/icons/Create";
import IconSettings from "@material-ui/icons/Settings";
import IconTrend from "@material-ui/icons/TrendingUp";

import Icons from "../../Icons";

import ModalDialog from "../../common/Dialogs/ModalDialog";
import TagLabel from "../../common/TagLabel";

const rowStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: 14,
  lineHeight: "16px",

  borderBottom: "1px solid #E6E7E9"
};

const styles = {
  root: {
    backgroundColor: "white"
  },
  closed: {
    color: "#AAADB6"
  },
  icon: {
    marginRight: 10,
    fill: "#2A2B2C"
  },
  title: {
    ...rowStyle,
    fontWeight: 700,
    paddingBottom: 20
  },
  link: {
    ...rowStyle,
    color: "#6592CC",
    height: 40,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  linkDisabled: {
    ...rowStyle,
    color: "#AAADB6",
    height: 40
  },
  linksList: {
    margin: 0,
    padding: 0
  },
  open: {
    color: "#85C97C"
  }
};

const testFlash = (props, msg) => {
  const { createFlashNote, document } = props;

  const error = msg ? new Error(msg) : null;
  Promise.resolve({ error }).then(json => {
    if (json.error) {
      createFlashNote(json.error.message, "error");
    } else {
      createFlashNote(`The test for ${document.doctype} was successful.`);
    }
  });
};

const getStatus = document => {
  const { application, is_approved } = document;
  const deadline = application.deadline_datetime;

  if (is_approved === 1) {
    return "Draft";
  }

  if (deadline && new Date(deadline) < new Date()) {
    return "Closed";
  }

  return "Open";
};

class ManageLinks extends React.Component {
  static propTypes = {
    applications: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    createFlashNote: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    document: PropTypes.shape({
      doctype: PropTypes.oneOf(["program", "challenge"]).isRequired
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    loadApplications: PropTypes.func.isRequired,
    updateDocument: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      _deleteDialog: false
    };
  }

  componentDidMount() {
    const { document, loadApplications } = this.props;

    loadApplications(document.id);
  }

  closeForApplications = () => {
    const { createFlashNote, document, updateDocument } = this.props;

    const deadline_datetime = new Date().toISOString();

    const data = {
      id: document.id,
      application: {
        ...document.application,
        deadline_datetime
      }
    };

    updateDocument(data)
      .then(json => {
        if (json.error) {
          createFlashNote(json.error.message, "error");
        } else {
          createFlashNote(
            `The ${document.doctype} is now closed for applications.`
          );
        }
      })
      .catch(error => {
        createFlashNote(error.message, "error");
      });
  };

  deleteDocument = () => {
    const { createFlashNote, deleteDocument, document, history } = this.props;

    deleteDocument(document.id)
      .then(json => {
        if (json.error) {
          createFlashNote(json.error.message, "error");
        } else {
          createFlashNote(`The ${document.doctype} has been removed.`);

          history.push(`/${document.doctype}s`);
        }
      })
      .catch(error => {
        createFlashNote(error.message, "error");
      });
  };

  renderDeleteDialog = () => {
    const { document } = this.props;
    const { _deleteDialog } = this.state;

    if (!_deleteDialog) return null;

    return (
      <ModalDialog
        key={`${Math.random()}`}
        actions={[
          {
            label: "Cancel",
            onClick: () => this.setState({ _deleteDialog: false })
          },
          {
            label: "Delete",
            onClick: this.deleteDocument
          }
        ]}
        onClose={() => this.setState({ _deleteDialog: false })}
        type="warning"
        title={`Delete ${document.doctype}`}
        message={`Do you really want to delete this ${document.doctype}?`}
      />
    );
  };

  renderBadge = () => {
    const { classes, document } = this.props;

    const status = getStatus(document);
    const className = status === "Open" ? classes.open : classes.closed;

    if (document.is_approved === 1) {
      return <TagLabel label="Draft" color="red" solid={true} />;
    }

    // return "black circle" character with status
    return (
      <span className={className}>
        {"\u25CF "}
        {status}
      </span>
    );
  };

  renderManageApplicationsLink = () => {
    const { applications, classes, document, history } = this.props;
    const { doctype, id, is_approved } = this.props.document;

    if (is_approved === 1) return null;

    // filter applications by document and pending state
    const _doctype = doctype === "program" ? "programs" : "think_tanks";
    const docUrl = `/api/${_doctype}/${document.id}/`;
    const pendingAppCount = applications
      .filter(app => app.url.startsWith(docUrl))
      .filter(app => app.is_approved === 1).length;

    return (
      <li
        className={classes.link}
        onClick={() => history.push(`/${doctype}/${id}/applications`)}
      >
        <IconGroup className={classes.icon} />
        Manage Applications
        <TagLabel
          label={`${pendingAppCount}`}
          color={pendingAppCount ? "red" : "grey"}
          solid={true}
          style={{ marginLeft: 10 }}
        />
      </li>
    );
  };

  render() {
    const { classes, history } = this.props;
    const { doctype, id } = this.props.document;
    const doctypeCap = doctype === "program" ? "Program" : "Think Tank";

    const status = getStatus(this.props.document);

    return (
      <div className={classes.root}>
        {this.renderDeleteDialog()}
        <div className={classes.title}>
          <IconSettings className={classes.icon} />
          {`Manage ${doctypeCap}`}
          <div style={{ flexGrow: 1 }} />
          {this.renderBadge()}
        </div>

        <ul className={classes.linksList}>
          <li
            className={classes.link}
            onClick={() => history.push(`/${doctype}/${id}/edit`)}
          >
            <IconPen className={classes.icon} />
            {`Edit ${doctypeCap} details`}
          </li>

          {this.renderManageApplicationsLink()}

          {/* close for applications: only if currently open */}
          {status === "Open" && (
            <li className={classes.link} onClick={this.closeForApplications}>
              <IconClose className={classes.icon} />
              Close for Applications
            </li>
          )}

          {/* promotion */}
          {false && (
            <li
              className={classes.linkDisabled}
              style={{ opacity: 0.4 }}
              onClick={() =>
                testFlash(
                  this.props,
                  `Promoting a ${doctype} is not implemented yet.`
                )
              }
            >
              <IconTrend className={classes.icon} />
              {`Promote ${doctypeCap}`}
            </li>
          )}

          {/* delete */}
          <li
            className={classes.link}
            onClick={() => this.setState({ _deleteDialog: true })}
          >
            <Icons.TrashCanOutline className={classes.icon} />
            {`Delete ${doctypeCap}`}
          </li>
        </ul>
      </div>
    );
  }
}

export { ManageLinks, styles };
export default withStyles(styles)(ManageLinks);
