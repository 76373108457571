import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import memoizeOne from "memoize-one";

import {
  COMPANY_TYPE_CORP,
  COMPANY_TYPE_FOUNDER
} from "../../../Reducers/Companies";
import { setScrollviewState } from "../../../Reducers/ScrollView";

import InfiniteScrollView from "./InfiniteScrollView";

const filterCompanies = memoizeOne((companies, companyType, rows) => {
  const filterFunc =
    companyType === COMPANY_TYPE_CORP ? c => c.is_corporate : c => c.is_founder;

  return companies.filter(filterFunc).slice(0, rows);
});

class CompaniesListViewContainer extends React.Component {
  static propTypes = {
    companyType: PropTypes.oneOf([COMPANY_TYPE_CORP, COMPANY_TYPE_FOUNDER])
      .isRequired,
    limit: PropTypes.number.isRequired,
    scrollview: PropTypes.object.isRequired,
    setScrollviewState: PropTypes.func.isRequired,
    loadCompanies: PropTypes.func.isRequired,
    updateCompanies: PropTypes.func.isRequired
  };

  static defaultProps = {
    limit: 20
  };

  componentDidMount() {
    const { limit } = this.props;

    const companies = this.getVisibleCompanies();

    if (companies.length >= limit) {
      // if we have more companies than the initial load it's
      // very likely we have loaded the companies list before
      this.props.updateCompanies();
    } else {
      this.props.loadCompanies({ limit });
    }
  }

  loadNext = () => {
    const { companies, companyType, limit, loading, scrollview } = this.props;
    const visibleRows = scrollview[companyType] || limit;

    if (loading) {
      return;
    }

    if (companies.length < visibleRows) {
      return;
    }

    this.props.setScrollviewState(companyType, visibleRows + limit);
  };

  getVisibleCompanies = () => {
    const { companies, companyType, limit, scrollview } = this.props;
    const rows = scrollview[companyType] || limit;

    return filterCompanies(companies, companyType, rows);
  };

  render() {
    const {
      companies,
      companyType,
      limit,
      scrollview,
      ...otherProps
    } = this.props;

    const visibleRows = scrollview[companyType] || limit;

    const _companies = this.getVisibleCompanies();
    const loadNext = companies.length > visibleRows ? this.loadNext : null;

    return (
      <InfiniteScrollView
        limit={limit}
        loading={companies.loading}
        loadNext={loadNext}
        {...otherProps}
        companyType={companyType}
        companies={_companies}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    companies: state.companies,
    scrollview: state.scrollview
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setScrollviewState: (key, value) => dispatch(setScrollviewState(key, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompaniesListViewContainer);
