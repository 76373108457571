import React from "react";
import PropTypes from "prop-types";

import ErrorPanel from "../common/Errors/ErrorPanel";
import LoadingPanel from "../common/LoadingPanel";

import FormBuilder from "../FormBuilder/FormBuilder";

import ApplicationBanner from "./ApplicationBanner";
import ApplicationCompleted from "./ApplicationCompleted";
import ApplicationFormMenu from "./ApplicationFormMenu";
import GuestLandingPage from "./GuestLandingPage";

// generate key to save application draft to localStorage
const getLocalStorageKey = props => {
  const { profile, doctype, match } = props;
  const { documentId } = match.params;
  const username = profile.username || "guest";

  const key = [username, doctype, documentId].join("__");

  return key;
};

// extract data for application submission from FormBuilder state
const state2data = state => {
  const { company, criteria, id, questions } = state;

  return { company, criteria, id, questions };
};

class ApplicationFormContainer extends React.Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    createFlashNote: PropTypes.func.isRequired,
    doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,

    localStorage: PropTypes.object.isRequired,
    loadDocument: PropTypes.func.isRequired,

    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired,
    pages: PropTypes.array.isRequired,
    profile: PropTypes.object.isRequired,
    submitApplication: PropTypes.func.isRequired
  };

  static defaultProps = {
    localStorage: global.localStorage
  };

  constructor(props) {
    super(props);

    const guest = Boolean(!props.profile || !props.profile.id);
    const pages = guest ? props.pages : props.pages.filter(p => !p.guestOnly);

    this.state = {
      document: { doctype: props.doctype, name: "...loading" },
      draft: {},
      error: null,
      loading: true,
      guest,
      pages,
      success: false
    };
  }

  componentDidMount() {
    const { documentId } = this.props.match.params;

    if (!documentId) {
      this.setState({
        error: new Error(`No ${this.props.doctype} id provided.`)
      });
      return;
    }

    // check localStorage for draft and load
    this.loadDraft().then(this.setDraft);

    // load document for application criteria etc
    return this.props.loadDocument(documentId).then(this.setDocument);
  }

  loadDraft = () => {
    const { localStorage, createFlashNote } = this.props;
    const key = getLocalStorageKey(this.props);
    const json = localStorage.getItem(key);

    if (!json) return Promise.resolve({});

    try {
      const draft = JSON.parse(json);
      createFlashNote("draft application found");

      return Promise.resolve(draft);
    } catch (error) {
      console.error(error);
    }

    return Promise.resolve({});
  };

  mergeWithDraft = (document, draft) => {
    const { company } = this.props;

    const application = {
      company: draft.company || company || {},

      application: draft.application || document.application || {},
      criteria: draft.criteria || document.criteria || [],
      questions: draft.questions || document.questions || []
    };

    return {
      id: document.id,
      doctype: document.doctype,
      link: document.link,
      logo_image_url: document.logo_image_url,
      name: document.name,
      isApplication: true,
      _isUnsavedDraft: true,
      ...application
    };
  };

  removeDraft = () => {
    const { localStorage } = this.props;

    const key = getLocalStorageKey(this.props);
    const json = localStorage.getItem(key);

    if (json) {
      localStorage.removeItem(key);
    }
  };

  saveDraft = state => {
    const { createFlashNote, localStorage } = this.props;
    const data = state2data(state);
    const key = getLocalStorageKey(this.props);

    try {
      localStorage.setItem(key, JSON.stringify(data));
      createFlashNote("Draft application has been saved.", "success");
    } catch (error) {
      console.error(`The application could not be saved. (${error.message})`);
      createFlashNote("Oops! Something went wrong.", "error");
    }
  };

  setDocument = document => {
    const { error } = document;

    if (error) {
      this.setState({ error, loading: false });
    } else {
      this.setState({
        document: this.mergeWithDraft(document, this.state.draft),
        loading: false
      });
    }
  };

  setDraft = draft => {
    const document = this.mergeWithDraft(this.state.document, draft);

    this.setState({ document, draft });
  };

  submit = _data => {
    const { id, ...data } = _data;

    return this.props.submitApplication(id, data).then(result => {
      if (!result.error) {
        this.removeDraft();
        this.setState({ success: true });
      }

      this.setState({ ...result });
      return result;
    });
  };

  renderApplicationForm = () => {
    const {
      company,
      createNewDocument,
      doctype,
      loadDocument,
      profile,
      localStorage,
      // location,
      match,
      submitApplication,
      ...otherProps
    } = this.props;

    return (
      <FormBuilder
        {...otherProps}
        MenuComponent={ApplicationFormMenu}
        pages={this.state.pages}
        document={this.state.document}
        updateDocument={this.submit}
        handleSave={this.saveDraft}
        publishLabel="Submit"
        hideBanner={true}
        state2document={state2data}
        stepperPosition="top"
      />
    );
  };

  renderBody = () => {
    const { history } = this.props;
    const { document, error, guest, loading, success } = this.state;

    if (success && document) {
      return (
        <ApplicationCompleted
          doctype={document.doctype}
          onClick={() => history.push(`/${document.doctype}/${document.id}`)}
        />
      );
    } else if (error) {
      return <ErrorPanel error={error} />;
    } else if (loading) {
      return <LoadingPanel />;
    } else if (guest) {
      return (
        <GuestLandingPage
          onCorpClick={() => this.props.history.push("/forcorporates#join")}
          onFounderClick={() => this.setState({ guest: false })}
        />
      );
    }

    return this.renderApplicationForm();
  };

  renderSuccessPage = () => {
    const { history } = this.props;
    const { document } = this.state;
    const documentLink = `/${document.doctype}/${document.id}`;

    return (
      <ApplicationCompleted
        doctype={document.doctype}
        onClick={() => history.push(documentLink)}
      />
    );
  };

  render() {
    const { document, loading } = this.state;

    return (
      <div>
        <ApplicationBanner document={document} loading={loading} />
        {this.renderBody()}
      </div>
    );
  }
}

export default ApplicationFormContainer;
