import React from "react";

import CreateButton from "../common/Buttons/CreateButton";
import ReferStartupButton from "../Startups/ReferStartupButton";

const MenubarCorporate = props => {
  return (
    <div style={{ backgroundColor: "white", height: 80 }}>
      <div
        className="body"
        style={{
          display: "flex",
          alignItems: "center",
          height: 80,
          justifyContent: "flex-end"
        }}
      >
        <ReferStartupButton>New Referral</ReferStartupButton>
        <CreateButton link={"/programs/new"}>Create Program</CreateButton>
        <CreateButton link={"/challenges/new"}>Create Think Tank</CreateButton>
      </div>
    </div>
  );
};

export default MenubarCorporate;
