import React from "react";

import Typography from "@material-ui/core/Typography";

import Section from "../common/Section";
import ContactCardSmall from "../Contacts/ContactCardSmall";

const ProfileContactsSection = props => {
  const { contacts, title } = props;

  const cards = contacts.length ? (
    contacts.map(contact => (
      <ContactCardSmall
        key={`${contact.first_name}_${contact.last_name}`}
        {...contact}
      />
    ))
  ) : (
    <Typography variant="body1">no contacts provided</Typography>
  );

  return (
    <Section
      title={title}
      fullwidth={false}
      style={{
        marginTop: "32px"
      }}
    >
      {cards}
    </Section>
  );
};

ProfileContactsSection.defaultProps = {
  contacts: [],
  title: "Contacts"
};

export default ProfileContactsSection;
