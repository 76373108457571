import { connect } from "react-redux";

import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

import {
  approveApplications,
  declineApplications
} from "../../Reducers/Applications";
import { createFlashNote } from "../../Reducers/FlashNotes";

import ApplicationsManageButtons from "./ApplicationsManageButtons";

const sendEmail = (apps, msg) => {
  if (!apps.length)
    return Promise.resolve({ error: new Error("no applications selected") });

  const data = { participants: apps.map(app => app.id), ...msg };
  const url = apps[0].url
    .split("/")
    .slice(0, 5)
    .join("/");

  return fetchWithToken(`${url}/notify/`, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(responseJsonOrError);
};

const mapDispatchToProps = dispatch => {
  return {
    approveApplications: apps => {
      dispatch(approveApplications(apps));
    },
    declineApplications: (apps, response_note) => {
      const data = { response_note };

      dispatch(declineApplications(apps, data));
    },
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    },
    sendEmail
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ApplicationsManageButtons);
