import React from "react";

import ProgramDetailsViewContainer from "../components/Programs/ProgramDetailsViewContainer";

import PageTemplate from "./PageTemplate";

const Program = props => {
  return (
    <PageTemplate>
      <ProgramDetailsViewContainer {...props} />
    </PageTemplate>
  );
};

export default Program;
