import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { DISPLAY_LABELS } from "../Criteria/config";

const countriesWithArticle = [
  1,
  3,
  16,
  33,
  40,
  43,
  46,
  55,
  73,
  75,
  78,
  107,
  125,
  127,
  167,
  178,
  192,
  195,
  196,
  217,
  233,
  234,
  237,
  240,
  241
];

const styles = {
  root: {
    flexGrow: 1,
    fontSize: 14,
    fontWeight: 300
  },
  strong: {
    fontWeight: 600,
    marginLeft: 4
  }
};

const stageIdMap = Object.keys(DISPLAY_LABELS).map(key => ({
  id: key,
  label: DISPLAY_LABELS[key]
}));

const formatList = (className, countries, hasArticle) => (v, index, values) => {
  const country = countries.find(c => c.id === v);

  if (!country) return null;

  return (
    <span key={index}>
      {hasArticle.indexOf(country.id) !== -1 ? (
        <span key={index} style={{ marginLeft: 4 }}>
          the
        </span>
      ) : null}
      <span className={className}>{country.label || country.name}</span>
      {index === values.length - 2
        ? " or"
        : index === values.length - 1
          ? ""
          : ","}
    </span>
  );
};

const ApplicationCriteriaQuestion = props => {
  const { classes, countries, markets, question } = props;

  const { label, value } = question;

  if (label === "countries") {
    return (
      <div className={classes.root}>
        Is your company
        <span className={classes.strong}>located</span> in
        {value.map(formatList(classes.strong, countries, countriesWithArticle))}
        ?
      </div>
    );
  }

  if (label === "markets") {
    return (
      <div className={classes.root}>
        Is your company active in one or more of the following
        <span className={classes.strong}>markets:</span>
        {value.map(formatList(classes.strong, markets, []))}?
      </div>
    );
  }

  if (label === "stages") {
    return (
      <div className={classes.root}>
        Is your company’s most recent
        <span className={classes.strong}>funding stage</span> equal to
        {value.map(formatList(classes.strong, stageIdMap, []))}?
      </div>
    );
  }

  return <div className={classes.root}>{value}</div>;
};

ApplicationCriteriaQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  markets: PropTypes.array.isRequired,
  question: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    response: PropTypes.string
  })
};

export { ApplicationCriteriaQuestion, styles };
export default withStyles(styles)(ApplicationCriteriaQuestion);
