import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import Banner from "../common/Banner";
import TabbedContainer from "../common/TabbedContainer";

import CreateButton from "../common/Buttons/CreateButton";
import DocumentsListViewContainer from "../Document/DocumentsListViewContainer";
import { loadChallenges, loadChallengesFromUrl, loadMyChallenges } from "./api";

import ThinkTanksTableOwnerView from "../Document/DocumentsListView/ThinkTanksTableOwnerView";
import ThinkTanksTableView from "../Document/DocumentsListView/ThinkTanksTableView";

import { EmptyThinkTanksListOwner } from "../Dashboard/Placeholders";

const tableStyle = {
  marginTop: 40,
  marginBottom: 60,
  backgrundColor: "white",
  boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.1)"
};

const ChallengesListViewPage = props => {
  const { company, subtitle, title } = props;

  const thinkTanksListView = (
    <DocumentsListViewContainer
      doctype="challenge"
      loadDocuments={props.loadChallenges}
      loadDocumentsFromUrl={props.loadChallengesFromUrl}
      ListViewComponent={ThinkTanksTableView}
      style={tableStyle}
    />
  );

  const body = company.is_corporate ? (
    <TabbedContainer
      actionButton={
        <CreateButton link={"/challenges/new"}>
          Start a new Think Tank
        </CreateButton>
      }
      labels={["All Challenges", "My Challenges"]}
      fullWidth={true}
      size="large"
    >
      {thinkTanksListView}
      <DocumentsListViewContainer
        doctype="challenge"
        loadDocuments={props.loadMyChallenges(company.id)}
        loadDocumentsFromUrl={props.loadChallengesFromUrl}
        ListViewComponent={ThinkTanksTableOwnerView}
        EmptyState={EmptyThinkTanksListOwner}
        style={tableStyle}
      />
    </TabbedContainer>
  ) : (
    thinkTanksListView
  );

  return (
    <React.Fragment>
      <Banner title={title} subtitle={subtitle} />
      <div className="body">{body}</div>
    </React.Fragment>
  );
};

ChallengesListViewPage.propTypes = {
  company: PropTypes.object.isRequired,
  doctype: PropTypes.string.isRequired,
  loadChallenges: PropTypes.func.isRequired,
  loadChallengesFromUrl: PropTypes.func.isRequired,
  loadMyPrograms: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

ChallengesListViewPage.defaultProps = {
  doctype: "program",
  loadChallenges,
  loadChallengesFromUrl,
  loadMyChallenges,
  subtitle: "Solve problems together with Corporates and Founder Teams",
  title: "Think Tank Challenges"
};

const mapStateToProps = state => {
  return {
    company: state.company,
    doctype: "challenge"
  };
};

export default connect(mapStateToProps)(ChallengesListViewPage);
