import React from "react";
import { PropTypes } from "prop-types";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import ErrorBoundary from "../components/common/Errors/ErrorBoundary";

class PageTemplate extends React.Component {
  static propTypes = {
    scrollToTop: PropTypes.bool
  };

  static defaultProps = {
    scrollToTop: false
  };

  componentDidMount() {
    if (document.location.hash) {
      const hash = document.location.hash.split("?")[0];
      const element = document.querySelector(hash);

      if (element) {
        element.scrollIntoView();
      }
    } else if (this.props.scrollToTop) {
      if (window.scrollTo) {
        window.scrollTo(0, 0);
      }
    }

    // reset document title in case it was modified by specific page
    window.document.title =
      "Colinked | Connecting Corporate, Founders &amp; Investors";
  }

  render() {
    return (
      <div>
        <Navigation />
        <ErrorBoundary>{this.props.children}</ErrorBoundary>
        <Footer />
      </div>
    );
  }
}

export default PageTemplate;
