import { fetchWithToken } from "../../api";
import { responseJsonOrError } from "../../api/response";

import * as programsApi from "../Programs/api";
import * as thinktankApi from "../Challenges/api";

export const loadPrograms = companyId => {
  const url = `/api/programs/?company=${companyId}`;

  return fetchWithToken(url)
    .then(responseJsonOrError)
    .then(programsApi.fixMiniumDataArray)
    .catch(error => ({ error }));
};

export const loadThinkTanks = companyId => {
  const url = `/api/think_tanks/?company=${companyId}`;

  return fetchWithToken(url)
    .then(responseJsonOrError)
    .then(thinktankApi.fixMiniumDataArray)
    .catch(error => ({ error }));
};
