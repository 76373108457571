const formatErrorMessage = json => {
  if (process.env.NODE_ENV === "development") {
    console.log(JSON.stringify(json, null, 2));
  }

  // separate known fields and validation errors
  const { code, detail, non_field_errors, messages, ...other } = json;
  const validationErrors = getValidationErrors(other);

  const lines = [];

  if (detail) {
    lines.push(code ? `${detail} (${code})` : detail);
  }

  if (Array.isArray(messages)) {
    messages.forEach(msg => {
      const { message, ...other } = msg;

      lines.push(`${message} (${JSON.stringify(other)})`);
    });
  }

  if (Array.isArray(non_field_errors)) {
    non_field_errors.forEach(err => {
      lines.push(JSON.stringify(err));
    });
  }

  if (Object.keys(validationErrors).length) {
    console.log(
      "Validation Errors:",
      JSON.stringify(validationErrors, null, 2)
    );
    lines.push("There were validation errors.");
  }

  return { lines, validationErrors };
};

const getErrorString = err => {
  if (typeof err === "string") return err;

  if (typeof err === "string") {
    return Object.keys(err)
      .map(key => `${key}: ${err[key]}`)
      .join(", ");
  }

  return JSON.stringify(err);
};

const getValidationErrors = json => {
  return Object.keys(json).reduce((errors, key) => {
    if (Array.isArray(json[key])) {
      errors[key] = json[key].map(getErrorString).join("\n");
    } else if (typeof json[key] === "object") {
      errors[key] = getValidationErrors(json[key]);
    } else {
      errors[key] = json[key];
    }

    return errors;
  }, {});
};

const responseJson = response => {
  // no content; don't try json()
  if (response.status === 204) {
    return {};
  }

  return response.json().catch(error => {
    console.error(error);

    return {};
  });
};

const responseErrorOther = response => {
  if (process.env.NODE_ENV !== "test") {
    console.warn(response);
  }

  const { status, statusText } = response;

  return response
    .json()
    .then(json => {
      const { lines, validationErrors } = formatErrorMessage(json);
      const message = [
        response.message || `${status} (${statusText})`,
        ...lines
      ]
        .filter(l => l)
        .join("\n");

      console.error(message);
      const result = {
        // error: new Error(message),
        error: new Error("Oops! Something went wrong."),
        validationErrors
      };

      return result;
    })
    .catch(error => {
      const message = `Error processing server response [${status} (${statusText})]:\n${
        error.message
      }`;
      const result = { error: new Error(message) };
      console.error(error);

      return result;
    });
};

export const responseJsonOrError = response => {
  // try to get json response data
  if (response.ok) return responseJson(response);

  // error handling: try to generate displayable errors
  const { status } = response;

  // server error: return basic status error
  if (status >= 500) {
    // const message = response.message || `${status} (${statusText})`;
    // return { error: new Error(message) };
    return { error: new Error("Oops! Something went wrong.") };
  }

  if (status === 404) {
    return { error: new Error(`URL not found (url='${response.url}')`) };
  }

  return responseErrorOther(response);
};

export const convertJsonToErrors = json => {
  return Object.keys(json).reduce((errors, key) => {
    const value = json[key];
    errors[key] = Array.isArray(value) ? value.join(" ") : `${value}`;

    return errors;
  }, {});
};
