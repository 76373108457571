import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchApplications } from "../../Reducers/Applications";

import LoadingPanel from "../common/LoadingPanel";

import ApplicationsListView from "./ApplicationsListView";

class ApplicationsListContainer extends React.Component {
  static propTypes = {
    applications: PropTypes.array.isRequired,
    document: PropTypes.object.isRequired,
    doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
    loadingState: PropTypes.object.isRequired,
    is_approved: PropTypes.oneOf([0, 1, 2]).isRequired,
    profile: PropTypes.object.isRequired,
    viewtype: PropTypes.oneOf(["public", "private"]).isRequired
  };

  static defaultProps = {
    is_approved: 1
  };

  componentDidMount() {
    this.load(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps);
  }

  load = props => {
    const { company, doctype, document, loadingState, is_approved } = props;

    if (!document || !document.id) return;

    const docUrl = this.getDocUrl(doctype, document);
    const fetchUrl = `${docUrl}/participants/?is_approved=${is_approved}&limit=100`;

    // load application details if user is looking at own program
    const details = document.company && document.company.id === company.id;

    if (!loadingState[fetchUrl]) {
      this.props.fetchApplications(fetchUrl, details);
    }
  };

  getDocUrl = (doctype, document) => {
    return doctype === "program"
      ? `/api/programs/${document.id}`
      : `/api/think_tanks/${document.id}`;
  };

  render() {
    const { doctype, document, loadingState } = this.props;

    if (!document || !document.id) return <LoadingPanel />;

    const docUrl = this.getDocUrl(doctype, document);

    if (loadingState[docUrl] === "loading") return <LoadingPanel />;
    // filter applications:
    // first filter applications specific to current project, then based on is_approved state
    const applications = this.props.applications
      .filter(app => app.url && app.url.startsWith(docUrl))
      .filter(app => app.is_approved === this.props.is_approved);
    return <ApplicationsListView {...this.props} applications={applications} />;
  }
}

const mapStateToProps = state => {
  return {
    applications: state.applications.applications,
    company: state.company,
    loadingState: state.applications.loadingState,
    profile: state.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchApplications: (url, details) =>
      dispatch(fetchApplications(url, details))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsListContainer);
