import React from "react";
import { PropTypes } from "prop-types";

import {
  LayoutDesktopLarge,
  LayoutDesktopMedium,
  LayoutMobile
} from "./layouts";

const ServiceDetailsView = props => {
  const { width } = props;

  if (width === "sm" || width === "xs") {
    return <LayoutMobile {...props} />;
  }
  if (width === "md") {
    return <LayoutDesktopMedium {...props} />;
  }
  return <LayoutDesktopLarge {...props} />;
};

ServiceDetailsView.propTypes = {
  document: PropTypes.object.isRequired,
  preview: PropTypes.bool,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

ServiceDetailsView.defaultProps = {
  preview: false
};

export default ServiceDetailsView;
