import React from "react";
import { PropTypes } from "prop-types";

import Banner from "../common/Banner";

import CreateMenu from "./CreateMenu";
import MarketplaceList from "./MarketplaceList";

const MarketplaceListPage = props => {
  const { title } = props;

  return (
    <React.Fragment>
      <Banner title={title} />
      <CreateMenu />
      <MarketplaceList />
    </React.Fragment>
  );
};

MarketplaceListPage.propTypes = {
  title: PropTypes.string.isRequired
};

MarketplaceListPage.defaultProps = {
  title: "Marketplace Offers"
};

export default MarketplaceListPage;
