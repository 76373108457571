import React from "react";
import { PropTypes } from "prop-types";
import AnimateHeight from "react-animate-height";

import ChevronDown from "@material-ui/icons/KeyboardArrowDown";
import ChevronUp from "@material-ui/icons/KeyboardArrowUp";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    padding: 30,
    backgroundColor: "white",
    marginTop: 25,
    boxShadow: "0 5px 10px 0 rgba(0,0,0,.1)"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  content: {
    marginTop: 30,
    marginRight: 30
  }
};

class FAQItem extends React.Component {
  state = { open: false };

  renderContent = () => {
    const { animation, classes, content, contentHTML } = this.props;
    const { open } = this.state;
    const height = open ? "auto" : 0;

    const _content = contentHTML ? (
      <div
        className={`faqcontent ${classes.content}`}
        dangerouslySetInnerHTML={{ __html: contentHTML }}
      />
    ) : (
      <div className={classes.content}>{content}</div>
    );

    if (!animation) {
      return open ? _content : null;
    }

    return (
      <AnimateHeight duration={animation} height={height}>
        {_content}
      </AnimateHeight>
    );
  };

  render() {
    const { classes, title } = this.props;
    const { open } = this.state;

    return (
      <div
        className={classes.root}
        onClick={() => this.setState({ open: !open })}
      >
        <div className={classes.title}>
          <span>{title}</span>
          {open ? <ChevronUp /> : <ChevronDown />}
        </div>

        {this.renderContent()}
      </div>
    );
  }
}

FAQItem.propTypes = {
  animation: PropTypes.number,
  classes: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

FAQItem.defaultProps = {
  animation: 500,
  content: "FAQ content goes here",
  title: "FAQ title"
};

export default withStyles(styles)(FAQItem);
