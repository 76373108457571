import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";

import Navigation from "./Navigation";

const mapStateToProps = state => ({
  company: state.company,
  profile: state.profile
});

export default connect(mapStateToProps)(withWidth()(withRouter(Navigation)));
