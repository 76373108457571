import React from "react";
import { PropTypes } from "prop-types";

import StyledTable from "../common/StyledTable";
import { withStyles } from "@material-ui/core";

import LocalLink from "../common/LocalLink";

const styles = {
  root: {
    marginTop: 20
  },
  title: {
    color: "#2A2B2C",
    fontSize: 16,
    fontWeight: 700,
    marginLeft: 30,
    marginBottom: 30
  },
  link: {
    color: "#398DD3",
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    },
    height: 60,
    lineHeight: "60px",
    paddingLeft: 30
  }
};

const renderLink = props => {
  const { classes, companyId, investors, limit } = props;

  if (!companyId) return null;
  if (investors.length <= limit) return null;

  const _rounds = investors.map(i => i[1]).reduce((map, _round) => {
    map[_round] = true;
    return map;
  }, {});
  const rounds = Object.keys(_rounds).length;

  const label = `See all ${
    investors.length
  } investors from ${rounds} funding rounds`;
  const link = `/startup/${companyId}`;

  return (
    <div className={classes.link}>
      <LocalLink label={label} link={link} />
    </div>
  );
};

const InvestorsTable = props => {
  const { classes, investors, limit } = props;

  const data = limit ? investors.slice(0, limit) : [...investors];

  return (
    <div className={classes.root}>
      <div className={classes.title}>{data.length} Investors</div>
      <StyledTable headers={props.headers} data={data} />
      {renderLink(props)}
    </div>
  );
};

InvestorsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  companyId: PropTypes.number,
  headers: PropTypes.array.isRequired,
  investors: PropTypes.array.isRequired,
  limit: PropTypes.number.isRequired
};

InvestorsTable.defaultProps = {
  headers: ["Investor Name", "Funding Round", "Lead Investor"],
  limit: 5
};

export { InvestorsTable, styles };
export default withStyles(styles)(InvestorsTable);
