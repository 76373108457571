import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { loadReferrals } from "../../Reducers/Referrals";

import ReferralsList from "./ReferralsList";

class ReferralsListContainer extends React.Component {
  static propTypes = {
    referrals: PropTypes.array.isRequired,
    loadReferrals: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.loadReferrals();
  }

  render() {
    const { loadReferrals, ...otherProps } = this.props;
    return <ReferralsList {...this.state} {...otherProps} />;
  }
}

const mapStateToProps = state => {
  return {
    referrals: state.referrals
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadReferrals: () => dispatch(loadReferrals())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralsListContainer);
