import { connect } from "react-redux";

import { createFlashNote } from "../../Reducers/FlashNotes";
import { setValidationErrors } from "../../Reducers/ValidationErrors";

import * as api from "./api";

import DocumentDetailsView from "../Document/DocumentDetailsView";
import FormBuilderContainer from "../FormBuilder/FormBuilderContainer";
import programFormSections from "./programBuilderSections";

const createNewDocument = profile => () => {
  return api.createNewProgram().then(program => ({
    ...program,
    company: profile.company
  }));
};

const mapStateToProps = state => ({
  PreviewComponent: DocumentDetailsView,
  exitRedirect: "/programs",
  pages: programFormSections,
  createNewDocument: createNewDocument(state.profile),
  deleteDocument: api.deleteProgram,
  duplicateDocument: api.duplicateProgram,
  loadDocument: api.loadProgram,
  updateDocument: api.updateProgram,
  validationErrors: state.validationErrors
});

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    },
    setValidationErrors: errors => dispatch(setValidationErrors(errors))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormBuilderContainer);
