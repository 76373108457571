import React from "react";
import PropTypes from "prop-types";

import ChevronDown from "@material-ui/icons/KeyboardArrowDown";
import { withStyles } from "@material-ui/core/styles";

import SubMenuButton from "../../common/Buttons/SubMenuButton";
import Avatar from "./Avatar";
import styles from "./styles";

const MenuWrapper = props => {
  const { children, buttonRef, ...other } = props;

  return (
    <button id="subWrapper" {...other}>
      {children}
    </button>
  );
};

class ProfileButton extends React.Component {
  static propTypes = {
    actions: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    profile: PropTypes.object.isRequired,
    showLogout: PropTypes.bool,
    width: PropTypes.string.isRequired
  };

  static defaultProps = {
    actions: [],
    showLogout: true
  };

  state = {
    anchorEl: null
  };

  logout = () => {
    this.setState({ anchorEl: null }, () => {
      this.props.history.push("/");
      this.props.logout();
    });
  };

  openMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  renderDetails = () => {
    const { classes, company, profile } = this.props;

    return (
      <div className={classes.details}>
        Hi, <span className={classes.name}>{profile.first_name}</span> from{" "}
        <span className={classes.company}>{company.name}</span>
      </div>
    );
  };

  render() {
    const { classes, profile, showLogout } = this.props;

    const actions = showLogout
      ? [
          ...this.props.actions,
          { label: "Log out", action: this.logout, active: true }
        ]
      : this.props.actions;

    return (
      <div className={classes.root}>
        <SubMenuButton
          classes={{ root: classes.subMenuRoot }}
          ButtonComponent={MenuWrapper}
          actions={actions}
        >
          <div
            id="flexContainer"
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Avatar profile={profile} />
            {this.renderDetails()}
            <ChevronDown className={classes.downIcon} />
          </div>
        </SubMenuButton>
      </div>
    );
  }
}

export default withStyles(styles)(ProfileButton);
