import React from "react";

import Banner from "../common/Banner";

const getTitle = props => {
  const { document, error, loading } = props;

  if (document._isUnsavedDraft) return "New Marketplace Offer";

  if (window.location.pathname === "/marketplace") {
    return "Marketplace Offers";
  }

  if (window.location.pathname.endsWith("/edit")) {
    return `Edit '${document.description}'`;
  }

  return error ? "Error" : loading ? "loading ..." : document.description || "";
};

const ServiceBanner = props => {
  const { document, width } = props;

  const breadcrumbs = [{ name: "Marketplace", link: "/marketplace" }];
  const title = getTitle(props);

  return (
    <Banner
      breadcrumbs={breadcrumbs}
      title={title}
      logo={document.company_logo_url}
      fontSize={width === "xs" ? 22 : 32}
    />
  );
};

export default ServiceBanner;
