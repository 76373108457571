import React from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Breadcrubs from "./Breadcrumbs";
import LogoImage from "./LogoImage";

const styles = {
  root: {
    boxSizing: "border-box",
    paddingTop: 30,
    paddingBottom: 30,
    background: "linear-gradient(-17deg, #398CD3 0%, #1D71B8 100%)",
    height: 160,
    display: "flex",
    alignItems: "center"
  },
  banner: {
    display: "flex",
    alignItems: "center"
  },

  logoContainer: {
    //backgroundColor: "yellow",
    //outline: "1px dashed orange"
  },
  logoRoot: {
    marginRight: 15
  },

  subtitle: {
    color: "white",
    fontSize: "16px",
    fontWeight: 700
  },
  title: {
    color: "white",
    fontWeight: 700,
    fontSize: 32,
    padding: 0
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column"
  }
};

const getDocumentBreadCrumbs = (document, pathname) => {
  const { doctype, id, name } = document;

  const listName =
    doctype === "program"
      ? "Programs"
      : doctype === "challenge"
      ? "Think Tank"
      : doctype;
  const listPage = { name: listName, link: `/${doctype}s` };

  // suppress document link in preview
  if (document.preview) {
    return [listPage];
  }

  // .../apply, .../applications and .../edit
  if (pathname.startsWith(`/${doctype}/${id}/`)) {
    return [listPage, { name, link: `/${doctype}/${id}` }];
  }

  if (pathname.startsWith(`/${doctype}/${id}`)) {
    return [listPage];
  }

  return [];
};

const getBreadCrumbs = props => {
  const { pathname } = props.location;

  // list page and create page
  if (pathname.startsWith("/programs"))
    return [{ name: "Programs", link: "/programs" }];

  if (pathname.startsWith("/challenges"))
    return [{ name: "Think Tank", link: "/challenges" }];

  if (props.document && props.document.id) {
    return getDocumentBreadCrumbs(props.document, pathname);
  }

  return [];
};

const Banner = props => {
  const { children, classes, fontSize, logo, title, subtitle } = props;

  const breadcrumbs = props.breadcrumbs.length
    ? props.breadcrumbs
    : getBreadCrumbs(props);

  const logoImage = logo ? (
    <LogoImage
      url={logo}
      radius={40}
      size={80}
      margin={0}
      classes={{ root: classes.logoRoot }}
    />
  ) : null;

  const titleType = title ? (
    <Typography
      id="banner-title"
      classes={{ display2: classes.title }}
      variant="display2"
      style={{ fontSize }}
    >
      {title}
    </Typography>
  ) : null;

  const subtitleType = subtitle ? (
    <Typography classes={{ caption: classes.subtitle }} variant="caption">
      {subtitle}
    </Typography>
  ) : null;

  return (
    <div className={classes.root}>
      <div className={`body-container ${classes.banner}`}>
        <div>{logoImage}</div>
        <div className={classes.titleContainer}>
          <Breadcrubs links={breadcrumbs} />
          {titleType}
          {subtitleType}
        </div>
        <div style={{ flexGrow: 1 }} />
        <div>{children}</div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  fontSize: PropTypes.number.isRequired,
  logo: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

Banner.defaultProps = {
  breadcrumbs: [],
  fontSize: 32,
  subtitle: ""
};

export { Banner, styles };
export default withRouter(withStyles(styles)(Banner));
