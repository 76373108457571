import React from "react";

import { withStyles } from "@material-ui/core/styles";

import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxBlank from "@material-ui/icons/CheckBoxOutlineBlank";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",

    padding: 15,
    border: "1px solid #E6E7E9",
    borderRadius: 4,
    marginTop: 20,
    marginBottom: 20,

    fontSize: 14,
    color: "#2A2B2C"
  },
  icon: {
    marginRight: 15
  },
  link: {
    color: "#398DD3",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  }
};

const TermsAndConditionsField = props => {
  const { classes, onChange, style, value } = props;

  const iconProps = {
    className: classes.icon,
    onClick: () => onChange(!value),
    style: value ? { fill: "#4F8DCD" } : {}
  };

  const Icon = value ? CheckBox : CheckBoxBlank;

  return (
    <div className={classes.root} style={style}>
      <Icon {...iconProps} />
      <div>
        Check here to confirm you agree to the{" "}
        <a
          href="/terms_of_use"
          className={classes.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          href="/privacy_policy"
          className={classes.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>{" "}
        of our user agreement
      </div>
    </div>
  );
};

TermsAndConditionsField.defaultProps = {
  style: {}
};

export default withStyles(styles)(TermsAndConditionsField);
