const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    marginLeft: 40,
    fontSize: 15,
    fontWeight: 400
    // maxWidth: 290
  },
  company: {
    fontWeight: 700
  },
  details: {
    fontWeight: 400,
    cursor: "pointer",
    // maxWidth: 200,
    overflowX: "hidden",
    whiteSpace: "nowrap"
  },
  icon: {},
  name: {
    fontWeight: 700
  },
  downIcon: {
    marginLeft: 5
  },
  menuButton: {
    //outline: "1px solid green",
    width: 36,
    minWidth: 36,
    padding: 4,
    position: "relative",
    top: 8,
    marginLeft: 16
  },
  menuItem: {
    "&:focus": {
      backgroundColor: "white",
      "& $primary, & $icon": {
        color: "white"
      }
    }
  },
  primary: {
    fontSize: "16px",
    letterSpacing: 0,
    paddingTop: 0
  },
  subMenuRoot: {
    height: 42 // limit to height of avatar image
  }
};

export default styles;
