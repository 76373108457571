import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import faker from "faker";

import { PrimaryButton, SecondaryButton } from "../../common/Buttons";
import * as propTypes from "../../common/Testimonials/propTypes";

import TextInputField from "../TextInputField";

const styles = {
  root: {
    boxSizing: "border-box",
    padding: 30,
    marginBottom: 20
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 30
  }
};

class TestimonialEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonial: { ...props.testimonial },
      errors: {}
    };
  }

  apply = () => {
    const { testimonial } = this.state;

    this.props.onChange({ ...testimonial, _edit: false }, true);
  };

  fill = () => {
    this.setState({
      testimonial: {
        author: faker.name.findName(),
        author_job_title: faker.name.jobTitle(),
        author_company: faker.company.companyName(),
        content: faker.lorem.paragraph()
      }
    });
  };

  getError = id => {
    const { testimonial } = this.state;

    if (!testimonial[id]) return `${id} is required`;

    return null;
  };

  handleCancel = () => {
    this.props.onChange({ _edit: false }, false);
  };

  disableButton = () => {
    const { testimonial } = this.state;
    const { author, author_company, content } = testimonial;

    return [author, author_company, content].some(s => !Boolean(s));
  };

  setError = (id, resetOnly = false) => {
    const error = this.getError(id);

    if (resetOnly && error) return;

    const errors = {
      ...this.state.errors,
      [id]: error
    };

    this.setState({ errors });
  };

  update = id => value => {
    const testimonial = {
      ...this.state.testimonial,
      [id]: value
    };

    this.setState({ testimonial }, () => this.setError(id, true));
  };

  renderTextInput = spec => {
    const { errors, testimonial } = this.state;

    return (
      <TextInputField
        key={spec.id}
        label={spec.label || spec.id}
        onChange={this.update(spec.id)}
        onBlur={() => this.setError(spec.id)}
        value={testimonial[spec.id]}
        error={Boolean(errors[spec.id])}
        helperText={errors[spec.id] || spec.helperText}
        limit={spec.limit || 40}
        width={spec.width || 330}
        required={true}
        rows={spec.rows}
        showLimit={spec.showLimit || false}
        type="text"
      />
    );
  };

  render() {
    const { classes, label, specs } = this.props;

    return (
      <div className={classes.root}>
        {specs.map(this.renderTextInput)}

        <div className={classes.buttons}>
          <SecondaryButton onClick={this.fill}>Fill</SecondaryButton>
          <SecondaryButton onClick={this.handleCancel}>Cancel</SecondaryButton>
          <PrimaryButton disabled={this.disableButton()} onClick={this.apply}>
            {label}
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

TestimonialEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  specs: PropTypes.array.isRequired,
  testimonial: propTypes.testimonial
};

TestimonialEditForm.defaultProps = {
  label: "Update",
  specs: [
    { id: "author", label: "Name" },
    { id: "author_job_title", label: "Job Title" },
    { id: "author_company", label: "Company name" },
    {
      id: "content",
      label: "Quote content",
      showLimit: true,
      limit: 500,
      rows: 4,
      width: "100%"
    }
  ],
  testimonial: {
    author: "",
    author_company: "",
    author_job_title: "",
    content: ""
  }
};

export { TestimonialEditForm, styles };
export default withStyles(styles)(TestimonialEditForm);
