import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import ApplicationDialog from "../common/ApplicationDialog";
import CompanyLink from "../common/CompanyLink";
import QueryDialog from "../common/QueryDialog";
import ThinkTankApplicationDialog from "../common/ThinkTankApplicationDialog";

import Icons from "../Icons";

import TagLabel from "../common/TagLabel";
import { documentTypeLabelsShort } from "../Document/config";

import ApplyButton from "./ApplyButton";
import AppointmentDate from "./AppointmentDate";
import AppointmentDeadline from "./AppointmentDeadline";
import AppointmentDetails from "./AppointmentDetails";
import AppointmentLocation from "./AppointmentLocation";
import AppointmentTime from "./AppointmentTime";
import DateOngoing from "./DateOngoing";

import styles from "./styles";

const getCompanyName = props => {
  const { document, preview } = props;
  const { is_anonymous } = document;

  // during preview use user/owner company
  const company = preview ? props.company : document.company;

  if (is_anonymous) return "(organizer withheld)";

  if (!company) return "";

  return <CompanyLink company={company} />;
};

const getHeader = props => {
  const { doctype, document, profile } = props;
  const { application_is_approved } = document;

  if (
    application_is_approved !== null &&
    application_is_approved !== undefined
  ) {
    return "Event details";
  }

  if (doctype === "challenge") {
    return "Take part";
  } else if (profile.membership && profile.membership.startsWith("corp")) {
    return "Participate";
  }

  return "Apply";
};

const renderDateTime = document => {
  const { start_datetime, end_datetime } = document;

  return (
    <React.Fragment>
      {start_datetime && (
        <AppointmentDate
          start_datetime={start_datetime}
          end_datetime={end_datetime}
        />
      )}
      {start_datetime && (
        <AppointmentTime
          start_datetime={start_datetime}
          end_datetime={end_datetime}
        />
      )}
    </React.Fragment>
  );
};

const renderMap = (location, classes) => {
  // TODO: render map view
  if (!false) return null;
  if (!location) return null;
  if (!location.map) return null;

  return (
    <div
      className={classes.placeholder}
      style={{
        height: 215,
        marginLeft: -30,
        marginRight: -30
      }}
    >
      TODO: map view
    </div>
  );
};

const renderType = (document, classes) => {
  const _type = document.meeting_type || document.program_type || "";
  const displayType = documentTypeLabelsShort[_type] || _type;

  return (
    <TagLabel
      label={displayType}
      color="gold"
      classes={{ root: classes.tagRoot }}
    />
  );
};

class MeetupPanel extends React.Component {
  state = {
    modal: null
  };

  showApplicationDialog = () => {
    const { doctype, document, history, profile } = this.props;

    if (!profile.company.id) {
      history.push(`/guest_application/${doctype}/${document.id}`);
    }

    // TODO: confirm applications url
    const postURL =
      doctype === "program"
        ? `/api/programs/${document.id}/register/`
        : `/api/think_tanks/${document.id}/register/`;

    const modal =
      doctype === "program" ? (
        <ApplicationDialog
          message=""
          onClose={() => {
            this.setState({ modal: null });
          }}
          postURL={postURL}
          title={`Apply for '${document.name || document.title}'`}
        />
      ) : (
        <ThinkTankApplicationDialog
          message=""
          onClose={() => {
            this.setState({ modal: null });
          }}
          postURL={postURL}
          title={`Apply for '${document.name || document.title}'`}
        />
      );
    this.setState({ modal });
  };

  showQueryDialog = () => {
    const { doctype, document } = this.props;

    // TODO: confirm program query url
    const postURL = `/${doctype}s/${document.id}/query`;

    const modal = (
      <QueryDialog
        message=""
        onClose={() => {
          this.setState({ modal: null });
        }}
        postURL={postURL}
        title={`Ask about '${document.name || document.title}'`}
      />
    );
    this.setState({ modal });
  };

  render() {
    const { classes, doctype, document, history, preview } = this.props;

    const { is_nda, is_ongoing } = document;
    const deadline_datetime = document.application
      ? document.application.deadline_datetime
      : document.deadline_datetime;

    const companyName = getCompanyName(this.props);

    return (
      <div className={classes.root}>
        {this.state.modal}

        {/* header and deadline countdonw */}
        <div className={classes.headerRow}>
          <div className={classes.header}>{getHeader(this.props)}</div>
          <AppointmentDeadline
            deadline_datetime={deadline_datetime}
            countdown={true}
          />
        </div>

        {/* type and organizer */}
        <div className={classes.detailsRow} style={{ alignItems: "center" }}>
          {renderType(document, classes)}
          {companyName && (
            <span className={classes.inlineText}>by {companyName}</span>
          )}
        </div>

        {/* old NDA flag (think tanks) */}
        {is_nda && (
          <div className={classes.detailsRow}>
            <Icons.FormatSection className={classes.inlineIcon} />
            <span className={classes.inlineText}>
              Participation requires a non-disclosure agreement (NDA)
            </span>
          </div>
        )}

        {/* start and end date */}
        {is_ongoing && (
          <DateOngoing doctype={doctype} deadline={deadline_datetime} />
        )}
        {!is_ongoing && renderDateTime(document)}

        {/* address */}
        {document.location ? (
          <AppointmentLocation {...document.location} />
        ) : (
          <AppointmentDetails document={document} />
        )}

        {/* map view */}
        {renderMap(document.location, classes)}

        <ApplyButton
          document={document}
          doctype={doctype}
          history={history}
          // TODO: remove code for onClick={this.showApplicationDialog}
          preview={preview}
        />
      </div>
    );
  }
}

MeetupPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  doctype: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  profile: PropTypes.object.isRequired
};

MeetupPanel.defaultProps = {
  preview: false
};

const mapStateToProps = state => ({
  company: state.company,
  profile: state.profile
});

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(MeetupPanel))
);
