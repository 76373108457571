import { validateURLOptional } from "../../Form/validate";
import DeleteAccountForm from "../DeleteAccountForm";
import UpdateEmailForm from "../UpdateEmailForm";
import UpdatePasswordForm from "../UpdatePasswordForm";

const pages = [
  {
    name: "Your Profile",
    formFields: [
      {
        id: "profile_header",
        label: "Edit your Profile",
        helperText: "Update your account details.",
        type: "header"
      },
      {
        label: "First Name",
        id: "first_name",
        limit: 40,
        required: true,
        width: 330
      },
      {
        label: "Last Name",
        id: "last_name",
        limit: 40,
        required: true,
        width: 330
      },
      {
        id: "job_title",
        label: "Job Title / Position",
        limit: 40,
        // required: true,
        width: 330
      },

      {
        id: "phone_number",
        label: "Contact phone number",
        // helperText: "You can choose who to share your number with with.",
        placeholder: "+44...",
        width: 440
      },

      {
        label: "Your Bio",
        id: "bio",
        rows: 4,
        width: 660,
        placeholder:
          "Provide a short bio to introduce yourself, your role and your experience"
      },

      {
        id: "linkedin",
        label: "LinkedIn Profile URL",
        helperText:
          "Sharing your LinkedIn profile allows others to connect, and verify who you are. Must begin with 'http://' or 'https://'.",
        validate: validateURLOptional,
        width: 440
      },
      {
        id: "twitter",
        label: "Twitter Profile URL",
        helperText:
          "Sharing your Twitter profile allows others to connect, and verify who you are. Must begin with 'http://' or 'https://'.",
        validate: validateURLOptional,
        width: 440
      },

      {
        id: "sep_avatar",
        type: "separator",
        label: "Avatar"
      },
      {
        label: "Profile image",
        id: "avatar_image_url",
        helperText:
          "Profile image should be square and a minimum 400 x 400 pixels for the best results, just like Twitter.",
        type: "image",
        // required: true,
        radius: 50,
        height: 100,
        width: 100
      }
    ]
  },

  {
    name: "Email Address",
    formFields: [
      {
        id: "email_header",
        label: "Update your Email",
        type: "header"
      },
      {
        id: "update_email_form",
        type: "component",
        component: UpdateEmailForm
      }
    ]
  },

  {
    name: "Update Password",
    formFields: [
      {
        id: "password_header",
        label: "Update your Password",
        type: "header"
      },
      {
        id: "update_password_form",
        type: "component",
        component: UpdatePasswordForm
      }
    ]
  },

  {
    name: "Delete Account",
    formFields: [
      {
        id: "delete_account_header",
        label: "Delete your account",
        type: "header"
      },
      {
        id: "delete_account_form",
        label: "TODO: delete account",
        type: "component",
        component: DeleteAccountForm
      }
    ]
  }
];

export default pages;
