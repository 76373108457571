import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ContactCardSmall from "../../Contacts/ContactCardSmall";
import FinanceStats from "../../Company/FinanceStats";
import InvestorsTable from "../../Company/InvestorsTable";
import ProfileTable from "../../Company/ProfileTable";

import MemberCompanySection from "./MemberCompanySection";

const styles = {
  root: {
    fontSize: 14
  },
  contactRoot: {
    marginBottom: 30
  },
  content: {
    paddingBottom: 30
    //borderBottom: "1px solid #EDEDEF"
  },
  description: {
    fontSize: 14
  },

  header: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 30
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 30
  },
  sectionRoot: {
    borderTop: "1px solid #EDEDEF"
  }
};

const renderCompany = props => {
  const { classes, company, openCompany } = props;

  return (
    <MemberCompanySection
      header="Company Details"
      classes={{ root: classes.sectionRoot }}
      open={openCompany}
    >
      <div className={classes.name}>{company.name}</div>
      <div className={classes.description}>{company.description}</div>
      <div className={classes.content}>
        <ProfileTable company={company} />
      </div>
    </MemberCompanySection>
  );
};

const renderFunding = props => {
  const { classes, company, openFunding } = props;

  if (company.is_corporate) return null;

  return (
    <MemberCompanySection
      header="Funding"
      classes={{ root: classes.sectionRoot }}
      open={openFunding}
    >
      <FinanceStats company={company} />
      {false && (
        <InvestorsTable companyId={company.id} investors={company.investors} />
      )}
    </MemberCompanySection>
  );
};

const renderTeam = props => {
  const { classes, company, openTeam } = props;

  const members = company.managers || company.team || [];
  const team =
    company.is_founder || company.is_corporate
      ? members.filter(user => user.is_public)
      : members;

  if (team.length === 0) {
    return (
      <MemberCompanySection
        header="Team"
        classes={{ root: classes.sectionRoot }}
        open={true}
      >
        <div style={{ marginBottom: 30 }}>no public contacts</div>
      </MemberCompanySection>
    );
  }

  return (
    <MemberCompanySection
      header={`Team (${team.length})`}
      classes={{ root: classes.sectionRoot }}
      open={openTeam}
    >
      {team.map((founder, index) => (
        <ContactCardSmall
          classes={{ root: classes.contactRoot }}
          key={index}
          {...founder}
          showEmail={true}
          showSocial={false}
        />
      ))}
    </MemberCompanySection>
  );
};

const MemberCompany = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      {renderCompany(props)}
      {renderTeam(props)}
      {renderFunding(props)}
    </div>
  );
};

MemberCompany.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  openCompany: PropTypes.bool,
  openFunding: PropTypes.bool,
  openTeam: PropTypes.bool
};

export default withStyles(styles)(MemberCompany);
