import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadRefreshToken, renewAccessToken } from "../../api/tokenApi";
import { fetchCountries } from "../../Reducers/Countries";
import { fetchTags } from "../../Reducers/Tags";
import { fetchTechnologies } from "../../Reducers/Technologies";
import { loadConfig } from "../../Reducers/Config";

import {
  loadUserData,
  logout,
  loadProfile,
  updateUserData,
  updateProfileData
} from "../../Reducers/Profile";

import Routes from "../../Routes";

class SessionContainer extends React.Component {
  userDataInterval = null;

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    // user not logged in or just logged off
    if (!nextProps.profile.id && prevState.profile_id) {
      return {
        profile_id: nextProps.profile.id,
        userJustLoggedOff: true,
        userJustLoggedOn: false
      };
    }

    // user logged in now
    if (nextProps.profile.id && !prevState.profile_id) {
      return {
        profile_id: nextProps.profile.id,
        userJustLoggedOn: true,
        userJustLoggedOff: false
      };
    }

    return null;
  }

  componentDidMount() {
    this.loadCommonData();
    this.loadUserProfile();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.userJustLoggedOn) {
      this.setUserDataInterval();

      this.setState({ userJustLoggedOn: false });
    }

    if (this.state.userJustLoggedOff) {
      this.clearUserDataInterval();

      this.setState({ userJustLoggedOff: false });
    }
  }

  componentWillUnmount() {
    this.clearUserDataInterval();
  }

  clearUserDataInterval() {
    if (this.userDataInterval) {
      clearInterval(this.userDataInterval);
      this.userDataInterval = null;
    }
  }

  loadCommonData = () => {
    this.props.loadConfig();
    this.props.fetchCountries();
    this.props.fetchTags();
    this.props.fetchTechnologies();
  };

  loadUserProfile = () => {
    const refresh = loadRefreshToken();

    if (refresh) {
      renewAccessToken()
        .then(() => {
          this.props.loadProfile();
        })
        .then(() => {
          // load initial user data
          this.props.loadUserData();

          // update user data every 10 minutes
          this.setUserDataInterval();
        })
        .catch(error => {
          console.log(error);
          console.error("Access token renewal failed.");

          this.props.logout();
        });
    } else {
      // guest user: reset profile loading state
      this.props.updateProfileData({ loading: false });
    }
  };

  setUserDataInterval = () => {
    // remove existing intervals, just in case
    this.clearUserDataInterval();

    this.userDataInterval = setInterval(
      this.props.updateUserData,
      1000 * 60 * 10
    );
  };

  render() {
    return <Routes />;
  }
}

const mapStateToProps = state => {
  return {
    config: state.config,
    countries: state.contries,
    tags: state.tags,
    technologies: state.technologies,
    profile: state.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadConfig: () => dispatch(loadConfig()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchTags: () => dispatch(fetchTags()),
    fetchTechnologies: () => dispatch(fetchTechnologies()),

    // profile related
    loadProfile: () => dispatch(loadProfile()),
    loadUserData: () => dispatch(loadUserData()),
    logout: () => dispatch(logout()),

    // update functions
    updateUserData: () => dispatch(updateUserData()),
    updateProfileData: data => dispatch(updateProfileData(data))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionContainer);
