import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";

import { withStyles } from "@material-ui/core/styles";

import { customStyles, styles } from "./styles";

const diffValueObject = (a, b) => {
  try {
    if (a.value === b.value && a.label === b.label) return false;

    return true;
  } catch (error) {
    return false;
  }
};

class FormSelectField extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { disabled, error, helperText, options, value } = this.props;

    if (disabled !== nextProps.disabled) {
      return true;
    }

    if (error !== nextProps.error) {
      return true;
    }

    if (helperText !== nextProps.helperText) {
      return true;
    }

    if (options !== nextProps.options) {
      return true;
    }

    if (value !== nextProps.value) {
      if (Array.isArray(value)) {
        return value.length !== nextProps.value.length;
      }

      return diffValueObject(value, nextProps.value);
    }

    return false;
  }

  render() {
    const {
      classes,
      disabled,
      label,
      helperText: _hText,
      options,
      required,
      SelectComponent,
      sort,
      width,
      ...other
    } = this.props;

    const helperText = typeof _hText === "object" ? _hText.error : _hText;
    const htStyle = this.props.error ? { color: "#DB6E53" } : {};
    const labelStyle = this.props.error ? { color: "#DB6E53" } : {};

    const _options = options.slice();
    if (sort) {
      _options.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
    }

    const _label = required ? `${label} (required)` : label;

    return (
      <div className={classes.root} style={{ width }}>
        {label && (
          <div className={classes.label} style={labelStyle}>
            {_label}
          </div>
        )}

        <SelectComponent options={_options} isDisabled={disabled} {...other} />

        {helperText && (
          <div className={classes.helperText} style={htStyle}>
            {helperText}
          </div>
        )}
      </div>
    );
  }
}

FormSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  closeMenuOnSelect: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  SelectComponent: PropTypes.func.isRequired,
  sort: PropTypes.bool,
  styles: PropTypes.object
};

FormSelectField.defaultProps = {
  closeMenuOnSelect: true,
  isMulti: true,
  SelectComponent: Select,
  sort: false,
  styles: customStyles
};

export { FormSelectField, styles };
export default withStyles(styles)(FormSelectField);
