import { ADD_TECHNOLOGY, SET_TECHNOLOGIES } from "./TechnologiesActions";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_TECHNOLOGY:
      return [...state, action.payload].sort((a, b) => {
        return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
      });

    case SET_TECHNOLOGIES:
      return action.payload;

    default:
      return state;
  }
};
