import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import WhatshotIcon from "@material-ui/icons/Whatshot";
import { withStyles } from "@material-ui/core/styles";

import { SecondaryButton, SecondaryButtonBlue } from "../common/Buttons";
import FlashIcon from "../Icons/FlashCircle";

import formStyles from "../../styles/formStyles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    marginRight: 4,
    fontSize: 20
  },
  search: {
    ...formStyles.input,
    fontWeight: 400,
    lineHeight: "20px",
    marginLeft: 10,
    width: 220
  }
};

const renderAdminButton = (props, id, label, Icon = null) => {
  const { classes, company, include, setInclude } = props;

  if (!company || company.id !== 69) return null;

  const Button = include[id] ? SecondaryButtonBlue : SecondaryButton;

  return (
    <Button onClick={() => setInclude({ ...include, [id]: !include[id] })}>
      {Icon ? <Icon className={classes.icon} /> : null}
      {label}
    </Button>
  );
};

const renderButton = (props, id, label, Icon = null) => {
  const { classes, sort, setSort } = props;

  const Button = id === sort ? SecondaryButtonBlue : SecondaryButton;

  return (
    <Button onClick={() => setSort(id)}>
      {Icon ? <Icon className={classes.icon} /> : null}
      {label}
    </Button>
  );
};

const MarketplaceListSearchBar = props => {
  const { classes, filter, setFilter } = props;

  return (
    <div className={classes.root}>
      {renderButton(props, "recent", "Most Recent", FlashIcon)}
      {renderButton(props, "popular", "Popular", WhatshotIcon)}

      <div style={{ flexGrow: 1 }} />
      {renderAdminButton(props, "draft", "Draft")}
      {renderAdminButton(props, "archived", "Archived")}

      <input
        className={classes.search}
        onChange={evt => setFilter(evt.target.value)}
        placeholder="Search"
        value={filter}
      />
    </div>
  );
};

MarketplaceListSearchBar.propTypse = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,

  filter: PropTypes.string.isRequired,
  include: PropTypes.object.isRequired,
  sort: PropTypes.string.isRequired,

  setFilter: PropTypes.func.isRequired,
  setInclude: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired
};

MarketplaceListSearchBar.defaultProps = {
  filter: "",
  sort: "recent",
  include: {},
  setFilter: () => {},
  setInclude: () => {},
  setSort: () => {}
};

const mapStateToProps = state => ({ company: state.company });

export { MarketplaceListSearchBar, styles };
export default withStyles(styles)(
  connect(
    mapStateToProps,
    null
  )(MarketplaceListSearchBar)
);
