import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import FormStep from "./FormStep";

const styles = {
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: 80,
    width: 800,
    margin: "0 auto 40px auto"
  }
};

const FormStepper = props => {
  const {
    activeStep,
    allowForwardNavigation,
    classes,
    onClick,
    steps,
    width
  } = props;

  let style = {};
  // if (width === "md") style = { width: 1000 };
  if (width === "sm") style = { width: "90vw" };

  const stepWidth = `${parseInt(95 / steps.length, 10)}%`;

  return (
    <div id="formStepper" className={classes.root} style={style}>
      {steps.map((step, index) => {
        return (
          <FormStep
            key={step.label}
            {...step}
            active={activeStep === index}
            connector={index !== 0}
            onClick={onClick ? () => onClick(index) : null}
            showLink={allowForwardNavigation || index < activeStep}
            step={index + 1}
            width={stepWidth}
          />
        );
      })}
    </div>
  );
};

FormStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  allowForwardNavigation: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      completed: PropTypes.bool
    })
  ).isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

export { FormStepper, styles };
export default withStyles(styles)(FormStepper);
