import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";

import EligibilitySummary from "../Form/ApplicationReview/EligibilitySummary";

const styles = {
  root: {
    margin: 30
  },
  criteriaHeader: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    backgroundColor: "#AAADB6",
    color: "white",
    fontSize: 13,
    fontWeight: 600,
    height: 60,
    padding: "15px 30px"
  },
  criteriaRoot: {
    margin: 0
  },
  criteriaStats: {
    display: "flex",
    alignItems: "center",
    color: "#2A2B2C",
    fontSize: 14,
    marginBottom: 30
  },
  criterion: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",

    color: "#2A2B2C",
    fontSize: 14,

    minHeight: 60,
    padding: "15px 30px",

    boxShadow: "inset 0 -1px 0 0 #E6E7E9"
  },

  icon: {
    fill: "#85C97C",
    fontSize: 20,
    marginRight: 5
  }
};

const EligibilityTab = props => {
  const { classes, company, criteria } = props;

  const nCr = criteria.length;
  const met = criteria.filter(c => c.response === "Yes").length;
  const msg = `'${company.name}' meets ${met}/${nCr} eligibility criteria`;
  const Icon = met !== nCr ? WarningIcon : CheckIcon;
  const style = met !== nCr ? { fill: "#DB6E53" } : {};

  return (
    <div className={classes.root}>
      <div className={classes.criteriaStats}>
        <Icon className={classes.icon} style={style} />
        <div>{msg}</div>
      </div>

      <div className={classes.criteriaHeader}>
        <div>Eligibility Criteria</div>
        <div>Criteria Met</div>
      </div>

      <EligibilitySummary
        classes={{ root: classes.criteriaRoot, criterion: classes.criterion }}
        criteria={criteria}
      />
    </div>
  );
};

EligibilityTab.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  criteria: PropTypes.array.isRequired
};

EligibilityTab.defaultProps = {
  company: {},
  criteria: []
};

export { EligibilityTab, styles };
export default withStyles(styles)(EligibilityTab);
