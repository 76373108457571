import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

import { createFlashNote } from "../FlashNotes";

import * as api from "./api";

// add a set of applications for a given program or challenge
export const ADD_APPLICATIONS = "SET_APPLICATIONS";
export const REMOVE_APPLICATION = "REMOVE_APPLICATION";
export const SET_APPLICATION_PENDING = "SET_APPLICATION_PENDING";
export const SET_APPLICATIONS_LOADING_STATE = "SET_APPLICATIONS_LOADING_STATE";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const UPDATE_APPLICATIONS = "UPDATE_APPLICATIONS";

export const addApplications = applications => {
  return {
    type: ADD_APPLICATIONS,
    payload: applications
  };
};

export const removeApplication = url => {
  return {
    type: REMOVE_APPLICATION,
    payload: url
  };
};

export const setApplicationsLoadingState = (documentUrl, value = "loading") => {
  return {
    type: SET_APPLICATIONS_LOADING_STATE,
    payload: { documentUrl, value }
  };
};

export const setApplicationPending = docUrl => {
  return {
    type: SET_APPLICATION_PENDING,
    payload: docUrl
  };
};

export const updateApplication = state => {
  return {
    type: UPDATE_APPLICATION,
    payload: state
  };
};

export const updateApplications = applications => {
  return {
    type: UPDATE_APPLICATIONS,
    payload: applications
  };
};

// load applications for a given program/challenge
export const fetchApplications = (fetchUrl, details) => {
  // strip query params from applications list url
  return dispatch => {
    // console.log("fetchUrl:", fetchUrl);
    dispatch(setApplicationsLoadingState(fetchUrl, "loading"));

    return api
      .fetchApplications(fetchUrl)
      .then(json => {
        const { error, applications } = json;
        if (error) throw error;

        dispatch(addApplications(applications));
        dispatch(setApplicationsLoadingState(fetchUrl, "done"));

        // load details on application
        if (details) {
          fetchDetails(applications, dispatch);
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(setApplicationsLoadingState(fetchUrl, "done"));
      });
  };
};

// load application details from server and merge result
// with existing application
const fetchDetails = (applications, dispatch) => {
  Promise.all(
    applications.map(app => {
      return api.fetchApplicationByUrl(app.url).then(details => ({
        ...app,
        ...details
      }));
    })
  )
    .then(results => {
      dispatch(addApplications(results));
    })
    .catch(error => this.setState({ error }));
};

const approveDeclineApplication = (url, action, data) => {
  const apiUrl = url.endsWith("/") ? `${url}${action}/` : `${url}/${action}/`;

  return dispatch => {
    dispatch(updateApplication({ url, _pending: true }));

    return fetchWithToken(apiUrl, {
      body: JSON.stringify(data),
      method: "PUT"
    })
      .then(response => responseJsonOrError(response))
      .then(json => {
        if (json.error) {
          dispatch(
            createFlashNote("Application status could not be updated", "error")
          );
          dispatch(
            updateApplication({
              url,
              _pending: false,
              error: new Error("The request has failed.")
            })
          );
        } else {
          const status = action === "approve" ? "success" : "info";
          dispatch(
            createFlashNote(`You have ${action}d the application.`, status)
          );
          dispatch(
            updateApplication({
              url,
              _pending: false,
              _statusText: "The request was successful.",
              updated_at: new Date().toISOString(),
              is_approved: 2
            })
          );
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(createFlashNote(error.message, "error"));
        dispatch(
          updateApplication({
            url,
            _pending: false,
            error
          })
        );
      });
  };
};

export const approveDeclineApplications = (applications, action, data) => {
  return dispatch => {
    // set all applications in pending
    const _applications = applications.map(app => ({ ...app, _pending: true }));
    dispatch(updateApplications(_applications));

    // collect all requests
    return Promise.all(
      applications.map(app => {
        return api.approveDeclineApplication(app, action, data);
      })
    )
      .then(applications => {
        const _applications = applications.map(app => {
          return {
            ...app,
            selected: false,
            _pending: false
          };
        });

        dispatch(updateApplications(_applications));

        // show flash note with error count
        const errors = applications.filter(app => app.error).length;
        if (errors) {
          const msg =
            errors === 1
              ? "There was 1 error during the update."
              : `There were ${errors} errors during the update.`;

          dispatch(createFlashNote(msg, "error"));
        }

        // show flash note with success count
        const success = applications.filter(app => !app.error).length;
        const msg =
          success === 1
            ? "1 application was updated successfully."
            : `${success} applications were updated successfully.`;

        dispatch(createFlashNote(msg, "success"));
      })
      .catch(error => {
        console.error(error);
        dispatch(createFlashNote(error.message, "error"));

        // reset application pending state
        const _applications = applications.map(app => ({
          ...app,
          _pending: false
        }));
        dispatch(updateApplications(_applications));
      });
  };
};

export const approveApplication = (url, data = {}) => {
  return approveDeclineApplication(url, "approve", data);
};

export const declineApplication = (url, data) => {
  return approveDeclineApplication(url, "decline", data);
};

export const approveApplications = (applications, data = {}) => {
  return approveDeclineApplications(applications, "approve", data);
};

export const declineApplications = (applications, data) => {
  return approveDeclineApplications(applications, "decline", data);
};

export const loadProgramApplications = documentId => {
  // limit participants list to pending applications
  const url = `/api/programs/${documentId}/participants/?limit=1000`;

  return fetchApplications(url, true);
};

export const loadThinkTankApplications = documentId => {
  // limit participants list to pending applications
  const url = `/api/think_tanks/${documentId}/participants/?limit=1000`;

  return fetchApplications(url, true);
};
