import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import TagLabel from "../common/TagLabel";

const styles = {
  root: {
    marginTop: 24,
    marginBottom: 24,
    paddingTop: 24,
    borderTop: "1px solid lightgrey"
  },
  title: {
    fontSize: 16,
    color: "#AAADB6",
    fontWeight: 600,
    marginBottom: 16
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: -5
  },
  placeholder: {
    lineHeight: "32px"
  },
  tagRoot: {
    marginRight: 5,
    marginTop: 5
  }
};

const DocumentTopics = props => {
  const { classes, topics, ...other } = props;

  const arr =
    typeof topics === "string"
      ? topics.split(",").filter(string => string)
      : topics;

  const badges =
    arr.length === 0 ? (
      <div className={classes.placeholder}>no topics selected</div>
    ) : (
      arr.map(topic => (
        <TagLabel
          key={topic.name || topic}
          classes={{ root: classes.tagRoot }}
          label={topic}
          color="blue"
        />
      ))
    );

  return (
    <div className={classes.root} {...other}>
      <div className={classes.title}>Markets</div>
      <div className={classes.container}>{badges}</div>
    </div>
  );
};

DocumentTopics.propTypes = {
  title: PropTypes.string.isRequired,
  topics: PropTypes.array.isRequired
};

DocumentTopics.defaultProps = {
  title: "Topics",
  topics: []
};

export default withStyles(styles)(DocumentTopics);
