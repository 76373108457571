import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ReferralDialog from "../Startups/ReferStartupDialog";
import { SecondaryButton, SecondaryButtonSmall } from "./Buttons";

const styles = {
  root: {
    lineHeight: "20px",
    whiteSpace: "nowrap"
  },
  button: {
    height: 20,
    borderRadius: 2
  },
  label: {
    fontSize: "13px",
    lineHeight: "20px",
    justifyContent: "start",
    color: "#398DD3",
    fontWeight: 700
  }
};

class ReferralButton extends React.Component {
  state = {
    modal: null
  };

  showReferralDialog = () => {
    const { referral } = this.props;
    const { recipient_company_name, message, recipient } = referral;

    const modal = (
      <ReferralDialog
        title="Resend invite"
        recipient={recipient}
        recipient_company_name={recipient_company_name || ""}
        message={message || ""}
        onClose={() => {
          this.setState({ modal: null });
        }}
      />
    );
    this.setState({ modal });
  };

  render() {
    const { children, classes, label, size, ...other } = this.props;

    const Button = size === "small" ? SecondaryButtonSmall : SecondaryButton;

    return (
      <div className={classes.root}>
        {this.state.modal}
        <Button
          aria-label="send referral"
          {...other}
          onClick={this.showReferralDialog}
        >
          {label || children}
        </Button>
      </div>
    );
  }
}

ReferralButton.defaultProps = {
  label: "",
  size: "normal"
};

ReferralButton.propTypes = {
  label: PropTypes.string,
  referral: PropTypes.object.isRequired,
  size: PropTypes.oneOf(["small", "normal"])
};

export default withStyles(styles)(ReferralButton);
