import React from "react";

import imgInvAvatar from "../assets/images/for-investors/colinked-for-investors-avatar.png";
import imgInvCustomers from "../assets/images/for-investors/colinked-for-investors-high-quality-customers.jpg";
import imgDueDiligence from "../assets/images/for-investors/colinked-for-investors-due-diligence.jpg";
import imgInvPlatform from "../assets/images/for-investors/colinked-for-investors-bespoke-platform.jpg";
import imgLogoFieldfisher from "../assets/images/for-investors/investor-logos/colinked-fieldfisher-logo.png";
import imgLogoTripplePoint from "../assets/images/for-investors/investor-logos/colinked-triple-point-logo.png";
import imgLogoSCC from "../assets/images/for-investors/investor-logos/colinked-source-code-control-logo.png";

import imgFormCharacters from "../assets/images/homepage/colinked-homepage-form-characters.png";

import SignupForm from "./common/SignupForm";

const ForInvestors = () => {
  return (
    <div>
      <section className="page-header-centered">
        <p className="uppercase">For Investors</p>
        <h1>
          Get access to a pipeline of <span>high quality</span> deal flow with
          completed <span>due diligence</span>
        </h1>
      </section>

      <section className="about-panel investor-about">
        <div className="content">
          <img
            src={imgInvAvatar}
            alt="Colinked - A character avatar portraying finance"
          />
          <h2>What is Colinked Capital?</h2>
          <p>
            Colinked Capital is an investment management platform which gives
            professional investors access to top quality dealflow. Each company
            comes directly from Colinked and has been verified suitable for
            fundraising by our internal investment team and has completed
            thorough due diligence with our partners.
          </p>
        </div>
      </section>

      <section className="content-padding-large investor-benefits">
        <section className="benefit">
          <div className="image left">
            <img
              src={imgInvCustomers}
              alt="Colinked - people pointing and searching for customers on a laptop."
            />
          </div>

          <div className="text">
            <h2>Find High Quality Companies</h2>
            <p>
              On Colinked, all founders have a direct relationship with one of
              our many global corporates. Whether co-sell agreement or part of
              an innovation engagement program, we know our founders are solving
              big problems and have established strategic corporate partners.
              All qualifying companies will be posted on the Colinked Capital
              website.
            </p>
          </div>
        </section>

        <section className="benefit reverse">
          <div className="text left">
            <h2>Completed Due Diligence</h2>
            <p>
              We’ve partnered with leading firms to conduct the necessary due
              diligence to ensure each company is of high calibre and ready to
              scale their business. We guarantee a complete level of legal,
              financial and technical due diligence with standard comprehensive
              reports all within a secure data room on the system.
            </p>
          </div>

          <div className="image">
            <img
              src={imgDueDiligence}
              alt="Colinked - people shaking hands in a meeting portraying due diligence."
            />
          </div>
        </section>

        <section className="benefit">
          <div className="image left">
            <img
              src={imgInvPlatform}
              alt="Colinked - a planning meeting with laptops."
            />
          </div>

          <div className="text">
            <h2>A Bespoke Platform</h2>
            <p>
              Aside from pipeline discovery, you’ll be able to manage your
              portfolio through Colinked Capital. Regular reporting, cap table
              management and inter-team collaboration are all implemented
              features to streamline your management.
            </p>
          </div>
        </section>
      </section>

      <section className="logo-grid investor-partners">
        <h2>Our Partners</h2>
        <p>The partners who help scale and execute our due diligence.</p>

        <div className="grid-items" style={{ textAlign: "center" }}>
          <img
            src={imgLogoFieldfisher}
            alt="Colinked - Fieldfisher logo"
            style={{ float: "none" }}
          />

          <img
            src={imgLogoTripplePoint}
            alt="Colinked - Triple Point logo"
            style={{ float: "none" }}
          />

          <img
            src={imgLogoSCC}
            alt="Colinked - Source Code Control logo"
            style={{ float: "none" }}
          />
        </div>
      </section>

      <section className="form-section">
        <div className="form-container">
          <h2>Join Colinked Capital</h2>
          <p>
            Get in touch and we will send you details on how to join our
            investment platform.
          </p>

          <SignupForm
            buttonLable="Request Access"
            showLinkedin={false}
            url="/api/companies/investors/apply/"
          />

          <img
            src={imgFormCharacters}
            alt="Colinked - Character avatars shaking hands"
            width={243}
            height={198}
            style={{ marginLeft: -10 }}
          />
        </div>
      </section>
    </div>
  );
};

export default ForInvestors;
