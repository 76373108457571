import { responseJsonOrError } from "../../api/response";
import { saveTokens } from "../../api/tokenApi";

export const validateToken = (url, token) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ registration_token: token }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };

  return fetch(url, options)
    .then(responseJsonOrError)
    .then(json => {
      const { error } = json;

      // strip generic 400 info from error message
      if (error && error.message.startsWith("400 (Bad Request)")) {
        const message = error.message
          .split("\n")
          .slice(1)
          .join("\n");

        return { ...json, error: new Error(message) };
      }

      return json.company
        ? {
            ...json,
            company_name: json.company.name || ""
          }
        : json;
    });
};

export const submitRegistration = (url, data, token = "") => {
  // convert object to FormData
  // const formData = new FormData();
  // Object.keys(data).forEach(key => {
  //   formData.append(key, data[key]);
  // });

  const body = token
    ? JSON.stringify({ ...data, registration_token: token })
    : JSON.stringify(data);

  return fetch(url, {
    body,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(responseJsonOrError)
    .then(json => {
      if (!json.error) {
        saveTokens(json.jwt_token);
      }

      return json;
    })
    .catch(error => ({ error }));
};
