import React from "react";
import { Provider } from "react-redux";

import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

import FlashNotes from "./components/FlashNotes";
import SessionContainer from "./components/Session";

import store from "./store";
import theme from "./theme";

import "./App.css";

import JssProvider from "react-jss/lib/JssProvider";
import { create } from "jss";
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.persistor = persistStore(store);

    this.generateClassName = createGenerateClassName();
    this.jss = create(jssPreset());
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={this.persistor}>
          <JssProvider
            jss={this.jss}
            generateClassName={this.generateClassName}
          >
            <MuiThemeProvider theme={theme}>
              <FlashNotes />
              {/* <Routes /> */}
              <SessionContainer />
            </MuiThemeProvider>
          </JssProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
