import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import placeholderImage from "../../../assets/documents_placeholder.png";

const styles = {
  root: {
    textAlign: "center"
  },
  title: {
    color: "#6592CC",
    fontSize: 18,
    fontWeight: 600,
    marginTop: 60
  },
  subtitle: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    marginTop: 20,
    marginBottom: 25
  },
  img: {
    marginTop: 65,
    maxWidth: "80%",
    marginBottom: -8
  }
};

const DocsListPlaceholder = props => {
  const { children, classes, subtitle, title } = props;

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      {subtitle ? <div className={classes.subtitle}>{subtitle}</div> : null}
      <div>{children}</div>
      <img className={classes.img} src={placeholderImage} alt="placeholder" />
    </div>
  );
};

const DocumentsListPlaceholder = withStyles(styles)(DocsListPlaceholder);

DocumentsListPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

DocumentsListPlaceholder.defaultProps = {
  title: "No documents have been created yet :("
};

const ProgramsListPlaceholder = props => {
  return <DocumentsListPlaceholder {...props} />;
};

ProgramsListPlaceholder.defaultProps = {
  title: "No programs have been created yet :(",
  subtitle:
    "Programs are offerings and events which founder companies, and corporates can apply to participate in."
};

const ThinkTanksListPlaceholder = props => {
  return <DocumentsListPlaceholder {...props} />;
};

ThinkTanksListPlaceholder.defaultProps = {
  title: "No Think Tanks have been created yet :(",
  subtitle:
    "Think Tanks encourage cooperation between corporations, or corporates and founders."
};

export { ProgramsListPlaceholder, ThinkTanksListPlaceholder };

export default DocumentsListPlaceholder;
