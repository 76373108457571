import React from "react";

import { responseJsonOrError } from "../../api/response";

import CommunitiesPage from "./CommunitiesPage";

class CommunitiesPageContainer extends React.Component {
  state = {
    events: []
  };
  componentDidMount() {
    fetch("/api/events/?limit=50")
      .then(response => responseJsonOrError(response))
      .then(json => json.results)
      .then(events => this.setState({ events }))

      .catch(err => {
        const error = new Error(
          `An error occured while loading calendar events. (${err})`
        );

        this.setState({ error });
      });
  }

  render() {
    return <CommunitiesPage events={this.state.events} />;
  }
}

export default CommunitiesPageContainer;
