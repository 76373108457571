import React from "react";
import { PropTypes } from "prop-types";
import moment from "moment";

import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

import BooleanField from "../Form/BooleanField";
import DateTimeSelector from "../Form/DateTimeSelector";
import ExpansionSection from "../Form/ExpansionSection";

import Icons from "../Icons";

const styles = {
  buttonIcon: {
    marginRight: 4
  },
  buttonLabelReopen: {
    color: "green"
  },
  buttonLabelWarning: {
    color: "darkred"
  },
  deadlineCloseButton: {
    marginLeft: 24,
    marginTop: 44,
    height: 40
  }
};

const getDefaultDeadline = (nDays = 30) => {
  const maxDate = moment().add(nDays, "days");

  return maxDate.toISOString();
};

class DeadlineInputSection extends React.Component {
  static propTypes = {
    deadline_datetime: PropTypes.string,
    doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
    is_ongoing: PropTypes.bool.isRequired,
    state: PropTypes.string,
    update: PropTypes.func.isRequired,
    runtime: PropTypes.number.isRequired
  };

  static defaultProps = {
    deadline_datetime: getDefaultDeadline(),
    runtime: 9999,
    state: "open"
  };

  constructor(props) {
    super(props);

    const firstPublishedDate = props.firstPublishedDate
      ? moment(props.firstPublishedDate)
      : moment();

    this.maxDeadline = firstPublishedDate
      .endOf("day")
      .add(props.runtime, "days");

    this.state = {
      _deadlineError: null,
      state: props.state,
      deadline_datetime: props.deadline_datetime,
      is_ongoing: props.is_ongoing
    };
  }

  setDeadline = deadline_datetime => {
    let _deadlineError = null;

    if (moment(deadline_datetime).isAfter(this.maxDeadline)) {
      _deadlineError = `Please select a date before ${this.maxDeadline.format(
        "MMM Do"
      )}.`;
    }

    if (moment(deadline_datetime).isSameOrBefore(moment(), "day")) {
      _deadlineError = `Please select a date after today.`;
    }

    if (_deadlineError) {
      this.setState({ _deadlineError });
    } else {
      this.update({ deadline_datetime, _deadlineError: null });
    }
  };

  setOngoing = value => {
    this.update({ is_ongoing: value, _deadlineError: null });

    // restore deadline error message if is_ongoing is false
    if (!value) {
      this.setDeadline(this.state.deadline_datetime);
    }
  };

  getActionButton = () => {
    return null;
    // eslint-disable-next-line no-unreachable
    const { classes } = this.props;
    const { state } = this.state;

    // default options: application is open
    let icon = <Icons.CloseCircle className={classes.buttonIcon} />;
    let labelClass = classes.buttonLabelWarning;
    let options = {
      onClick: () => this.update({ state: "closed" })
    };
    let text = "Close for applications";

    // if max deadline_datetime has passed, keep closed for applications
    if (moment().isAfter(this.maxDeadline)) {
      // console.log("isAfter deadline_datetime");
      icon = null;
      options = {
        disabled: true,
        onClick: null
      };
      text = "closed for applications";
    }

    // state is closed and max deadline_datetime has not passed, allow reopen
    if (state === "closed" && moment().isBefore(this.maxDeadline)) {
      labelClass = classes.buttonLabelReopen;
      icon = null;
      options = {
        onClick: () => this.update({ state: "open" })
      };
      text = "Reopen for applications";
    }

    return (
      <Button
        size="small"
        classes={{
          root: classes.deadlineCloseButton,
          label: labelClass
        }}
        {...options}
      >
        {icon}
        {text}
      </Button>
    );
  };

  update = state => {
    const { _deadlineError, ...options } = state;

    this.setState(state, this.props.update(options));
  };

  render() {
    const { _deadlineError, deadline_datetime } = this.state;
    const value = new Date(deadline_datetime).toISOString();

    const deadlineInput = (
      <DateTimeSelector
        date={value}
        error={Boolean(_deadlineError) || this.props.error}
        label={_deadlineError || "Please pick a date"}
        onChange={this.setDeadline}
        helperText={this.props.helperText}
        disabled={Boolean(this.state.is_ongoing)}
      />
    );

    const ongoingInput =
      this.props.doctype === "program" ? (
        <BooleanField
          id="is_ongoing"
          label="This is an ongoing program without deadline"
          onChange={this.setOngoing}
          value={Boolean(this.state.is_ongoing)}
        />
      ) : null;

    return (
      <ExpansionSection
        title="Deadline"
        summaryText={moment(deadline_datetime).format("llll")}
        panelProps={{ defaultExpanded: true }}
      >
        <div style={{ display: "flex", flexGrow: 1 }}>
          <div style={{ flexGrow: 1 }}>
            {deadlineInput}
            {ongoingInput}
          </div>
          {this.getActionButton()}
        </div>
      </ExpansionSection>
    );
  }
}

export default withStyles(styles)(DeadlineInputSection);
