import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import { getCapitalRange, currency, stage as fundingStages } from "./config";

const styles = {
  root: {},
  row: {
    display: "flex",
    justifyContent: "space-around"
  },
  panel: {
    height: 120,
    width: 300,
    backgroundColor: "white",
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.05)",
    marginBottom: 20
  },
  content: {
    height: 80,
    color: "#2A2B2C",
    fontSize: 22,
    fontWeight: 300,
    lineHeight: "80px",
    textAlign: "center"
  },
  label: {
    lineHeight: "40px",
    textAlign: "center",
    color: "#398DD3",
    fontSize: 14,
    fontWeight: 600,
    borderTop: "1px solid #E6E7E9"
  }
};

const renderPanel = (content = "not available", label, classes) => {
  const style = content === "not available" ? { color: "#AAADB6" } : {};

  return (
    <div className={classes.panel}>
      <div className={classes.content} style={style}>
        {content}
      </div>
      <div className={classes.label}>{label}</div>
    </div>
  );
};

const formatRange = (id, company, map) => {
  const value = map[company[id]] || company[id] || "n/a";
  const _currency = company[`${id}_currency`];

  return `${currency.symbol[_currency] || _currency} ${value}`;
};

const FinanceStats = props => {
  const { classes, company, config, rows } = props;
  const { funding_rounds, stage } = company;

  const capital_range = getCapitalRange(config);
  const revenue_range = getCapitalRange(config);

  const _rounds = renderPanel(funding_rounds || 0, "Funding Rounds", classes);
  const _stage = renderPanel(
    fundingStages.map[stage] || stage,
    "Last Funding Stage",
    classes
  );
  const _total = renderPanel(
    formatRange("capital_to_date", company, capital_range.map),
    "Total Funding Raised",
    classes
  );
  const _revenue = renderPanel(
    formatRange("revenue_range", company, revenue_range.map),
    "Annual Revenue Range",
    classes
  );

  if (rows === 1) {
    return (
      <div className={classes.root}>
        {_rounds}
        {_stage}
        {_total}
        {_revenue}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {_rounds}
        {_stage}
      </div>
      <div className={classes.row}>
        {_total}
        {_revenue}
      </div>
    </div>
  );
};

FinanceStats.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.shape({
    capital_to_date: PropTypes.string,
    funding_rounds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    revenue_range: PropTypes.string,
    stage: PropTypes.string
  }),
  config: PropTypes.object.isRequired,
  rows: PropTypes.number.isRequired
};

FinanceStats.defaultProps = {
  rows: 2
};

const mapStateToProps = state => ({ config: state.config });

export { FinanceStats, styles };
export default connect(mapStateToProps)(withStyles(styles)(FinanceStats));
