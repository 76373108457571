import React from "react";

import ChallengesListViewPage from "../components/Challenges/ChallengesListViewPage";

import PageTemplate from "./PageTemplate";

const Challenges = props => {
  return (
    <PageTemplate>
      <ChallengesListViewPage />
    </PageTemplate>
  );
};

export default Challenges;
