import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { withStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";

const styles = {
  root: {
    padding: 0,
    borderRadius: 0
  },
  buttonCancel: {
    color: "rgba(0,0,0,0.54)",
    position: "absolute",
    top: 15,
    right: 15,
    cursor: "pointer"
  },
  closeIcon: {
    marginLeft: 10,
    cursor: "pointer"
  },
  contentRoot: {
    padding: 0,
    borderRadius: 0,
    "&:first-child:": {
      paddingTop: 0
    }
  },
  paper: {},
  titleContainer: {
    height: 60,
    display: "flex",
    alignItems: "center",
    color: "#2A2B2C",
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: 30,
    paddingRight: 30,
    margin: 0
  }
};

class ScrollableDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true
    };
  }

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ open: false }, () => {
      if (onClose) onClose();
    });
  };

  renderCancelButton = () => {
    const { classes } = this.props;

    return (
      <div
        id="closeDialog"
        className={classes.buttonCancel}
        onClick={this.handleClose}
      />
    );
  };

  render() {
    const { children, classes, scroll, title, maxWidth } = this.props;

    return (
      <Dialog
        classes={{ paper: classes.root }}
        maxWidth={maxWidth}
        open={this.state.open}
        onClose={this.handleClose}
        scroll={scroll}
      >
        <DialogContent
          classes={{ root: classes.contentRoot }}
          style={{ paddingTop: 0 }}
        >
          <div className={classes.titleContainer}>
            <div style={{ flexGrow: 1 }}>{title}</div>
            <CloseIcon
              className={classes.closeIcon}
              onClick={this.handleClose}
            />
          </div>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}

ScrollableDialog.propTypes = {
  scroll: PropTypes.oneOf(["body", "paper"]),
  style: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

ScrollableDialog.defaultProps = {
  title: "some title",
  maxWidth: "md",
  scroll: "body",
  style: {}
};

export default withRouter(withStyles(styles)(ScrollableDialog));
