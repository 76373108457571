import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: { xs: 0, sm: 640, md: 960, lg: 1260, xl: 1920 }
  },
  palette: {
    primary: {
      main: "rgba(64,86,118,1.0)"
    },
    secondary: {
      main: "rgba(64,86,118,1.0)"
    },
    branding: {
      red: "#db6e53",
      green: "#9cb59e",
      gold: "#cea751"
    },
    blue: {
      primary: "rgba(101,146,204,1.0)",
      main: "rgba(101,146,204,1.0)",
      light: "rgba(101,146,204,0.15)",
      dark: "rgba(64,86,118,1.0)"
    },
    yellow: {
      primary: "rgba(200,168,95,1.0)",
      light: "rgba(200,168,95,0.2)",
      dark: "rgba(200,168,95,1.0)"
    },
    neutral: {
      primary: "rgba(0, 0, 0, 0.54)",
      main: "rgba(0, 0, 0, 0.54)",
      light: "#F2F3F4",
      dark: "rgba(0, 0, 0, 0.30)"
    },
    text: {
      primary: "#2A2B2C"
    }
  },
  typography: {
    fontFamily: "Nunito,sans-serif",
    fontWeight: 400,
    body1: {
      fontSize: 14,
      color: "#2A2B2C",
      fontWeight: 300
    },
    body2: {
      fontWeight: 600
    },
    button: {
      //fontStyle: "italic",
      textTransform: "none"
    },
    subheading: {
      fontWeight: 600,
      fontSize: "1.00rem",
      lineHeight: "1.25rem"
      //letterSpacing: "0.1em"
    }
  }
});

//console.log(theme);
export default theme;

export const buttonPrimaryClasses = {
  root: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.1)",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2)"
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: "#2A2B2C"
  },
  iconClass: {
    marginRight: 5,
    fontSize: 20
  }
};

export const outlineButtonClasses = {
  root: {
    height: 36,
    border: "2px solid #2A2B2C",
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.1)",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2)"
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: "#2A2B2C"
  },
  iconClass: {
    marginRight: 5,
    fontSize: 20
  }
};

export const buttonPrimaryLarge = {
  backgroundColor: "#6592CC",
  color: "white",
  height: 60,
  borderRadius: 5,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 0,
  marginLeft: 20,
  "&:nth-child(1)": {
    marginLeft: 0
  },
  boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
  "&:hover": {
    backgroundColor: "#3D5A80",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,0.2)"
  }
};

export const buttonSecondaryLarge = {
  ...buttonPrimaryLarge,
  color: "#2A2B2C",
  backgroundColor: "white",
  border: "1px solid #2A2B2C",
  "&:hover": {
    backgroundColor: "rgba(85,91,110,0.1)",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,0.2)"
  }
};
