import {
  RESET_COMPANY_DATA,
  SET_COMPANY_DATA,
  UPDATE_COMPANY_DATA
} from "./companyActionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case RESET_COMPANY_DATA:
      return {};

    case SET_COMPANY_DATA:
      return { ...action.payload };

    case UPDATE_COMPANY_DATA:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
