export const applications = [
  {
    id: 6,
    company: {
      id: 74,
      name: "Firef.ly",
      logo_image_url:
        "/uploads/Company/image/f51355301e3d4e4fa54c5dc38cd076c1-fair.png",
      is_corporate: false,
      is_founder: true
    },
    is_public: true,
    application_note:
      "Short and sweet.\n\nBuzz disrupt tweens multiple points of entry. Leverage context lean content top influencers dedication. Crowdsource leverage wearables platform. Curated top influencers leverage. Gen Y cross-device B2B lean content flat design viral virality. User-friendly click bait trending seamless platform. Funnel seamless multiple points of entry sticky content seed money content curation.",
    response_note:
      "Congratulations!\n\nYou have been approved to Pear Shaped.\n\nWe are happy to have you on board.",
    created_at: "2018-09-07T07:30:40.943686",
    is_approved: 2
  },
  {
    id: 7,
    company: {
      id: 77,
      name: "Immersivere Hab",
      logo_image_url:
        "/uploads/Company/image/4e259bf0aca6404a844da78a71e827a0-wind_energy.jpeg",
      is_corporate: false,
      is_founder: true
    },
    is_public: true,
    application_note: "super short",
    response_note:
      "Congratulations!\n\nYou have been approved to Pear Shaped.\n\nWe are happy to have you on board.",
    created_at: "2018-09-07T08:07:33.198144",
    is_approved: 2
  },
  {
    id: 9,
    company: { name: "Wolf - Rogahn Group", is_guest: true },
    is_public: true,
    application_note:
      "Deleniti amet consequatur. Exercitationem quae et vel ut. Consequatur autem recusandae et deserunt ex.",
    response_note: null,
    created_at: "2018-09-26T07:08:38.115069",
    is_approved: 1
  },
  {
    id: 10,
    company: { name: "Dickens, Yundt and Nitzsche LLC", is_guest: true },
    is_public: true,
    application_note:
      "Maxime voluptatum omnis sit numquam tempore repellendus. Ut dicta eveniet quae similique reprehenderit. Et molestiae molestias est. Ea ea ut. Expedita mollitia maxime.",
    response_note: null,
    created_at: "2018-09-26T08:03:41.113474",
    is_approved: 1
  }
];

export const details = {
  "/api/programs/6/participants/10/": {
    id: 10,
    email: "Anabel.Crona@example.com",
    is_approved: 1,
    is_public: true,
    response: {
      city: "Cassintown",
      name: "Dickens, Yundt and Nitzsche LLC",
      size: "11-50",
      email: "Anabel.Crona@example.com",
      stage: "seed",
      address: "64831 Cartwright Isle",
      country: 1,
      markets: [89, 61, 67],
      website: "https://www.example.com",
      postcode: "65125",
      active_in: [64, 28, 70],
      is_public: true,
      job_title: "Regional Brand Assistant",
      last_name: "Crona",
      first_name: "Anabel",
      pitch_text:
        "Quibusdam iusto totam officiis voluptatem dolorem. Qui recusandae ipsam excepturi quia et rerum deserunt qui repellendus. Perferendis alias quo quia blanditiis autem cumque est.",
      description: "Incidunt ut id ea eveniet sit inventore veritatis tempora.",
      company_type: "private",
      phone_number: "1-379-526-5061 x430",
      technologies: "customer loyalty",
      year_founded: 2010,
      revenue_range: "0_500000",
      capital_to_date: 500000,
      application_note:
        "Maxime voluptatum omnis sit numquam tempore repellendus. Ut dicta eveniet quae similique reprehenderit. Et molestiae molestias est. Ea ea ut. Expedita mollitia maxime."
    },
    application_note:
      "Maxime voluptatum omnis sit numquam tempore repellendus. Ut dicta eveniet quae similique reprehenderit. Et molestiae molestias est. Ea ea ut. Expedita mollitia maxime.",
    response_note: null,
    created_at: "2018-09-26T08:03:41.113474"
  },
  "/api/programs/6/participants/9/": {
    id: 9,
    email: "Fermin.Oberbrunner@example.com",
    is_approved: 1,
    is_public: true,
    response: {
      city: "East Eliseville",
      name: "Wolf - Rogahn Group",
      size: "11-50",
      email: "Fermin.Oberbrunner@example.com",
      stage: "seed",
      address: "82484 Grant Fall",
      country: 1,
      markets: [16, 32, 34],
      website: "https://www.example.com",
      postcode: "69036",
      active_in: [20, 92, 85],
      is_public: true,
      job_title: "Chief Creative Consultant",
      last_name: "Oberbrunner",
      first_name: "Fermin",
      pitch_text:
        "Minus eaque debitis occaecati autem ducimus quaerat rem sint nihil. Repudiandae maiores ipsum animi qui rem omnis et voluptatibus quos. Veritatis quia cumque a qui est aut.",
      description:
        "Sint ut quod aut odio est doloribus ut harum. Adipisci voluptatibus voluptas quaerat eum. Dolores et praesentium. In quis exercitationem aut laboriosam excepturi reprehenderit omnis.",
      company_type: "private",
      phone_number: "374-340-5329 x37601",
      technologies: "local area network",
      year_founded: 2010,
      revenue_range: "0_500000",
      capital_to_date: 500000,
      application_note:
        "Deleniti amet consequatur. Exercitationem quae et vel ut. Consequatur autem recusandae et deserunt ex."
    },
    application_note:
      "Deleniti amet consequatur. Exercitationem quae et vel ut. Consequatur autem recusandae et deserunt ex.",
    response_note: null,
    created_at: "2018-09-26T07:08:38.115069",
    company: {
      city: "East Eliseville",
      name: "Wolf - Rogahn Group",
      size: "11-50",
      email: "Fermin.Oberbrunner@example.com",
      stage: "seed",
      address: "82484 Grant Fall",
      country: 1,
      markets: [16, 32, 34],
      website: "https://www.example.com",
      postcode: "69036",
      active_in: [20, 92, 85],
      is_public: true,
      job_title: "Chief Creative Consultant",
      last_name: "Oberbrunner",
      first_name: "Fermin",
      pitch_text:
        "Minus eaque debitis occaecati autem ducimus quaerat rem sint nihil. Repudiandae maiores ipsum animi qui rem omnis et voluptatibus quos. Veritatis quia cumque a qui est aut.",
      description:
        "Sint ut quod aut odio est doloribus ut harum. Adipisci voluptatibus voluptas quaerat eum. Dolores et praesentium. In quis exercitationem aut laboriosam excepturi reprehenderit omnis.",
      company_type: "private",
      phone_number: "374-340-5329 x37601",
      technologies: "local area network",
      year_founded: 2010,
      revenue_range: "0_500000",
      capital_to_date: 500000,
      application_note:
        "Deleniti amet consequatur. Exercitationem quae et vel ut. Consequatur autem recusandae et deserunt ex."
    },
    criteria: [
      { label: "countries", response: "Yes", value: [1, 2, 103] },
      { label: "markets", response: "No", value: [17, 44, 67] },
      { label: "stages", response: "Yes", value: ["series_a", "series_b"] },
      {
        label: "other",
        response: "No",
        value:
          "Does your company make use of Microsoft products and services within its technology?"
      },
      {
        label: "other",
        response: "Yes",
        value: "Another custom reason goes here…"
      }
    ],
    questions: [
      {
        type: "text_input",
        label: "A text input question",
        response: "this is the first line\nthis is the next"
      },
      {
        type: "single_choice_input",
        label: "A single choice question (only one answer allowed)",
        options: ["Option A", "Option B", "Option C"],
        response: ["Option C"]
      },
      {
        type: "multiple_choice_input",
        label: "A multiple choice question (multiple answers allowed)",
        options: [
          "Multiple choice Option A",
          "Multiple choice  Option B",
          "Multiple choice Option C"
        ],
        response: ["Multiple choice  Option B", "Multiple choice Option C"]
      },
      {
        type: "file_upload",
        label: "A file upload request",
        fileTypes: ["application/pdf"],
        response: "/uploads/TODO/path/to/document.pdf"
      }
    ]
  },
  "/api/programs/6/participants/7/": {
    id: 7,
    email: "immersive@white.space",
    is_approved: 2,
    is_public: true,
    response: {
      first_name: "Immersivere",
      last_name: "Hab",
      email: "immersive@white.space",
      job_title: "CTO",
      phone_number: null,
      name: "Immersivere Hab",
      address: "",
      city: "",
      postcode: "",
      country: null,
      website: "https://immersiverehab.com/",
      company_type: "private",
      markets: [],
      technologies: null,
      description: "",
      size: "1-10",
      stage: "seed",
      capital_to_date: null,
      revenue_range: "0_500000",
      pitch_text: null,
      year_founded: null,
      active_in: [],
      application_note: "super short",
      response_note:
        "Congratulations!\n\nYou have been approved to Pear Shaped.\n\nWe are happy to have you on board."
    },
    application_note: "super short",
    response_note:
      "Congratulations!\n\nYou have been approved to Pear Shaped.\n\nWe are happy to have you on board.",
    created_at: "2018-09-07T08:07:33.198144"
  },
  "/api/programs/6/participants/6/": {
    id: 6,
    email: "fire@white.space",
    is_approved: 2,
    is_public: true,
    response: {
      first_name: "Chad",
      last_name: "Cribbins",
      email: "fire@white.space",
      job_title: "CTO",
      phone_number: null,
      name: "Firef.ly",
      address: "123 Country Road",
      city: "Everywhere",
      postcode: "AB1 2CD",
      country: null,
      website: "http://firef.ly/",
      company_type: "private",
      markets: ["3", "4", "7", "8", "9"],
      technologies: null,
      description: "Let's add a description.",
      size: "11-50",
      stage: "series-b",
      capital_to_date: 567001,
      revenue_range: "1000000_2000000",
      pitch_text: "Whatever works...",
      year_founded: "2013",
      active_in: ["19", "20", "21"],
      application_note:
        "Short and sweet.\n\nBuzz disrupt tweens multiple points of entry. Leverage context lean content top influencers dedication. Crowdsource leverage wearables platform. Curated top influencers leverage. Gen Y cross-device B2B lean content flat design viral virality. User-friendly click bait trending seamless platform. Funnel seamless multiple points of entry sticky content seed money content curation.",
      response_note:
        "Congratulations!\n\nYou have been approved to Pear Shaped.\n\nWe are happy to have you on board."
    },
    application_note:
      "Short and sweet.\n\nBuzz disrupt tweens multiple points of entry. Leverage context lean content top influencers dedication. Crowdsource leverage wearables platform. Curated top influencers leverage. Gen Y cross-device B2B lean content flat design viral virality. User-friendly click bait trending seamless platform. Funnel seamless multiple points of entry sticky content seed money content curation.",
    response_note:
      "Congratulations!\n\nYou have been approved to Pear Shaped.\n\nWe are happy to have you on board.",
    created_at: "2018-09-07T07:30:40.943686"
  }
};
