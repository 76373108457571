import { connect } from "react-redux";

import ApplicationsListView from "./ApplicationsListView";

import { updateApplications } from "../../Reducers/Applications";

const mapDispatchToProps = dispatch => {
  return {
    deselectAll: apps => {
      const deselected = apps.map(app => ({ ...app, selected: false }));

      dispatch(updateApplications(deselected));
    },
    selectAll: apps => {
      const selected = apps.map(app => ({ ...app, selected: true }));

      dispatch(updateApplications(selected));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ApplicationsListView);
