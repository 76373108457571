import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    width: "100%",
    overflowX: "auto",
    marginBottom: 80
  },
  label: {
    fontSize: 15,
    marginTop: 20,
    marginBottom: 5
  },
  input: {
    boxSizing: "border-box",
    padding: "15px 10px",
    backgroundColor: "#F2F3F4",
    width: 220,
    marginBottom: 20
  }
};

class CompanyInvestorsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      investors: props.company.investors || []
    };
  }

  handleChange = () => {
    const company = { ...this.props.company, investors: this.state.investors };

    this.props.onChange(company);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.label}>Investor 1</div>
        <div className={classes.input}>TODO</div>
        <div className={classes.label}>Investor 2</div>
        <div className={classes.input}>TODO</div>
        <div className={classes.label}>Investor 3</div>
        <div className={classes.input}>TODO</div>
      </div>
    );
  }
}

CompanyInvestorsForm.propTypes = {
  company: PropTypes.shape({
    investors: PropTypes.array.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

CompanyInvestorsForm.defaultProps = {
  company: { investors: [] }
};

export { CompanyInvestorsForm, styles };
export default withStyles(styles)(CompanyInvestorsForm);
