import React from "react";

import { withStyles } from "@material-ui/core/styles";

import imageSignupForm from "../../assets/images/homepage/colinked-homepage-form-characters.png";

import SignupForm from "../common/SignupForm";
import TabbedContainer from "../common/TabbedContainer";

export const styles = {
  root: { boxShadow: "none" },
  appBarRoot: { paddingTop: 5, paddingBottom: 0 },
  tabsRoot: { fontSize: "16px", padding: 0 },
  tabRoot: { fontSize: "16px", marginRight: 0 },
  medium: { fontSize: "16px" }
};

const ContactForm = props => {
  const { classes, testProps } = props;

  return (
    <section className="form-section">
      <div className="form-container">
        <h2>Become a Colinked Member</h2>
        <p>
          In our beta, we signed up 40 global corporates and 40 founder teams,
          you can join our movement here:
        </p>

        <TabbedContainer
          classes={classes}
          labels={["I am a Corporate", "I am a Founder"]}
          tabWidth="50%"
        >
          <SignupForm
            url="/api/companies/corporations/apply/"
            showLinkedin={true}
            testProps={testProps}
          />
          <SignupForm
            url="/api/companies/founders/apply/"
            testProps={testProps}
          />
        </TabbedContainer>

        <img
          className="illustration"
          src={imageSignupForm}
          alt="Colinked - Character avatars shaking hands"
        />
      </div>
    </section>
  );
};

export default withStyles(styles)(ContactForm);
