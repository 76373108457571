import React from "react";

import PageTemplate from "./PageTemplate";

import TermsOfUse from "../components/StandAlonePages/TermsOfUse";

const Program = props => {
  return (
    <PageTemplate>
      <TermsOfUse />
    </PageTemplate>
  );
};

export default Program;
