import { withStyles } from "material-ui";

import { DesktopLayout } from "./DesktopLayout";

import styles from "./styles";

const stylesMedium = {
  ...styles,
  main: {
    ...styles.main,
    minWidth: 610,
    width: 610
  },
  row: {
    ...styles.row,
    minWidth: 1000,
    width: 1000
  },
  sidebar: {
    ...styles.sidebar,
    maxWidth: 360,
    width: 360
  }
};

export default withStyles(stylesMedium)(DesktopLayout);
