import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { createFlashNote } from "../../../Reducers/FlashNotes";
import {
  clearValidationError,
  setValidationError,
  setValidationErrors
} from "../../../Reducers/ValidationErrors";
import { updateProfileData } from "../../../Reducers/Profile";
import { update } from "../../../Reducers/Profile/api";

import FormBuilder from "../../FormBuilder/FormBuilderContainer";
import Banner from "../../common/Banner";
import userProfilePages from "./formSections";
import UserProfileEditorMenu from "./UserProfileEditorMenu";

const UserProfileBanner = props => {
  return (
    <Banner
      title="User Profile"
      breadcrumbs={[{ name: "Dashboard", link: "/dashboard" }]}
    />
  );
};

const updateProfile = dispatch => data => {
  const { id, is_approved, ...profileData } = data;

  return update(profileData).then(result => {
    const { error } = result;

    if (error) {
      dispatch(createFlashNote(error.message, "error"));
    } else {
      dispatch(updateProfileData(profileData));
    }

    return result;
  });
};

// const ProfilePreview = props => {
//   return <ProfileView {...props} company={props.document} />;
// };

const mapStateToProps = state => ({
  // PreviewComponent: ProfilePreview,
  BannerComponent: UserProfileBanner,
  MenuComponent: UserProfileEditorMenu,
  documentProp: "profile",
  // exitRedirect: "/dashboard",
  hidePageButtons: true,
  hidePageIndex: true,
  hideStepperIcons: true,
  pages: userProfilePages,
  profile: state.profile,
  createNewDocument: () => {},
  // add 'doctype' and 'is_approved' to fool FormBuilder
  document: { doctype: "profile", ...state.profile, is_approved: 2 },
  validationErrors: state.validationErrors
});

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    },
    clearValidationError: id => dispatch(clearValidationError(id)),
    setValidationError: (id, error) => dispatch(setValidationError(id, error)),
    setValidationErrors: errors => dispatch(setValidationErrors(errors)),
    updateDocument: updateProfile(dispatch)
  };
};

const mergeProps = (mapStateToProps, mapDispatchToProps) => {
  return {
    ...mapStateToProps,
    ...mapDispatchToProps,
    loadDocument: () => {
      const {
        description,
        company,
        isSignedIn,
        loading,
        loginError,
        membership,
        ...profile
      } = mapStateToProps.document;

      return Promise.resolve(profile);
    }
  };
};

const CompanyProfileEditor = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withRouter(FormBuilder));

export default CompanyProfileEditor;
