import React from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import EmptyListState from "../common/EmptyListState";

import {
  getOutlineButtonClasses,
  getPrimaryButtonClasses
} from "../../styles/buttonStyles";

const outlineStyles = getOutlineButtonClasses();
const primaryStyles = getPrimaryButtonClasses();

const OutlineButton = withStyles(outlineStyles)(Button);
const PrimaryButton = withStyles(primaryStyles)(Button);

const EmptyState = props => {
  const { history, style, subtitle, title } = props;

  return (
    <EmptyListState title={title} subtitle={subtitle} style={style}>
      <div style={{ margin: 20 }}>
        <PrimaryButton onClick={() => history.push("/mycompany/edit")}>
          Complete Company Profile
        </PrimaryButton>
        <OutlineButton onClick={() => history.push("/startups")}>
          Browse Founder Companies
        </OutlineButton>
      </div>
    </EmptyListState>
  );
};

EmptyState.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

EmptyState.defaultProps = {
  title: "No connections yet, complete your company profile to get started",
  subtitle:
    "Connecting with Corporates or Founder Teams allows you to share contact details and start a conversation. To be discoverable on Colinked, first complete your company profile.",
  style: { marginTop: 20 }
};

export { EmptyState };

export default withRouter(EmptyState);
