import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { BODY_FONT_BLACK, GREY_MEDIUM } from "../../../styles/colors";

import emptyProgramsImage from "../../../assets/flow-diagram.svg";

import TabbedContainer from "../../common/TabbedContainer";
import ProgramCard from "./Card";

const styles = {
  root: {},
  cardsContainer: {
    padding: 30
  },
  paragraph: {
    marginTop: 25,
    color: BODY_FONT_BLACK,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "24px"
  },
  tabsRoot: {
    boxShadow: "none"
  },
  title: {
    color: BODY_FONT_BLACK,
    fontSize: 18,
    fontWeight: 600
  }
};

const EmptyList = props => {
  const { doctype } = props;

  return (
    <div
      style={{
        padding: 30,
        textAlign: "center"
      }}
    >
      <img src={emptyProgramsImage} alt="illustration" />
      <div
        style={{
          color: GREY_MEDIUM,
          fontSize: 17,
          fontWeight: 600
        }}
      >
        {`No ${doctype}s yet`}
      </div>
    </div>
  );
};

EmptyList.defaultProps = {
  doctype: "program"
};

const renderCards = (documents, classes) => {
  if (!documents.length) return null;

  return (
    <div className={classes.cardsContainer}>
      {documents.map((document, index) => (
        <ProgramCard key={index} document={document} />
      ))}
    </div>
  );
};

const Initiatives = props => {
  const { challenges, classes, programs, showChallenges } = props;

  const labels = [`Programs (${programs.length})`];
  if (showChallenges) {
    labels.push(`Think Tanks (${challenges.length})`);
  }

  const programCards = renderCards(programs, classes) || (
    <EmptyList doctype={"program"} />
  );

  const challengeCards = renderCards(challenges, classes) || (
    <EmptyList doctype={"think tank"} />
  );

  return (
    <div className={classes.root}>
      <TabbedContainer
        classes={{ root: classes.tabsRoot }}
        labels={labels}
        tabWidth={110}
      >
        {programCards}
        {challengeCards}
      </TabbedContainer>
    </div>
  );
};

Initiatives.propTypes = {
  challenges: PropTypes.array.isRequired,
  programs: PropTypes.array.isRequired,
  showChallenges: PropTypes.bool
};

Initiatives.defaultProps = {
  challenges: [],
  programs: [],
  showChallenges: true
};

export default withStyles(styles)(Initiatives);
