// import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    margin: 30,
    marginTop: 90,
    border: "1px solid #E6E7E9",
    padding: 30
  }
};

const ApplicationNotes = props => {
  return null;
};

ApplicationNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  notes: PropTypes.array.isRequired
};

ApplicationNotes.defaultProps = {
  notes: []
};

export { ApplicationNotes, styles };
export default withStyles(styles)(ApplicationNotes);
