import React from "react";
import { PropTypes } from "prop-types";

import Banner from "../common/Banner";
import TabbedConnectionsList from "./TabbedConnectionsList";

const ConnectionsPage = props => {
  const { title } = props;
  return (
    <React.Fragment>
      <Banner title={title} />
      <div className="body" style={{ marginBottom: 60 }}>
        <TabbedConnectionsList />
      </div>
    </React.Fragment>
  );
};

ConnectionsPage.propTypes = {
  title: PropTypes.string.isRequired
};

ConnectionsPage.defaultProps = {
  title: "My Connections"
};

export default ConnectionsPage;
