import React from "react";
import { PropTypes } from "prop-types";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import PulseIcon from "../common/PulseIcon";

const styles = {
  root: {
    display: "flex",
    alignItems: "center"
  },
  text: {
    color: "#2A2B2C",
    fontSize: 14
    // fontWeight: 300
  },
  textHighlight: {
    fontWeight: "bold",
    marginLeft: 0,
    marginRight: 4
  }
};

const formatCount = (datetime, text, classes) => {
  const deadline = new Date(datetime);
  const now = new Date();

  const diff = deadline - now;

  if (diff < 0) {
    return (
      <span className={classes.text}>
        <span className={classes.textHighlight}>closed</span>
      </span>
    );
  }

  let unit = "days";
  let count = 0;

  if (diff > 24 * 60 * 60 * 1000) {
    count = Math.floor(diff / (24 * 60 * 60 * 1000));
    unit = count > 1 ? "days" : "day";
  } else if (diff > 60 * 60 * 1000) {
    count = Math.floor(diff / (60 * 60 * 1000));
    unit = count > 1 ? "hours" : "hour";
  } else if (diff > 60 * 1000) {
    count = Math.floor(diff / (60 * 1000));
    unit = count > 1 ? "minutes" : "minute";
  } else {
    count = Math.floor(diff / 1000);
    unit = "seconds";
  }

  return (
    <span className={classes.text}>
      <span className={classes.textHighlight}>{count}</span>
      {`${unit} ${text}`}
    </span>
  );
};

const formatText = (datetime, classes) => {
  const dateFormat = "dddd Do MMMM, h:mma";
  const date = moment(datetime);
  const text = `deadline: ${date.format(dateFormat)}`;

  return <span className={classes.text}>{text}</span>;
};

const renderIcon = datetime => {
  if (moment().isBefore(datetime)) {
    return (
      <PulseIcon pulse={false} style={{ marginRight: 0, marginBottom: 1 }} />
    );
  }

  return (
    <PulseIcon
      color="#AAADB6"
      pulse={false}
      style={{ marginRight: 0, marginBottom: 1 }}
    />
  );
};

const AppointmentDeadline = props => {
  const { classes, deadline_datetime, countdown, countdownText } = props;

  if (!deadline_datetime) return null;

  const text = countdown
    ? formatCount(deadline_datetime, countdownText, classes)
    : formatText(deadline_datetime, classes);

  return (
    <div className={classes.root}>
      {renderIcon(deadline_datetime)}
      {text}
    </div>
  );
};

AppointmentDeadline.propTypes = {
  deadline_datetime: PropTypes.string,
  countdown: PropTypes.bool,
  countdownText: PropTypes.string.isRequired,
  style: PropTypes.object
};

AppointmentDeadline.defaultProps = {
  countdown: false,
  countdownText: "left",
  style: {}
};

export { AppointmentDeadline, styles };
export default withStyles(styles)(AppointmentDeadline);
