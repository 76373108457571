import React from "react";
import { PropTypes } from "prop-types";
import moment from "moment";
import DatePicker from "react-datepicker";

import { withStyles } from "@material-ui/core/styles";

import ClearIcon from "@material-ui/icons/Clear";
import DateRangeIcon from "@material-ui/icons/DateRange";

import formStyles from "../../styles/formStyles";

import "./react-datepicker.min.css";
import "./datepickerstyles.css";

const styles = {
  root: {
    marginTop: 20,
    marginBottom: 20
  },
  container: {
    display: "flex",
    alignItems: "center"
  },
  datePicker: {
    //outline: "1px solid red"
  },
  iconClear: {
    cursor: "pointer",
    fill: "#AAADB6",
    marginLeft: 6,
    "&:hover": {
      fill: "#DB6E53"
    }
  },
  iconDateRange: {
    fill: "#AAADB6",
    marginRight: 10
  },
  innerContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ededef",
    flexGrow: 1,
    height: 40
  },
  label: formStyles.label,
  helperText: formStyles.helperText
};

const DateTimeSelector = props => {
  const {
    classes,
    date,
    disabled,
    error,
    helperText,
    label,
    onChange,
    showClear,
    width
  } = props;

  //const width =
  //  typeof props.width === "string" ? props.width : props.width + 30;

  let selected;
  try {
    selected = date ? moment(new Date(date).toISOString()) : null;
  } catch (error) {
    selected = moment();
  }

  const labelStyle = error ? { color: "red" } : {};
  const opacity = disabled ? 0.5 : 1;

  return (
    <div className={classes.root} style={{ opacity, width }}>
      <div className={classes.label} style={labelStyle}>
        {label}
      </div>

      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div style={{ width: "100%" }}>
            <DatePicker
              selected={selected}
              onChange={value => {
                if (!value) {
                  onChange("");
                } else {
                  onChange(value.toISOString());
                }
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              dateFormat="LLL"
              timeCaption="time"
              disabled={disabled}
              className={classes.datePicker}
            />
          </div>
          <DateRangeIcon className={classes.iconDateRange} />
        </div>

        {showClear && (
          <ClearIcon
            className={classes.iconClear}
            onClick={() => onChange("")}
          />
        )}
      </div>

      {helperText && (
        <div className={classes.helperText} style={labelStyle}>
          {helperText}
        </div>
      )}
    </div>
  );
};

DateTimeSelector.propTypes = {
  date: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showClear: PropTypes.bool,
  width: PropTypes.oneOf([220, 440, 660])
};

DateTimeSelector.defaultProps = {
  date: "",
  showClear: true,
  width: 440
};

export default withStyles(styles)(DateTimeSelector);
