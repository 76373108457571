import React from "react";
import { PropTypes } from "prop-types";

import moment from "moment";

import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core/styles";

import formStyles from "../../styles/formStyles";
import GroupButton from "../common/ButtonGroup/GroupButton";

import DateTimeSelector from "./DateTimeSelector";

const styles = {
  root: {
    marginTop: 20
  },
  container: {
    display: "flex",
    alignItems: "center"
  },
  helperText: formStyles.helperText,
  label: formStyles.label
};

const getEndDatetime = (end, start) => {
  if (!end) return moment(start).add(7, "days");

  const momentEnd = moment(end);
  if (start.isBefore(momentEnd)) return momentEnd;

  return moment(start).add(7, "days");
};

const renderButton = (label, onClick, selected) => {
  return (
    <GroupButton selected={Boolean(selected)} onClick={onClick}>
      {label}
    </GroupButton>
  );
};

const setMultipleDays = props => {
  const { handleChangeFactory, state } = props;

  const start_datetime = state.start_datetime
    ? moment(state.start_datetime)
    : moment();

  const end_datetime = getEndDatetime(state.end_datetime, start_datetime);

  handleChangeFactory("is_ongoing")(false);
  handleChangeFactory("start_datetime")(start_datetime.format());
  handleChangeFactory("end_datetime")(end_datetime.format());
};

const setOngoing = props => {
  const { handleChangeFactory } = props;

  handleChangeFactory("is_ongoing")(true);
  handleChangeFactory("start_datetime")("");
  handleChangeFactory("end_datetime")("");
};

const setSingleDay = props => {
  const { handleChangeFactory, state } = props;

  const start_datetime = state.start_datetime
    ? moment(state.start_datetime)
    : moment();

  handleChangeFactory("is_ongoing")(false);
  handleChangeFactory("start_datetime")(start_datetime.format());
  handleChangeFactory("end_datetime")("");
};

const DurationSelector = props => {
  const { classes, error, handleChangeFactory, helperText, label } = props;

  const { start_datetime, end_datetime, is_ongoing } = props.state;

  const endError =
    start_datetime &&
    end_datetime &&
    new Date(start_datetime) > new Date(end_datetime);

  const endErrorHelp = endError ? "end date must be after start date" : null;

  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <div className={classes.container}>
        {renderButton(
          "One Day",
          () => setSingleDay(props),
          start_datetime && !end_datetime
        )}

        {renderButton(
          "Multiple Days",
          () => setMultipleDays(props),
          start_datetime && end_datetime
        )}

        {renderButton("Ongoing", () => setOngoing(props), is_ongoing)}
      </div>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
      {start_datetime && (
        <DateTimeSelector
          date={start_datetime}
          label="Start Date and Time"
          onChange={handleChangeFactory("start_datetime")}
          showClear={false}
        />
      )}
      {end_datetime && (
        <DateTimeSelector
          date={end_datetime}
          error={endError}
          helperText={endErrorHelp}
          label="End Date and Time"
          onChange={handleChangeFactory("end_datetime")}
          showClear={false}
        />
      )}
    </div>
  );
};

DurationSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  handleChangeFactory: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  state: PropTypes.shape({
    is_ongoing: PropTypes.bool,
    start_datetime: PropTypes.string,
    end_datetime: PropTypes.string
  }).isRequired
};

DurationSelector.defaultProps = {
  label: "Program Duration"
};

export default withStyles(styles)(DurationSelector);
