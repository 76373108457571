import React from "react";

import SignInPageContainer from "../components/Authentication/SignInPageContainer";

import PageTemplate from "./PageTemplate";

const SignIn = props => {
  return (
    <PageTemplate>
      <SignInPageContainer />
    </PageTemplate>
  );
};

export default SignIn;
