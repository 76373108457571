import React from "react";
import { PropTypes } from "prop-types";

import emailvalidator from "email-validator";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import FormSelectField from "../Form/Selectors/FormSelectField";
import SimpleDialog from "../common/SimpleDialog";
import TextInputField from "../Form/TextInputField";

import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

import { getPrimaryButtonClasses } from "../../styles/buttonStyles";

const sendStyles = getPrimaryButtonClasses("#6592CC");
const SendButton = withStyles(sendStyles)(Button);

const styles = {
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 40
  },
  comment: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    marginTop: -5,
    marginBottom: 10
  },
  textInputRoot: {
    marginTop: 0
  }
};

class ReferFounderTeamDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
      recipient_company_name: "",
      recipient_name: "",
      recipient: "",
      relationship: null
    };
  }

  getTextInputProps = id => {
    const errorId = `${id}_error`;

    return {
      error: Boolean(this.state[errorId]),
      limit: 80,
      onBlur: this.onBlur(id),
      onChange: this.updateState(id),
      type: "text",
      value: this.state[id],
      width: 440
    };
  };

  isDisabled = (id, value) => {
    const state = { ...this.state, [id]: value };

    if (
      !state.recipient_company_name ||
      !state.recipient_name ||
      !state.recipient ||
      !state.relationship
    )
      return true;

    if (!emailvalidator.validate(state.recipient)) return true;

    return false;
  };

  onBlur = id => () => {
    const errorId = `${id}_error`;

    if (!this.state[id]) {
      this.setState({ [errorId]: "This field is required." });
    } else if (
      id === "recipient" &&
      !emailvalidator.validate(this.state.recipient)
    ) {
      this.setState({ [errorId]: "Enter a valid email address" });
    } else {
      this.setState({ [errorId]: null });
    }
  };

  onSubmit = () => {
    const {
      disabled,
      recipient_company_name_error,
      recipient_name_error,
      recipient_error,
      relationship,
      ...data
    } = this.state;

    fetchWithToken(this.props.postURL, {
      method: "POST",
      body: JSON.stringify({ ...data, relationship: relationship.value })
    })
      .then(responseJsonOrError)
      .then(json => {
        if (json.error) {
          this.props.createFlashNote(json.error.message, "error");
        } else {
          this.props.createFlashNote(
            "Your referral has been received.",
            "success"
          );
          this.props.loadReferrals();
        }

        this.props.onClose();
      });
  };

  updateState = id => value => {
    const disabled = this.isDisabled(id, value);

    this.setState({ [id]: value, disabled });
  };

  render() {
    const { classes, config, maxMenuRows, referrals, title } = this.props;

    let comment = `You can refer up to ${
      config.referrals__max_corp_referrals
    } founder teams.`;

    if (referrals.length === 1) {
      comment += ` You have already referred ${
        referrals.length
      } founder teams.`;
    } else if (referrals.length > 1) {
      comment += ` You have already referred ${
        referrals.length
      } founder teams.`;
    }

    const maxMenuHeight = maxMenuRows ? maxMenuRows * 41 + 2 * 4 : null; //41 row height + 2*4 padding

    return (
      <SimpleDialog title={title} width={500}>
        <div className={classes.comment}>{comment}</div>

        <TextInputField
          id="recipient_company_name"
          label="Company Name"
          helperText={
            this.state.recipient_company_name_error ||
            "What are the founder team called?"
          }
          {...this.getTextInputProps("recipient_company_name")}
        />

        <TextInputField
          id="recipient_name"
          label="Contact Name"
          helperText={
            this.state.recipient_name_error ||
            "Who at the company will receive the invite?"
          }
          {...this.getTextInputProps("recipient_name")}
        />

        <TextInputField
          id="recipient"
          label="Email address"
          helperText={
            this.state.recipient_error || "Where should we send the invite to?"
          }
          {...this.getTextInputProps("recipient")}
        />

        <FormSelectField
          id="relationship"
          isMulti={false}
          label="How have you worked with this company?"
          maxMenuHeight={maxMenuHeight}
          onChange={this.updateState("relationship")}
          options={[
            { value: "Part of Accelerator", label: "Part of Accelerator" },
            {
              value: "Part of Open Innovation Program",
              label: "Part of Open Innovation Program"
            },
            { value: "Investee Company", label: "Investee Company" },
            { value: "Co-sale Agreement", label: "Co-sale Agreement" },
            { value: "Preferred Supplier", label: "Preferred Supplier" },
            { value: "Ex-Employees", label: "Ex-Employees" },
            { value: "other", label: "other" }
          ]}
          value={this.state.relationship}
          width={440}
        />

        <div className={classes.buttons}>
          <SendButton disabled={this.state.disabled} onClick={this.onSubmit}>
            Refer Founder Team
          </SendButton>
        </div>
      </SimpleDialog>
    );
  }
}

ReferFounderTeamDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  createFlashNote: PropTypes.func.isRequired,
  loadReferrals: PropTypes.func.isRequired,
  maxMenuRows: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  postURL: PropTypes.string.isRequired,
  referrals: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

ReferFounderTeamDialog.defaultProps = {
  maxMenuRows: 3,
  onClose: () => {},
  title: "Refer a Founder Team",
  postURL: "/api/referrals/"
};

export { ReferFounderTeamDialog, styles };
export default withStyles(styles)(ReferFounderTeamDialog);
