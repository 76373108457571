import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import Icons from "../Icons";

import ExpansionSection from "./ExpansionSection";
import OptionalSection from "./OptionalSection";

const convertSectionsType = sections => {
  if (!sections) {
    return [];
  }

  if (Array.isArray(sections)) {
    return sections;
  }

  try {
    const _sections = JSON.parse(sections || "[]");
    return _sections;
  } catch (error) {
    console.error("Unexpected input for JSON.parse");
    console.log(`input: [${typeof sections}]`, sections);
  }

  return [];
};

class CustomSections extends React.Component {
  static propTypes = {
    expansion: PropTypes.bool,
    sections: PropTypes.string,

    update: PropTypes.func.isRequired
  };

  static defaultProps = {
    expansion: false,
    sections: "[]"
  };

  constructor(props) {
    super(props);

    this.state = {
      sections: convertSectionsType(props.sections)
    };
  }

  componentWillReceiveProps(nextProps) {
    const sections = convertSectionsType(nextProps.sections);

    this.setState({ sections });
  }

  addSection = () => {
    const sections = [...this.state.sections, { title: "", content: "" }];

    this.setState({ sections });
  };

  removeSection = index => {
    const sections = [...this.state.sections];
    sections.splice(index, 1);

    this.setState({ sections }, this.update);
  };

  setSection = (index, title, content, participants_only) => {
    const sections = [...this.state.sections];
    sections[index] = { title, content, participants_only };

    this.setState({ sections }, this.update);
  };

  update = () => {
    this.props.update(JSON.stringify(this.state.sections));
  };

  renderSectionsInput = () => {
    const { sections } = this.state;

    return sections.map((section, index) => {
      const onChange = (title, content, hidden) => {
        this.setSection(index, title, content, hidden);
      };
      const onRemove = () => this.removeSection(index);

      return (
        <OptionalSection
          index={index}
          key={index}
          section={section}
          onChange={onChange}
          onRemove={onRemove}
        />
      );
    });
  };

  addSectionButton = () => {
    return (
      <div
        style={{
          marginTop: 24,
          paddingTop: 12,
          borderTop: "1px solid lightgrey"
        }}
      >
        <div style={{ display: "flex" }}>
          <Button
            size="small"
            onClick={this.addSection}
            style={{ color: "green" }}
          >
            <Icons.Plus style={{ fill: "green", marginRight: 5 }} />Add new
            section
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { expansion } = this.props;
    const { sections } = this.state;

    const body = (
      <div style={{ flexGrow: 1 }}>
        {this.renderSectionsInput()}
        {this.addSectionButton()}
      </div>
    );

    if (!expansion) {
      return body;
    }

    return (
      <ExpansionSection
        title="Custom Sections"
        summaryText={sections.map(s => s.title).join(", ")}
        panelProps={{ defaultExpanded: Boolean(sections.length) }}
      >
        {body}
      </ExpansionSection>
    );
  }
}

export default CustomSections;
