import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core";

import MuiTextField from "@material-ui/core/TextField";

import globalStyles from "./styles";

const styles = {
  textFieldRoot: globalStyles.textFieldRoot,
  formRoot: globalStyles.formRoot,
  formInput: globalStyles.formInput,
  formInputLabel: globalStyles.formInputLabel
};

const TextField = props => {
  const { classes, label, value, onChange, style } = props;

  const rows = parseInt(value.length / 60, 10) + 1;
  const multiline = Boolean(parseInt(value.length / 60, 10));

  return (
    <MuiTextField
      label={label}
      value={value}
      onChange={onChange}
      rows={rows}
      multiline={multiline}
      classes={{
        root: classes.textFieldRoot
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.formRoot,
          input: classes.formInput
        }
      }}
      InputLabelProps={{
        shrink: false,
        className: classes.formInputLabel
      }}
      style={style}
    />
  );
};

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  value: PropTypes.string.isRequired
};

TextField.defaultProps = {
  onChange: () => {},
  value: ""
};

export { TextField, styles };
export default withStyles(styles)(TextField);
