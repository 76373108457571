import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  benefitsKeyMap,
  benefitTypes,
  documentTypeLabelsShort
} from "../../Document/config";

import BenefitValueInput from "./BenefitValueInput";
import EquityInput from "./EquityInput";
import OptionalBenefit from "./OptionalBenefit";
import OtherBenefits from "./OtherBenefits";
import styles from "./styles";

import { availableBenefits } from "./config";

// const testBenefits = [
//   { label: "Funding", value: "50000", unit: "EUR (\u20AC)" },
//   { label: "Collaboration / Partnership", value: "" },
//   { label: "Office Space" },
//   { label: "Equity", value: "123", unit: "%" },
//   { label: "Other", value: "collectible underware" },
//   { label: "Other", value: "in-house lifestyle guru" }
// ];

const propsBenefitsToState = benefits => {
  return benefits
    .map(benefit => ({
      ...benefit,
      label: benefit.label || benefitTypes[benefit.key]
    }))
    .map((benefit, index) => {
      return benefit.label !== "Other"
        ? benefit
        : { ...benefit, label: `Other|${index}` };
    });
};

const stateBenefitsToProps = benefits => {
  return benefits
    .filter(benefit => {
      if (!benefit.label.startsWith("Other|")) {
        return true;
      } else if (benefit.value !== "") {
        return true;
      } else {
        return false;
      }
    })
    .map(benefit => {
      return benefit.label.startsWith("Other|")
        ? { ...benefit, label: "Other" }
        : benefit;
    })
    .map(benefit => {
      const key = benefitsKeyMap[benefit.label];

      return key ? { ...benefit, key } : null;
    })
    .filter(benefit => benefit);
};

class BenefitsSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      benefits: propsBenefitsToState(props.benefits)
    };
  }

  addOther = () => {
    const label = `Other|${Math.random()}`;
    const benefits = [...this.state.benefits, { label, value: "" }];

    this.setState({ benefits }, this.updateState);
  };

  removeOther = label => {
    this.toggleBenefit(label);
  };

  toggleBenefit = label => {
    const benefit = this.state.benefits.find(b => b.label === label);

    const benefits = benefit
      ? this.state.benefits.filter(b => b.label !== label)
      : [...this.state.benefits, { label }];

    this.setState({ benefits }, this.updateState);
  };

  updateBenefits = obj => {
    const benefits =
      obj.label === "Equity"
        ? this._updateEquity(obj)
        : this._updateBenefits(obj);

    this.setState({ benefits }, this.updateState);
  };

  _updateBenefits = obj => {
    return this.state.benefits.map(benefit => {
      return benefit.label === obj.label ? obj : benefit;
    });
  };

  _updateEquity = obj => {
    if (!obj.value) {
      return this.state.benefits.filter(b => b.label !== "Equity");
    }

    const { benefits } = this.state;

    const _equity = benefits.find(b => b.label === "Equity");
    return _equity
      ? benefits.map(benefit => (benefit.label === "Equity" ? obj : benefit))
      : [...benefits, obj];
  };

  updateState = () => {
    const benefits = stateBenefitsToProps(this.state.benefits);

    this.props.onChange(benefits);
  };

  renderBenefit = label => {
    const benefit = this.state.benefits.find(b => b.label === label);

    const props = {
      key: label,
      benefit,
      benefits: this.state.benefits,
      classes: this.props.classes,
      label,
      onChange: this.updateBenefits,
      toggleBenefit: this.toggleBenefit
    };

    if (["Funding", "Prize Money", "Offer"].indexOf(label) !== -1) {
      return <BenefitValueInput {...props} />;
    }

    if (label === "Equity") {
      return <EquityInput {...props} />;
    }

    if (label === "Other") {
      return (
        <OtherBenefits
          {...props}
          addOther={this.addOther}
          removeOther={this.removeOther}
        />
      );
    }

    return <OptionalBenefit {...props} />;
  };

  render() {
    const { availableBenefits, classes, state, width } = this.props;
    const { program_type } = state;

    const available = availableBenefits[program_type];
    const programName = documentTypeLabelsShort[program_type] || program_type;

    return (
      <div className={classes.root} style={{ width }}>
        <div className="body1">
          What is the <strong>{programName}</strong> offering? Choose all that
          apply.
        </div>

        <div className={classes.container}>
          {available.map(label => this.renderBenefit(label))}
        </div>
      </div>
    );
  }
}

BenefitsSelector.propTypes = {
  availableBenefits: PropTypes.object.isRequired,
  benefits: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  state: PropTypes.shape({
    program_type: PropTypes.string.isRequired
  }).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

BenefitsSelector.defaultProps = {
  availableBenefits,
  //benefits: testBenefits, // TODO: remove
  width: "100%"
};

export { BenefitsSelector, styles };
export default withStyles(styles)(BenefitsSelector);
