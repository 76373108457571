import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../../Icons";

const questionBodyStyle = {
  border: "1px solid #E6E7E9",
  borderRadius: 3,
  padding: 15,
  marginBottom: 5,
  "&:last-child": {
    marginBottom: 0
  }
};

const styles = {
  root: {
    margin: "0 30px"
  },
  fileName: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  fileUpload: {
    ...questionBodyStyle,
    display: "flex",
    alignItems: "center",
    color: "#6592CC",
    fontWeight: 600
  },
  icon: {
    marginRight: 10
  },
  responseText: {
    color: "#2A2B2C",
    fontFamily: "Nunito, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    hyphens: "auto",

    marginBottom: 5,
    "&:first-child": {
      marginTop: 0
    },
    "&:last-child": {
      marginBottom: 0
    }
  },
  question: {
    marginTop: 20,
    marginBottom: 20,
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300
  },
  questionBody: {
    ...questionBodyStyle
  },
  questionLabel: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 5
  }
};

const formatResponse = (question, classes) => {
  const { response, type } = question;

  if (!response) return null;

  if (type === "file_upload") {
    return (
      <div className={classes.fileUpload}>
        <Icons.CloudDownload className={classes.icon} />
        <a
          className={classes.filename}
          href={response}
          target="_blank"
          rel="noopener noreferrer"
        >
          {(response || "").split("/").slice(-1)}
        </a>
      </div>
    );
  }

  if (type === "text_input") {
    return (
      <div className={classes.questionBody}>
        {(response || "")
          .split("\n")
          .filter(s => s)
          .map((text, i) => (
            <p key={i} className={classes.responseText}>
              {text}
            </p>
          ))}
      </div>
    );
  }

  if (Array.isArray(response)) {
    return (
      <React.Fragment>
        {response.map((r, i) => (
          <div key={i} className={classes.questionBody}>
            {r}
          </div>
        ))}
      </React.Fragment>
    );
  }

  return (
    <div className={classes.questionBody}>
      {`[TODO: type=${type}]`} {JSON.stringify(response)}
    </div>
  );
};

const renderQuestion = (question, index, props) => {
  const { classes } = props;

  if (!question.response) return null;

  return (
    <div key={index} className={classes.question}>
      <div className={classes.questionLabel}>{question.label}</div>
      {formatResponse(question, classes)}
    </div>
  );
};

const QuestionsSummary = props => {
  const { classes, questions } = props;

  return (
    <div className={classes.root}>
      {questions.map((question, index) =>
        renderQuestion(question, index, props)
      )}
    </div>
  );
};

QuestionsSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      response: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      type: PropTypes.string.isRequired
    })
  )
};

export { QuestionsSummary, styles };
export default withStyles(styles)(QuestionsSummary);
