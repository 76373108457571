import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import memoizeOne from "memoize-one";

import { withStyles } from "@material-ui/core/styles";

import { loadServices, updateServiceList } from "../../Reducers/Marketplace";

import LoadingPanel from "../common/LoadingPanel";
import DocumentsListPlaceholder from "../Document/DocumentsListView/DocumentsListPlaceholder";
import DocumentsGridView from "../Document/DocumentsGridView";

import MarketplaceListSearchBar from "./MarketplaceListSearchBar";
import ServiceCard from "./ServiceCard";

const styles = theme => ({
  root: {
    marginTop: 40,
    marginBottom: 8 * theme.spacing.unit
  }
});

const EmptyState = props => {
  return (
    <DocumentsListPlaceholder
      {...props}
      title="No service offerings have been created yet."
      subtitle=""
    />
  );
};

const filterByString = (service, string) => {
  const searchString = string.toLowerCase();
  const { company_name, description } = service;

  const filter =
    description.toLowerCase().includes(searchString) ||
    company_name.toLowerCase().includes(searchString);

  return filter;
};

const sortAndFilter = (array, state) => {
  const { filter, include, sort } = state;

  const sortFunc = sort === "recent" ? sortByCreated : sortByPopular;

  const _array = array.filter(service => {
    // include drafts (admin only)
    if (service.is_approved !== 2 && !include.draft) return false;

    // include archived (admin only)
    if (service.is_archived && !include.archived) return false;

    // filter string
    if (filter) return filterByString(service, filter);

    return true;
  });

  _array.sort(sortFunc);

  return _array;
};

// sort by created_at date - most recent first
const sortByCreated = (a, b) => {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
};

// sort date stamps - most recent first
const sortByDateDescending = (a, b) => {
  return new Date(b).getTime() - new Date(a).getTime();
};

const sortByPopular = (a, b) => {
  if (a.is_popular && !b.is_popular) return -1;
  if (b.is_popular && !a.is_popular) return 1;

  return sortByCreated(a, b);
};

class MarketplaceList extends React.Component {
  state = {
    filter: "",
    include: { draft: true, archived: false },
    sort: "recent"
  };

  // use memoized sortAndFilter
  sortAndFilter = memoizeOne(sortAndFilter);

  componentDidMount() {
    const { documents, loadServices, updateServiceList } = this.props;

    if (!documents.length) {
      loadServices();
    } else if (documents.length === 1) {
      // special case if a service was loaded via direct visit to details page
      loadServices();
    } else if (documents.length > 12) {
      // not a full page yet; we can affort to load all services ...
      loadServices();
    } else {
      const dates = documents.map(d => d.updated_at);
      dates.sort(sortByDateDescending);

      updateServiceList(dates[0]);
    }
  }

  render() {
    const { classes, company, documents } = this.props;
    const { loading } = documents;

    const _documents = this.sortAndFilter(documents, this.state);
    const showGrid = !loading || Boolean(documents.length);

    return (
      <div className={`body ${classes.root}`}>
        <MarketplaceListSearchBar
          admin={company.id === 69}
          {...this.state}
          setFilter={value => this.setState({ filter: value })}
          setInclude={options => this.setState({ include: options })}
          setSort={value => this.setState({ sort: value })}
        />
        {showGrid && (
          <DocumentsGridView
            CardComponent={ServiceCard}
            EmptyState={EmptyState}
            documents={_documents}
          />
        )}
        {loading && <LoadingPanel />}
      </div>
    );
  }
}

MarketplaceList.propTypes = {
  EmptyState: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  loadServices: PropTypes.func.isRequired,
  updateServiceList: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

MarketplaceList.defaultProps = {
  EmptyState,
  title: "Marketplace"
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    company: state.company,
    documents: state.marketplace
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadServices: () => dispatch(loadServices()),
    updateServiceList: updated_at => dispatch(updateServiceList(updated_at))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MarketplaceList));
