import React from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core/styles";

import CustomTableCell from "../../common/StyledTable/CustomTableCell";

import { ThinkTanksListPlaceholder } from "./DocumentsListPlaceholder";
import * as renderers from "./renderers";

import styles from "./styles";

const renderRow = (document, props) => {
  const { classes, renderers } = props;

  return (
    <TableRow className={classes.row} key={document.id}>
      {renderers.map((cellRenderer, index) => (
        <CustomTableCell key={index}>
          {cellRenderer(document, classes)}
        </CustomTableCell>
      ))}
    </TableRow>
  );
};

const ThinkTanksTableOwnerView = props => {
  const {
    classes,
    documents,
    EmptyState,
    error,
    headers,
    loading,
    style
  } = props;

  if (!documents.length && EmptyState) {
    return <EmptyState />;
  }

  return (
    <div className={classes.root} style={style}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <CustomTableCell className={classes.td} key={header}>
                {header}
              </CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tablebody}>
          {documents.map(document => renderRow(document, props))}
          {error && renderers.renderErrorRow(error, headers.length, classes)}
          {loading && renderers.renderLoadingRow(headers.length, classes)}
        </TableBody>
      </Table>
    </div>
  );
};

ThinkTanksTableOwnerView.propTypes = {
  classes: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  renderers: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired
};

ThinkTanksTableOwnerView.defaultProps = {
  EmptyState: ThinkTanksListPlaceholder,
  headers: [
    "Think Tank Name",
    "Type",
    "Location",
    "Published",
    "Pending",
    "Approved",
    "Declined"
  ],
  renderers: [
    renderers.renderDetails,
    renderers.formatType,
    renderers.renderLocation,
    renderers.renderPublishedDate,
    renderers.renderPendingApplications,
    renderers.renderApprovedApplications,
    renderers.renderDeclinedApplications
  ]
};

export default withStyles(styles)(ThinkTanksTableOwnerView);
