import * as colors from "./colors";
import { hoverColor } from "./utils";

export const SIZES = {
  large: {
    height: 60,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 5,
    marginLeft: 10
  },
  medium: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 3,
    marginLeft: 10
  },
  small: {
    height: 28,
    minHeight: 28,
    padding: "5px 10px",
    borderRadius: 3,
    marginLeft: 10
  }
};

export const getPrimaryClass = (
  bgColor,
  fgColor = "#ffffff",
  size = "medium"
) => {
  const buttonSize = SIZES[size] || SIZES.medium;

  return {
    backgroundColor: bgColor,
    color: fgColor,
    ...buttonSize,
    "&:hover": {
      backgroundColor: hoverColor(bgColor),
      boxShadow: colors.BOX_SHADOW_HOVER,
      cursor: "pointer"
    },
    "&:first-child": {
      marginLeft: 0
    }
  };
};

export const primary = getPrimaryClass(colors.BLUE, "#ffffff", "medium");

export const getLinkButtonClasses = options => {
  const color = options.color || colors.BLUE;
  const size = options.size || "medium";
  const fontSize = { small: 12, medium: 14, large: 16 }[size] || 14;

  return {
    root: {
      ...getPrimaryClass("#ffffff", color, size),
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#ffffff",
        boxShadow: "none",
        textDecoration: "underline",
        cursor: "pointer"
      }
    },
    label: {
      fontSize,
      fontWeight: 700,
      lineHeight: "1em",
      color: color,
      whiteSpace: "nowrap",
      boxShadow: "none"
    },
    disabled: {
      ...getPrimaryClass("#ffffff", color, size),
      color: color,
      opacity: 0.2,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#ffffff",
        boxShadow: "none"
      }
    }
  };
};

export const getPrimaryButtonClasses = (
  bgColor = colors.BLUE,
  fgColor = "#ffffff",
  size = "medium"
) => {
  const fontSize = { small: 12, medium: 14, large: 16 }[size] || 14;

  return {
    root: {
      ...getPrimaryClass(bgColor, fgColor, size)
    },
    label: {
      fontSize,
      fontWeight: 700,
      lineHeight: "1em",
      color: fgColor,
      whiteSpace: "nowrap"
    },
    disabled: {
      ...getPrimaryClass(bgColor, fgColor, size),
      color: fgColor,
      opacity: 0.2,
      boxShadow: "none"
    }
  };
};

export const buttonPrimaryClasses = getPrimaryButtonClasses(
  colors.BLUE,
  "#ffffff"
);

export const getOutlineClass = (
  color = colors.BODY_FONT_BLACK,
  size = "medium"
) => {
  const buttonSize = SIZES[size] || SIZES.medium;

  return {
    ...buttonSize,
    border: `1px solid ${color}`,
    boxShadow: "none",
    color,
    "&:hover": {
      backgroundColor: colors.INPUT_GREY,
      boxShadow: colors.BOX_SHADOW_HOVER
    },
    "&:first-child": {
      marginLeft: 0
    }
  };
};

export const iconClasses = {
  large: { marginRight: 6, fontSize: 24 },
  medium: { marginRight: 5, fontSize: 20 },
  small: { marginRight: 5, fontSize: 20 }
};

export const iconClassesRight = {
  large: { marginLeft: 6, fontSize: 24 },
  medium: { marginLeft: 5, fontSize: 20 },
  small: { marginLeft: 5, fontSize: 20 }
};

export const outline = getOutlineClass(colors.BODY_FONT_BLACK);

export const getOutlineButtonClasses = (
  color = colors.BODY_FONT_BLACK,
  size = "medium"
) => {
  const fontSize = { small: 12, medium: 14, large: 16 }[size] || 14;

  return {
    root: {
      ...getOutlineClass(color, size)
    },
    disabled: {
      ...getOutlineClass(colors.BODY_FONT_BLACK, size),
      opacity: 0.2,
      boxShadow: "none"
    },
    label: {
      fontSize,
      lineHeight: "1em",
      fontWeight: 700,
      color,
      whiteSpace: "nowrap"
    }
  };
};

export const outlineButtonClasses = getOutlineButtonClasses(
  colors.BODY_FONT_BLACK
);

export const buttonOutlineLargeClasses = {
  root: {
    ...outline,
    ...SIZES.large,
    border: "1px solid #2A2B2C"
  },
  disabled: {
    ...outline,
    ...SIZES.large,
    opacity: 0.2,
    boxShadow: "none"
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "1em",
    color: colors.BODY_FONT_BLACK,
    whiteSpace: "nowrap"
  }
};

export const buttonPrimaryLargeClasses = {
  root: {
    ...primary,
    ...SIZES.large
  },
  disabled: {
    ...primary,
    ...SIZES.large,
    opacity: 0.2,
    boxShadow: "none"
  },
  label: {
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "1em",
    whiteSpace: "nowrap"
  }
};

export const buttonPrimaryLarge = {
  backgroundColor: "#6592CC",
  color: "white",
  height: 60,
  borderRadius: 5,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 0,
  marginLeft: 20,
  "&:first-child": {
    marginLeft: 0
  },
  boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
  "&:hover": {
    backgroundColor: "#3D5A80",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,0.2)",
    cursor: "pointer"
  }
};

export const buttonSecondaryLarge = {
  ...buttonPrimaryLarge,
  color: colors.BODY_FONT_BLACK,
  backgroundColor: "white",
  border: "1px solid #2A2B2C",
  "&:hover": {
    backgroundColor: "rgba(85,91,110,0.1)",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,0.2)",
    cursor: "pointer"
  }
};

const buttonStyles = {
  outline: outlineButtonClasses.root,
  primary: buttonPrimaryClasses.root
};

export default buttonStyles;
