import React from "react";
import { PropTypes } from "prop-types";

import IconArrowDown from "@material-ui/icons/KeyboardArrowDown";
import IconArrowUp from "@material-ui/icons/KeyboardArrowUp";
import IconWarning from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";

import { LIGHT_GREY, RED } from "../../../styles/colors";

import CompletionSection from "./CompletionSection";
import { getProfileCompletionState } from "./utils";

const styles = {
  root: {
    backgroundColor: "white",
    borderTop: `1px solid ${LIGHT_GREY}`
  },
  container: {
    display: "flex",
    alignItems: "center",
    height: 80
  },
  icon: {
    fill: RED,
    marginRight: 10
  },
  sections: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 25
  }
};

class ProfileCompletionHint extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
  };
  static defaultProps = {};

  state = { open: true };

  toggleOpenState = () => {
    this.setState({ open: !this.state.open });
  };

  renderSections = sections => {
    const { classes } = this.props;

    return (
      <div className={classes.sections}>
        {sections.map((section, idx) => (
          <CompletionSection key={idx} {...section} />
        ))}
      </div>
    );
  };

  render() {
    const { classes, company, profile } = this.props;

    // only show this to owner
    if (company.id !== profile.company) return null;

    const sections = getProfileCompletionState(company);

    if (!sections.some(section => section.completed === false)) {
      return null;
    }

    const ArrowIcon = this.state.open ? IconArrowUp : IconArrowDown;

    return (
      <div className={classes.root}>
        <div className={`body ${classes.container}`}>
          <IconWarning className={classes.icon} />
          <span>
            {company.name}'s company profile is <strong>incomplete.</strong>
          </span>
          <div style={{ flexGrow: 1 }} />
          <ArrowIcon onClick={this.toggleOpenState} />
        </div>
        {this.state.open ? this.renderSections(sections) : null}
      </div>
    );
  }
}

export { styles, ProfileCompletionHint };
export default withStyles(styles)(ProfileCompletionHint);
