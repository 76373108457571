import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { GREY_DARK } from "../../styles/colors";
import { ReactComponent as ColinkedLogo } from "../../assets/images/colinked-logo-small.svg";

const styles = {
  root: {
    padding: 30,
    textAlign: "center"
  },
  message: {
    animation: "loading-message 2s",
    color: GREY_DARK,
    fontSize: 14
    // fontWeight: 700,
    // marginTop: 15
  },
  logo: {
    animation: "loading-spin infinite 2s linear"
  },
  "@keyframes loading-message": {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  },
  "@keyframes loading-spin": {
    from: {
      transform: "rotate(0deg)"
    },
    to: {
      transform: "rotate(360deg)"
    }
  }
};

const LoadingPanel = props => {
  const { classes, message } = props;

  return (
    <div className={classes.root}>
      <ColinkedLogo className={classes.logo} />

      <div className={classes.message}>{message}</div>
    </div>
  );
};

LoadingPanel.propTypes = {
  message: PropTypes.string.isRequired
};

LoadingPanel.defaultProps = {
  message: "loading ..."
};

export { LoadingPanel, styles };
export default withStyles(styles)(LoadingPanel);
