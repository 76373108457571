import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import founderImage from "../../../assets/flow-diagram.svg";

const styles = {
  root: {
    boxSizing: "border-box",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    padding: 30,
    height: "100%"
  },

  image: {
    width: 120,
    height: 90
  },
  link: {
    color: "#398DD3",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  text: {
    fontSize: 15,
    maxWidth: 680,
    textAlign: "center"
  },
  title: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10,
    color: "#AAADB6"
  }
};

const ManageChart = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <img src={founderImage} alt="" className={classes.image} />
      <div className={classes.title}>Analytics Coming Soon</div>
      <div className={classes.text}>
        We’re working on program analytics right now, and we’d love to hear from
        you! What analytics would be most helpful for your programs? You can{" "}
        <a
          className={classes.feedback_sm}
          href="mailto:support@colinked.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          share your suggestions via email.
        </a>
      </div>
    </div>
  );
};

ManageChart.propTypes = {
  classes: PropTypes.object.isRequired
};

export { ManageChart, styles };
export default withStyles(styles)(ManageChart);
