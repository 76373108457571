import React from "react";

import { DashboardContainer } from "../components/Dashboard";

import PageTemplate from "./PageTemplate";

const Dashboard = props => {
  return (
    <PageTemplate>
      <DashboardContainer />
    </PageTemplate>
  );
};

export default Dashboard;
