import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

export const SET_NOTIFICATION_HIDDEN = "SET_NOTIFICATION_HIDDEN";
export const SET_NOTIFICATION_PENDING = "SET_NOTIFICATION_PENDING";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_LOADING = "SET_NOTIFICATIONS_LOADING";

export const setNotificationPending = (id, pending = true) => {
  return {
    type: SET_NOTIFICATION_PENDING,
    payload: { id, pending }
  };
};

export const setNotificationHidden = id => {
  return {
    type: SET_NOTIFICATION_HIDDEN,
    payload: id
  };
};

export const setNotifications = notifications => {
  return {
    type: SET_NOTIFICATIONS,
    payload: notifications
  };
};

export const setNotificationsLoading = () => {
  return {
    type: SET_NOTIFICATIONS_LOADING
  };
};

export const clearNotifications = () => {
  return dispatch => {
    dispatch(setNotifications([]));
  };
};

export const fetchNotifications = () => {
  return dispatch => {
    dispatch(setNotificationsLoading());

    return fetchWithToken("/api/notifications/", {
      cache: "no-cache",
      credentials: "same-origin"
    })
      .then(response => responseJsonOrError(response))
      .then(json => {
        if (json.error) throw json.error;
        dispatch(setNotifications(json.results));
      })
      .catch(error => {
        console.log(error);
        dispatch(setNotifications([]));
      });
  };
};

export const hideNotification = id => {
  return dispatch => {
    dispatch(setNotificationPending(id, true));

    return fetchWithToken(`/api/notifications/${id}/`, {
      cache: "no-cache",
      credentials: "same-origin",
      method: "PUT",
      body: JSON.stringify({ is_silenced: true })
    })
      .then(response => responseJsonOrError(response))
      .then(json => {
        if (json.error) {
          dispatch(setNotificationPending(id, false));
        } else {
          dispatch(setNotificationHidden(id));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(setNotificationPending(id, false));
      });
  };
};

export const updateNotifications = () => {
  return fetchNotifications();
};
