import formStyles from "../formStyles";

const selectionStyle = {
  fontSize: 14,
  fontWeight: 300,
  height: 19,
  borderRadius: 3,
  backgroundColor: "#F2F3F4",
  padding: "10px 15px"
};

const styles = {
  root: {
    marginTop: 20
  },
  addOptionButton: {
    display: "flex",
    alignItems: "center",
    color: "#6592CC",
    fontSize: 14,
    fontWeight: 600,
    margin: "20px 0",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  container: {
    marginBottom: 30
  },
  controlsRoot: {
    flexShrink: 0,
    marginTop: 30
  },
  empty: {
    color: "#AAA6BD",
    fontSize: 14,
    fontStyle: "italic",
    fontWeight: 300,
    lineHeight: "17px",
    marginBottom: 20
  },
  icon: {
    fill: "#2A2B2C",
    fontSize: 20,
    cursor: "pointer",
    marginLeft: 10
  },
  iconLeft: {
    fontSize: 20,
    cursor: "pointer",
    marginRight: 10
  },
  iconCheckbox: {
    fill: "#AAA6BD",
    fontSize: 20,
    cursor: "pointer",
    marginRight: 10
  },
  iconCheckboxChecked: {
    fill: "#398DD3",
    fontSize: 20,
    cursor: "pointer",
    marginRight: 10
  },
  label: {
    fontSize: 14,
    fontWeight: 300,
    marginBottom: 6
  },
  option: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: 20
  },
  optional: {
    color: "#2A2B2C",
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    fontSize: 14
  },
  optionsRoot: {
    marginLeft: 20
  },
  row: {
    display: "flex"
  },

  selection: {
    ...selectionStyle,
    //backgroundColor: "white",
    color: "#555B6E",
    fontWeight: 600,
    //paddingLeft: 0,
    display: "flex",
    alignItems: "center"
  },
  type: {
    marginLeft: 10,
    width: 200,
    flexShrink: 0
  },
  updateTypeLink: {
    color: "#6592CC",
    fontSize: 14,
    fontWeight: 600,
    marginTop: 5,
    marginLeft: 4,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  value: {
    // outline: "1px solid green",
    flexGrow: 1,
    maxWidth: 440
  },
  valueInput: {
    ...selectionStyle,
    width: "calc(100% -30px)"
  },

  // overrides for form components
  textFieldRoot: {
    ...formStyles.root,
    margin: 0,
    width: "100%"
  },
  formRoot: {
    ...formStyles.formRoot,
    width: "100%"
  },
  formInput: {
    ...formStyles.formInput,
    height: "auto"
  },
  formInputLabel: {
    ...formStyles.formInputLabel
    //position: "static"
  },
  formInputRoot: {
    marginTop: 0,
    marginBottom: 0
  },
  formTagsRoot: {
    marginTop: 0,
    marginBottom: 0
  }
};

export default styles;
