import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import founderImage from "../../assets/girl-green.svg";
import corpImage from "../../assets/guy-with-beard.svg";

import { getPrimaryButtonClasses } from "../../styles/buttonStyles";

const buttonBlueStyles = getPrimaryButtonClasses("#398DD3");
const buttonGreenStyles = getPrimaryButtonClasses("#85C97C");

const headerStyle = {
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 20
};

const styles = {
  root: {
    maxWidth: 800,
    margin: "0 auto",
    marginBottom: 100
  },

  panels: {
    display: "flex",
    justifyContent: "center",

    fontSize: 16,
    textAlign: "center",
    width: 500,
    margin: "0 auto"
  },
  bold: {
    fontWeight: 700,
    marginTop: 15,
    marginBottom: 30
  },
  blue: {
    ...headerStyle,
    color: "#398DD3"
  },
  green: {
    ...headerStyle,
    color: "#85C97C"
  },
  image: {
    width: 156,
    height: 119,
    marginBottom: 30
  },
  panel: {
    boxSizing: "border-box",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 30,
    marginRight: 40,
    "&:last-child": {
      marginRight: 0
    },
    minWidth: 380
  },
  text: { flexGrow: 1, minHeight: 132 },
  title: {
    color: "#2A2B2C",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "100px",
    height: 100
  },

  buttonBlueRoot: {
    ...buttonBlueStyles.root,
    marginTop: 30
  },
  buttonBlueLabel: {
    ...buttonBlueStyles.label
  },
  buttonGreenRoot: {
    ...buttonGreenStyles.root,
    marginTop: 30
  },
  buttonGreenLabel: {
    ...buttonGreenStyles.label
  }
};

const GuestLandingPage = props => {
  const {
    corpButtonLabel,
    founderButtonLabel,
    classes,
    onCorpClick,
    onFounderClick
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Select your company type to start your application
      </div>
      <div className={classes.panels}>
        <div className={classes.panel}>
          <img src={founderImage} alt="" className={classes.image} />
          <div className={classes.green}>Apply as a Founder Company</div>
          <div className={classes.text}>
            Founder teams not currently Colinked members are welcome to apply
            for programs. As a guest application, you won’t be able to save your
            progress so only apply when you are ready. Good luck!
          </div>

          <Button
            classes={{
              root: classes.buttonGreenRoot,
              label: classes.buttonGreenLabel
            }}
            onClick={onFounderClick}
          >
            {founderButtonLabel}
          </Button>
        </div>
        <div className={classes.panel}>
          <img src={corpImage} alt="" className={classes.image} />
          <div className={classes.blue}>Apply as a Corporate</div>
          <div className={classes.text}>
            Corporate applications are limited to registered members, but don’t
            fret, corporates can join our community free for a limited time.
            Become a member today to apply and connect with other corporates and
            founder companies.
          </div>

          <Button
            classes={{
              root: classes.buttonBlueRoot,
              label: classes.buttonBlueLabel
            }}
            onClick={onCorpClick}
          >
            {corpButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

GuestLandingPage.propTypes = {
  corpButtonLabel: PropTypes.string,
  founderButtonLabel: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onCorpClick: PropTypes.func,
  onFounderClick: PropTypes.func
};

GuestLandingPage.defaultProps = {
  corpButtonLabel: "Become a member",
  founderButtonLabel: "Start Application",
  onCorpClick: () => {},
  onFounderClick: () => {}
};

export { GuestLandingPage, styles };
export default withStyles(styles)(GuestLandingPage);
