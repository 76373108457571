import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { logout } from "../../../Reducers/Profile";

import MobileLinks from "./MobileLinks";

const mapStateToProps = state => {
  return {
    company: state.company,
    profile: state.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};

const MobileLinksContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MobileLinks));

export default MobileLinksContainer;
