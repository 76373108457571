import React from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core/styles";

import CustomTableCell from "../common/StyledTable/CustomTableCell";

import ContactsRow from "./ContactsRow";
import * as renderers from "./renderers";

import styles from "./tableStyles";

const renderFooter = props => {
  const { classes, FooterComponent, headers } = props;

  if (!FooterComponent) return null;

  return (
    <TableFooter className={classes.footer}>
      <TableRow>
        <CustomTableCell span={headers.length}>
          <FooterComponent />
        </CustomTableCell>
      </TableRow>
    </TableFooter>
  );
};

const renderRow = (connection, renderers, classes) => {
  const _classes = { ...classes, row: classes.rowNoBorder };

  if (connection.showContacts) {
    return (
      <React.Fragment key={connection.id}>
        {_renderRow(connection, renderers, _classes)}
        <ContactsRow connection={connection} colSpan={renderers.length} />
      </React.Fragment>
    );
  }

  return _renderRow(connection, renderers, classes);
};

const _renderRow = (connection, renderers, classes) => {
  return (
    <TableRow className={classes.row} key={connection.id}>
      {renderers.map((cellRenderer, index) => (
        <CustomTableCell key={index}>
          {cellRenderer(connection, classes)}
        </CustomTableCell>
      ))}
    </TableRow>
  );
};

const ConnectionsTable = props => {
  const { classes, company, connections, headers } = props;

  const _renderers = [
    renderers.renderCompany(company),
    renderers.renderCompanyType(company),
    renderers.renderConnectionDate,
    renderers.renderConnectionStatus(company),
    renderers.renderContactsButton(company)
  ];

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <CustomTableCell className={classes.th} key={header || index}>
                {header}
              </CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tablebody}>
          {connections.map(document =>
            renderRow(document, _renderers, classes)
          )}
        </TableBody>
        {renderFooter(props)}
      </Table>
    </div>
  );
};

ConnectionsTable.propTypes = {
  FooterComponent: PropTypes.func,
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  connections: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  renderers: PropTypes.arrayOf(PropTypes.func).isRequired
};

ConnectionsTable.defaultProps = {
  headers: ["Company", "Type", "Date Connected", "Status", ""],
  renderers: [
    renderers.renderCompany,
    renderers.renderCompanyType,
    renderers.renderConnectionDate,
    renderers.renderConnectionStatus,
    renderers.renderContactsButton
  ]
};

export default withStyles(styles)(ConnectionsTable);
