import { fetchWithToken } from "../../api";
import { responseJsonOrError } from "../../api/response";

export const loadApplications = (doctype, documentId) => {
  // limit participants list to pending applications
  const url =
    doctype === "program"
      ? `/api/programs/${documentId}/participants/?is_approved=1`
      : `/api/think_tanks/${documentId}/participants/?is_approved=1`;

  return fetchWithToken(url)
    .then(response => responseJsonOrError(response))
    .catch(error => error);
};

export const loadApplication = (doctype, documentId, id) => {
  const url =
    doctype === "program"
      ? `/api/programs/${documentId}/participants/${id}/`
      : `/api/think_tanks/${documentId}/participants/${id}/`;

  return fetchWithToken(url)
    .then(response => responseJsonOrError(response))
    .catch(error => error);
};

export const loadParticipants = (doctype, documentId) => {
  const url =
    doctype === "program"
      ? `/api/programs/${documentId}/participants/?is_approved=2`
      : `/api/think_tanks/${documentId}/participants/?is_approved=2`;

  return fetchWithToken(url).then(response => responseJsonOrError(response));
};

export const loadFounderApplications = () => {
  return fetchWithToken("/api/companies/applications")
    .then(response => responseJsonOrError(response))
    .catch(error => error);
};
