import React from "react";

import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";

import Event from "./Event";

const styles = {
  header: {
    color: "#AAADB6",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 25
  },
  root: {},
  xl: {},
  lg: {},
  md: {},
  sm: {},
  xs: {
    marginLeft: -30,
    width: "90vw"
  }
};

const EventsCalendar = props => {
  const { classes, events, width } = props;

  const panels = events.length ? (
    events.map(event => <Event {...event} key={event.name} />)
  ) : (
    <Typography variant="caption" component="p">
      (no comming events scheduled)
    </Typography>
  );

  return (
    <div className={`${classes.root} ${classes[width]}`}>
      <Typography
        gutterBottom
        variant="subheading"
        component="h3"
        className={classes.header}
      >
        Upcoming Events
      </Typography>
      {panels}
    </div>
  );
};

EventsCalendar.defaultProps = {
  events: []
};

export default withWidth()(withStyles(styles)(EventsCalendar));
