import React from "react";
import PropTypes from "prop-types";

import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";

import ErrorPanel from "../common/Errors/ErrorPanel";
import SpinnerButton from "../common/SpinnerButton";

import BooleanField from "../Form/BooleanField";
import FormInputField from "../Form/FormInputField";

import styles from "../common/dialogStyles";

const SUCCESS_MESSAGE = "The request was successful.";

class CallbackDialog extends React.Component {
  constructor(props) {
    super(props);

    this.id = `id_${Math.random()}`;
    this.contentRef = React.createRef();

    const state = props.fields
      .filter(field => field.name)
      .reduce((state, field) => {
        state[field.name] = field.value || "";
        return state;
      }, {});

    this.state = {
      ...state,
      _height: props.height,
      status: props.status,
      loading: props.loading,
      statusText: null,
      open: true,
      error: null,
      validationErrors: {}
    };
  }

  componentDidMount() {
    // change state to force re-render
    this.setState({ __forceHeightUpdate: true });
  }

  componentDidUpdate() {
    const content = document.getElementById(this.id);
    const height = content && content.offsetHeight;

    if (height && height !== this.state._height) {
      this.setState({ _height: height });
    }
  }

  onSubmit = () => {
    const { fields, onSubmit } = this.props;

    const data = fields
      .filter(field => field.name)
      .map(field => field.name)
      .reduce((data, name) => {
        data[name] = this.state[name];
        return data;
      }, {});

    onSubmit(data);
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ open: false }, () => {
      if (onClose) onClose();
    });
  };

  setValue = (name, value) => {
    const validationErrors = Object.keys(this.state.validationErrors).reduce(
      (errors, key) => {
        if (key !== name) {
          errors[key] = this.state.validationErrors[key];
        }
        return errors;
      },
      {}
    );

    this.setState({ [name]: value, status: null, validationErrors });
  };

  renderCancelButton = () => {
    const { classes } = this.props;

    return (
      <div className={classes.buttonCancel} onClick={this.handleClose}>
        <CloseIcon />
      </div>
    );
  };

  renderSubmitButton = () => {
    const { buttonLabel, buttonStyle, classes, error, loading } = this.props;

    const empty = this.props.fields
      .filter(field => field.name)
      .filter(field => field.type && field.type !== "bool")
      .some(field => this.state[field.name] === "");

    const disabled = Boolean(loading || error || empty);

    const classNames =
      buttonStyle === "warning"
        ? {
            root: classes.buttonWarning,
            label: classes.buttonLabelWarning
          }
        : {
            root: classes.button,
            label: classes.buttonLabel
          };

    return error ? null : (
      <SpinnerButton
        disabled={disabled}
        classes={classNames}
        onClick={this.onSubmit}
        spinner={loading}
      >
        {buttonLabel}
      </SpinnerButton>
    );
  };

  renderInputField = (spec, index) => {
    const { classes } = this.props;
    const { name, helperText, label, rows, type } = spec;
    const validationError = this.state.validationErrors[name];

    if (!name)
      return (
        <div key={index} className={classes.helperText}>
          {helperText}
        </div>
      );

    if (type && type === "bool") {
      return (
        <BooleanField
          key={name}
          id={name}
          label={label || name}
          value={Boolean(this.state[name])}
          onChange={value => this.setValue(name, value)}
        />
      );
    }

    return (
      <FormInputField
        key={name}
        id={name}
        label={label || name}
        value={this.state[name]}
        onChange={value => this.setValue(name, value)}
        width="100%"
        rows={rows}
        type={type}
        classes={{ root: classes.formRoot }}
        helperText={validationError || helperText}
        error={Boolean(validationError)}
      />
    );
  };

  renderForm = () => {
    const { fields } = this.props;

    return (
      <React.Fragment>
        {fields.map((spec, index) => this.renderInputField(spec, index))}
      </React.Fragment>
    );
  };

  renderError = () => {
    const error = this.props.error || this.state.error;
    const { validationErrors } = this.state;

    if (!error && !Object.keys(validationErrors).length) return null;

    const { classes } = this.props;

    return (
      <ErrorPanel
        classes={{
          root: classes.errorRoot,
          title: classes.errorTitle,
          message: classes.errorMessage
        }}
        error={error || new Error("Validation error")}
        validationErrors={this.state.validationErrors}
      />
    );
  };

  renderStatus = () => {
    const { classes, statusText } = this.props;
    if (!statusText) return null;

    const className =
      statusText === SUCCESS_MESSAGE ? classes.successText : classes.statusText;

    return <div className={className}>{statusText}</div>;
  };

  render() {
    const { classes, title, width } = this.props;
    const height = this.state._height || this.props.height;

    const marginTop = `-${(height + 60) / 2}px`;
    const marginLeft = `-${(width + 60) / 2}px`;
    const containerHeight = height - 49; // title=19, title-margin=30

    return (
      <Modal open={this.state.open} onClose={this.handleClose}>
        <div
          className={classes.paper}
          style={{ height, width, marginTop, marginLeft }}
        >
          <div id={this.id} ref={this.contentRef}>
            <div className={classes.title}>{title}</div>
            <div
              className={classes.container}
              style={{ _height: containerHeight }}
            >
              {this.renderForm()}
              {this.renderStatus()}
              {this.renderError()}

              {this.renderSubmitButton()}
            </div>
          </div>
          {this.renderCancelButton()}
        </div>
      </Modal>
    );
  }
}

CallbackDialog.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonStyle: PropTypes.oneOf(["default", "warning"]),
  error: PropTypes.object,
  loading: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      rows: PropTypes.number,
      value: PropTypes.any
    })
  ),
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired
};

CallbackDialog.defaultProps = {
  buttonLabel: "Submit",
  buttonStyle: "default",
  height: 350,
  width: 390
};

export default withStyles(styles)(CallbackDialog);
