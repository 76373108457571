import React from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import playButton from "../../assets/images/homepage/colinked-play-button.svg";
import videoBackground from "../../assets/images/homepage/colinked-video-background.jpg";
import videoPreview from "../../assets/images/homepage/colinked-video-preview-square.gif";

import { PrimaryButtonLarge } from "../common/Buttons";

import VideoPlayerModal from "../common/VideoPlayerModal";

const styles = {
  root: {
    padding: "70px 20px"
  },
  link: {
    color: "#398dd3",
    fontWeight: 600,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  container: {
    display: "flex",
    alignItems: "center"
  },
  content: {
    padding: 50
  },
  playButton: {
    position: "absolute",
    bottom: 0,
    right: 88,
    "&:hover": {
      cursor: "pointer"
    }
  },
  preview: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    objectFit: "cover",
    width: "380px",
    height: "380px",
    borderRadius: "50%",

    "&:hover": {
      cursor: "pointer"
    }
  },
  text: {
    maxWidth: 580
  },
  videobg: {
    position: "relative",
    flexShrink: 0,
    textAlign: "center",

    backgroundImage: `url( "${videoBackground}" )`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center",

    width: 580,
    height: 470
  }
};

class AboutColinked extends React.Component {
  state = {
    modal: false
  };

  showModal = () => {
    if (!this.state.modal) return;

    return (
      <VideoPlayerModal
        key={`${Math.random()}`}
        url="https://www.youtube.com/embed/qBVyDW_AWCk"
      />
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <section className={`body ${classes.root}`}>
        {this.showModal()}
        <div className={classes.container}>
          <div className={classes.videobg}>
            <img
              className={classes.preview}
              src={videoPreview}
              alt="Colinked - Gif preview of Colinked video"
              onClick={() => this.setState({ modal: true })}
            />

            <img
              className={classes.playButton}
              onClick={() => this.setState({ modal: true })}
              src={playButton}
              alt="Colinked - Video play button"
            />
          </div>

          <div className={classes.content}>
            <h2>About Colinked</h2>
            <p className={classes.text}>
              After years of matching corporates and scale-ups offline, we built
              Colinked to scale our process and allow industry-wide
              collaborations. We’re doing this because we understand the
              challenges of corporate engagement and the necessity of building
              strong routes to market for founders.
            </p>
            <PrimaryButtonLarge
              style={{ marginLeft: 0 }}
              onClick={() => this.setState({ modal: true })}
            >
              Watch Our Video
            </PrimaryButtonLarge>
          </div>
        </div>
      </section>
    );
  }
}

export default withStyles(styles)(withRouter(AboutColinked));
