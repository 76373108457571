import "react-app-polyfill/ie11";
import "@babel/polyfill";

//import "core-js";
// import "core-js/fn/array/find";
// import "core-js/fn/object/values";
// import "core-js/fn/string/ends-with";
// import "core-js/fn/string/starts-with";
// import "core-js/fn/string/includes";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";
unregister();

ReactDOM.render(<App />, document.getElementById("root"));
//registerServiceWorker();
