import React from "react";
import PropTypes from "prop-types";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import { withStyles } from "@material-ui/core/styles";

import { BODY_FONT_BLACK, GREY_DARK, GREY_LIGHT } from "../../../styles/colors";
import { SecondaryButton } from "./";

const menuItemStyle = {
  fontSize: 15,
  padding: "10px 20px",
  color: GREY_DARK,
  "&:hover": {
    color: BODY_FONT_BLACK,
    backgroundColor: "white"
  }
};
const styles = {
  root: {
    display: "flex"
  },
  menuItem: {
    ...menuItemStyle
  },
  menuItemSep: {
    ...menuItemStyle,
    padding: 20,
    borderTop: `1px solid ${GREY_LIGHT}`,
    "&:first-child": {
      borderTop: "none"
    }
  },
  paper: {
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
    borderRadius: 0
  },
  popper: {
    position: "absolute",
    top: 50,
    right: 0,
    zIndex: 1
  },
  button: {}
};

class SubMenuButton extends React.Component {
  state = {
    open: false
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl && this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  renderMenuList = () => {
    const { actions, classes, separator } = this.props;

    const itemClass = separator ? classes.menuItemSep : classes.menuItem;

    const items = actions.map((action, index) => {
      const Icon = action.icon;

      return (
        <MenuItem
          key={index}
          className={itemClass}
          onClick={() => this.setState({ open: false }, action.action)}
          style={action.active ? { color: BODY_FONT_BLACK } : {}}
        >
          {Icon ? <Icon /> : null}
          {action.label}
        </MenuItem>
      );
    });

    return <MenuList>{items}</MenuList>;
  };

  render() {
    const {
      ButtonComponent,
      buttonProps,
      children,
      classes,
      menuWidth,
      style
    } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <div style={{ position: "relative" }}>
          <ButtonComponent
            className={classes.button}
            buttonRef={node => {
              this.anchorEl = node;
            }}
            aria-owns={open ? "sub-menu-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            {...buttonProps}
          >
            {children}
          </ButtonComponent>

          <Popper
            className={classes.popper}
            style={{ width: menuWidth }}
            open={open}
            anchorEl={this.anchorEl}
            placement="bottom-end"
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="sub-menu-grow"
                style={{
                  transformOrigin: "right top"
                }}
              >
                <Paper className={classes.paper} style={style}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    {this.renderMenuList()}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

SubMenuButton.propTypes = {
  ButtonComponent: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      active: PropTypes.bool,
      icon: PropTypes.func
    })
  ).isRequired,
  buttonProps: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  menuWidth: PropTypes.number.isRequired,
  separator: PropTypes.bool,
  style: PropTypes.object.isRequired
};

SubMenuButton.defaultProps = {
  ButtonComponent: SecondaryButton,
  actions: [],
  buttonProps: {},
  menuWidth: 220,
  separator: false,
  style: {}
};

export { SubMenuButton, styles };
export default withStyles(styles)(SubMenuButton);
