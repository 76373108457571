import React from "react";

import Typography from "@material-ui/core/Typography";

import Section from "../common/Section";

import NewsList from "./NewsList";

const LATEST_NEWS_REQUEST = "/api/news/";

class NewsListContainer extends React.Component {
  state = {
    articles: [],
    error: null
  };

  componentDidMount() {
    this.loadArticles();
  }

  loadArticles = () => {
    fetch(LATEST_NEWS_REQUEST)
      .then(response => {
        const { status, statusText, message } = response;

        if (status === "success" || status === 200) {
          return response.json();
        } else {
          const msg = message || `${status} (${statusText})`;

          this.setState({ error: new Error(msg) });
          return { results: [] };
        }
      })
      .then(json => json.results)
      .then(articles => this.setState({ articles: articles.slice(0, 4) }))
      .catch(err => {
        const error = new Error(
          `An error occured while loading news articles: ${err.message}`
        );
        this.setState({ error });
      });
  };

  render() {
    const { title, ...other } = this.props;
    const { articles, error } = this.state;

    if (error) {
      return (
        <Section title={title} fullwidth={false}>
          <Typography color="error" variant="body2">
            Error: {error.message}
          </Typography>
        </Section>
      );
    }

    return <NewsList {...this.props} {...other} articles={articles} />;
  }
}

NewsListContainer.defaultProps = {
  title: "News"
};

export default NewsListContainer;
