const optionAsString = option => {
  const keys = Object.keys(option);

  return keys.length ? `${keys[0]}=${option[keys[0]]}` : null;
};

export const formatSearchString = options => {
  if (!options.length) return null;

  return options
    .map(item => optionAsString(item))
    .filter(option => option)
    .join("&");
};
