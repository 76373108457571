import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import rootReducer from "./Reducers";

const persistConfig = {
  key: "fuse.store",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    "config",
    "myApplications",
    "notifications",
    // "profile",
    "referrals"
  ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const defaultState = {};

export default createStore(
  persistedReducer,
  defaultState,
  applyMiddleware(thunkMiddleware)
);
