import * as COLORS from "./colors";

export const linkStyle = {
  fontSize: 15,
  lineHeight: "20px",
  fontWeight: 700,
  color: COLORS.BLUE_PRIMARY,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
};
