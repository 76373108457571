import emailvalidator from "email-validator";
import moment from "moment";

// TODO: clean up regex
// eslint-disable-next-line no-useless-escape
const REGEX_URL = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

export const validateAudience = options => {
  const { open_to_founders, open_to_corporates } = options;

  if (!open_to_founders && !open_to_corporates) {
    return "Select founders, corporates or both";
  }

  return null;
};

// validate company attributes
//
// NOTE: the return value is a string on error, null on success
//
export const validateCompanyAttribute = (required_fields = []) => (
  id,
  value
) => {
  if (required_fields.indexOf(id) !== -1) {
    if (!value) {
      return "This field is required";
    }
  }

  if (id === "website") {
    if (!value.startsWith("http://") && !value.startsWith("https://")) {
      return "URL must begin with 'http://' or 'https://'.";
    }
    if (!_validateURL(value)) {
      return "Please provide a valid URL (must begin with 'http://' or 'https://').";
    }
  }

  if (id === "year_founded") {
    if (!value) return null;

    const year = parseInt(value, 10);
    if (Number.isNaN(year)) {
      return `'${value}' is not a valid number`;
    }

    if (year < 1900) {
      return "founding year is too early";
    }
    if (year > new Date().getFullYear()) {
      return "founding year is invalid";
    }
  }

  if (id === "markets") {
    if (!value || value.length === 0) {
      return "At least one tag needs to be selected.";
    }
  }

  return null;
};

export const validateCompany = (validationFields = []) => {
  const validator = validateCompanyAttribute(validationFields);

  return company => {
    return validationFields.reduce((errors, attr) => {
      const msg = validator(attr, company[attr]);

      if (msg) {
        errors[attr] = msg;
      }

      return errors;
    }, {});
  };
};

export const validateCriteria = value => {
  const answered = value.filter(q => q.response !== undefined);

  const error =
    answered.length !== value.length
      ? {
          error: `${value.length - answered.length} out of ${
            value.length
          } questions unanswered`
        }
      : {};

  return error;
};

const questionIsAnswered = q => {
  if (!q.response) return false;

  if (Array.isArray(q.response) && q.response.length === 0) {
    return false;
  }

  return true;
};

export const validateQuestions = value => {
  const required = value.filter(q => !q.is_optional);

  const answered = required.filter(questionIsAnswered);

  if (answered.length !== required.length) {
    return {
      error: `${required.length - answered.length} out of ${
        required.length
      } required questions unanswered`
    };
  }

  return {};
};

export const validateCompanyFunding = company => {
  if (!company || !company.funding_rounds) {
    return { error: "Select your funding rounds." };
  }
  if (!company.capital_to_date) {
    return { error: "Select your funding range." };
  }
  if (!company.revenue_range) {
    return { error: "Select your revenue range." };
  }

  if (company.is_actively_seeking_funding && !company.current_target) {
    return { error: "Provide your current funding target." };
  }

  return {};
};

// const validateCompanyInvestors = value => {
//   return {};
// };

// validateCompanyTeam
export const validateCompanyTeam = (id = "team") => company => {
  const team = company[id] || [];
  if (!team || !team.length) {
    const error = "At least one team member has to be listed.";

    return { error };
  }

  return {};
};

export const validateDuration = (_, state) => {
  const { is_ongoing, end_datetime, start_datetime } = state;

  if (!is_ongoing && !start_datetime && !end_datetime) {
    return "Specify a date or timeframe";
  }

  return null;
};

export const validateEmail = value => {
  if (!emailvalidator.validate(value)) {
    return "Enter a valid email address";
  }

  return null;
};

const validateEndDate = (end, state) => {
  if (!end) return null;

  if (!state.start_datetime) return "End date should be before start date.";

  if (moment(end).isSameOrBefore(moment(state.start_datetime))) {
    return "End date should be before start date.";
  }
};

export const validateLocation = location => {
  if (!location) return null;

  const error = {};
  if (!location.city) {
    error.city = "Please provide the city or town.";
  }

  if (!location.country) {
    error.country = "Please provide the country.";
  }

  if (Object.keys(error).length) return error;

  return null;
};

export const validatePassword = value => {
  if (!value || value.trim().length === 0) {
    return "Empty passwords are not allowed.";
  } else if (value.trim().length < 9) {
    return "Password needs to be at least 9 characters long.";
  }

  return null;
};

export const validatePasswordConfirmation = (value, state) => {
  const { password } = state;

  if (!value) {
    return "Password confirmation is required.";
  }

  if (password !== value) {
    return "Password and password confirmation are different.";
  }

  return validatePassword(value);
};

export const validateProfile = sections => {
  const heading = sections.find(section => section.type === "heading");
  if (!heading || !heading.content) {
    return "At least one heading has to be provided";
  }

  const richtext = sections.find(section => section.type === "richtext");
  if (!richtext || !richtext.content) {
    return "At least one text block has to be provided";
  }

  return null;
};

export const validateProgramName = (value, field = "name") => {
  if (!value) return "Name is required";
  if (value === "undefined") return "Name can't be 'undefined'.";

  return null;
};

export const validateTaC = value => {
  if (!value) return "You have to accept our Terms and Conditions.";

  return null;
};

export const validateTags = (minSelection = 1, type = "items") => value => {
  const condition =
    minSelection === 1
      ? `${minSelection} ${type} has`
      : `${minSelection} ${type} have`;

  if (!value || value.length < minSelection) {
    return `at least ${condition} to be selected`;
  }

  return null;
};

export const validateURL = (value = "", required = true) => {
  if (!value && !required) {
    return null;
  }

  if (!value && required) {
    return "website is required";
  }

  if (!value.startsWith("http://") && !value.startsWith("https://")) {
    return "URL must begin with 'http://' or 'https://'.";
  }

  if (!_validateURL(value)) {
    return "Please provide a valid URL (must begin with 'http://' or 'https://').";
  }

  return null;
};

export const validateURLOptional = value => validateURL(value, false);

export const _validateURL = value => {
  if (!value || typeof value !== "string") return false;

  if (!value.startsWith("http://") && !value.startsWith("https://"))
    return false;

  const _value = value.includes("://") ? value.split("://")[1] : value;
  const domain = _value.split("/")[0];

  return Boolean(domain.toLowerCase().match(REGEX_URL));
};

export const validateUsername = value => {
  const err = { username: "" }; // empty string to reset error
  if (!emailvalidator.validate(value)) {
    err.username = "Enter a valid email address";
  }

  return err;
};

export const validate = (state, fields) => {
  const validationErrors = fields
    .filter(field => field.type !== "separator")
    .filter(field => field.type !== "header")
    .reduce((errors, field) => {
      if (!field.id) {
        console.warn(`No id for field '${field.label}'.`);
      }
      const id = field.id;
      const value = field.dataId ? state[field.dataId] : state[id];

      // check presence of required values
      if (!value && field.required) {
        errors[id] = "This field is required.";
      }

      // check value with specific validation function
      if (field.validate) {
        const validation = field.validate(value, state);

        if (validation && validation.error) {
          errors[id] = validation.error;
        } else if (validation && Object.keys(validation).length) {
          errors[id] = validation;
        }
      }

      // check that end date is after start date
      if (id === "end_datetime") {
        const error = validateEndDate(value, state);

        if (error) {
          errors[id] = error;
        }
      }

      if (value && field.type === "email") {
        if (!emailvalidator.validate(value)) {
          errors[id] = "Enter a valid email address";
        }
      }

      return errors;
    }, {});

  // check if password and confirm_password are equal
  const confirm = fields.find(field => field.id === "confirm_password");
  if (confirm && state["password"] !== state["confirm_password"]) {
    validationErrors["confirm_password"] = "Passwords are not equal";
  }

  return validationErrors;
};

export const validateField = (field, value, state = {}) => {
  if (field.type === "separator") return null;
  if (field.type === "header") return null;

  // check required values
  if (field.required && !value) {
    return `${field.label} is required`;
  }

  // double-check length limit (already caught by text field)
  if (field.limit && value.length > field.limit) {
    return `${field.label} can only be ${field.limit} characters long`;
  }

  // check value with specific validation function
  if (value && field.validate) {
    const error = field.validate(value);

    if (error) return error;
  }

  // check email format
  if (value && field.type === "email") {
    if (!emailvalidator.validate(value)) {
      return "Enter a valid email address";
    }
  }

  // check value with specific validation function
  if (field.id === "confirm_password") {
    const error = validatePasswordConfirmation(value, state);

    if (error) return error;
  }
};
