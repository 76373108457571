import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { responseJsonOrError } from "../../api/response";
import logosCorporate from "../../assets/images/homepage/colinked-homepage-corporate-icons.png";
import logosFounders from "../../assets/images/homepage/colinked-homepage-founder-icons.png";

const animationStyle = {
  animationCount: 1,
  animationDuration: 2000,
  backfaceVisibility: "hidden"
};

const styles = {
  root: {
    flex: "1 1 0",
    maxWidth: 600,
    position: "relative"
  },
  rootSmall: {
    width: 320,
    height: 320,
    margin: "0 auto",
    position: "relative"
  },
  circle: {
    backgroundColor: "white",
    position: "absolute",
    borderRadius: "50%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    boxShadow: "0 2px 20px 0 rgba(0,0,0,0.1)"
  },
  image: {
    width: "100%"
  },

  front: {
    animationName: "front-to-back",
    ...animationStyle
  },
  back: {
    animationName: "back-to-front",
    ...animationStyle,
    transform: "rotateY(180deg)"
  },
  "@keyframes front-to-back": {
    from: {
      transform: "rotateY(0deg)"
    },
    to: {
      transform: "rotateY(180deg)"
    }
  },
  "@keyframes back-to-front": {
    from: {
      transform: "rotateY(180deg)"
    },
    to: {
      transform: "rotateY(360deg)"
    }
  }
};

const addPositions = (companies, positions) => {
  const withLogo = companies.filter(node => node.logo_image_url);

  return resizeCompaniesArray(withLogo, positions).map((node, index) => {
    const posIndex = index % positions.length;
    const pos = positions[posIndex];

    return { ...node, ...pos };
  });
};

/*
 * Top have all positions filled and only have a single
 * pair replacing each other the number of companies
 * needs to be a multiple of the numper of positions.
 *
 * Also, the order of companies should be randomized.
 */
const resizeCompaniesArray = (companies, positions) => {
  const nTarget =
    !companies.length % positions.length
      ? companies.length
      : Math.ceil(companies.length / positions.length) * positions.length;

  return [...shuffle(companies), ...shuffle(companies)].slice(0, nTarget);
};

const shuffle = arr => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

class LogoContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    defaultWidth: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    left: PropTypes.bool,
    positions: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired
      })
    ).isRequired,
    url: PropTypes.oneOf([
      "/api/companies/corporations/",
      "/api/companies/founders/"
    ]).isRequired
  };

  static defaultProps = {
    defaultWidth: 600,
    duration: 2000,
    positions: [
      { x: 60, y: 60, size: 135 },
      { x: 225, y: 155, size: 110 },
      { x: 330, y: 10, size: 140 },
      { x: 15, y: 245, size: 160 },
      { x: 400, y: 195, size: 180 },
      { x: 235, y: 325, size: 120 },
      { x: 125, y: 450, size: 80 },
      { x: 375, y: 425, size: 120 }
    ]
  };

  constructor(props) {
    super(props);

    this.id = `id_${Math.random()}`.replace(".", "");

    this.state = {
      companies: [],
      slice: [],
      height: props.defaultWidth,
      scale: 1.0
    };

    this._UNMOUNTING = false;
    this.updateInterval = null;
  }

  componentDidMount() {
    this.loadImages();

    window.addEventListener("resize", this.resize);
    this.resize();
  }

  componentWillUnmount() {
    this._UNMOUNTING = true;
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }

    window.removeEventListener("resize", this.resize);
  }

  getRootClass = () => {
    const { classes, left, width } = this.props;

    if (width === "xs" || width === "sm") return classes.rootSmall;

    return left ? `${classes.root} left` : classes.root;
  };

  loadImages = () => {
    const { duration, positions } = this.props;
    const sliceLength = positions.length;

    fetch(`${this.props.url}?limit=1000`)
      .then(responseJsonOrError)
      .then(json => {
        const { results, error } = json;

        if (this._UNMOUNTING) return;

        if (error) {
          this.setState({ error });
        } else {
          const companies = addPositions(results, positions);

          this.setState({
            companies,
            index: 0,
            slice: companies.slice(0, sliceLength + 1)
          });
          this.updateInterval = setInterval(this.updateSlice, duration);
        }
      });
  };

  resize = () => {
    const div = document.getElementById(this.id);

    if (!div) return;

    const width = div.offsetWidth;
    const scale = width / this.props.defaultWidth;

    if (this._UNMOUNTING) return;

    this.setState({ height: width, scale, width });
  };

  updateSlice = () => {
    const { companies } = this.state;
    if (!companies.length) return;

    const index = (this.state.index + 1) % companies.length;

    const slice = [...companies, ...companies].slice(
      index,
      index + this.props.positions.length + 1
    );

    if (this._UNMOUNTING) return;

    this.setState({ index, slice });
  };

  renderImages = () => {
    const { classes, duration } = this.props;
    const { scale, slice } = this.state;

    if (!slice.length) return null;

    return slice.map((node, idx, nodes) => {
      const className =
        idx === 0
          ? `${classes.circle} ${classes.front}`
          : idx === nodes.length - 1
          ? `${classes.circle} ${classes.back}`
          : classes.circle;

      return (
        <div
          key={`${node.name}_${idx}`}
          className={className}
          style={{
            left: node.x * scale,
            top: node.y * scale,
            width: node.size * scale,
            height: node.size * scale,
            animationDuration: duration,
            backgroundImage: `url("${node.logo_image_url}")`
          }}
        />
      );
    });
  };

  renderStaticImage = () => {
    const { classes, url } = this.props;

    const logoSrc = url.endsWith("corporates/")
      ? logosCorporate
      : logosFounders;

    return (
      <img
        className={classes.image}
        src={logoSrc}
        alt="Colinked - corporate logos of businesses that are on the Colinked platform"
      />
    );
  };

  render() {
    const { error, height } = this.state;

    return (
      <div
        id={this.id}
        className={this.getRootClass()}
        style={{ height }}
        onClick={this.updateSlice}
      >
        {error ? this.renderStaticImage() : this.renderImages()}
      </div>
    );
  }
}

export { styles, LogoContainer };
export default withWidth()(withStyles(styles)(LogoContainer));
