export const questionTypes = [
  "text_input",
  "single_choice_input",
  "multiple_choice_input",
  "file_upload"
];

export const questionTypeLabels = {
  text_input: "Text Answer",
  single_choice_input: "Single Choice Answer",
  multiple_choice_input: "Multiple Choice Answer",
  file_upload: "File upload request"
};

export const questionTypeLabelsShort = {
  text_input: "Text Answer",
  single_choice_input: "Single Choice",
  multiple_choice_input: "Multiple Choice",
  file_upload: "File upload"
};
