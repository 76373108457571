import {
  defaultProfile,
  REPLACE_PROFILE_DATA,
  RESET_PROFILE_DATA,
  SET_LOGIN_ERROR,
  SET_LOGIN_SUCCESS,
  SET_PROFILE_DATA,
  UPDATE_PROFILE_DATA
} from "./profileActionTypes";

// start app with user in loading state;
// loading state is reset in SessionContainer after mount
const initialState = { ...defaultProfile, loading: true };

export default (state = initialState, action) => {
  switch (action.type) {
    case REPLACE_PROFILE_DATA:
      return { ...defaultProfile, ...action.payload };

    case RESET_PROFILE_DATA:
      return { ...defaultProfile, ...action.payload };

    case SET_LOGIN_SUCCESS:
      return { ...state, isSignedIn: action.payload };

    case SET_LOGIN_ERROR:
      return {
        ...defaultProfile,
        isSignedIn: false,
        loading: false,
        loginError: action.payload
      };

    case SET_PROFILE_DATA:
      return { ...defaultProfile, ...action.payload };

    case UPDATE_PROFILE_DATA:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
