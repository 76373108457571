import React from "react";
import { withStyles } from "@material-ui/core/styles";

const REGEX_URL = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

const styles = {
  link: {
    color: "#398DD3",
    fontWeight: 600,
    "&:hover": {
      textDecoration: "underline"
    }
  }
};

export const ExternalLink = props => {
  const { classes, label, url } = props;
  const href = url.match(REGEX_URL) ? url : `http://${url}`;

  return (
    <a
      className={classes.link}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label || url}
    </a>
  );
};

export default withStyles(styles)(ExternalLink);
