import React from "react";

import CompanyProfile from "../components/Company/CompanyProfilePageContainer";

import PageTemplate from "./PageTemplate";

const CompanyProfilePage = props => {
  return (
    <PageTemplate scrollToTop={true}>
      <CompanyProfile {...props} />
    </PageTemplate>
  );
};

export default CompanyProfilePage;
