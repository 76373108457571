const buttonStyle = {
  padding: "20px 30px",
  fontSize: 16,
  fontWeight: 600,
  height: 60
};

const styles = {
  root: {
    borderLeft: "none",
    paddingLeft: 0
  },
  buttons: {
    marginTop: 60,
    marginBottom: 0
  },
  buttonCancel: {
    ...buttonStyle,
    marginLeft: 30,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  buttonSave: {
    ...buttonStyle,
    backgroundColor: "#6592CC",
    color: "white"
  }
};

export default styles;
