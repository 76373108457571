import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import {
  COMPANY_TYPE_CORP,
  COMPANY_TYPE_FOUNDER,
  loadCorporates,
  updateCorporates
} from "../../Reducers/Companies";

import Banner from "../common/Banner";
import CompaniesListViewContainer from "../Company/ListView/CompaniesListViewContainer";

class CorporatesListPage extends React.Component {
  static propTypes = {
    companyType: PropTypes.oneOf([COMPANY_TYPE_CORP, COMPANY_TYPE_FOUNDER])
      .isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    scrollview: PropTypes.object.isRequired,
    loadCompanies: PropTypes.func.isRequired,
    updateCompanies: PropTypes.func.isRequired
  };

  static defaultProps = {
    headers: ["Company", "Location", "Markets"]
  };

  render() {
    const { companyType, scrollview } = this.props;
    const key = `${companyType}_count`;
    const count = scrollview[key] || 0;

    return (
      <div>
        <Banner
          title={count ? `${count} Corporates` : "Corporates"}
          subtitle="Connect with corporate companies on Colinked."
        />
        <div className="body" style={{ marginBottom: 60 }}>
          <CompaniesListViewContainer {...this.props} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    companyType: COMPANY_TYPE_CORP,
    scrollview: state.scrollview
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCompanies: options => dispatch(loadCorporates(options)),
    updateCompanies: () => dispatch(updateCorporates())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorporatesListPage);
