import React from "react";
import VisibilitySettings from "./VisibilitySettings";

const loadCompanyNames = () => {
  // TODO: implement blacklist api call
  return Promise.resolve(["IBM", "McLaren", "Microsoft", "Oracle", "Virgin"]);
};

class VisibilitySettingsContainer extends React.Component {
  state = {
    companyNames: []
  };

  componentDidMount() {
    if (!this.props.companyNames) {
      loadCompanyNames().then(companyNames => this.setState({ companyNames }));
    }
  }

  render() {
    return (
      <VisibilitySettings
        companyNames={this.state.companyNames}
        {...this.props}
      />
    );
  }
}

export default VisibilitySettingsContainer;
