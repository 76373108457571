import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import formStyles from "../../styles/formStyles";

const styles = {
  root: {
    // outline: "1px solid red",
    marginTop: 20,
    width: "100%"
  },
  ...formStyles
};

const helperTextToString = obj => {
  if (!obj) return obj;

  if (typeof obj === "string") {
    return obj;
  }

  const { error } = obj;

  return error !== undefined ? error : `${obj}`;
};

const renderLabel = (props, textStyle) => {
  const { classes, label, required } = props;

  if (!label) return null;

  const _label = required ? `${label} (required)` : label;

  return (
    <div className={classes.label} style={textStyle}>
      {_label}
    </div>
  );
};

class TextInputField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limitError: null,
      helperText: props.helperText,
      value: props.value === null ? "" : props.value
    };
  }

  componentWillReceiveProps(nextProps) {
    const { helperText, value } = nextProps;

    if (helperText !== this.props.helperText || value !== this.props.value) {
      const _value = value === null ? "" : value;
      const _helperText = helperTextToString(helperText);

      this.setState({ helperText: _helperText, value: _value });
    }
  }

  onBlur = () => {
    this._onChange(this.props.value);

    this.props.onBlur && this.props.onBlur();
  };

  onChange = event => this._onChange(event.target.value);

  _onChange = _value => {
    const { limit } = this.props;
    const helperText = helperTextToString(this.props.helperText);

    const value = _value || "";

    if (!limit || value.length <= limit) {
      this.setState({ limitError: false, helperText, value }, () =>
        this.props.onChange(value)
      );
    } else {
      const limitErrorText =
        this.props.limitErrorText ||
        `maximum character length exceeded (${limit})`;

      this.setState(
        {
          limitError: true,
          helperText: limitErrorText,
          value: value.slice(0, limit)
        },
        () => this.props.onChange(value.slice(0, limit))
      );
    }
  };

  renderHelperText = textStyle => {
    const { classes } = this.props;
    if (!this.state.helperText) return null;

    return (
      <div className={classes.helperText} style={textStyle}>
        {this.state.helperText}
      </div>
    );
  };

  renderLimit = textStyle => {
    const { classes, limit, showLimit } = this.props;
    const value = this.state.value || "";

    if (!limit || !showLimit) return null;

    return (
      <div className={classes.helperText} style={textStyle}>
        {limit - value.length}/{limit} characters remaining
      </div>
    );
  };

  render() {
    const {
      classes,
      error: _error,
      helperText: _helperText,
      id,
      label,
      limit,
      limitErrorText,
      onChange,
      required,
      rows,
      showLimit,
      style,
      value,
      width,
      ...otherProps
    } = this.props;

    const error = this.state.limitError || _error;

    const errorStyle = error ? formStyles.error : {};
    const rowStyle = rows ? { height: rows * 17 + 20, resize: "none" } : {};
    const inputStyle = { ...rowStyle, ...errorStyle };

    const textStyle = error ? { color: "#DB6E53" } : {};

    const inputProps = {
      ...otherProps,
      id,
      className: error ? `${classes.error} ${classes.input}` : classes.input,
      onBlur: this.onBlur,
      onChange: this.onChange,
      value: this.state.value || "",
      rows: rows ? rows : null,
      style: inputStyle
    };

    return (
      <div className={classes.root} style={{ ...style, maxWidth: width }}>
        {renderLabel(this.props, textStyle)}
        {rows ? <textarea {...inputProps} /> : <input {...inputProps} />}
        {this.renderHelperText(textStyle)}
        {this.renderLimit(textStyle)}
      </div>
    );
  }
}

TextInputField.defaultProps = {
  label: "Input",
  limit: 0,

  type: "text",
  width: 440,
  rows: null,
  style: {}
};

TextInputField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  limit: PropTypes.number.isRequired,
  limitErrorText: PropTypes.string,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.any
};

export default withStyles(styles)(TextInputField);
