import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { PrimaryButtonRed, SecondaryButton } from "../../../common/Buttons";
import SimpleDialog from "../../../common/SimpleDialog";

const styles = {
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 40
  },
  text: {
    color: "#2A2B2C",
    fontSize: 15,
    lineHeight: "20px"
  }
};

class RemoveConnectionDialog extends React.Component {
  onSubmit = () => {
    const { company, connection } = this.props;

    this.props.ignoreConnection(
      connection.id,
      `You have removed your connection with ${company.name}.`
    );
    this.props.onClose();
  };

  render() {
    const { classes, company, title } = this.props;

    const _title = title || `Connect with ${company.name}`;

    return (
      <SimpleDialog title={_title} width={440}>
        <div className={classes.text}>{`Removing ${
          company.name
        } as a connection will prevent you from having access to their contact information. Are you sure you wish to remove ${
          company.name
        } as a connection?`}</div>

        <div className={classes.buttons}>
          <PrimaryButtonRed onClick={this.onSubmit}>Remove</PrimaryButtonRed>
          <SecondaryButton onClick={() => this.props.onClose()}>
            Cancel
          </SecondaryButton>
        </div>
      </SimpleDialog>
    );
  }
}

RemoveConnectionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  connection: PropTypes.object.isRequired,
  ignoreConnection: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string
};

RemoveConnectionDialog.defaultProps = {
  onClose: () => {}
};

export { RemoveConnectionDialog, styles };
export default withStyles(styles)(RemoveConnectionDialog);
