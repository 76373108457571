import React from "react";

import { Link } from "react-router-dom";

import CompanyPanel from "../../../common/CompanyPanel";
import TagLabel from "../../../common/TagLabel";

import CompanyInitiatives from "../../Initiatives";
import FinanceStats from "../../FinanceStats";
import ProfileTable from "../../ProfileTable";

import TeamProfileView from "../../Team/TeamProfileView";

import CompanyLocation from "../CompanyLocation";
import DocumentTopics from "../DocumentTopics";

export const renderCompanyDetails = props => {
  const { classes, company } = props;

  return (
    <div style={{ marginTop: 10 }}>
      <div className={classes.title}>About the Company</div>
      <div className={classes.paragraph}>{company.description}</div>
      <ProfileTable company={company} />
    </div>
  );
};

export const renderCompanyLocation = props => {
  const { classes, company, profile } = props;

  const editLink =
    company.id === profile.company ? (
      <Link to="/mycompany/edit#about">Edit</Link>
    ) : null;

  return (
    <div className={classes.sidebar}>
      <div style={{ marginTop: 10, display: "flex", alignItems: "center" }}>
        <div style={{ flexGrow: 1 }} />
        {editLink}
      </div>
      <CompanyLocation location={company.location} style={{ marginTop: 0 }} />
    </div>
  );
};

export const renderDescription = props => {
  const { classes, company } = props;
  const { mission_statement, program_type, markets } = company;

  return (
    <div className={classes.main}>
      <div className={classes.description}>{mission_statement}</div>

      <DocumentTopics topics={markets}>
        {program_type && (
          <TagLabel
            label={program_type}
            color="gold"
            classes={{ root: classes.tagRoot }}
          />
        )}
      </DocumentTopics>
    </div>
  );
};

export const renderInitiatives = props => {
  const { classes, company } = props;
  const { challenges, programs } = company;

  return (
    <div className={classes.panel}>
      <CompanyInitiatives challenges={challenges} programs={programs} />
    </div>
  );
};

export const renderFinance = props => {
  const { classes, company, profile } = props;

  const editLink =
    company.id === profile.company ? (
      <Link to="/mycompany/edit#funding">Edit</Link>
    ) : null;

  return (
    <div className="body">
      <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
        <div className={classes.title}>Funding</div>
        {company.is_actively_seeking_funding ? (
          <TagLabel
            color="green"
            label="Actively Seeking Funding"
            solid={true}
            style={{ marginLeft: 15 }}
          />
        ) : null}
        <div style={{ flexGrow: 1 }} />
        {editLink}
      </div>
      <FinanceStats
        classes={{ root: classes.financeRoot, panel: classes.financePanel }}
        company={company}
        rows={1}
      />
    </div>
  );
};

export const renderReferrer = props => {
  const { classes, company } = props;

  // placeholder to stop description from spilling into sidebar
  if (!company.referrer) return <div className={classes.sidebar} />;

  return (
    <div className={classes.sidebar}>
      <CompanyPanel
        company={company.referrer}
        classes={{
          label: classes.companyLabel,
          name: classes.companyName,
          root: classes.companyRoot
        }}
        prefix="Referred by "
        radius={30}
        subtitle={company.referrer && company.referrer.relationship}
      />
    </div>
  );
};

export const renderTeam = props => {
  const { company, profile } = props;

  const editLink =
    company.id === profile.company ? (
      <Link to="/mycompany/edit#team">Edit</Link>
    ) : null;

  const width = company.is_founder ? "xs" : props.width;
  const classes = company.is_founder ? { root: props.classes.panel } : {};

  return (
    <TeamProfileView
      classes={classes}
      managers={company.managers}
      editLink={editLink}
      width={width}
    />
  );
};
