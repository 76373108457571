import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import {
  COMPANY_TYPE_CORP,
  COMPANY_TYPE_FOUNDER,
  loadFounders,
  updateFounders
} from "../../Reducers/Companies";

import Banner from "../common/Banner";
import TabbedContainer from "../common/TabbedContainer";

import CompaniesListViewContainer from "../Company/ListView/CompaniesListViewContainer";

import ReferStartupButton from "./ReferStartupButton";
import ReferralsListContainer from "../Referrals/ReferralsListContainer";

const StartupsListCorpsView = props => {
  const { profile, referrals, ...otherProps } = props;

  const labels = referrals.length
    ? ["All Founder Companies", `My Referrals (${referrals.length})`]
    : ["All Founder Companies"];

  const referralsTab = referrals.length ? (
    <div
      style={{
        marginTop: 40,
        backgroundColor: "white",
        boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)"
      }}
    >
      <ReferralsListContainer showPagination={true} />
    </div>
  ) : null;

  return (
    <TabbedContainer
      fullWidth={true}
      labels={labels}
      size="large"
      actionButton={
        <ReferStartupButton>Refer a new Founder Team</ReferStartupButton>
      }
    >
      <CompaniesListViewContainer {...otherProps} />
      {referralsTab}
    </TabbedContainer>
  );
};

class StartupsListViewPage extends React.Component {
  static propTypes = {
    companyType: PropTypes.oneOf([COMPANY_TYPE_CORP, COMPANY_TYPE_FOUNDER])
      .isRequired,
    headers: PropTypes.array,
    limit: PropTypes.number.isRequired,
    profile: PropTypes.object
  };

  static defaultProps = {
    headers: [
      "Company",
      "Location",
      "Founded",
      "Stage",
      "Markets",
      "Referred by"
    ],
    limit: 20
  };

  render() {
    const { profile, scrollview, ...otherProps } = this.props;

    const key = `${this.props.companyType}_count`;
    const count = scrollview[key] || 0;

    return (
      <div>
        <Banner
          title={count ? `${count} Founder Teams` : "Founder Teams"}
          subtitle="Connect with Founder Teams and manage your referrals."
        />

        <div className="body" style={{ marginBottom: 60 }}>
          {profile.membership && profile.membership.startsWith("corp") ? (
            <StartupsListCorpsView {...this.props} />
          ) : (
            <CompaniesListViewContainer {...otherProps} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    companyType: COMPANY_TYPE_FOUNDER,
    profile: state.profile,
    referrals: state.referrals,
    scrollview: state.scrollview
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCompanies: options => dispatch(loadFounders(options)),
    updateCompanies: () => dispatch(updateFounders())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StartupsListViewPage);
