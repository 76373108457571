import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { logout } from "../Reducers/Profile/profileActions";
import SocialLinks from "./common/SocialLinks";

import colinkedLogo from "../assets/images/colinked-logo-footer.svg";

const styles = {
  root: {
    backgroundColor: "#4B4C4F",
    backgroundImage: `url( "${colinkedLogo}" )`,
    backgroundSize: "auto 225%",
    backgroundPosition: "right 35%",
    backgroundRepeat: "no-repeat",
    color: "white",
    // minHeight: 360,
    paddingTop: 60
  },
  columHeader: {
    color: "white",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 25
  },
  release: {
    color: "#2A2B2C",
    flexGrow: 1,
    textAlign: "right"
  },
  sitemap: {
    lineHeight: "30px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },

  socialIcon: {
    fill: "white"
  },
  socialRoot: {
    marginTop: 20
  },

  footerCol: {
    color: "white",
    fontSize: 15,
    width: 260
  },
  footerBar: {
    backgroundColor: "#2A2B2C",
    color: "white",
    fontSize: 13,
    paddingTop: 20,
    paddingBottom: 23
  },
  footerBarContent: {
    display: "flex",
    flexWrap: "wrap"
  },
  footerBarLink: {
    color: "white",
    fontWeight: 600,
    marginRight: 12,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  footerBarText: {
    fontWeight: 400
  }
};

const address = [
  "Whitespace Group",
  "20 St Thomas Street - 2nd Floor",
  "Runway East London Bridge",
  "London",
  "SE1 9RS",
  "United Kingdom"
];

const sitemapGuests = [
  ["Home", "/"],
  ["About Colinked", "/about"],
  ["For Corporates", "/forcorporates"],
  ["For Founders", "/forfounders"],
  ["For Investors", "/forinvestors"],
  ["Programs", "/programs"],
  ["FAQs", "/faqs"]
];

const sitemapCorporates = [
  ["Home", "/"],
  ["Browse Founder Teams", "/startups"],
  ["Browse Corporates", "/corporations"],
  ["Connections", "/connections"],
  ["Programs", "/programs"],
  ["Think Tank", "/challenges"],
  ["About", "/About"],
  ["FAQs", "/faqs"]
];

const userLinks = [
  ["Dashboard", "/dashboard"],
  ["Manage Company Profile", "/mycompany/edit"],
  ["User Profile", "/myprofile/edit"],
  ["Sign Out", "logout"]
];

const renderSiteMap = props => {
  const { classes, company, history } = props;

  const sitemap = company.is_corporate
    ? sitemapCorporates
    : company.is_founder
    ? sitemapCorporates.filter(site => site[0] !== "Think Tank")
    : sitemapGuests;

  return sitemap.map(line => {
    const [text, url] = line;

    return (
      <div
        key={text}
        className={classes.sitemap}
        onClick={() => history.push(url)}
      >
        {text}
      </div>
    );
  });
};

const renderUserLinks = (lines, props) => {
  const { classes, history, logout, company } = props;

  if (!company.id)
    return (
      <div className={classes.sitemap} onClick={() => history.push("/signin")}>
        Sign In
      </div>
    );

  return lines.map(line => {
    const [text, url] = line;

    const action =
      url === "logout"
        ? () => {
            history.push("/");
            logout();
          }
        : () => history.push(url);

    return (
      <div key={text} className={classes.sitemap} onClick={action}>
        {text}
      </div>
    );
  });
};

const Footer = props => {
  const { classes, history } = props;

  return (
    <div id="footer" className={classes.root}>
      <footer
        className="body"
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        <div className={classes.footerCol} style={{}}>
          <div className={classes.columHeader}>Get in touch</div>

          <strong>Colinked</strong>

          {address.map((line, idx) => (
            <div key={idx}>{line}</div>
          ))}

          <SocialLinks
            classes={{
              root: classes.socialRoot,
              icon: classes.socialIcon
            }}
            email="hello@colinked.io"
            linkedin="https://www.linkedin.com/company/whitedotspace/"
            twitter="https://twitter.com/co_linked"
            showEmail={true}
          />
        </div>

        <div className={classes.footerCol}>
          <div className={classes.columHeader}>Discover and Apply</div>

          {renderSiteMap(props)}
        </div>

        <div className={classes.footerCol}>
          <div className={classes.columHeader}>Your Account</div>

          {renderUserLinks(userLinks, props)}
        </div>
      </footer>

      <div className={classes.footerBar}>
        <div className={`body ${classes.footerBarContent}`}>
          <div
            className={classes.footerBarLink}
            alt="terms of use"
            onClick={() => history.push("/terms_of_use")}
          >
            Terms of Use.
          </div>
          <div
            className={classes.footerBarLink}
            alt="acceptable use policy"
            onClick={() => history.push("/acceptable_use_policy")}
          >
            Acceptable Use Policy.
          </div>
          <div
            className={classes.footerBarLink}
            alt="terms of use"
            onClick={() => history.push("/privacy_policy")}
          >
            Privacy Policy.
          </div>
          <div className={classes.footerBarText}>
            All rights reserved.{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              Copyright Whitespace Ventures 2019.
            </span>
          </div>
          <div className={classes.release}>release/2.9</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    company: state.company
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withWidth()(withStyles(styles)(Footer))));
