import React from "react";
import { PropTypes } from "prop-types";

import * as renderers from "./renderers";

import styles from "./styles";
import { withStyles } from "material-ui";

import CompanyEthos from "../../CompanyEthos";
import CompanyPitch from "../../CompanyPitch";

const renderPitchOrEthos = props => {
  const { classes, company } = props;

  const pitchOrEthos = company.is_corporate ? (
    <CompanyEthos {...props} classes={{ root: classes.main }} />
  ) : (
    <CompanyPitch {...props} classes={{ root: classes.main }} />
  );

  return pitchOrEthos;
};

const renderTeamSection = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      <div
        className={classes.main}
        style={{
          backgroundColor: "white",
          boxShadow: "0 5px 10px 0 rgba(0,0,0,0.05)"
        }}
      >
        {renderers.renderTeam(props)}
      </div>

      {/* placeholder to stop team from spilling into sidebar*/}
      {<div className={classes.sidebar} />}
    </React.Fragment>
  );
};

export const DesktopLayout = props => {
  const { classes, company } = props;

  return (
    <div className={classes.root}>
      <div className={classes.containerTop}>
        <div className={classes.row}>
          {renderers.renderDescription(props)}
          {renderers.renderReferrer(props)}
        </div>

        <div
          id="pitch_or_ethos"
          className={classes.row}
          style={{ marginTop: 30 }}
        >
          {renderPitchOrEthos(props)}
          <div className={classes.sidebar}>
            {company.is_corporate
              ? renderers.renderInitiatives(props)
              : renderers.renderTeam(props)}
          </div>
        </div>
      </div>

      <div id="company_details" className={classes.containerMiddle}>
        <div className={classes.row}>
          <div className={classes.main}>
            {renderers.renderCompanyDetails(props)}
          </div>
          {renderers.renderCompanyLocation(props)}
        </div>
      </div>

      <div id="finance_or_team" className={classes.containerBottom}>
        <div className={classes.row}>
          {company.is_corporate && renderTeamSection(props)}
          {company.is_founder && renderers.renderFinance(props)}
        </div>
      </div>
    </div>
  );
};

DesktopLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

export default withStyles(styles)(DesktopLayout);
