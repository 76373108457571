import React from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core/styles";

import CustomTableCell from "../../common/StyledTable/CustomTableCell";

import {
  ProgramsListPlaceholder,
  ThinkTanksListPlaceholder
} from "./DocumentsListPlaceholder";
import * as renderers from "./renderers";

import styles from "./styles";

const renderRows = props => {
  const { classes, doctype, documents } = props;

  return documents.map(document => {
    return (
      <TableRow className={classes.row} key={document.id}>
        {doctype === "program"
          ? renderers.renderImageCell(document, classes)
          : null}

        <CustomTableCell>
          {renderers.renderDetails(document, classes)}
        </CustomTableCell>

        <CustomTableCell>{renderers.formatType(document)}</CustomTableCell>

        {doctype !== "program" ? (
          <CustomTableCell>
            {renderers.renderOrganiser(document, classes)}
          </CustomTableCell>
        ) : null}

        <CustomTableCell>
          {document.location ? document.location.city : "Global"}
        </CustomTableCell>

        <CustomTableCell style={{ width: 160 }}>
          {renderers.renderDateRange(document, classes)}
        </CustomTableCell>

        <CustomTableCell style={{ width: 240 }}>
          {renderers.renderTopics(document.topics, classes)}
        </CustomTableCell>
      </TableRow>
    );
  });
};

const getPlaceholder = doctype => {
  if (doctype === "program") return ProgramsListPlaceholder;
  if (doctype === "challenge") return ThinkTanksListPlaceholder;

  return null;
};

const DocumentsListViewTable = props => {
  const { classes, doctype, documents, error, loading, style } = props;

  const EmptyState = props.EmptyState || getPlaceholder(doctype);

  const headers =
    doctype === "program"
      ? ["", "Program Name", "Type", "Location", "Date and Time", "Markets"]
      : [
          "Think Tank Title",
          "Type",
          "Organiser",
          "Location",
          "Date and Time",
          "Markets"
        ];

  if (!documents.length && EmptyState) {
    return <EmptyState />;
  }

  return (
    <div className={classes.root} style={style}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <CustomTableCell className={classes.td} key={header}>
                {header}
              </CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tablebody}>
          {renderRows(props)}
          {error && renderers.renderErrorRow(error, headers.length, classes)}
          {loading && renderers.renderLoadingRow(headers.length, classes)}
        </TableBody>
      </Table>
    </div>
  );
};

DocumentsListViewTable.propTypes = {
  classes: PropTypes.object.isRequired,
  EmptyState: PropTypes.func,
  documents: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  style: PropTypes.object.isRequired
};

DocumentsListViewTable.defaultProps = {
  headers: ["", "Program Name", "Type", "Location", "Date and Time", "Markets"],
  style: {}
};

export default withStyles(styles)(DocumentsListViewTable);
