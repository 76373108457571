import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxBlank from "@material-ui/icons/CheckBoxOutlineBlank";

import EmptyTableImage from "../../assets/program-app-empty-state.svg";

import StyledTable from "../common/StyledTable";

import ApplicationsListRow from "./ApplicationsListRowContainer";
import ApplicationsManageButtons from "./ApplicationsManageButtonsContainer";

const styles = {
  root: {},

  buttons: {
    backgroundColor: "white",
    padding: 30,
    paddingBottom: 15
  },

  icon: {
    fill: "white",
    fontSize: 20
  },
  table: {
    backgroundColor: "white",
    padding: 30
  },

  emptyRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    backgroundColor: "white",
    height: 400
  },
  emptyImage: {
    width: 160,
    height: 160
  },
  emptyTitle: {
    color: "#AAADB6",
    fontSize: 18,
    fontWeight: 600,
    margin: 20
  },
  emptyComment: {
    color: "#2A2B2C",
    fontSize: 15
  }
};

const renderCheckBox = (selected, props) => {
  const { classes, applications, deselectAll, selectAll } = props;

  if (selected.length === applications.length) {
    return (
      <CheckBox
        className={classes.icon}
        onClick={() => deselectAll(applications)}
      />
    );
  }

  return (
    <CheckBoxBlank
      className={classes.icon}
      onClick={() => selectAll(applications)}
    />
  );
};

const ApplicationsListView = props => {
  const { applications, classes, headers, status } = props;

  const selected = applications.filter(app => app.selected);
  const checkbox = renderCheckBox(selected, props);

  return applications.length ? (
    <div className={classes.root}>
      <ApplicationsManageButtons
        classes={{ root: classes.buttons }}
        selected={selected}
        status={status}
      />
      <StyledTable
        headers={[checkbox, ...headers.slice(1)]}
        data={applications}
        RowComponent={ApplicationsListRow}
      />
    </div>
  ) : (
    <div className={classes.emptyRoot}>
      <img className={classes.emptyImage} src={EmptyTableImage} alt="" />
      <div className={classes.emptyTitle}>No applications yet.</div>
      <div className={classes.emptyComment}>
        “Patience you must have my young padawan” - Yoda
      </div>
    </div>
  );
};

ApplicationsListView.propTypes = {
  applications: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  deselectAll: PropTypes.func.isRequired,
  headers: PropTypes.array.isRequired,
  selectAll: PropTypes.func.isRequired,
  status: PropTypes.oneOf(["pending", "approved", "declined"]).isRequired
};

ApplicationsListView.defaultProps = {
  applications: [],
  deselectAll: ids => console.log(ids, "deselectAll"),
  headers: [
    <CheckBoxBlank style={{ fill: "white", fontSize: 20 }} />,
    "",
    "Company Name",
    "Type",
    "Application Type",
    "Application Date"
    // "Status"
  ],
  selectAll: ids => console.log(ids, "selectAll")
};

export { ApplicationsListView, styles };
export default withStyles(styles)(ApplicationsListView);
