import { ADD_FLASH_NOTE, REMOVE_FLASH_NOTE } from "./FlashNotesActions";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_FLASH_NOTE:
      return [...state, action.payload];

    case REMOVE_FLASH_NOTE:
      return state.filter(note => note.id !== action.payload);

    default:
      return state;
  }
};
