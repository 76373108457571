import React from "react";
import { PropTypes } from "prop-types";

import RichTextEditor from "react-rte";

import { throttle } from "underscore";

import { withStyles } from "@material-ui/core/styles";

import Controls from "./Controls";
import { styles as baseStyles } from "./BaseFrame";

import "./RichTextFrame.css";

const styles = {
  ...baseStyles,
  content: {
    height: 40,
    lineHeight: "40px",
    borderRadius: 3,
    border: "none",
    backgroundColor: "#F2F3F4",
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    paddingLeft: 15,
    paddingRight: 15
  },
  rteRoot: {
    fontFamily: "sans-serif"
  },
  rteToolbar: {
    borderBottom: "none"
  }
};

function myBlockStyleFn(contentBlock) {
  const type = contentBlock.getType();
  if (type === "blockquote") {
    return "fuse-rte-blockquote";
  }
}

class Heading extends React.Component {
  static propTypes = {
    content: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    toolbarConfig: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
  };

  static defaultProps = {
    placeholder: "Please provide some content",
    toolbarConfig: {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS"
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" }
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
        { label: "Blockquote", style: "blockquote" }
      ]
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.content
        ? RichTextEditor.createValueFromString(props.content, "html")
        : RichTextEditor.createEmptyValue()
    };

    this.onChange = throttle(this._onChange, 400).bind(this);
  }

  _onChange = () => {
    const { value } = this.state;

    this.props.onChange({ content: value.toString("html") });
  };

  onEditorChange = value => {
    this.setState({ value }, this.onChange);
  };

  renderControls = () => {
    const { deleteFrame, moveDown, moveUp } = this.props;

    const controlProps = {
      deleteFrame,
      moveDown,
      moveUp
    };

    return <Controls {...controlProps} />;
  };

  render() {
    const { classes, placeholder, toolbarConfig } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        {this.renderControls()}

        <div className={classes.type}>Text Block</div>

        <RichTextEditor
          blockStyleFn={myBlockStyleFn}
          className={classes.rteRoot}
          onChange={this.onEditorChange}
          placeholder={placeholder}
          toolbarClassName={classes.rteToolbar}
          toolbarConfig={toolbarConfig}
          value={value}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Heading);
