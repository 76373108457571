export const CLEAR_VALIDATION_ERROR = "CLEAR_VALIDATION_ERROR";
export const CLEAR_VALIDATION_ERRORS = "CLEAR_VALIDATION_ERRORS";
export const SET_VALIDATION_ERROR = "SET_VALIDATION_ERROR";
export const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS";

export const clearValidationError = id => {
  return {
    type: CLEAR_VALIDATION_ERROR,
    payload: id
  };
};

export const clearValidationErrors = () => {
  return {
    type: CLEAR_VALIDATION_ERRORS
  };
};

export const setValidationError = (id, msg) => {
  return {
    type: SET_VALIDATION_ERROR,
    payload: { [id]: msg }
  };
};

export const setValidationErrors = errors => {
  return {
    type: SET_VALIDATION_ERRORS,
    payload: errors
  };
};
