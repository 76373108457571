import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { deleteAccount } from "../../../Reducers/Profile";
import styles from "./styles";

import DeleteAccountForm from "./DeleteAccountForm";

const mapStateToProps = state => ({ profile: state.profile });
const mapDispatchToProps = dispatch => {
  return {
    deleteAccount: password => dispatch(deleteAccount(password))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DeleteAccountForm));
