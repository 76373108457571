import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import FileUpload from "../ProfileBuilder/FileUpload";
import TextInputField from "../TextInputField";

import ApplicationSelectForm from "./ApplicationSelectForm";

const styles = {
  root: {
    //marginTop: 40,
    width: "100%",
    //overflowX: "auto",
    marginBottom: 40
  },
  empty: {
    borderTop: "1px solid #E6E7E9",
    margin: "30px -30px 0",
    padding: 30,
    paddingBottom: 10,
    textAlign: "center"
  },
  frameRoot: {
    marginTop: 40,
    marginBottom: 0,
    "&:first-child": {
      marginTop: 20
    }
  },
  label: {
    color: "#2A2B2C",
    fontSize: 14,
    marginBottom: 6
  },

  // FileUpload overrides
  clickFrame: {
    marginTop: -20
  },
  fileUploadRoot: {
    marginTop: 0,
    marginBottom: 0
  },
  fileUploadRootError: {
    border: "1px solid rgb(219, 110, 83)",
    marginTop: 0,
    marginBottom: 0
  }
};

const stringToErrorObject = s => {
  try {
    const obj = JSON.parse(s);
    return obj;
  } catch (error) {
    return { error };
  }
};

class ApplicationQestionsForm extends React.Component {
  static propTypes = {
    questions: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    validationErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  };

  static defaultProps = {
    validationErrors: null
  };

  constructor(props) {
    super(props);

    this.state = {
      questions: props.questions.map(question => ({
        ...question
      }))
    };
  }

  getValidationErrors = () => {
    const { questions, validationErrors } = this.props;

    if (validationErrors) {
      return validationErrors
        .split("\n")
        .map(stringToErrorObject)
        .map(obj => obj.response || "");
    }

    return questions.map(q => "");
  };

  onChange = (index, value) => {
    const questions = this.state.questions.map((question, idx) =>
      idx === index ? { ...question, response: value } : question
    );

    this.setState({ questions }, () => this.props.onChange(questions));
  };

  renderFileUpload = (question, index, error, label) => {
    const { classes } = this.props;
    const textStyle = error ? { color: "#DB6E53" } : {};

    return (
      <div key={index} className={classes.frameRoot}>
        <div className={classes.label} style={textStyle}>
          {label}
        </div>
        <FileUpload
          classes={{
            root: error ? classes.fileUploadRootError : classes.fileUploadRoot,
            clickFrame: classes.clickFrame
          }}
          allowedFileTypes={question.fileTypes}
          content={question.response || ""}
          onChange={value => this.onChange(index, value.content)}
          showControls={false}
          showDescriptionInput={false}
          value={question.response}
          helperText={error}
        />
        {error ? (
          <div className="helperText" style={textStyle}>
            {error}
          </div>
        ) : null}
      </div>
    );
  };

  renderQuestion = (question, index) => {
    const { classes } = this.props;
    const { is_optional, label: _label, type } = question;

    const label = is_optional ? _label : `${_label} (required)`;
    const errors = this.getValidationErrors();

    if (type === "file_upload") {
      return this.renderFileUpload(question, index, errors[index], label);
    }

    if (type === "text_input") {
      return (
        <TextInputField
          key={index}
          classes={{ root: classes.frameRoot }}
          label={label}
          id={`text_input_${index}_new`}
          onChange={value => this.onChange(index, value)}
          rows={8}
          value={question.response}
          width="100%"
          error={Boolean(errors[index])}
          helperText={errors[index]}
        />
      );
    }

    return (
      <ApplicationSelectForm
        key={index}
        classes={{ root: classes.frameRoot }}
        {...question}
        onChange={value => this.onChange(index, value)}
        error={Boolean(errors[index])}
        helperText={errors[index]}
      />
    );
  };

  render() {
    const { classes, questions } = this.props;

    if (!questions.length) {
      return (
        <div className={classes.empty}>
          The organiser does not request any information from participants.
        </div>
      );
    }

    return (
      <div className={classes.root}>{questions.map(this.renderQuestion)}</div>
    );
  }
}

export { ApplicationQestionsForm, styles };
export default withStyles(styles)(ApplicationQestionsForm);
