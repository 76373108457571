import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import errorDude from "../../../assets/error-dude.svg";
import logo_linkedin from "../../../assets/linkedin.svg";
import logo_twitter from "../../../assets/twitter.svg";

import LocalLink from "../LocalLink";

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //marginTop: 40,
    marginBottom: 120,
    paddingTop: 40
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  dot: {
    color: "#398DD3",
    marginLeft: 15,
    marginRight: 15
  },

  link: {
    color: "#398DD3",
    fontWeight: 300,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  links: {
    marginTop: 20
  },
  linksSocial: {
    marginTop: 40
  },
  social: {
    marginLeft: 15,
    "&:first-child": {
      marginLeft: 0
    }
  },
  socialIcon: {
    width: 40,
    height: 40
  },

  // image sizes
  large: {
    width: 300,
    height: 300
  },
  medium: {
    width: 200,
    height: 200
  },
  small: {
    width: 100,
    height: 100
  }
};

const ErrorGraphic = props => {
  const { children, classes, resource, size, ...other } = props;

  return (
    <div className={classes.root} {...other}>
      <div className={classes.column}>
        <img
          className={classes[size]}
          src={errorDude}
          alt="error illustration"
        />

        {children}

        <div className={classes.links}>
          <LocalLink className={classes.link} label="Visit homepage" link="/" />
          <span className={classes.dot}>•</span>
          <a className={classes.link} href="mailto:support@colinked.io">
            Email us support@colinked.io
          </a>
        </div>
        <div className={classes.linksSocial}>
          <a
            href="https://twitter.com/whitedotspace"
            rel="noopener noreferrer"
            target="_blank"
            className={classes.social}
          >
            <img
              style={{ backgroundColor: "#0077B5" }}
              src={logo_twitter}
              className={classes.socialIcon}
              alt="Twitter logo"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/whitedotspace/"
            rel="noopener noreferrer"
            target="_blank"
            className={classes.social}
          >
            <img
              style={{ backgroundColor: "#1DA1F3" }}
              src={logo_linkedin}
              className={classes.socialIcon}
              alt="LinkedIn logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

ErrorGraphic.propTypes = {
  classes: PropTypes.object.isRequired,
  resource: PropTypes.string,
  size: PropTypes.oneOf(["large", "medium", "small"])
};

ErrorGraphic.defaultProps = {
  size: "medium"
};

export default withStyles(styles)(ErrorGraphic);
