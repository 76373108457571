import { connect } from "react-redux";

import { createFlashNote } from "../../Reducers/FlashNotes";
import { setValidationErrors } from "../../Reducers/ValidationErrors";

import * as api from "./api";

import DocumentDetailsView from "../Document/DocumentDetailsView";
import FormBuilderContainer from "../FormBuilder/FormBuilderContainer";
import thinkTankFormSections from "./thinkTankBuilderSections";

const createNewDocument = profile => () => {
  return api.createNewChallenge().then(challenge => ({
    ...challenge,
    company: profile.company
  }));
};

const mapStateToProps = state => ({
  PreviewComponent: DocumentDetailsView,
  exitRedirect: "/challenges",
  pages: thinkTankFormSections,
  createNewDocument: createNewDocument(state.profile),
  deleteDocument: api.deleteChallenge,
  duplicateDocument: api.duplicateChallenge,
  loadDocument: api.loadChallenge,
  updateDocument: api.updateChallenge,
  validationErrors: state.validationErrors
});

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    },
    setValidationErrors: errors => dispatch(setValidationErrors(errors))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormBuilderContainer);
