import queryString from "query-string";

import { COMPANY_TYPE_CORP, COMPANY_TYPE_FOUNDER } from "./constants";
import * as actions from "./CompaniesActionTypes";
import * as api from "./api";

import { createFlashNote } from "../FlashNotes";
import { setScrollviewState } from "../ScrollView";

/**
 * _loadCompanies: factory for loadFounders or loadCorporates functions
 *
 * @param {string} companyType
 * @returns factory function
 */
export const _loadCompanies = companyType => (options = { limit: 20 }) => {
  const _apiLoadCompanies =
    companyType === COMPANY_TYPE_CORP ? api.loadCorporates : api.loadFounders;

  return dispatch => {
    dispatch(actions.setCompaniesListLoading(true));

    _apiLoadCompanies(options).then(json => {
      const { error, results, next, count } = json;

      if (error) {
        dispatch(createFlashNote(error.message, "error"));
        dispatch(actions.setCompaniesListLoading(false));
      } else {
        dispatch(actions.updateCompaniesList(results));
        if (count) {
          dispatch(setScrollviewState(`${companyType}_count`, count));
        }
        if (next) {
          dispatch(loadNextCompanies(_apiLoadCompanies, next));
        }
      }
    });
  };
};

/**
 * loadCorporates: load companies from /api/companies/corporations
 *
 * loading steps:
 *   1) set loading state
 *   2) get list of corporates from api
 *   3) update list with new data or reset loading state
 *   4) if return value has 'next' attribute, call loadNextCompanies
 *
 * @param {string} companyType
 * @returns redux thunk function
 */
export const loadCorporates = _loadCompanies(COMPANY_TYPE_CORP);

/**
 * loadFounders: load companies from /api/companies/founders
 *
 * loading steps:
 *   1) set loading state
 *   2) get list of founder companies from api
 *   3) update list with new data or reset loading state
 *   4) if return value has 'next' attribute, call loadNextCompanies
 *
 * @param {object} options
 * @returns redux thunk function
 */
export const loadFounders = _loadCompanies(COMPANY_TYPE_FOUNDER);

/**
 * loadCompany: load more details for company
 *
 * loading steps:
 *   1) set loading state on company
 *   2) load details
 *   3) update company with received details or just reset loading state
 *
 * @param {object} company
 * @returns redux thunk function
 */
export const loadCompanyDetails = company => {
  const { is_corporate, id } = company;

  const _apiLoadCompany = is_corporate ? api.loadCorporate : api.loadFounder;

  return dispatch => {
    dispatch(actions.updateCompany({ id, loading: true }));

    _apiLoadCompany(id).then(json => {
      const { error } = json;

      if (error) {
        dispatch(createFlashNote(error.message, "error"));
        dispatch(actions.updateCompany({ id, loading: false }));
      } else {
        dispatch(
          actions.updateCompany({ ...json, id, _details: true, loading: false })
        );
      }
    });
  };
};

/**
 * loadNextCompanies:
 *
 * continues loading of companies in chunks in the background
 * until all corps or founders have been fetched from server
 *
 * @param {func} apiLoadCompanies
 * @param {string} next
 * @param {number} [limit=50]
 * @returns redux thunk function
 */
const loadNextCompanies = (apiLoadCompanies, next, limit = 50) => {
  const params = queryString.parse(next.split("?")[1]);
  const { limit: _, offset, ...other } = params;
  const options = { ...other, limit, offset };

  return dispatch => {
    dispatch(actions.setCompaniesListLoading(true));

    apiLoadCompanies(options).then(json => {
      const { error, results, next } = json;

      if (error) {
        dispatch(createFlashNote(error.message, "error"));
        dispatch(actions.setCompaniesListLoading(false));
      } else {
        dispatch(actions.updateCompaniesList(results));
        if (next) {
          dispatch(loadNextCompanies(apiLoadCompanies, next));
        }
      }
    });
  };
};

/**
 * updateCompany: add new attributes to company or add new company object
 *
 * matches company via company.id
 *
 * @returns redux thunk function
 */
export const updateCompany = company => {
  return dispatch => {
    if (!company.id) {
      dispatch(createFlashNote("No company id provided", "error"));

      return;
    }
    dispatch(actions.updateCompany(company));
  };
};

/**
 * TODO: updateCorporates: check /api/companies/corporates for updates
 *
 * @returns redux thunk function
 */
export const updateCorporates = () => {
  return dispatch => {
    // console.log("TODO: updateCorporates");
  };
};

/**
 * TODO: updateFounders: check /api/companies/founders for updates
 *
 * @returns redux thunk function
 */
export const updateFounders = () => {
  return dispatch => {
    // console.log("TODO: updateFounders");
  };
};
