import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

import {
  COMPANY_TYPE_CORP,
  COMPANY_TYPE_FOUNDER
} from "../../../Reducers/Companies";

import CompanyPanel from "../../common/CompanyPanel";
import ErrorPanel from "../../common/Errors/ErrorPanel";
import LoadingPanel from "../../common/LoadingPanel";

import Icons from "../../Icons";

import CustomTableCell from "../../common/StyledTable/CustomTableCell";
import tableStyles from "../../common/StyledTable/styles";
import { renderTopics } from "../../common/StyledTable/utils";

const styles = {
  ...tableStyles,

  root: {
    ...tableStyles.root,
    marginTop: 40,
    backgroundColor: "white",
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)"
  },
  company: {
    //width: "280px"
  },
  link: {
    display: "block",
    fontWeight: 600,
    marginTop: 5,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underLine"
    }
  },
  referrerLabel: {
    fontSize: 13,
    fontWeight: 400
  }
};

const formatCompany = (company, companyType) => {
  const link = getCompanyLink(company, companyType);

  return (
    <CompanyPanel
      company={company}
      link={link}
      subtitle={company.mission_statement}
    />
  );
};

const formatLocation = (location, countries) => {
  if (!location) return null;

  const country = countries.find(c => c.id === location.country) || {
    code: ""
  };

  return [location.city, country.code].filter(s => s).join(", ");
};

const getCompanyLink = (company, companyType) => {
  return companyType === COMPANY_TYPE_FOUNDER
    ? `/startup/${company.id}`
    : `/corporate/${company.id}`;
};

const getBodyCell = (header, company, props) => {
  const { classes, companyType, config } = props;

  if (header === "Company") {
    return (
      <CustomTableCell key={header} className={classes.company}>
        {formatCompany(company, companyType)}
      </CustomTableCell>
    );
  }

  if (header === "Status" && company[header] === "Pending") {
    return (
      <CustomTableCell
        //style={{ width: "80px" }}
        key={header}
      >
        <div>{company[header]}</div>
        <Typography
          variant="caption"
          component="p"
          onClick={() => console.log("TODO: send invitation dialog")}
        >
          Resend invitation
        </Typography>
      </CustomTableCell>
    );
  }

  if (header === "Founded") {
    return (
      <CustomTableCell key={header}>{company.year_founded}</CustomTableCell>
    );
  }

  if (header === "Founder Contact") {
    return (
      <CustomTableCell key={header}>
        <div
          style={{
            position: "relative",
            top: "-3px"
          }}
          onClick={() => console.log("TODO: show message dialog")}
        >
          {company[header]}
          <Icons.Envelope
            style={{
              fontSize: 16,
              position: "relative",
              top: "4px",
              left: "3px"
            }}
          />
        </div>
      </CustomTableCell>
    );
  }

  if (header === "Location") {
    return (
      <CustomTableCell key={header}>
        {formatLocation(company.location, props.countries)}
      </CustomTableCell>
    );
  }

  if (header === "Markets") {
    return (
      <CustomTableCell key={header}>
        {renderTopics(company.markets)}
      </CustomTableCell>
    );
  }

  if (header === "Referred by") {
    const referrer = company.referrer;

    if (!referrer) {
      return <CustomTableCell key={header}>{""}</CustomTableCell>;
    }
    return (
      <CustomTableCell key={header}>
        <CompanyPanel
          company={referrer}
          size={40}
          classes={{ name: classes.referrerLabel }}
        />
      </CustomTableCell>
    );
  }

  if (header === "Stage") {
    const { stage } = company;
    const _stage = config.company_stage[stage] || stage;

    return <CustomTableCell key={header}>{_stage}</CustomTableCell>;
  }

  if (header === "Total Raised") {
    const {
      capital_to_date: _capital,
      capital_to_date_currency: currency
    } = company;
    const capital = config.companies__revenue_ranges[_capital] || _capital;

    return (
      <CustomTableCell key={header}>
        {currency} {capital}
      </CustomTableCell>
    );
  }

  const value = company[header] || company[header.toLowerCase()];
  return (
    <CustomTableCell key={header}>
      <span>{value}</span>
    </CustomTableCell>
  );
};

const renderErrorRow = (error, columns, classes) => {
  return (
    <TableRow className={classes.row} style={{ backgroundColor: "white" }}>
      <CustomTableCell colSpan={columns}>
        <ErrorPanel error={error} />
      </CustomTableCell>
    </TableRow>
  );
};

const renderLoadingRow = (columns, classes) => {
  return (
    <TableRow className={classes.row} style={{ backgroundColor: "white" }}>
      <CustomTableCell colSpan={columns}>
        <LoadingPanel />
      </CustomTableCell>
    </TableRow>
  );
};

const renderRows = props => {
  const { classes, companies, headers } = props;

  return companies.map(company => {
    return (
      <TableRow className={classes.row} key={company.id}>
        {headers.map(header => getBodyCell(header, company, props))}
      </TableRow>
    );
  });
};

const CompaniesListView = props => {
  const { classes, error, headers, loading } = props;

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <CustomTableCell key={index}>{header}</CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tablebody}>
          {renderRows(props)}
          {error && renderErrorRow(error, headers.length, classes)}
          {loading && renderLoadingRow(headers.length, classes)}
        </TableBody>
      </Table>
    </div>
  );
};

CompaniesListView.propTypes = {
  classes: PropTypes.object.isRequired,
  companyType: PropTypes.oneOf([COMPANY_TYPE_CORP, COMPANY_TYPE_FOUNDER])
    .isRequired,
  companies: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired
};

CompaniesListView.defaultProps = {
  headers: ["Company", "Location", "Founded", "Stage", "Markets", "Referred by"]
};

const mapStateToProps = state => ({
  config: state.config,
  countries: state.countries
});
export default connect(mapStateToProps)(withStyles(styles)(CompaniesListView));
