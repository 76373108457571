import { PropTypes } from "prop-types";

const _document = {
  title: PropTypes.string,
  description: PropTypes.string,
  sections: PropTypes.array,
  topics: PropTypes.array,

  public: PropTypes.bool,
  anonymous: PropTypes.bool,
  corporates_only: PropTypes.bool,
  blacklist: PropTypes.array,

  deadline: PropTypes.string,
  state: PropTypes.string,
  firstPublishedDate: PropTypes.string,

  type: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  details: PropTypes.string,
  other: PropTypes.string
};

export const document = PropTypes.shape({
  ..._document
}).isRequired;

export const documentEdit = PropTypes.shape({
  ..._document,
  country: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
}).isRequired;
