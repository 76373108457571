import React from "react";

import { withStyles } from "@material-ui/core/styles";

import PopularTag from "../PopularTag";
import DocumentProfile from "../../Document/DocumentDetailsView/DocumentProfile";
import DocumentTopics from "../../Document/DocumentDetailsView/DocumentTopics";
import EnquirePanel from "./EnquirePanel";
import Testimonials from "../../common/Testimonials";

const stylesLarge = {
  root: {},
  panel: {
    backgroundColor: "white",
    boxShadow: "0 5px 10px 0 rgba(55,60,68,0.05)"
  },
  disclaimer: {
    fontSize: 15,
    lineHeight: "18px",
    fontStyle: "italic"
  },

  // layout structure: row, main, sidebar
  row: {
    marginTop: 30,
    display: "flex",
    width: 1260,
    "&:first-child": {
      marginTop: 0
    }
  },
  main: {
    flexGrow: 1,
    width: 810
  },
  sidebar: {
    width: 420,
    marginLeft: 30,
    flexGrow: 0,
    height: "auto"
  }
};

const stylesMedium = {
  ...stylesLarge,
  main: {
    ...stylesLarge.main,
    width: 610,
    minWidth: 610
  },
  row: {
    ...stylesLarge.row,
    width: 940,
    minWidth: 940
  },
  sidebar: {
    ...stylesLarge.sidebar,
    width: 300,
    minWidth: 300
  }
};

const stylesMobile = {
  ...stylesLarge,
  root: {
    ...stylesLarge.root,
    maxWidth: 610,
    width: "90%"
  },
  main: {},
  row: {
    marginTop: 30,
    "&:first-child": {
      marginTop: 0
    }
  }
};

const renderTopics = props => {
  const { classes, document } = props;
  const { tags, is_popular } = document;

  return (
    <div className={classes.main}>
      <DocumentTopics topics={tags}>
        {is_popular && <PopularTag style={{ marginTop: 5 }} />}
      </DocumentTopics>
    </div>
  );
};

const renderEnquire = props => {
  const { classes, document } = props;

  return (
    <div className={classes.panel}>
      <EnquirePanel document={document} />
    </div>
  );
};

const renderTestimonials = props => {
  const { classes, document } = props;
  const { testimonials } = document;

  if (!testimonials.length) return null;

  return (
    <div className={classes.row}>
      <div className={`${classes.main} ${classes.panel}`}>
        <Testimonials testimonials={document.testimonials} />
      </div>

      <div className={classes.sidebar} />
    </div>
  );
};

export const LayoutDesktop = props => {
  const { classes, document } = props;

  return (
    <div id="ServiceDetailsViewDesktop" className={classes.root}>
      <div className={classes.row}>
        {renderTopics(props)}
        <div className={classes.sidebar} />
      </div>

      <div className={classes.row}>
        <div className={`${classes.main} ${classes.panel}`}>
          <DocumentProfile profile={document.profile} />
        </div>

        <div className={classes.sidebar}>{renderEnquire(props)}</div>
      </div>

      {renderTestimonials(props)}
    </div>
  );
};
export const LayoutDesktopLarge = withStyles(stylesLarge)(LayoutDesktop);
export const LayoutDesktopMedium = withStyles(stylesMedium)(LayoutDesktop);

export const _LayoutMobile = props => {
  const { classes, document } = props;

  return (
    <div id="layoutSmall" className={classes.root}>
      <div className={classes.row}>{renderTopics(props)}</div>

      <div className={classes.row}>
        <div className={`${classes.main} ${classes.panel}`}>
          <DocumentProfile profile={document.profile} />
        </div>
      </div>

      {document.testimonials.length ? (
        <div className={classes.row}>
          <div className={`${classes.main} ${classes.panel}`}>
            <Testimonials testimonials={document.testimonials} />
          </div>
        </div>
      ) : null}

      <div className={classes.row}>
        <div className={classes.main}>{renderEnquire(props)}</div>
      </div>
    </div>
  );
};
export const LayoutMobile = withStyles(stylesMobile)(_LayoutMobile);
