import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    backgroundColor: "white",
    height: 80
  },
  container: {
    display: "flex",
    alignItems: "center",
    height: 80,
    margin: "0 auto"
  }
};

const Menu = props => {
  const { children, classes, menuId } = props;

  return (
    <div className={classes.root}>
      <div className={`body ${classes.container}`} id={menuId}>
        {children}
      </div>
    </div>
  );
};

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  menuId: PropTypes.string
};

export { Menu, styles };
export default withStyles(styles)(Menu);
