import {
  SET_NOTIFICATION_HIDDEN,
  SET_NOTIFICATION_PENDING,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_LOADING
} from "./NotificationsActions";

const setLoadingState = state => {
  const newState = [...state];
  newState.loading = true;

  return newState;
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return [...action.payload];

    case SET_NOTIFICATIONS_LOADING:
      return setLoadingState(state);

    case SET_NOTIFICATION_HIDDEN:
      return state.filter(note => note.id !== action.payload);

    case SET_NOTIFICATION_PENDING:
      return state.map(note =>
        note.id === action.payload.id ? { ...note, ...action.payload } : note
      );

    default:
      return state;
  }
};
