import React from "react";

import PageTemplate from "./PageTemplate";

import ForCorporates from "../components/ForCorporates";

const ForCorporatesPage = props => {
  return (
    <PageTemplate>
      <ForCorporates />
    </PageTemplate>
  );
};

export default ForCorporatesPage;
