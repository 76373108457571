import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

import { buttonPrimaryClasses } from "../../../styles/buttonStyles";

import Icons from "../../Icons";

const styles = {
  ...buttonPrimaryClasses
};

const PreviewButton = props => {
  const { classes, Icon, label, children, ...other } = props;
  const { iconClass, ...buttonClasses } = classes;

  return (
    <Button classes={buttonClasses} {...other}>
      <Icon className={iconClass} />
      {label || children}
    </Button>
  );
};

PreviewButton.propsTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  Icon: PropTypes.func.isRequired
};

PreviewButton.defaultProps = {
  label: "Preview",
  Icon: Icons.Preview
};

export { PreviewButton, styles };
export default withStyles(styles)(PreviewButton);
