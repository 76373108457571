import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { loadThinkTankApplications } from "../../../Reducers/Applications";
import { createFlashNote } from "../../../Reducers/FlashNotes";
import { deleteChallenge, updateChallenge } from "../../Challenges/api";

import ManageLinks from "./ManageLinks";

const mapStateToProps = state => {
  return {
    applications: state.applications.applications,
    deleteDocument: deleteChallenge,
    updateDocument: updateChallenge
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => dispatch(createFlashNote(msg, type)),
    loadApplications: id => dispatch(loadThinkTankApplications(id))
  };
};

const ManageThinkTankLinks = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageLinks);

export default withRouter(ManageThinkTankLinks);
