import IconDevices from "@material-ui/icons/Devices";
import IconFlight from "@material-ui/icons/Flight";
import IconFounded from "@material-ui/icons/AccountBalance";
import IconInfo from "@material-ui/icons/Info";
import IconLanguage from "@material-ui/icons/Language";
import IconMonitor from "@material-ui/icons/DesktopWindows";
import IconPeople from "@material-ui/icons/People";

export const specCorporates = [
  {
    Icon: IconMonitor,
    label: "Website",
    key: "website"
  },
  {
    Icon: IconInfo,
    label: "Type",
    key: "company_type"
  },
  {
    Icon: IconPeople,
    label: "Employees",
    key: "size"
  }
];

export const specFounders = [
  {
    Icon: IconMonitor,
    label: "Website",
    key: "website"
  },
  {
    Icon: IconFounded,
    label: "Founded",
    key: "year_founded"
  },
  {
    Icon: IconPeople,
    label: "Company Size",
    key: "size"
  },

  {
    Icon: IconDevices,
    label: "Technologies",
    key: "technologies"
  },

  {
    Icon: IconFlight,
    label: "Active in",
    key: "active_in"
  },
  {
    Icon: IconLanguage,
    label: "Social Media",
    key: "social_media"
  }
];
