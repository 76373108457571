import { program_type_options } from "../Document/config";
import {
  validateAudience,
  validateDuration,
  validateLocation,
  validateProgramName,
  validateProfile,
  validateTags
} from "../Form/validate";

const pages = [
  {
    name: "About the Program",
    formFields: [
      {
        id: "about_the_program_sep",
        label: "About the program",
        helperText: "The what, where and when ...",
        type: "header"
      },

      {
        label: "Program name",
        id: "name",
        limit: 80,
        required: true,
        width: 440,
        validate: validateProgramName
      },

      {
        id: "program_type",
        label: "Program Type",
        type: "idselect",
        required: true,
        options: program_type_options.options,
        labels: program_type_options.labels,
        value: program_type_options.options[0],
        helperText: "Please select a suitable type for your program.",
        width: 440
      },

      {
        label: "Program Benefits",
        id: "benefits",
        type: "benefits"
      },

      {
        label: "What markets and topics are relevant to this program?",
        id: "topics",
        type: "tags",
        category: "program",
        helperText:
          "Select as many tags as relevant, you can also type-in your own",
        placeholder:
          "Start typing to see suggested topics or create your own ...",
        required: true,
        style: { marginTop: 30 },
        validate: validateTags(1, "topic"),
        value: []
      }
    ],
    nextButtonLabel: "Next: Date and Location"
  },
  {
    name: "Date and Location",
    formFields: [
      {
        id: "date_and_time_sep",
        label: "Date and Time",
        helperText: "When does this program take place?",
        type: "header"
      },
      {
        id: "duration_selector",
        label: "Program Duration (required)",
        type: "duration",
        validate: validateDuration
      },

      {
        id: "location_sep",
        label: "Location",
        helperText: "Where does this program take place?",
        type: "header"
      },
      {
        id: "location",
        label: "",
        type: "location",
        validate: validateLocation,
        width: 440
      }
    ],
    nextButtonLabel: "Next: Branding"
  },
  {
    name: "Branding",
    formFields: [
      {
        id: "header_branding",
        label: "Branding",
        helperText:
          "Add a brief description and logo image to help your program stand out.",
        type: "header"
      },
      {
        id: "description",
        label: "Brief Program description",
        helperText:
          "This one-line description is displayed in the programs listing view. Try a short, snappy strapline to help sell your program.",
        required: true,
        limit: 140
      },
      {
        id: "logo_image_url",
        label: "Upload your program logo",
        helperText:
          "Company logos should be square and a minimum 400 x 400 pixels. They will display in the program listing view and again on the program profile.",
        type: "image",
        width: 200,
        height: 200,
        required: true
      }
    ],
    nextButtonLabel: "Next: Build Profile"
  },
  {
    name: "Description Builder",
    formFields: [
      {
        id: "header_profile",
        label: "Build program profile",
        helperText:
          "Build out the content of our program below. You can save your work anytime and preview how it looks! Please provide at least one heading and a text block.",
        type: "header"
      },
      {
        id: "profile",
        label: "Build program profile",
        type: "profile",
        section: true,
        validate: validateProfile
      }
    ],
    nextButtonLabel: "Next: Applications"
  },
  {
    name: "Who can apply",
    formFields: [
      {
        id: "header_application",
        label: "Who can apply",
        helperText:
          "Specify eligibility criteria for who can apply to your program",
        type: "header"
      },
      {
        id: "application",
        label: "Who can apply to this program? (required)",
        type: "applicationOptions",
        validate: validateAudience
      },

      {
        id: "header_eligibility",
        label: "Eligibility",
        helperText:
          "What criteria must applicants meet? Criteria you add will form a questionnaire for applicants.",
        type: "header"
      },

      {
        label: "Program Criteria",
        id: "criteria",
        type: "criteria"
      }
    ],

    nextButtonLabel: "Next: Application Questions"
  },

  {
    name: "Application Questions",
    formFields: [
      {
        id: "header_additional_questions",
        label: "Application Questions",
        helperText:
          "All applications include a full company profile, however addtional information can be requested as part of the application process. Add any questions you want applicants to answer. For example, get applicants to upload their pitch deck.",
        type: "header"
      },

      {
        label: "Application Questions",
        id: "questions",
        type: "questions"
      }
    ]
  }
];

export default pages;
