import { createFlashNote } from "../FlashNotes";

import { loadCompanyFromProfile } from "../Company/api";
import { setCompanyData } from "../Company/companyActionTypes";

import * as api from "./api";
import * as actions from "./profileActionTypes";
import { loadUserData, resetUserData } from "./utils";

export const resetLoginError = () => {
  return dispatch => {
    dispatch(actions.setLoginError(null));
  };
};

export const deleteAccount = password => {
  return dispatch => {
    api
      .deleteAccount(password)
      .then(() => {
        dispatch(createFlashNote("Your account has been removed.", "success"));

        dispatch(resetUserData());
      })
      .catch(error => {
        dispatch(createFlashNote(error.message, "error"));
      });
  };
};

export const login = (username, password) => {
  return dispatch => {
    dispatch(resetUserData({ loading: true }));

    return api
      .login({ username, password })
      .then(() => {
        dispatch(createFlashNote("Welcome to Colinked!", "success"));

        // TODO: loadProfile does not return anything
        return dispatch(loadProfile());
      })
      .then(profile => {
        dispatch(loadUserData());

        return profile;
      })
      .catch(error => {
        dispatch(createFlashNote("The authentication failed.", "error"));
        dispatch(actions.updateProfileData({ loading: false }));
        dispatch(actions.setLoginError(error));
      });
  };
};

export const logout = () => {
  return dispatch => {
    dispatch(actions.updateProfileData({ signingOut: true }));

    api.logout().then(() => {
      dispatch(resetUserData());
    });
  };
};

export const update = data => {
  return dispatch => {
    dispatch(actions.updateProfileData({ loading: true }));

    api
      .update(data)
      .then(result => {
        const { error } = result;

        if (error) {
          dispatch(actions.updateProfileData({ error, loading: false }));
          dispatch(createFlashNote(error.message, "error"));
        } else {
          dispatch(actions.updateProfileData({ ...data, loading: false }));
          dispatch(createFlashNote("profile has been updated", "success"));
        }
      })
      .catch(error => {
        dispatch(actions.updateProfileData({ error, loading: false }));
        dispatch(createFlashNote(error.message, "error"));
      });
  };
};

export const loadProfile = () => {
  return dispatch => {
    // reset existing user profile
    dispatch(resetUserData({ loading: true }));

    api
      .getUserProfile()
      .then(profile => {
        dispatch(
          actions.setProfileData({
            ...profile,
            loading: true // keep loading set until company is loaded
          })
        );

        // load company data
        loadCompanyFromProfile(profile).then(json => {
          if (json.error) {
            // profile is useless without company => reset
            dispatch(resetUserData());

            dispatch(createFlashNote("error loading company data", "error"));
            dispatch(actions.setLoginError(json.error));
          } else {
            dispatch(setCompanyData(json));
            dispatch(
              actions.updateProfileData({ loading: false, isSignedIn: true })
            );
          }
        });
      })
      .catch(error => {
        dispatch(actions.setLoginError(error));

        return actions.defaultProfile;
      });
  };
};

export const updateProfileData = data => {
  return dispatch => {
    dispatch(actions.updateProfileData(data));
  };
};
