import React from "react";

import ProgramBuilderContainer from "../components/Programs/ProgramBuilderContainer";

import PageTemplate from "./PageTemplate";

const BuildProgram = props => {
  return (
    <PageTemplate>
      <ProgramBuilderContainer {...props} />
    </PageTemplate>
  );
};

export default BuildProgram;
