import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import { getPrimaryButtonClasses } from "../../styles/buttonStyles";

const styles = getPrimaryButtonClasses("#85C97C", "#ffffff", "large");

const documentIsClosed = document => {
  const { application, is_ongoing } = document;
  const { deadline_datetime } = application;

  if (is_ongoing) return false;

  if (!deadline_datetime) return false;

  if (new Date(deadline_datetime) < new Date()) {
    return true;
  }
};

const getStatus = document => {
  const { application_is_approved } = document;

  if (application_is_approved !== null) {
    return [
      "Your application was declined.",
      "Your application is pending.",
      "You are participating."
    ][application_is_approved];
  }

  if (documentIsClosed(document)) {
    return "Application has closed";
  }

  return null;
};

const userCanApply = (company, document) => {
  const application = document.application || {};

  if (!company.id && !application.open_to_guests) return false;

  if (company.is_corporate && !application.open_to_corporates) {
    return false;
  }

  if (company.is_founder && !application.open_to_founders) {
    return false;
  }

  return true;
};

const userHasApplied = document => {
  return document.is_company_registered;
};

const userIsOwner = (document, company) => {
  if (!company.id) return false;

  if (document.company.id === company.id) return true;

  return false;
};

const shouldRender = props => {
  const { company, doctype, document, preview } = props;

  // always render in preview mode
  if (preview) return true;

  // disable application for guests
  // if (!company.id) return false;

  // don't show for user's own programs/challenges
  if (userIsOwner(document, company)) return false;

  // check if user has already applied
  if (userHasApplied(document)) return false;

  // check if user type can apply to specific event
  if (!userCanApply(company, document)) return false;

  // only members can apply to think tanks
  if (doctype === "challenge" && !company.id) return false;

  // check if status exists or deadline_datetime has passed
  if (getStatus(document)) return true;

  return true;
};

const ApplyButton = props => {
  const { company, classes, doctype, document, history, preview } = props;
  const { iconClass, ...buttonClasses } = classes;

  // return null if button should not be shown to user
  if (!shouldRender(props)) return null;

  // check if application status exists or deadline has passed
  const status = getStatus(document);
  const disabled = Boolean(status);

  const label =
    doctype === "challenge" || company.is_corporate
      ? "Register Interest"
      : "Apply Now";

  const onClick = () => history.push(`/${doctype}/${document.id}/apply`);

  return (
    <Button
      classes={buttonClasses}
      onClick={onClick}
      disabled={preview || disabled}
      style={{ width: "100%", margin: 0 }}
    >
      {status || label}
    </Button>
  );
};

ApplyButton.propTypes = {
  company: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  doctype: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

ApplyButton.defaultProps = {
  doctype: "program"
};

const mapStateToProps = state => {
  return {
    company: state.company
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ApplyButton));
