import React from "react";

import ExternalLink from "../../common/ExternalLink";
import CountriesList from "../../common/CountriesList";
import MarketsList from "../../common/MarketsList";
import SocialLinks from "../../common/SocialLinks";
import TechnologiesList from "../../common/TechnologiesList";

const hasSocialLinks = company => {
  return company.linkedin || company.twitter || company.facebook;
};

const formatLocation = (_location = {}) => {
  const location = _location || { address: "", country: "", city: "" };
  const address = location.address || "";

  const _country = location.country || "";
  const country = Number.isInteger(_country) ? "" : _country.label || _country;

  return [address.split("\n").join(", "), location.city, country]
    .filter(string => string)
    .join(", ");
};

export const transformCompanyData = company => {
  // TODO add technologies back in when data type is solved
  const { ...data } = company;

  data["active_in"] =
    company.active_in && company.active_in.length ? (
      <CountriesList countries={company.active_in} />
    ) : null;

  data["markets"] = <MarketsList markets={company.markets} />;

  data["location"] = formatLocation(company.location);

  data["size"] = (company.size || "").replace("_", " - ");

  data["social_media"] = hasSocialLinks(company) ? (
    <SocialLinks
      linkedin={company.linkedin}
      twitter={company.twitter}
      facebook={company.facebook}
    />
  ) : null;

  data["technologies"] = (
    <TechnologiesList technologies={company.technologies} />
  );

  data["website"] = company.website ? (
    <ExternalLink url={company.website} />
  ) : null;

  return data;
};
