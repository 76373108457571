import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { responseJsonOrError } from "../../../api/response";
import FormSelectField from "./FormSelectField";

import formStyles from "../../../styles/formStyles";

const styles = {
  root: {
    marginTop: 20
    //marginBottom: 10
  },
  error: {
    ...formStyles.input,
    ...formStyles.error
  },
  helperText: formStyles.helperText,
  label: formStyles.label,
  loading: {
    ...formStyles.input,
    textAlign: "center",
    color: "#AAADB6"
  }
};

class Blacklist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      error: null,
      loading: true
    };
  }

  componentDidMount() {
    fetch("/api/companies/corporations/?limit=1000")
      .then(responseJsonOrError)
      .then(json => {
        const { error, results } = json;

        if (error) {
          console.log(error);
          this.setState({ error, loading: false });
        } else {
          this.setState({ companies: results, loading: false });
        }
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  }

  renderErrorFrame = () => {
    const { classes, label, width } = this.props;
    const { error } = this.state;

    return (
      <div className={classes.root} style={{ width }}>
        <div className={classes.label}>{label}</div>
        <div className={classes.error}>{error.message}</div>
      </div>
    );
  };

  renderLoadingFrame = () => {
    const { classes, label, width } = this.props;

    return (
      <div className={classes.root} style={{ width }}>
        <div className={classes.label}>{label}</div>
        <div className={classes.loading}>Loading ...</div>
      </div>
    );
  };

  render() {
    const { onChange, value, ...otherProps } = this.props;
    const { companies, error, loading } = this.state;

    if (error) return this.renderErrorFrame();
    if (loading) return this.renderLoadingFrame();

    const options = companies.map(company => ({
      value: company.id,
      label: company.name
    }));

    const _value = value
      .map(id => options.find(option => option.value === id))
      .filter(obj => obj);

    return (
      <FormSelectField
        options={options}
        {...otherProps}
        value={_value}
        isMulti={true}
        onChange={selection => {
          onChange(selection.map(c => c.value));
        }}
      />
    );
  }
}

Blacklist.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired
};

Blacklist.defaultProps = {
  label: "Companies to hide this Think Tank from",
  value: [],
  width: 660
};

export default withStyles(styles)(Blacklist);
