import { connect } from "react-redux";

import { createFlashNote } from "../../Reducers/FlashNotes";
import { setValidationErrors } from "../../Reducers/ValidationErrors";

import FormBuilderStateContainer from "../FormBuilder/FormBuilderStateContainer";

import ServiceBanner from "./ServiceBanner";
import ServiceDetailsView from "./ServiceDetailsView/ServiceDetailsView";

import pages from "./MarketplaceBuilderPages";

import {
  selectService,
  loadServices,
  reloadService,
  updateService
} from "../../Reducers/Marketplace";
import { addFrontendData } from "../../Reducers/Marketplace/api";

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    BannerComponent: ServiceBanner,
    PreviewComponent: ServiceDetailsView,
    exitRedirect: "/marketplace",
    pages: pages,
    createNewDocument: (data = {}) => addFrontendData(data),
    validationErrors: state.validationErrors,
    documents: state.marketplace
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    },
    loadDocuments: () => dispatch(loadServices()),
    reloadDocument: id => dispatch(reloadService(id)),
    setValidationErrors: errors => dispatch(setValidationErrors(errors)),
    selectDocument: id => dispatch(selectService(id)),
    updateDocument: data => {
      // TODO: handle validationErrors
      dispatch(updateService(data));
      return Promise.resolve({});
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormBuilderStateContainer);
