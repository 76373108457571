import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import IconFlightLand from "@material-ui/icons/FlightLand";

const styles = {
  root: {
    boxSizing: "border-box",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    height: 160,
    background: "linear-gradient(358.41deg, #398CD3 0%, #1D71B8 100%)"
  },
  icon: {
    fill: "white",
    fontSize: 48,
    marginBottom: 10
  },
  subtitle: {
    color: "white",
    fontSize: "16px",
    fontWeight: 600
  },
  title: {
    color: "white",
    fontWeight: 600,
    fontSize: 32
  }
};

const BannerRegistration = props => {
  const { classes, Icon, title, subtitle } = props;

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} />
      <div className={classes.title}>{title}</div>
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
    </div>
  );
};

BannerRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
  Icon: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

BannerRegistration.defaultProps = {
  Icon: IconFlightLand,
  subtitle: ""
};

export { BannerRegistration, styles };
export default withStyles(styles)(BannerRegistration);
