import React from "react";
import PropTypes from "prop-types";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";

import { ContactCardSmall } from "../Contacts";

import tableStyles from "./tableStyles";

const styles = {
  root: {
    ...tableStyles.row,
    "&:hover": {}
  },
  cell: {
    maxWidth: 1200,
    overflowX: "auto",
    padding: "0px 30px"
  },
  container: {
    display: "flex"
  },
  contactCardRoot: {
    marginTop: 0,
    marginRight: 20,
    marginBottom: 20,
    padding: 20,
    maxWidth: 350
  },
  contactCardBio: {
    maxHeight: 50,
    overflow: "hidden"
  }
};

const ContactsRow = props => {
  const { classes, colSpan, connection } = props;
  const { managers } = connection;

  // need to set width of flex container manually or right margin is wrong
  // card width: 350, padding: 2*20, marginRight: 20
  const width = 410 * managers.length + 10;
  const maxWidth = props.width === "md" ? 900 : 1200;

  return (
    <TableRow className={classes.root}>
      <TableCell
        colSpan={colSpan}
        className={classes.cell}
        style={{ maxWidth: maxWidth }}
      >
        <div className={classes.container} style={{ width: width }}>
          {connection.managers.map(mngr => (
            <ContactCardSmall
              classes={{
                root: classes.contactCardRoot,
                bio: classes.contactCardBio
              }}
              {...mngr}
              showEmail={true}
              showSocial={true}
            />
          ))}
        </div>
      </TableCell>
    </TableRow>
  );
};

ContactsRow.propTypes = {
  classes: PropTypes.object.isRequired,
  colSpan: PropTypes.number.isRequired,
  connection: PropTypes.shape({
    managers: PropTypes.array.isRequired
  }).isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

export default withWidth()(withStyles(styles)(ContactsRow));
