import React from "react";
import { connect } from "react-redux";

import AddIcon from "@material-ui/icons/Add";

import { createFlashNote } from "../../Reducers/FlashNotes";
import { loadReferrals } from "../../Reducers/Referrals";

import { BODY_FONT_BLACK } from "../../styles/colors";
import { SecondaryButton } from "../common/Buttons";

import ReferStartupDialog from "./ReferFounderTeamDialogContainer";

const iconStyle = {
  position: "relative",
  top: -1,
  fill: "#2A2B2C",
  marginRight: 4,
  fontSize: 20
};

class ReferStartupButton extends React.Component {
  state = {
    modal: false
  };

  renderDialog = () => {
    return (
      <ReferStartupDialog
        key={`${Math.random()}`}
        title="Refer a Founder Team"
        onClose={() => this.setState({ modal: false })}
      />
    );
  };

  render() {
    const {
      ButtonComponent,
      children,
      createFlashNote,
      config,
      dispatch,
      fillColor,
      loadReferrals,
      referrals,
      ...other
    } = this.props;

    // do not show button if referrals have reached referrals__max_corp_referrals
    // if (referrals.length >= config.referrals__max_corp_referrals) {
    //   return null;
    // }

    return (
      <React.Fragment>
        {this.state.modal && this.renderDialog()}
        <ButtonComponent
          {...other}
          onClick={() => this.setState({ modal: true })}
        >
          <AddIcon style={{ ...iconStyle, fill: fillColor }} />
          {children}
        </ButtonComponent>
      </React.Fragment>
    );
  }
}

ReferStartupButton.defaultProps = {
  ButtonComponent: SecondaryButton,
  fillColor: BODY_FONT_BLACK
};

const mapStateToProps = state => ({
  config: state.config,
  referrals: state.referrals
});

const mapDispatchToProps = dispatch => ({
  createFlashNote: (msg, type) => dispatch(createFlashNote(msg, type)),
  loadReferrals: () => dispatch(loadReferrals())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferStartupButton);
