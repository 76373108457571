export const foundingStages = {
  map: {
    seed: "Seed",
    series_a: "Series A",
    series_b: "Series B",
    series_c: "Series C+"
  },
  options: ["seed", "series_a", "series_b", "series_c"],
  labels: ["Seed", "Series A", "Series B", "Series C+"]
};

export const foundingStagesOptions = foundingStages.options.map(
  (value, index) => ({
    value,
    label: foundingStages.labels[index]
  })
);

export const DISPLAY_LABELS = {
  countries: "Location",
  countries_select: "Select Region or Countries",
  markets: "Markets",
  markets_select: "Select markets or topics",
  stages: "Funding Stages",
  stages_select: "Select funding stage(s)",
  other: "Custom",
  other_select: "Criteria (Yes/No Question)",

  ...foundingStages.map
};
