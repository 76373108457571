const buttonStyle = {
  color: "rgba(255,255,255,0.87)",
  fontWeight: "bold",
  "&:nth-of-type(1)": {
    marginRight: 24
  }
};

const HEIGHT = 485;
const styles = theme => ({
  paper: {
    width: "390px",
    height: HEIGHT,

    position: "absolute",
    left: "50%",
    top: "50%",
    marginLeft: "-300px",
    marginTop: `-${HEIGHT / 2}px`,

    backgroundColor: "white",
    padding: 30,
    //boxShadow: "0 15px 10px 0 rgba(55,60,68,0.05)"
    "&:focus": {
      boxShadow: "none"
    }
  },
  title: {
    color: "#6592CC",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 30,
    whiteSpace: "nowrap",
    overflowX: "hidden"
  },
  container: {
    display: "flex",
    flexDirection: "column"
    //justifyContent: "space-between",
    //height: HEIGHT - 56 - 32
  },

  helperText: {
    color: "#2A2B2C",
    fontSize: 15,
    fontWeight: 300,
    marginBottom: 20,
    lineHeight: "18px"
  },

  errorRoot: {
    marginBottom: 10
  },
  errorTitle: {
    backgroundColor: "#F2F3F4",
    padding: "10px 15px"
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    fontWeight: 300
  },

  button: {
    height: 60,
    width: "100%",
    marginTop: 20,
    boxShadow: "none"
  },
  buttonLabel: {
    color: "white",
    fontSize: 16,
    fontWeight: 600
  },
  buttonRaised: {
    backgroundColor: "#6592CC",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,0.1)"
  },

  buttons: {
    display: "flex",
    justifyContent: "center"
  },
  buttonCancel: {
    ...buttonStyle,
    color: "rgba(0,0,0,0.54)",
    cursor: "pointer",
    position: "absolute",
    top: 15,
    right: 15
  },
  buttonSubmit: {
    ...buttonStyle,
    backgroundColor: "green"
  },
  buttonDecline: {
    ...buttonStyle,
    backgroundColor: "darkred"
  },
  formRoot: {
    marginTop: 0
  },
  response: {
    padding: 16,
    border: "1px solid lightgrey"
  },
  responseTitle: {
    padding: 8,
    backgroundColor: "lightgrey",
    fontWeight: "bold"
  },
  responseBody: {
    padding: 8
  },
  error: {
    color: "darkred"
  }
});

export default styles;
