import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import { getOutlineButtonClasses } from "../../../styles/buttonStyles";

const buttonClasses = getOutlineButtonClasses();
const buttonClassesBlue = getOutlineButtonClasses("#398DD3");

const styles = {
  buttonClasses,
  root: {
    display: "flex",
    alignItems: "center"
  },
  button: {
    ...buttonClasses.root
  },
  disabled: {
    ...buttonClasses.disabled
  },
  selected: {
    ...buttonClassesBlue.root,
    color: "#398DD3",
    cursor: "default",
    "&:hover": {
      backgroundColor: "white"
    }
  }
};

const GroupButton = props => {
  const { children, classes, disabled, onClick, selected } = props;

  const className = selected
    ? classes.selected
    : disabled
    ? classes.disabled
    : classes.button;

  return (
    <Button
      className={className}
      data-testclass="group-button"
      onClick={!disabled ? onClick : null}
    >
      {children}
    </Button>
  );
};

GroupButton.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool
};

export default withStyles(styles)(GroupButton);
