import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import { outlineButtonClasses } from "../../../styles/buttonStyles";

import VideoPlayer from "../../common/VideoPlayer";

import Controls from "./Controls";
import { styles as baseStyles } from "./BaseFrame";

const EmbedButton = withStyles(outlineButtonClasses)(props => {
  const { classes, children, ...other } = props;
  const { iconClass, ...buttonClasses } = classes;

  return (
    <Button classes={buttonClasses} {...other}>
      {children}
    </Button>
  );
});

const styles = {
  ...baseStyles,
  content: {
    height: 40,
    lineHeight: "40px",
    borderRadius: 3,
    border: "none",
    backgroundColor: "#F2F3F4",
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    paddingLeft: 15,
    paddingRight: 15,
    flexGrow: 1
  }
};

class VideoFrame extends React.Component {
  static propTypes = {
    content: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
  };

  static defaultProps = {
    content: ""
  };

  constructor(props) {
    super(props);

    this.state = {
      approved: Boolean(props.content),
      content: props.content
    };
  }

  embedVideo = () => {
    const { approved, content } = this.state;

    if (!approved) {
      this.setState({ approved: true }, this.props.onChange({ content }));
    } else {
      this.setState(
        { content: "", approved: false },
        this.props.onChange({ content: "" })
      );
    }
  };

  renderPlayer = () => {
    const { approved, content } = this.state;

    if (!approved || !content) return null;

    return <VideoPlayer url={content} />;
  };

  render() {
    const { classes, deleteFrame, moveDown, moveUp } = this.props;
    const { approved, content } = this.state;

    const controlProps = {
      deleteFrame,
      moveDown,
      moveUp
    };

    return (
      <div className={classes.root}>
        <Controls {...controlProps} />

        <div className={classes.type}>Embed Video</div>
        <div className={classes.label}>
          Copy and paste an embed link for a Youtube or Vimeo video below to
          display on the page.
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            className={classes.content}
            onChange={evt => this.setState({ content: evt.target.value })}
            type="text"
            value={content}
          />

          <EmbedButton
            disabled={!Boolean(content)}
            style={{ minWidth: 90 }}
            onClick={this.embedVideo}
          >
            {approved ? "Clear" : "Embed"}
          </EmbedButton>
        </div>

        {this.renderPlayer()}
      </div>
    );
  }
}

export default withStyles(styles)(VideoFrame);
