import React from "react";

import AboutColinked from "./AboutColinked";
import ContactForm from "./ContactForm";
import FeaturedPrograms from "./FeaturedPrograms";
import HeroImage from "./HeroImage";
import PartnersSection from "./PartnersSection";

const Home = () => {
  return (
    <div>
      <HeroImage />
      <AboutColinked />
      <PartnersSection />
      <FeaturedPrograms />
      <ContactForm />
    </div>
  );
};

export default Home;
