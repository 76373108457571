import { think_tank_type_options } from "../Document/config";
import {
  validateAudience,
  validateDuration,
  validateProgramName,
  validateProfile,
  validateTags
} from "../Form/validate";

const pages = [
  {
    name: "Think Tank Details",
    formFields: [
      {
        id: "think_tank_details_sep",
        label: "Think Tank Details",
        helperText: "The what, where and when ...",
        type: "header"
      },

      {
        label: "What is the Think Tank called?",
        id: "name",
        limit: 80,
        required: true,
        width: 440,
        validate: validateProgramName
      },

      {
        id: "meeting_type",
        label: "How will participants take part in this Think Tank",
        type: "meeting_type",
        required: true,
        options: think_tank_type_options.options,
        labels: think_tank_type_options.labels,
        value: think_tank_type_options.options[0],
        width: 660
      },

      {
        id: "duration_selector",
        label: "When does this Think Tank take place? (required)",
        type: "duration",
        validate: validateDuration
      },

      {
        id: "description",
        label: "Provide a short description to summarise this Think Tank",
        helperText:
          "This one-line description is displayed in the programs listing view. Try a short, snappy strapline to help sell your program.",
        required: true,
        limit: 140,
        validate: value => (!value ? "This field is required" : "")
      },

      {
        id: "topics",
        label: "What markets and topics are relevant to this Think Tank?",
        helperText:
          "Select as many tags as relevant, you can also type-in your own",
        placeholder: "Search for topics ...",
        required: true,
        style: { marginTop: 30 },
        type: "tags",
        validate: validateTags(1, "topic"),
        value: []
      }
    ],
    nextButtonLabel: "Next: Description"
  },

  {
    name: "Description Builder",
    formFields: [
      {
        id: "header_profile",
        label: "Build Think Tank description",
        helperText:
          "Define the layout and content of your Think Tank description by adding, rearranging and deleting content blocks. you can save your Think Tank as a draft at any time and preview how it looks!",
        type: "header"
      },
      {
        id: "profile",
        label: "_Build Thin Tank profile",
        type: "profile",
        section: true,
        validate: validateProfile
      }
    ],
    nextButtonLabel: "Next: Who can apply"
  },

  {
    name: "Who can apply",
    formFields: [
      {
        id: "header_application",
        label: "Who can apply",
        helperText:
          "Specify eligibility criteria for who can apply to your program",
        type: "header"
      },
      {
        id: "application",
        label: "Who can apply to this program?",
        type: "applicationOptions",
        hideGuests: true,
        validate: validateAudience
      },

      {
        id: "header_blacklist",
        label: "Hide from specific companies",
        helperText:
          "We appreciate that not all Think Tank topics are appropriate for everyone to see. If you would like to discuss a more sensitive topic, you can hide this Think Tank from sepcific companies.",
        type: "header"
      },
      {
        label: "Companies to hide this Think Tank from",
        id: "blacklist",
        type: "blacklist",
        width: 660
      },

      {
        id: "header_eligibility",
        label: "Eligibility",
        helperText:
          "What criteria must applicants meet? Criteria you add will form a questionnaire for applicants.",
        type: "header"
      },

      {
        label: "_Eligibility",
        id: "criteria",
        type: "criteria"
      }
    ],

    nextButtonLabel: "Next: Submit"
  },
  {
    name: "Submission Questions",
    formFields: [
      {
        id: "header_additional_questions",
        label: "Additional Questions",
        helperText:
          "Addtional information can be requested as part of the submission process. Add any questions you want applicants to answer.",
        type: "header"
      },

      {
        label: "Additional Questions",
        id: "questions",
        type: "questions"
      }
    ],
    nextButtonLabel: "Next: Submit"
  }
];

export default pages;
