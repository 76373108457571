import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

export const loadMyApplications = () => {
  return fetchWithToken("/api/companies/applications/?limit=1000")
    .then(responseJsonOrError)
    .catch(error => ({ error }));
};

const getLinkFromUrl = url => {
  const parts = url.split("/");

  if (parts[2] === "think_tanks") return `/challenge/${parts[3]}`;
  if (parts[2] === "programs") return `/program/${parts[3]}`;

  return null;
};

export const loadApplicationDetails = application => {
  const { url } = application;

  const docUrl = url
    .split("/")
    .slice(0, 4)
    .join("/");

  const link = getLinkFromUrl(url);

  return fetchWithToken(docUrl + "/")
    .then(responseJsonOrError)
    .then(document => {
      if (application.program) {
        return {
          ...application,
          program: {
            ...application.program,
            ...document,
            link
          }
        };
      } else if (application.think_tank) {
        return {
          ...application,
          think_tank: {
            ...application.think_tank,
            ...document,
            link
          }
        };
      }

      return application;
    })
    .catch(error => ({ ...application, error }));
};
