import React from "react";

import { withStyles } from "@material-ui/core/styles";

import ConnectionsListContainer from "../Connections/ConnectionsListContainer";

import CorporateActivities from "./CorporateActivities";
import ReferralsListContainer from "../Referrals/ReferralsListContainer";

const styles = {
  card: {
    backgroundColor: "white",
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
    marginTop: 40,
    "&:last-child": {
      marginBottom: 60
    }
  }
};

const CorporateDashboard = props => {
  const { classes, company } = props;

  return (
    <React.Fragment>
      <div className={`body ${classes.card}`}>
        <CorporateActivities company={company} />
      </div>

      <div className={`body ${classes.card}`}>
        <ReferralsListContainer recent={5} title="Recent Referrals" />
      </div>

      <div className={`body ${classes.card}`}>
        <ConnectionsListContainer />
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(CorporateDashboard);
