import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import Banner from "../common/Banner";
import TabbedContainer from "../common/TabbedContainer";

import CreateButton from "../common/Buttons/CreateButton";
import DocumentsListViewContainer from "../Document/DocumentsListViewContainer";
import { loadPrograms, loadProgramsFromUrl, loadMyPrograms } from "./api";

const tableStyle = {
  marginTop: 40,
  marginBottom: 60,
  backgrundColor: "white",
  boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.1)"
};

const ProgramsListViewPage = props => {
  const { company, subtitle, title } = props;

  const programsListView = (
    <DocumentsListViewContainer
      doctype="program"
      loadDocuments={props.loadPrograms}
      loadDocumentsFromUrl={props.loadProgramsFromUrl}
      style={tableStyle}
    />
  );

  const content = company.is_corporate ? (
    <TabbedContainer
      actionButton={
        <CreateButton link={"/programs/new"}>Start a new Program</CreateButton>
      }
      labels={["All Programs", "My Programs"]}
      fullWidth={true}
      size="large"
    >
      {programsListView}
      <DocumentsListViewContainer
        doctype="program"
        loadDocuments={props.loadMyPrograms(company.id)}
        loadDocumentsFromUrl={props.loadProgramsFromUrl}
        style={tableStyle}
      />
    </TabbedContainer>
  ) : (
    programsListView
  );

  return (
    <React.Fragment>
      <Banner title={title} subtitle={subtitle} />
      <div className="body">{content}</div>
    </React.Fragment>
  );
};

ProgramsListViewPage.propTypes = {
  company: PropTypes.object.isRequired,
  doctype: PropTypes.string.isRequired,
  loadPrograms: PropTypes.func.isRequired,
  loadProgramsFromUrl: PropTypes.func.isRequired,
  loadMyPrograms: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

ProgramsListViewPage.defaultProps = {
  doctype: "program",
  loadPrograms,
  loadProgramsFromUrl,
  loadMyPrograms,
  subtitle:
    "Find and apply for Accelerators, Hackathons, Incubators, Competitions and more...",
  title: "Programs"
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    company: state.company
  };
};

export default connect(mapStateToProps)(ProgramsListViewPage);
