import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

import { outlineButtonClasses } from "../../../styles/buttonStyles";

import Icons from "../../Icons";

const styles = {
  ...outlineButtonClasses,
  iconClass: {
    ...outlineButtonClasses.iconClass,
    marginRight: 5
  }
};

const SaveDraftButton = props => {
  const { classes, Icon, label, children, ...other } = props;
  const { iconClass, ...buttonClasses } = classes;

  return (
    <Button classes={buttonClasses} {...other}>
      <Icon className={iconClass} />
      {label || children}
    </Button>
  );
};

SaveDraftButton.propsTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  Icon: PropTypes.func.isRequired
};

SaveDraftButton.defaultProps = {
  label: "Save Draft",
  Icon: Icons.ContentSave
};

export { SaveDraftButton, styles };
export default withStyles(styles)(SaveDraftButton);
