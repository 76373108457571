import React from "react";
import { PropTypes } from "prop-types";

import { Flipper, Flipped } from "react-flip-toolkit";

import { withStyles } from "@material-ui/core/styles";

import Banner from "../common/Banner";
import { PrimaryButtonLarge } from "../common/Buttons";

import FAQItem from "./FAQItem";
import FAQMenu from "./FAQMenu";
import faqContent from "./faqContent";

const styles = {
  container: {
    maxWidth: 1100,
    minHeight: 400,
    margin: "50px auto 0"
  },
  contact: {
    maxWidth: 550,
    paddingTop: 50,
    paddingBottom: 100,
    textAlign: "center",
    margin: "25px auto 0"
  },
  contactTitle: {
    color: "#2a2b2c",
    fontSize: 24,
    fontWeight: 700
  }
};

const filterByAudience = audience => faq => {
  if (!audience) return true;

  return !faq.audience || faq.audience === audience;
};

const filterFAQs = filter => faq => {
  if (!filter) return true;

  return (
    faq.title.toLowerCase().includes(filter.toLowerCase()) ||
    faq.content.toLowerCase().includes(filter.toLowerCase())
  );
};

class FAQs extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    faqs: PropTypes.array.isRequired
  };

  static defaultProps = {
    faqs: faqContent
  };

  constructor(props) {
    super(props);

    this.state = {
      audience: "",
      faqs: props.faqs,
      filter: ""
    };
  }

  setAudience = audience => {
    const { filter } = this.state;

    const faqs = this.props.faqs
      .filter(filterByAudience(audience))
      .filter(filterFAQs(filter));

    this.setState({ audience, faqs });
  };

  setFilter = filter => {
    const { audience } = this.state;

    const faqs = this.props.faqs
      .filter(filterByAudience(audience))
      .filter(filterFAQs(filter));

    this.setState({ faqs, filter });
  };

  render() {
    const { classes } = this.props;
    const { audience, faqs, filter } = this.state;

    const flipKey = `${audience}_${filter}`;

    return (
      <div style={{ backgroundColor: "#F2F3F4" }}>
        <Banner title="Colinked FAQs" />

        <div className={classes.container}>
          <FAQMenu
            audience={audience}
            filter={filter}
            setAudience={this.setAudience}
            setFilter={this.setFilter}
          />

          <Flipper flipKey={flipKey}>
            <ul>
              {faqs.map(faq => (
                <Flipped key={faq.title} flipId={faq.title}>
                  <li>
                    <FAQItem {...faq} />
                  </li>
                </Flipped>
              ))}
            </ul>
          </Flipper>

          <div className={classes.contact}>
            <div className={classes.contactTitle}>Can't find an answer?</div>
            <div style={{ margin: 25 }}>
              Have another question or query about Colinked? Get in touch and
              one of team members will reply as soon as possible.
            </div>
            <PrimaryButtonLarge href="mailto:support@colinked.io">
              Contact Us
            </PrimaryButtonLarge>
          </div>
        </div>
      </div>
    );
  }
}

export { FAQs };
export default withStyles(styles)(FAQs);
