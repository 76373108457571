import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import TabbedContainer from "../common/TabbedContainer";

import ConnectionsList from "./ConnectionsList";
import { connectionIsActive, connectionIsInactive } from "./utils";

const TabbedConnectionsList = props => {
  const { classes, company, connections } = props;

  const active = connections.filter(connectionIsActive(company));
  const ignored = connections.filter(connectionIsInactive(company));

  // add loading flag to new connections lists
  active.loading = connections.loading;
  ignored.loading = connections.loading;

  const listProps = {
    company,
    hideTitle: true,
    limit: 0,
    style: { marginTop: 0 }
  };

  return (
    <TabbedContainer
      labels={[`Active (${active.length})`, `Ignored (${ignored.length})`]}
      tabHashes={["active", "ignored"]}
      classes={classes}
      fullWidth={true}
      size="large"
    >
      <div className="card" style={{ marginTop: 40 }}>
        <ConnectionsList {...listProps} connections={active} />
      </div>

      <div className="card" style={{ marginTop: 40 }}>
        <ConnectionsList {...listProps} connections={ignored} />
      </div>
    </TabbedContainer>
  );
};

TabbedConnectionsList.propTypes = {
  connections: PropTypes.array.isRequired
};

TabbedConnectionsList.defaultProps = {
  // keep classes prop as a way to customize styles
  classes: {}
};

const mapStateToProps = state => {
  return {
    company: state.company,
    connections: state.connections
  };
};

export { TabbedConnectionsList };
export default connect(mapStateToProps)(TabbedConnectionsList);
