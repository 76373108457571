import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";

import { withStyles } from "@material-ui/core/styles";

import VideoPlayer from "./VideoPlayer";

const styles = {
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-470px, -265px)",
    padding: 0,
    margin: 0,
    width: 940,
    height: 529,
    outline: "none"
  },
  backdrop: {
    backgroundColor: "rgba(85,91,110,0.9)"
  },
  buttonCancel: {
    position: "absolute",
    top: -48,
    right: 0,

    fill: "white",
    fontSize: 40,
    cursor: "pointer"
  },
  videoPlayer: {
    marginTop: 0
  }
};

class VideoPlayerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true
    };
  }

  handleClose = () => {
    this.setState({ open: false }, () => {
      this.props.onClose && this.props.onClose();
    });
  };

  render() {
    const { classes, url, height, width } = this.props;

    const style = {
      transform: `translate(-${width / 2}px, -${height / 2}px)`,
      width,
      height
    };

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
        onClose={this.handleClose}
        BackdropProps={{ className: classes.backdrop }}
      >
        <div className={classes.root} style={style}>
          <CloseIcon
            className={classes.buttonCancel}
            onClick={this.handleClose}
          />
          <VideoPlayer
            classes={{ root: classes.videoPlayer }}
            playing={false}
            url={url}
          />
        </div>
      </Modal>
    );
  }
}

VideoPlayerModal.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired
};

VideoPlayerModal.defaultProps = {
  width: 940,
  height: 529
};

export default withRouter(withStyles(styles)(VideoPlayerModal));
