import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AddIcon from "@material-ui/icons/Add";

import { SecondaryButton } from "./";

const iconStyle = {
  position: "relative",
  top: -1,
  fill: "#2A2B2C",
  marginRight: 4,
  fontSize: 20
};

const CreateButton = props => {
  const {
    children,
    dispatch,
    doctype,
    history,
    link,
    location,
    profile,
    staticContext,
    ...other
  } = props;

  // show button only to corporates
  if (!profile.membership || !profile.membership.startsWith("corp")) {
    return null;
  }

  return (
    <SecondaryButton onClick={() => history.push(link)} {...other}>
      <AddIcon style={iconStyle} />
      {children}
    </SecondaryButton>
  );
};

CreateButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  link: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({ profile: state.profile });

export default connect(mapStateToProps)(withRouter(CreateButton));
