import { resetCompany } from "../Company";
import {
  clearNotifications,
  fetchNotifications,
  updateNotifications
} from "../Notifications";
import {
  loadMyApplications,
  resetMyApplications,
  updateMyApplications
} from "../MyApplications";
import {
  loadConnections,
  resetConnections,
  updateConnections
} from "../Connections";
import { resetServices } from "../Marketplace";

import { resetProfileData } from "./profileActionTypes";

export const PROFILE_ATTRIBUTES = [
  "id",
  "avatar_image_url",
  "bio",
  "email",
  "first_name",
  "job_title",
  "last_name",
  "linkedin",
  "password",
  "phone_number",
  "twitter",
  "username"
];

export const cleanProfileData = data => {
  return PROFILE_ATTRIBUTES.reduce((profile, key) => {
    if (data.hasOwnProperty(key)) {
      profile[key] = data[key];
    }
    return profile;
  }, {});
};

export const loadUserData = () => {
  return dispatch => {
    dispatch(loadConnections());
    dispatch(loadMyApplications());
    dispatch(fetchNotifications());
  };
};

export const resetUserData = data => {
  return dispatch => {
    dispatch(resetProfileData(data));
    dispatch(resetCompany());
    dispatch(resetConnections());
    dispatch(resetMyApplications());
    dispatch(resetServices());

    dispatch(clearNotifications());
  };
};

export const updateUserData = () => {
  return dispatch => {
    dispatch(updateConnections());
    dispatch(updateMyApplications());
    dispatch(updateNotifications());
  };
};
