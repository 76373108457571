import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ErrorGraphic from "./ErrorGraphic";

const styles = {
  body1: {
    fontSize: 16,
    marginTop: 20
  },
  body2: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: 20
  },
  location: {
    backgroundColor: "#F9EFED",
    border: "1px solid #DB6E53",
    borderRadius: 3,
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 11,
    paddingBottom: 11,
    marginTop: 20
  }
};

const NotFound = props => {
  const { classes, resource } = props;

  const resourceType = resource ? "resource" : "page";

  return (
    <ErrorGraphic size="large">
      <div className={classes.body2}>
        The internet is broken!! I repeat, the internet is broken!!
      </div>
      <div className={classes.body1}>
        {`Not really, but it does look like we can’t find the ${resourceType} you are
          looking for.`}
      </div>
      <div className={classes.location}>
        {resource || document.location.href}
      </div>
    </ErrorGraphic>
  );
};

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
  resource: PropTypes.string
};

export default withStyles(styles)(NotFound);
