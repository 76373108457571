import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../Icons";
import styles from "./styles";

const renderBlock = (lines, Icon, classes) => {
  return (
    <div className={classes.detailsRow}>
      <Icon className={classes.inlineIcon} />
      <div className={classes.detailsBlock}>
        {lines.map((line, index) => (
          <div className={classes.detailsLine} key={index}>
            {line}
          </div>
        ))}
      </div>
    </div>
  );
};

const AppointmentLocation = props => {
  const { classes, city, address, countries, postcode } = props;

  let country = props.country;
  const countryObj =
    country && country.id
      ? country
      : countries.find(_country => _country.id === country);
  if (countryObj) {
    country = countryObj.label;
  }

  const location = [city, country].filter(c => c).join(", ");
  const lines = [...(address || "").split("\n"), postcode, location].filter(
    line => line
  );
  const Icon = Icons.MapMarker;

  if (lines.length === 0) {
    return null;
  }

  return <React.Fragment>{renderBlock(lines, Icon, classes)}</React.Fragment>;
};

AppointmentLocation.propTypes = {
  classes: PropTypes.object.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  address: PropTypes.string,
  postcode: PropTypes.string,
  map: PropTypes.object
};

AppointmentLocation.defaultProps = {
  address: "",
  postcode: ""
};

const mapStateToProps = state => {
  return {
    countries: state.countries
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(AppointmentLocation));
