import React from "react";

import ThinkTankBuilderContainer from "../components/Challenges/ThinkTankBuilderContainer";

import PageTemplate from "./PageTemplate";

const BuildProgram = props => {
  return (
    <PageTemplate>
      <ThinkTankBuilderContainer {...props} />
    </PageTemplate>
  );
};

export default BuildProgram;
