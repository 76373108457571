import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

export const ADD_REFERRAL = "ADD_REFERRAL";
export const REMOVE_REFERRAL = "REMOVE_REFERRAL";
export const SET_REFERRALS = "SET_REFERRALS";
export const UPDATE_REFERRAL = "UPDATE_REFERRAL";

export const addReferral = referral => {
  return {
    type: ADD_REFERRAL,
    payload: referral
  };
};

export const removeReferral = referral => {
  return {
    type: REMOVE_REFERRAL,
    payload: referral
  };
};

export const setReferrals = referrals => {
  return {
    type: SET_REFERRALS,
    payload: referrals
  };
};

export const updateReferral = referral => {
  return {
    type: UPDATE_REFERRAL,
    payload: referral
  };
};

export const clearReferrals = () => {
  return dispatch => {
    dispatch(setReferrals([]));
  };
};

export const loadReferrals = () => {
  return dispatch => {
    fetchWithToken("/api/referrals/?limit=1000")
      .then(response => responseJsonOrError(response))
      .then(json => {
        const { error, results } = json;

        if (error) {
          dispatch(setReferrals([]));
        } else {
          dispatch(setReferrals(results));
        }
      })
      .catch(error => {
        // TODO: display error
        console.error(error);
      });
  };
};
