import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core";

import Icons from "../../Icons";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import {
  questionTypes,
  questionTypeLabels,
  questionTypeLabelsShort
} from "./config";
import globalStyles from "./styles";

const styles = {
  updateTypeLink: globalStyles.updateTypeLink,
  icon: globalStyles.icon,
  iconLeft: globalStyles.iconLeft,
  type: globalStyles.type,
  label: globalStyles.label,
  selection: globalStyles.selection
};

const renderIcon = (type, classes) => {
  if (type === "text_input") {
    return <Icons.Text className={classes.iconLeft} />;
  }
  if (type === "single_choice_input") {
    return <Icons.CheckboxMarkedCircleOutline className={classes.iconLeft} />;
  }
  if (type === "multiple_choice_input") {
    return <Icons.CheckboxMarkedOutline className={classes.iconLeft} />;
  }
  if (type === "file_upload") {
    return <Icons.CloudUpload className={classes.iconLeft} />;
  }

  return null;
};

class TypeSelector extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
    optionLabels: PropTypes.object.isRequired,
    optionLabelsShort: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    updateType: PropTypes.func.isRequired
  };

  static defaultProps = {
    options: questionTypes,
    optionLabels: questionTypeLabels,
    optionLabelsShort: questionTypeLabelsShort
  };

  state = {
    open: false
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    // if (this.anchorEl.contains(event.target)) {
    //   return;
    // }

    this.setState({ open: false });
  };

  handleMenuClick = label => {
    this.setState({ open: false }, () => this.props.updateType(label));
  };

  render() {
    const {
      classes,
      options,
      optionLabels,
      optionLabelsShort,
      question
    } = this.props;
    const { type } = question;

    const label = type ? optionLabelsShort[type] || type : "Select type ... ";

    const menuEntries = options.map(option => (
      <MenuItem
        key={option}
        onClick={() => this.handleMenuClick(option)}
        className={classes.menuItem}
      >
        {optionLabels[option] || option}
      </MenuItem>
    ));

    return (
      <div>
        <div
          className={classes.type}
          aria-owns={this.state.open ? "sub-menu-grow" : null}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          <div className={classes.label}>Answer Type</div>
          <div className={classes.selection}>
            {renderIcon(type, classes)}
            <div style={{ flexGrow: 1 }}>{label}</div>
            <Icons.ChevronDown className={classes.icon} />
          </div>
        </div>

        <Popper
          className={classes.popper}
          open={this.state.open}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="sub-menu-grow"
              style={{
                transformOrigin: "right top"
              }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>{menuEntries}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

export { TypeSelector, styles };
export default withStyles(styles)(TypeSelector);
