import * as api from "./api";
import * as actions from "./ServiceActionTypes";

import { createFlashNote } from "../FlashNotes";

export const createNewService = data => {
  return dispatch => {
    api.createNewService(data).then(json => {
      if (json.error) {
        dispatch(createFlashNote(json.error.message, "error"));
      } else {
        dispatch(createFlashNote("New service has been created.", "success"));
        dispatch(actions.createNewService(json));
      }
    });
  };
};

export const deleteService = id => {
  return dispatch => {
    api.deleteService(id).then(json => {
      if (json.error) {
        dispatch(createFlashNote(json.error.message, "error"));
      } else {
        dispatch(createFlashNote("The service has been deleted.", "success"));
        dispatch(actions.deleteService(id));
      }
    });
  };
};

export const loadService = id => {
  return dispatch => {
    dispatch(actions.updateServiceList([{ id, loading: true }]));

    api.loadService(id).then(json => {
      const { error, ...data } = json;

      if (error) {
        dispatch(createFlashNote(error.message, "error"));
        dispatch(actions.updateService({ id, error, loading: false }));
      } else {
        dispatch(actions.updateService({ ...data, id, loading: false }));
      }
    });
  };
};

export const loadServices = () => {
  return dispatch => {
    dispatch(actions.setServiceListLoading());

    api.loadServices().then(json => {
      if (json.error) {
        dispatch(createFlashNote(json.error.message, "error"));
        // need to update list with empty array to remove 'loading' flag
        dispatch(actions.setServiceList([]));
      } else {
        dispatch(actions.setServiceList(json.results || []));
      }
    });
  };
};

export const reloadService = id => {
  return dispatch => {
    api.loadServices(id).then(json => {
      if (!json.error) {
        dispatch(actions.updateService(json));
      }
    });
  };
};

export const resetServices = (loading = false) => {
  return dispatch => {
    dispatch(actions.setServiceList([]));
    if (loading) {
      dispatch(actions.setServiceListLoading());
    }
  };
};

export const selectService = id => {
  return dispatch => {
    dispatch(actions.selectService(id));
  };
};

export const updateService = data => {
  return dispatch => {
    dispatch(actions.updateService({ id: data.id, loading: true }));

    api.updateService(data).then(json => {
      if (json.error) {
        dispatch(createFlashNote(json.error.message, "error"));
        dispatch(actions.updateService({ id: data.id, loading: false }));
      } else {
        dispatch(createFlashNote("Update successful.", "success"));
        dispatch(actions.updateService({ ...json, loading: false }));
      }
    });
  };
};

export const updateServiceList = updated_at => {
  return dispatch => {
    dispatch(actions.setServiceListLoading());

    api.updateServices(updated_at).then(json => {
      if (json.error) {
        dispatch(createFlashNote(json.error.message, "error"));
        // need to update list with empty array to remove 'loading' flag
        dispatch(actions.updateServiceList([]));
      } else {
        dispatch(actions.updateServiceList(json.results || []));
      }
    });
  };
};
