import { CORPORATES, FOUNDERS } from "./constants";

const faqContent = [
  {
    title: "Why should I join as a corporate member?",
    contentHTML: `<ol>
      <li>You can post your corporate engagement and innovation programs</li>
      <li>Reach out to other global corporates</li>
      <li>Find quality / vetted scale ups</li>
      <li>Discover exploratory engagements with other corps</li>
    </ol>`,
    audience: CORPORATES
  },
  {
    title: "Why should I join as a Founder Team?",
    contentHTML: `<ol>
    <li>You can discover corporate programs and engagements</li> 
    <li>You can connect directly with corporates</li>
    <li>You can get easy access to our investReady? service</li>
    </ol>`,
    audience: FOUNDERS
  },
  {
    title: "How do I join as a corporate member?",
    contentHTML: `You can register here: <a href="https://colinked.io/forcorporates" target="_blank" rel="noopener noreferrer">colinked.io/forcorporates</a>`,
    audience: CORPORATES
  },
  {
    title: "How do I join as a Founder Team?",
    contentHTML:
      'You can register here: <a href="https://colinked.io/forfounders" target="_blank" rel="noopener noreferrer">colinked.io/forfounders</a>',
    audience: FOUNDERS
  },
  {
    title: "How much is it for corporate members?",
    content: "Colinked is totally free to use.",
    audience: CORPORATES
  },
  {
    title: "How much is it for Founder Teams?",
    contentHTML: `Colinked is totally free to use! How we make money is through our add-on services. 
    You can use our InvestReady? service to help with your fundraising round where we'll take a 
    success fee to help you fundraise. You can find more here: 
    <a href="www.white.space/investready" alt="investready" target="_blank" rel="noopener noreferrer">www.white.space/investready</a>`,
    audience: FOUNDERS
  },
  {
    title: "How many users can we have as a corporate member?",
    content:
      "Unlimited - invite as many users from your company as you please. ",
    audience: CORPORATES
  },

  // {
  //   title: "Who can see my company profile?",
  //   content: "Everyone can see your company name and logo."
  // },
  {
    title: "What can other users see of my profile?",
    content:
      "Everyone can see your company profile. Users will be able to see your name and job role. No one can see your personal details until you accept a connection with them."
  },
  {
    title: "What do you do with my personal data?",
    content:
      "Nothing. We track website usage and that's about it. You can find our full privacy policy in the website footer below. We also collect your browser details if you submit feedback."
  },
  {
    title: "How do I add my colleagues to join?",
    content:
      "You can can add your colleagues by pressing the 'manage team' button in the profile dropdown. This can be opened by pressing your profile name on the top right."
  },

  {
    title: "How much info do you collect on startups?",
    content:
      "We collect a pitch deck, funding details, members of their team and much more! Enough for you to decide if you want to work with them or not.",
    audience: CORPORATES
  },

  {
    title: "What is a Program?",
    content:
      "Programs are events that you can post and share with all Colinked members. Anything can be a program including accelerators, hackathons or workshops. You can toggle these to be private or public and can manage the application to them through Colinked."
  },
  {
    title: "How can I promote a Program?",
    contentHTML: `If you want to feature a program on our home page and increase your engagement, you can do so for £200 per day by emailing  <a href="mailto:support@colinked.io">support@colinked.io</a> `,
    audience: CORPORATES
  },
  {
    title: "What is a Think Tank?",
    content:
      "A ThinkTank is a feature on Colinked that enables Corporate members to collaborate with other Corporates on the platform and, if needed, do this under an NDA.",
    audience: CORPORATES
  },
  {
    title: "Do ThinkTanks happen on Colinked?",
    content:
      "No. We recognise that you will want the freedom to decide how you want to collaborate with other Corporates and this may be in person or via a conference call. We leave it up to you to organise directly with your chosen participants.",
    audience: CORPORATES
  },
  {
    title: "Can I hide ThinkTanks from my competitors and other firms?",
    content:
      "Yep! ThinkTanks are only visible to other Colinked members. We offer a blacklist option so you can hide it from competitors on the platform."
  },

  {
    title: "Can I still apply to programs without being a member?",
    content: "Yes, you can still apply to any of the programs.",
    audience: FOUNDERS
  },
  {
    title: "How do I get notified about applications",
    content:
      "Applications to your programs and Think Tanks are emailed to your inbox. You will be able to configure this in your settings.",
    audience: CORPORATES
  },
  {
    title: "How do I manage applications?",
    content:
      "When users apply for programs, you'll be notified which each applicant. To view applicants, go to the 'Programs' page, Select the 'My Programs' tab and click on the program you want to manage. From there, you'll see the 'Manage applications' button in the top header.",
    audience: CORPORATES
  },

  {
    title: "How do I refer startups?",
    content:
      "On your dashboard, you will see the button 'Refer Founder Team' in the header.",
    audience: CORPORATES
  },
  {
    title: "How many startups can I refer?",
    contentHTML: `You can refer 20 startups and see their progress. If you want to refer more than 20, send us a message at <a href="mailto:support@colinked.io">support@colinked.io</a> and we'll increase your cap.`,
    audience: CORPORATES
  },
  {
    title: "How do I connect with Corporates?",
    contentHTML: `<ol>
    <li>Apply to their programs. Through applying to their programs, you can ask questions directly on their program page.</li>
    <li>Request connection. You can find a list of corps on Colinked in the company page. When you're on a company profile, press the 'Connect' button at the top right. If they accept your connection, you'll see their primary contact email details</li>
    </ol>`,
    audience: FOUNDERS
  },
  {
    title: "Who manages the corporate account?",
    content:
      "It varies - the typical corporate user tends to be CIOs, Head of Partnerships and Associates, all people looking to directly engage with startups! Even without an accepted connection, you can see their job titles.",
    audience: FOUNDERS
  },

  {
    title: "How do I make my profile stand out?",
    contentHTML: `A great profile will have all key information filled in and will increase the chances of corporate connections.
    <ul> 
    <li>Your Company pitch should explain what and why you do what you do.</li>
    <li>Any images or videos you have about your product go a long way to standing out</li>
    <li>Highlight your key team members - everyone wants to know who their really dealing with!</li>
    </ul>`
  },
  {
    title: "Can I reach out to other founders?",
    contentHTML: `You can view other founder teams and connect with them like you would a corporate`
  },
  {
    title: "Does Colinked help setting up face to face meetings?",
    contentHTML: `No. Right now, we just facilitate matchmaking. However, if you are trying to meet investors and raise investments, we can help with that - reach out to <a href="mailto:support@colinked.io">support@colinked.io</a> and we'll see what we can do.`
  }
];

export default faqContent;
