import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <section className="page-header-aligned-left">
        <h1>
          Colinked <span>Privacy Policy</span>
        </h1>
      </section>

      <section className="content-padding-small">
        <section className="content">
          <h2>Introduction</h2>

          <p>
            Welcome to Whitespace Ventures Limited's (Whitespace) privacy
            policy.
          </p>

          <p>
            Whitespace respects your privacy and is committed to protecting your
            personal data. This privacy policy will inform you as to how we look
            after your personal data when you visit our website (regardless of
            where you visit it from) and tell you about your privacy rights and
            how the law protects you.
          </p>

          <ol>
            <li>
              <a href="#1">Important Information and Who We Are</a>
            </li>
            <li>
              <a href="#2">The Data We Collect About You</a>
            </li>
            <li>
              <a href="#3">How Is Your Personal Data Collected</a>
            </li>
            <li>
              <a href="#4">How We Use Your Personal Data</a>
            </li>
            <li>
              <a href="#5">Discolusers of Your Personal Data</a>
            </li>
            <li>
              <a href="#6">International Transfers</a>
            </li>
            <li>
              <a href="#7">Data Security</a>
            </li>
            <li>
              <a href="#8">Data Retention</a>
            </li>
            <li>
              <a href="#9">Your Legal Rights</a>
            </li>
            <li>
              <a href="#10">Glossary</a>
            </li>
          </ol>

          <h2 id="1">Important Information and Who We Are</h2>

          <h3>Purpose of this privacy policy</h3>

          <p>
            This privacy policy aims to give you information on how Whitespace
            collects and processes your personal data through your use of this
            website, including any data you may provide through this website
            when you join the Colinked platform <strong>(Colinked)</strong> (by
            signing up and creating a profile) or purchase a product or service
            through Colinked.
          </p>

          <p>
            This website is not intended for children and we do not knowingly
            collect data relating to children.
          </p>

          <p>
            It is important that you read this privacy policy together with any
            other privacy policy or fair processing policy we may provide on
            specific occasions when we are collecting or processing personal
            data about you so that you are fully aware of how and why we are
            using your data. This privacy policy supplements other notices and
            privacy policies and is not intended to override them.
          </p>

          <h3>Controller</h3>

          <p>
            Whitespace is made up of different legal entities including
            Whitespace Ventures Limited, Whitespace Global Limited, Whitespace
            Alpha Limited, Whitespace Beta Limited and Whitespace TV Limited.
            This privacy policy is issued on behalf of the Whitespace Group so
            when we mention "Whitespace", "we", "us" or "our" in this privacy
            policy, we are referring to the relevant company in the Whitespace
            Group responsible for processing your data. Whitespace Ventures
            Limited is the controller and responsible for this website.
          </p>

          <p>
            We have appointed a data privacy manager who is responsible for
            overseeing questions in relation to this privacy policy. If you have
            any questions about this privacy policy, including any requests to{" "}
            <em>exercise your legal rights</em>, please contact the data privacy
            manager using the details set out below.
          </p>

          <h3>Contact Details</h3>

          <p>
            If you have any questions about this privacy policy or our privacy
            practices, please contact our data privacy manager in the following
            ways:
          </p>

          <p>Full name of legal entity: Whitespace Ventures Limited</p>

          <p>
            Email address:{" "}
            <a href="mailto:gdpr@colinked.io">gdpr@colinked.io</a>
          </p>

          <p>Postal address: Runway East, 58 City Road, London, EC1Y 2AL</p>

          <p>
            You have the right to make a complaint at any time to the
            Information Commissioner's Office (ICO), the UK supervisory
            authority for data protection issues (
            <a
              href="https://ico.org.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.ico.org.uk
            </a>
            ). We would, however, appreciate the chance to deal with your
            concerns before you approach the ICO so please contact us in the
            first instance.
          </p>

          <h3>
            Changes to the privacy policy and your duty to inform us of changes
          </h3>

          <p>
            We keep our privacy policy under regular review. This version was
            last updated on 25th July 2018. Historic versions can be obtained by
            contacting us.
          </p>

          <p>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us.
          </p>

          <h3>Third-party links</h3>

          <p>
            This website may include links to third-party websites, plug-ins and
            applications. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. We do
            not control these third-party websites and are not responsible for
            their privacy statements. When you leave our website, we encourage
            you to read the privacy policy of every website you visit.
          </p>

          <h2 id="2">The Data We Collect About You</h2>

          <p>
            Personal data, or personal information, means any information about
            an individual from which that person can be identified. It does not
            include data where the identity has been removed (anonymous data).
          </p>

          <p>
            We may collect, use, store and transfer different kinds of personal
            data about you which we have grouped together as follows:
          </p>

          <ul>
            <li>
              <strong>Identity Data:</strong> includes first name, last name,
              username or similar identifier and title.
            </li>
            <li>
              <strong>Contact Data:</strong> includes billing address, delivery
              address, email address and telephone numbers.
            </li>
            <li>
              <strong>Financial Data:</strong> includes bank account and payment
              card details. Whitespace does not collect this data and any
              payments made for products or services through Colinked will be
              processed through third parties, Braintree and Recurly. Their
              privacy policies can be accessed by navigating to these links:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.braintreepayments.com/legal/braintree-privacy-policy"
              >
                www.braintreepayments.com/legal/braintree-privacy-policy
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://recurly.com/legal/privacy"
              >
                www.recurly.com/legal/privacy
              </a>
              .
            </li>
            <li>
              <strong>Transaction Data:</strong> includes details about payments
              to and from you and other details of products and services you
              have purchased from us.
            </li>
            <li>
              <strong>Technical Data:</strong> includes internet protocol (IP)
              address, your login data, browser type and version, time zone
              setting and location, browser plug-in types and versions,
              operating system and platform, and other technology on the devices
              you use to access this website.
            </li>
            <li>
              <strong>Profile Data:</strong> includes your username and
              password, purchases or orders made by you, your interests,
              preferences, feedback and survey responses.
            </li>
            <li>
              <strong>Usage Data:</strong> includes information about how you
              use our website, products and services.
            </li>
            <li>
              <strong>Marketing and Communications Data</strong> includes your
              preferences in receiving marketing from us and our third parties
              and your communication preferences.
            </li>
          </ul>

          <p>
            We also collect, use and share <strong>Aggregated Data</strong> such
            as statistical or demographic data for any purpose. Aggregated Data
            could be derived from your personal data but is not considered
            personal data in law as this data will not directly or indirectly
            reveal your identity. For example, we may aggregate your Usage Data
            to calculate the percentage of users accessing a specific website
            feature. However, if we combine or connect Aggregated Data with your
            personal data so that it can directly or indirectly identify you, we
            treat the combined data as personal data which will be used in
            accordance with this privacy policy.
          </p>

          <p>
            We do not collect any{" "}
            <strong>Special Categories of Personal Data</strong> about you (this
            includes details about your race or ethnicity, religious or
            philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health, and
            genetic and biometric data). Nor do we collect any information about
            criminal convictions and offences.
          </p>

          <h3>If you fail to provide personal data</h3>

          <p>
            Where we need to collect personal data by law, or under the terms of
            a contract we have with you, and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are
            trying to enter into with you (for example, to provide you with
            goods or services). In this case, we may have to cancel a product or
            service you have with us but we will notify you if this is the case
            at the time.
          </p>

          <h2 id="3">How is Your Personal Data Collected</h2>

          <p>
            We use different methods to collect data from and about you
            including through:
          </p>

          <ul>
            <li>
              <strong>Direct interactions</strong>. You may give us your
              Identity and Contact Data by filling in forms or by corresponding
              with us by post, phone, email or otherwise. This includes personal
              data you provide when you:
            </li>
            <ul>
              <li>apply for our products or services;</li>
              <li>create an account on our website;</li>
              <li>subscribe to our service or publications;</li>
              <li>request marketing to be sent to you; or</li>
              <li>give us feedback or contact us.</li>
            </ul>

            <li>
              <strong>Automated technologies or interactions</strong>. As you
              interact with our website, we will automatically collect Technical
              Data about your equipment, browsing actions and patterns. We
              collect this personal data by using, server logs and other similar
              technologies. Whitespace does not use cookies.{" "}
            </li>

            <li>
              Third parties or publicly available sources. We will receive
              personal data about you from various third parties and public
              sources as set out below:
            </li>
            <ul>
              <li>Technical Data from the following parties:</li>

              <ul>
                <li>
                  analytics providers such as Google based outside the EU;{" "}
                </li>
              </ul>
            </ul>

            <li>
              Identity and Contact Data from publicly available sources such as
              Companies House and the Electoral Register based inside the EU.
            </li>

            <li>
              Identity and Contact Data from existing users on the Colinked
              platform who may recommend you or you company to join the Colinked
              platform.
            </li>
          </ul>

          <h2 id="4">How We Use Your Personal Data</h2>

          <p>
            We will only use your personal data when the law allows us to. Most
            commonly, we will use your personal data in the following
            circumstances:
          </p>

          <ul>
            <li>
              Where we need to perform the contract we are about to enter into
              or have entered into with you.
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests.
            </li>
            <li>Where we need to comply with a legal obligation.</li>
          </ul>

          <p>
            Scroll down to the Glossary to find out more about the types of
            lawful basis that we will rely on to process your personal data.
          </p>

          <p>
            Generally, we do not rely on consent as a legal basis for processing
            your personal data although we will get your consent before sending
            third party direct marketing communications to you via email or text
            message. You have the right to withdraw consent to marketing at any
            time by contacting us.
          </p>

          <h3>Purposes for which we will use your personal data</h3>

          <p>
            We have set out below, in a table format, a description of all the
            ways we plan to use your personal data, and which of the legal bases
            we rely on to do so. We have also identified what our legitimate
            interests are where appropriate.
          </p>

          <p>
            Note that we may process your personal data for more than one lawful
            ground depending on the specific purpose for which we are using your
            data. Please contact us if you need details about the specific legal
            ground we are relying on to process your personal data where more
            than one ground has been set out in the table below.{" "}
          </p>

          <table className="tg">
            <tbody>
              <tr>
                <th className="tg-s268">
                  <span style={{ fontWeight: "bold" }}>Purpose/Activity</span>
                </th>
                <th className="tg-s268">
                  <span style={{ fontWeight: "bold" }}>Type of data</span>
                </th>
                <th className="tg-s268">
                  <span style={{ fontWeight: "bold" }}>
                    Lawful basis for processing including basis of legitimate
                    interest
                  </span>
                </th>
              </tr>
              <tr>
                <td className="tg-s268">To register you as a new customer</td>
                <td className="tg-s268">
                  (a) Identity <br />
                  (b) Contact
                  <br />
                </td>
                <td className="tg-s268">Performance of a contract with you</td>
              </tr>
              <tr>
                <td className="tg-s268">
                  To process and deliver your order including:
                  <br />
                  <br />
                  (a) Manage payments, fees and charges
                  <br />
                  (b) Collect and recover money owed to us
                </td>
                <td className="tg-s268">
                  (a) Identity <br />
                  (b) Contact
                  <br />
                  (c) Transaction <br />
                  (d) Marketing and Communications
                </td>
                <td className="tg-s268">
                  (a) Performance of a contract with you
                  <br />
                  (b) Necessary for our legitimate interests (to recover debts
                  due to us)
                </td>
              </tr>
              <tr>
                <td className="tg-0lax">
                  To manage our relationship with you which will include:
                  <br />
                  <br />
                  (a) Notifying you about changes to our terms or privacy policy
                  <br />
                  (b) Asking you to leave a review or take a survey
                </td>
                <td className="tg-0lax">
                  (a) Identity <br />
                  (b) Contact <br />
                  (c) Profile <br />
                  (d) Marketing and Communications
                </td>
                <td className="tg-0lax">
                  (a) Performance of a contract with you <br />
                  (b) Necessary to comply with a legal obligation
                  <br />
                  (c) Necessary for our legitimate interests (to keep our
                  records updated and to study how customers use our
                  products/services)
                </td>
              </tr>
              <tr>
                <td className="tg-0lax">
                  To enable you to partake in a prize draw, competition or
                  complete a survey
                </td>
                <td className="tg-0lax">
                  (a) Identity <br />
                  (b) Contact <br />
                  (c) Profile <br />
                  (d) Usage
                  <br />
                  (e) Marketing and Communications
                </td>
                <td className="tg-0lax">
                  (a) Performance of a contract with you <br />
                  (b) Necessary for our legitimate interests (to study how
                  customers use our products/services, to develop them and grow
                  our business)
                </td>
              </tr>
              <tr>
                <td className="tg-0lax">
                  To administer and protect our business and this website
                  (including troubleshooting, data analysis, testing, system
                  maintenance, support, reporting and hosting of data)
                </td>
                <td className="tg-0lax">
                  (a) Identity
                  <br />
                  (b) Contact
                  <br />
                  (c) Technical
                </td>
                <td className="tg-0lax">
                  (a) Necessary for our legitimate interests (for running our
                  business, provision of administration and IT services, network
                  security, to prevent fraud and in the context of a business
                  reorganisation or group restructuring exercise)
                  <br />
                  (b) Necessary to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td className="tg-0lax">
                  To deliver relevant website content and advertisements to you
                  and measure or understand the effectiveness of the advertising
                  we serve to you
                </td>
                <td className="tg-0lax">
                  (a) Identity <br />
                  (b) Contact <br />
                  (c) Profile <br />
                  (d) Usage <br />
                  (e) Marketing and Communications
                  <br />
                  (f) Technical
                </td>
                <td className="tg-0lax">
                  Necessary for our legitimate interests (to study how customers
                  use our products/services, to develop them, to grow our
                  business and to inform our marketing strategy)
                </td>
              </tr>
              <tr>
                <td className="tg-0lax">
                  To use data analytics to improve our website,
                  products/services, marketing, customer relationships and
                  experiences
                </td>
                <td className="tg-0lax">
                  (a) Technical <br />
                  (b) Usage
                </td>
                <td className="tg-0lax">
                  Necessary for our legitimate interests (to define types of
                  customers for our products and services, to keep our website
                  updated and relevant, to develop our business and to inform
                  our marketing strategy)
                </td>
              </tr>
              <tr>
                <td className="tg-0lax">
                  To make suggestions and recommendations to you about goods or
                  services that may be of interest to you
                </td>
                <td className="tg-0lax">
                  (a) Identity <br />
                  (b) Contact <br />
                  (c) Technical <br />
                  (d) Usage siness
                  <br />
                  (e) Profile <br />
                  (f) Marketing and Communications
                </td>
                <td className="tg-0lax">
                  Necessary for our legitimate interests (to develop our
                  products/services and grow our bu)
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Promotional offers from us</h3>

          <p>
            We may use your Identity, Contact, Technical, Usage and Profile Data
            to form a view on what we think you may want or need, or what may be
            of interest to you. This is how we decide which products, services
            and offers may be relevant for you (we call this marketing).{" "}
          </p>

          <p>
            You will receive marketing communications from us if you have
            requested information from us or purchased goods or services from us
            and you have not opted out of receiving that marketing.
          </p>

          <h3>Third-party marketing </h3>

          <p>
            We will get your express opt-in consent before we share your
            personal data with any third party for marketing purposes.{" "}
          </p>

          <h3>Opting out</h3>

          <p>
            You can ask us or third parties to stop sending you marketing
            messages at any time by contacting us at any time.
          </p>

          <p>
            Where you opt out of receiving these marketing messages, this will
            not apply to personal data provided to us as a result of a
            product/service purchase, product/service experience or other
            transactions.
          </p>

          <h3>Cookies</h3>

          <p>
            Whitespace does not use cookies within the Colinked however where
            you click on any links to third-party websites, plug-ins and
            applications accessible through Colinked, those third party websites
            may use cookies and you should read the applicable cookie policy in
            relation to the use of cookies by that third party.
          </p>

          <h3>Change of purpose </h3>

          <p>
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to get an explanation as to how the processing
            for the new purpose is compatible with the original purpose, please
            contact us.{" "}
          </p>

          <p>
            If we need to use your personal data for an unrelated purpose, we
            will notify you and we will explain the legal basis which allows us
            to do so.
          </p>

          <p>
            Please note that we may process your personal data without your
            knowledge or consent, in compliance with the above rules, where this
            is required or permitted by law.
          </p>

          <h2 id="5">Disclosures of Your Personal Data</h2>

          <p>
            We may share your personal data with the parties set out below for
            the purposes set out in the table{" "}
            <em>Purposes for which we will use your personal data</em> above.
          </p>

          <ul>
            <li>
              Internal Third Parties as set out in the <em>Glossary</em> below
            </li>
            <li>
              External Third Parties as set out in the <em>Glossary</em> below
            </li>
            <li>
              Third parties to whom we may choose to sell, transfer or merge
              parts of our business or our assets. Alternatively, we may seek to
              acquire other businesses or merge with them. If a change happens
              to our business, then the new owners may use your personal data in
              the same way as set out in this privacy policy.
            </li>
          </ul>

          <p>
            We require all third parties to respect the security of your
            personal data and to treat it in accordance with the law. We do not
            allow our third-party service providers to use your personal data
            for their own purposes and only permit them to process your personal
            data for specified purposes and in accordance with our instructions.
          </p>

          <h2 id="6">International Transfers</h2>

          <p>
            Many of our external third parties are based outside the EEA so
            their processing of your personal data will involve a transfer of
            data outside the EEA.
          </p>

          <p>
            Whenever we transfer your personal data out of the EEA, we ensure a
            similar degree of protection is afforded to it by ensuring at least
            one of the following safeguards is implemented:
          </p>

          <ul>
            <li>
              We will only transfer your personal data to countries that have
              been deemed to provide an adequate level of protection for
              personal data by the European Commission. For further details, see
              European Commission: Adequacy of the protection of personal data
              in non-EU countries.
            </li>

            <li>
              Where we use certain service providers, we may use specific
              contracts approved by the European Commission which give personal
              data the same protection it has in Europe. For further details,
              see European Commission: Model contracts for the transfer of
              personal data to third countries.
            </li>

            <li>
              Where we use providers based in the US, we may transfer data to
              them if they are part of the Privacy Shield which requires them to
              provide similar protection to personal data shared between Europe
              and the US. For further details, see European Commission: EU-US
              Privacy Shield.
            </li>

            <li>
              Please contact us if you want further information on the specific
              mechanism used by us when transferring your personal data out of
              the EEA.{" "}
            </li>
          </ul>

          <h2 id="7">Data security</h2>

          <p>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject
            to a duty of confidentiality.{" "}
          </p>

          <p>
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </p>

          <h2 id="8">Data retention</h2>

          <h3>How long will you use my personal data for?</h3>

          <p>
            We will only retain your personal data for as long as reasonably
            necessary to fulfil the purposes we collected it for, including for
            the purposes of satisfying any legal, regulatory, tax, accounting or
            reporting requirements. We may retain your personal data for a
            longer period in the event of a complaint or if we reasonably
            believe there is a prospect of litigation in respect to our
            relationship with you.
          </p>

          <p>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature and sensitivity of the personal data,
            the potential risk of harm from unauthorised use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal, regulatory, tax, accounting or other
            requirements.
          </p>

          <p>
            By law we have to keep basic information about our customers
            (including Contact, Identity and Transaction Data) for six years
            after they cease being customers for tax purposes.
          </p>

          <p>
            In some circumstances you can ask us to delete your data: see{" "}
            <a href="#9">Your Legal Rights</a> below for further information.
          </p>

          <p>
            In some circumstances we will anonymise your personal data (so that
            it can no longer be associated with you) for research or statistical
            purposes, in which case we may use this information indefinitely
            without further notice to you.{" "}
          </p>

          <h2 id="9">Your legal rights</h2>

          <p>
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data. Please click on the links
            below to find out more about these rights:{" "}
          </p>

          <ul>
            <li>
              <a
                href="https://ico.org.uk/your-data-matters/your-right-of-access/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ico.org.uk/your-data-matters/your-right-of-access/{" "}
              </a>
            </li>
            <li>
              <a
                href="https://ico.org.uk/your-data-matters/your-right-to-get-your-data-corrected/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ico.org.uk/your-data-matters/your-right-to-get-your-data-corrected/{" "}
              </a>
            </li>
            <li>
              <a
                href="https://ico.org.uk/your-data-matters/your-right-to-get-your-data-deleted/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ico.org.uk/your-data-matters/your-right-to-get-your-data-deleted/{" "}
              </a>
            </li>
            <li>
              <a
                href="https://ico.org.uk/your-data-matters/the-right-to-object-to-the-use-of-your-data/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ico.org.uk/your-data-matters/the-right-to-object-to-the-use-of-your-data/
              </a>
            </li>
            <li>
              <a
                href="https://ico.org.uk/your-data-matters/your-right-to-limit-how-organisations-use-your-data/  "
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ico.org.uk/your-data-matters/your-right-to-limit-how-organisations-use-your-data/
              </a>
            </li>
            <li>
              <a
                href="https://ico.org.uk/your-data-matters/your-right-to-data-portability/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ico.org.uk/your-data-matters/your-right-to-data-portability/
              </a>
            </li>
            <li>
              <a
                href="https://ico.org.uk/your-data-matters/your-rights-relating-to-decisions-being-made-about-you-without-human-involvement/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ico.org.uk/your-data-matters/your-rights-relating-to-decisions-being-made-about-you-without-human-involvement/
              </a>
            </li>
          </ul>

          <p>
            If you wish to exercise any of the rights set out above, please
            contact us.
          </p>

          <h3>No fee usually required</h3>

          <p>
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive. Alternatively, we could refuse to comply with your
            request in these circumstances.
          </p>

          <h3>What we may need from you</h3>

          <p>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.
          </p>

          <h3>Time limit to respond</h3>

          <p>
            We try to respond to all legitimate requests within one month.
            Occasionally it could take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.{" "}
          </p>

          <h2 id="10">Glossary</h2>

          <h3>Lawful Basis</h3>

          <p>
            <strong>Legitimate Interest</strong> means the interest of our
            business in conducting and managing our business to enable us to
            give you the best service/product and the best and most secure
            experience. We make sure we consider and balance any potential
            impact on you (both positive and negative) and your rights before we
            process your personal data for our legitimate interests. We do not
            use your personal data for activities where our interests are
            overridden by the impact on you (unless we have your consent or are
            otherwise required or permitted to by law). You can obtain further
            information about how we assess our legitimate interests against any
            potential impact on you in respect of specific activities by
            contacting us.
          </p>

          <p>
            <strong>Performance of Contract</strong> means processing your data
            where it is necessary for the performance of a contract to which you
            are a party or to take steps at your request before entering into
            such a contract.
          </p>

          <p>
            <strong>Comply with a legal obligation</strong> means processing
            your personal data where it is necessary for compliance with a legal
            obligation that we are subject to.
          </p>

          <h3>Third Parties</h3>

          <p>
            <strong>Internal Third Parties:</strong> Other companies in the
            Whitespace Group and who are based within the EEA.
          </p>

          <p>
            <strong>External Third Parties:</strong>
          </p>

          <ul>
            <li>
              Google Analytics – used to provide Whitespace with analytics and
              user journeys. You can find their Privacy Policy here:{" "}
              <a
                target="_black"
                href="https://policies.google.com/privacy?hl=en"
              >
                https://policies.google.com/privacy?hl=en
              </a>
            </li>

            <li>
              Microsoft Azure (West Europe) – used to store your data. You can
              find their Privacy Policy here:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://privacy.microsoft.com/en-gb/privacystatement"
              >
                https://privacy.microsoft.com/en-gb/privacystatement
              </a>
            </li>

            <li>
              FeatureUpvote – used to determine your feedback. You can find
              their Privacy Policy here:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://featureupvote.com/privacypolicy/"
              >
                https://featureupvote.com/privacypolicy/
              </a>
            </li>

            <li>
              SendGrid – used to send out emails to you. You can find their
              Privacy Policy here:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://sendgrid.com/policies/privacy/"
              >
                https://sendgrid.com/policies/privacy/
              </a>
            </li>

            <li>
              Braintree – used to collect payments. You can find their Privacy
              Policy here:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.paypal.com/uk/webapps/mpp/ua/privacy-full"
              >
                https://www.paypal.com/uk/webapps/mpp/ua/privacy-full
              </a>
            </li>

            <li>
              Recurly – use to manage billing and subscriptions. You can find
              their Privacy Policy here:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://recurly.com/legal/privacy"
              >
                https://recurly.com/legal/privacy
              </a>
            </li>

            <li>
              Hotjar - used to collect analytics and act as a way to provide
              feedback. You can find their privacy policy here:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hotjar.com/legal/policies/privacy"
              >
                https://www.hotjar.com/legal/policies/privacy
              </a>
            </li>

            <li>
              HM Revenue &amp; Customs, regulators and other authorities based
              in the United Kingdom who require reporting of processing
              activities in certain circumstances. You can find their Privacy
              Policy here:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.gov.uk/government/publications/data-protection-act-dpa-information-hm-revenue-and-customs-hold-about-you"
              >
                https://www.gov.uk/government/publications/data-protection-act-dpa-information-hm-revenue-and-customs-hold-about-you
              </a>
            </li>
          </ul>

          <h3>Your Legal Rights</h3>

          <p>You have the right to:</p>

          <ul>
            <li>
              <strong>Request access</strong> to your personal data (commonly
              known as a "data subject access request"). This enables you to
              receive a copy of the personal data we hold about you and to check
              that we are lawfully processing it.
            </li>

            <li>
              <strong>Request correction</strong> of the personal data that we
              hold about you. This enables you to have any incomplete or
              inaccurate data we hold about you corrected, though we may need to
              verify the accuracy of the new data you provide to us.
            </li>

            <li>
              <strong>Request erasure</strong> of your personal data. This
              enables you to ask us to delete or remove personal data where
              there is no good reason for us continuing to process it. You also
              have the right to ask us to delete or remove your personal data
              where you have successfully exercised your right to object to
              processing (see below), where we may have processed your
              information unlawfully or where we are required to erase your
              personal data to comply with local law. Note, however, that we may
              not always be able to comply with your request of erasure for
              specific legal reasons which will be notified to you, if
              applicable, at the time of your request.{" "}
            </li>

            <li>
              <strong>Object to processing</strong> of your personal data where
              we are relying on a legitimate interest (or those of a third
              party) and there is something about your particular situation
              which makes you want to object to processing on this ground as you
              feel it impacts on your fundamental rights and freedoms. You also
              have the right to object where we are processing your personal
              data for direct marketing purposes. In some cases, we may
              demonstrate that we have compelling legitimate grounds to process
              your information which override your rights and freedoms.
            </li>

            <li>
              <strong>Request restriction of processing</strong> of your
              personal data. This enables you to ask us to suspend the
              processing of your personal data in the following scenarios:{" "}
            </li>

            <ul>
              <li>If you want us to establish the data's accuracy.</li>
              <li>
                Where our use of the data is unlawful but you do not want us to
                erase it.
              </li>
              <li>
                Where you need us to hold the data even if we no longer require
                it as you need it to establish, exercise or defend legal claims.
              </li>
              <li>
                You have objected to our use of your data but we need to verify
                whether we have overriding legitimate grounds to use it.{" "}
              </li>
            </ul>

            <li>
              <strong>Request the transfer</strong> of your personal data to you
              or to a third party. We will provide to you, or a third party you
              have chosen, your personal data in a structured, commonly used,
              machine-readable format. Note that this right only applies to
              automated information which you initially provided consent for us
              to use or where we used the information to perform a contract with
              you.{" "}
            </li>

            <li>
              <strong>Withdraw consent</strong> at any time where we are relying
              on consent to process your personal data. However, this will not
              affect the lawfulness of any processing carried out before you
              withdraw your consent. If you withdraw your consent, we may not be
              able to provide certain products or services to you. We will
              advise you if this is the case at the time you withdraw your
              consent.
            </li>
          </ul>
        </section>
      </section>

      <section className="call-to-action">
        <div className="container">
          <div className="content">
            <h2>Our Valued Members</h2>
            <p>
              In our beta, we signed up some of the biggest corporates and
              brightest founder teams, you can join our movement here:
            </p>
            <button className="btn">For Corporates</button>{" "}
            <button className="btn">For Founders</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
