import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import {
  ProgramsListPlaceholder,
  ThinkTanksListPlaceholder
} from "../DocumentsListView/DocumentsListPlaceholder";

import * as PROPTYPES from "../../propTypes";

import LoadingPanel from "../../common/LoadingPanel";

import GridCard from "./GridCard";

const styles = {
  root: {
    // marginTop: 30,
    width: "100%",
    overflow: "visible",
    // boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  cardRoot_xl: {
    marginRight: 20,
    "&:nth-child(4n)": {
      marginRight: 0
    }
  },
  cardRoot_lg: {
    marginRight: 20,
    "&:nth-child(4n)": {
      marginRight: 0
    }
  },
  cardRoot_md: {
    marginRight: 0
  },
  cardRoot_sm: {
    marginRight: 0
  }
};

const getContainerStyle = width => {
  if (width === "lg" || width === "xl") {
    return {
      justifyContent: "flex-start",
      width: 1260,
      marginLeft: "auto",
      marginRight: "auto"
    };
  }

  if (width === "xs") {
    return {
      justifyContent: "center"
    };
  }

  return {
    justifyContent: "space-between",
    width: width === "md" ? 940 : 620,
    marginLeft: "auto",
    marginRight: "auto"
  };
};

const getPlaceholder = doctype => {
  if (doctype === "program") return ProgramsListPlaceholder;
  if (doctype === "challenge") return ThinkTanksListPlaceholder;

  return null;
};

const renderCards = props => {
  const { CardComponent, classes, documents, width } = props;

  const rootClassName = `cardRoot_${width}`;
  const rootClass = classes[rootClassName];
  const _classes = rootClass ? { root: rootClass } : {};

  return documents.map(document => (
    <CardComponent key={document.id} classes={_classes} document={document} />
  ));
};

const DocumentsGridView = props => {
  const { classes, doctype, documents, loading, style, width } = props;

  const EmptyState = props.EmptyState || getPlaceholder(doctype);

  if (!documents.length && EmptyState) {
    return <EmptyState />;
  }

  const containerStyle = {
    ...style,
    ...getContainerStyle(width)
  };

  return (
    <div className={classes.root} style={containerStyle}>
      {renderCards(props)}
      {loading && <LoadingPanel />}
    </div>
  );
};

DocumentsGridView.propTypes = {
  CardComponent: PropTypes.func.isRequired,
  EmptyState: PropTypes.func,
  classes: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  style: PropTypes.object.isRequired,
  width: PROPTYPES.width
};

DocumentsGridView.defaultProps = {
  CardComponent: GridCard,
  style: {}
};

export default withWidth()(withStyles(styles)(DocumentsGridView));
