import React from "react";

import RegistrationForm from "../components/Registration/RegistrationForm";
import pages from "../components/Registration/userFormSections";

import PageTemplate from "./PageTemplate";

const RegistrationCompany = props => {
  return (
    <PageTemplate>
      <RegistrationForm
        {...props}
        pages={pages}
        url="/api/managers/register/"
      />
    </PageTemplate>
  );
};

export default RegistrationCompany;
