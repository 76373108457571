import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import {
  getOutlineButtonClasses,
  getPrimaryButtonClasses
} from "../../styles/buttonStyles";

import Icons from "../Icons";

const { iconClass, ...exitButtonStyles } = getOutlineButtonClasses("#ffffff");
const ExitButton = withStyles(exitButtonStyles)(Button);

const {
  iconClass: iconClassPublish,
  ...publishButtonStyles
} = getPrimaryButtonClasses("#ffffff", "rgba(133,201,124)");
const PublishButton = withStyles(publishButtonStyles)(Button);

const styles = {
  root: {
    height: 100
  },
  container: {
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "white"
  },
  icon: {
    marginRight: 5
  },
  sticky: {
    backgroundColor: "rgba(133,201,124,0.9)",
    position: "fixed",
    top: 0,
    width: "100vw",
    zIndex: 2
  },
  title: {
    color: "white",
    fontSize: 22,
    fontWeight: 600
  },
  wrapper: {
    backgroundColor: "rgba(133,201,124,0.9)",
    width: "100vw"
  }
};

const renderHeader = props => {
  const {
    classes,
    doctype,
    exitPreview,
    isComplete,
    publish,
    publishLabel
  } = props;

  const title =
    props.title || doctype === "program"
      ? "Preview Program"
      : doctype === "challenge"
        ? "Preview Think Tank"
        : "Preview";

  return (
    <div className={`body ${classes.container}`}>
      <ExitButton onClick={exitPreview}>
        <Icons.ArrowLeft style={iconClass} />
        Return to Edit
      </ExitButton>

      <div className={classes.title}>{title}</div>

      {publish ? (
        <PublishButton disabled={!isComplete} onClick={publish}>
          <Icons.CloudUpload className={classes.icon} />
          {publishLabel}
        </PublishButton>
      ) : (
        <div style={{ width: 142 }} />
      )}
    </div>
  );
};

const PreviewHeader = props => {
  const { classes, id, sticky } = props;

  const wrapperClass = sticky ? classes.sticky : classes.wrapper;
  return (
    <div className={classes.root} id={id}>
      <div className={wrapperClass}>{renderHeader(props)}</div>
    </div>
  );
};

PreviewHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  doctype: PropTypes.string,
  exitPreview: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isComplete: PropTypes.bool,
  publish: PropTypes.func,
  publishLabel: PropTypes.string.isRequired,
  title: PropTypes.string
};

PreviewHeader.defaultProps = {
  id: "PreviewHeader",
  isComplete: false,
  publishLabel: "Publish",
  sticky: false,
  title: ""
};

export { PreviewHeader, styles };
export default withStyles(styles)(PreviewHeader);
