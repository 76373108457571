import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import MoreIcon from "@material-ui/icons/MoreHoriz";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import SubMenuButton from "../common/Buttons/SubMenuButton";

import PreviewButton from "../common/Buttons/PreviewButton";
import PublishButton from "../common/Buttons/PublishButton";
import SaveDraftButton from "../common/Buttons/SaveDraftButton";

import Icons from "../Icons";

const styles = {
  root: {
    backgroundColor: "white",
    height: 80
  },
  backlink: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    marginLeft: 10,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  backlinkIcon: {
    fontSize: 20,
    position: "relative",
    top: "4.5px"
  },
  container: {
    display: "flex",
    alignItems: "center",
    height: 80,
    width: 1160,
    margin: "0 auto"
  }
};

const getDoctypeLable = doctype => {
  if (doctype === "program") return "Program";
  if (doctype === "challenge") return "Think Tank";

  return doctype;
};

const getDiscardLable = document => {
  const doctypeLabel = getDoctypeLable(document.doctype);

  if (document._isUnsavedDraft) {
    return `Exit and discard draft ${doctypeLabel}`;
  }

  if (document._hasUnsavedChanges) {
    return "Exit and discard changes";
  }

  return `Return to ${document.doctype} details`;
};

const renderBacklink = props => {
  const { classes, document, handleDiscard } = props;

  if (!handleDiscard) return null;

  return (
    <div>
      <Icons.ArrowLeft className={classes.backlinkIcon} />
      <span className={classes.backlink} onClick={handleDiscard}>
        {getDiscardLable(document)}
      </span>
    </div>
  );
};

const getStyle = width => {
  if (width === "md") return { width: 1000 };
  if (width === "sm") return { width: "90vw" };

  return {};
};

// handle save-as-draft: only if not published and not a copmany profile
const renderSaveDraft = props => {
  const { document, handleSave } = props;
  const { doctype, _hasUnsavedChanges, is_approved } = document;

  if (doctype !== "program" && doctype !== "challenge") return null;
  if (is_approved === 2) return null;

  return (
    <SaveDraftButton
      onClick={handleSave}
      disabled={Boolean(!_hasUnsavedChanges)}
    />
  );
};

// handlePublish if doc is not yet approved and not a company
const renderPublish = props => {
  const { document, handlePublish } = props;
  const { doctype, is_approved, isCompleted } = document;

  if (doctype !== "program" && doctype !== "challenge") return null;
  if (is_approved === 2) return null;

  return (
    <PublishButton
      label="Publish"
      onClick={handlePublish}
      disabled={Boolean(!isCompleted)}
    />
  );
};

const renderSubMenu = props => {
  const { handleDelete, handleDuplicate } = props;
  const { doctype } = props.document;

  const actions = [];

  if (handleDelete) {
    actions.push({
      label: `Delete ${doctype}`,
      action: handleDelete
    });
  }

  if (handleDuplicate) {
    actions.push({
      label: "Duplicate and save as new draft",
      action: handleDuplicate
    });
  }

  if (!actions.length) return null;

  return (
    <SubMenuButton actions={actions} menuWidth={260}>
      <MoreIcon /> <ExpandMoreIcon />
    </SubMenuButton>
  );
};

const renderUpdate = props => {
  const { document, handleUpdate } = props;
  const { doctype, is_approved, _isValid, _hasUnsavedChanges } = document;

  if (doctype === "registration") return null;
  if (is_approved !== 2) return null;

  return (
    <PublishButton
      label="Update"
      onClick={handleUpdate}
      disabled={!Boolean(_isValid && _hasUnsavedChanges)}
    />
  );
};

const FormBuilderMenu = props => {
  const { classes, menuId, width } = props;

  // disable preview option if no PreviewComponent is available
  const handlePreview = props.PreviewComponent ? props.handlePreview : null;

  return (
    <div className={classes.root}>
      <div className={classes.container} style={getStyle(width)} id={menuId}>
        {renderBacklink(props)}

        <div style={{ flexGrow: 1 }} />
        {renderSubMenu(props)}

        {renderSaveDraft(props)}
        {handlePreview && <PreviewButton onClick={handlePreview} />}
        {renderPublish(props)}
        {renderUpdate(props)}
      </div>
    </div>
  );
};

FormBuilderMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.shape({
    doctype: PropTypes.string.isRequired,
    _hasUnsavedChanges: PropTypes.bool,
    _isUnsavedDraft: PropTypes.bool,
    isCompleted: PropTypes.bool,
    is_approved: PropTypes.number.isRequired
  }).isRequired,
  handleDiscard: PropTypes.func,
  handlePreview: PropTypes.func,
  handlePublish: PropTypes.func,
  handleSave: PropTypes.func,
  handleUpdate: PropTypes.func,
  id: PropTypes.string.isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

FormBuilderMenu.defaultProps = {
  document: {
    doctype: "",
    is_approved: 1
  },
  id: "formbuilder-menu"
};

export { FormBuilderMenu, styles };
export default withStyles(styles)(FormBuilderMenu);
