import {
  ADD_APPLICATIONS,
  REMOVE_APPLICATION,
  SET_APPLICATIONS_LOADING_STATE,
  UPDATE_APPLICATION,
  UPDATE_APPLICATIONS
} from "./ApplicationsActions";

const mergeAppLists = (oldList, newList) => {
  const merged = [...oldList, ...newList].reduce((merged, app) => {
    merged[app.url] = app;

    return merged;
  }, {});

  return Object.values(merged).sort((a, b) => a.url < b.url);
};

const updateApplication = (applications, update) => {
  if (!update.url) console.error("No application provided");

  return applications.map(application => {
    return application.url === update.url
      ? { ...application, ...update }
      : application;
  });
};

const updateApps = (oldList, newList) => {
  const updated = [...oldList, ...newList].reduce((updated, app) => {
    if (updated[app.url]) {
      updated[app.url] = { ...updated[app.url], ...app };
    } else {
      updated[app.url] = app;
    }

    return updated;
  }, {});

  return Object.values(updated).sort((a, b) => a.url < b.url);
};

export default (state = { loadingState: {}, applications: [] }, action) => {
  switch (action.type) {
    case ADD_APPLICATIONS:
      return {
        ...state,
        applications: mergeAppLists(state.applications, action.payload)
      };

    case REMOVE_APPLICATION:
      return {
        ...state,
        applications: state.applications.filter(
          app => app.url !== action.payload
        )
      };

    case SET_APPLICATIONS_LOADING_STATE:
      const { documentUrl, value } = action.payload;
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [documentUrl]: value
        }
      };

    case UPDATE_APPLICATION:
      return {
        ...state,
        applications: updateApplication(state.applications, action.payload)
      };

    case UPDATE_APPLICATIONS:
      return {
        ...state,
        applications: updateApps(state.applications, action.payload)
      };

    default:
      return state;
  }
};
