import { LIGHT_GREY } from "../../../styles/colors";

const styles = {
  root: {
    width: "100%",
    overflowX: "auto",
    marginBottom: 30
  },
  details: {
    margin: "20px 3px 10px 3px"
  },
  editForm: {
    margin: "10px 5px",
    padding: 0
  },
  formContainer: {
    marginTop: 20
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    cursor: "pointer",
    marginLeft: 10
  },
  iconContainer: {
    display: "flex",
    alignItems: "top",
    marginLeft: 20
  },
  testimonial: {
    border: `1px solid ${LIGHT_GREY}`,
    padding: "5px 10px",
    paddingLeft: 10,
    marginTop: 15
  }
};

export default styles;
