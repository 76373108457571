import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core";

import Icons from "../../Icons";

import TextField from "./TextField";
import globalStyles from "./styles";

const styles = {
  root: globalStyles.optionsRoot,
  addButton: globalStyles.addOptionButton,
  icon: globalStyles.icon,
  option: globalStyles.option
};

const Option = withStyles(styles)(props => {
  const { classes, option, index, onChange, onDelete } = props;

  return (
    <div className={classes.option}>
      <TextField
        key={`key_${index}`}
        label={`Option ${index + 1}`}
        value={option}
        onChange={event => onChange(event.target.value.replace(/\n/g, ""))}
      />
      <Icons.TrashCanOutline
        className={classes.icon}
        style={{ marginBottom: 10 }}
        onClick={onDelete}
      />
    </div>
  );
});

const QuestionOptions = props => {
  const { classes, options, onChange } = props;

  if (!options || !options.length) return null;

  const optionFields = options.map((option, idx) => (
    <Option
      key={idx}
      option={option}
      index={idx}
      onChange={value => {
        onChange(options.map((opt, _idx) => (_idx === idx ? value : opt)));
      }}
      onDelete={() => onChange(options.filter((o, _idx) => _idx !== idx))}
    />
  ));

  return (
    <div className={classes.root}>
      {optionFields}
      <div
        className={classes.addButton}
        onClick={() => onChange([...options, ""])}
      >
        <Icons.PlusCircleOutline style={{ fontSize: 20, marginRight: 5 }} />
        Add Option
      </div>
    </div>
  );
};

QuestionOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array,
  index: PropTypes.number.isRequired
};

QuestionOptions.defaultProps = {
  onChange: () => {}
};

export { QuestionOptions, styles };
export default withStyles(styles)(QuestionOptions);
