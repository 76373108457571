import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Controls from "./Controls";

import formStyles from "../../../styles/formStyles";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",

    padding: 20,
    border: "2px solid #E6E7E9",
    fontSize: 13,
    //color: "#AAADB6",
    fontWeight: 300,
    position: "relative",

    marginBottom: 20
  },
  content: {
    height: 38,
    border: "1px solid #AAADB6",
    lineHeight: "38px",
    paddingLeft: 15
  },
  label: formStyles.label,
  type: {
    color: "#AAADB6",
    fontSize: "12px",
    fontWeight: 600,
    marginBottom: 15,
    textTransform: "uppercase"
  }
};

const contentStyles = {};

const BaseFrame = props => {
  const { classes, content, deleteFrame, moveDown, moveUp, type } = props;

  const controlProps = {
    deleteFrame,
    moveDown,
    moveUp
  };

  return (
    <div className={classes.root}>
      <Controls {...controlProps} />

      <div className={classes.type}>{type}</div>
      <div className={classes.content}>{content}</div>
    </div>
  );
};

BaseFrame.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteFrame: PropTypes.func.isRequired,
  moveDown: PropTypes.func,
  moveUp: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["heading", "richtext", "image", "video", "file"])
    .isRequired
};

export { BaseFrame, styles, contentStyles };
export default withStyles(styles)(BaseFrame);
