import React from "react";
import { PropTypes } from "prop-types";

import MUIButton from "@material-ui/core/Button";

const classes = {
  root: {},
  disabled: {},
  label: {},
  iconClass: {}
};

const Button = props => {
  const {
    classes,
    dispatch,
    IconLeft,
    IconRight,
    label,
    children,
    ...other
  } = props;
  const { iconClass, ...buttonClasses } = classes;

  return (
    <MUIButton classes={buttonClasses} {...other}>
      {IconLeft && <IconLeft className={iconClass} />}
      {label || children}
      {IconRight && <IconRight className={iconClass} />}
    </MUIButton>
  );
};

Button.propsTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  IconLeft: PropTypes.func,
  IconRight: PropTypes.func
};

Button.defaultProps = {
  classes,
  label: "",
  IconLeft: null,
  IconRight: null
};

export default Button;
