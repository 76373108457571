import React from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import { BLUE, BODY_FONT_BLACK, GREY_DARK } from "../../../styles/colors";

const pageLink = {
  color: GREY_DARK,
  fontSize: 18,
  fontWeight: 700,
  lineHeight: "76px",
  height: 76,
  borderBottom: "4px solid white",
  marginRight: 40,
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline"
  }
};

const styles = {
  root: {
    ...pageLink
  },
  rootActive: {
    ...pageLink,
    color: BODY_FONT_BLACK,
    lineHeight: "76px",
    height: 76,
    borderBottom: `4px solid ${BLUE}`
  }
};

const scrollTo = anchor => {
  const el = document.getElementById(anchor.replace("#", ""));

  if (!el || !el.scrollIntoView) return;

  el.scrollIntoView();
};

const PageLink = props => {
  const { active, anchor, classes, history, label, onClick } = props;

  const className = active ? classes.rootActive : classes.root;

  return (
    <div
      onClick={() => {
        history.push(anchor);
        scrollTo(anchor);
        onClick();
      }}
      className={className}
    >
      {label}
    </div>
  );
};

PageLink.propTypes = {
  active: PropTypes.bool,
  anchor: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

PageLink.defaultProps = {
  onClick: () => {}
};

export { PageLink, styles };
export default withRouter(withStyles(styles)(PageLink));
