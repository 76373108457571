import React from "react";

import ProgramsListViewPage from "../components/Programs/ProgramsListViewPage";

import PageTemplate from "./PageTemplate";

const Programs = props => {
  return (
    <PageTemplate>
      <ProgramsListViewPage />
    </PageTemplate>
  );
};

export default Programs;
