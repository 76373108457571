import React from "react";

import PageTemplate from "./PageTemplate";

import ForFounders from "../components/ForFounders";

const ForFoundersPage = props => {
  return (
    <PageTemplate>
      <ForFounders />
    </PageTemplate>
  );
};

export default ForFoundersPage;
