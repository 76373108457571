import React from "react";
import { PropTypes } from "prop-types";
import { Link, withRouter } from "react-router-dom";

import { withStyles } from "material-ui";

import { PrimaryButton } from "../common/Buttons";
import EmptyListState from "../common/EmptyListState";

const styles = {
  root: {
    backgroundColor: "white",
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.05)",
    padding: 30,
    position: "relative"
  },
  editLink: {
    lineHeight: "16px",
    position: "absolute",
    top: 30,
    right: 30
  }
};

const GoToEthosEditor = withRouter(props => {
  const { children, history, staticContext, ...other } = props;

  return (
    <PrimaryButton
      {...other}
      onClick={() => history.push("/mycompany/edit#ethos")}
    >
      {children}
    </PrimaryButton>
  );
});

const renderPlaceholder = props => {
  const { company, profile } = props;

  return company.id === profile.company ? (
    <EmptyListState
      style={{ padding: 60 }}
      title="You haven’t added your company’s ethos to innovation yet"
      subtitle="Your company ethos is your way to promote your company’s background and what your company offers corporates and other founders."
    >
      <GoToEthosEditor>Add your company ethos</GoToEthosEditor>
    </EmptyListState>
  ) : (
    <EmptyListState
      style={{ padding: 60 }}
      title="This corporate has not shared their company ethos yet."
    />
  );
};

const renderEthos = props => {
  const { classes, company, profile } = props;

  const companyEthos = (
    <div
      className="rich-text-content"
      dangerouslySetInnerHTML={{ __html: company.ethos }}
    />
  );

  if (company.id !== profile.company) {
    return companyEthos;
  }

  return (
    <React.Fragment>
      <Link className={classes.editLink} to="/mycompany/edit#ethos">
        Edit
      </Link>
      {companyEthos}
    </React.Fragment>
  );
};

const CompanyEthos = props => {
  const { classes, company } = props;
  const { ethos } = company;

  if (!company.is_corporate) return null;

  return (
    <div className={classes.root}>
      {ethos ? renderEthos(props) : renderPlaceholder(props)}
    </div>
  );
};

CompanyEthos.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

export { CompanyEthos };
export default withStyles(styles)(CompanyEthos);
