import React from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import { PrimaryButton, SecondaryButton } from "../common/Buttons";
import EmptyListState from "../common/EmptyListState";

import ReferStartupButton from "../Startups/ReferStartupButton";

const subtitle =
  "Referring a founder team sends them an exclusive invite to join Colinked, and helps our community grow with qualified companies. Worked with a great company in the past? Get started by referring them now.";

const ReferralsListEmptyState = props => {
  const { history, subtitle, title } = props;

  return (
    <EmptyListState title={title} subtitle={subtitle} style={{ marginTop: 20 }}>
      <div style={{ margin: 20 }}>
        <ReferStartupButton ButtonComponent={PrimaryButton} fillColor="white">
          Refer a Founder Team
        </ReferStartupButton>
        <SecondaryButton onClick={() => history.push("/startups")}>
          Browse Founder Companies
        </SecondaryButton>
      </div>
    </EmptyListState>
  );
};

ReferralsListEmptyState.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

ReferralsListEmptyState.defaultProps = {
  title: "No referrals yet",
  subtitle
};

export { ReferralsListEmptyState };
export default withRouter(ReferralsListEmptyState);
