import React from "react";
import PropTypes from "prop-types";

import FormFieldSeparator from "./FormFieldSeparator";
import FormHeader from "./FormHeader";
import FormSelectField from "./FormSelectField";
import { FormTagsFieldContainer } from "./Tags";
import TechnologiesFieldContainer from "./Technologies";
import ImageUploadField from "./ImageUploadField";

import BenefitsSelector from "./Benefits";
import Blacklist from "./Selectors/Blacklist";
import BooleanField from "./BooleanField";
import CountriesSelector from "./Selectors/CountriesSelector";
import CountrySelector from "./Selectors/CountrySelector";
import CriteriaSelector from "./Criteria";
import CustomSections from "./CustomSections";
import DateTimeSelector from "./DateTimeSelector";
import DurationSelector from "./DurationSelector";
import FoundersSection from "./FoundersSection";
import FundingForm from "./FundingForm";
import IdSelector from "./IdSelector";
import LocationField from "./LocationField";
import MarketsSelector from "./MarketsSelector";
import MeetingTypeSelector from "./MeetingTypeSelector";
// import NewPasswordField from "./NewPasswordField";
// import PasswordConfirmationField from "./PasswordConfirmationField";
import ProfileBuilder from "./ProfileBuilder";
import QuestionsBuilder from "./Questions";
import RichTextInputField from "./RichTextInputField";
import TeamManagement from "./TeamManagement";
import TestimonialsManagement from "./TestimonialsManagement";
import TermsAndConditionsField from "./TermsAndConditionsField";
import TextInputField from "./TextInputField";

import ApplicationCriteriaForm from "./ApplicationCriteriaForm";
import ApplicationOptionsSelector from "./ApplicationOptionsSelector";
import ApplicationQuestionsForm from "./ApplicationQuestionsForm";
import ApplicationReview from "./ApplicationReview";

import CompanyDetailsForm from "./CompanyDetailsForm";
import CompanyFundingForm from "./CompanyFundingForm";
import CompanyInvestorsForm from "./CompanyInvestorsForm";
import CompanyTeamForm from "./CompanyTeamForm";

const InputFieldWrapper = props => {
  const {
    id,
    fieldSpec,
    state,
    errors,
    handleBlur,
    handleChange,
    setActivePage,
    ...otherProps
  } = props;

  const { label, validate, ...otherSpec } = fieldSpec;
  const value = state[id] !== undefined ? state[id] : fieldSpec.value;
  const error = errors && errors.hasOwnProperty(id);
  const helperText = error ? errors[id] : otherSpec.helperText;
  const validationErrors = errors ? errors[id] : null;

  const spec = {
    ...otherProps,
    ...otherSpec,
    helperText,
    error,
    id,
    label,
    value
  };

  if (fieldSpec.type === "separator") {
    return (
      <FormFieldSeparator helperText={helperText} {...otherSpec}>
        {label}
      </FormFieldSeparator>
    );
  }

  if (fieldSpec.type === "header") {
    return <FormHeader {...spec} />;
  }

  if (fieldSpec.type === "applicationOptions") {
    return (
      <ApplicationOptionsSelector
        {...spec}
        setActivePage={setActivePage}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "applicationCriteriaForm") {
    return (
      <ApplicationCriteriaForm
        {...spec}
        application={state.application}
        criteria={state.criteria}
        setActivePage={setActivePage}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "applicationQuestionsForm") {
    return (
      <ApplicationQuestionsForm
        {...spec}
        questions={state.questions}
        setActivePage={setActivePage}
        onChange={handleChange(id)}
        validationErrors={validationErrors}
      />
    );
  }

  if (fieldSpec.type === "applicationReview") {
    return (
      <ApplicationReview
        {...spec}
        application={state}
        setActivePage={setActivePage}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "benefits") {
    return (
      <BenefitsSelector
        width={660}
        {...spec}
        state={state}
        benefits={value}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "blacklist") {
    return (
      <Blacklist
        width={660}
        {...spec}
        value={value}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "bool") {
    return (
      <BooleanField
        width={660}
        {...spec}
        value={Boolean(value)}
        onChange={value => {
          handleChange(id)(value);
          handleBlur(id)();
        }}
      />
    );
  }

  // CompanyDetails, Funding, Team and Investors
  //
  // All of these update attributes of the "company" object.
  // They take the company object as value, update the attributes
  // in their scope and return a new object with their updates.

  if (fieldSpec.type === "companyDetails") {
    return (
      <CompanyDetailsForm
        {...spec}
        company={state.company}
        onBlur={handleBlur("company")}
        onChange={handleChange("company")}
        validationErrors={validationErrors}
        width={660}
      />
    );
  }

  if (fieldSpec.type === "companyFunding") {
    return (
      <CompanyFundingForm
        {...spec}
        company={state.company}
        errors={errors}
        onChange={handleChange("company")}
        validationErrors={validationErrors}
        width={660}
      />
    );
  }

  if (fieldSpec.type === "funding") {
    return (
      <FundingForm
        {...spec}
        errors={errors}
        handleChangeFactory={handleChange}
        state={state}
        validationErrors={validationErrors}
        width={660}
      />
    );
  }

  if (fieldSpec.type === "companyInvestors") {
    return (
      <CompanyInvestorsForm
        {...spec}
        company={state.company}
        onBlur={handleBlur("company")}
        onChange={handleChange("company")}
        validationErrors={validationErrors}
        width={660}
      />
    );
  }

  if (fieldSpec.type === "companyTeam") {
    return (
      <CompanyTeamForm
        {...spec}
        company={state.company}
        onBlur={handleBlur("company")}
        onChange={handleChange("company")}
        validationErrors={validationErrors}
        width={660}
      />
    );
  }

  if (fieldSpec.type === "component") {
    const Component = fieldSpec.component;
    return (
      <Component
        spec={spec}
        state={state}
        value={value}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "countries") {
    return (
      <CountriesSelector
        width={660}
        {...spec}
        value={value}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "country") {
    return (
      <CountrySelector
        width={660}
        {...spec}
        value={value}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "content") {
    return (
      <CustomSections
        {...spec}
        //value={spec.value ? spec.value : "[]"}
        sections={state.content ? state.content : "[]"}
        update={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "criteria") {
    return (
      <CriteriaSelector
        width="100%"
        {...spec}
        criteria={value}
        onChange={handleChange(id)}
        validationErrors={validationErrors}
      />
    );
  }

  if (fieldSpec.type === "questions") {
    return (
      <QuestionsBuilder
        width="100%"
        {...spec}
        questions={value}
        onChange={handleChange(id)}
        validationErrors={validationErrors}
      />
    );
  }

  if (fieldSpec.type === "duration") {
    return (
      <DurationSelector
        {...spec}
        handleChangeFactory={handleChange}
        state={state}
        validationErrors={validationErrors}
      />
    );
  }

  // if (fieldSpec.type === "new_password") {
  //   return (
  //     <NewPasswordField {...spec} handleChange={handleChange} state={state} />
  //   );
  // }

  // if (fieldSpec.type === "password_confirmation") {
  //   return (
  //     <PasswordConfirmationField
  //       {...spec}
  //       onChange={handleChange(id)}
  //       state={state}
  //       value={value}
  //     />
  //   );
  // }

  if (fieldSpec.type === "markets") {
    return (
      <MarketsSelector
        width={660}
        {...spec}
        value={value}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "location") {
    return (
      <LocationField
        width={660}
        {...spec}
        location={value}
        hasLocation={value || state._isUnsavedDraft}
        onChange={handleChange(id)}
        validationErrors={validationErrors}
      />
    );
  }

  if (fieldSpec.type === "meeting_type") {
    return (
      <MeetingTypeSelector
        width={660}
        {...spec}
        value={value}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "idselect") {
    return (
      <IdSelector
        width={660}
        {...spec}
        value={value}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "date") {
    const { value } = spec;

    return (
      <DateTimeSelector
        width={440}
        {...spec}
        date={value}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "founders") {
    const { value } = spec;

    return (
      <FoundersSection
        {...spec}
        title={"Founders"}
        founders={value}
        update={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "image") {
    return <ImageUploadField {...spec} onChange={handleChange(id)} />;
  }

  if (fieldSpec.type === "profile") {
    return (
      <ProfileBuilder
        {...spec}
        state={state}
        sections={value}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "richtext") {
    return (
      <RichTextInputField
        {...spec}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "tags") {
    return (
      <FormTagsFieldContainer
        {...spec}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "team") {
    return (
      <TeamManagement
        {...spec}
        team={value}
        onChange={handleChange(id)}
        validationErrors={validationErrors}
      />
    );
  }

  if (fieldSpec.type === "technologies") {
    return (
      <TechnologiesFieldContainer
        {...spec}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  if (fieldSpec.type === "testimonials") {
    return (
      <TestimonialsManagement
        {...spec}
        testimonials={value}
        onChange={handleChange(id)}
        validationErrors={validationErrors}
      />
    );
  }

  if (fieldSpec.type === "terms_and_conditions") {
    return (
      <TermsAndConditionsField
        {...spec}
        onChange={value => {
          handleChange(id)(value);
          handleBlur(id)();
        }}
      />
    );
  }

  if (fieldSpec.options) {
    return (
      <FormSelectField
        {...spec}
        onBlur={handleBlur(id)}
        onChange={handleChange(id)}
      />
    );
  }

  return (
    <TextInputField
      {...spec}
      limit={spec.limit || 140}
      type={spec.type || "text"}
      onBlur={handleBlur(id)}
      onChange={handleChange(id)}
    />
  );
};

InputFieldWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  fieldSpec: PropTypes.shape({
    label: PropTypes.string,
    helperText: PropTypes.string,
    validate: PropTypes.func,
    value: PropTypes.any
  }).isRequired,
  state: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

InputFieldWrapper.defaultProps = {
  errors: {}
};

export default InputFieldWrapper;
