import React from "react";
import PropTypes from "prop-types";

import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";

import NotificationsIcon from "@material-ui/icons/Notifications";

const DashboardLink = props => {
  const { classes, history, notifications, profile } = props;

  const label = notifications.length ? (
    <Badge
      classes={{ badge: classes.badge }}
      badgeContent={notifications.length}
    >
      <NotificationsIcon className={classes.icon} />
    </Badge>
  ) : (
    <NotificationsIcon className={classes.iconInactive} />
  );

  // TODO: temporary disabled notification actions
  if (!false) return null;

  if (!profile.isSignedIn) return null;

  const onClick = props.onClick
    ? props.onClick
    : () => history.push("/dashboard");

  return (
    <Button
      classes={{
        root: classes.root,
        label: classes.label,
        sizeSmall: classes.sizeSmall,
        flatPrimary: classes.flatPrimary
      }}
      onClick={onClick}
      size="small"
    >
      {label}
    </Button>
  );
};

DashboardLink.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  notifications: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  profile: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired
};

DashboardLink.defaultProps = {
  notifications: [],
  style: {}
};

export default DashboardLink;
