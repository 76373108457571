import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { LinkButton } from "./Buttons";

import investreadyImage from "../../assets/images/investready-fundraise.jpg";

const styles = {
  root: {
    color: "white",
    padding: 20,
    backgroundImage: `linear-gradient(-180deg, rgba(73, 188, 180, 0.95) 0%, rgba(53, 145, 181, 0.95) 52%, rgba(56, 134, 179, 0.95) 100%), url( "${investreadyImage}" )`,
    backgroundSize: "cover"
  },
  header: {
    fontSize: 15,
    fontWeight: 700
  },
  text: {
    fontSize: 15
  }
};

const InvestReady = props => {
  const { classes, style } = props;

  return (
    <div className={classes.root} style={style}>
      <div className={classes.header}>Looking to fundraise?</div>
      <div className={classes.text}>Claim your free hour of consultation</div>
      <LinkButton
        style={{ marginLeft: 0, marginTop: 20 }}
        href="mailto:investready@white.space"
      >
        Claim Now
      </LinkButton>
    </div>
  );
};

InvestReady.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired
};

InvestReady.defaultProps = {
  style: {}
};

export { InvestReady, styles };
export default withStyles(styles)(InvestReady);
