import React from "react";
import { PropTypes } from "prop-types";

import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    padding: 30
  },
  criteria: {
    fontWeight: 600,
    paddingLeft: 20,
    width: 180,
    minWidth: 180
  },
  intro: {
    marginTop: 30,
    marginBottom: 30
  },
  other: {
    lineHeight: "17px",
    marginBottom: 8
  },
  row: {
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "1px solid #E6E7E9",
    "&:last-child": {
      borderBottom: "none"
    },
    paddingTop: 20,
    paddingBottom: 20
  },

  title: {
    fontSize: 18,
    fontWeight: 600
  },
  value: {}
};

const DISPLAY_LABELS = {
  countries: "Region / Countries",
  markets: "Markets",
  stages: "Funding Stages",
  other: "Other",

  seed: "Seed",
  series_a: "Series A",
  series_b: "Series B",
  series_c: "Series C+"
};

const displayLabel = label => {
  return DISPLAY_LABELS[label] || label;
};

const getCountryNames = (arr, countries) => {
  return arr
    .map(id => countries.find(country => country.id === id))
    .filter(c => c)
    .map(c => c.label);
};

const getMarketNames = (arr, markets) => {
  return arr
    .map(id => markets.find(market => market.id === id))
    .filter(m => m)
    .map(m => m.name);
};

const getUserGroups = application => {
  const { open_to_corporates, open_to_founders, open_to_guests } = application;

  let groups = "";
  if (open_to_corporates && open_to_founders) {
    groups = "Corporations and Founder Companies";
  } else if (open_to_corporates) {
    groups = "Corporations only";
  } else if (open_to_founders) {
    groups = "Founder Companies only";
  }

  const guests = open_to_guests
    ? "Both Colinked and Non-Colinked members"
    : "Colinked members only";

  return [groups, guests].filter(s => s).join(", ");
};

const renderCriteria = (criteria, props) => {
  const { label } = criteria;

  let value = criteria.value;
  if (label === "countries") {
    value = getCountryNames(value, props.countries);
  } else if (label === "markets") {
    value = getMarketNames(value, props.markets);
  }

  return renderRow(
    displayLabel(label),
    value.map(displayLabel).join(", "),
    props.classes
  );
};

const renderOther = (arr, props) => {
  if (!arr.length) return null;

  const { classes } = props;

  const value = arr.map((criteria, index) => (
    <div className={classes.other} key={index}>
      {criteria.value}
    </div>
  ));

  return renderRow("Other", value, props.classes);
};

const renderRow = (label, value, classes) => {
  return (
    <div key={label} className={classes.row}>
      <div className={classes.criteria}>{label}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

const DocumentCriteria = props => {
  const { classes } = props;
  const { criteria, application } = props.document;

  const audience = getUserGroups(application);
  const nda = application.nda_required ? "Yes" : "No";

  return (
    <div className={classes.root}>
      <div className={classes.title}>Who can apply?</div>
      <div className={classes.intro}>
        All potential applicants must meet the following eligibilty criteria
        before applying to the program.
      </div>
      {renderRow("Open to", audience, classes)}
      {renderRow("NDA Required", nda, classes)}
      {criteria
        .filter(c => c.label !== "other")
        .map(c => renderCriteria(c, props))}
      {renderOther(criteria.filter(c => c.label === "other"), props)}
    </div>
  );
};

DocumentCriteria.propTypes = {
  classes: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  document: PropTypes.object.isRequired,
  markets: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    countries: state.countries,
    markets: state.tags
  };
};

export { DocumentCriteria, styles };
export default connect(mapStateToProps)(withStyles(styles)(DocumentCriteria));
