import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { createFlashNote } from "../../../Reducers/FlashNotes";
import { updateProfileData } from "../../../Reducers/Profile";

import UpdateEmailForm from "./UpdateEmailForm";

const styles = {
  root: {},
  buttons: {
    marginTop: 60,
    marginBottom: 0
  }
};

const mapStateToProps = state => ({ profile: state.profile });

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => dispatch(createFlashNote(msg, type)),
    updateProfile: data => dispatch(updateProfileData(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UpdateEmailForm));
