import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";

const HEIGHT = 485;

const styles = {
  paper: {
    width: "390px",
    height: HEIGHT,

    position: "absolute",
    left: "50%",
    top: "50%",
    marginLeft: "-300px",
    marginTop: `-${HEIGHT / 2}px`,

    backgroundColor: "white",
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.2)",
    "&:focus": {
      boxShadow: "none",
      outline: "none"
    }
  },
  buttonCancel: {
    fill: "#2A2B2C"
    // position: "absolute",
    // top: 15,
    // right: 15
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 60,
    paddingLeft: 30,
    paddingRight: 20,
    borderBottom: "1px solid #E6E7E9"
  },
  title: {
    color: "#2A2B2C",
    fontSize: 17,
    fontWeight: 700,
    // lineHeight: "20px",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    flexGrow: 1
  },
  container: {
    padding: 30
  }
};

class SimpleDialog extends React.Component {
  constructor(props) {
    super(props);

    this.id = `id_${Math.random()}`;
    this.contentRef = React.createRef();

    this.state = {
      _height: props.height,
      open: true
    };
  }

  componentDidMount() {
    this.setState({ __forceHeightUpdate: true });
  }

  componentDidUpdate() {
    const content = document.getElementById(this.id);
    const height = content && content.offsetHeight;

    if (height && height !== this.state._height) {
      this.setState({ _height: height });
    }
  }

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ open: false }, () => {
      if (onClose) onClose();
    });
  };

  render() {
    const { classes, children, style, title, width } = this.props;
    const height = this.state._height || this.props.height;

    const marginTop = `-${(height + 60) / 2}px`;
    const marginLeft = `-${(width + 60) / 2}px`;
    const _style = { height, width, marginTop, marginLeft, ...style };

    return (
      <Modal open={this.state.open} onClose={this.handleClose}>
        <div className={classes.paper} style={_style}>
          <div id={this.id} ref={this.contentRef}>
            <div className={classes.header}>
              <div className={classes.title}>{title}</div>

              <CloseIcon
                className={classes.buttonCancel}
                onClick={this.handleClose}
              />
            </div>
            <div className={classes.container}>{children}</div>
          </div>
        </div>
      </Modal>
    );
  }
}

SimpleDialog.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClose: PropTypes.func
};

SimpleDialog.defaultProps = {
  height: 485,
  width: 600,
  style: {}
};

export default withRouter(withStyles(styles)(SimpleDialog));
