export const SET_COUNTRIES = "SET_COUNTRIES";

export const setCountries = array => ({ type: SET_COUNTRIES, payload: array });

export const fetchCountries = () => {
  return dispatch => {
    return fetch(`/api/countries/?limit=1000`, {
      cache: "no-cache",
      credentials: "same-origin"
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.message || response.statusText);
        }

        return response.json();
      })
      .then(json => (json.results ? json.results : json))
      .then(countries => {
        dispatch(setCountries(countries));
      })
      .catch(error => console.log(error));
  };
};
