import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

import { createFlashNote } from "../FlashNotes";

import * as api from "./api";
import * as actionTypes from "./connectionsActionTypes";

export const approveConnection = (id, message = "You are now connected.") => {
  return _submitConnectionUpdate(
    id,
    `/api/connections/${id}/approve/`,
    {
      is_approved: 2
    },
    { message, type: "success" }
  );
};

export const ignoreConnection = (
  id,
  message = "You have ignored the connection request."
) => {
  return _submitConnectionUpdate(
    id,
    `/api/connections/${id}/decline/`,
    {
      is_approved: 0
    },
    { message, type: "info" }
  );
};

export const loadConnections = (limit = 1000) => {
  return dispatch => {
    dispatch(actionTypes.setConnectionsLoading());

    api.loadConnections(limit).then(json => {
      const { error, results } = json;

      if (error) {
        dispatch(actionTypes.setConnections([]));
      } else {
        dispatch(actionTypes.setConnections(results));
      }
    });
  };
};

export const requestConnection = (url, message, company, myCompany) => {
  const apiData = { message, connection_company: company.id };

  return dispatch => {
    // TODO: dispatch(setPendingState)
    api.requestConnection(url, apiData, company, myCompany).then(json => {
      if (json.error) {
        dispatch(createFlashNote(json.error.message, "error"));
      } else {
        dispatch(actionTypes.updateConnectionsList([json]));
        dispatch(createFlashNote("Your request has been received.", "success"));
      }

      return json;
    });
  };
};

export const resetConnections = () => {
  return dispatch => {
    dispatch(actionTypes.setConnections([]));
  };
};

export const toggleConnectionContacts = (id, value) => {
  return dispatch => {
    dispatch(actionTypes.toggleConnectionContacts(id, value));
  };
};

export const updateConnections = () => {
  return dispatch => {
    api.loadConnections(20).then(json => {
      const { error, results } = json;

      if (!error) {
        dispatch(actionTypes.updateConnectionsList(results));
      }
    });
  };
};

const _submitConnectionUpdate = (id, url, finalState = {}, flashNote = {}) => {
  return dispatch => {
    dispatch(actionTypes.updateConnectionAction(id, { pending: true }));

    fetchWithToken(url, { method: "PUT" })
      .then(response => responseJsonOrError(response))
      .then(json => {
        const { error } = json;

        if (error) {
          dispatch(
            actionTypes.updateConnectionAction(id, { pending: false, error })
          );
          dispatch(createFlashNote(error.message, "error"));
        } else {
          dispatch(
            actionTypes.updateConnectionAction(id, {
              pending: false,
              ...finalState
            })
          );
          dispatch(createFlashNote(flashNote.message, flashNote.type));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(
          actionTypes.updateConnectionAction(id, { pending: false, error })
        );
        dispatch(createFlashNote(error.message, "error"));
      });
  };
};
