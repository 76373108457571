import { createFlashNote } from "../FlashNotes";

import * as api from "./api";

import {
  resetCompanyData,
  setCompanyData,
  updateCompanyData
} from "./companyActionTypes";

// strip frontend state from data sent to server
const state2document = state => {
  const data = Object.keys(state)
    .filter(key => !key.startsWith("_"))
    .filter(key => key !== "doctype")
    .filter(key => key !== "error")
    .filter(key => key !== "validationErrors")
    .reduce((data, key) => {
      data[key] = state[key];
      return data;
    }, {});

  // replace company object with id for programs and challenges
  if (state.doctype === "program" || state.doctype === "challenge") {
    return { ...data, company: data.company && data.company.id };
  }

  return data;
};

export const loadCompany = (type, id) => {
  return dispatch => {
    return api
      .loadCompany(type, id)
      .then(result => {
        dispatch(
          setCompanyData({
            ...result,
            doctype: "company",
            pending: false
          })
        );

        return result;
      })
      .catch(error => {
        dispatch(
          createFlashNote(
            `failed to load company data: ${error.message}`,
            "error"
          )
        );
        dispatch(
          updateCompanyData({
            error,
            pending: false
          })
        );

        return { error };
      });
  };
};

export const resetCompany = () => {
  return dispatch => {
    dispatch(resetCompanyData());
  };
};

export const updateCompany = _data => {
  const { doctype, ...data } = _data;
  console.log("updateCompany", data);

  return dispatch => {
    dispatch(updateCompanyData({ pending: true }));

    api
      .updateCompany(state2document(data))
      .then(result => {
        const { error, ...other } = result;

        if (error) {
          dispatch(
            updateCompanyData({
              error,
              pending: false
            })
          );
          dispatch(createFlashNote(`update failed: ${error.message}`, "error"));
        } else {
          dispatch(
            updateCompanyData({
              ...other,
              ...data,
              pending: false
            })
          );
          dispatch(
            createFlashNote("Company profile has been updated.", "success")
          );
        }
      })
      .catch(error => {
        dispatch(
          updateCompanyData({
            error,
            pending: false
          })
        );
        dispatch(createFlashNote(`update failed: ${error.message}`, "error"));
      });
  };
};
