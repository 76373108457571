export const CREATE_SERVICE = "CREATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const SELECT_SERVICE = "SELECT_SERVICE";
export const SET_SERVICE_LIST = "SET_SERVICE_LIST";
export const SET_SERVICE_LIST_LOADING = "SET_SERVICE_LIST_LOADING";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_SERVICE_LIST = "UPDATE_SERVICE_LIST";

export const createNewService = payload => ({
  type: CREATE_SERVICE,
  payload
});

export const deleteService = id => ({
  type: DELETE_SERVICE,
  payload: id
});

export const selectService = id => ({
  type: SELECT_SERVICE,
  payload: id
});

export const setServiceList = payload => ({
  type: SET_SERVICE_LIST,
  payload
});

export const setServiceListLoading = () => ({
  type: SET_SERVICE_LIST_LOADING
});

export const updateService = payload => ({
  type: UPDATE_SERVICE,
  payload
});

export const updateServiceList = payload => ({
  type: UPDATE_SERVICE_LIST,
  payload
});
