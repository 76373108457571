const selectionStyle = {
  boxSizing: "border-box",
  fontSize: 14,
  fontWeight: 300,
  height: 40,
  borderRadius: 3,
  backgroundColor: "#EDEDEF",
  padding: "10px 15px"
};

const styles = {
  root: {
    marginTop: 20
  },
  controlsRoot: {
    flexShrink: 0,
    marginTop: 30
  },
  empty: {
    color: "#AAA6BD",
    fontSize: 14,
    fontStyle: "italic",
    fontWeight: 300,
    lineHeight: "17px",
    padding: "11px 15px",
    border: "2px solid #F2F3F4",
    borderRadius: 3,
    marginBottom: 20,
    width: 410,
    textAlign: "center"
  },
  label: {
    fontSize: 14,
    fontWeight: 300,
    marginBottom: 6
  },
  row: {
    display: "flex",
    marginBottom: 20
  },
  selection: {
    ...selectionStyle,
    backgroundColor: "white",
    border: "1px solid #EDEDEF"
  },
  textInput: {
    marginTop: 0
  },
  type: {
    marginRight: 10,
    width: 190,
    flexShrink: 0
  },
  value: {
    outline: "1px solid green",
    flexGrow: 1,
    maxWidth: 440
  },
  valueInput: {
    ...selectionStyle,
    width: "calc(100% -30px)"
  },

  // overrides for form components
  formInput: {
    height: "auto"
  },
  formTagsRoot: {
    marginTop: 0,
    marginBottom: 0
  }
};

export default styles;
