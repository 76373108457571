import { connect } from "react-redux";

const findTagById = (id, tags) => {
  return tags.find(tag => tag.id === id);
};

const TechnologiesList = props => {
  const { tags, technologies } = props;

  if (!technologies || !technologies.length) return null;

  if (technologies[0].name) {
    return technologies
      .map(m => m.name)
      .sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" }))
      .join(", ");
  }

  if (typeof parseInt(technologies[0], 10) === "number") {
    return technologies
      .map(technology => parseInt(technology, 10))
      .map(id => findTagById(id, tags) || id)
      .filter(tag => tag)
      .map(tag => tag.name)
      .sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" }))
      .join(", ");
  }

  return technologies.join(", ");
};

const mapStateToProps = state => {
  return {
    tags: state.technologies
  };
};

export default connect(
  mapStateToProps,
  null
)(TechnologiesList);
