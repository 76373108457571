/* eslint no-use-before-define: 0 */

// eslint-disable-next-line no-unused-vars
import jwt from "jsonwebtoken";

export const REFRESH_TOKEN_KEY = "fuse.jwt.refresh";
export const ACCESS_TOKEN_KEY = "fuse.jwt.access";
export const TOKEN_REFRESH_TIME = 5;

const DEBUG_TOKEN = false;
let RENEW_ACCESS_TOKEN_PENDING = false;

function debugLog() {
  if (DEBUG_TOKEN) {
    const args = Array.prototype.slice.call(arguments);

    console.log(args.join(""));
  }
}

/*
 *  remove existing tokens from local and session storage
 */
export const clearTokens = () => {
  debugLog("clearTokens");
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const displayToken = (url = "") => {
  debugLog(`displayToken(${url})`);
  const access = sessionStorage.getItem(ACCESS_TOKEN_KEY);
  const refresh =
    sessionStorage.getItem(REFRESH_TOKEN_KEY) ||
    localStorage.getItem(REFRESH_TOKEN_KEY);

  if (access) {
    debugLog("access: " + JSON.stringify(jwt.decode(access)));
  } else {
    debugLog("no access token");
  }

  if (refresh) {
    debugLog("refresh: " + JSON.stringify(jwt.decode(refresh)));
  } else {
    debugLog("no refresh token");
  }
};

/*  fetchWithToken
 *
 *  add a valid auth token to the request headers
 *
 *  request and save an updated token when the current token
 *  has expired
 *
 *  raise Error when token has expired (after 30 days)
 */
export const fetchWithToken = (
  _url,
  options = {},
  functions = { getAccessToken }
) => {
  const { getAccessToken } = functions;
  const optHeaders = options.headers || {};
  const url = _url.replace(/\/\//g, "/");

  return getAccessToken()
    .then(_token => {
      // issues with token format
      if (_token.access) {
        console.warn("token:", JSON.stringify(token));
      }
      if (typeof _token !== "string") {
        console.warn("token:", JSON.stringify(token));
      }
      const token = _token.access ? _token.access : _token;

      const headers = {
        ...optHeaders,
        ...getAuthHeaders(token),
        ...getContentHeaders()
      };

      return fetch(url, { ...options, headers });
    })
    .catch(error => {
      // console.warn(`fetchWithToken (${url}):`, error.message);

      const headers = {
        ...optHeaders,
        ...getContentHeaders()
      };

      return fetch(url, { ...options, headers });
    });
};

/*
 *  uploadFormWithToken
 *
 *  add auth token to the request headers if available
 *
 *  request and save a new token when returned from server
 *
 *  raise Error when token has expired (after 30 days)
 */
export const uploadFormWithToken = (
  url,
  options = {},
  functions = { getAccessToken }
) => {
  const { getAccessToken } = functions;
  const optHeaders = options.headers || {};

  return getAccessToken()
    .then(token => {
      return {
        ...optHeaders,
        ...getAuthHeaders(token)
      };
    })
    .then(headers => fetch(url, { ...options, headers }))
    .catch(error => {
      console.warn("uploadFormWithToken:", error);

      const headers = {
        ...optHeaders
      };

      return fetch(url, { ...options, headers });
    });
};

/**
 *
 * @param {*} data
 */
export const saveTokens = data => {
  debugLog("saveTokens");
  debugLog("  refresh: ", (data.refresh || "..").split(".")[2]);
  debugLog("  access:  ", (data.access || "..").split(".")[2]);

  const { access, refresh } = data;

  if (access) {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, access);
  }

  if (refresh) {
    sessionStorage.setItem(REFRESH_TOKEN_KEY, refresh);
    localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
  }
};

/**
 *
 * @param {*} token
 */
const getAuthHeaders = token => {
  return token ? { Authorization: `Bearer ${token}` } : {};
};

/*
 *  return content headers for application/json data
 */
const getContentHeaders = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
};

/**
 *  return existing valid access token or renew token if necessary
 */
export const getAccessToken = (
  functions = { getLocalAccessToken, renewAccessToken }
) => {
  const { getLocalAccessToken, renewAccessToken } = functions;
  // check token from localStorage first
  const token = getLocalAccessToken();

  // token exists and is valid (checked in getLocalAccessToken)
  if (token) {
    if (token.access) {
      console.warn("TOKEN is OBJECT", token);

      return Promise.resolve(token.access);
    }

    return Promise.resolve(token);
  }

  // no valid token or token expired => renew it
  if (!RENEW_ACCESS_TOKEN_PENDING) {
    return renewAccessToken().then(token => {
      if (token)
        if (token.access) {
          console.warn("TOKEN is OBJECT", token);

          return token.access;
        }

      return token;
    });
  }

  // wait until ongoing fetch has completed
  if (RENEW_ACCESS_TOKEN_PENDING) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(getAccessToken());
      }, 500);
    });
  }
};

/*
 *  load access token from localStorage and check data
 */
export const getLocalAccessToken = (functions = { jwt }) => {
  const { jwt } = functions;

  const token = sessionStorage.getItem(ACCESS_TOKEN_KEY);

  if (!token) return;

  // return on invalid or incomplete token
  const decoded = jwt.decode(token);
  if (decoded === null) return;

  const { exp } = decoded;
  if (!exp) return;

  // check that token has at least 5 seconds more to live
  const now = new Date().getTime() / 1000;
  if (exp < now + 5) {
    return;
  }

  // return only valid token
  return token;
};

/*
 *  load refresh token from localStorage and check data
 */
export const getLocalRefreshToken = (functions = { jwt }) => {
  const { jwt } = functions;

  const sessionToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);
  if (sessionToken) {
    return { token: sessionToken };
  }

  const token = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (!token) {
    return { token, error: new Error("no refresh token found") };
  }

  const decoded = jwt.decode(token);
  if (decoded === null) {
    return { error: new Error("refresh token is invalid") };
  }

  const { exp } = decoded;
  if (!exp) {
    return { error: new Error("refresh token is incomplete") };
  }

  // keep valid token in session storage for quick access
  if (validateToken(token, 24 * 60 * 60)) {
    sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
  }

  debugLog("valid refresh token found");
  return { token, decoded };
};

/*
 *  copy validated token to session storage for quick access
 */
export const loadRefreshToken = () => {
  debugLog("loadRefreshToken");

  const token = localStorage.getItem(REFRESH_TOKEN_KEY);

  // check that token is valid for another 24 hours
  if (token && validateToken(token, 24 * 60 * 60)) {
    sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
    debugLog(" => valid token");
    return token;
  }

  // if (process.env.NODE_ENV !== "test") {
  //   console.warn("no local refresh token");
  // }
};

/*
 *  request renewed auth token from API
 */
export const renewAccessToken = (functions = { getLocalRefreshToken }) => {
  debugLog("renewAccessToken");

  const { getLocalRefreshToken } = functions;
  const { error, token } = getLocalRefreshToken();

  if (error) {
    return Promise.reject(error);
  }

  const headers = {
    ...getContentHeaders()
  };

  RENEW_ACCESS_TOKEN_PENDING = true;

  return fetch("/api/auth/token/refresh/", {
    method: "POST",
    headers,
    body: JSON.stringify({ refresh: token })
  })
    .then(response => {
      if (!response.ok) {
        RENEW_ACCESS_TOKEN_PENDING = false;
        clearTokens();

        throw new Error("Token refresh failed.");
      }

      return response.json();
    })
    .then(data => {
      saveTokens(data);
      RENEW_ACCESS_TOKEN_PENDING = false;

      return data.access;
    });
};

/*
 *  uploadFile
 *
 *  add a valid auth token to the request headers
 *
 *  keep content headers as needed for file upload via FormData
 *
 *  returns error when no token can be obtained
 *
 */
export const uploadFile = (
  url,
  options = {},
  functions = { getAccessToken }
) => {
  const { getAccessToken } = functions;
  const optHeaders = options.headers || {};

  return getAccessToken()
    .then(token => {
      const headers = {
        ...optHeaders,
        ...getAuthHeaders(token),
        Accept: "application/json"
      };

      return fetch(url, { ...options, headers });
    })
    .catch(() => {
      // image upload does not require token
      const headers = {
        ...optHeaders,
        Accept: "application/json"
      };

      return fetch(url, { ...options, headers });
    });
};

/*
 *  uploadImage
 *
 *  add a valid auth token to the request headers
 *
 *  keep content headers as needed for image upload via FormData
 *
 *  returns error when no token can be obtained
 *
 */
export const uploadImage = (
  url,
  options = {},
  functions = { getAccessToken }
) => {
  const { getAccessToken } = functions;
  const optHeaders = options.headers || {};

  return getAccessToken()
    .then(token => {
      const headers = {
        ...optHeaders,
        ...getAuthHeaders(token),
        Accept: "application/json"
      };

      return fetch(url, { ...options, headers });
    })
    .catch(() => {
      // image upload does not require token
      const headers = {
        ...optHeaders,
        Accept: "application/json"
      };

      return fetch(url, { ...options, headers });
    });
};

/*
 *  check if token is valid and has not expired with an optional
 *  minimum time to live (ttl)
 */
export const validateToken = (token, ttl = 0) => {
  const decoded = jwt.decode(token);

  // something wrong with token
  if (decoded === null) {
    return false;
  }

  const { exp } = decoded;
  const now = parseInt(new Date().getTime() / 1000, 10);
  // token older than 5 minutes but not expired yet
  if (exp > now + ttl) {
    return true;
  }

  // token has expired
  return false;
};
