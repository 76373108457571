import React from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core/styles";

import LoadingPanel from "../common/LoadingPanel";
import CustomTableCell from "../common/StyledTable/CustomTableCell";

import * as renderers from "./renderers";

import styles from "./tableStyles";

const renderRow = (referral, renderers, classes) => {
  return (
    <TableRow className={classes.row} key={referral.id}>
      {renderers.map((cellRenderer, index) => (
        <CustomTableCell key={index}>
          {cellRenderer(referral, classes)}
        </CustomTableCell>
      ))}
    </TableRow>
  );
};

const renderLoadingRow = (columns, classes) => {
  return (
    <TableRow className={classes.row} style={{ backgroundColor: "white" }}>
      <CustomTableCell colSpan={columns}>
        <LoadingPanel />
      </CustomTableCell>
    </TableRow>
  );
};

const ReferralsTable = props => {
  const { classes, headers, loading, referrals, renderers } = props;

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <CustomTableCell className={classes.td} key={header || index}>
                {header}
              </CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tablebody}>
          {referrals.map(ref => renderRow(ref, renderers, classes))}
          {loading && renderLoadingRow(headers.length, classes)}
        </TableBody>
      </Table>
    </div>
  );
};

ReferralsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  referrals: PropTypes.array.isRequired,
  renderers: PropTypes.arrayOf(PropTypes.func).isRequired
};

ReferralsTable.defaultProps = {
  headers: ["Company", "Date Referred", "Founder Contact", "Status"],
  loading: false,
  renderers: [
    renderers.renderCompany,
    renderers.renderReferralDate,
    renderers.renderFounderContact,
    renderers.renderStatus
  ]
};

export default withStyles(styles)(ReferralsTable);
