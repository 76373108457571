import { connect } from "react-redux";

import DocumentDetailsViewContainer from "../Document/DocumentDetailsViewContainer";

import { loadProgram } from "./api.js";

const mapStateToProps = state => {
  return {
    company: state.company,
    doctype: "program",
    loadDocument: loadProgram,
    profile: state.profile,
    labels: ["Program Details", "Applications", "Participants"],
    subtitles: [
      "Edit Program details",
      "Manage pending applications",
      "List of approved program participants"
    ]
  };
};

export default connect(mapStateToProps)(DocumentDetailsViewContainer);
