import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "inline-flex",
    alignItems: "center",
    flexShrink: 0,

    backgroundColor: "rgba(29,113,184,0.1)",
    color: "#1D71B8",

    fontSize: 12,
    fontWeight: 700,
    marginLeft: 5,
    height: 24,
    lineHeight: "24px",
    borderRadius: 12,

    paddingLeft: 10,
    paddingRight: 10,

    overflow: "hidden",
    verticalAlign: "middle",
    whiteSpace: "nowrap"
  },
  grey: {
    color: "#555B6E",
    backgroundColor: "rgba(230,231,233,0.5)"
  },
  blue: {
    backgroundColor: "rgba(29,113,184,0.1)",
    color: "#1D71B8"
  },
  green: {
    color: "#9CB59E",
    backgroundColor: "#EBF0EC"
  },
  gold: {
    color: "#CEA751",
    backgroundColor: "#F5EDDC"
  },
  red: {
    color: "#DB6E53",
    backgroundColor: "rgba(230,231,233,0.5)"
  },
  grey_solid: {
    color: "white",
    backgroundColor: "#AAADB6"
  },
  blue_solid: {
    color: "white",
    backgroundColor: "#6592CC"
  },
  green_solid: {
    color: "white",
    backgroundColor: "#85C97C"
  },
  gold_solid: {
    color: "white",
    backgroundColor: "#CEA751"
  },
  red_solid: {
    color: "white",
    backgroundColor: "#DB6E53"
  }
};

const findTagById = (id, tags) => {
  return tags.find(tag => tag.id === id);
};

const TagLabel = props => {
  const {
    classes,
    dispatch,
    color,
    featured,
    IconLeft,
    IconRight,
    label,
    solid,
    style,
    tags,
    ...other
  } = props;

  if (!label) return null;

  const tagById = label.id
    ? findTagById(label.id, tags)
    : findTagById(label, tags);
  const _label = label.name || (tagById && tagById.name) || `${label}`;

  const _color = solid ? `${color}_solid` : color;

  const className = `${classes.root} ${classes[_color]}`;

  return (
    <div className={className} {...other} style={style}>
      {IconLeft && (
        <IconLeft style={{ fontSize: 20, marginLeft: -3, marginRight: 5 }} />
      )}
      {_label}
      {IconRight && <IconRight />}
    </div>
  );
};

TagLabel.propTypes = {
  IconLeft: PropTypes.func,
  IconRight: PropTypes.func,
  color: PropTypes.oneOf(["grey", "blue", "green", "gold", "red"]),
  featured: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]).isRequired,
  solid: PropTypes.bool,
  style: PropTypes.object.isRequired
};

TagLabel.defaultProps = {
  color: "blue",
  featured: false,
  solid: false,
  style: {}
};

const mapStateToProps = state => {
  return {
    tags: state.tags
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(TagLabel));
