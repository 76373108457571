import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

const errorWrapper = message => {
  return Promise.resolve({
    error: new Error(message)
  });
};

const fixMissingData = json => {
  const link = json.is_corporate
    ? `/corporate/${json.id}`
    : json.is_founder
    ? `/startup/${json.id}`
    : null;
  const type = json.is_corporate ? "corporations" : "founders";

  return { ...json, doctype: "company", is_approved: 2, link, type };
};

export const loadCompany = (type, id) => {
  if (type !== "corporations" && type !== "founders") {
    return errorWrapper(
      `Company type must be one of 'corporations' or 'founders'. Provided type: '${type}'`
    );
  }

  return fetchWithToken(`/api/companies/${type}/${id}/`)
    .then(responseJsonOrError)
    .then(fixMissingData);
};

export const loadCompanyFromProfile = profile => {
  // console.log("loadCompanyFromProfile", profile.username);
  const { company, membership } = profile;

  const id = company.id ? company.id : company;

  const url =
    membership === "founder"
      ? `/api/companies/founders/${id}/`
      : `/api/companies/corporations/${id}/`;

  return fetchWithToken(url, {
    method: "GET",
    redentials: "same-origin",
    headers: {
      "content-type": "application/json"
    }
  })
    .then(responseJsonOrError)
    .then(fixMissingData);
};

export const updateCompany = data => {
  const { type, id, ...companyData } = data;

  const url = companyData.is_corporate
    ? "/api/companies/corporations/"
    : "/api/companies/founders/";

  return fetchWithToken(`${url}/${id}/`, {
    body: JSON.stringify(companyData),
    cache: "no-cache",
    credentials: "same-origin",
    method: "PUT"
  }).then(responseJsonOrError);
};
