import React from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { withStyles } from "@material-ui/core/styles";

import ConnectionLinkDialog from "./ConnectionLinkDialog";

const styles = {
  root: {
    display: "flex",
    alignItems: "center"
  },
  link: {
    color: "#398DD3",
    fontWeight: 700,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    },
    marginLeft: 10,
    whiteSpace: "nowrap"
  }
};

class ConnectionActions extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    connection: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
  };

  state = {
    modal: false
  };

  componentDidMount() {
    const { connection } = this.props;

    // use window.location.search to parse search params because
    // window.location will change after history.push in this.removeIdFromUrl
    const params = queryString.parse(window.location.search);

    // convert connection.id to string because params.id is a string
    if (params.id === `${connection.id}`) {
      this.setState({ modal: true });
    }
  }

  removeIdFromUrl = () => {
    const { history, location } = this.props;

    // update  window.location to page url without query params
    history.push(location.pathname);
  };

  renderModal = () => {
    const { connection, title } = this.props;

    return (
      <ConnectionLinkDialog
        connection={connection}
        onClose={() => this.setState({ modal: false }, this.removeIdFromUrl)}
        title={title}
      />
    );
  };

  render() {
    const { children, classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.modal && this.renderModal()}
        {children}
        <span
          className={classes.link}
          onClick={() => this.setState({ modal: true })}
        >
          View Request
        </span>
      </div>
    );
  }
}

export { ConnectionActions, styles };
export default withRouter(withStyles(styles)(ConnectionActions));
