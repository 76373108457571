import { mergeListsById } from "../utils";

import {
  CREATE_SERVICE,
  DELETE_SERVICE,
  SELECT_SERVICE,
  SET_SERVICE_LIST,
  SET_SERVICE_LIST_LOADING,
  UPDATE_SERVICE,
  UPDATE_SERVICE_LIST
} from "./ServiceActionTypes";

// add 'loading' attribute to services array object
// gets removed automatically when list is updated
const setLoading = services => {
  const _services = [...services];
  _services.loading = true;

  return _services;
};

const sortServicesByDate = arr => {
  // sort by time in reverse order (most recent updated first)
  arr.sort((a, b) => b.updated_at - a.updated_at);

  return arr;
};

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_SERVICE:
      return [...state, action.payload];

    case DELETE_SERVICE:
      return state.filter(service => service.id !== action.payload);

    case SELECT_SERVICE:
      return state.map(service => {
        if (service.id === action.payload)
          return { ...service, selected: true };

        if (service.selected) return { ...service, selected: false };

        return service;
      });

    case SET_SERVICE_LIST:
      return sortServicesByDate([...action.payload]);

    case SET_SERVICE_LIST_LOADING:
      return setLoading(state);

    case UPDATE_SERVICE:
      return state.map(service =>
        service.id === action.payload.id
          ? { ...service, ...action.payload }
          : service
      );

    case UPDATE_SERVICE_LIST:
      return mergeListsById(state, action.payload);

    default:
      return state;
  }
};
