import { connect } from "react-redux";

import ApplicationCriteriaQuestion from "./ApplicationCriteriaQuestion";

const mapStateToProps = state => {
  return {
    countries: state.countries,
    markets: state.tags
  };
};

export default connect(mapStateToProps)(ApplicationCriteriaQuestion);
