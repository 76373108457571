import React from "react";
import moment from "moment";

import TableRow from "@material-ui/core/TableRow";

import ErrorPanelSmall from "../../common/Errors/ErrorPanelSmall";
import LoadingPanel from "../../common/LoadingPanel";

import CompanyPanel from "../../common/CompanyPanel";

import LocalLink from "../../common/LocalLink";
import PulseIcon from "../../common/PulseIcon";
import AppointmentDeadline from "../../Meetup/AppointmentDeadline";

import CustomTableCell from "../../common/StyledTable/CustomTableCell";
import TagLabel from "../../common/TagLabel";

import { documentTypeLabelsShort } from "../config";

export { renderTopics } from "../../common/StyledTable/utils";

export const formatType = document => renderType(document);

export const renderErrorRow = (error, columns, classes) => {
  return (
    <TableRow className={classes.row} style={{ backgroundColor: "white" }}>
      <CustomTableCell colSpan={columns}>
        <ErrorPanelSmall error={error} />
      </CustomTableCell>
    </TableRow>
  );
};

export const renderLoadingRow = (columns, classes) => {
  return (
    <TableRow className={classes.row} style={{ backgroundColor: "white" }}>
      <CustomTableCell colSpan={columns}>
        <LoadingPanel />
      </CustomTableCell>
    </TableRow>
  );
};

export const renderApplicationDate = (document, classes) => {
  return formatDate(document.created_at);
};

export const renderApplicationStatus = (document, classes) => {
  const label = ["Unsuccessful", "Pending", "Accepted"][document.is_approved];
  const color = ["red", "grey", "green"][document.is_approved];
  const solid = [true, false, true][document.is_approved];

  return (
    <TagLabel
      label={label}
      color={color}
      solid={solid}
      style={{ marginLeft: -5 }}
    />
  );
};

export const renderDocumentDate = (document, classes) => {
  if (document.program) return renderDate(document.program, classes);
  if (document.think_tank) return renderDate(document.think_tank, classes);
};

export const renderDocumentLocation = (document, classes) => {
  if (document.program) return renderLocation(document.program, classes);
  if (document.think_tank) return renderLocation(document.think_tank, classes);
};

export const renderDocumentDetails = (document, classes) => {
  if (document.program) return renderDetails(document.program, classes);
  if (document.think_tank) return renderDetails(document.think_tank, classes);
};

export const renderDocumentOrganiser = (document, classes) => {
  if (document.program) return renderOrganiser(document.program, classes);
  if (document.think_tank) return renderOrganiser(document.think_tank, classes);
};

export const renderDocumentType = (document, classes) => {
  if (document.program) return renderType(document.program, classes);
  if (document.think_tank) return renderType(document.think_tank, classes);
};

export const renderType = document => {
  const type =
    document.program_type || document.meeting_type || document.think_tank_type;

  return type ? (
    <TagLabel
      label={documentTypeLabelsShort[type] || type}
      color="gold"
      style={{ marginLeft: -5 }}
    />
  ) : null;
};

export const formatDate = (
  string,
  options = {
    year: "numeric",
    month: "short",
    day: "numeric"
  }
) => {
  return new Date(string).toLocaleDateString("en-GB", options);
};

export const renderDate = (document, classes) => {
  const { start_datetime } = document;

  if (!start_datetime) return "Ongoing";

  return formatDate(start_datetime, {
    month: "short",
    day: "numeric"
  });
};

export const renderDateRange = (document, classes) => {
  const { application, start_datetime, end_datetime } = document;
  const deadline = application.deadline_datetime;

  if (!start_datetime && deadline) {
    return `Apply by ${moment(deadline).format("Do MMMM")}`;
  }

  if (!start_datetime) {
    return "Ongoing";
  }

  if (!end_datetime) {
    return formatDate(start_datetime);
  }

  const startDate = formatDate(start_datetime, {
    month: "short",
    day: "numeric"
  });

  const endDate = formatDate(end_datetime);

  return [startDate, endDate].join(" to ");
};

export const renderDeadline = application => {
  if (!application) return null;
  const deadline = application.deadline_datetime;

  return deadline ? (
    <AppointmentDeadline
      countdown={true}
      countdownText="to apply"
      deadline_datetime={deadline}
    />
  ) : (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PulseIcon
        color="#85C97C"
        pulse={false}
        style={{ marginRight: 0, marginBottom: 1 }}
      />{" "}
      <div style={{ color: "#2A2B2C", fontSize: 14, fontWeight: 600 }}>
        Open
      </div>
    </div>
  );
};

export const renderDetails = (document, classes) => {
  return (
    <div>
      <div className={classes.documentName}>
        <LocalLink link={document.link}>{document.name}</LocalLink>
      </div>
      {renderDeadline(document.application)}
      <div className={classes.documentDescription}>{document.description}</div>
    </div>
  );
};

export const renderLocation = (document, classes) => {
  const { location } = document;

  return location ? location.city : "Global";
};

export const renderOrganiser = (document, classes) => {
  if (!document.company) return null;

  return (
    <CompanyPanel
      classes={{
        label: classes.companyLabel,
        logo: classes.companyLogo
      }}
      radius={15}
      size={30}
      company={document.company || {}}
    />
  );
};

export const renderImage = (document, classes) => {
  if (!document.logo_image_url) return null;

  return (
    <img
      className={classes.logoImage}
      src={document.logo_image_url}
      alt="program logo"
    />
  );
};

export const renderImageCell = (document, classes) => {
  return <CustomTableCell>{renderImage(document, classes)}</CustomTableCell>;
};

export const renderApprovedApplications = document =>
  document.approved_applications;

export const renderDeclinedApplications = document =>
  document.declined_applications;

export const renderPendingApplications = document =>
  document.pending_applications;

export const renderPublishedDate = (document, classes) => {
  const { approved_on_datetime, is_approved } = document;

  if (is_approved === 1)
    return (
      <TagLabel
        label="Draft"
        color="red"
        solid={true}
        style={{ marginLeft: -5 }}
      />
    );

  if (approved_on_datetime) {
    return formatDate(approved_on_datetime);
  }

  return null;
};
