import React from "react";

import StartupsListViewPage from "../components/Startups/StartupsListViewPage";

import PageTemplate from "./PageTemplate";

const Startups = props => {
  return (
    <PageTemplate>
      <StartupsListViewPage />
    </PageTemplate>
  );
};

export default Startups;
