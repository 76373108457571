import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { loadConnections, updateConnections } from "../../Reducers/Connections";

import ConnectionsList from "./ConnectionsList";

class ConnectionsListContainer extends React.Component {
  static propTypes = {
    connections: PropTypes.array.isRequired,
    loadConnections: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { connections } = this.props;

    if (connections.length) {
      this.props.updateConnections();
    } else {
      this.props.loadConnections();
    }
  }

  render() {
    const { loadConnections, updateConnections, ...otherProps } = this.props;
    return <ConnectionsList {...otherProps} />;
  }
}

const mapStateToProps = state => {
  return {
    connections: state.connections,
    company: state.company,
    profile: state.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadConnections: () => dispatch(loadConnections()),
    updateConnections: cons => dispatch(updateConnections(cons))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectionsListContainer);
