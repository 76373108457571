import React from "react";

import Banner from "../components/common/Banner";
import NotFound from "../components/common/Errors/NotFound";

import PageTemplate from "./PageTemplate";

const NotFoundPage = props => {
  return (
    <PageTemplate>
      <Banner title="404" />
      <NotFound />
    </PageTemplate>
  );
};

export default NotFoundPage;
