import React from "react";

import TagLabel from "../TagLabel";

export const renderTopics = (topics = []) => {
  const _topics = topics
    .slice(0, 3)
    .map(topic => (
      <TagLabel
        key={topic}
        label={topic}
        color="blue"
        style={{ marginTop: 5 }}
      />
    ));

  const more =
    topics.length > 3 ? (
      <TagLabel
        label={`+${topics.length - 3}`}
        color="grey"
        style={{ marginTop: 5 }}
      />
    ) : null;

  return (
    <React.Fragment>
      {_topics}
      {more}
    </React.Fragment>
  );
};
