import React from "react";

import imgFoundersFind from "../assets/images/for-founders/colinked-for-founders-find.png";
import imgFoundersReach from "../assets/images/for-founders/colinked-for-founders-reach.png";
import imgFoundersMB from "../assets/images/for-founders/colinked-for-founders-moneyball.png";

import imgFormCharacters from "../assets/images/homepage/colinked-homepage-form-characters.png";

import logoPS from "../assets/images/for-founders/founder-logos/colinked-polystream-logo.png";
import logoClip from "../assets/images/for-founders/founder-logos/colinked-digital-clipboard-logo.png";
import logoSnap from "../assets/images/for-founders/founder-logos/colinked-plan-snap-logo.png";
import logoAphex from "../assets/images/for-founders/founder-logos/colinked-aphex-logo.png";
import logoYecco from "../assets/images/for-founders/founder-logos/colinked-yecco-logo.png";
import logoFitswarm from "../assets/images/for-founders/founder-logos/colinked-fitswarm-logo.png";
import logoByzgen from "../assets/images/for-founders/founder-logos/colinked-byzgen-logo.png";

import logoTempo from "../assets/images/for-founders/founder-logos/colinked-tempo-logo.png";
import logoFiryfly from "../assets/images/for-founders/founder-logos/colinked-firefly-logo.png";

import logoEventopedia from "../assets/images/for-founders/founder-logos/colinked-eventopedia-logo.png";
import logoSeed from "../assets/images/for-founders/founder-logos/colinked-seedlegals-logo.png";
import logoUnmind from "../assets/images/for-founders/founder-logos/colinked-unmind-logo.png";

import SignupForm from "./common/SignupForm";

const ForFounders = () => {
  return (
    <div>
      <section className="page-header-centered">
        <p className="uppercase">For Founders</p>
        <h1>
          Develop <span>new routes</span> to markets and get the{" "}
          <span>support</span> you need
        </h1>
      </section>

      <section className="content-padding-large">
        <section className="benefit">
          <div className="image left">
            <img
              src={imgFoundersFind}
              alt="Colinked - Illustration which includes icons showing how founders can find corporates"
            />
          </div>

          <div className="text">
            <h2>Find Corporate Programs</h2>
            <p>
              Discover corporate engagement programs to validate your business
              and find new markets. You can find and apply to programs varying
              from accelerators to hackathons.
            </p>
          </div>
        </section>

        <section className="benefit reverse">
          <div className="text left">
            <h2>Reach out to Corporates</h2>
            <p>
              Dying to reach out to a certain company? Request to connect with
              them through Colinked and find the right decision maker to engage
              with to take your business to the next level.
            </p>
          </div>

          <div className="image">
            <img
              src={imgFoundersReach}
              alt="Colinked - Illustration which includes icons showing how founders can reach corporates"
            />
          </div>
        </section>

        <section className="benefit">
          <div className="image left">
            <img
              src={imgFoundersMB}
              alt="Colinked - Illustration which includes icons showing how founders can use the moneyball service"
            />
          </div>

          <div className="text">
            <h2>Use our InvestReady? Service</h2>
            <p>
              Looking to fundraise or get your duedil in order? We’re here to
              help! Our Founder InvestReady? Service is designed to create
              financial modelling and due diligence reports for scale ups during
              your investment rounds.
            </p>
          </div>
        </section>
      </section>

      <section className="logo-grid">
        <h2>Our Founder Members</h2>
        <p>
          No matter what stage you company is, <br /> Colinked can impact your
          business.
        </p>

        <div className="grid-items">
          <img src={logoPS} alt="Colinked - Polystream logo" />

          <img src={logoClip} alt="Colinked - Digital Clipboard logo" />

          <img src={logoSnap} alt="Colinked - Plansnap logo" />

          <img src={logoAphex} alt="Colinked - Aphex logo" />

          <img src={logoYecco} alt="Colinked - Yecco logo" />

          <img src={logoFitswarm} alt="Colinked - Fitswarm logo" />

          <img src={logoByzgen} alt="Colinked - Byzgen logo" />

          <img src={logoTempo} alt="Colinked - Tempo logo" />

          <img src={logoFiryfly} alt="Colinked - Firefly logo" />

          <img src={logoEventopedia} alt="Colinked - Eventopedia logo" />

          <img src={logoSeed} alt="Colinked - Seedlegals logo" />

          <img src={logoUnmind} alt="Colinked - Unmind logo" />
        </div>
      </section>

      <section className="form-section">
        <div className="form-container">
          <h2>Apply to be a Founder Member</h2>
          <p>Apply to join other founder teams currently on Colinked:</p>

          <SignupForm
            buttonLable="Request to Join"
            showLinkedin={false}
            url="/api/companies/founders/apply/"
          />

          <img
            src={imgFormCharacters}
            alt="Colinked - Character avatars shaking hands"
            width={243}
            height={198}
          />
        </div>
      </section>
    </div>
  );
};

export default ForFounders;
