import React from "react";
import { PropTypes } from "prop-types";

import withWidth from "@material-ui/core/withWidth";

import {
  COMPANY_TYPE_CORP,
  COMPANY_TYPE_FOUNDER
} from "../../Reducers/Companies";

import Banner from "../common/Banner";

import CompanyActions from "./ActionMenu";
import CompanyProfile from "./ProfileView";
import ProfileCompletionHint from "./ProfileCompletionHint";

const CompanyProfilePage = props => {
  const { company, companyType, profile } = props;

  const breadcrumbs =
    companyType === COMPANY_TYPE_CORP
      ? [{ name: "Corporates", link: "/corporations" }]
      : [{ name: "Founders", link: "/startups" }];

  return (
    <div>
      <Banner
        title={company.name || "...loading"}
        logo={company.logo_image_url}
        breadcrumbs={breadcrumbs}
      />
      <CompanyActions company={company} profile={profile} />
      <ProfileCompletionHint company={company} profile={profile} />
      <CompanyProfile {...props} />
    </div>
  );
};

CompanyProfilePage.propTypes = {
  company: PropTypes.object.isRequired,
  companyType: PropTypes.oneOf([COMPANY_TYPE_CORP, COMPANY_TYPE_FOUNDER])
    .isRequired,
  profile: PropTypes.object.isRequired,
  programs: PropTypes.array.isRequired,
  challenges: PropTypes.array.isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

CompanyProfilePage.defaultProps = {
  company: {},
  programs: [],
  challenges: []
};

export default withWidth()(CompanyProfilePage);
