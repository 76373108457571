import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    marginTop: "64px",
    marginBottom: "32px",
    borderBottom: "1px solid #E6E7E9",
    paddingBottom: 32
  },
  headline: {
    fontSize: "16px",
    color: "#AAADB6",
    fontWeight: "bolder"
  },
  subheading: {
    fontSize: "16px",
    fontWeight: "bolder"
  }
};

const Section = props => {
  const {
    children,
    classes,
    component,
    fullwidth,
    title,
    variant,
    ...other
  } = props;
  const rootClass = fullwidth ? `body ${classes.root}` : classes.root;

  return (
    <div className={rootClass} {...other}>
      {title && (
        <Typography
          paragraph
          variant={variant}
          component={component}
          classes={{
            headline: classes.headline,
            subheading: classes.subheading
          }}
        >
          {title}
        </Typography>
      )}
      {children}
    </div>
  );
};

Section.propTypes = {
  fullwidth: PropTypes.bool,
  title: PropTypes.string
};

Section.defaultProps = {
  component: "h2",
  fullwidth: true,
  title: "",
  variant: "headline"
};

export default withStyles(styles)(Section);
