import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import emailvalidator from "email-validator";

import BooleanField from "../BooleanField";
import ImageUploadField from "../ImageUploadField";
import TextInputField from "../TextInputField";

import {
  getOutlineButtonClasses,
  getPrimaryButtonClasses
} from "../../../styles/buttonStyles";

const { iconClass, ...outlineButtonStyles } = getOutlineButtonClasses();
const { iconClass: iC2, ...primaryButtonStyles } = getPrimaryButtonClasses(
  "#6592CC"
);

const OutlineButton = withStyles(outlineButtonStyles)(Button);
const PrimaryButton = withStyles(primaryButtonStyles)(Button);

const styles = {
  root: {
    boxSizing: "border-box",
    border: "1px solid #EDEDEF",
    padding: 30,
    marginBottom: 20
  },
  buttons: {
    display: "flex",
    marginTop: 30
  },
  iconClass: {
    fill: "white",
    marginRight: 5
  },
  switchRoot: {
    marginTop: 10
  },
  title: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 600
  }
};

const DEFAULTS = {
  avatar_image_url: "",
  bio: "",
  is_company_founder: false,
  first_name: "",
  last_name: "",
  email: "",
  job_title: ""
};

class EditMemberForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: { ...DEFAULTS, ...props.account },
      errors: 0,
      validationErrors: {}
    };
  }

  handleCancel = () => {
    const { account, removeOnCancel } = this.props;

    this.props.onChange({ ...account, editing: false }, removeOnCancel);
  };

  handleBlur = id => () => {
    const value = this.state.account[id];
    const validationErrors = { ...this.state.validationErrors };

    validationErrors[id] = this.validate(id, value);
    const errors = Object.values(validationErrors).filter(e => e).length;

    this.setState({ errors, validationErrors });
  };

  handleChange = id => value => {
    const account = { ...this.state.account, [id]: value };

    this.setState({ account });
  };

  isValid = () => {
    const { requiredFields } = this.props;
    const { account, errors } = this.state;

    if (errors) return false;

    return requiredFields.map(id => account[id]).every(value => value);
  };

  update = () => {
    const { account } = this.state;

    this.props.onChange({ ...account, editing: false });
  };

  validate = (id, value) => {
    const { labels, emptyErrors, requiredFields } = this.props;

    // check required fields
    if (!value) {
      if (requiredFields.indexOf(id) !== -1) {
        return emptyErrors[id] || `${labels[id] || id} is required`;
      }
    }

    if (id === "email" && !emailvalidator.validate(value)) {
      return "Enter a valid email address";
    }

    return null;
  };

  renderTextInput = id => {
    const { labels, limits, primary, requiredFields } = this.props;
    const { account } = this.state;
    const error = this.state.validationErrors[id];

    const helperText =
      primary && id === "email"
        ? "Important - This is the primary contact email for application updates!"
        : null;

    return (
      <TextInputField
        key={id}
        label={labels[id] || id}
        limit={limits[id]}
        onBlur={this.handleBlur(id)}
        onChange={this.handleChange(id)}
        required={requiredFields.indexOf(id) !== -1}
        value={account[id]}
        error={Boolean(error)}
        helperText={error || helperText}
        width={440}
      />
    );
  };

  render() {
    const { classes, limits, removeOnCancel, title } = this.props;
    const { account } = this.state;

    const inputs = ["first_name", "last_name", "email", "job_title"].map(
      this.renderTextInput
    );

    return (
      <div className={classes.root}>
        <div className={classes.title}>{title}</div>

        {inputs}

        <TextInputField
          label="Short Bio"
          limit={limits["bio"]}
          onBlur={this.handleBlur("bio")}
          onChange={this.handleChange("bio")}
          rows={4}
          showLimit={true}
          value={account.bio}
          width={660}
        />

        <BooleanField
          classes={{ root: classes.switchRoot }}
          id="is_company_founder"
          label="Company Founder"
          onChange={this.handleChange("is_company_founder")}
          value={account.is_company_founder}
        />

        <ImageUploadField
          id="avatar_image_url"
          label="Upload a profile photo"
          helperText="Profile photos should be square and a minimum 400 x 400 pixels. Profile photos add a personal touch and help to build trust! Max file size 2mb"
          onChange={this.handleChange("avatar_image_url")}
          value={account.avatar_image_url}
          width={100}
          height={100}
          radius={50}
        />

        <div className={classes.buttons}>
          <OutlineButton onClick={this.handleCancel}>Cancel</OutlineButton>
          <PrimaryButton disabled={!this.isValid()} onClick={this.update}>
            {removeOnCancel ? "Add" : "Update"}
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

EditMemberForm.propTypes = {
  account: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  limits: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  removeOnCancel: PropTypes.bool,
  requiredFields: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

EditMemberForm.defaultProps = {
  account: {},
  emptyErrors: {
    first_name: "Your team member's first name is required",
    last_name: "Your team member's last name is required",
    email: "Enter an email address",
    job_title: "Your team member's job title is required"
  },
  labels: {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email Address",
    job_title: "Job Title / Role"
  },
  limits: {
    first_name: 30,
    last_name: 30,
    job_title: 40,
    bio: 300
  },
  onChange: () => {},
  requiredFields: ["first_name", "last_name", "email", "job_title"],
  title: "Edit Account"
};

export { EditMemberForm, styles };
export default withStyles(styles)(EditMemberForm);
