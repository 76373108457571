import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import ws_cic_logo from "../../assets/ws_cic_logo.svg";
import ws_fic_logo from "../../assets/ws_fic_logo.svg";
import ws_iic_logo from "../../assets/ws_iic_logo.svg";

import Banner from "../common/Banner";
import EventsCalendar from "../Events/EventsCalendar";

const styles = {
  bgSection: {
    paddingTop: 60,
    paddingBottom: 30
  },
  body1: {
    lineHeight: "21px",
    textAlign: "justify"
  },
  clubName: {
    color: "#3D5A80",
    fontWeight: 600
  },
  events: {
    marginTop: 30,
    outline: "1px solid red"
  },
  eventsRoot: {
    maxWidth: 400,
    marginBottom: 30
  },
  eventsHeader: {
    paddingTop: 0,
    marginTop: 0
  },

  link: {
    color: "#3D5A80",
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  linksContainer: {
    marginTop: 30,
    marginBottom: 30
  },
  clubSection: {},
  gridContainer: {},
  logo: {
    height: 100,
    marginRight: 30,
    marginBottom: 15
  },
  sectionRoot: {
    marginTop: 20,
    borderBottom: "none",
    paddingBottom: 0
  },
  sectionLabel: {
    color: "#6592CC"
  },
  xs: {
    marginLeft: 0,
    width: "100%"
  }
};

const CommunitiesPage = props => {
  const { classes, events } = props;

  return (
    <React.Fragment>
      <Banner
        title="Communities"
        subtitle="Our communities bring together Founders, Corporates and Investors"
      />
      <div className={classes.bgSection}>
        <div className={`body ${classes.clubSection}`}>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={12} md={8} lg={8} style={{ paddingRight: "80px" }}>
              <div style={{ display: "flex" }}>
                <img
                  className={classes.logo}
                  src={ws_cic_logo}
                  alt="Corporate Innovation Club logo"
                />
                <Typography
                  paragraph
                  variant="body1"
                  classes={{ body1: classes.body1 }}
                >
                  The Whitespace{" "}
                  <span className={classes.clubName}>
                    Corporate Innovation Club
                  </span>{" "}
                  is a community of over 60 international and global corporate
                  brands as well as NGOs and government bodies – organisations
                  that share a passion and interest in using an open innovation
                  approach to help create new, different experiences and revenue
                  models.
                </Typography>
              </div>
              <Typography
                paragraph
                variant="body1"
                classes={{ body1: classes.body1 }}
              >
                The senior representatives either have a direct remit around
                driving innovation on behalf of their organisation, or are a key
                contributor to it. The purpose of our community is to learn from
                each other – both successes and failures – and from expert guest
                speakers around specific topics or themes.
              </Typography>
              <Typography variant="body1" classes={{ body1: classes.body1 }}>
                The Club meets every two months and is always hosted by a Club
                member and chaired by Whitespace. The Club is free to
                participate in and the members have collectively agreed on the
                topics, to ensure that the discussion will be fully relevant to
                their needs and provide scope for sharing knowledge and learning
                from each other’s experiences. Depending on the topic, the Club
                also invites external experts to present, engage and provoke an
                honest and open dialogue amongst all the members.
              </Typography>
              <div className={classes.linksContainer}>
                <a
                  className={classes.link}
                  href="https://docs.wixstatic.com/ugd/d0d1e1_54d40d64f8a345bd8e0e566c9e3b9d32.pdf"
                  alt="Corporate Innovation Club brochure"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View the Corporate Innovation Club brochure
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <EventsCalendar
                classes={{
                  header: classes.eventsHeader,
                  root: classes.eventsRoot,
                  xs: classes.xs
                }}
                events={events.filter(event => event.event_type === "corp")}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.bgSection} style={{ backgroundColor: "#F2F3F4" }}>
        <div className={`body ${classes.clubSection}`}>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={12} md={8} lg={8} style={{ paddingRight: "80px" }}>
              <div style={{ display: "flex" }}>
                <img
                  className={classes.logo}
                  src={ws_fic_logo}
                  alt="Founders Innovation Club logo"
                />
                <Typography
                  paragraph
                  variant="body1"
                  classes={{ body1: classes.body1 }}
                >
                  The Whitespace{" "}
                  <span className={classes.clubName}>
                    Founders Innovation Club
                  </span>{" "}
                  is a community of tech startup founders, who have a shared
                  passion for innovation and wish to work and engage with
                  Corporates as part of their Go To Market approach.
                </Typography>
              </div>
              <Typography
                paragraph
                variant="body1"
                classes={{ body1: classes.body1 }}
              >
                Drawing from different stages and experiences of startups, this
                community runs alongside the Whitespace Corporate &amp; Investor
                Innovation Clubs – and where it makes sense, the Whitespace team
                will brings the three communities together to network and
                potentially collaborate.
              </Typography>
              <Typography variant="body1" classes={{ body1: classes.body1 }}>
                The Club meets every two months and is always hosted by a
                Corporate Club member and chaired by Whitespace. The Club is
                free to participate in and the members have collectively agreed
                on the topics, to ensure that the discussion will be fully
                relevant to their needs and provide scope for sharing knowledge
                and learning from each other’s experiences. Depending on the
                topic, the Club also invites external experts to present, engage
                and provoke an honest and open dialogue amongst all the members.
              </Typography>
              <div className={classes.linksContainer}>
                <a
                  className={classes.link}
                  href="https://docs.wixstatic.com/ugd/d0d1e1_0d7e0c089a0642f7b938fa558e01b21f.pdf"
                  alt="Founders Innovation Club brochure"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View the Founders Innovation Club brochure
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <EventsCalendar
                classes={{
                  header: classes.eventsHeader,
                  root: classes.eventsRoot,
                  xs: classes.xs
                }}
                events={events.filter(event => event.event_type === "founder")}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.bgSection}>
        <div className={`body ${classes.clubSection}`}>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={12} md={8} lg={8} style={{ paddingRight: "80px" }}>
              <div style={{ display: "flex" }}>
                <img
                  className={classes.logo}
                  src={ws_iic_logo}
                  alt="Investor Innovation Club logo"
                />
                <Typography
                  paragraph
                  variant="body1"
                  classes={{ body1: classes.body1 }}
                >
                  The Whitespace{" "}
                  <span className={classes.clubName}>
                    Investor Innovation Club
                  </span>{" "}
                  has been established as a community of professional and
                  sophisticated investors with an interest in and a passion for
                  how technology innovation can create different and new
                  experiences and revenue models and how technology scale ups in
                  the UK are a key part of this.
                </Typography>
              </div>
              <Typography
                paragraph
                variant="body1"
                classes={{ body1: classes.body1 }}
              >
                Running alongside the Whitespace Corporate and Founders
                Innovation Clubs in 2018, the Investor Innovation Club brings
                together a community of investors to learn from each other –
                both successes and failures – but also learn from invited
                subject matter experts around specific topics or themes. In
                addition there will be points of engagement throughout the year
                with both the Corporate members of the Corp’ Innovation Club and
                the founders of the Founders Innovation Club.
              </Typography>
              <Typography variant="body1" classes={{ body1: classes.body1 }}>
                The Club meets every two months and is always hosted by a
                Corporate or Investor Club member and chaired by Whitespace. The
                Club is free to participate in and the members have collectively
                agreed on the topics, to ensure that the discussion will be
                fully relevant to their needs and provide scope for sharing
                knowledge and learning from each other’s experiences. Depending
                on the topic, the Club also invites external experts to present,
                engage and provoke an honest and open dialogue amongst all the
                members.
              </Typography>
              <div className={classes.linksContainer}>
                <a
                  className={classes.link}
                  href="https://docs.wixstatic.com/ugd/d0d1e1_756d97aa722f404a869cfc7d36ea6933.pdf"
                  alt="Founders Innovation Club brochure"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View the Investor Innovation Club brochure
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <EventsCalendar
                classes={{
                  header: classes.eventsHeader,
                  root: classes.eventsRoot,
                  xs: classes.xs
                }}
                events={events.filter(event => event.event_type === "investor")}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(CommunitiesPage);
