import { ADD_TAG, SET_TAGS } from "./TagsActions";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_TAG:
      return [...state, action.payload].sort((a, b) => {
        return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
      });

    case SET_TAGS:
      return action.payload;

    default:
      return state;
  }
};
