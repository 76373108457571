const rowStyle = {
  maxHeight: 110,
  backgroundColor: "white",
  borderBottom: "1px solid #E6E7E9",
  "&:last-child": {
    borderBottom: "none"
  },
  "&:hover": {
    backgroundColor: "#F3F7FB"
  }
};

const styles = {
  root: {
    // marginTop: 30,
    backgroundColor: "white",
    width: "100%",
    overflowX: "auto"
  },

  table: {
    minWidth: 900,
    overflowX: "auto"
  },
  row: {
    ...rowStyle
  },
  td: {},
  th: {
    whiteSpace: "nowrap"
  },
  footer: {
    borderTop: "1px solid #E6E7E9"
  },

  companyLabel: {
    fontSize: 13
  },
  companyLogo: {
    marginRight: 10
  },

  rowNoBorder: {
    ...rowStyle,
    borderBottom: "none"
  }
};

export default styles;
