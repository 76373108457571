export const formatFileSize = size => {
  if (size === 0) return "";

  if (size > 1024 * 1024) {
    const mb = Math.round(size / (1024 * 1024), 2);
    return `${mb} MB`;
  }

  if (size > 1024) {
    const kB = Math.round(size / 1024, 2);
    return `${kB} kB`;
  }

  return `${size} bytes`;
};

export const getPagesWithErrors = (sections, keys) => {
  const keysMap = keys.reduce((map, key) => {
    map[key] = true;

    return map;
  }, {});

  return sections
    .map((section, page) => {
      const { formFields } = section;

      if (formFields && formFields.some(field => keysMap[field.id])) {
        return page;
      }

      return -1;
    })
    .filter(page => page !== -1);
};

const getFieldValues = (state, field) => {
  if (field.type === "bool") {
    state[field.id] = field.value;
  } else if (field.id) {
    state[field.id] = field.value || "";
  } else {
    console.warn(`No id for field '${field.label}'.`);
  }

  return state;
};

export const getStateFromSection = (section, state = {}) => {
  if (section.formFields) {
    return section.formFields
      .filter(field => field.type !== "separator")
      .reduce(getFieldValues, state);
  }

  return state;
};

export const getStateFromSections = sections => {
  const state = {};

  sections.forEach(section => {
    if (section.formFields) {
      section.formFields
        .filter(field => field.type !== "separator")
        .reduce(getFieldValues, state);
    }
  });

  return state;
};

export const getKeysFromSections = sections => {
  const keys = [];

  sections.forEach(section => {
    if (section.formFields) {
      section.formFields
        .filter(field => field.type !== "separator")
        .forEach(field => {
          if (field.id) {
            keys.push(field.id);
          } else {
            console.warn(`No id for field '${field.label}'.`);
          }
        });
    }
  });

  return keys;
};

export const idFromLabel = label => {
  return label.replace(/\s+/g, "-").toLowerCase();
};
