import { connect } from "react-redux";

import MessagePanel from "./MessagePanel";

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(mapStateToProps)(MessagePanel);
