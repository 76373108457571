import React from "react";

import LocalLink from "./LocalLink";

const CompanyLink = props => {
  const { company } = props;

  if (!company) return null;

  if (typeof company === "string") return company;

  const { id, name, is_corporate } = company;

  if (!id) return name || "";

  const link = is_corporate ? `/corporate/${id}` : `/startup/${id}`;

  return <LocalLink label={name} link={link} />;
};

export default CompanyLink;
