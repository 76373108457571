import React from "react";
import { PropTypes } from "prop-types";

import withWidth from "@material-ui/core/withWidth";

import Banner from "../common/Banner";

import ErrorPanel from "../common/Errors/ErrorPanel";
import LoadingPanel from "../common/LoadingPanel";
import DocumentDetailsView from "./DocumentDetailsView";

import ManagePanel from "./ManagePanel/ManagePanel";

const DocumentBanner = props => {
  const { document, error, loading, width } = props;

  const title = error
    ? "Error"
    : loading
    ? "loading ..."
    : props.title || document.name;

  return (
    <Banner
      title={title}
      logo={document.logo_image_url}
      fontSize={width === "xs" ? 22 : 32}
      document={document}
    />
  );
};

class DocumentDetailsViewConnected extends React.Component {
  static defaultProps = {
    BannerComponent: DocumentBanner,
    ManagePanelComponent: ManagePanel,
    MenuComponent: null,
    ViewComponent: DocumentDetailsView
  };

  static propTypes = {
    BannerComponent: PropTypes.func.isRequired,
    ManagePanelComponent: PropTypes.func,
    ViewComponent: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    doctype: PropTypes.string.isRequired,
    documents: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    loadDocument: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    labels: PropTypes.array,
    setWindowTitle: PropTypes.func,
    subtitles: PropTypes.array,
    match: PropTypes.shape({
      params: PropTypes.shape({
        documentId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  };

  state = {
    activity: null,
    error: null,
    loading: true,
    document: {},
    documentId: null
  };

  componentDidMount() {
    this.loadDocument(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.loadDocument(nextProps);
  }

  loadDocument = props => {
    const { documentId } = props.match.params;
    const id = parseInt(documentId, 10);

    const document = props.documents.find(d => d.id === id);

    if (document) {
      if (document !== this.state.document) {
        this.setState({ document, id, loading: false });
      }
    } else if (id !== this.state.loading) {
      // start loading of a new document
      this.setState({ loading: id }, () => this.props.loadDocument(id));
    }
  };

  renderBody = () => {
    const { error, loading, document } = this.state;
    const { ViewComponent, doctype, width } = this.props;

    if (error) return <ErrorPanel error={error} />;

    if (loading) return <LoadingPanel />;

    if (document.loading) return <LoadingPanel />;

    if (document.id)
      return (
        <div
          style={{
            display: "flex",
            alignContent: "flex-start",
            justifyContent: "center",
            paddingTop: 30,
            paddingBottom: 90,
            opacity: document.loading ? 0.5 : 1
          }}
        >
          <ViewComponent doctype={doctype} document={document} width={width} />
        </div>
      );

    return null;
  };

  render() {
    const { error, loading, document } = this.state;
    const {
      BannerComponent,
      ManagePanelComponent,
      MenuComponent,
      company,
      profile,
      width
    } = this.props;

    return (
      <div>
        <BannerComponent
          error={error}
          loading={loading}
          document={document}
          width={width}
        />
        {MenuComponent && <MenuComponent document={document} />}
        {ManagePanelComponent && (
          <ManagePanelComponent
            company={company}
            document={document}
            profile={profile}
          />
        )}
        {this.renderBody()}
      </div>
    );
  }
}

export default withWidth()(DocumentDetailsViewConnected);
