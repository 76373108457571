import React from "react";

import PageTemplate from "./PageTemplate";

import ForInvestors from "../components/ForInvestors";

const ForInvestorsPage = props => {
  return (
    <PageTemplate>
      <ForInvestors />
    </PageTemplate>
  );
};

export default ForInvestorsPage;
