const statusStyle = {
  color: "white",
  fontSize: 14,
  fontWeight: 600,
  maxWidth: 320,
  textAlign: "center",
  padding: "11px 15px",
  lineHeight: "16px"
};

const styles = theme => ({
  banner: {
    height: 20
  },
  root: {
    width: "100vw",
    backgroundColor: "#F2F3F4",
    display: "flex",
    justifyContent: "center",
    paddingTop: 80,
    paddingBottom: 240
  },
  button: {
    height: 60,
    width: 330,
    marginTop: 20,
    boxShadow: "none"
  },
  error: {
    ...statusStyle,
    maxWidth: 300,
    backgroundColor: "#DB6E53"
  },
  loading: {
    ...statusStyle,
    backgroundColor: "#AAADB6"
  },
  success: {
    ...statusStyle,
    backgroundColor: "#9CB59E"
  },
  buttonLabel: {
    color: "white",
    fontSize: 16,
    fontWeight: 600
  },
  buttonRaised: {
    backgroundColor: "#6592CC",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,0.1)"
  },
  formContainer: {
    backgroundColor: "white",
    padding: 30,
    boxShadow: "0 15px 10px 0 rgba(55,60,68,0.05)",
    marginBottom: 30
  },
  formTitle: {
    color: "#6592CC",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 30
  },
  passwordLink: {
    color: "#3D5A80",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "17px",
    cursor: "pointer"
  },
  testButtons: {
    maxWidth: 330,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    margin: 10,
    marginBottom: 0
  }
});

export default styles;
