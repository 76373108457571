import React from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import EmptyListState from "../common/EmptyListState";

import Button from "@material-ui/core/Button";

import {
  getOutlineButtonClasses,
  getPrimaryButtonClasses
} from "../../styles/buttonStyles";

const outlineStyles = getOutlineButtonClasses();
const primaryStyles = getPrimaryButtonClasses();

const OutlineButton = withStyles(outlineStyles)(Button);
const PrimaryButton = withStyles(primaryStyles)(Button);

export const EmptyProgramsList = () => {
  return (
    <EmptyListState
      title="No current programs."
      subtitle="Programs are offerings and events which founder companies and corporates can apply to aprticipate in."
    />
  );
};

export const EmptyProgramsListOwner = withRouter(props => {
  const { history } = props;

  return (
    <EmptyListState
      title="You have not created any programs yet."
      subtitle="Programs are offerings and events which founder companies and corporates can apply to aprticipate in."
    >
      <div style={{ margin: 20 }}>
        <PrimaryButton onClick={() => history.push("/programs/build")}>
          Create a program
        </PrimaryButton>
        <OutlineButton onClick={() => history.push("/programs")}>
          Browse Programs
        </OutlineButton>
      </div>
    </EmptyListState>
  );
});

export const EmptyThinkTanksList = () => {
  return (
    <EmptyListState
      title="No current Think Tanks."
      subtitle="Think Tanks encourage cooperation between corporations, or corporates and founders."
    />
  );
};

export const EmptyThinkTanksListOwner = withRouter(props => {
  const { history } = props;

  return (
    <EmptyListState
      title="You have not created a think tank yet."
      subtitle="Think Tanks encourage cooperation between corporations, or corporates and founders."
    >
      <div style={{ margin: 20 }}>
        <PrimaryButton onClick={() => history.push("/challenges/build")}>
          Create a Think Tank
        </PrimaryButton>
        <OutlineButton onClick={() => history.push("/challenges")}>
          Browse Think Tanks
        </OutlineButton>
      </div>
    </EmptyListState>
  );
});

export const EmptyApplicationsList = withRouter(props => {
  const { history } = props;

  return (
    <EmptyListState
      title="You haven't applied to any offerings yet."
      subtitle="You applications to programs and Think Tanks can be managed here. To get started, browse and search for programs and Think Tanks to discover new opportunities. "
    >
      <div style={{ margin: 20 }}>
        <OutlineButton onClick={() => history.push("/challenges")}>
          Browse Think Tanks
        </OutlineButton>
        <OutlineButton onClick={() => history.push("/programs")}>
          Browse Programs
        </OutlineButton>
      </div>
    </EmptyListState>
  );
});
