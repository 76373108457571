import { PropTypes } from "prop-types";

export const testimonial = PropTypes.shape({
  author: PropTypes.string.isRequired,
  author_company: PropTypes.string.isRequired,
  author_job_title: PropTypes.string,
  content: PropTypes.string.isRequired
});

export const testimonials = PropTypes.arrayOf(testimonial).isRequired;
