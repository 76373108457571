import {
  validateProfile,
  validateTags,
  validateURLOptional
} from "../Form/validate";

const pages = [
  {
    name: "About the Service",
    formFields: [
      {
        id: "about_the_service_sep",
        label: "About the service",
        helperText: "Basic data and appearance ...",
        type: "header"
      },

      {
        id: "card_image_url",
        label: "Upload your service logo",
        helperText:
          "This image will represent the service on the listings page. It should have a w:h ratio of 2:1 and be at least 300px wide.",
        type: "image",
        width: 300,
        height: 150,
        required: true
      },

      {
        id: "description",
        label: "Brief service description",
        helperText:
          "This one-line description is displayed in the services listing view. Try a short, snappy strapline to help sell your service.",
        required: true,
        limit: 250,
        type: "text"
      },

      {
        id: "is_popular",
        label: 'Mark this service as "popular"',
        type: "bool",
        value: false
      },

      {
        id: "header_tags",
        label: "Tags",
        helperText:
          "Add a brief description and logo image to help your service stand out.",
        type: "header",
        style: { marginTop: 30 }
      },
      {
        label: "What markets and topics are relevant to this service?",
        id: "tags",
        type: "tags",
        helperText: "Select relevant tags only",
        placeholder: "Start typing to see suggested topics ...",
        required: true,
        style: { marginTop: 30 },
        validate: validateTags(1, "topic"),
        value: [],
        width: 660
      },

      {
        id: "header_contact",
        label: "How to get in contact ",
        helperText:
          "Define how interested users can get in contact with the service provider.",
        type: "header"
      },
      {
        id: "mail_to_email",
        label: "Provide a contact email",
        limit: 140,
        required: true,
        type: "text",
        width: 330
      }
      // {
      //   id: "action_url",
      //   label: "... or provide a redirect URL to their website",
      //   width: 330,
      //   type: "text",
      //   limit: 250
      // }
    ],
    nextButtonLabel: "Next: About the Company"
  },
  {
    name: "Company",
    formFields: [
      {
        id: "header_company",
        label: "About the Provider",
        helperText: "Details on the company that's providing the service.",
        type: "header"
      },
      {
        id: "company_name",
        label: "Company name",
        required: true,
        limit: 140,
        type: "text"
      },
      {
        id: "company_mission_statement",
        label: "Company mission statement",
        required: true,
        limit: 140,
        type: "text"
      },
      {
        id: "company_logo_url",
        label: "Upload the company's logo",
        helperText:
          "This logo identifies the service provider. It should be round (square) and around 300px wide.",
        type: "image",
        width: 120,
        height: 120,
        radius: 60,
        required: true
      },
      {
        id: "company_url",
        label: "Company Link",
        helperText:
          'Provide a link to the company website; must begin with "https://" or "http://"',
        limit: 140,
        type: "text",
        validate: validateURLOptional
      }
    ]
  },
  {
    name: "Description",
    formFields: [
      {
        id: "header_profile",
        label: "Service Description",
        helperText: "Details on the service.",
        type: "header"
      },
      {
        id: "profile",
        label: "_Build Service profile",
        type: "profile",
        section: true,
        validate: validateProfile
      }
    ]
  },
  {
    name: "Testimonials",
    formFields: [
      {
        id: "header_testimonials",
        label: "Testimonials",
        helperText: "Add several testimonial blocks for the service.",
        type: "header"
      },
      {
        id: "testimonials",
        label: "_Testimonials",
        type: "testimonials",
        width: 660
      }
    ]
  }
];

export default pages;
