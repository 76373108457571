import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import placeholderLogo from "../../assets/corp_logo_placeholder.png";
import campaignPlaceholderImage from "../../assets/placeholder.jpg";

import iconCalendar from "../../assets/images/colinked-icon-calendar.svg";
import iconMapMarker from "../../assets/images/colinked-icon-map-marker.svg";

import AppointmentDeadline from "../Meetup/AppointmentDeadline";
import TagLabel from "../common/TagLabel";

const renderCompanyLogo = company => {
  const { logo_image_url } = company;

  return (
    <div className="company-logo">
      <img src={logo_image_url || placeholderLogo} alt="" />
    </div>
  );
};

const renderDeadline = application => {
  const deadline_datetime = application ? application.deadline_datetime : null;
  if (!deadline_datetime)
    return (
      <div className="tag indicator">
        <span className="dot" /> open
      </div>
    );

  return (
    <div className="tag indicator">
      <AppointmentDeadline
        deadline_datetime={deadline_datetime}
        countdown={true}
        countdownText="to go"
      />
    </div>
  );
};

const renderLocation = location => {
  if (!location) return null;

  return (
    <li>
      <span>
        <img src={iconMapMarker} alt="Colinked - Calendar Icon" />
      </span>{" "}
      {location.city}
    </li>
  );
};

const renderTime = (start, end) => {
  const body = !start
    ? ["Year-round program"]
    : [new Date(start).toDateString()];
  if (end) {
    body.push(new Date(end).toDateString());
  }

  return (
    <li>
      <span>
        <img src={iconCalendar} alt="Colinked - Calendar Icon" />
      </span>{" "}
      {body.join(" to ")}
    </li>
  );
};

const renderPromoImage = program => {
  const image = program.campaign_image_url || campaignPlaceholderImage;

  return image ? (
    <img
      src={image}
      alt="Colinked - Program Promotion illustration"
      width={360}
      height={180}
    />
  ) : (
    <div className="placeholder" />
  );
};

const renderProgramTag = (topic, color) => {
  const style = {
    height: 36,
    lineHeight: "36px",
    borderRadius: 18,
    fontWeight: 600,
    marginLeft: 0,
    marginRight: 10
  };

  return <TagLabel color={color} label={topic} style={style} />;
};

const renderProgramType = (type, config) => {
  const program_types = config.programs__program_types || {};
  const _type = program_types[type] || type;

  return renderProgramTag(_type, "gold");
};

const FeaturedProgramCard = props => {
  const { history, program } = props;

  return (
    <div
      onClick={() => {
        history.push(`/program/${program.id}`);
        return false;
      }}
      className="card"
    >
      <div className="card-header">
        {renderDeadline(program.application)}

        <div className="overlay" />

        {renderPromoImage(program)}
        {renderCompanyLogo(program.company)}
      </div>

      <div className="card-content">
        <h3>{program.name}</h3>
        <p>{program.description}</p>
        <ul>
          {renderTime(program.start_datetime, program.end_datetime)}
          {renderLocation(program.location)}
        </ul>
      </div>

      <div className="card-footer">
        {renderProgramType(program.program_type, props.config)}
        {program.topics.length
          ? renderProgramTag(program.topics[0], "blue")
          : null}
        {program.topics.length > 1 ? (
          <div>
            <strong>+{program.topics.length - 1}</strong>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ config: state.config });

export default withRouter(connect(mapStateToProps)(FeaturedProgramCard));
