const styles = {
  banner: {
    height: 20
  },
  root: {
    width: "100vw",
    backgroundColor: "#F2F3F4",
    display: "flex",
    justifyContent: "center",
    paddingTop: 80,
    paddingBottom: 240
  },
  button: {
    height: 60,
    width: 330,
    marginTop: 20,
    boxShadow: "none"
  },
  error: {
    color: "red",
    fontSize: 14,
    maxWidth: 320,
    textAlign: "center",
    outline: "1px solid red",
    padding: 5
  },
  buttonLabel: {
    color: "white",
    fontSize: 16,
    fontWeight: 600
  },
  buttonRaised: {
    backgroundColor: "#6592CC",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,0.1)"
  },
  centeredColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  formContainer: {
    backgroundColor: "white",
    padding: 30,
    boxShadow: "0 15px 10px 0 rgba(55,60,68,0.05)",
    marginBottom: 30
  },
  formTitle: {
    color: "#6592CC",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 30
  },
  passwordLink: {
    color: "#3D5A80",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "17px",
    cursor: "pointer"
  },
  testButtons: {
    maxWidth: 310,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    margin: 10,
    marginBottom: 0
  }
};

export default styles;
