import React from "react";
import { PropTypes } from "prop-types";

import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import CancelIcon from "@material-ui/icons/Clear";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import RefreshIcon from "@material-ui/icons/Refresh";

import placeholderLogo from "../../assets/corp_logo_placeholder.png";

import { CustomTableCell } from "../common/StyledTable";

import ApplicationDetailsModal from "./ApplicationDetailsModal";

// application_note: null
// company: {
//   id: 74
//   is_corporate: false
//   is_founder: true
//   logo_image_url: "/uploads/Company/image/f51355301e3d4e4fa54c5dc38cd076c1-fair.png"
//   name: "Firef.ly"
// }
// created_at: "2018-09-21T13:34:57.292251"
// id: 8
// is_approved: 1
// is_public: true
// response_note: null
// url: "/api/programs/4/participants/8/"

const styles = {
  root: {},
  companyDescription: {
    fontSize: 13,
    lineHeight: "17px",
    marginTop: 5,
    maxHeight: 35,
    maxWidth: 600,
    overflow: "hidden"
  },
  companyName: {
    color: "#398DD3",
    fontSize: 15,
    lineHeight: "20px",
    fontWeight: 700,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  companyNamePending: {
    color: "#398DD3",
    fontWeight: 700
  },

  error: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    backgroundColor: "#F9EFED",
    border: "1px solid red",
    color: "#2A2B2C",

    height: 60,
    paddingLeft: 15,
    paddingRight: 15
  },
  errorIcon: {
    cursor: "pointer",
    fill: "red",
    marginLeft: 20
  },

  icon: {
    fill: "#2A2B2C",
    fontSize: 20,
    cursor: "pointer",
    marginTop: 3,
    marginBottom: -3
  },
  iconError: {
    fill: "#2A2B2C",
    fontSize: 20,
    opacity: 0.4,
    marginTop: 3,
    marginBottom: -3
  },
  iconPending: {
    fill: "#4F8DCD",
    fontSize: 20,
    marginTop: 3,
    marginBottom: -3,
    animation: "buttonspin infinite 2s linear"
  },
  "@keyframes buttonspin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" }
  },
  row: {
    // boxShadow: "0 1px 0 0 #EDEDEF"
    borderBottom: "1px solid #EDEDEF"
  },
  table: {}
};

class ApplicationsListRow extends React.Component {
  state = {
    modal: false
  };

  renderApplicationDetailsModal = () => {
    const { data } = this.props;
    const company = data.company || {};

    // adding random key to force redraw on repeated click on button
    return (
      <ApplicationDetailsModal
        key={`${Math.random()}`}
        application={data}
        onClose={() => this.setState({ modal: false })}
        title={`Details for '${company.name}'`}
      />
    );
  };

  renderCheckBox = () => {
    const {
      classes,
      data,
      deselectApplication,
      selectApplication
    } = this.props;
    const { url, selected } = data;

    const onClick = selected
      ? () => deselectApplication(url)
      : () => selectApplication(url);

    const checkBoxProps = {
      className: classes.icon,
      onClick
    };

    if (data.error) {
      return <CheckBoxBlank className={classes.iconError} />;
    }

    if (data._pending) {
      return <RefreshIcon className={classes.iconPending} />;
    }

    return selected ? (
      <CheckBox {...checkBoxProps} style={{ fill: "#4F8DCD" }} />
    ) : (
      <CheckBoxBlank {...checkBoxProps} />
    );
  };

  renderCompanyCell = () => {
    const { classes, data } = this.props;
    const company = data.company || {};

    if (!company.name) return null;

    const description = company.description ? (
      <div className={classes.companyDescription}>{company.description}</div>
    ) : null;

    if (data._pending || data.error) {
      return (
        <div>
          <div className={classes.companyNamePending}>{company.name}</div>
          {description}
        </div>
      );
    }

    return (
      <div style={{ maxHeight: 60 }}>
        <Tooltip title="Click to see company and application details.">
          <div
            className={classes.companyName}
            onClick={() => this.setState({ modal: true })}
          >
            {company.name}
          </div>
        </Tooltip>
        {description}
      </div>
    );
  };

  getCompanyData = () => {
    const { data } = this.props;
    const date = new Date(data.created_at).toDateString();

    if (!data.company) {
      return ["", "", date];
    }

    if (data.company.is_guest) {
      return ["", "Guest", date];
    }

    return [
      data.company.is_corporate ? "Corporation" : "Founder Company",
      "Member",
      date
    ];
  };

  renderDataCells = () => {
    const data = this.getCompanyData();

    return data.map((cell, index) => (
      <CustomTableCell key={index}>{cell}</CustomTableCell>
    ));
  };

  renderError = () => {
    const { classes, data } = this.props;

    const { error, url } = data;

    return (
      <CustomTableCell colSpan={4} style={{ flexGrow: 1 }}>
        <div className={classes.error}>
          {(error && error.message) || "Error"}
          <CancelIcon
            className={classes.errorIcon}
            onClick={() => this.props.clearError(url)}
          />
        </div>
      </CustomTableCell>
    );
  };

  render() {
    const { classes, data } = this.props;

    const company = data.company || {};

    const imgSrc = company.logo_image_url || placeholderLogo;
    const logoImg = (
      <img
        src={imgSrc}
        alt="logo"
        width={60}
        height={60}
        style={{ marginTop: 3, marginBottom: -3 }}
      />
    );

    const style = data.selected ? { backgroundColor: "#F3F7FB" } : {};
    if (data._pending) {
      style["opacity"] = 0.6;
    }

    return (
      <TableRow className={classes.row} style={style}>
        {this.state.modal && this.renderApplicationDetailsModal()}

        <CustomTableCell style={{ paddingRight: 30, width: 20 }}>
          {this.renderCheckBox()}
        </CustomTableCell>

        <CustomTableCell
          style={{ padding: "15px 0", width: 60, maxHeight: 60 }}
        >
          {logoImg}
        </CustomTableCell>

        <CustomTableCell style={{ maxHeight: 60 }}>
          {this.renderCompanyCell()}
        </CustomTableCell>

        {data.error ? this.renderError() : this.renderDataCells()}
      </TableRow>
    );
  }
}

ApplicationsListRow.propTypes = {
  classes: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deselectApplication: PropTypes.func.isRequired,
  selectApplication: PropTypes.func.isRequired
};

ApplicationsListRow.defaultProps = {
  clearError: url => console.log("TODO: clearError", url),
  deselectApplication: url => console.log("toggle", url),
  selectApplication: url => console.log("toggle", url)
};

export { ApplicationsListRow, styles };
export default withStyles(styles)(ApplicationsListRow);
