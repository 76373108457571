export const ADD_FLASH_NOTE = "ADD_FLASH_NOTE";
export const REMOVE_FLASH_NOTE = "REMOE_FLASH_NOTE";

const TYPES = ["success", "info", "warning", "error"];

export const createFlashNote = (message, type = "info") => {
  const _type = TYPES.indexOf(type) !== -1 ? type : "info";

  return {
    type: ADD_FLASH_NOTE,
    payload: {
      message: message || "undefined",
      type: _type,
      id: new Date().getTime()
    }
  };
};

export const clearFlashNote = id => {
  return {
    type: REMOVE_FLASH_NOTE,
    payload: id
  };
};
