import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { ContactCardSmall } from "../../Contacts";

const styles = {
  root: {
    color: "#2A2B2C",
    padding: 30,
    paddingTop: 40
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },

  titleContainer: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    color: "#2A2B2C",
    fontSize: 18,
    fontWeight: 600,
    margin: 0,
    padding: 0
  },

  card: {
    marginTop: 30
  },
  card_xs: {
    minWidth: 200
  },
  card_lg: {
    minWidth: 350,
    width: 350,
    "&:nth-child(even)": {
      marginLeft: 50
    }
  }
};

class TeamProfileView extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    editLink: PropTypes.object,
    id: PropTypes.string.isRequired,
    managers: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
  };

  static defaultProps = {
    id: "contacts",
    managers: [],
    title: "Team"
  };

  componentDidMount() {
    if (document.location.hash) {
      const element = document.querySelector(document.location.hash);

      element && element.scrollIntoView();
    }
  }

  render() {
    const { classes, id, managers, editLink, title, width } = this.props;

    let cardClass = classes.card;
    if (width === "xl" || width === "lg") {
      cardClass = classes.card_lg;
    } else if (width === "xs") {
      cardClass = classes.card_xs;
    }

    const rootStyle = width === "xs" ? { paddingTop: 30 } : {};

    return (
      <div id={id} className={classes.root} style={rootStyle}>
        <div className={classes.titleContainer}>
          <h3 className={classes.title}>{title}</h3>
          <div style={{ flexGrow: 1 }} />
          {editLink}
        </div>

        <div className={classes.container}>
          {managers
            .filter(member => member.is_public)
            .map((member, index) => (
              <ContactCardSmall
                key={index}
                classes={{ root: cardClass }}
                {...member}
                showEmail={true}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TeamProfileView);
