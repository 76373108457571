import React from "react";

import CompanyPanel from "../common/CompanyPanel";
import TagLabel from "../common/TagLabel";

import ConnectionLink from "../Company/ActionMenu/ConnectionStatus/ConnectionLink";

import ContactsButton from "./ContactsButton";

export const formatDate = (
  string,
  options = {
    year: "numeric",
    month: "short",
    day: "numeric"
  }
) => {
  const dateString = new Date(string).toLocaleDateString("en-GB", options);

  return <span style={{ whiteSpace: "nowrap" }}>{dateString}</span>;
};

const getOtherCompany = (company, connection) => {
  const { connection_company, request_company } = connection;

  return connection_company.id !== company.id
    ? connection_company
    : request_company;
};

export const renderImage = (connection, classes) => {
  if (!connection.logo_image_url) return null;

  return (
    <img
      className={classes.logoImage}
      src={connection.logo_image_url}
      alt="program logo"
    />
  );
};

export const renderCompany = company => (connection, classes) => {
  const other = getOtherCompany(company, connection);

  return (
    <CompanyPanel
      classes={{
        label: classes.companyLabel,
        logo: classes.companyLogo
      }}
      radius={30}
      size={60}
      company={other || {}}
      subtitle={other.mission_statement}
    />
  );
};

export const renderCompanyType = company => connection => {
  const other = getOtherCompany(company, connection);

  if (other.is_corporate) return "Corporate";
  if (other.is_founder) return "Founder";
  if (other.is_guest) return "Guest";

  return null;
};

export const renderConnectionDate = connection => {
  if (!connection.updated_at) return null;

  return formatDate(connection.updated_at);
};

export const renderContactsButton = company => connection => {
  if (connection.is_approved !== 2) return null;

  return (
    <ContactsButton
      id={connection.id}
      showContacts={Boolean(connection.showContacts)}
    >
      View Contacts
    </ContactsButton>
  );
};

export const renderConnectionStatus = company => (connection, classes) => {
  const other = getOtherCompany(company, connection);
  const {
    request_company: from_company,
    connection_company: to_company
  } = connection;

  if (connection.is_approved === 2) {
    return renderStatusBadge("Connected", "grey", false);
  }

  // show all other requests as pending to the user/company that sent it
  if (from_company.id === company.id) {
    return renderStatusBadge("Pending", "grey", false);
  }

  // show "ignored" only of connections that user has ignored
  if (connection.is_approved === 0 && to_company.id === company.id) {
    return renderStatusBadge("Ignored", "red", false);
  }

  return (
    <ConnectionLink
      connection={connection}
      title={`${other.name} wants to connect!`}
    >
      <TagLabel
        label="new"
        color="green"
        solid={true}
        style={{ marginLeft: -5 }}
      />
    </ConnectionLink>
  );
};

const renderStatusBadge = (label, color = "grey", solid = true) => {
  return (
    <TagLabel
      label={label}
      color={color}
      solid={solid}
      style={{ marginLeft: -5 }}
    />
  );
};
