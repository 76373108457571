import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { SecondaryButton } from "../common/Buttons";

const SignInButton = props => {
  const { history, profile } = props;

  if (profile.isSignedIn) return null;

  if (profile.loading) {
    return (
      <SecondaryButton disabled={true}>
        <strong>Signing in ...</strong>
      </SecondaryButton>
    );
  }

  return (
    <SecondaryButton onClick={() => history.push("/signin")}>
      <strong>Sign In</strong>
    </SecondaryButton>
  );
};

SignInButton.propTypes = {
  profile: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

const mapStateToProps = state => ({ profile: state.profile });

export { SignInButton };
export default withRouter(connect(mapStateToProps)(SignInButton));
