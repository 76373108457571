import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Email from "@material-ui/icons/Email";

import { iconClasses } from "../../styles/buttonStyles";

import {
  PrimaryButtonGreen,
  PrimaryButtonRed,
  SecondaryButton
} from "../common/Buttons";

import DownloadCSVButton from "./DownloadCSVButton";
import DeclineApplicationsModal from "./DeclineApplicationsModal";
import EmailApplicantsModal from "./EmailApplicantsModal";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "no-wrap"
  },
  icon: iconClasses["medium"]
};

class ApplicationsManageButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      declineModal: false,
      emailModal: false
    };
  }

  sendEmail = (selected, msg) => {
    this.props.sendEmail(selected, msg).then(json => {
      const { error } = json;
      if (error) {
        this.props.createFlashNote(error.message, "error");
      } else {
        this.props.createFlashNote(
          `${selected.length} participants will be notified`,
          "success"
        );
      }
    });
  };

  renderApproveButtons = () => {
    const { approveApplications, selected, status } = this.props;

    if (status !== "pending") return null;

    const disabled =
      !selected.length || Boolean(selected.find(app => app._pending));

    return (
      <React.Fragment>
        <PrimaryButtonGreen
          disabled={disabled}
          onClick={() => {
            approveApplications(selected);
          }}
        >
          Approve
        </PrimaryButtonGreen>
        <PrimaryButtonRed
          disabled={disabled}
          onClick={() => this.setState({ declineModal: true })}
        >
          Decline
        </PrimaryButtonRed>
      </React.Fragment>
    );
  };

  renderDeclineModal = () => {
    const { selected } = this.props;

    const title =
      selected.length === 1
        ? "Decline Application"
        : `Decline Applications (${selected.length})`;

    return (
      <DeclineApplicationsModal
        closeDialog={() => this.setState({ declineModal: false })}
        onClose={() => this.setState({ declineModal: false })}
        onDecline={msg => {
          this.setState({ declineModal: false });
          this.props.declineApplications(selected, msg);
        }}
        title={title}
      />
    );
  };

  renderEmailModal = () => {
    const { selected } = this.props;

    const title =
      selected.length === 1
        ? "Email Applicant"
        : `Email Applicants (${selected.length})`;

    return (
      <EmailApplicantsModal
        onClose={() => this.setState({ emailModal: false })}
        onSend={msg => {
          this.setState({ emailModal: false });
          this.sendEmail(selected, msg);
        }}
        title={title}
      />
    );
  };

  render() {
    const { classes, format, selected } = this.props;
    const { declineModal, emailModal } = this.state;

    return (
      <div className={classes.root}>
        <DownloadCSVButton />

        <SecondaryButton
          disabled={!selected.length}
          onClick={() => this.setState({ emailModal: true })}
        >
          <Email className={classes.icon} />
          {format === "short" ? "Email" : "Email Applicant"}
        </SecondaryButton>

        {this.renderApproveButtons()}

        {declineModal && this.renderDeclineModal()}

        {emailModal && this.renderEmailModal()}
      </div>
    );
  }
}

ApplicationsManageButtons.propTypes = {
  approveApplications: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  createFlashNote: PropTypes.func.isRequired,
  declineApplications: PropTypes.func.isRequired,
  format: PropTypes.oneOf(["normal", "short"]).isRequired,
  sendEmail: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  status: PropTypes.oneOf(["pending", "approved", "declined"]).isRequired
};

ApplicationsManageButtons.defaultProps = {
  format: "normal",
  selected: []
};

export { ApplicationsManageButtons, styles };
export default withStyles(styles)(ApplicationsManageButtons);
