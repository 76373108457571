import { validateCriteria, validateQuestions } from "../Form/validate";

const pages = [
  {
    name: "Eligibility",
    formFields: [
      {
        id: "header_eligibility",
        label: "Eligibility",
        helperText:
          "In order to participate in this Think Tanks all applicants must meet the following criteria. Complete the below checklist to confirm you meet the listed criteria.",
        type: "header"
      },
      {
        label: "Program Criteria",
        id: "criteria",
        type: "applicationCriteriaForm",
        validate: validateCriteria,
        section: true
      }
    ],
    nextButtonLabel: "Next: Questions"
  },
  {
    name: "Application Questions",
    formFields: [
      {
        id: "header_application_questions",
        label: "Application Questions",
        helperText: "Complete the below application form",
        type: "header"
      },
      {
        label: "Application Questions",
        id: "questions",
        type: "applicationQuestionsForm",
        validate: validateQuestions
      }
    ],
    nextButtonLabel: "Next: Review"
  },
  {
    name: "Review",
    formFields: [
      {
        id: "header_review",
        label: "Review your application",
        helperText:
          "All submissions include information from your company profile. Review your completed submission below, including information about your company, and make any changes required before you submit.",
        type: "header"
      },
      {
        label: "Review",
        id: "review",
        type: "applicationReview",
        section: true
      }
    ],
    nextButtonLabel: "Submit"
  }
];

export default pages;
