import { connect } from "react-redux";

import { createFlashNote } from "../../Reducers/FlashNotes";

import {
  clearValidationError,
  clearValidationErrors,
  setValidationError,
  setValidationErrors
} from "../../Reducers/ValidationErrors";

import ApplicationFormContainer from "../ApplicationForm/ApplicationFormContainer";

import programApplicationSections from "./programApplicationSections";

import { loadProgram, submitApplication } from "./api";

// import testProgram from "../FormBuilder/testProgram";

const mapStateToProps = state => {
  return {
    company: state.company,
    doctype: "program",
    // loadDocument: () => Promise.resolve(testProgram),
    loadDocument: loadProgram,
    pages: programApplicationSections,
    profile: state.profile,
    submitApplication,
    validationErrors: state.validationErrors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    },

    clearValidationError: (id, msg) => dispatch(clearValidationError(id, msg)),
    clearValidationErrors: () => dispatch(clearValidationErrors()),
    setValidationError: (id, msg) => dispatch(setValidationError(id, msg)),
    setValidationErrors: errors => dispatch(setValidationErrors(errors))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationFormContainer);
