import React from "react";

import CompanyProfileEditor from "../components/Company/ProfileEditor";

import PageTemplate from "./PageTemplate";

const MyCompanyEdit = props => {
  return (
    <PageTemplate>
      <CompanyProfileEditor {...props} />
    </PageTemplate>
  );
};

export default MyCompanyEdit;
