import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import * as colors from "../../../styles/colors";

import {
  getLinkButtonClasses,
  getOutlineButtonClasses,
  getPrimaryButtonClasses
} from "../../../styles/buttonStyles";

const outlineStyles = getOutlineButtonClasses();
const primaryStyles = getPrimaryButtonClasses();

export const OutlineButton = withStyles(outlineStyles)(Button);

// primary
export const PrimaryButton = withStyles(primaryStyles)(Button);
export const PrimaryButtonGreen = withStyles(
  getPrimaryButtonClasses(colors.GREEN, colors.WHITE)
)(Button);
export const PrimaryButtonRed = withStyles(
  getPrimaryButtonClasses(colors.RED, colors.WHITE)
)(Button);

// primary large
export const PrimaryButtonLarge = withStyles(
  getPrimaryButtonClasses(colors.BLUE, colors.WHITE, "large")
)(Button);
export const PrimaryButtonLargeRed = withStyles(
  getPrimaryButtonClasses(colors.RED, colors.WHITE, "large")
)(Button);
export const PrimaryButtonLargeGreen = withStyles(
  getPrimaryButtonClasses(colors.GREEN, colors.WHITE, "large")
)(Button);

// secondary
export const SecondaryButton = withStyles(
  getOutlineButtonClasses(colors.BODY_FONT_BLACK, "medium")
)(Button);
export const SecondaryButtonBlue = withStyles(
  getOutlineButtonClasses(colors.BLUE_PRIMARY, "medium")
)(Button);

// secondary large
export const SecondaryButtonLarge = withStyles(
  getOutlineButtonClasses(colors.BODY_FONT_BLACK, "large")
)(Button);

// secondary small
export const SecondaryButtonSmall = withStyles(
  getOutlineButtonClasses(colors.BODY_FONT_BLACK, "small")
)(Button);
export const SecondaryButtonSmallBlue = withStyles(
  getOutlineButtonClasses(colors.BLUE, "small")
)(Button);

export const LinkButton = withStyles(
  getLinkButtonClasses({ color: colors.GREY_DARK })
)(Button);

export const LinkButtonLarge = withStyles(
  getLinkButtonClasses({ size: "large" })
)(Button);
export const LinkButtonLargeRed = withStyles(
  getLinkButtonClasses({ color: colors.RED, size: "large" })
)(Button);
