import React from "react";
import { PropTypes } from "prop-types";

import ActionDialog from "../../common/ActionDialog";
import Icons from "../../Icons";

class ArchiveDocumentLink extends React.Component {
  static propTypes = {
    createFlashNote: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    document: PropTypes.object,
    doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    modal: false
  };

  handleStatus = status => {
    const { createFlashNote, doctype, history } = this.props;

    if (status === 200) {
      createFlashNote(`The ${doctype} has been removed.`, "success");
      history.push(`/${doctype}s/`);
    }
  };

  renderDialog = () => {
    const { doctype, document } = this.props;

    const fields = [
      {
        name: "",
        helperText: `You are about to archive your ${doctype} "${
          document.name
        }". This will remove it from all listings on Colinked.`
      }
    ];

    const postURL =
      doctype === "program"
        ? `/api/programs/${document.id}/`
        : `/api/think_tanks/${document.id}/`;

    return (
      <ActionDialog
        buttonLabel={`Archive ${doctype}`}
        buttonStyle="warning"
        closeOnSuccess={true}
        fields={fields}
        method="DELETE"
        onClose={status =>
          this.setState({ modal: false }, () => this.handleStatus(status))
        }
        postURL={postURL}
        title={`Archive this ${doctype}`}
      />
    );
  };

  render() {
    const { classes, doctype } = this.props;

    return (
      <div
        className={classes.root}
        onClick={() => this.setState({ modal: true })}
      >
        {this.state.modal && this.renderDialog()}
        <Icons.TrashCanOutline className={classes.icon} />
        <span>{`Remove/archive this ${doctype}`}</span>
      </div>
    );
  }
}

export default ArchiveDocumentLink;
