import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../Icons";
import styles from "./styles";

const renderBlock = (lines, Icon, classes) => {
  return (
    <div className={classes.detailsRow}>
      <Icon className={classes.inlineIcon} />
      <div className={classes.detailsBlock}>
        {lines.map((line, index) => (
          <div className={classes.detailsLine} key={index}>
            {line}
          </div>
        ))}
      </div>
    </div>
  );
};

const AppointmentDetails = props => {
  const { document, classes, countries } = props;
  const { city, address, additional_notes } = document;

  let country = document.country;
  const countryObj =
    country && country.id
      ? country
      : countries.find(_country => _country.id === country);
  if (countryObj) {
    country = countryObj.label;
  }

  const detailsLines = address ? address.split("\n").filter(line => line) : [];
  const otherLines = additional_notes
    ? additional_notes.split("\n").filter(line => line)
    : [];

  const location = [city, country].filter(c => c).join(", ");
  const lines = location ? [location, ...detailsLines] : detailsLines;
  const Icon = city ? Icons.MapMarker : Icons.Phone;

  if (lines.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      {renderBlock(lines, Icon, classes)}
      {Boolean(otherLines.length) &&
        renderBlock(otherLines, Icons.Info, classes)}
    </React.Fragment>
  );
};

AppointmentDetails.propTypes = {
  document: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    address: PropTypes.string,
    additional_notes: PropTypes.string
  })
};

const mapStateToProps = state => {
  return {
    countries: state.countries
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(AppointmentDetails));
