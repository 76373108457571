const availableBenefits = {
  accelerator: [
    "Funding",
    "Mentorship",
    "Office Space",
    "Training",
    "Other",
    "Equity"
  ],
  incubator: [
    "Funding",
    "Mentorship",
    "Office Space",
    "Training",
    "Other",
    "Equity"
  ],
  hackathon: [
    "Prize Money",
    "Mentorship",
    "Collaboration / Partnership",
    "Other"
  ],
  challenge: [
    "Prize Money",
    "Mentorship",
    "Collaboration / Partnership",
    "Other"
  ],
  competition: [
    "Prize Money",
    "Mentorship",
    "Collaboration / Partnership",
    "Other"
  ],
  proposal: ["Offer", "Collaboration / Partnership", "Other"],
  other: ["Other"]
};

export { availableBenefits };
