import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { fetchWithToken } from "../../../api/tokenApi";
import { responseJsonOrError } from "../../../api/response";
import { addTag } from "../../../Reducers/Tags/TagsActions";

import FormSelectField from "../Selectors/FormSelectField";
import FormSelectFieldCreatable from "../Selectors/FormSelectFieldCreatable";

const option2value = ({ value }) => {
  return value.match(/^\d+$/) ? parseInt(value, 10) : value;
};

const tag2option = tag => {
  const label = tag.label || tag.name;

  if (tag.id) return { value: tag.id.toString(), label };

  return { value: tag.value.toString(), label };
};

const uploadNewTag = name => {
  // return Promise.resolve({ id: 681, name });
  return fetchWithToken("/api/tags/", {
    body: JSON.stringify({ name }),
    cache: "no-cache",
    credentials: "same-origin",
    method: "POST"
  })
    .then(response => responseJsonOrError(response))
    .then(json => {
      if (json.error) throw json.error;

      return json;
    });
};

const value2option = (v, options) => {
  if (v.value && v.label) return { ...v, value: v.value.toString() };

  return options.find(option => option.value === v.toString());
};

class FormTagsFieldContainer extends React.Component {
  static propTypes = {
    category: PropTypes.string,
    options: PropTypes.array,
    tags: PropTypes.array.isRequired
  };

  static defaultProps = {
    classes: {},
    category: "",
    options: [],
    value: []
  };

  constructor(props) {
    super(props);

    this.state = {
      creatable: false,
      error: null,
      options: [],
      selection: []
    };
  }

  componentDidMount() {
    this.setOptionsFromProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const needsUpdate = ["options", "tags", "value"].some(attr => {
      return this.props[attr] !== nextProps[attr];
    });

    if (needsUpdate) {
      this.setOptionsFromProps(nextProps);
    }
  }

  createOption = _value => {
    const value = _value.find(v => v.__isNew__);
    if (!value || !value.label) return;

    uploadNewTag(value.label)
      .then(newTag => {
        if (newTag.error) throw newTag.error;

        this.props.addTag(newTag);

        return tag2option(newTag);
      })
      .then(newOption => {
        const options = [...this.state.options, newOption];
        const selection = _value.filter(v => !v.__isNew__).concat([newOption]);

        this.setState({ error: null, options, selection }, this.onChange);
      })
      .catch(error => {
        console.error(error);

        this.setState({ error });
      });
  };

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === "create-option") {
      this.createOption(newValue);
    } else {
      this.setState({ selection: newValue, error: null }, this.onChange);
    }
  };

  setOptionsFromProps = props => {
    // use tags if no options are given via props
    const options = (props.options.length ? props.options : props.tags).map(
      tag2option
    );

    const selection = props.value
      .map(v => value2option(v, options))
      .filter(v => v)
      .sort((a, b) =>
        a.label.localeCompare(b.label, "en", { sensitivity: "base" })
      );

    this.setState({
      creatable: props.creatable || !props.options.length,
      error: null,
      options,
      selection
    });
  };

  onChange = () => {
    const value = this.state.selection.map(option2value);

    this.props.onChange(value);
  };

  render() {
    const {
      addTag,
      category,
      helperText,
      onChange,
      options: __options,
      value: __value,
      ...otherProps
    } = this.props;

    const props = {
      ...otherProps,
      error: Boolean(this.state.error || this.props.error),
      helperText: this.state.error ? this.state.error.message : helperText,
      onChange: this.handleChange,
      options: this.state.options,
      value: this.state.selection
    };

    // if (false && this.props.creatable || this.state.creatable) {
    if (false) {
      return <FormSelectFieldCreatable {...props} />;
    }

    return <FormSelectField {...props} />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addTag: tag => {
      dispatch(addTag(tag));
    }
  };
};

const mapStateToProps = state => {
  return {
    tags: state.tags
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormTagsFieldContainer);
