import {
  validatePassword,
  validateTaC,
  validateURLOptional
} from "../Form/validate";

const pages = [
  {
    name: "Create your profile",
    formFields: [
      {
        id: "create_your_profile_header",
        label: "Create your user profile",
        helperText:
          "Create your user profile now to  get started connecting and innovating with the world’s most exciting founder companies.",
        type: "header"
      },

      {
        label: "Company Name",
        id: "company_name",
        width: 440,
        disabled: true,
        readOnly: true
      },
      {
        label: "Your Company Email Address",
        id: "email",
        helperText: "This will be your username",
        type: "email",
        limit: 80,
        required: true,
        width: 440
      },

      {
        label: "First Name",
        id: "first_name",
        limit: 40,
        required: true,
        width: 330
      },
      {
        label: "Last Name",
        id: "last_name",
        limit: 40,
        required: true,
        width: 330
      },
      {
        id: "job_title",
        label: "Job Title / Position",
        limit: 40,
        required: true,
        width: 330
      },

      {
        id: "user_password_sep",
        label: "Create a Password",
        type: "separator"
      },
      {
        id: "password",
        label: "Password",
        type: "password",
        placeholder: "Enter a new password",
        helperText:
          "Please select a strong password (at least 9 characters long).",
        limit: 80,
        required: true,
        validate: validatePassword,
        width: 330
      },
      {
        id: "confirm_password",
        label: "Confirm Password",
        type: "password",
        limit: 80,
        required: true,
        validate: validatePassword,
        width: 330
      }
    ],
    nextButtonLabel: "Next Step: About you"
  },
  {
    name: "About you",
    formFields: [
      {
        id: "about_you_header",
        label: "About you ...",
        helperText:
          "It’s optional, but completing the fields below add a personal touch to your company’s profile and allows other teams to learn more about the team behind your company.",
        type: "header"
      },

      {
        id: "phone_number",
        label: "Contact phone number",
        // helperText: "You can choose who to share your number with with.",
        placeholder: "+44...",
        width: 440
      },
      {
        label: "Your Bio",
        id: "bio",
        limit: 300,
        placeholder:
          "Provide a short bio to introduce yourself, your role and your experience",
        rows: 4,
        showLimit: true,
        width: 660
      },
      {
        id: "linkedin",
        label: "LinkedIn Profile URL",
        helperText:
          "Sharing your LinkedIn profile allows others to connect, and verify who you are. Must begin with 'https://'.",
        validate: validateURLOptional,
        width: 440
      },
      {
        id: "twitter",
        label: "Twitter Profile URL",
        helperText:
          "Sharing your Twitter profile allows others to connect, and verify who you are. Must begin with 'http://' or 'https://'.",
        validate: validateURLOptional,
        width: 440
      },
      {
        label: "Upload a profile photo",
        id: "avatar_image_url",
        helperText: "Square images work best, a minimum of 400 x 400 pixels.",
        type: "image",
        radius: 50,
        height: 100,
        width: 100
      },

      {
        label: "Terms and Conditions",
        id: "terms_and_conditions",
        type: "terms_and_conditions",
        required: true,
        style: { marginTop: 40 },
        validate: validateTaC
      }
    ]
  }
];

export default pages;
