export const REPLACE_PROFILE_DATA = "REPLACE_PROFILE_DATA";
export const RESET_PROFILE_DATA = "RESET_PROFILE_DATA";

export const SET_LOGIN_SUCCESS = "SET_LOGIN_SUCCESS";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";

export const defaultProfile = {
  isSignedIn: false,
  loading: false,
  loginError: null,
  company: {},
  membership: ""
};

export const replaceProfileData = data => {
  return {
    type: REPLACE_PROFILE_DATA,
    payload: data
  };
};

export const resetProfileData = data => {
  return {
    type: RESET_PROFILE_DATA,
    payload: data
  };
};

export const setLoginSuccess = success => {
  return {
    type: SET_LOGIN_SUCCESS,
    payload: success
  };
};

export const setLoginError = loginError => {
  return {
    type: SET_LOGIN_ERROR,
    payload: loginError
  };
};

export const setProfileData = data => {
  return {
    type: SET_PROFILE_DATA,
    payload: data
  };
};

export const updateProfileData = data => {
  return {
    type: UPDATE_PROFILE_DATA,
    payload: data
  };
};
