import React from "react";
import { PropTypes } from "prop-types";

import InfoIcon from "@material-ui/icons/Info";
import { withStyles } from "@material-ui/core/styles";

import ButtonGroup from "../common/ButtonGroup";

import formStyles from "../../styles/formStyles";

const styles = {
  root: {
    marginTop: 16,
    marginBottom: 16
  },
  details: {
    display: "flex",
    alignItems: "center",

    backgroundColor: "#F3F7FB",
    color: "#2A2B2C",
    fontSize: 15,
    marginTop: 20
  },
  helperText: formStyles.helperText,
  icon: {
    fill: "#555B6D",
    margin: 10
  },
  label: formStyles.label
};

const MeetingTypeSelector = props => {
  const {
    classes,
    error,
    label,
    labels,
    value,
    options,
    onChange,
    showDetails,
    width
  } = props;

  const helperText = error ? "This field is required." : props.helperText;
  const state = value ? labels[options.indexOf(value)] : null;
  const textStyle = error ? { color: "#DB6E53" } : {};

  return (
    <div className={classes.root} style={{ width }}>
      {label ? (
        <div className={classes.label} style={textStyle}>
          {label}
        </div>
      ) : null}

      <ButtonGroup
        options={labels}
        state={state}
        onChange={value => {
          // value returned from ButtonGroup is an array
          const option = value.length
            ? options[labels.indexOf(value[0])]
            : null;

          onChange(option);
        }}
      />

      {helperText ? (
        <div className={classes.helperText} style={textStyle}>
          {helperText}
        </div>
      ) : null}

      {showDetails ? (
        <div className={classes.details}>
          <InfoIcon className={classes.icon} />
          You can share the specific details with chosen participants later in
          the process.
        </div>
      ) : null}
    </div>
  );
};

MeetingTypeSelector.defaultProps = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  labels: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  showDetails: PropTypes.bool,
  value: PropTypes.string.isRequired
};

MeetingTypeSelector.defaultProps = {
  label: "Select a meeting type",
  showDetails: true,
  width: 440
};

export default withStyles(styles)(MeetingTypeSelector);
