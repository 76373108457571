import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import ActionDialog from "./ActionDialog";

const QueryDialog = props => {
  const { message, profile, ...other } = props;

  const name = profile ? `${profile.first_name} ${profile.last_name}` : "";
  const email = profile ? profile.email : "";

  return (
    <ActionDialog
      fields={[
        { name: "name", label: "Your name", value: name },
        { name: "email", label: "Your email address", value: email },
        {
          name: "question",
          label: `Your question`,
          rows: 5
        }
      ]}
      {...other}
    />
  );
};

QueryDialog.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.string,
  postURL: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired
};

QueryDialog.defaultProps = {
  title: "Ask a question",
  message: "Please state your question."
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(mapStateToProps)(QueryDialog);
