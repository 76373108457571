import currencyFormatter from "../../utils/currencyFormatter";

export const toGBP = number => currencyFormatter(number, "GBP");

const sortByOption = sorted => (a, b) => {
  return sorted.indexOf(a.option) - sorted.indexOf(b.option);
};
const revenueRangesOrder = [
  "0_500000",
  "500000_1000000",
  "1000000_2000000",
  "2000000_3000000",
  "3000000_5000000",
  "5000000_10000000",
  "10000000_20000000",
  "20000000_30000000",
  "30000000"
];

export const getCapitalRange = config => {
  if (!config) throw new Error("no config");

  const rangeMap = config.companies__revenue_ranges || {};

  const rangeOptions = Object.keys(rangeMap).map(key => ({
    option: key,
    value: rangeMap[key]
  }));
  rangeOptions.sort(sortByOption(revenueRangesOrder));

  return {
    map: rangeMap,
    options: rangeOptions.map(r => r.option),
    labels: rangeOptions.map(r => r.value)
  };
};

const _company_size = [
  ["1_10", "1 - 10"],
  ["11_50", "11 - 50"],
  ["51_200", "51 - 200"],
  ["201_500", "201 - 500"],
  ["501_1000", "501 - 1000"],
  ["1001_5000", "1001 - 5000"],
  ["5001_10000", "5001 - 10,000"],
  ["10001", "10,001+"]
];

export const company_size = {
  options: _company_size.map(option => option[0]),
  labels: _company_size.map(option => option[1])
};

const _company_type = [
  ["public", "Public"],
  ["private", "Private"],
  ["non_profit", "Non-Profit"],
  ["partnership", "Partnership"],
  ["other", "Other"]
];

export const company_type = {
  options: _company_type.map(option => option[0]),
  labels: _company_type.map(option => option[1])
};

const _currency = [
  ["gbp", "GBP (£)"],
  ["usd", "USD ($)"],
  ["eur", "EUR (\u20AC)"]
];

export const currency = {
  map: _currency.reduce((map, pair) => {
    map[pair[0]] = pair[1];
    return map;
  }, {}),
  options: _currency.map(option => option[0]),
  labels: _currency.map(option => option[1]),
  symbol: { gbp: "£", usd: "$US", eur: "\u20AC" }
};

const _stage = [
  ["seed", "Seed"],
  ["series_a", "Series A"],
  ["series_b", "Series B"],
  ["series_c", "Series C+"]
];

export const stage = {
  map: _stage.reduce((map, pair) => {
    map[pair[0]] = pair[1];
    return map;
  }, {}),
  options: _stage.map(option => option[0]),
  labels: _stage.map(option => option[1])
};

export default {
  company_size,
  company_type,
  stage
};
