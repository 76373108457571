import React from "react";
import { PropTypes } from "prop-types";

import DocumentsListView from "./DocumentsListView";

class InfiniteScrollView extends React.Component {
  static propTypes = {
    ListViewComponent: PropTypes.func.isRequired
  };

  static defaultProps = {
    ListViewComponent: DocumentsListView
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  onScroll = () => {
    const footer = document.getElementById("footer");
    const offset = footer ? footer.offsetHeight : "480"; // regular footer + margin

    if (
      window.innerHeight + window.scrollY >=
        document.body.offsetHeight - offset &&
      this.props.documents.length &&
      !this.props.loading
    ) {
      this.props.loadNext();
    }
  };

  render() {
    const { ListViewComponent } = this.props;

    return <ListViewComponent {...this.props} />;
  }
}

export default InfiniteScrollView;
