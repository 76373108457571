import Color from "color";

import { BOX_SHADOW_HOVER } from "./colors";

export const colorize = (styles, color) => {
  return {
    ...styles,
    root: {
      ...styles.root,
      backgroundColor: color,
      "&:hover": {
        backgroundColor: hoverColor(color),
        boxShadow: BOX_SHADOW_HOVER
      }
    },
    disabled: {
      ...styles.disabled,
      backgroundColor: color
    }
  };
};

export const hoverColor = color => {
  if (color === "none") return "none";

  return Color(color)
    .darken(0.25)
    .rgb()
    .string();
};
