import {
  SET_CONNECTIONS,
  SET_CONNECTIONS_LOADING,
  TOGGLE_CONNECTION_CONTACTS,
  UPDATE_CONNECTION,
  UPDATE_CONNECTIONS
} from "./connectionsActionTypes";

const setLoading = connections => {
  const _conn = [...connections];
  _conn.loading = true;

  return _conn;
};

const sortConnections = arr => {
  // sort by time in reverse order (most recent updated first)
  arr.sort((a, b) => b.updated_at - a.updated_at);

  return arr;
};

const updateConnectionsList = (oldList, newList) => {
  // merge lists by connection id
  // newList contains the updated connections so those will
  // overwrite older connections in the map
  const arr = Object.values(
    [...oldList, ...newList].reduce((byId, connection) => {
      byId[connection.id] = connection;
      return byId;
    }, {})
  );

  return sortConnections(arr);
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_CONNECTIONS:
      return sortConnections([...action.payload]);

    case SET_CONNECTIONS_LOADING:
      return setLoading(state);

    case TOGGLE_CONNECTION_CONTACTS:
      return state.map(connection =>
        connection.id === action.payload.id
          ? { ...connection, showContacts: action.payload.value }
          : connection
      );

    case UPDATE_CONNECTION:
      return state.map(connection =>
        connection.id === action.payload.id
          ? { ...connection, ...action.payload }
          : connection
      );

    case UPDATE_CONNECTIONS:
      return updateConnectionsList(state, action.payload);

    default:
      return state;
  }
};
