import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import FormBuilderStep from "./FormBuilderStep";

const styles = {
  root: {
    width: 300,
    marginRight: 60
  }
};

const FormBuilderStepper = props => {
  const { activeStep, classes, onClick, steps, width } = props;

  const style = width === "md" ? { width: 200, marginRight: 30 } : {};

  return (
    <div className={classes.root} style={style}>
      {steps.map((step, index) => {
        return (
          <FormBuilderStep
            key={step.label}
            {...step}
            active={activeStep === index}
            onClick={onClick ? () => onClick(index) : null}
          />
        );
      })}
    </div>
  );
};

FormBuilderStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      completed: PropTypes.bool
    })
  ).isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

export { FormBuilderStepper, styles };
export default withStyles(styles)(FormBuilderStepper);
