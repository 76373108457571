import React from "react";

import ConnectionsPage from "../components/Connections/ConnectionsPage";

import PageTemplate from "./PageTemplate";

const Connections = props => {
  return (
    <PageTemplate>
      <ConnectionsPage />
    </PageTemplate>
  );
};

export default Connections;
