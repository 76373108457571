import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

// change company type from number to object until API can be updated
const response2Connection = (json, company = {}, myCompany = {}) => {
  const connection_company =
    typeof json.connection_company === "number"
      ? {
          ...company,
          id: json.connection_company
        }
      : json.connection_company;

  const request_company =
    typeof json.request_company === "number"
      ? { ...myCompany, id: json.request_company }
      : json.request_company;

  return { ...json, connection_company, request_company };
};

export const loadConnections = (limit = 1000) => {
  return fetchWithToken(`/api/connections/?limit=${limit}`)
    .then(responseJsonOrError)
    .then(json => {
      const { results, error } = json;

      if (error) return json;

      return {
        ...json,
        results: results.map(c => ({
          ...c,
          updated_at: new Date(c.updated_at)
        }))
      };
    })

    .catch(error => ({ error }));
};

export const requestConnection = (url, data, company = {}, myCompany = {}) => {
  return fetchWithToken(url, {
    method: "POST",
    body: JSON.stringify(data)
  })
    .then(responseJsonOrError)
    .then(json => response2Connection(json, company, myCompany));
};
