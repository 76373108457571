import React from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import IconDefault from "@material-ui/icons/HelpOutline";
import IconDone from "@material-ui/icons/Done";

import { withStyles } from "@material-ui/core/styles";

import {
  LIGHT_GREY,
  BLUE,
  GREEN,
  BODY_FONT_BLACK
} from "../../../styles/colors";

const circleStyle = {
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 60,
  height: 60,
  border: `1px solid ${LIGHT_GREY}`,
  borderRadius: "50%"
};

const labelStyle = {
  color: BODY_FONT_BLACK,
  fontSize: 15,
  fontWeight: 700,
  marginTop: 10
};

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: 60,
    "&:last-child": {
      marginRight: 0
    }
  },
  circle: {
    ...circleStyle
  },
  circleCompleted: {
    ...circleStyle,
    backgroundColor: GREEN,
    border: "none"
  },
  icon: {
    fill: BLUE,
    fontSize: 32,
    "&:hover": {
      cursor: "pointer"
    }
  },
  label: {
    ...labelStyle
  },
  link: {
    ...labelStyle,
    color: BLUE,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  }
};

const CompletionSection = props => {
  const { classes, completed, history } = props;

  const Icon = completed ? IconDone : props.icon || IconDefault;
  const iconStyle = completed ? { fill: "white" } : {};
  const label = completed ? (
    <div className={classes.label}>{props.label}</div>
  ) : (
    <div className={classes.link} onClick={() => history.push(props.link)}>
      {props.label}
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={completed ? classes.circleCompleted : classes.circle}>
        <Icon
          className={classes.icon}
          style={iconStyle}
          onClick={() => history.push(props.link)}
        />
      </div>

      {label}
    </div>
  );
};

CompletionSection.propTypes = {
  classes: PropTypes.object.isRequired,
  completed: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  icon: PropTypes.func,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

CompletionSection.defaultProps = {};

export { CompletionSection, styles };
export default withRouter(withStyles(styles)(CompletionSection));
