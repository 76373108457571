import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { createFlashNote } from "../../../Reducers/FlashNotes";

import styles from "./styles";
import UpdatePasswordForm from "./UpdatePasswordForm";

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    }
  };
};

const UpdatePasswordFormContainer = connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(UpdatePasswordForm));

export default UpdatePasswordFormContainer;
