import React from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import styles from "./formStyles";

const FormInputField = props => {
  const {
    classes,
    disabled,
    error,
    helperText,
    label,
    rows,
    onChange,
    type,
    value,
    width
  } = props;

  const defaultValue = type === "number" || type === "select" ? 0 : "";

  const otherProps = { disabled, error, helperText, label, rows, type };

  return (
    <TextField
      {...otherProps}
      multiline={Boolean(rows)}
      value={value || defaultValue}
      onChange={event => onChange(event.target.value)}
      classes={{
        root: classes.root
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.formRoot,
          input: props.error ? classes.formInputError : classes.formInput
        },
        style: {
          width
        }
      }}
      InputLabelProps={{
        shrink: false,
        className: classes.formInputLabel
      }}
    />
  );
};

FormInputField.defaultProps = {
  label: "Input",
  type: "text",
  width: 440,
  rows: null
};

FormInputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  width: PropTypes.any
};

export default withStyles(styles)(FormInputField);
