import React from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { withStyles } from "@material-ui/core/styles";

import formStyles from "./formStyles";

const styles = {
  ...formStyles,
  menu: {}
};

const FormSelectField = props => {
  const { classes, id, label, onChange, value, width, ...otherProps } = props;

  const options = props.options
    ? props.options.map((option, index) => (
        <MenuItem key={option} value={index}>
          {option}
        </MenuItem>
      ))
    : null;

  const numValue = Math.max(props.options.indexOf(value), 0);

  return (
    <TextField
      {...otherProps}
      label={label}
      id={id}
      select
      value={numValue}
      onChange={event => onChange(props.options[event.target.value])}
      classes={{
        root: classes.root
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.formRoot,
          input: classes.formInput
        },
        style: {
          width
        }
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.formInputLabel
      }}
      SelectProps={{
        MenuProps: {
          className: classes.menu
        }
      }}
    >
      {options}
    </TextField>
  );
};

FormSelectField.defaultProps = {
  label: "Please select:",
  width: 660
};

FormSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default withStyles(styles)(FormSelectField);
