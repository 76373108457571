import React from "react";

import RestoreAccountPage from "../components/UserProfile/RestoreAccount";

import PageTemplate from "./PageTemplate";

const RestoreAccount = props => {
  return (
    <PageTemplate>
      <RestoreAccountPage {...props} />
    </PageTemplate>
  );
};

export default RestoreAccount;
