import { SET_MY_APPLICATIONS } from "./MyApplicationsActions";

export default (state = [], action) => {
  switch (action.type) {
    case SET_MY_APPLICATIONS:
      return [...action.payload];

    default:
      return state;
  }
};
