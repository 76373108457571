import React from "react";
import { PropTypes } from "prop-types";
import { Prompt } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import Banner from "../common/Banner";

import FormBuilderMenu from "./FormBuilderMenu";
import FormBuilderForm from "./FormBuilderForm";
import FormBuilderFormButtons from "./FormBuilderFormButtons";
import FormBuilderStepper from "./FormBuilderStepper";
import FormStepperHorizontal from "../Form/FormStepper";

import PreviewHeader from "./PreviewHeader";

import { getPageErrors, getTitleFromDocument } from "./utils";

const rootStyle = {
  backgroundColor: "#F2F3F4",
  display: "flex",
  alignContent: "flex-start",
  justifyContent: "center"
};

const styles = {
  root: {
    ...rootStyle,
    paddingTop: 55,
    paddingBottom: 150
  },
  rootColumn: {
    ...rootStyle,
    flexDirection: "column",
    margin: "0 auto",
    width: 800,
    paddingTop: 55,
    paddingBottom: 150
  },
  previewRoot: {
    ...rootStyle
  }
};

class FormBuilderPage extends React.Component {
  state = {
    _preview: false,
    _previewSticky: false
  };

  // id for previewHeader to check position against scroll position
  previewHeaderId = null;

  componentDidMount() {
    this.previewHeaderId = `${Math.random()}`.replace(".", "");

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getPreviewAction = () => {
    const {
      is_approved,
      _hasUnsavedChanges,
      _isUnsavedDraft
    } = this.props.document;

    if (_isUnsavedDraft) return this.props.handlePublish;

    if (!_hasUnsavedChanges) return null;

    return is_approved === 2
      ? this.props.handleUpdate
      : this.props.handlePublish;
  };

  handleDiscardAndExit = () => {
    const { document, handleDelete, history } = this.props;

    if (document._isUnsavedDraft && handleDelete) {
      handleDelete();
    } else {
      history.push(document.link);
    }
  };

  handlePreview = () => {
    this.setState({ _preview: true });
  };

  handleScroll = () => {
    const header = document.getElementById(this.previewHeaderId);
    if (!header) return;

    const rect = header.getBoundingClientRect();
    const sticky = Boolean(rect.y < 0);

    if (sticky !== this.state._previewSticky) {
      this.setState({ _previewSticky: sticky });
    }
  };

  nextPage = () => {
    if (this.pageIsValid(true)) {
      this.props.setActivePage(this.props.activePage + 1);
    }
  };

  previousPage = () => {
    this.props.setActivePage(this.props.activePage - 1);
  };

  pageHasErrors = _idx => {
    const { activePage, pages, validationErrors } = this.props;
    const pageIndex = _idx !== undefined ? _idx : activePage;

    const errors = getPageErrors(validationErrors, pages)[pageIndex];
    // console.log("pageHasErrors", pages[pageIndex].name, errors);

    return errors;
  };

  pageIsValid = (setErrors = false) => {
    const { activePage } = this.props;
    const page = this.props.pages[activePage];
    const fields = page.formFields;

    const validationErrors = this.props.validate(this.props.document, fields);

    if (Object.keys(validationErrors).length) {
      // console.log("pageIsValid", page.name, "false");
      if (setErrors) {
        this.props.setValidationErrors(validationErrors);
      }

      return false;
    }

    return true;
  };

  validatePage = index => {
    const fields = this.props.pages[index].formFields;
    const validationErrors = this.props.validate(this.props.document, fields);

    return Object.keys(validationErrors).length === 0;
  };

  renderBanner = () => {
    const { document, hideBanner, BannerComponent } = this.props;
    const { _preview } = this.state;

    const title = getTitleFromDocument(document, _preview);

    if (hideBanner) return null;

    return (
      <BannerComponent
        title={title}
        logo={document.logo_image_url}
        document={{ ...document, preview: _preview }}
      />
    );
  };

  renderForm = () => {
    const { activePage, hidePageButtons, hidePageIndex, pages } = this.props;

    if (!pages.length) return null;

    const page = pages[activePage];

    return (
      <FormBuilderForm
        formFields={page.formFields}
        handleBlur={this.props.handleBlur}
        handleChange={this.props.handleChange}
        index={
          hidePageIndex ? null : `Step ${activePage + 1} of ${pages.length}`
        }
        nextButtonLabel={page.nextButtonLabel}
        state={this.props.document}
        setActivePage={this.props.setActivePage}
        validationErrors={this.props.validationErrors}
        width={this.props.width}
      >
        {hidePageButtons ? null : this.renderFormButtons()}
      </FormBuilderForm>
    );
  };

  renderFormButtons = () => {
    const { activePage, isRegistrationForm, pages } = this.props;
    const { is_approved, isCompleted } = this.props.document;

    // 1) Do *now* show buttons for existing/published documents
    //    navigation is via sidebar an update via page.
    // 2) Show buttons on registration forms
    if (is_approved === 2 && !isRegistrationForm) return null;

    // next button: not on last page and only enabled when there are no errors
    const next = activePage < pages.length - 1 ? this.nextPage : null;
    const nextDisabled = this.pageHasErrors() || !this.pageIsValid();
    const nextLabel = pages[activePage].nextButtonLabel || "Next";

    // previous button: not on first page
    const previous = activePage ? this.previousPage : null;

    // publish or update: registration forms use update to keep form buttons visible
    const publish =
      is_approved === 2 || isRegistrationForm
        ? this.props.handleUpdate
        : this.props.handlePublish;

    const publishDisabled =
      activePage !== pages.length - 1 || this.pageHasErrors();

    const publishLabel = is_approved === 2 ? "Update" : "Publish";

    return (
      <FormBuilderFormButtons
        isComplete={isCompleted}
        next={next}
        nextDisabled={nextDisabled}
        nextLabel={nextLabel}
        previous={previous}
        publish={publish}
        publishDisabled={publishDisabled}
        publishLabel={this.props.publishLabel || publishLabel}
      />
    );
  };

  renderMenu = () => {
    const { classes, MenuComponent, ...props } = this.props;

    if (!MenuComponent) return null;

    return (
      <MenuComponent
        {...props}
        id={props.menuId}
        handleDiscard={this.handleDiscardAndExit}
        handlePreview={this.handlePreview}
      />
    );
  };

  renderPreviewHeader = () => {
    const { doctype, is_approved, isCompleted } = this.props.document;

    const { _preview, _previewSticky } = this.state;

    if (!_preview) return null;

    const publish = this.getPreviewAction();
    const publishLabel = is_approved === 2 ? "Update" : "Publish";

    return (
      <PreviewHeader
        doctype={doctype}
        exitPreview={() => this.setState({ _preview: false })}
        id={this.previewHeaderId}
        isComplete={isCompleted}
        publish={publish}
        publishLabel={publishLabel}
        sticky={_previewSticky}
      />
    );
  };

  renderPreviewLayout = () => {
    const { PreviewComponent, classes, document, profile, width } = this.props;

    return (
      <div>
        {this.renderPreviewHeader()}
        {this.renderBanner()}

        <div className={classes.previewRoot}>
          <PreviewComponent
            document={{ ...document, preview: true }}
            preview={true}
            profile={profile}
            width={width}
          />
        </div>
      </div>
    );
  };

  renderStepper = () => {
    const {
      AdvertisingComponent,
      activePage,
      hideStepperIcons,
      pages,
      stepperPosition,
      visitedPages,
      width
    } = this.props;

    const steps = pages.map((page, index) => {
      const error = this.pageHasErrors(index);

      return {
        label: page.name,
        completed: hideStepperIcons
          ? false
          : !error && this.validatePage(index),
        error,
        visited: visitedPages[index]
      };
    });

    const stepperProps = {
      activeStep: activePage,
      onClick: this.props.setActivePage,
      steps,
      width
    };

    if (AdvertisingComponent && stepperPosition !== "top") {
      return (
        <div>
          <FormBuilderStepper {...stepperProps} />
          <AdvertisingComponent style={{ marginRight: 60, marginTop: 30 }} />
        </div>
      );
    } else if (stepperPosition === "top") {
      return <FormStepperHorizontal {...stepperProps} />;
    } else {
      return <FormBuilderStepper {...stepperProps} />;
    }
  };

  render() {
    const { classes, document, stepperPosition } = this.props;
    const { _hasUnsavedChanges } = document;

    if (this.state._preview) {
      return this.renderPreviewLayout();
    }

    const rootClass =
      stepperPosition === "top" ? classes.rootColumn : classes.root;

    return (
      <div>
        {this.renderBanner()}
        {this.renderMenu()}

        <Prompt
          when={_hasUnsavedChanges}
          message={() =>
            `You have unsaved changes. Are you sure you want to leave this page?`
          }
        />

        <div id="formContainer" className={rootClass}>
          {this.renderStepper()}
          {this.renderForm()}
        </div>
      </div>
    );
  }
}

FormBuilderPage.propTypes = {
  // UI state of form
  activePage: PropTypes.number.isRequired,
  visitedPages: PropTypes.array.isRequired,

  // data objects
  document: PropTypes.shape({
    doctype: PropTypes.oneOf([
      "company",
      "profile",
      "program",
      "challenge",
      "registration",
      "service"
    ]).isRequired
  }).isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      formFields: PropTypes.array.isRequired,
      nextButtonLabel: PropTypes.string
    })
  ).isRequired,
  validationErrors: PropTypes.object.isRequired,

  // action handlers
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  handleDuplicate: PropTypes.func,
  handleUpdate: PropTypes.func.isRequired,
  handlePublish: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,

  // functions
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  setActivePage: PropTypes.func.isRequired,
  setValidationErrors: PropTypes.func.isRequired,

  // layout options
  AdvertisingComponent: PropTypes.func,
  BannerComponent: PropTypes.func,
  MenuComponent: PropTypes.func,
  PreviewComponent: PropTypes.func,
  hideBanner: PropTypes.bool,
  hidePageButtons: PropTypes.bool,
  hidePageIndex: PropTypes.bool,
  hideStepperIcons: PropTypes.bool,
  menuId: PropTypes.string.isRequired,
  stepperPosition: PropTypes.oneOf(["left", "top"]).isRequired,

  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

FormBuilderPage.defaultProps = {
  BannerComponent: Banner,
  MenuComponent: FormBuilderMenu,
  hideBanner: false,
  hidePageButtons: false,
  hideStepperIcons: false,
  stepperPosition: "left"
};

export { FormBuilderPage, styles };
export default withWidth()(
  withStyles(styles, { name: "FormBuilder" })(FormBuilderPage)
);
