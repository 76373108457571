import React from "react";

import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    height: 60,
    marginTop: 48,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#6592CC",
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
    "&:hover": {
      backgroundColor: "#3D5A80",
      boxShadow: "0 10px 10px 0 rgba(0,0,0,0.2)"
    }
  },
  disabled: {
    backgroundColor: "#E6E7E8",
    cursor: "default",
    boxShadow: "none"
  },
  label: {
    color: "white",
    fontWeight: 600,
    fontSize: 16
  },
  icon: {
    marginRight: 6,
    animation: "buttonspin infinite 2s linear"
  },
  "@keyframes buttonspin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" }
  }
};

const SpinnerButton = props => {
  const { classes, children, disabled, spinner, ...other } = props;

  const style = spinner ? {} : { paddingLeft: 45, paddingRight: 45 };
  const icon = spinner ? <RefreshIcon className={classes.icon} /> : null;

  return (
    <Button
      //variant="raised"
      classes={{
        root: classes.root,
        disabled: classes.disabled,
        label: classes.label
      }}
      style={style}
      size="large"
      disabled={spinner || disabled}
      {...other}
    >
      {icon}
      {children}
    </Button>
  );
};

export default withStyles(styles)(SpinnerButton);
