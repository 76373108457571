import { connect } from "react-redux";

import DocumentDetailsViewContainer from "../Document/DocumentDetailsViewContainer";

import { loadChallenge } from "./api.js";

const mapStateToProps = state => {
  return {
    company: state.company,
    doctype: "challenge",
    loadDocument: loadChallenge,
    profile: state.profile,
    labels: ["Think Tank Details", "Applications", "Participants"],
    subtitles: [
      "Edit Think Tank details",
      "Manage pending applications",
      "List of approved Think Tank participants"
    ]
  };
};

export default connect(mapStateToProps)(DocumentDetailsViewContainer);
