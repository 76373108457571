export const connectionIsActive = myCompany => connection => {
  // approved and pending are active for all
  if (connection.is_approved !== 0) return true;

  // ignored connections are shows as active to requesting company
  if (connection.request_company.id === myCompany.id) return true;

  // declined by the user/company
  return false;
};

export const connectionIsInactive = myCompany => connection => {
  return !connectionIsActive(myCompany)(connection);
};
