import React from "react";
import { PropTypes } from "prop-types";

import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

import CompanyPanel from "../common/CompanyPanel";
import Section from "../common/Section";
import SimpleDialog from "../common/SimpleDialog";
import StartupProfile from "../Startups/StartupProfile";

import ApplicationStatusGroup from "./ApplicationStatusGroup";

const styles = {
  header: {
    //outline: "1px solid red",
    display: "flex",
    alignItems: "center",
    paddingLeft: 20
  },
  note: {
    marginTop: 20,
    marginBottom: 20
  },
  noteHeader: {
    fontSize: 16,
    fontWeight: 600
  },
  noteText: {
    fontSize: 14,
    fontWeight: 300
  },
  container: {
    //outline: "1px solid red",
    overflowY: "scroll",
    height: 360,
    paddingLeft: 20
  }
};

const renderSection = (content, title, classes) => {
  if (!content) return null;

  return (
    <Section
      variant="subheading"
      title={title}
      fullwidth={false}
      style={{
        borderBottom: "none",
        marginTop: "20px",
        marginBottom: 10,
        padding: "5px 20px",
        backgroundColor: "#F2F3F4"
      }}
    >
      {renderText(content, classes)}
    </Section>
  );
};

const renderText = (text, classes) => {
  const paragraphs = (text || "").split("\n").filter(p => p);

  return paragraphs.map((paragraph, index) => (
    <Typography
      key={index}
      paragraph
      variant="body1"
      classes={{ body1: classes.body1 }}
    >
      {paragraph}
    </Typography>
  ));
};

const ApplicationDetailsDialog = props => {
  const { classes, application, title } = props;

  const { application_note, company } = application;

  return (
    <SimpleDialog title={title} width={900} height={680}>
      <div className={classes.header}>
        <CompanyPanel company={company} />
        <div style={{ flexGrow: 1 }} />
        <ApplicationStatusGroup application={application} />
      </div>

      {renderSection(application_note, "Cover Note", classes)}

      <div className={classes.container}>
        <StartupProfile startup={company} />
      </div>
    </SimpleDialog>
  );
};

ApplicationDetailsDialog.propTypes = {
  application: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

ApplicationDetailsDialog.defaultProps = {
  title: "Company Details"
};

export default withStyles(styles)(ApplicationDetailsDialog);
