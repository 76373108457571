import { connect } from "react-redux";

const findCountryById = (id, countries) => {
  return countries.find(country => country.id === id);
};

const CountriesList = props => {
  const { countryLabels, countries } = props;

  if (!countries || !countries.length) return null;

  if (countries[0].label) {
    return countries.map(m => m.label).join(", ");
  }

  if (typeof parseInt(countries[0], 10) === "number") {
    return countries
      .map(market => parseInt(market, 10))
      .map(id => findCountryById(id, countryLabels) || id)
      .filter(country => country)
      .map(country => country.label)
      .join(", ");
  }

  return countries.join(", ");
};

const mapStateToProps = state => {
  return {
    countryLabels: state.countries
  };
};

export default connect(
  mapStateToProps,
  null
)(CountriesList);
