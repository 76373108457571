import React from "react";
import { PropTypes } from "prop-types";
import { withStyles } from "material-ui";

import CompanyEthos from "../../CompanyEthos";
import CompanyPitch from "../../CompanyPitch";

import * as renderers from "./renderers";
import desktopStyles from "./styles";

const styles = {
  ...desktopStyles,
  description: {
    ...desktopStyles.description,
    paddingLeft: 30
  },
  main: {
    ...desktopStyles.main,
    width: "100%",
    maxWidth: 610
  },
  row: {
    ...desktopStyles.row,
    width: "100%",
    maxWidth: 610,
    marginBottom: 30
  },
  sidebar: {
    ...desktopStyles.sidebar,
    width: "100%",
    maxWidth: 610,
    marginLeft: 0
  }
};

export const MobileLayout = props => {
  const { classes, company } = props;

  const ethosOrPitch = company.is_corporate ? (
    <CompanyEthos {...props} classes={{ root: classes.main }} />
  ) : (
    <CompanyPitch {...props} classes={{ root: classes.main }} />
  );

  return (
    <div id="layoutSmall" className={classes.root}>
      <div className={classes.row}>{renderers.renderDescription(props)}</div>

      <div className={classes.row}>{ethosOrPitch}</div>

      <div className={classes.row}>
        <div
          className={classes.panel}
          style={{ width: "100%", marginBottom: 0 }}
        >
          {renderers.renderCompanyDetails(props)}
        </div>
      </div>

      <div className={classes.row}>
        <div
          className={classes.panel}
          style={{ width: "100%", marginBottom: 0 }}
        >
          {renderers.renderCompanyLocation(props)}
        </div>
      </div>

      <div className={classes.containerBottom}>
        <div className={classes.row}>{renderers.renderTeam(props)}</div>
      </div>
    </div>
  );
};

MobileLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired
};

export default withStyles(styles)(MobileLayout);
