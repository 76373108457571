import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

export const ADD_TAG = "ADD_TAG";
export const SET_TAGS = "SET_TAGS";

export const addTag = tag => ({ type: ADD_TAG, payload: tag });

export const setTags = array => ({ type: SET_TAGS, payload: array });

export const createTag = tag => {
  return dispatch => {
    return fetchWithToken(`/api/tags/`, {
      cache: "no-cache",
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify(tag)
    })
      .then(response => responseJsonOrError(response))
      .then(json => {
        if (json.error) {
          throw json.error;
        }

        return json;
      })
      .then(tag => dispatch(addTag(tag)))
      .catch(error => console.log(error));
  };
};

export const fetchTags = () => {
  return dispatch => {
    return fetch(`/api/tags/?limit=10000`, {
      cache: "no-cache",
      credentials: "same-origin"
    })
      .then(response => responseJsonOrError(response))
      .then(json => {
        if (json.error) {
          throw json.error;
        }

        return json;
      })
      .then(json => (json.results ? json.results : json))
      .then(tags => {
        tags.sort((a, b) => {
          return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
        });

        dispatch(setTags(tags));
      })
      .catch(error => {
        if (process.env.NODE_ENV !== "test") console.log(error);
      });
  };
};
