import React from "react";
import { PropTypes } from "prop-types";

import moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import { think_tank_type_options } from "../Document/config";

import BooleanField from "../Form/BooleanField";
import DateTimeSelector from "../Form/DateTimeSelector";
import CountrySelector from "../Form/Selectors/CountrySelector";
import FormInputField from "../Form/FormInputField";

import MeetingTypeSelector from "../Form/MeetingTypeSelector";

import meetupStyles from "./styles";

const styles = theme => ({
  ...meetupStyles(theme),
  root: {
    backgroundColor: "none",
    padding: 0
  },
  marginTopZero: {
    marginTop: 0
  }
});

const getMeetupSettings = state => {
  const options = [
    "is_nda",
    "is_ongoing",
    "program_type",
    "meeting_type",
    "start_datetime",
    "end_datetime",
    "city",
    "country",
    "postcode",
    "address",
    "additional_notes"
  ];

  return options
    .filter(option => state[option] !== "")
    .reduce((data, option) => {
      data[option] = state[option];
      return data;
    }, {});
};

class MeetupEditView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...props.document };
  }

  update = obj => {
    this.setState(obj, this.onChange);
  };

  onChange = () => {
    this.props.onChange(getMeetupSettings(this.state));
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps.document });
  }

  setStartDate = datestring => {
    if (moment(datestring).isSameOrBefore(moment())) {
      this.update({ _startDateError: "Please select a future date." });
      return;
    }

    if (this.state.end_datetime < datestring) {
      this.update({ start_datetime: datestring, end_datetime: datestring });
    }
    this.update({ start_datetime: datestring });
  };

  setEndDate = datestring => {
    const { start_datetime } = this.state;
    if (start_datetime < datestring) {
      this.update({ end_datetime: datestring, _endDateError: null });
    } else {
      this.update({ _endDateError: "Please select a time after the start" });
    }
  };

  renderDetailsField = () => {
    const { address, meeting_type } = this.state;

    const label = meeting_type ? "Details" : "Address";

    return (
      <FormInputField
        id="address"
        value={address}
        label={label}
        onChange={address => this.update({ address })}
        rows={4}
        width="100%"
      />
    );
  };

  render() {
    const { classes, doctype } = this.props;
    const {
      city,
      country,
      postcode,
      start_datetime,
      end_datetime,
      _startDateError,
      _endDateError,
      additional_notes,
      meeting_type
    } = this.state;

    const typeSelector =
      doctype !== "program" ? (
        <MeetingTypeSelector
          value={meeting_type}
          onChange={value => this.update({ meeting_type: value })}
          options={think_tank_type_options.options}
          labels={think_tank_type_options.labels}
          width="100%"
        />
      ) : (
        <div className={classes.header} style={{ marginTop: 16 }}>
          Program Details
        </div>
      );

    const isNDAInput =
      doctype !== "program" ? (
        <BooleanField
          id="is_nda"
          label="Participation requires a non-disclosure agreement (NDA)"
          onChange={value => this.update({ is_nda: value })}
          value={Boolean(this.state.is_nda)}
        />
      ) : null;

    const isOngoingInput =
      doctype === "program" ? (
        <BooleanField
          id="is_ongoing"
          label="This is an ongoing program without deadline"
          onChange={value => this.update({ is_ongoing: value })}
          value={Boolean(this.state.is_ongoing)}
        />
      ) : null;

    const marginTopClasses =
      doctype === "program" ? { root: classes.marginTopZero } : {};

    return (
      <div className={classes.root}>
        {typeSelector}

        {isNDAInput}
        {isOngoingInput}

        <DateTimeSelector
          date={start_datetime}
          error={Boolean(_startDateError)}
          label={_startDateError || "Select a start date and time"}
          onChange={this.setStartDate}
          disabled={Boolean(this.state.is_ongoing)}
          classes={marginTopClasses}
        />

        <DateTimeSelector
          date={end_datetime}
          error={Boolean(_endDateError)}
          label={_endDateError || "Select end time"}
          onChange={this.setEndDate}
          disabled={Boolean(this.state.is_ongoing)}
        />

        <div className={classes.header} style={{ marginTop: 30 }}>
          Location
        </div>

        <FormInputField
          id="city"
          label="City"
          value={city || ""}
          onChange={city => this.update({ city })}
          width="100%"
        />

        <CountrySelector
          id="country"
          label="Country"
          value={country || 1}
          onChange={country => this.update({ country })}
          width="100%"
        />

        <FormInputField
          id="postcode"
          label="Postcode"
          value={postcode || ""}
          onChange={postcode => this.update({ postcode })}
          width="100%"
        />

        {this.renderDetailsField()}

        <FormInputField
          id="additional_notes"
          value={additional_notes}
          label="Any additional information"
          onChange={additional_notes => this.update({ additional_notes })}
          rows={4}
          width="100%"
        />
      </div>
    );
  }
}

MeetupEditView.propTypes = {
  doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
  document: PropTypes.shape({
    type: PropTypes.string,
    start_datetime: PropTypes.string,
    end_datetime: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.number,
    address: PropTypes.string,
    additional_notes: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(MeetupEditView);
