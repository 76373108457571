import { connect } from "react-redux";

import { ignoreConnection } from "../../../../Reducers/Connections";

import RemoveConnectionDialog from "./RemoveConnectionDialog";

const mapDispatchToProps = dispatch => ({
  ignoreConnection: (id, msg) => dispatch(ignoreConnection(id, msg))
});

export default connect(
  null,
  mapDispatchToProps
)(RemoveConnectionDialog);
