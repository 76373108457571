import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../../Icons";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    border: "2px dashed #E6E7E9",
    fontSize: 13,
    color: "#AAADB6",
    fontWeight: 600,
    position: "relative"
  },
  box: {
    height: 80,
    width: 80,
    border: "1px solid #E6E7E9",
    borderRadius: 3,
    color: "#2A2B2C",

    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)"
    },

    marginLeft: 10,
    "&:first-child": {
      marginLeft: 0
    },

    // internal arrangement
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  boxIcon: {
    fontSize: 30,
    fill: "#AAADB6",
    marginBottom: 5
  },
  icon: {
    fontSize: 48,
    fill: "#AAADB6",
    marginBottom: 5
  },
  label: {
    textTransform: "uppercase",
    marginBottom: 15
  },
  tag: {
    height: 28,
    width: 60,
    borderRadius: 14,
    backgroundColor: "rgba(230,231,233,0.5)",
    lineHeight: "28px",
    textAlign: "center",
    position: "absolute",
    top: 15,
    right: 15
  }
};

const CreateFrame = props => {
  const { addFrame, classes, frames, maxFrames } = props;

  return (
    <div className={classes.root}>
      <div className={classes.tag}>
        {frames}/{maxFrames}
      </div>
      <div>
        <Icons.Plus className={classes.icon} />
      </div>
      <div className={classes.label}>Add New Section</div>
      <div style={{ display: "flex" }}>
        <div className={classes.box} onClick={() => addFrame("heading")}>
          <Icons.FormatHeader1 className={classes.boxIcon} />
          Heading
        </div>
        <div className={classes.box} onClick={() => addFrame("richtext")}>
          <Icons.FormatLeftAlign className={classes.boxIcon} />
          Text
        </div>
        <div className={classes.box} onClick={() => addFrame("image")}>
          <Icons.ImageMultiple className={classes.boxIcon} />
          Image
        </div>
        <div className={classes.box} onClick={() => addFrame("video")}>
          <Icons.Monitor className={classes.boxIcon} />
          Video
        </div>
        <div className={classes.box} onClick={() => addFrame("file")}>
          <Icons.Upload className={classes.boxIcon} />
          File
        </div>
      </div>
    </div>
  );
};

CreateFrame.propTypes = {
  addFrame: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  frames: PropTypes.number.isRequired,
  maxFrames: PropTypes.number.isRequired
};

CreateFrame.defaultProps = {
  maxFrames: 20
};

export { CreateFrame, styles };
export default withStyles(styles)(CreateFrame);
