import React from "react";
import PropTypes from "prop-types";

import MUIAvatar from "@material-ui/core/Avatar";

const Avatar = props => {
  const { profile, size, style } = props;
  const { name, first_name, last_name } = profile;

  const image_url = profile.avatar_image_url || profile.logo_image_url;
  const text = name
    ? name.slice(0, 1)
    : (first_name || "?").slice(0, 1) + (last_name || "?").slice(0, 1);

  const _style = {
    marginRight: 15,
    ...style,
    width: size,
    height: size
  };

  if (image_url) {
    return <MUIAvatar alt={text} src={image_url} style={_style} />;
  }

  return <MUIAvatar style={_style}>{text}</MUIAvatar>;
};

Avatar.propTypes = {
  profile: PropTypes.shape({
    avatar_image_url: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    logo_image_url: PropTypes.string,
    name: PropTypes.stirng
  }),
  size: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired
};

Avatar.defaultProps = {
  size: 42,
  style: {}
};

export default Avatar;
