import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    color: "white",
    fontSize: 13
  },
  link: {
    textDecoration: "underline",
    margin: "0 5px",
    "&:first-child": {
      marginLeft: 0
    }
  }
};

const renderLink = classes => ({ name, link }) => {
  return (
    <React.Fragment key={link}>
      <Link className={classes.link} to={link}>
        {name}
      </Link>
      /
    </React.Fragment>
  );
};

const Breadcrumbs = props => {
  const { classes, profile } = props;

  const links = props.links.filter(link => {
    if (link.link === "/dashboard") return Boolean(profile.id);
    return true;
  });

  if (!links.length) return null;

  return (
    <div className={`breadcrumbs ${classes.root}`}>
      {links.map(renderLink(classes))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired
};

Breadcrumbs.defaultProps = {
  links: []
};

const mapStateToProps = state => ({
  profile: state.profile
});

export { Breadcrumbs, styles };
export default connect(mapStateToProps)(withStyles(styles)(Breadcrumbs));
