import React from "react";

import { withStyles } from "@material-ui/core/styles";

import MyApplicationsListContainer from "../Applications/MyApplicationsListContainer";
import ThinkTanksTableView from "../Document/DocumentsListView/ThinkTanksTableView";
import DocumentsListViewContainer from "../Document/DocumentsListViewContainer";

import { loadPrograms } from "../Programs/api";
import { loadChallenges } from "../Challenges/api";

import TabbedContainer from "../common/TabbedContainer";

import { EmptyApplicationsList, EmptyProgramsList } from "./Placeholders";

const styles = {
  tabsRoot: {
    paddingLeft: 30
  }
};

const FounderActivities = props => {
  const { classes } = props;

  return (
    <TabbedContainer
      classes={classes}
      labels={["My Applications", "New Programs", "New Think Tanks"]}
    >
      <div>
        <div style={{ padding: "20px 30px" }}>
          Manage your previous program and think tanks applications.
        </div>
        <MyApplicationsListContainer EmptyState={EmptyApplicationsList} />
      </div>

      <div>
        <div style={{ padding: "20px 30px" }}>
          Newly listed programs you may be interested in.
        </div>
        <DocumentsListViewContainer
          doctype="program"
          loadDocuments={loadPrograms}
          EmptyState={EmptyProgramsList}
        />
      </div>

      <div>
        <div style={{ padding: "20px 30px" }}>
          Newly listed Think Tanks you may be interested in.
        </div>
        <DocumentsListViewContainer
          doctype="challenge"
          loadDocuments={loadChallenges}
          ListViewComponent={ThinkTanksTableView}
        />
      </div>
    </TabbedContainer>
  );
};

export default withStyles(styles)(FounderActivities);
