import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import formStyles from "../../styles/formStyles";

const styles = {
  root: {
    marginTop: 20,
    marginBottom: 10
  },
  container: {
    display: "flex",
    alignItems: "center"
  },

  // The slider: round white dot, 20px
  slider: {
    position: "absolute",
    borderRadius: 11,
    cursor: "pointer",
    content: "",
    height: 20,
    width: 20,
    left: 5,
    bottom: 4,
    backgroundColor: "white",
    transition: ".2s"
  },

  // The switch - grey with rounded corners
  switch: {
    position: "relative",
    borderRadius: 14,
    width: 54,
    height: 28,
    backgroundColor: "#AAA6BD",
    marginLeft: 10
  },

  // reset label margin to center align with switch
  label: {
    ...formStyles.label,
    marginBottom: 0
  },
  helperText: formStyles.helperText
};

const BooleanField = props => {
  const {
    classes,
    error,
    helperText,
    invert,
    label,
    onChange,
    width,
    value
  } = props;

  const checked = invert ? !value : value;

  const sliderStyle = checked ? { left: 28 } : {};
  const switchStyle = checked ? { backgroundColor: "#85C97C" } : {};

  const textStyle = error ? { color: "red" } : {};

  return (
    <div className={classes.root} style={{ maxWidth: width }}>
      <div className={classes.container}>
        <div className={classes.label} style={textStyle}>
          {label}
        </div>

        <div className={classes.switch} style={switchStyle}>
          <div
            className={classes.slider}
            style={sliderStyle}
            onClick={() => onChange(!value)}
          />
        </div>
      </div>

      {helperText ? (
        <div className={classes.helperText} style={textStyle}>
          {helperText}
        </div>
      ) : null}
    </div>
  );
};

BooleanField.defaultProps = {
  invert: false,
  label: "Input",
  width: 660
};

BooleanField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.any,
  value: PropTypes.bool.isRequired
};

export default withStyles(styles)(BooleanField);
