import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import NavigationLink from "./NavigationLink";

import { guestLinks, memberLinks } from "./links";

const styles = {
  root: {
    display: "flex",
    alignItems: "center"
  },
  active: {
    color: "#2A2B2C"
  }
};

const onLinkClick = (history, profile, link, label) => {
  if (window.ga) {
    window.ga("send", "event", "Link", "click", link);
  }

  const { membership } = profile;

  if (window.mixpanel) {
    window.mixpanel.track(`nav ${label}`, { membership });
  }

  history.push(link);
};

const renderLink = props => (item, index) => {
  const { classes, history, location, profile } = props;

  const _classes = location.pathname.startsWith(item.link)
    ? { root: classes.active }
    : {};

  return (
    <NavigationLink
      key={index}
      classes={_classes}
      onClick={() => onLinkClick(history, profile, item.link, item.label)}
    >
      {item.label}
    </NavigationLink>
  );
};

const DesktopLinks = props => {
  const { classes, profile } = props;

  const options = profile.isSignedIn ? memberLinks : guestLinks;

  return <div className={classes.root}>{options.map(renderLink(props))}</div>;
};

DesktopLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number,
    membership: PropTypes.string.isRequired
  }).isRequired
};

const mapStateToProps = state => ({ profile: state.profile });

export { DesktopLinks, styles };
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(DesktopLinks))
);
