import React from "react";

import { withRouter } from "react-router-dom";

const HeroImage = props => {
  const { history } = props;

  return (
    <section className="homepage-hero-banner">
      <div className="content">
        <div className="mission-statement">
          <h1>
            Colinked is where <span>founders</span> and <span>corporates</span>{" "}
            connect and collaborate.
          </h1>
          <p>
            We understand the challenges of corporate engagement and the
            necessity of building strong routes to market for founders. Colinked
            allows for industry-wide collaboration to be made easy online.
          </p>
          <button
            className="btn-white"
            onClick={() => history.push("/forcorporates")}
          >
            For Corporates
          </button>{" "}
          <button
            className="btn-white"
            onClick={() => history.push("/forfounders")}
          >
            For Founders
          </button>
        </div>
      </div>
    </section>
  );
};

export default withRouter(HeroImage);
