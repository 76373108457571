const ACTIONS = ["apply", "query", "application"];
export const MANAGE_ACTIONS = ["edit", "applications", "participants"];
const ACTIVITIES = [...ACTIONS, ...MANAGE_ACTIONS];

export const capitaliseDoctype = doctype => {
  const capitalised = {
    program: "Program",
    challenge: "Think Tank",
    thinktank: "Think Tank"
  };

  return (
    capitalised[doctype] ||
    `${doctype.slice(0, 1).toUpperCase()}${doctype.slice(1)}`
  );
};

const validateActivity = activity => {
  if (activity && ACTIVITIES.indexOf(activity) !== -1) {
    return activity;
  }

  return null;
};

export const parseURL = pathname => {
  const [
    host,
    doctype,
    documentId,
    activity,
    applicationId,
    applicationAction
  ] = pathname.split("/");

  return validateMatchParams({
    host,
    doctype,
    documentId,
    activity,
    applicationId,
    applicationAction
  });
};

const validateApplicationParams = params => {
  let { applicationId, applicationAction } = params;
  let error = null;

  // if no applicationId is present, reset action
  if (!applicationId) {
    return { error, applicationId: null, applicationAction: null };
  }

  // reset action if not one of the allowed
  if (applicationAction !== "accept" && applicationAction !== "decline") {
    error = new Error(`unknown application action '${applicationAction}'`);
    applicationAction = null;
  }

  return { error, applicationId, applicationAction };
};

export const validateMatchParams = (params, profile) => {
  // TODO: limit available actions depending on profile/company

  // documentId required by route
  const { host, doctype, documentId } = params;
  let activity = validateActivity(params.activity);
  let { error, applicationId, applicationAction } = validateApplicationParams(
    params
  );

  // reset application details if user wants to apply or send query
  if (activity === "apply" || activity === "query") {
    return {
      applicationId: null,
      applicationAction: null,
      error: null
    };
  }

  // applications list _may_ have no application details
  if (activity === "applications") {
    if (params.applicationId && !params.applicationAction) {
      // create error and reset activity
      error = new Error(`Application action undefined.`);
    }
  }

  // application details view needs application id
  if (activity === "application") {
    if (!applicationId) {
      // create error and reset activity
      error = new Error(`Application id is missing.`);
      activity = null;
      applicationId = null;
      applicationAction = null;
    } else if (applicationId && !applicationAction) {
      // create error and reset activity
      error = new Error(`TODO: application details view.`);
    }
  }

  return {
    host,
    doctype,
    documentId,
    activity,
    applicationId,
    applicationAction,
    error
  };
};

const listHasChanged = (list1, list2) => {
  if (!Array.isArray(list1) || !Array.isArray(list2)) {
    return false;
  }

  if (list1.length !== list2.length) {
    return true;
  }

  return list1.some(value => list2.indexOf(value) === -1);
};

const sectionHasChanged = (section1, section2) => {
  let _change = false;

  ["title", "content", "participants_only"].forEach(key => {
    if (section1[key] !== section2[key]) {
      _change = true;
    }
  });

  return _change;
};

export const compareStates = (state1, state2) => {
  let _dirty = false;

  Object.keys(state1)
    .filter(key => !key.startsWith("_"))
    .filter(key => key !== "sections")
    .filter(key => key !== "topics")
    .forEach(key => {
      if (state1[key] !== state2[key]) {
        _dirty = true;
      }
    });

  // check if list of topics has changed
  if (listHasChanged(state1.topics, state2.topics)) {
    _dirty = true;
  }

  // check if list of topics has changed
  if (listHasChanged(state1.blacklist, state2.blacklist)) {
    _dirty = true;
  }

  if (state1.sections.length !== state2.sections.length) {
    _dirty = true;
  } else {
    state1.sections.forEach((section, index) => {
      if (sectionHasChanged(section, state2.sections[index])) {
        _dirty = true;
      }
    });
  }

  return _dirty;
};

export const thinkTankDefaultState = {
  name: "",
  description: "",
  content: "[]",
  desires: "",
  topics: [], // TODO: filter ids from incomming object

  image_url: null,

  is_ongoing: false,
  deadline_datetime: "2020-10-29T19:57:00",
  start_datetime: "2018-10-29T21:57:00",
  end_datetime: "2018-10-28T21:57:00",

  meeting_type: "video",
  city: "London",
  country: 1, // TODO: filter id conditionally
  postcode: "",
  address: "5678 random road",
  additional_notes: "",

  is_nda: false,
  is_public: true,
  is_anonymous: false,
  is_corporate_only: false
};

export const programDefaultState = {
  name: "",
  description: "",
  content: "[]",
  criteria: "",
  topics: [],

  hero_image_url: "",
  logo_image_url: "",

  program_type: "competition",
  city: "",
  country: 1,
  postcode: "",
  address: "",
  additional_notes: "",

  is_open_to_corps: false,
  is_ongoing: false,
  deadline_datetime: "",
  start_datetime: "",
  end_datetime: ""
};

export const filterDocProps = (document, doctype) => {
  const defaults =
    doctype === "program" ? programDefaultState : thinkTankDefaultState;

  const data = Object.keys(defaults).reduce((data, key) => {
    data[key] = document[key];
    return data;
  }, {});

  // remove images from data if not set
  data.logo_image_url === "" && delete data.logo_image_url;
  data.hero_image_url === "" && delete data.hero_image_url;

  // convert topics to ids
  if (document.topics && document.topics.length && document.topics[0].id) {
    data.topics = document.topics.map(topic => topic.id);
  }

  // convert country to id
  if (document.country && document.country.id) {
    data.country = document.country.id;
  }

  // verify type of content
  if (document.content && Array.isArray(document.content)) {
    data.content = JSON.stringify(document.content);
  }

  // convert type of criteria
  if (document.criteria && Array.isArray(document.criteria)) {
    data.criteria = JSON.stringify(document.criteria);
  }

  return data;
};
