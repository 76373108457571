import React from "react";
import { withStyles } from "@material-ui/core/styles";

import MyApplicationsListContainer from "../Applications/MyApplicationsListContainer";
import DocumentsListViewContainer from "../Document/DocumentsListViewContainer";

import ProgramsTableOwnerView from "../Document/DocumentsListView/ProgramsTableOwnerView";
import ThinkTanksTableOwnerView from "../Document/DocumentsListView/ThinkTanksTableOwnerView";

import { loadMyPrograms } from "../Programs/api";
import { loadMyChallenges } from "../Challenges/api";

import TabbedContainer from "../common/TabbedContainer";

import {
  EmptyApplicationsList,
  EmptyProgramsListOwner,
  EmptyThinkTanksListOwner
} from "./Placeholders";

const styles = {
  tabsRoot: {
    paddingLeft: 30
  }
};

const CorporateActivities = props => {
  const { classes, company } = props;

  return (
    <TabbedContainer
      labels={["My Programs", "My Think Tanks", "My Applications"]}
      classes={classes}
    >
      <div>
        <div style={{ padding: "20px 30px" }}>
          View and manage your active, draft and recently closed programs.
        </div>
        <DocumentsListViewContainer
          classes={{ root: classes.docList }}
          doctype="program"
          loadDocuments={loadMyPrograms(company.id)}
          ListViewComponent={ProgramsTableOwnerView}
          EmptyState={EmptyProgramsListOwner}
        />
      </div>

      <div>
        <div style={{ padding: "20px 30px" }}>
          View and manage your active, draft and recently closed Think Tanks.
        </div>
        <DocumentsListViewContainer
          classes={{ root: classes.docList }}
          doctype="challenge"
          loadDocuments={loadMyChallenges(company.id)}
          ListViewComponent={ThinkTanksTableOwnerView}
          EmptyState={EmptyThinkTanksListOwner}
        />
      </div>

      <div>
        <div style={{ padding: "20px 30px" }}>
          Manage your previous program and think tanks applications.
        </div>
        <MyApplicationsListContainer EmptyState={EmptyApplicationsList} />
      </div>
    </TabbedContainer>
  );
};

CorporateActivities.defaultProps = {};

export default withStyles(styles)(CorporateActivities);
