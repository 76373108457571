import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import moment from "moment";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import {
  approveApplication,
  declineApplication
} from "../../Reducers/Applications";

import SpinnerButton from "../common/SpinnerButton";

const buttonStyle = {
  color: "rgba(255,255,255,0.87)",
  fontSize: 14,
  fontWeight: 600,
  marginTop: 0,
  borderRadius: 0,
  boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
  height: 40,
  paddingLeft: 15,
  paddingRight: 15,
  width: 65
};

const cellStyle = {
  flexGrow: 0,
  flexShrink: 0,
  padding: 8
};

const statusLabel = {
  lineHeight: "1em",
  paddingTop: 4,
  paddingBottom: 4
};

const statusStyle = {
  ...cellStyle,
  flexGrow: 0,
  width: 240,
  display: "flex",
  marginLeft: 24,
  alignItems: "center"
};

const styles = {
  actions: {
    ...statusStyle,
    alignItems: "center",
    justifyContent: "space-between"
  },
  status: {
    ...statusStyle,
    flexGrow: 0,
    flexShrink: 0
  },
  statusXS: {
    ...statusStyle,
    marginLeft: 0,
    marginTop: 8,
    marginBottom: 8,
    borderTop: "1px solid lightgrey",
    borderBottom: "1px solid lightgrey",
    paddingTop: 0,
    paddingBottom: 0,
    width: "calc(100%-16px)"
  },
  approved: {
    ...statusLabel,
    color: "green"
  },
  declined: {
    ...statusLabel,
    color: "darkred"
  },
  buttonApprove: {
    ...buttonStyle,
    backgroundColor: "#9CB59E",
    "&:hover": {
      backgroundColor: "#7F9680",
      boxShadow: "0 5px 10px 0 rgba(0,0,0,0.2)"
    }
  },
  buttonApproveLabel: {
    color: "white",
    fontSize: 14,
    fontWeight: 600
  },
  buttonDecline: {
    ...buttonStyle,
    backgroundColor: "white",
    border: "2px solid #2A2B2C",
    "&:hover": {
      backgroundColor: "rgba(85,91,110,0.1)",
      boxShadow: "0 5px 10px 0 rgba(0,0,0,0.2)"
    }
  },
  buttonDeclineLabel: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 600
  }
};

const formatDate = dateString => {
  return moment(dateString).format("Do MMMM YYYY");
};

const renderButtons = props => {
  const { application, classes, onApprove, onDecline } = props;

  return (
    <div className={classes.actions} style={{ marginLeft: 0 }}>
      <SpinnerButton
        variant="raised"
        classes={{
          root: classes.buttonApprove,
          label: classes.buttonApproveLabel
        }}
        onClick={onApprove}
        spinner={Boolean(application._pending)}
      >
        Approve
      </SpinnerButton>
      <SpinnerButton
        variant="raised"
        classes={{
          root: classes.buttonDecline,
          label: classes.buttonDeclineLabel
        }}
        onClick={onDecline}
        spinner={Boolean(application._pending)}
      >
        Decline
      </SpinnerButton>
    </div>
  );
};

const ApplicationStatusGroup = props => {
  const { classes, application, width } = props;

  const status = application.is_approved;
  const statusDate =
    application.statusDate || application.updated_at || application.created_at;

  // status: 1=pending,
  if (status === 1) return renderButtons(props);

  // status: 0=declined, 2=approved
  let className = "";
  if (status === 0) {
    className = classes.declined;
  }
  if (status === 2) {
    className = classes.approved;
  }

  return (
    <div className={width === "xs" ? classes.statusXS : classes.status}>
      <div className={classes.statusLabels}>
        <Typography variant="body2" className={className}>
          {status === 2 ? "approved" : "declined"}
        </Typography>
        <Typography variant="body1" className={classes.date}>
          {formatDate(statusDate)}
        </Typography>
      </div>
      {/*<Button
          classes={{ root: classes.buttonRemove }}
          onClick={() => console.log(`TODO: hide application ${id}`)}
        >
          <Icons.CloseCircle />
        </Button> */}
    </div>
  );
};

ApplicationStatusGroup.propTypes = {
  application: PropTypes.object.isRequired,
  onApprove: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    approveApplication: url => dispatch(approveApplication(url)),
    declineApplication: url => dispatch(declineApplication(url))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withWidth()(withStyles(styles)(ApplicationStatusGroup)));
