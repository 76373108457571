import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import IconCheck from "@material-ui/icons/Check";
import IconCross from "@material-ui/icons/Close";

import ApplicationCriteriaQuestion from "../ApplicationCriteriaForm/ApplicationCriteriaQuestionContainer";

const styles = {
  root: {
    margin: "0 30px"
  },
  criterion: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",

    color: "#2A2B2C",
    fontSize: 14,

    minHeight: 70,
    paddingTop: 25,
    paddingBottom: 25,

    boxShadow: "inset 0 -1px 0 0 #E6E7E9"
  },
  criterionText: {
    flexGrow: 1
  },
  response: {
    flexGrow: 0,
    textAlign: "center",
    width: 24
  },
  responseNo: {
    fill: "#DB6E53"
  },
  responseYes: {
    fill: "#9CB59E"
  }
};

const renderCriterion = (criterion, index, props) => {
  const { classes } = props;
  const { response } = criterion;

  // const text = formatCriterion(label, value, props);
  const text = <ApplicationCriteriaQuestion question={criterion} />;

  return (
    <div key={index} className={classes.criterion}>
      <div className={classes.criterionText}>{text}</div>
      {renderResponse(response, props)}
    </div>
  );
};

const renderResponse = (response = "", props) => {
  const { classes, IconCheckMark, IconCross } = props;

  return (
    <div className={classes.response}>
      {response.toString().toLowerCase() === "yes" ? (
        <IconCheckMark className={classes.responseYes} />
      ) : (
        <IconCross className={classes.responseNo} />
      )}
    </div>
  );
};

const EligibilitySummary = props => {
  const { classes, criteria } = props;

  return (
    <div className={classes.root}>
      {criteria.map((criterion, index) =>
        renderCriterion(criterion, index, props)
      )}
    </div>
  );
};

EligibilitySummary.propTypes = {
  classes: PropTypes.object.isRequired,
  criteria: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
        .isRequired,
      response: PropTypes.string
    })
  ),
  IconCheckMark: PropTypes.func.isRequired,
  IconCross: PropTypes.func.isRequired
};

EligibilitySummary.defaultProps = {
  IconCheckMark: IconCheck,
  IconCross: IconCross
};

export { EligibilitySummary, styles };
export default withStyles(styles)(EligibilitySummary);
