const styles = theme => ({
  chip: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 2 * theme.spacing.unit,
    borderRadius: 3,
    height: 28,
    fontWeight: 600,
    fontSize: "13px",
    color: theme.palette.blue.primary,
    backgroundColor: theme.palette.blue.light
  },
  meta: {
    marginTop: 24,
    marginBottom: 24,
    paddingTop: 24,
    borderTop: "1px solid lightgrey",
    "&:nth-of-type(1)": {
      borderTop: "none"
    }
  },
  metaTitle: {
    fontSize: 16,
    color: "#AAADB6",
    fontWeight: "bold",
    marginBottom: 16
  },
  participantsRow: {
    lineHeight: "64px",
    padding: 12,
    "&:nth-of-type(even)": {
      backgroundColor: "#F2F3F4"
    }
  },
  placeholder: {
    fontStyle: "italic",
    color: "grey"
  }
});

export default styles;
