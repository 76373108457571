import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import ActionDialog from "./ActionDialog";

const ApplicationDialog = props => {
  const { message, profile, ...other } = props;

  //const name = profile ? `${profile.first_name} ${profile.last_name}` : "";

  return (
    <ActionDialog
      fields={[
        {
          name: "application_note",
          label: "Cover note",
          rows: 9,
          helperText:
            "Include a short cover note explaining why you are applying and why you can add value to this challenge."
        }
      ]}
      height={425}
      {...other}
    />
  );
};

ApplicationDialog.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.string,
  postURL: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired
};

ApplicationDialog.defaultProps = {
  title: "Apply",
  message: "Please provide a reason for your application."
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(mapStateToProps)(ApplicationDialog);
