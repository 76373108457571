const formInput = {
  boxSizing: "border-box",
  fontFamily: "Nunito, sans-serif",
  borderRadius: 3,
  backgroundColor: "#F2F3F4",
  color: "#2A2B2C",
  //border: "1px solid #ced4da",
  fontSize: 14,
  height: 40,
  padding: "10px 15px",
  width: "100%",
  //transition: theme.transitions.create(["border-color", "box-shadow"]),
  "&:focus": {
    // borderColor: "#80bdff",
    // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    boxShadow: "none",
    backgroundColor: "#E8EFF8"
  }
};

const formStyles = {
  root: {
    // material-ui defaults
    //display: "inline-flex",
    flexDirection: "column",
    position: "relative",
    // Reset fieldset default style
    minWidth: 0,
    padding: 0,
    //margin: 0,
    border: 0,
    // local overrides
    //outline: "0.5px solid red"
    display: "block",
    margin: "20px 0"
  },
  formRoot: {
    padding: 0,
    "label + &": {
      marginTop: 22
    }
  },
  formInput: {
    ...formInput
  },
  formInputError: {
    ...formInput,
    backgroundColor: "#F9EFED",
    border: "1px solid #DB6E53"
  },
  formInputLabel: {
    //position: "static",
    fontSize: 14,
    fontWeight: 300,
    color: "#2A2B2C",
    transform: "none"
  },
  formImageLabel: {
    fontSize: 14,
    fontWeight: 300,
    color: "#2A2B2C"
  },
  error: {
    boxSizing: "border-box",
    backgroundColor: "#F9EFED",
    border: "1px solid #DB6E53",
    height: 40
  },
  helperText: {
    fontSize: 13
  }
};

export default formStyles;
