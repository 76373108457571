import React from "react";
import { PropTypes } from "prop-types";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../Icons";
import styles from "./styles";

const AppointmentDate = props => {
  const { start_datetime, end_datetime, classes } = props;

  const dateFormat = "dddd Do MMMM, YYYY";
  const dateShort = "ddd Do MMMM";
  const start = moment(start_datetime);
  const end = moment(end_datetime);

  const text = start.isBefore(end, "day")
    ? `from ${start.format(dateShort)} to ${end.format(dateShort)}`
    : start.format(dateFormat);

  return (
    <div className={classes.detailsRow}>
      <Icons.CalendarRange className={classes.inlineIcon} />
      <span className={classes.inlineText}>{text}</span>
    </div>
  );
};

AppointmentDate.propTypes = {
  start_datetime: PropTypes.string.isRequired,
  end_datetime: PropTypes.string
};

export default withStyles(styles)(AppointmentDate);
