import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import CompanyPanel from "../../../common/CompanyPanel";

const styles = {
  root: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    padding: 30
  },
  placeholder: {
    marginTop: 20,
    color: "#AAADB6"
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 30
  }
};

const renderPlaceholder = classes => {
  return (
    <div>
      <div>
        No particpants yet{" "}
        <span role="img" aria-label="emoji">
          🙁
        </span>
      </div>
      <div className={classes.placeholder}>
        Program particpants will appear here once accepted and shared by the
        program organiser.
      </div>
    </div>
  );
};

const renderCompany = company => {
  return (
    <div key={company.id || company.name} style={{ marginTop: 10 }}>
      <CompanyPanel company={company} size={40} />
    </div>
  );
};

const DocumentParticipants = props => {
  const { participants, classes } = props;

  const list = participants.length
    ? participants.map(renderCompany)
    : renderPlaceholder(classes);

  return (
    <div className={classes.root}>
      <div className={classes.title}>Participants</div>
      {list}
    </div>
  );
};

DocumentParticipants.propTypes = {
  participants: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

export { DocumentParticipants, styles };
export default withStyles(styles)(DocumentParticipants);
