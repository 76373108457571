import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FormSelectField from "./FormSelectField";

import { getCountryOptions } from "./utils";

const getCountryOptionsCached = getCountryOptions();

const CountrySelector = props => {
  if (!props.options.length) return null;

  const {
    countries,
    dispatch,
    onChange,
    options,
    value,
    ...otherProps
  } = props;

  const _country = options.find(option => option.value === value);

  return (
    <FormSelectField
      options={options}
      {...otherProps}
      value={_country}
      isMulti={false}
      onChange={country => onChange(country.value)}
    />
  );
};

CountrySelector.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.number
};

CountrySelector.defaultProps = {
  label: "Please select your country",
  value: null
};

const mapStateToProps = state => {
  return {
    options: getCountryOptionsCached(state.countries)
  };
};

export default connect(mapStateToProps)(CountrySelector);
