export const styles = {
  root: {
    marginTop: 20
  },
  error: {},
  helperText: {
    color: "#555B6E",
    fontSize: 13,
    fontWeight: 300,
    marginTop: 6
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 6
  },
  loading: {}
};

export const customStyles = {
  control: (styles, state) => {
    const error = state.selectProps.error;
    return {
      ...styles,
      backgroundColor: error
        ? "#F9EFED"
        : state.isFocused
          ? "#E8EFF8"
          : "#EDEDEF",
      border: error ? "1px solid #DB6E53" : "none",
      color: "#DB6E53",
      fontSize: 14,
      minHeight: 40,
      boxShadow: null,
      outline: null
    };
  },
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isDisabled ? null : styles.backgroundColor,
      color: state.isDisabled ? "#ccc" : "#2A2B2C",
      cursor: state.isDisabled ? "not-allowed" : "default",
      fontSize: 13
    };
  },
  multiValue: styles => {
    return {
      ...styles,
      alignItems: "center",
      backgroundColor: "#555B6E",
      borderRadius: 14,
      fontSize: 13,
      fontWeight: 600,
      height: 28
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    fontSize: 13,
    fontWeight: 600,
    color: "white",
    marginLeft: 7
  }),
  multiValueRemove: styles => ({
    ...styles,
    marginRight: 5,
    borderRadius: 7,
    color: "white",
    ":hover": {
      backgroundColor: "#DB6E53"
    }
  })
};
