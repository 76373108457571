import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ChevronRight from "@material-ui/icons/KeyboardArrowRight";
import CheckIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

const rootStyle = {
  color: "rgba(42,43,44,0.7)",
  display: "flex",
  alignItems: "center",
  fill: "#E6E7E9",
  fontSize: 14,
  fontWeight: 600,
  height: 60,
  boxShadow: "0 1px 0 0 #E6E7E9",
  "&:last-child": {
    boxShadow: "none"
  }
};

const styles = {
  root: {
    ...rootStyle
  },
  rootActive: {
    ...rootStyle,
    color: "#2A2B2C",
    //fontWeight: 600,
    fill: "#398DD3"
  },
  bar: {
    height: 30,
    borderLeft: "3px solid #398DD3",
    width: 27
  },
  icon: {},
  iconActive: {
    fill: "#398DD3"
  },
  iconCompleted: {
    fill: "#94C684",
    marginRight: 8
  },
  iconError: {
    fill: "#DB6E53",
    marginRight: 8
  },
  link: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  }
};

const FormBuilderStep = props => {
  const {
    active,
    classes,
    completed,
    error,
    label,
    onClick,
    showCompleted,
    visited
  } = props;

  const rootClass = active ? classes.rootActive : classes.root;
  const iconClass = active ? classes.iconActive : classes.icon;
  const linkClass = active ? classes.linkActive : classes.link;

  const bar = active ? (
    <div className={classes.bar} />
  ) : (
    <div style={{ width: 30 }} />
  );
  const icon = error ? (
    <ErrorIcon className={classes.iconError} />
  ) : completed && visited && showCompleted ? (
    <CheckIcon className={classes.iconCompleted} />
  ) : null;

  return (
    <div onClick={onClick} className={rootClass}>
      {bar}
      {icon}

      <div className={linkClass} style={{ flexGrow: 1 }}>
        {label}
      </div>
      <ChevronRight className={iconClass} style={{ fontSize: 24 }} />
    </div>
  );
};

FormBuilderStep.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  completed: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  showCompleted: PropTypes.bool,
  visted: PropTypes.bool
};

FormBuilderStep.defaultProps = {
  showCompleted: true
};

export { FormBuilderStep, styles };
export default withStyles(styles, { name: "FormBuilderStep" })(FormBuilderStep);
