const HEIGHT = 485;

const styles = {
  buttonCancel: {
    color: "rgba(0,0,0,0.54)",
    position: "absolute",
    top: 15,
    right: 15
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    display: "flex",
    flexDirection: "column"
  },
  icon: {
    marginRight: 8
  },
  message: {
    color: "#2A2B2C",
    fontSize: 14,
    marginBottom: 30
  },
  paper: {
    width: "390px",
    height: HEIGHT,

    position: "absolute",
    left: "50%",
    top: "50%",
    marginLeft: "-300px",
    marginTop: `-${HEIGHT / 2}px`,

    backgroundColor: "white",
    padding: 30,
    //boxShadow: "0 15px 10px 0 rgba(55,60,68,0.05)"
    "&:focus": {
      boxShadow: "none"
    }
  },
  title: {
    display: "flex",
    alignItems: "center",

    color: "#6592CC",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 30,
    whiteSpace: "nowrap",
    overflowX: "hidden"
  }
};

export default styles;
