import React from "react";

import PageTemplate from "./PageTemplate";

import AcceptableUsePolicy from "../components/StandAlonePages/AcceptableUsePolicy";

const Program = props => {
  return (
    <PageTemplate>
      <AcceptableUsePolicy />
    </PageTemplate>
  );
};

export default Program;
