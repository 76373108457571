import React from "react";
import PropTypes from "prop-types";

import RichTextEditor from "react-rte";

import { throttle } from "underscore";

import { withStyles } from "@material-ui/core/styles";

import formStyles from "../../styles/formStyles";

import "./ProfileBuilder/RichTextFrame.css";

const styles = {
  root: {
    // outline: "1px solid red",
    marginTop: 20
  },
  ...formStyles,
  content: {
    height: 40,
    lineHeight: "40px",
    borderRadius: 3,
    border: "none",
    backgroundColor: "#F2F3F4",
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    paddingLeft: 15,
    paddingRight: 15
  },
  rteRoot: {
    fontFamily: "sans-serif"
  },
  rteToolbar: {
    borderBottom: "none"
  }
};

function myBlockStyleFn(contentBlock) {
  const type = contentBlock.getType();
  if (type === "blockquote") {
    return "fuse-rte-blockquote";
  }
}

class RichTextInputField extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    toolbarConfig: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  static defaultProps = {
    label: "Input",
    placeholder: "Please provide some content",
    toolbarConfig: {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS"
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" }
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
        { label: "Blockquote", style: "blockquote" }
      ]
    },
    type: "richtext",
    width: 440
  };

  constructor(props) {
    super(props);

    this.state = {
      limitError: null,
      helperText: props.helperText,
      value: props.value
        ? RichTextEditor.createValueFromString(props.value, "html")
        : RichTextEditor.createEmptyValue()
    };

    this.onChange = throttle(this._onChange, 200).bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { helperText } = nextProps;

    if (helperText) this.setState({ helperText });
  }

  _onChange = () => {
    const { value } = this.state;

    this.props.onChange(value.toString("html"));
  };

  onEditorChange = value => {
    this.setState({ value }, this.onChange);
  };

  render() {
    const {
      classes,
      error,
      helperText,
      label,
      onBlur,
      placeholder,
      toolbarConfig,
      width
    } = this.props;

    const { value } = this.state;
    const textStyle = error ? { color: "#DB6E53" } : {};

    return (
      <div className={classes.root} style={{ width }}>
        {label ? (
          <div className={classes.label} style={textStyle}>
            {label}
          </div>
        ) : null}

        <RichTextEditor
          blockStyleFn={myBlockStyleFn}
          className={classes.rteRoot}
          onBlur={onBlur}
          onChange={this.onEditorChange}
          placeholder={placeholder}
          toolbarClassName={classes.rteToolbar}
          toolbarConfig={toolbarConfig}
          value={value}
        />

        {helperText ? (
          <div className={classes.helperText} style={textStyle}>
            {helperText}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(RichTextInputField);
