import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    // marginTop: 10
    // paddingBottom: 10
  },
  label: {
    fontWeight: 600,
    marginBottom: 10
  },
  helperText: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300
  },
  rulerBottom: {
    height: 10,
    marginLeft: -30,
    marginRight: -30,
    borderBottom: "1px solid #E6E7E9"
  },
  rulerTop: {
    height: 20,
    marginLeft: -30,
    marginRight: -30,
    marginTop: 20,
    borderTop: "1px solid #E6E7E9"
  }
};

const FormHeader = props => {
  const { classes, helperText, label, rulerBottom, rulerTop, width } = props;

  return (
    <React.Fragment>
      {rulerTop && <div className={classes.rulerTop} />}

      <div className={classes.root} style={{ width }}>
        <div className={classes.label}>{label}</div>
        {helperText && <div className={classes.helperText}>{helperText}</div>}
      </div>

      {rulerBottom && <div className={classes.rulerBottom} />}
    </React.Fragment>
  );
};

FormHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  rulerBottom: PropTypes.bool,
  rulerTop: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

FormHeader.defaultProps = {
  helperText: "",
  width: 584
};

export { FormHeader, styles };
export default withStyles(styles, { name: "FormHeader" })(FormHeader);
