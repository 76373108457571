import React from "react";

import ResetPasswordPageContainer from "../components/Authentication/ResetPasswordPageContainer";

import PageTemplate from "./PageTemplate";

const ResetPassword = props => {
  return (
    <PageTemplate>
      <ResetPasswordPageContainer />
    </PageTemplate>
  );
};

export default ResetPassword;
