import React from "react";

import EmailIcon from "@material-ui/icons/Email";

import CompanyPanel from "../common/CompanyPanel";
import ReferralButton from "../common/ReferralButton";
import TagLabel from "../common/TagLabel";

export const formatDate = (
  string,
  options = {
    year: "numeric",
    month: "short",
    day: "numeric"
  }
) => {
  return new Date(string).toLocaleDateString("en-GB", options);
};

export const renderCompany = (referral, classes) => {
  const { recipient_company, recipient_company_name } = referral;

  const company = recipient_company || {
    name: recipient_company_name
  };

  const labelClass = recipient_company
    ? classes.companyLabel
    : classes.companyLabelNoLink;

  const nameClass = recipient_company
    ? classes.companyName
    : classes.companyNameGrey;

  return (
    <CompanyPanel
      classes={{
        label: labelClass,
        logo: classes.companyLogo,
        name: nameClass
      }}
      radius={30}
      size={60}
      company={company}
      subtitle={company.mission_statement}
    />
  );
};

export const renderFounderContact = (referral, classes) => {
  return (
    <a href={`mailto:${referral.recipient}`}>
      <div className={classes.recipientLink}>
        <EmailIcon className={classes.emailIcon} />
        <span style={{}}>{referral.recipient_name || referral.recipient}</span>
      </div>
    </a>
  );
};

export const renderReferralDate = referral => {
  if (!referral.created_at) return null;

  return formatDate(referral.created_at);
};

export const renderStatus = referral => {
  if (referral.is_registered) {
    return (
      <TagLabel
        label="Accepted"
        color="green"
        solid={true}
        style={{ marginLeft: -5 }}
      />
    );
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TagLabel
        label="Pending"
        color="grey"
        solid={false}
        style={{ marginLeft: -5, marginRight: 10 }}
      />
      <ReferralButton referral={referral} size="small">
        Resend
      </ReferralButton>
    </div>
  );
};
