import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../../Icons";

const styles = {
  root: {},
  header: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: 30,
    marginBottom: 30,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer"
  }
};

class MemberCompanySection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open
    };
  }

  toggleState = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { children, classes, header } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.headerRow} onClick={this.toggleState}>
          <div className={classes.header}>{header}</div>
          <div>{open ? <Icons.ChevronUp /> : <Icons.ChevronDown />}</div>
        </div>

        {open && children}
      </div>
    );
  }
}

MemberCompanySection.defaultProps = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  open: PropTypes.bool
};

MemberCompanySection.defaultProps = {
  open: false
};

export default withStyles(styles)(MemberCompanySection);
