import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import LoadingPanel from "../common/LoadingPanel";
import LocalLink from "../common/LocalLink";

import ReferralsListEmptyState from "./ReferralsListEmptyState";
import ReferralsTable from "./ReferralsTable";

import { getRecentReferrals, sortByCompanyName } from "./utils";

const styles = {
  root: {
    marginTop: 40,
    width: "100%",
    overflowX: "auto"
  },
  title: {
    backgroundColor: "white",
    borderBottom: "1px solid #E6E7E9",
    color: "#2A2B2C",
    fontSize: 15,
    fontWeight: 700,
    height: 60,
    lineHeight: "60px",
    paddingLeft: 30
  },
  footerLink: {
    borderTop: "1px solid #E6E7E9",
    color: "#398DD3",
    fontSize: 15,
    fontWeight: 700,
    padding: 15,
    paddingLeft: 30
  }
};

const FooterLink = props => {
  const { classes, count } = props;

  return (
    <div className={classes.footerLink}>
      <LocalLink
        label={`View all ${count} Referrals`}
        link="/startups#referrals"
      />
    </div>
  );
};

const ReferralsList = props => {
  const { classes, company, recent } = props;

  const referrals = recent
    ? getRecentReferrals(props.referrals, recent)
    : [...props.referrals].sort(sortByCompanyName);

  const body = referrals.length ? (
    <ReferralsTable
      company={company}
      referrals={referrals}
      loading={referrals.loading}
    />
  ) : referrals.loading ? (
    <LoadingPanel />
  ) : (
    <ReferralsListEmptyState />
  );

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {props.title || `My Referrals (${referrals.length})`}
      </div>
      <div style={{ padding: "20px 30px" }}>
        View the status of your recently referred founder companies.
      </div>

      {body}

      {referrals.length < props.referrals.length ? (
        <FooterLink classes={classes} count={props.referrals.length} />
      ) : null}
    </div>
  );
};

ReferralsList.propTypes = {
  classes: PropTypes.object.isRequired,
  recent: PropTypes.number.isRequired,
  referrals: PropTypes.array.isRequired,
  title: PropTypes.string
};

ReferralsList.defaultProps = {
  recent: 0
};

export { ReferralsList, styles };

export default withStyles(styles)(ReferralsList);
