import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { getCapitalRange, currency, stage } from "../../Company/config";

import ButtonGroup from "../../common/ButtonGroup/ButtonGroup";
import IdSelector from "../IdSelector";
import TextInputField from "../TextInputField";

const styles = {
  root: {
    width: "100%",
    overflowX: "auto",
    overflowY: "visible",
    paddingBottom: 100
  },
  controlsRoot: {
    marginTop: 0,
    marginRight: 10
  },
  label: {
    fontSize: 15,
    marginTop: 20,
    marginBottom: 5
  },
  input: {
    boxSizing: "border-box",
    padding: "15px 10px",
    backgroundColor: "#F2F3F4",
    width: 220,
    marginBottom: 20
  },
  bGroupButton: {
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 40
  }
};

class CompanyFundingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      capital_to_date: null,
      capital_to_date_currency: "gbp",
      current_target: 0,
      current_target_currency: "gbp",
      funding_rounds: 0,
      revenue_range: null,
      revenue_range_currency: "gbp",
      is_actively_seeking_funding: false,
      stage: "seed",

      // update with company defaults and values
      ...props.company
    };
  }

  handleChange = id => value => {
    this.setState({ [id]: value }, () => {
      this.props.onChange(this.state);
    });
  };

  render() {
    const { classes, config } = this.props;
    const capital_range = getCapitalRange(config);
    const revenue_range = getCapitalRange(config);

    return (
      <div className={classes.root}>
        <div className={classes.label}>
          How many previous funding rounds have you completed?
        </div>
        <ButtonGroup
          classes={{
            button: classes.bGroupButton,
            disabled: classes.bGroupButton,
            selected: classes.bGroupButton
          }}
          maxSelections={1}
          onChange={value => {
            if (!value.length) {
              this.handleChange("funding_rounds")(0);
            } else if (value[0] === "9+") {
              this.handleChange("funding_rounds")(9);
            } else {
              this.handleChange("funding_rounds")(parseInt(value[0], 10));
            }
          }}
          options={["1", "2", "3", "4", "5", "6", "7", "8", "9+"]}
          state={
            this.state.funding_rounds === 9
              ? "9+"
              : `${this.state.funding_rounds}`
          }
          style={{ width: 440 }}
        />

        <div className={classes.label}>
          What is your most recent funding round?
        </div>
        <IdSelector
          classes={{ root: classes.controlsRoot }}
          id="stage"
          label=""
          onChange={this.handleChange("stage")}
          options={stage.options}
          labels={stage.labels}
          value={this.state.stage}
          width={220}
        />

        <div className={classes.label}>
          What is the total amount of funding raised? (as a range)
        </div>
        <div style={{ display: "flex" }}>
          <IdSelector
            classes={{ root: classes.controlsRoot }}
            label=""
            id="capital_to_date_currency"
            onChange={this.handleChange("capital_to_date_currency")}
            options={currency.options}
            labels={currency.labels}
            value={this.state.capital_to_date_currency}
            width={110}
          />
          <IdSelector
            classes={{ root: classes.controlsRoot }}
            label=""
            id="capital_to_date"
            onChange={this.handleChange("capital_to_date")}
            options={capital_range.options}
            labels={capital_range.labels}
            value={this.state.capital_to_date}
            width={220}
          />
        </div>

        <div className={classes.label}>
          What is your annual revenue? (as a range)
        </div>
        <div style={{ display: "flex" }}>
          <IdSelector
            classes={{ root: classes.controlsRoot }}
            label=""
            id="revenue_range_currency"
            onChange={this.handleChange("revenue_range_currency")}
            options={currency.options}
            labels={currency.labels}
            value={this.state.revenue_range_currency}
            width={110}
          />
          <IdSelector
            classes={{ root: classes.controlsRoot }}
            label=""
            id="revenue_range"
            onChange={this.handleChange("revenue_range")}
            options={revenue_range.options}
            labels={revenue_range.labels}
            value={this.state.revenue_range}
            width={220}
          />
        </div>

        <div className={classes.label}>Are you actively seeking funding?</div>
        <ButtonGroup
          maxSelections={1}
          onChange={value => {
            if (value.length && value[0] === "Yes") {
              this.handleChange("is_actively_seeking_funding")(true);
            } else {
              this.handleChange("is_actively_seeking_funding")(false);
            }
          }}
          options={["Yes", "No"]}
          state={this.state.is_actively_seeking_funding ? "Yes" : "No"}
        />

        <div className={classes.label}>
          How much are you seeking to raise in your current funding round?
        </div>

        <div style={{ display: "flex" }}>
          <IdSelector
            classes={{ root: classes.controlsRoot }}
            label=""
            id="current_target_currency"
            onChange={this.handleChange("current_target_currency")}
            options={currency.options}
            labels={currency.labels}
            value={this.state.current_target_currency}
            width={110}
            disabled={!this.state.is_actively_seeking_funding}
          />
          <TextInputField
            classes={{ root: classes.controlsRoot }}
            label=""
            id="revenue_range"
            onChange={this.handleChange("current_target")}
            type="number"
            value={this.state.current_target}
            width={220}
            disabled={!this.state.is_actively_seeking_funding}
          />
        </div>
      </div>
    );
  }
}

CompanyFundingForm.propTypes = {
  company: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

CompanyFundingForm.defaultProps = {
  company: {}
};

const mapStateToProps = state => ({ config: state.config });

export { CompanyFundingForm, styles };
export default connect(mapStateToProps)(withStyles(styles)(CompanyFundingForm));
