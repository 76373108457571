import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import emailvalidator from "email-validator";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import { fetchWithToken } from "../../../api";
import { responseJsonOrError } from "../../../api/response";
import { createFlashNote } from "../../../Reducers/FlashNotes";
import TextInputField from "../TextInputField";

import {
  getOutlineButtonClasses,
  getPrimaryButtonClasses
} from "../../../styles/buttonStyles";

const { iconClass, ...outlineButtonStyles } = getOutlineButtonClasses(
  "#2A2B2C"
);
const { iconClass: _ic, ...primaryButtonStyles } = getPrimaryButtonClasses(
  "#398DD3"
);
const OutlineButton = withStyles(outlineButtonStyles)(Button);
const PrimaryButton = withStyles(primaryButtonStyles)(Button);

const styles = {
  root: {
    border: "1px solid #EDEDEF",
    marginRight: 30,
    padding: 30
  },
  buttons: {
    marginTop: 20
  },
  error: {
    border: "1px solid #DB6E53",
    borderRadius: 3,
    backgroundColor: "#F9EFED",
    padding: "10px 15px"
  },
  spinnerButtonRoot: {
    marginTop: 0
  },
  title: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 600
  }
};

const sendInvite = (recipient, url) => {
  const data = { recipient };

  const options = {
    method: "POST",
    body: JSON.stringify(data)
  };

  return fetchWithToken(url, options).then(responseJsonOrError);
};

class InviteTeamMemberDialog extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    createFlashNote: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    sendInvite: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired
  };

  static defaultProps = {
    createFlashNote: () => {},
    onCancel: () => {},
    sendInvite,
    url: "/api/managers/invitations/"
  };

  state = {
    error: null,
    email: "",
    pending: false,
    success: false,
    valid: false,
    validationErrors: {}
  };

  onSubmit = () => {
    const { createFlashNote, sendInvite } = this.props;

    return sendInvite(this.state.email, this.props.url)
      .then(json => {
        const { error } = json;

        if (error) {
          createFlashNote(`Invitation failed: ${error.message}`, "error");
          this.setState({ ...json, pending: false });
        } else {
          createFlashNote("The invitation has been sent.", "success");
          this.setState({ pending: false, success: true });
        }
      })
      .catch(error => {
        this.setState({ error, pending: false });
      });
  };

  setEmail = email => {
    const valid = emailvalidator.validate(email);

    this.setState({ email, valid });
  };

  render() {
    const { classes } = this.props;
    const {
      error,
      email,
      pending,
      success,
      valid,
      validationErrors
    } = this.state;

    const closeLabel = error || success ? "Close" : "Cancel";
    const disabled = !valid || pending;
    const helperText = error
      ? validationErrors.recipient || error.message
      : null;

    return (
      <div className={classes.root}>
        <div className={classes.title}>Invite Team Member</div>

        <TextInputField
          error={Boolean(error)}
          label={error ? "Error" : "Email Address"}
          limit={80}
          onChange={this.setEmail}
          value={email}
          helperText={helperText}
          width={440}
        />

        <div className={classes.buttons}>
          <OutlineButton onClick={this.props.onCancel}>
            {closeLabel}
          </OutlineButton>

          {!success &&
            !error && (
              <PrimaryButton
                classes={{ root: classes.spinnerButtonRoot }}
                disabled={disabled}
                onClick={this.onSubmit}
              >
                Invite
              </PrimaryButton>
            )}
        </div>
      </div>
    );
  }
}

export { InviteTeamMemberDialog, styles };

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(InviteTeamMemberDialog));
