import React from "react";

import MapIcon from "@material-ui/icons/Room";

const CompanyLocation = props => {
  const location = props.location || {};

  if (!location.address && !location.city && !location.postcode) return null;

  const lines = [
    ...(location.address || "").split("\n"),
    location.city,
    location.postcode
  ]
    .filter(s => s)
    .map((line, index) => (
      <div
        key={index}
        style={{ color: "#2A2B2C", fontSize: 15, lineHeight: "24px" }}
      >
        {line}
      </div>
    ));

  return (
    <div style={{ padding: 30, display: "flex", ...props.style }}>
      <div style={{ width: 40 }}>
        <MapIcon style={{ fill: "#555B6D" }} />
      </div>
      <div>{lines}</div>
    </div>
  );
};

CompanyLocation.defaultProps = {
  style: { marginTop: 20 }
};

export default CompanyLocation;
