import React from "react";
import { PropTypes } from "prop-types";

import withWidth from "@material-ui/core/withWidth";

import Banner from "../common/Banner";

import ErrorPanel from "../common/Errors/ErrorPanel";
import LoadingPanel from "../common/LoadingPanel";
import DocumentDetailsView from "./DocumentDetailsView";
import DocumentManageView from "./DocumentManageView";

import ManagePanel from "./ManagePanel/ManagePanel";
import { MANAGE_ACTIONS, parseURL, validateMatchParams } from "./utils";

const DocumentBanner = props => {
  const { document, error, loading, width } = props;

  const title = error
    ? "Error"
    : loading
    ? "loading ..."
    : props.title || document.name;

  return (
    <Banner
      title={title}
      logo={document.logo_image_url}
      fontSize={width === "xs" ? 22 : 32}
      document={document}
    />
  );
};

class DocumentDetailsViewContainer extends React.Component {
  static defaultProps = {
    BannerComponent: DocumentBanner,
    ManagePanelComponent: ManagePanel,
    MenuComponent: null,
    ViewComponent: DocumentDetailsView
  };

  static propTypes = {
    BannerComponent: PropTypes.func.isRequired,
    ManagePanelComponent: PropTypes.func,
    ViewComponent: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    doctype: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    loadDocument: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    labels: PropTypes.array,
    setWindowTitle: PropTypes.func,
    subtitles: PropTypes.array
  };

  state = {
    activity: null,
    error: null,
    loading: true,
    document: {},
    documentId: null
  };

  componentDidMount() {
    this.parseLocation(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.parseLocation(nextProps);
  }

  parseLocation = props => {
    const { location } = props;
    const { activity } = props.match.params;

    const { documentId, ...other } = validateMatchParams(
      parseURL(location.pathname),
      this.props.profile
    );

    if (documentId !== this.state.documentId) {
      this.load(documentId);
    }

    this.setState({ ...other, activity });
  };

  load = documentId => {
    if (this.state.loading === documentId) return;
    if (this.state.documentId === documentId) return;

    this.setState({ loading: documentId }, () => this._load(documentId));
  };

  _load = documentId => {
    this.props
      .loadDocument(documentId)
      .then(document => {
        if (document.error) {
          this.setState({ error: document.error, documentId, loading: false });
        } else {
          if (this.props.setWindowTitle) {
            this.props.setWindowTitle(document);
          }
          this.setState({ document, documentId, loading: false });
        }
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  };

  renderManageView = () => {
    const { doctype, loadDocument, width, ...otherProps } = this.props;
    const { activity, document } = this.state;

    return (
      <DocumentManageView
        {...otherProps}
        activity={activity}
        doctype={doctype}
        document={document}
      />
    );
  };

  render() {
    const { activity, error, loading, document } = this.state;
    const {
      BannerComponent,
      ManagePanelComponent,
      MenuComponent,
      ViewComponent,
      company,
      doctype,
      profile,
      width
    } = this.props;

    // loading={Boolean(loading)}

    if (MANAGE_ACTIONS.indexOf(activity) !== -1) {
      return this.renderManageView(activity);
    }

    let body = null;

    if (error) {
      body = <ErrorPanel error={error} />;
    } else if (loading) {
      body = <LoadingPanel />;
    } else if (document.id) {
      body = (
        <ViewComponent doctype={doctype} document={document} width={width} />
      );
    }

    return (
      <div>
        <BannerComponent
          error={error}
          loading={loading}
          document={document}
          width={width}
        />
        {MenuComponent && <MenuComponent document={document} />}
        {ManagePanelComponent && (
          <ManagePanelComponent
            company={company}
            document={document}
            profile={profile}
          />
        )}
        {body}
      </div>
    );
  }
}

export default withWidth()(DocumentDetailsViewContainer);
