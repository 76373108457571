import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import emptyStateImage from "../../assets/flow-diagram.svg";

const styles = {
  root: {
    padding: 30,
    textAlign: "center"
  },
  subtitle: {
    margin: "20px auto",
    maxWidth: 600
  },
  title: {
    color: "#AAADB6",
    fontSize: 17,
    fontWeight: 600
  }
};

const EmptyListState = props => {
  const { children, classes, image, title, style, subtitle } = props;

  return (
    <div className={classes.root} style={style}>
      {image && <img src={image} alt="illustration" />}
      {title && <div className={classes.title}>{title}</div>}
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      {children}
    </div>
  );
};

EmptyListState.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.string,
  style: PropTypes.object.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

EmptyListState.defaultProps = {
  image: emptyStateImage,
  style: {},
  subtitle: "",
  title: "No content yet"
};

export default withStyles(styles)(EmptyListState);
