const styles = {
  root: {
    marginTop: 40,
    marginBottom: 90,
    width: "100%"
  },

  // top, middle, bottom for xl and lg layouts
  containerTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  containerMiddle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    backgroundColor: "white",
    borderTop: "1px solid #E6E7E9",
    borderBottom: "1px solid #E6E7E9",

    paddingTop: 30,
    paddingBottom: 30,
    marginTop: 60
  },
  containerBottom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 60
  },
  panel: {
    //outline: "1px solid red",
    backgroundColor: "white",
    boxShadow: "0 5px 10px 0 rgba(55,60,68,0.05)",
    marginBottom: 30
  },

  // layout structure: row, main, sidebar
  main: {
    flexGrow: 1,
    width: 810,
    alignItems: "flex-start"
  },
  row: {
    display: "flex",
    alignItems: "flex-start",
    width: 1260
  },
  sidebar: {
    width: 420,
    marginLeft: 30,
    flexGrow: 0,
    height: "auto"
  },

  companyLabel: {
    color: "#555B6E",
    fontSize: 16
  },
  companyName: {
    color: "#6592CC",
    fontWeight: 600
  },
  companyRoot: {
    marginLeft: 30
  },

  description: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 20,
    paddingLeft: 15,
    borderLeft: "3px solid #398DD3"
  },

  financeRoot: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  financePanel: {
    maxWidth: 280
  },

  questions: {
    height: 100,
    backgroundColor: "white",
    fontWeight: "bold",
    color: "#E6E7E9",
    lineHeight: "100px",
    flexGrow: 1,
    textAlign: "center"
  },
  tagRoot: {
    marginRight: 5,
    marginTop: 5,
    borderRadius: 14
  },

  paragraph: {
    marginTop: 25,
    color: "#2A2B2C",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "24px"
  },
  title: {
    color: "#2A2B2C",
    fontSize: 18,
    fontWeight: 600
  }
};

export default styles;
