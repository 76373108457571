import { connect } from "react-redux";

import { createFlashNote } from "../../Reducers/FlashNotes";
import { loadReferrals } from "../../Reducers/Referrals";

import ReferFounderTeamDialog from "./ReferFounderTeamDialog";

const mapStateToProps = state => {
  return {
    config: state.config,
    referrals: state.referrals
  };
};

const mapDispatchToProps = dispatch => ({
  createFlashNote: (msg, type) => dispatch(createFlashNote(msg, type)),
  loadReferrals: () => dispatch(loadReferrals())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferFounderTeamDialog);
