export const RESET_COMPANY_DATA = "RESET_COMPANY_DATA";
export const SET_COMPANY_DATA = "SET_COMPANY_DATA";
export const UPDATE_COMPANY_DATA = "UPDATE_COMPANY_DATA";

export const resetCompanyData = () => {
  return {
    type: RESET_COMPANY_DATA,
    payload: {}
  };
};

export const setCompanyData = data => {
  return {
    type: SET_COMPANY_DATA,
    payload: data
  };
};

export const updateCompanyData = data => {
  return {
    type: UPDATE_COMPANY_DATA,
    payload: data
  };
};
