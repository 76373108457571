import { connect } from "react-redux";

import { logout } from "../../../Reducers/Profile";

import ProfileButton from "./ProfileButton";

const mapStateToProps = state => {
  return {
    profile: state.profile,
    company: state.company
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileButton);
