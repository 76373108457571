import React from "react";
import { connect } from "react-redux";

import MenuBar from "../common/MenuBar";

import CreateButton from "../common/Buttons/CreateButton";

const CreateMenu = ({ company }) => {
  if (!company || company.id !== 69) {
    return null;
  }

  return (
    <MenuBar>
      <div style={{ flexGrow: 1 }} />
      <CreateButton link="/marketplace/new">Create New</CreateButton>
    </MenuBar>
  );
};

const mapStateToProps = state => ({ company: state.company });

export default connect(
  mapStateToProps,
  null
)(CreateMenu);
