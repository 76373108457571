import React from "react";
import { withRouter } from "react-router-dom";

import imageWhitespaceTeam from "../assets/images/about/colinked-about-whitespace-team.png";
import imageColinkedScreen from "../assets/images/about/colinked-our-platform.jpg";

const About = props => {
  const { history } = props;

  return (
    <div>
      <section className="page-header-centered">
        <h1>
          Our mission is to <span>foster collaboration</span> by{" "}
          <span>streamlining introductions</span>
        </h1>
        <p>
          After years of matching corporates and scale-ups offline, we built
          Colinked to scale our process and allow industry-wide collaborations.
          We’re doing this because we understand the challenges of corporate
          engagement and the necessity of building strong routes to market for
          founders.
        </p>
      </section>

      <section className="content-padding-large">
        <section className="about-block">
          <div className="image">
            <img
              src={imageWhitespaceTeam}
              alt="Colinked - the Whitespace team who developed Colinked"
            />
          </div>

          <div className="text">
            <h2>About Colinked</h2>
            <p>
              In 2014, Whitespace started as a VC firm founded by a team of
              former hackers and financial advisors. We would invest exclusively
              into startups who were able to address the challenges our
              corporate partners faced. Through working with our startups to
              make their technology enterprise friendly, we developed a
              brilliant tech team building scalable products used by global
              clients.
            </p>

            <p>
              In 2016, we closed our fund to focus on our ability to develop
              software. We've kept our strong corporate partnerships alive
              through our corp club, an offline bi-monthly meeting where we kept
              hearing the same corporate challenge brought up; how can we best
              manage startup engagement and innovation?
            </p>
          </div>
        </section>

        <section className="about-block">
          <div className="image">
            <img
              src={imageColinkedScreen}
              alt="Colinked - the the Colinked platform being used on a laptop"
            />
          </div>

          <div className="text">
            <h2>Our Platform</h2>
            <p>
              Colinked addresses the challenge that many corporates have in
              finding and engaging with well qualified and pre-vetted startups.
              The traditional way that Corporates do this is by a blend of
              talent scouts, accelerators, incubators, competitions and VC
              networks. Colinked is an online platform for Corporates and
              referred startups to better connect in one place and, through the
              use of AI and ML, also allow Corporates to have a much more
              efficient and richer experience in completing their search.{" "}
            </p>
          </div>
        </section>
      </section>

      <section className="call-to-action">
        <div className="container">
          <div className="content">
            <h2>Our Valued Members</h2>
            <p>
              In our beta, we signed up some of the biggest corporates and
              brightest founder teams, you can join our movement here:
            </p>
            <button
              className="btn"
              onClick={() => history.push("/forcorporates")}
            >
              For Corporates
            </button>{" "}
            <button
              className="btn"
              onClick={() => history.push("/forfounders")}
            >
              For Founders
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(About);
