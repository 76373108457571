import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import ExpansionSection from "./ExpansionSection";
import FounderForm from "./FounderForm";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 60
    //padding: 20,
    //border: "2px solid lightgrey"
  },
  title: {
    fontSize: 16,
    fontWeight: "bolder"
  }
});

class FoundersSection extends React.Component {
  static propTypes = {
    expansion: PropTypes.bool,
    founders: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    title: PropTypes.string,

    update: PropTypes.func.isRequired
  };

  static defaultProps = {
    expansion: false,
    founders: "[]",
    title: "Founders"
  };

  constructor(props) {
    super(props);

    const founders = JSON.parse(props.founders);
    this.state = {
      founders: [...founders]
    };
  }

  componentWillReceiveProps(nextProps) {
    let founders = [];
    if (typeof nextProps.founders === "string") {
      founders = JSON.parse(nextProps.founders);
    } else if (Array.isArray(nextProps.founders)) {
      founders = nextProps.founders;
    }

    this.setState({ founders });
  }

  addFounder = () => {
    const founders = [
      ...this.state.founders,
      {
        avatar_url: "",
        first_name: "",
        last_name: "",
        email: "",
        bio: "",
        position: "",
        social_linkedin: "",
        social_twitter: ""
      }
    ];

    this.setState({ founders });
  };

  removeFounder = index => {
    const founders = [...this.state.founders];
    founders.splice(index, 1);

    this.setState({ founders }, this.update);
  };

  updateFounder = (index, founder) => {
    const founders = [...this.state.founders];
    founders[index] = founder;

    this.setState({ founders }, this.update);
  };

  update = () => {
    const founders = JSON.stringify(this.state.founders);

    this.props.update(founders);
  };

  renderSectionsInput = () => {
    const { founders } = this.state;

    return founders.map((founder, index) => {
      const onChange = args => {
        this.updateFounder(index, args);
      };
      const onRemove = () => this.removeFounder(index);

      return (
        <FounderForm
          index={index}
          key={index}
          founder={founder}
          onChange={onChange}
          onRemove={onRemove}
        />
      );
    });
  };

  addSectionButton = existing => {
    const label = existing ? "Add another Founder" : "Add a Founder";

    return (
      <div
        style={{
          marginTop: 24,
          paddingTop: 12,
          borderTop: "1px solid lightgrey"
        }}
      >
        <div style={{ display: "flex" }}>
          <Button
            size="small"
            onClick={this.addFounder}
            style={{ color: "green" }}
          >
            <span style={{ fontSize: 18, marginRight: 5 }}>{"\u002B"}</span>
            {label}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { classes, expansion, title } = this.props;
    const { founders } = this.state;

    const body = (
      <div className={classes.root}>
        {title && <div className={classes.title}>{title}</div>}
        {this.renderSectionsInput()}
        {this.addSectionButton(founders.length, classes)}
      </div>
    );

    if (!expansion) {
      return body;
    }

    return (
      <ExpansionSection
        title={title}
        summaryText={founders.map(s => s.title).join(", ")}
        panelProps={{ defaultExpanded: Boolean(founders.length) }}
      >
        {body}
      </ExpansionSection>
    );
  }
}

export default withStyles(styles)(FoundersSection);
