const styles = {
  root: {
    width: "100%",
    overflowX: "auto",
    //marginTop: 20,
    marginBottom: 30
  },
  card: {
    padding: 15
  },
  cardContainer: {
    position: "relative",
    margin: 30,
    borderTop: "1px solid #E6E7E9",
    "&:first-child": {
      borderTop: 0
    }
  },
  contactBio: {
    paddingRight: 120
  },
  deleteButton: {
    padding: 0,
    minWidth: 28
  },
  divider: {
    color: "#2A2B2C",
    fontSize: 15,
    fontWeight: 600,
    borderTop: "1px solid #E6E7E9",
    paddingTop: 20,
    paddingLeft: 30
  },
  error: {
    display: "flex",
    alignItems: "center",

    color: "#2A2B2C",
    fontSize: 14,
    border: "1px solid #DB6E53",
    borderRadius: 3,
    backgroundColor: "#F9EFED",
    padding: "10px 15px",
    marginLeft: 80,
    marginTop: 20
  },
  errorMessage: {
    flexGrow: 1
  },
  clearError: {
    marginLeft: 10,
    "&:hover": {
      cursor: "pointer"
    }
  },
  icon: {
    fontSize: 18,
    //marginTop: -4,
    marginLeft: 5
  },
  iconDelete: {
    fontSize: 18,
    marginTop: -1
  },
  label: {
    fontSize: 15,
    marginTop: 20,
    marginBottom: 5
  },
  input: {
    boxSizing: "border-box",
    padding: "15px 10px",
    backgroundColor: "#F2F3F4",
    width: 220,
    marginBottom: 20
  }
};

export default styles;
