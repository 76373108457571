import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import IconOpenInNew from "@material-ui/icons/OpenInNew";

import placeholderLogo from "../../assets/corp_logo_placeholder.png";

import LogoImage from "../common/LogoImage";

import ScrollableDialog from "../common/ScrollableDialog";

import ApplicationDetailsTabs from "./ApplicationDetailsTabs";
import ApplicationsManageButtons from "./ApplicationsManageButtonsContainer";
import ApplicationNotes from "./ApplicationNotes";

const styles = {
  paper: {
    padding: 0
  },
  buttonRoot: {
    backgroundColor: "none",
    marginLeft: 30
  },
  companyName: {
    display: "flex",
    alignItems: "center",

    color: "#398DD3",
    fontSize: 15,
    fontWeight: 600,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  companyNameIcon: {
    fontSize: 18,
    marginLeft: 5
  },
  description: {
    color: "#2A2B2C",
    fontSize: 13,
    lineHeight: "17px",
    fontWeight: 400,
    marginTop: 5,
    maxHeight: 32,
    overflowY: "hidden",
    hyphens: "auto"
  },
  title: {
    color: "#2A2B2C",
    fontSize: 16,
    fontWeight: 600,
    padding: "30px 20px",
    margin: 0
  },
  header: {
    backgroundColor: "#F2F3F4",
    display: "flex",
    alignItems: "center",
    padding: 30
  }
};

const ApplicationDetailsModal = props => {
  const { classes, application, onClose, title } = props;

  const { company } = application;

  return (
    <ScrollableDialog
      title={title}
      onClose={onClose}
      style={{
        width: 1100,
        height: 900,
        marginTop: 120,
        marginLeft: -550,
        top: 0
      }}
    >
      <div className={classes.header}>
        <LogoImage
          url={company.logo_image_url || placeholderLogo}
          radius={30}
        />
        <div style={{ flexGrow: 1, flexShrink: 1 }}>
          <div className={classes.companyName}>
            <span>{company.name}</span>
            <IconOpenInNew className={classes.companyNameIcon} />
          </div>
          <div className={classes.description}>{company.description}</div>
        </div>
        <ApplicationsManageButtons
          classes={{ root: classes.buttonRoot }}
          format="short"
          selected={[application]}
          status="pending"
        />
      </div>
      <ApplicationDetailsTabs {...application} />
      <ApplicationNotes notes={application.notes || []} />
    </ScrollableDialog>
  );
};

ApplicationDetailsModal.propTypes = {
  application: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

ApplicationDetailsModal.defaultProps = {
  title: "Company Details"
};

export default withStyles(styles)(ApplicationDetailsModal);
