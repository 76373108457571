import React from "react";
import { PropTypes } from "prop-types";

import ChevronDown from "@material-ui/icons/KeyboardArrowDown";
import ChevronUp from "@material-ui/icons/KeyboardArrowUp";
import IconPen from "@material-ui/icons/Create";
import { withStyles } from "@material-ui/core/styles";

import { PrimaryButtonGreen } from "../../common/Buttons";
import TrashCanOutline from "../../Icons/TrashCanOutline";

import Testimonial from "../../common/Testimonials/Testimonial";
import * as propTypes from "../../common/Testimonials/propTypes";

import TestimonialEditForm from "./TestimonialEditForm";
import styles from "./styles";

const stripLocalState = testimonial => {
  const { _edit, _expand, ...ttm } = testimonial;

  return ttm;
};

class TestimonialsManagement extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    testimonials: propTypes.testimonials
  };

  static defaultProps = {
    testimonials: []
  };

  constructor(props) {
    super(props);

    this.state = {
      testimonials: [...props.testimonials],
      showAddForm: false
    };
  }

  addTestimonial = (testimonial, update) => {
    if (!update) {
      this.setState({ showAddForm: false });
    } else {
      const testimonials = [...this.state.testimonials, testimonial];
      this.setState({ testimonials, showAddForm: false }, () => {
        if (update) {
          this.handleChange();
        }
      });
    }
  };

  deleteTestimonial = index => {
    const testimonials = this.state.testimonials.filter(
      (_, idx) => idx !== index
    );

    this.setState({ testimonials }, this.handleChange);
  };

  editTestimonial = index => {
    const testimonials = this.state.testimonials.map((ttm, _idx) =>
      _idx === index ? { ...ttm, _edit: true } : { ...ttm, _edit: false }
    );

    this.setState({ testimonials });
  };

  handleChange = () => {
    const testimonials = this.state.testimonials.map(stripLocalState);

    this.props.onChange(testimonials);
  };

  toggleExpand = index => {
    const testimonials = this.state.testimonials.map((ttm, _idx) => {
      if (_idx !== index) return ttm;

      const _expand = !Boolean(ttm._expand);
      const _edit = !_expand ? false : ttm._edit;

      return { ...ttm, _expand, _edit };
    });

    this.setState({ testimonials });
  };

  updateTestimonial = index => (newTTM, update) => {
    const testimonials = this.state.testimonials.map((ttm, _idx) =>
      _idx === index ? { ...ttm, ...newTTM } : ttm
    );

    this.setState({ testimonials }, () => {
      if (update) {
        this.handleChange();
      }
    });
  };

  renderButton = () => {
    const { testimonials } = this.state;

    return (
      <div style={{ marginTop: 40, textAlign: "right" }}>
        <PrimaryButtonGreen
          onClick={() => this.setState({ showAddForm: true })}
          disabled={testimonials.some(t => t._edit)}
        >
          Add New
        </PrimaryButtonGreen>
      </div>
    );
  };

  renderForm = () => {
    const { classes } = this.props;

    return (
      <div className={classes.formContainer}>
        <div className={classes.testimonial}>
          <div className={classes.header}>
            <div className={classes.title}>
              <strong>New Testimonial</strong>
            </div>
          </div>
          <TestimonialEditForm
            classes={{ root: classes.editForm }}
            label="Add"
            onChange={this.addTestimonial}
          />
        </div>
      </div>
    );
  };

  renderIcons = index => {
    const { classes } = this.props;
    const { showAddForm, testimonials } = this.state;
    const showEdit = !showAddForm && !testimonials.some(t => t._edit);

    if (!testimonials[index]._expand)
      return (
        <ChevronDown
          className={classes.icon}
          onClick={() => this.toggleExpand(index)}
        />
      );

    return (
      <div className={classes.iconContainer}>
        {showEdit && (
          <IconPen
            className={classes.icon}
            onClick={() => this.editTestimonial(index)}
          />
        )}
        <TrashCanOutline
          className={classes.icon}
          onClick={() => this.deleteTestimonial(index)}
        />
        <ChevronUp
          className={classes.icon}
          onClick={() => this.toggleExpand(index)}
        />
      </div>
    );
  };

  renderTestimonial = (testimonial, index) => {
    const { classes } = this.props;

    const body = testimonial._edit ? (
      <TestimonialEditForm
        classes={{ root: classes.editForm }}
        onChange={this.updateTestimonial(index)}
        testimonial={testimonial}
      />
    ) : testimonial._expand ? (
      <Testimonial
        classes={{ root: classes.details }}
        testimonial={testimonial}
      />
    ) : null;

    return (
      <div key={index} className={classes.testimonial}>
        {this.renderHeader(testimonial, index)}
        {body}
      </div>
    );
  };

  renderHeader = (testimonial, index) => {
    const { classes } = this.props;
    const { author, author_job_title, author_company } = testimonial;

    return (
      <div className={classes.header}>
        <div className={classes.title}>
          <strong>{author}</strong>
          {", "}
          {author_job_title ? `${author_job_title}, ` : null}
          {author_company}
        </div>

        <div style={{ flexGrow: 1 }} />

        {this.renderIcons(index)}
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { showAddForm, testimonials } = this.state;

    return (
      <div className={classes.root}>
        <div>{testimonials.map(this.renderTestimonial)}</div>

        {showAddForm ? this.renderForm() : this.renderButton()}
      </div>
    );
  }
}

export { TestimonialsManagement, styles };
export default withStyles(styles)(TestimonialsManagement);
