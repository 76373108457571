import { connect } from "react-redux";

import { requestConnection } from "../../../../Reducers/Connections";

import ConnectWithDialog from "./ConnectWithDialog";

const mapStateToProps = state => {
  return {
    myCompany: state.company
  };
};

const mapDispatchToProps = dispatch => ({
  requestConnection: (url, data, company, myCompany) =>
    dispatch(requestConnection(url, data, company, myCompany))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectWithDialog);
