import React from "react";

import MarketplaceListPage from "../components/Marketplace/MarketplaceListPage";

import PageTemplate from "./PageTemplate";

const Marketplace = props => {
  return (
    <PageTemplate>
      <MarketplaceListPage />
    </PageTemplate>
  );
};

export default Marketplace;
