import React from "react";
import { PropTypes } from "prop-types";

import Typography from "@material-ui/core/Typography";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => {
  return {
    collapseEntered: { overflow: "visible" },
    expansionRoot: { margin: "16px 0" },
    summaryExpanded: { backgroundColor: theme.palette.neutral.light }
  };
};

const ExpansionSection = props => {
  const {
    classes,
    children,
    title,
    showOverflow,
    summaryText,
    panelProps
  } = props;

  const collapseProps = {
    CollapseProps: {
      classes: {
        entered: classes.collapseEntered
      }
    }
  };

  const rootProps = showOverflow
    ? { ...collapseProps, ...panelProps }
    : panelProps;

  return (
    <ExpansionPanel
      {...rootProps}
      classes={{
        root: classes.expansionRoot
      }}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ expanded: classes.summaryExpanded }}
      >
        <Typography
          variant="subheading"
          style={{ paddingTop: 0, minWidth: 150 }}
        >
          {title}
        </Typography>
        {summaryText && (
          <Typography variant="body1" style={{ flexGrow: 0 }}>
            {summaryText}
          </Typography>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ExpansionSection.propTypes = {
  title: PropTypes.string.isRequired,
  summaryText: PropTypes.string,
  showOverflow: PropTypes.bool
};

ExpansionSection.defaultProps = {
  showOverflow: true
};

export default withStyles(styles)(ExpansionSection);
