import ServiceReducer from "./ServiceReducer";
export default ServiceReducer;

export * from "./ServiceActions";

export {
  loadService,
  deleteService,
  loadServices as loadMarketplace,
  resetServices as resetMarketplace
} from "./ServiceActions";
