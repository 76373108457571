import { responseJsonOrError } from "../../api/response";

import {
  clearTokens,
  fetchWithToken,
  getLocalRefreshToken,
  getAccessToken,
  saveTokens
} from "../../api/tokenApi";

import { cleanProfileData } from "./utils";

export const deleteAccount = password => {
  return fetchWithToken("/api/users/account/delete/", {
    body: JSON.stringify({ password }),
    cache: "no-cache",
    credentials: "same-origin",
    method: "PUT"
  })
    .then(responseJsonOrError)
    .then(response => {
      if (response.error) {
        throw response.error;
      }
      clearTokens();

      return response;
    });
};

export const getUserProfile = () => {
  // console.log("api.getUserProfile()");
  const { error } = getLocalRefreshToken();

  if (error) {
    console.warn("no refresh token found");
    return Promise.reject({});
  }

  return getAccessToken()
    .then(() => {
      return fetchWithToken("/api/users/profile/", {
        cache: "no-cache"
      });
    })
    .then(responseJsonOrError)
    .then(mergeUserProfile)
    .catch(error => {
      console.error(error);

      throw new Error("getAccessToken() failed.");
    });
};

export const login = data => {
  // console.log("api.login()");
  const { username, password } = data;

  if (!username || !password) {
    throw new Error("username and password are required");
  }

  return fetch("/api/auth/token/obtain/", {
    body: JSON.stringify({ username, password }),
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "content-type": "application/json"
    },
    method: "POST"
  })
    .then(responseJsonOrError)
    .then(response => {
      if (response.error) {
        throw response.error;
      }
      saveTokens(response);

      return response;
    })
    .catch(error => {
      console.error(error);

      throw new Error("login failed.");
    });
};

export const logout = () => {
  clearTokens();

  // return promise to keep api signature and prepare for async API action in future
  return Promise.resolve({});
};

const mergeUserProfile = userdata => {
  if (userdata.profile) {
    const { profile, ...userWithoutProfile } = userdata;
    const { id, user, ...profileData } = profile;

    return {
      ...userWithoutProfile,
      ...profileData,
      profileId: id
    };
  }

  return userdata;
};

export const update = data => {
  return fetchWithToken("/api/users/profile/", {
    body: JSON.stringify(cleanProfileData(data)),
    cache: "no-cache",
    credentials: "same-origin",
    method: "PUT"
  }).then(responseJsonOrError);
};

export const updatePassword = data => {
  return fetchWithToken("/api/users/account/password/", {
    body: JSON.stringify(data),
    cache: "no-cache",
    credentials: "same-origin",
    method: "PUT"
  }).then(responseJsonOrError);
};
