import React from "react";
import { PropTypes } from "prop-types";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../Icons";
import styles from "./styles";

const AppointmentTime = props => {
  const { start_datetime, end_datetime, classes } = props;

  const timeFormat = "h:mma";
  const start = moment(start_datetime);
  const end = moment(end_datetime);

  const text = start.isSame(end, "day")
    ? `${start.format(timeFormat)} - ${end.format(timeFormat)}`
    : start.format(timeFormat);

  return (
    <div className={classes.detailsRow}>
      <Icons.Clock className={classes.inlineIcon} />
      <span className={classes.inlineText}>{text}</span>
    </div>
  );
};

AppointmentTime.propTypes = {
  start_datetime: PropTypes.string.isRequired,
  end_datetime: PropTypes.string
};

export default withStyles(styles)(AppointmentTime);
