import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { createFlashNote } from "../../../Reducers/FlashNotes";
import { updateService, deleteService } from "../../../Reducers/Marketplace";

import ServiceLinks from "./ServiceLinks";

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => dispatch(createFlashNote(msg, type)),
    deleteDocument: id => dispatch(deleteService(id)),
    updateDocument: data => dispatch(updateService(data))
  };
};

const ServiceLinksContainer = connect(
  null,
  mapDispatchToProps
)(ServiceLinks);

export default withRouter(ServiceLinksContainer);
