const styles = {
  root: {
    backgroundColor: "white",
    padding: 30
  },
  header: {
    fontSize: 16,
    fontWeight: 600,
    color: "#2A2B2C"
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  detailsRow: {
    marginTop: 15,
    marginBottom: 15,
    width: "auto",
    //height: 24,
    fontWeight: 300,
    borderRadius: 2,
    marginRight: 15,
    display: "flex",
    alignItems: "flex-start",
    "&:last-child": {
      marginBottom: 0
    }
  },
  inlineIcon: {
    color: "#2A2B2C",
    marginRight: 15
  },
  inlineText: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2A2B2C"
  },
  deadlineCountdown: {
    color: "white",
    backgroundColor: "#555B6E"
  },
  detailsBlock: {
    marginTop: 2,
    fontSize: "14px",
    justifyContent: "start",
    color: "#2A2B2C"
  },
  detailsLine: {
    lineHeight: "20px"
  },
  button: {
    marginTop: 30,
    display: "block",
    height: 60,
    width: "100%",
    fontWeight: 600,
    fontSize: 16,
    //maxWidth: 320,
    //lineHeight: "44px",
    textAlign: "center"
  },
  apply: {
    color: "white",
    backgroundColor: "#555B6E",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,0.1)",
    "&:hover": {
      backgroundColor: "#3D414F",
      boxShadow: "0 10px 10px 0 rgba(0,0,0,0.2)"
    }
  },
  disabled: {
    border: "2px solid rgba(0, 0, 0, 0.26)"
  },

  participating: {
    backgroundColor: "#F2F3F4",
    border: "2px solid #2A2B2C",
    color: "#2A2B2C"
  },
  placeholder: {
    backgroundColor: "#EDEDED",
    color: "#555B6E",
    margin: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  question: {
    marginTop: 15,
    backgroundColor: "#F2F3F4",
    border: "2px solid #2A2B2C",
    color: "#2A2B2C"
  },
  tagRoot: {
    marginRight: 10,
    marginTop: 5,
    borderRadius: 14
  },
  update: {
    backgroundColor: "darkorange"
  }
};

export default styles;
