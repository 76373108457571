import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "../common/SimpleDialog";
import TextInputField from "../Form/TextInputField";

import {
  getOutlineButtonClasses,
  getPrimaryButtonClasses
} from "../../styles/buttonStyles";

const declineStyles = getPrimaryButtonClasses("#DB6E53");
const outlineStyles = getOutlineButtonClasses();

const DeclineButton = withStyles(declineStyles)(Button);
const OutlineButton = withStyles(outlineStyles)(Button);

const styles = {
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10
  },
  textInputRoot: {
    marginTop: 0
  }
};

class DeclineApplicationsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.text
    };
  }

  render() {
    const { classes, closeDialog, onClose, onDecline, title } = this.props;

    return (
      <SimpleDialog title={title} onClose={onClose} width={520}>
        <TextInputField
          classes={{ root: classes.textInputRoot }}
          label="Customise the reason why the application was unsuccessful"
          rows={6}
          onChange={text => this.setState({ text })}
          value={this.state.text}
          width={520}
        />
        <div className={classes.buttons}>
          <OutlineButton onClick={closeDialog}>Cancel</OutlineButton>
          <DeclineButton
            disabled={!this.state.text}
            onClick={() => onDecline(this.state.text)}
          >
            Decline
          </DeclineButton>
        </div>
      </SimpleDialog>
    );
  }
}

DeclineApplicationsModal.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

DeclineApplicationsModal.defaultProps = {
  text:
    "We’re sorry your application has been unsuccessful to our program. Please try again.",
  title: "Decline Application"
};

export default withStyles(styles)(DeclineApplicationsModal);
