import React from "react";
import { PropTypes } from "prop-types";

import { fetchWithToken } from "../../../api/tokenApi";
import { responseJsonOrError } from "../../../api/response";

import { PrimaryButtonLarge } from "../../common/Buttons";

import TextInputField from "../../Form/TextInputField";
import { validateEmail } from "../../Form/validate";

const formFields = [
  {
    id: "current_email",
    label: "Current Email Address",
    type: "email",
    disabled: true,
    readOnly: true
  },
  {
    id: "email",
    label: "New Email Address",
    required: true,
    type: "email"
  },
  {
    id: "password",
    label: "Confirm your password",
    required: true,
    type: "password"
  }
];

class UpdateEmailForm extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    createFlashNote: PropTypes.func.isRequired,
    formFields: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired
  };

  static defaultProps = {
    formFields,
    url: "/api/users/account/email/"
  };

  constructor(props) {
    super(props);

    this.state = {
      current_email: props.profile.email,
      email: "",
      password: "",
      validationErrors: {}
    };
  }

  isComplete = () => {
    const { email, password } = this.state;
    const emailError = validateEmail(this.state.email);

    return email && !emailError && password;
  };

  onBlur = id => () => {
    const error = this.validateValue(id);

    if (error) {
      this.setValidationError(id, error);
    } else {
      this.removeValidationError(id);
    }
  };

  onChange = id => value => {
    // always remove password validation errors on pw change
    const error = id === "password" ? null : this.validateValue(id, value);

    if (!error) {
      this.removeValidationError(id);
    }

    this.setState({ [id]: value });
  };

  removeValidationError = id => {
    const validationErrors = { ...this.state.validationErrors };
    delete validationErrors[id];

    this.setState({ validationErrors });
  };

  setInitialState = () => {
    const { profile } = this.props;

    this.setState({
      current_email: profile.email,
      email: "",
      password: "",
      validationErrors: {}
    });
  };

  setValidationError = (id, error) => {
    const validationErrors = { ...this.state.validationErrors };
    validationErrors[id] = error;

    this.setState({ validationErrors });
  };

  updateEmail = () => {
    const data = {
      email: this.state.email,
      password: this.state.password
    };

    return fetchWithToken("/api/users/account/email/", {
      method: "PUT",
      body: JSON.stringify(data)
    })
      .then(response => responseJsonOrError(response))
      .then(json => {
        const { error, validationErrors } = json;

        if (error) {
          this.props.createFlashNote("Oops! Something went wrong.", "error");
          this.setState({ validationErrors });
        } else {
          this.props.createFlashNote(
            "Your password has been updated.",
            "success"
          );

          this.setInitialState();
        }
      })
      .catch(error => {
        console.error(error);
        this.props.createFlashNote("Oops! Something went wrong.", "error");
      });
  };

  validateValue = (id, _value) => {
    // always assume password is correct; let server do validation
    if (id === "password") return null;

    return validateEmail(_value || this.state[id]);
  };

  renderButtons = () => {
    const { classes } = this.props;
    const { validationErrors } = this.state;
    const disabled =
      !this.isComplete() || Boolean(Object.keys(validationErrors).length);

    return (
      <div className={classes.buttons}>
        <PrimaryButtonLarge disabled={disabled} onClick={this.updateEmail}>
          Update Email
        </PrimaryButtonLarge>
      </div>
    );
  };

  renderTextField = ({ id, label, ...options }) => {
    const value = this.state[id];
    const validationError = this.state.validationErrors[id];

    return (
      <TextInputField
        key={id}
        id={id}
        error={Boolean(validationError)}
        helperText={validationError}
        label={label}
        limit={80}
        limitErrorText={"Must be less than 80 characters."}
        onBlur={this.onBlur(id)}
        onChange={this.onChange(id)}
        type={"password"}
        value={value}
        width={440}
        {...options}
      />
    );
  };

  render() {
    const { formFields } = this.props;

    return (
      <div>
        {formFields.map(this.renderTextField)}
        {this.renderButtons()}
      </div>
    );
  }
}

export default UpdateEmailForm;
