import React from "react";
import { PropTypes } from "prop-types";
import { Redirect } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import Banner from "../common/Banner";
import TextInputField from "../Form/TextInputField";
import { validateEmail } from "../Form/validate";

import ResetPasswordDialog from "./ResetPasswordDialog";
import styles from "./styles";
import testAccounts from "./testAccounts";

export class SignInPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    redirect: PropTypes.string,
    resetLoginError: PropTypes.func.isRequired
  };

  static defaultProps = {
    redirect: "/dashboard"
  };

  emailTimeout = null;

  state = {
    disabled: true,
    email: "",
    emailError: null,
    modal: false,
    password: "",
    passwordError: null
  };

  componentWillUnmount() {
    this.emailTimeout && clearTimeout(this.emailTimeout);
  }

  handleKeyEvent = event => {
    // validate and submit if enter is pressed in confirm pw field
    const keyCode = event.which || event.keyCode;
    if (keyCode === 13) {
      const emailError = validateEmail(this.state.email);

      if (!emailError && this.state.password.length) {
        this.onSubmit();
      }
    }
  };

  clearError = () => {
    const { email, password } = this.state;

    const emailError = validateEmail(email);
    const disabled = emailError || !password.length;

    if (!emailError) {
      this.setState({ disabled, emailError: null });
    }
  };

  validateEmail = () => {
    const emailError = validateEmail(this.state.email);
    const disabled = Boolean(emailError) || this.state.disabled;

    this.setState({ disabled, emailError });
  };

  validatePassword = () => {
    return;
  };

  onSubmit = () => {
    this.props.login(this.state.email, this.state.password);
  };

  renderForm = () => {
    const { classes } = this.props;
    const { disabled, email, emailError, password, passwordError } = this.state;

    return (
      <div className={classes.formContainer}>
        <div className={classes.formTitle}>Sign in to Colinked</div>

        <TextInputField
          id="email"
          label="Email Address"
          error={Boolean(emailError)}
          helperText={emailError}
          limit={80}
          onBlur={this.validateEmail}
          onChange={value => this.setState({ email: value }, this.clearError)}
          type="text"
          value={email}
          width={330}
        />

        <TextInputField
          id="password"
          label="Password"
          error={Boolean(passwordError)}
          helperText={passwordError}
          limit={255}
          onBlur={this.validatePassword}
          onChange={value =>
            this.setState({ password: value }, this.clearError)
          }
          onKeyPress={this.handleKeyEvent}
          type="password"
          value={password}
          width={330}
        />

        <Button
          classes={{
            root: classes.button,
            raised: classes.buttonRaised,
            disabled: classes.disabled,
            label: classes.buttonLabel
          }}
          variant="raised"
          disabled={disabled}
          onClick={this.onSubmit}
        >
          Sign In
        </Button>

        {this.renderTestButtons()}
      </div>
    );
  };

  renderPasswordLink = () => {
    const { classes } = this.props;

    return (
      <span
        className={classes.passwordLink}
        onClick={() => this.setState({ modal: true })}
      >
        Help, I've forgotten my password!
      </span>
    );
  };

  renderTestButtons = () => {
    const { classes } = this.props;

    if (process.env.NODE_ENV !== "development") return null;

    return (
      <div className={classes.testButtons}>
        {testAccounts.map(account => (
          <Button
            key={account.label}
            color="primary"
            size="small"
            onClick={() => {
              this.setState(
                {
                  email: account.email,
                  password: account.password
                },
                this.onSubmit
              );
            }}
          >
            {account.label}
          </Button>
        ))}
        <Button
          size="small"
          onClick={() => {
            this.setState(
              {
                email: "corp@corp.com",
                password: "dealflowuserXX"
              },
              this.onSubmit
            );
          }}
        >
          fail
        </Button>
      </div>
    );
  };

  render() {
    const { classes, profile, redirect } = this.props;

    if (profile.isSignedIn) {
      return <Redirect to={redirect || "/dashboard"} />;
    }

    return (
      <div>
        <Banner title="" classes={{ root: classes.banner }} />
        <div className={classes.root}>
          {this.state.modal && (
            <ResetPasswordDialog
              onClose={() => {
                this.setState({ modal: null });
              }}
            />
          )}
          <div className={classes.centeredColumn}>
            {this.renderForm()}
            {this.renderPasswordLink()}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SignInPage);
