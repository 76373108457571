import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { buttonPrimaryClasses } from "../../../styles/buttonStyles";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import Icons from "../../Icons";

import { DISPLAY_LABELS } from "./config";

const styles = {
  ...buttonPrimaryClasses,
  root: {
    ...buttonPrimaryClasses.root,
    marginLeft: 0
  },
  iconClass: {
    fill: "white",
    marginRight: 5
  },
  menuItem: {
    fontSize: 14,
    letterSpacing: 0,
    minWidth: 110
  },
  paper: {
    // forced offset from menu button
    position: "relative",
    top: 10
  },
  popper: {
    zIndex: 1
  }
};

class AddCriteriaButton extends React.Component {
  state = {
    open: false
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleMenuClick = label => {
    this.setState({ open: false }, () => this.props.onClick(label));
  };

  render() {
    const {
      classes,
      Icon,
      label,
      children,
      onClick,
      options,
      ...other
    } = this.props;

    const { open } = this.state;

    const buttonClasses = {
      root: classes.root,
      disabled: classes.disabled,
      label: classes.label
    };

    const menuEntries = options.map(option => (
      <MenuItem
        key={option}
        onClick={() => this.handleMenuClick(option)}
        className={classes.menuItem}
      >
        {DISPLAY_LABELS[option] || option}
      </MenuItem>
    ));

    return (
      <div>
        <Button
          classes={buttonClasses}
          {...other}
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? "sub-menu-grow" : null}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          <Icon className={classes.iconClass} />
          {label || children}
          <Icons.ChevronDown
            className={classes.iconClass}
            style={{ marginLeft: 5, marginRight: 0 }}
          />
        </Button>

        <Popper
          className={classes.popper}
          open={open}
          anchorEl={this.anchorEl}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="sub-menu-grow"
              style={{
                transformOrigin: "right top"
              }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>{menuEntries}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

AddCriteriaButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  Icon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

AddCriteriaButton.defaultProps = {
  label: "Add Criteria",
  Icon: Icons.PlusCircleOutline
};

export { AddCriteriaButton, styles };
export default withStyles(styles)(AddCriteriaButton);
