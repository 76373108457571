import {
  CLEAR_VALIDATION_ERROR,
  CLEAR_VALIDATION_ERRORS,
  SET_VALIDATION_ERROR,
  SET_VALIDATION_ERRORS
} from "./validationErrorsActions";

export default (state = {}, action) => {
  switch (action.type) {
    case CLEAR_VALIDATION_ERROR:
      const newState = { ...state };
      delete newState[action.payload];

      return newState;

    case CLEAR_VALIDATION_ERRORS:
      return {};

    case SET_VALIDATION_ERROR:
      return { ...state, ...action.payload };

    case SET_VALIDATION_ERRORS:
      return { ...action.payload };

    default:
      return state;
  }
};
