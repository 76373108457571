import React from "react";
import { PropTypes } from "prop-types";

import CompaniesListView from "./CompaniesListView";

class InfiniteScrollView extends React.Component {
  static propTypes = {
    companies: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    loadNext: PropTypes.func
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  onScroll = () => {
    if (!this.props.loadNext) return;

    const footer = document.getElementById("footer");
    const offset = footer ? footer.offsetHeight : "480"; // regular footer + margin

    if (
      window.innerHeight + window.scrollY >=
        document.body.offsetHeight - offset &&
      this.props.companies.length &&
      !this.props.loading
    ) {
      this.props.loadNext();
    }
  };

  render() {
    return <CompaniesListView {...this.props} />;
  }
}

export default InfiniteScrollView;
