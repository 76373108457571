import React from "react";
import PropTypes from "prop-types";

import ColinkedLogo from "../../assets/images/colinked-logo-dark.svg";

const getLogoSize = width => {
  if (width === "lg" || width === "xl") {
    return { width: 170, height: 38 };
  } else {
    return { width: 125, height: 27 };
  }
};

const NavigationLogo = props => {
  const { history, style, width } = props;

  return (
    <img
      src={ColinkedLogo}
      alt="Colinked logo"
      onClick={() => history.push("/")}
      {...getLogoSize(width)}
      style={style}
    />
  );
};

NavigationLogo.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  style: PropTypes.object.isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

NavigationLogo.defaultProps = {
  style: { cursor: "pointer" }
};

export default NavigationLogo;
