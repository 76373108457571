import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";

import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

import BooleanField from "../Form/BooleanField";
import FormInputField from "../Form/FormInputField";
import FormSelectField from "../Form/FormSelectField";
import ErrorPanel from "../common/Errors/ErrorPanel";

import CloseIcon from "@material-ui/icons/Close";

import styles from "./dialogStyles";

const SUCCESS_MESSAGE = "The request was successful.";

class ActionDialog extends React.Component {
  constructor(props) {
    super(props);

    this.id = `id_${Math.random()}`;
    this.contentRef = React.createRef();

    const state = props.fields
      .filter(field => field.name)
      .reduce((state, field) => {
        state[field.name] = field.value || "";
        return state;
      }, {});

    this.state = {
      ...state,
      _height: props.height,
      status: null,
      statusText: null,
      open: true,
      error: null,
      validationErrors: {}
    };
  }

  componentDidMount() {
    this.setState({ __forceHeightUpdate: true });
  }

  componentDidUpdate() {
    const content = document.getElementById(this.id);
    const height = content && content.offsetHeight;

    if (height && height !== this.state._height) {
      this.setState({ _height: height });
    }
  }

  onSubmit = () => {
    const { fields, hiddenData, method, postURL } = this.props;

    const data = fields
      .filter(field => field.name)
      .map(field => field.name)
      .reduce((data, name) => {
        data[name] = this.state[name];
        return data;
      }, {});

    const options = {
      method,
      body: JSON.stringify({ ...hiddenData, ...data })
    };

    fetchWithToken(postURL, options)
      .then(response => responseJsonOrError(response))
      .then(response => {
        const { error, ...other } = response;
        if (error) {
          this.setState({ error, statusText: null, ...other });
        } else {
          this.setState(
            // fake 200 return code because it's not part of responseJsonOrError object
            { status: 200, statusText: SUCCESS_MESSAGE, ...other },
            () => {
              if (this.props.closeOnSuccess) {
                this.handleClose();
              }
            }
          );
        }
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  handleClose = () => {
    const { history, onClose, redirect } = this.props;

    this.setState({ open: false }, () => {
      if (onClose) onClose(this.state.status);
      if (redirect) history.push(redirect);
    });
  };

  setValue = (name, value) => {
    const validationErrors = Object.keys(this.state.validationErrors).reduce(
      (errors, key) => {
        if (key !== name) {
          errors[key] = this.state.validationErrors[key];
        }
        return errors;
      },
      {}
    );

    this.setState({
      [name]: value,
      status: null,
      error: null,
      validationErrors
    });
  };

  renderCancelButton = () => {
    const { classes } = this.props;

    return (
      <div className={classes.buttonCancel} onClick={this.handleClose}>
        <CloseIcon />
      </div>
    );
  };

  renderSubmitButton = () => {
    const { classes, buttonLabel } = this.props;
    const { error, status } = this.state;

    const empty = this.props.fields
      .filter(field => field.name)
      .filter(field => field.type && field.type !== "bool")
      .some(field => this.state[field.name] === "");

    const disabled = status || empty;

    return error ? null : (
      <Button
        variant="raised"
        disabled={Boolean(disabled)}
        classes={{
          root: classes.button,
          raised: classes.buttonRaised,
          disabled: classes.disabled,
          label: classes.buttonLabel
        }}
        onClick={this.onSubmit}
      >
        {buttonLabel}
      </Button>
    );
  };

  renderInputField = (spec, index) => {
    const { classes } = this.props;
    const { name, helperText, label, rows, type, ...other } = spec;
    const validationError = this.state.validationErrors[name];

    if (!name)
      return (
        <div key={index} className={classes.helperText}>
          {helperText}
        </div>
      );

    if (type && type === "bool") {
      return (
        <BooleanField
          key={name}
          id={name}
          label={label || name}
          value={Boolean(this.state[name])}
          onChange={value => this.setValue(name, value)}
        />
      );
    }

    if (type && type === "select") {
      return (
        <FormSelectField
          {...other}
          key={name}
          id={name}
          label={label || name}
          onChange={value => this.setValue(name, value)}
          value={this.state[name]}
        />
      );
    }

    return (
      <FormInputField
        key={name}
        id={name}
        label={label || name}
        value={this.state[name]}
        onChange={value => this.setValue(name, value)}
        width="100%"
        rows={rows || 1}
        classes={{ root: classes.formRoot }}
        helperText={validationError || helperText}
        error={Boolean(validationError)}
      />
    );
  };

  renderForm = () => {
    const { fields } = this.props;

    return (
      <React.Fragment>
        {fields.map((spec, index) => this.renderInputField(spec, index))}
      </React.Fragment>
    );
  };

  renderError = () => {
    const { error, validationErrors } = this.state;

    if (!error && !Object.keys(validationErrors).length) return null;

    const { classes } = this.props;

    return (
      <ErrorPanel
        classes={{
          root: classes.errorRoot,
          title: classes.errorTitle,
          message: classes.errorMessage
        }}
        error={error || new Error("Validation error")}
        validationErrors={this.state.validationErrors}
      />
    );
  };

  renderStatus = () => {
    const { statusText } = this.state;
    if (!statusText) return null;

    const { classes } = this.props;
    const className =
      statusText === SUCCESS_MESSAGE ? classes.successText : classes.statusText;

    return <div className={className}>{statusText}</div>;
  };

  render() {
    const { classes, title, width } = this.props;
    const height = this.state._height || this.props.height;

    const marginTop = `-${(height + 60) / 2}px`;
    const marginLeft = `-${(width + 60) / 2}px`;
    const containerHeight = height - 49; // title=19, title-margin=30

    return (
      <Modal open={this.state.open} onClose={this.handleClose}>
        <div
          className={classes.paper}
          style={{ height, width, marginTop, marginLeft }}
        >
          <div id={this.id} ref={this.contentRef}>
            <div className={classes.title}>{title}</div>
            <div
              className={classes.container}
              style={{ _height: containerHeight }}
            >
              {this.renderForm()}
              {this.renderStatus()}
              {this.renderError()}

              {this.renderSubmitButton()}
            </div>
          </div>
          {this.renderCancelButton()}
        </div>
      </Modal>
    );
  }
}

ActionDialog.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  closeOnSuccess: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      rows: PropTypes.number,
      value: PropTypes.any
    })
  ),
  hiddenData: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  postURL: PropTypes.string.isRequired,
  method: PropTypes.oneOf(["PUT", "POST", "DELETE"]).isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  redirect: PropTypes.string,
  onClose: PropTypes.func
};

ActionDialog.defaultProps = {
  buttonLabel: "Submit",
  closeOnSuccess: false,
  method: "POST",
  hiddenData: {},
  height: 350,
  width: 390
};

export default withRouter(withStyles(styles)(ActionDialog));
