const styles = {
  root: {
    width: "100%",
    overflowX: "auto"
  },
  companyLabel: {
    fontSize: 13
  },
  companyLogo: {
    marginRight: 10
  },
  documentDescription: {
    color: "#2A2B2C",
    fontSize: 13,
    lineHeight: "17px",
    maxHeight: 33.5,
    overflowY: "hidden"
  },
  documentName: {
    color: "#398DD3",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "19px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underLine"
    }
  },

  table: {
    minWidth: 900,
    overflowX: "auto"
  },
  referrerLabel: {
    fontSize: 13,
    fontWeight: 400
  },
  row: {
    maxHeight: 110,
    backgroundColor: "white",
    borderBottom: "1px solid #E6E7E9",
    "&:last-child": {
      borderBottom: "none"
    },
    "&:hover": {
      backgroundColor: "#F3F7FB"
    }
  },
  td: {},
  company: {
    //width: "280px"
  },
  logoImage: {
    width: 80,
    height: 80,
    borderRadius: 40,
    marginBottom: -8
  }
};

export default styles;
