import React from "react";
import { PropTypes } from "prop-types";

import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import IconEdit from "@material-ui/icons/Edit";

import LocalLink from "../../common/LocalLink";
import EligibilitySummary from "./EligibilitySummary";

import MemberCompany from "./MemberCompany";
import QuestionsSummary from "./QuestionsSummary";

const styles = {
  root: {},
  editLink: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  header: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    backgroundColor: "#AAADB6",
    color: "white",
    fontSize: 14,
    fontWeight: 600,

    height: 60,
    padding: "0 30px"
  },
  content: {
    fontSize: 14,
    padding: 30
  },
  icon: {
    marginRight: 5
  },
  link: {
    color: "#6592CC",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  memberCompanyRoot: {
    padding: 30
  }
};

const renderProfileLink = (company, classes) => {
  const { id, is_corporate } = company;
  const link = is_corporate ? `/corporate/${id}` : `/startup/${id}`;

  return (
    <div className={classes.content} style={{ paddingBottom: 0 }}>
      The following information from your company profile will be included with
      your application. To make changes or add missing information,{" "}
      <LocalLink
        className={classes.link}
        label="edit your company profile now"
        link={link}
      />{" "}
      before submitting.
    </div>
  );
};

const renderCompany = props => {
  const { application, classes } = props;
  const company = props.company.id ? props.company : application.company;
  const companyOptions = company.id
    ? {}
    : {
        openCompany: true,
        openFunding: true,
        openTeam: true
      };

  return (
    <React.Fragment>
      <div className={classes.header}>About your Company</div>
      {company.id ? renderProfileLink(company, classes) : null}
      <MemberCompany
        classes={{ root: classes.memberCompanyRoot }}
        company={company}
        {...companyOptions}
      />
    </React.Fragment>
  );
};

const renderEligibility = props => {
  const { application, classes, setActivePage } = props;

  if (!application.criteria || application.criteria.length === 0) return null;

  return (
    <div>
      <div className={classes.header}>
        <div>Eligibility</div>
        <div className={classes.editLink} onClick={() => setActivePage(0)}>
          <IconEdit className={classes.icon} /> Edit
        </div>
      </div>
      <EligibilitySummary criteria={application.criteria} />
    </div>
  );
};

const renderQuestions = props => {
  const { application, classes, setActivePage } = props;

  if (!application.questions || application.questions.length === 0) return null;

  return (
    <div>
      <div className={classes.header}>
        <div>Application Questions</div>
        <div className={classes.editLink} onClick={() => setActivePage(1)}>
          <IconEdit className={classes.icon} /> Edit
        </div>
      </div>
      <QuestionsSummary questions={application.questions} />
    </div>
  );
};

const ApplicationReview = props => {
  return (
    <div>
      {renderEligibility(props)}
      {renderQuestions(props)}
      {renderCompany(props)}
    </div>
  );
};

ApplicationReview.propTypes = {
  application: PropTypes.shape({
    application: PropTypes.object.isRequired,
    company: PropTypes.object, // company object for guests
    criteria: PropTypes.array.isRequired,
    questions: PropTypes.array.isRequired
  }).isRequired,
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired // company object for members
};

ApplicationReview.defaultProps = {
  company: {}
};

const mapStateToProps = state => ({ company: state.company });

export default connect(mapStateToProps)(withStyles(styles)(ApplicationReview));
