export const getCountryOptions = () => {
  let _countries_cache;
  let _options_cache;

  return countries => {
    if (countries !== _countries_cache) {
      _countries_cache = countries;
      _options_cache = countries.map(country => ({
        value: country.id,
        label: country.label
      }));
    }

    return _options_cache;
  };
};
