import { UPDATE_CONFIG } from "./configActions";

export const defaultState = {
  referrals__max_corp_referrals: 20,
  programs__program_types: {
    accelerator: "Accelerator programme",
    challenge: "Business challenge",
    competition: "Competition",
    hackathon: "Hackathon",
    incubator: "Incubator",
    mentorship: "Mentorship",
    proposal: "Request for Proposal (RFP)",
    // open_inno: "Open Innovation Platform",
    other: "Other"
    // phone: "Conference Call",
    // physical: "Location",
    // video: "Video Conference Call"
  },
  company_capital_range: {
    "0-500000": "0 - 500k",
    "500000-1000000": "500k - 1M",
    "1000000-2000000": "1M - 2M",
    "2000000-3000000": "2M - 3M",
    "3000000-5000000": "3M - 5M",
    "5000000-10000000": "5M - 10M",
    "10000000-20000000": "10M - 20M",
    "20000000-30000000": "20M - 30M",
    "30000000+": "30M+"
  },
  company_stage: {
    seed: "Seed",
    "series-a": "Series A",
    "series-b": "Series B",
    "series-c+": "Series C+"
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_CONFIG:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
