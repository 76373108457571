import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import CompanyPanel from "../common/CompanyPanel";

import ApproveDeclineDialog from "./ApproveDeclineDialog";
import ApplicationDetailsButton from "./ApplicationDetailsButton";
import ApplicationStatusGroup from "./ApplicationStatusGroup";

const cellStyle = {
  flexGrow: 0,
  flexShrink: 0,
  padding: 8
};

const statusStyle = {
  ...cellStyle,
  flexGrow: 0,
  width: 200,
  display: "flex",
  marginLeft: 24,
  alignItems: "center"
};

const statusLabel = {
  lineHeight: "1em",
  paddingTop: 4,
  paddingBottom: 4
};

const styles = {
  root: {
    display: "flex",
    padding: 8,
    alignItems: "center"
  },
  row: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F2F3F4"
    }
  },
  cell: {
    ...cellStyle
  },
  companyRoot: {
    flexShrink: 0,
    flexGrow: 0,
    width: 350,
    overflow: "hidden"
  },
  companyRootFlexGrow: {
    width: 280,
    flexGrow: 1,
    marginLeft: 8
  },
  companyType: {
    ...cellStyle,
    width: 100,
    flexGrow: 0,
    flexShrink: 0
  },
  application_note: {
    lineHeight: "17px",
    flexGrow: 1,
    flexShrink: 1,
    padding: 8,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 20,
    height: 51,
    overflow: "hidden"
  },
  status: {
    ...statusStyle,
    flexGrow: 0,
    flexShrink: 0
  },
  statusXS: {
    ...statusStyle,
    marginLeft: 0,
    marginTop: 8,
    marginBottom: 8,
    borderTop: "1px solid lightgrey",
    borderBottom: "1px solid lightgrey",
    paddingTop: 0,
    paddingBottom: 0,
    width: "calc(100%-16px)"
  },
  statusLabels: {
    flexGrow: 1
  },
  approved: {
    ...statusLabel,
    color: "green"
  },
  declined: {
    ...statusLabel,
    color: "darkred"
  },
  date: {
    ...statusLabel
  },
  actions: {
    ...statusStyle,
    alignItems: "center",
    justifyContent: "space-between"
  },

  buttonRemove: {
    flexGrow: 0,
    width: 36,
    minWidth: 36,
    height: 48,
    paddingLeft: 0,
    paddingRight: 0,
    color: "rgba(0,0,0,0.54)"
  }
};

//TODO: get default approve/decline messages
const getDialogMessage = (document, label) => {
  const docName = document.name || document.title;

  if (label.toLowerCase() === "approve") {
    return `Congratulations!\n
You have been approved to ${docName}.\n
We are happy to have you on board.`;
  }

  if (label.toLowerCase() === "decline") {
    return `We are sorry to inform you that your application to ${docName} has been declined.`;
  }

  return "Please add a message";
};

class ApplicationsPanel extends React.Component {
  state = {
    modal: null
  };

  getApplicationUrl = () => {
    const { application, doctype, document } = this.props;

    return doctype === "program"
      ? `/api/programs/${document.id}/participants/${application.id}/`
      : `/api/think_tanks/${document.id}/participants/${application.id}/`;
  };

  onApproveDecline = label => () => {
    this.setState({ modal: label });
  };

  renderApproveDeclineDialog = () => {
    const { application, document } = this.props;
    const { company } = application;

    const label = this.state.modal;
    if (!label) return null;

    const companyName = company.name || "unknown";

    return (
      <ApproveDeclineDialog
        action={label.toLowerCase()}
        applicationUrl={this.getApplicationUrl()}
        companyName={companyName}
        message={getDialogMessage(document, label)}
        onClose={() => {
          this.setState({ modal: null });
        }}
        title={`${label} application from ${companyName}`}
      />
    );
  };

  renderButton = label => {
    const { classes } = this.props;

    const className = classes[`button${label}`];

    return (
      <Button
        variant="raised"
        className={className}
        onClick={this.onApproveDecline(label)}
      >
        {label}
      </Button>
    );
  };

  render() {
    const { classes, application, width } = this.props;

    const company = application.company || { name: "undefined" };

    const detailsButton = (
      <ApplicationDetailsButton
        application={application}
        onApprove={this.onApproveDecline("Approve")}
        onDecline={this.onApproveDecline("Decline")}
      />
    );

    if (width === "xs") {
      return (
        <div className={classes.root} style={{ display: "block" }}>
          <CompanyPanel
            company={company}
            classes={{ root: classes.companyRootFlexGrow }}
            style={{ flexGrow: 1, width: "100%" }}
            title={detailsButton}
          />

          <ApplicationStatusGroup
            application={application}
            onApprove={this.onApproveDecline("Approve")}
            onDecline={this.onApproveDecline("Decline")}
          />

          <Typography variant="body1" className={classes.application_note}>
            {application.application_note}
          </Typography>
        </div>
      );
    }

    // small layout: company, type and status in row 1, application_note in row 2
    if (width === "sm") {
      return (
        <div>
          <div className={classes.root}>
            <CompanyPanel
              title={detailsButton}
              company={company}
              classes={{ root: classes.companyRootFlexGrow }}
            />

            <ApplicationStatusGroup
              application={application}
              onApprove={this.onApproveDecline("Approve")}
              onDecline={this.onApproveDecline("Decline")}
            />
          </div>
          <div className={classes.cell} style={{ paddingTop: 0 }}>
            <Typography variant="body1">
              {application.application_note}
            </Typography>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        {this.state.modal && this.renderApproveDeclineDialog()}
        <CompanyPanel
          title={detailsButton}
          company={company}
          classes={{ root: classes.companyRoot }}
        />
        <Typography variant="body1" className={classes.application_note}>
          {application.application_note}
        </Typography>
        {/*this.renderStatus() */}
        <ApplicationStatusGroup
          application={application}
          onApprove={this.onApproveDecline("Approve")}
          onDecline={this.onApproveDecline("Decline")}
        />
      </div>
    );
  }
}

ApplicationsPanel.propTypes = {
  application: PropTypes.object.isRequired,
  classes: PropTypes.object,
  document: PropTypes.object.isRequired,
  doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
  width: PropTypes.string.isRequired
};

export default withStyles(styles)(ApplicationsPanel);
