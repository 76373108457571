import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FormSelectField from "./FormSelectField";

import { getCountryOptions } from "./utils";

const getCountryOptionsCached = getCountryOptions();

const CountriesSelector = props => {
  if (!props.options.length) return null;

  const { dispatch, onChange, options, value, ...otherProps } = props;

  const _countries = value
    .map(id => options.find(option => option.value === id))
    .filter(obj => obj);

  return (
    <FormSelectField
      options={options}
      {...otherProps}
      value={_countries}
      isMulti={true}
      onChange={selection => {
        onChange(selection.map(c => c.value));
      }}
    />
  );
};

CountriesSelector.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array
};

CountriesSelector.defaultProps = {
  label: "Please select your countries",
  value: []
};

const mapStateToProps = state => {
  return {
    options: getCountryOptionsCached(state.countries)
  };
};

export default connect(mapStateToProps)(CountriesSelector);
