import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import Icons from "../Icons";

import FormInputField from "./FormInputField";
import ImageUploadField from "./ImageUploadField";

const styles = theme => ({
  root: {
    marginTop: 24,
    paddingTop: 12,
    borderTop: "1px solid lightgrey",
    "&:nth-of-type(1)": {
      marginTop: 0,
      paddingTop: 0,
      borderTop: "none"
    }
  },
  buttonRoot: {
    height: 36,
    flexGrow: 0
  },
  buttonLabel: {
    //color: theme.palette.text.secondary
    color: "darkred"
  },
  buttonIcon: {
    marginRight: 4
  },
  controls: {
    display: "flex",
    justifyContent: "space-between",
    //outline: "1px solid red",
    alignItems: "center"
    //marginBottom: 10
  },
  founderName: {
    fontWeight: "bolder",
    fontSize: 16
  },
  avatar_url: {
    width: 120,
    marginRight: 0,
    flexGrow: 0
  },
  row: {
    display: "flex"
  },
  column: {
    flexGrow: 1
  }
});

const capitalize = words => {
  switch (words) {
    case "first_name":
      return "First name";

    case "last_name":
      return "Last name";

    case "social_linkedin":
      return "LinkedIn";

    case "social_twitter":
      return "Twitter";

    default:
      return words
        .split()
        .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
        .join(" ");
  }
};

class FounderForm extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    founder: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { ...props.founder };
  }

  onChange = (key, value) => {
    this.setState({ [key]: value }, () => this.props.onChange(this.state));
  };

  renderAvatarInput = () => {
    const { index } = this.props;
    const { avatar_url } = this.props.founder;

    return (
      <ImageUploadField
        id={`founder_${index}_avatar`}
        width={120}
        height={120}
        label="Profile image"
        onChange={value => this.onChange("avatar_url", value)}
        value={avatar_url}
        InputLabelProps={{
          shrink: true
        }}
        style={{ flexGrow: 1, marginBottom: 0, marginTop: 10 }}
      />
    );
  };

  renderTextField = (key, index, rows = 1) => {
    const value = this.state[key] || "";

    return (
      <FormInputField
        id={`founder_${index}_${key}`}
        label={capitalize(key)}
        value={value}
        onChange={value => this.onChange(key, value)}
        rows={rows}
        width="100%"
        style={{ flexGrow: 1, marginBottom: 0, marginTop: 10, marginLeft: 20 }}
      />
    );
  };

  render() {
    const { classes, index, founder, onRemove } = this.props;
    const { first_name, last_name } = founder;

    const avatarInput = this.renderAvatarInput();
    const firstNameInput = this.renderTextField("first_name", index);
    const lastNameInput = this.renderTextField("last_name", index);
    const emailInput = this.renderTextField("email", index);
    const positionInput = this.renderTextField("position", index);
    const bioInput = this.renderTextField("bio", index, 4);
    const linkedinInput = this.renderTextField("social_linkedin", index);
    const twitterInput = this.renderTextField("social_twitter", index);

    const removeFounderButton = (
      <Button
        size="small"
        onClick={onRemove}
        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
      >
        <Icons.CloseCircle className={classes.buttonIcon} />Remove Founder
      </Button>
    );

    return (
      <div className={classes.root}>
        <div className={classes.controls}>
          <span className={classes.founderName}>
            {first_name} {last_name}
          </span>
          {removeFounderButton}
        </div>
        <div className={classes.row}>
          <div className={classes.avatar}>{avatarInput}</div>
          <div className={classes.column}>
            <div className={classes.row}>
              {firstNameInput}
              {lastNameInput}
            </div>
            <div className={classes.row}>
              {emailInput}
              {positionInput}
            </div>
          </div>
        </div>

        <div className={classes.row} style={{ marginLeft: -20 }}>
          {bioInput}
        </div>
        <div className={classes.row} style={{ marginLeft: -20 }}>
          {linkedinInput}
          {twitterInput}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FounderForm);
