import React from "react";

import ChallengeDetailsViewContainer from "../components/Challenges/ChallengeDetailsViewContainer";

import PageTemplate from "./PageTemplate";

const Challenge = props => {
  return (
    <PageTemplate>
      <ChallengeDetailsViewContainer {...props} />
    </PageTemplate>
  );
};

export default Challenge;
