import React from "react";
import { PropTypes } from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import SaveDraftButton from "../common/Buttons/SaveDraftButton";

import Icons from "../Icons";

const styles = {
  root: {
    backgroundColor: "white",
    height: 80,
    width: "100vw"
  },
  backlink: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    marginLeft: 10,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  backlinkIcon: {
    fontSize: 20,
    position: "relative",
    top: "4.5px"
  },
  container: {
    display: "flex",
    alignItems: "center",

    height: 80,
    width: 800,
    margin: "0 auto"
  }
};

const getDiscardLable = props => {
  const { document, handleDiscardLabel } = props;
  if (handleDiscardLabel) return handleDiscardLabel;

  if (document.doctype === "program") {
    return "Back to Program";
  } else if (document.doctype === "Challenge") {
    return "Back to Think Tank";
  } else {
    return "Back";
  }
};

const renderBacklink = props => {
  const { classes, handleDiscard } = props;
  const handleDiscardLabel = getDiscardLable(props);

  if (!handleDiscard) return null;

  return (
    <div>
      <Icons.ArrowLeft className={classes.backlinkIcon} />
      <span className={classes.backlink} onClick={handleDiscard}>
        {handleDiscardLabel}
      </span>
    </div>
  );
};

const ApplicationFormMenu = props => {
  const {
    classes,
    document,
    handleSave,
    handleSaveLabel,
    handleSaveTooltip,
    id
  } = props;

  const disabled = Boolean(!document._hasUnsavedChanges);
  const buttonPlain = (
    <SaveDraftButton
      disabled={disabled}
      label={handleSaveLabel}
      onClick={() => handleSave(document)}
    />
  );

  const button =
    handleSaveTooltip && !disabled ? (
      <Tooltip title={handleSaveTooltip}>{buttonPlain}</Tooltip>
    ) : (
      buttonPlain
    );

  return (
    <div className={classes.root} id={id}>
      <div className={classes.container}>
        {renderBacklink(props)}

        <div style={{ flexGrow: 1 }} />
        {button}
      </div>
    </div>
  );
};

ApplicationFormMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.shape({
    id: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
    _hasUnsavedChanges: PropTypes.bool
  }).isRequired,
  handleDiscard: PropTypes.func,
  handleDiscardLabel: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  handleSaveLabel: PropTypes.string,
  handleSaveTooltip: PropTypes.string,
  hasUnsavedChanges: PropTypes.bool,
  id: PropTypes.string.isRequired
};

ApplicationFormMenu.defaultProps = {
  handleDiscardLabel: "",
  handleSaveLabel: "Save for later",
  handleSaveTooltip:
    "If you save a draft you can complete your application later on this computer.",
  id: "applicationform-menu"
};

export { ApplicationFormMenu, styles };
export default withStyles(styles)(ApplicationFormMenu);
