import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ApplicationDetailsDialog from "./ApplicationDetailsDialog";

const styles = theme => ({
  root: {
    //lineHeight: "24px",
    whiteSpace: "nowrap"
  },
  label: {
    fontSize: "15px",
    lineHeight: "18px",
    //marginTop: 24
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "underline"
  }
});

class ApplicationDetailsButton extends React.Component {
  state = {
    modal: false
  };

  renderCompanyDetailsDialog = () => {
    const { application, onApprove, onDecline } = this.props;
    const { company } = application;

    // adding random key to force redraw on repeated click on button
    return (
      <ApplicationDetailsDialog
        key={`${Math.random()}`}
        application={application}
        onClose={() => {
          this.setState({ modal: false });
        }}
        onApprove={() => {
          this.setState({ modal: false }, () => onApprove());
        }}
        onDecline={() => {
          this.setState({ modal: false }, () => onDecline());
        }}
        title={`Details for '${company.name}'`}
      />
    );
  };

  render() {
    const { classes, application } = this.props;
    const { company } = application;

    return (
      <div className={classes.root}>
        {this.state.modal && this.renderCompanyDetailsDialog()}
        <div
          className={classes.label}
          onClick={() => this.setState({ modal: true })}
        >
          {company.name || "undefined"}
        </div>
      </div>
    );
  }
}

ApplicationDetailsButton.propTypes = {
  application: PropTypes.object.isRequired,
  onApprove: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired
};

export default withStyles(styles)(ApplicationDetailsButton);
