import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { responseJsonOrError } from "../../../api/response";
import { fetchWithToken } from "../../../api/tokenApi";

import { createFlashNote } from "../../../Reducers/FlashNotes";
import {
  clearValidationError,
  setValidationError,
  setValidationErrors
} from "../../../Reducers/ValidationErrors";
import { updateCompanyData } from "../../../Reducers/Company/companyActionTypes";

import Banner from "../../common/Banner";
import InvestReady from "../../common/InvestReady";
import FormBuilder from "../../FormBuilder/FormBuilderContainer";

import pagesCorporate from "./profileEditorSectionsCorporate";
import pagesFounder from "./profileEditorSectionsFounder";
import ProfileView from "../ProfileView";

const getBreadcrumbs = document => {
  const { is_corporate, is_founder, id, name, preview } = document;

  const breadcrumbs = is_corporate
    ? [{ name: "Corporates", link: "/corporations" }]
    : is_founder
    ? [{ name: "Founder Teams", link: "/startups" }]
    : [];

  // return just the list page breadcrumb in preview mode
  if (preview) return breadcrumbs;

  is_corporate && breadcrumbs.push({ name, link: `/corporate/${id}` });
  is_founder && breadcrumbs.push({ name, link: `/startup/${id}` });

  return breadcrumbs;
};

const CompanyProfileBanner = props => {
  const { document } = props;
  const { name, preview, logo_image_url } = document;

  const breadcrumbs = getBreadcrumbs(document);
  const title = preview ? name : "Manage Company Profile";

  return (
    <Banner
      title={title}
      breadcrumbs={breadcrumbs}
      logo={preview ? logo_image_url : null}
    />
  );
};

const updateCompany = dispatch => data => {
  const { id, doctype, ...companyData } = data;

  // temp fix for incorrect existing data
  const _data = Object.keys(companyData).reduce((_data, key) => {
    if (key !== "size") {
      _data[key] = companyData[key];
    } else {
      _data[key] = companyData[key] === "10,001+" ? "10001" : companyData[key];
    }
    return _data;
  }, {});

  const url = companyData.is_corporate
    ? "/api/companies/corporations/"
    : "/api/companies/founders/";

  return fetchWithToken(`${url}/${id}/`, {
    body: JSON.stringify(_data),
    cache: "no-cache",
    credentials: "same-origin",
    method: "PUT"
  })
    .then(responseJsonOrError)
    .then(result => {
      if (!result.error) {
        dispatch(updateCompanyData(data));
      }

      return result;
    });
};

const ProfilePreview = props => {
  return <ProfileView {...props} company={props.document} />;
};

const mapStateToProps = state => ({
  BannerComponent: CompanyProfileBanner,
  AdvertisingComponent: state.company.is_founder ? InvestReady : null,
  PreviewComponent: ProfilePreview,
  documentProp: "company",
  exitRedirect: "/dashboard",
  hidePageButtons: true,
  hideStepperIcons: true,
  pages: state.profile.membership === "founder" ? pagesFounder : pagesCorporate,
  profile: state.profile,
  company: state.company,
  createNewDocument: () => {},
  // add 'doctype' and 'is_approved' to fool FormBuilder
  document: state.company,
  validationErrors: state.validationErrors
});

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    },
    clearValidationError: id => dispatch(clearValidationError(id)),
    setValidationError: (id, error) => dispatch(setValidationError(id, error)),
    setValidationErrors: errors => dispatch(setValidationErrors(errors)),
    updateDocument: updateCompany(dispatch)
  };
};

const mergeProps = (mapStateToProps, mapDispatchToProps) => {
  return {
    ...mapStateToProps,
    ...mapDispatchToProps,
    loadDocument: () => Promise.resolve(mapStateToProps.document)
  };
};

const CompanyProfileEditor = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withRouter(FormBuilder));

export default CompanyProfileEditor;
