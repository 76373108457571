const benefitStyle = {
  height: 50,
  border: "1px solid #E6E7E9",
  borderRadius: 3,
  marginTop: 5,
  display: "flex",
  alignItems: "center",
  fontSize: 14,
  fontWeight: 300,
  color: "#2A2B2C"
};

const inputStyle = {
  height: 36,
  lineHeight: "36px",
  fontSize: 14,
  fontWeight: 300,
  color: "#2A2B2C",
  //color: "rgba(42,43,44,0.7)",
  paddingLeft: 12,
  paddingRight: 12,
  backgroundColor: "white",
  border: "1px solid #E6E7E9",
  borderRadius: 3,
  marginLeft: 5
};

const styles = {
  root: {
    marginTop: 20,
    paddingLeft: 20
  },
  addOther: {
    marginLeft: 58,
    marginTop: 10,
    fontWeight: 600,
    color: "#6592CC",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  addOtherIcon: {
    fill: "#6592CC",
    marginRight: 5,
    fontSize: 20
  },
  removeOtherIcon: {
    fill: "#6592CC",
    marginLeft: 5,
    cursor: "pointer",
    "&:hover": {
      fill: "red"
    }
  },
  amountRoot: {
    ...benefitStyle,
    display: "block",
    height: "auto",
    paddingBottom: 15
  },
  benefit: {
    ...benefitStyle
  },
  checkbox: {
    marginLeft: 5,
    marginRight: 5
  },
  equityRoot: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    marginTop: 20
  },
  equityDecorator: {
    display: "inline-block",
    width: 30,
    position: "relative",
    left: -25
  },
  equityInput: {
    ...inputStyle,
    marginLeft: 0,
    marginTop: 5,
    backgroundColor: "#EDEDEF",
    width: 110,
    textAlign: "right"
  },
  input: {
    ...inputStyle,
    "&:first-child": {
      marginLeft: 58
    }
  },
  otherInput: {
    ...inputStyle,
    marginLeft: 58,
    marginBottom: 5,
    width: 414
  }
};

export { benefitStyle, inputStyle };
export default styles;
