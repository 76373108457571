import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import LocalLink from "./LocalLink";
import LogoImage from "./LogoImage";
import placeholderLogoImage from "../../assets/corp_logo_placeholder.png";

const styles = {
  root: {
    display: "flex",
    alignItems: "center"
  },
  container: {
    height: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  label: {
    fontSize: 15,
    lineHeight: "17px",
    whiteSpace: "nowrap"
  },
  logo: {
    marginRight: 15
  },
  name: {
    color: "#398DD3",
    fontWeight: 700,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  },
  nolink: {
    "&:hover": {
      textDecoration: "none",
      cursor: "default"
    }
  },
  unknown: {
    fontWeight: 300,
    fontStyle: "italic"
  },
  subtitle: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "17px",
    marginTop: 5,
    maxHeight: 32,
    overflow: "hidden"
  }
};

const getCompanyLink = (company, classes) => {
  const name = company.name || "unknown";
  let link = null;

  if (company.is_guest) {
    link = null;
  } else if (company.is_corporate && company.id) {
    link = `/corporate/${company.id}`;
  } else if (company.is_founder && company.id) {
    link = `/startup/${company.id}`;
  }

  if (link) {
    return <LocalLink className={classes.name} label={name} link={link} />;
  } else if (company.website) {
    return (
      <a
        className={classes.name}
        href={company.website}
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
      </a>
    );
  } else {
    return <span className={`${classes.name} ${classes.nolink}`}>{name}</span>;
  }
};

const renderLogo = props => {
  const { classes, company, size } = props;
  const logo = company.logo_image_url;

  return (
    <LogoImage
      classes={{ root: classes.logo }}
      size={size}
      radius={size / 2}
      margin={0}
      url={logo || placeholderLogoImage}
    />
  );
};

const CompanyPanel = props => {
  const { classes, company, link, prefix, size, subtitle, suffix } = props;

  const name = company.name || "unknown";
  const _link = link ? (
    <LocalLink className={classes.name} label={name} link={link} />
  ) : (
    getCompanyLink(company, classes)
  );
  const style = {}; // subtitle ? { marginTop: 18 } : {};
  const logo = renderLogo(props);

  const title = props.title ? (
    <div className={classes.label} style={style}>
      {props.title}
    </div>
  ) : (
    <div className={classes.label} style={style}>
      {prefix}
      {_link}
      {suffix}
    </div>
  );

  return (
    <div className={classes.root}>
      {logo}
      <div className={classes.container} style={{ height: size }}>
        {title}
        {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      </div>
    </div>
  );
};

CompanyPanel.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo_image_url: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  radius: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired
};

CompanyPanel.defaultProps = {
  prefix: "",
  suffix: "",
  subtitle: "",
  radius: 0,
  size: 60
};

export default withStyles(styles)(CompanyPanel);
