import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ErrorPanelSmall from "../../common/Errors/ErrorPanelSmall";
import BaseFrame from "./BaseFrame";
import CreateFrame from "./CreateFrame";
import FileUpload from "./FileUpload";
import Heading from "./Heading";
import Image from "./Image";
import RichTextFrame from "./RichTextFrame";
import VideoFrame from "./VideoFrame";

const styles = {
  root: {
    padding: 30,
    paddingTop: 20
    // boxShadow: "inset 0px 0px 10px 0 rgba(0,0,0,0.1)"
  }
};

class ProfileBuilder extends React.Component {
  static propTypes = {
    state: PropTypes.object.isRequired,
    sections: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    sections: []
  };

  constructor(props) {
    super(props);

    // show empty header section for new programs
    const _sections = props.sections.length
      ? props.sections
      : [{ type: "heading" }];

    // add key attribute to object to help with tracking of frames
    this.state = {
      sections: _sections.map(obj => ({
        ...obj,
        key: `key_${Math.random()}`
      }))
    };
  }

  addFrame = type => {
    const sections = [
      ...this.state.sections,
      { type, key: `key_${Math.random()}` }
    ];

    this.setState({ sections });
  };

  deleteFrame = index => {
    const { sections } = this.state;

    const before = sections.slice(0, index);
    const after = sections.slice(index + 1);
    const _sections = [...before, ...after];

    this.setState({ sections: _sections }, this.updateProgram);
  };

  getDeleteFrameFrame = (section, index, sections) => {
    // return null for the only header and text frame in the profile
    // otherwise return deleteFrame function for frame index
    if (section.type === "heading" || section.type === "richtext") {
      if (sections.filter(s => s.type === section.type).length === 1)
        return null;
    }

    return () => this.deleteFrame(index);
  };

  moveDown = index => {
    const { sections } = this.state;

    if (index === sections.length - 1) return;

    const before = sections.slice(0, index);
    const after = sections.slice(index + 2);
    const _sections = [
      ...before,
      sections[index + 1],
      sections[index],
      ...after
    ];

    this.setState({ sections: _sections }, this.updateProgram);
  };

  moveUp = index => {
    const { sections } = this.state;

    if (index === 0) return;

    const before = sections.slice(0, index - 1);
    const after = sections.slice(index + 1);

    const _sections = [
      ...before,
      sections[index],
      sections[index - 1],
      ...after
    ];

    this.setState({ sections: _sections }, this.updateProgram);
  };

  onChange = index => content => {
    const { sections } = this.state;

    const before = sections.slice(0, index);
    const after = sections.slice(index + 1);
    const section = { ...sections[index], ...content };
    const _sections = [...before, section, ...after];

    this.setState({ sections: _sections }, this.updateProgram);
  };

  updateProgram = () => {
    const sections = this.state.sections.map(obj => {
      const { key, ...rest } = obj;

      return rest;
    });

    this.props.onChange(sections);
  };

  renderFrame = (section, index, sections) => {
    const moveDown =
      index !== sections.length - 1 ? () => this.moveDown(index) : null;
    const moveUp = index !== 0 ? () => this.moveUp(index) : null;

    const props = {
      ...section,
      deleteFrame: this.getDeleteFrameFrame(section, index, sections),
      moveDown,
      moveUp,
      onChange: this.onChange(index)
    };

    if (section.type === "file") {
      return <FileUpload {...props} />;
    } else if (section.type === "heading") {
      return <Heading {...props} />;
    } else if (section.type === "image") {
      return <Image {...props} />;
    } else if (section.type === "richtext") {
      return <RichTextFrame {...props} />;
    } else if (section.type === "video") {
      return <VideoFrame {...props} />;
    }

    return <BaseFrame {...props} />;
  };

  render() {
    const { classes, error, helperText } = this.props;
    const { sections } = this.state;

    return (
      <div className={classes.root}>
        {error && <ErrorPanelSmall error={{ message: helperText }} />}
        {sections.map(this.renderFrame)}
        <CreateFrame frames={sections.length} addFrame={this.addFrame} />
      </div>
    );
  }
}

export { ProfileBuilder, styles };
export default withStyles(styles)(ProfileBuilder);
