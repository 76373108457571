import React from "react";

import ClearIcon from "@material-ui/icons/Clear";

const ErrorPanelSmall = props => {
  const { error, onClear } = props;

  return (
    <div className="errorPanel">
      <div>{error.message}</div>
      {onClear && <ClearIcon className="errorPanelIcon" onClick={onClear} />}
    </div>
  );
};

export default ErrorPanelSmall;
