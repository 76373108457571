import React from "react";
import { PropTypes } from "prop-types";

import { throttle } from "underscore";

import { withStyles } from "@material-ui/core/styles";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxBlank from "@material-ui/icons/CheckBoxOutlineBlank";

import TextField from "../TextInputField";

import AddQuestionButton from "./AddQuestion";
import Controls from "./Controls";
import QuestionOptions from "./QuestionOptions";
import TypeSelector from "./TypeSelector";

import styles from "./styles";

// type => "text_input", "choice_input", "file_upload"
// label (string)

// -> text_input
// content (string)
// rows: 4
// maxLength: 4096

// -> choice_input
// content (array of strings, selection)
// options (array of strings)
// multiple (boolean)

// -> file_upload
// content (string, url of uploaded file)
// fileTypes: ["application/pdf"]
// maxSize: 50 (max size in MB)

const propsQuestionsToState = questions => {
  return questions.sort((a, b) => a.order - b.order).map((question, index) => ({
    is_optional: false,
    key: question.id || `key_${Math.random()}`,
    options: null,
    ...question,

    // overwrite original order with index in list
    order: index
  }));
};

const stateQuestionsToProps = questions => {
  // filter out empty option fields and key attribute
  const clean = questions
    .filter(question => question.label && question.type)
    .map(question => {
      const { options, ...nonOpts } = question;

      return options
        ? { ...nonOpts, options: options.filter(o => o) }
        : nonOpts;
    })
    .filter(question => !question.options || question.options.length)
    .map(question => {
      const { key, ...other } = question;

      // add { options: null } when no options list is given
      return { options: null, ...other };
    });

  // reset order to index in list
  return clean.map((q, index) => ({ ...q, order: index }));
};

class QuestionsBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: propsQuestionsToState(props.questions)
    };

    this.updateState = throttle(this._updateState, 400).bind(this);
  }

  addQuestion = (type = "", label = "", key) => {
    const { questions } = this.state;

    const selectOptions = type.endsWith("choice_input")
      ? { options: [""] }
      : {};

    const question = {
      is_optional: false,
      key: key || `key_${Math.random()}`,
      order: questions.length,
      type,
      label,
      ...selectOptions
    };

    this.setState({ questions: [...questions, question] });
  };

  updateLabel = index => label => {
    const q = this.state.questions[index] || {};

    this.updateQuestion({ ...q, label }, index);
  };

  updateOptions = index => options => {
    const q = this.state.questions[index] || {};

    this.updateQuestion({ ...q, options }, index);
  };

  updateQuestion = (question, index) => {
    const questions = this.state.questions.map((q, idx) => {
      return idx === index ? question : q;
    });

    this.setState({ questions }, this.updateState);
  };

  _updateState = () => {
    const questions = stateQuestionsToProps(this.state.questions);

    this.props.onChange(questions);
  };

  updateType = index => type => {
    const q = this.state.questions[index];
    const options = type.endsWith("choice_input") ? q.options || [""] : null;

    this.updateQuestion({ ...q, type, options }, index);
  };

  renderControls = index => {
    const { classes } = this.props;

    return (
      <Controls
        classes={{ root: classes.controlsRoot }}
        index={index}
        items={this.state.questions}
        updateItems={questions =>
          this.setState({ questions }, this.updateState)
        }
      />
    );
  };

  renderOptionalCheckbox = (question, index) => {
    const { classes } = this.props;

    const checkbox = question.is_optional ? (
      <CheckBox
        className={classes.iconCheckboxChecked}
        onClick={() =>
          this.updateQuestion({ ...question, is_optional: false }, index)
        }
      />
    ) : (
      <CheckBoxBlank
        className={classes.iconCheckbox}
        onClick={() =>
          this.updateQuestion({ ...question, is_optional: true }, index)
        }
      />
    );

    return (
      <div className={classes.optional}>{checkbox} Question is optional</div>
    );
  };

  renderQuestion = (question, index) => {
    const { classes } = this.props;

    return (
      <div key={index} className={classes.container}>
        <div className={classes.row}>
          {this.renderQuestionInput(question, index)}
          <TypeSelector
            question={question}
            updateType={this.updateType(index)}
          />
          {this.renderControls(index)}
        </div>

        {this.renderOptionalCheckbox(question, index)}
      </div>
    );
  };

  renderQuestionInput = (question, index) => {
    const { label: value, options } = question;
    const label = "Question Text";
    const onChange = this.updateLabel(index);

    if (!options) {
      return this.renderTextField(label, value, onChange);
    }

    return (
      <div style={{ width: "100%" }}>
        {this.renderTextField(label, value, onChange)}
        <QuestionOptions
          options={options}
          index={index}
          onChange={this.updateOptions(index)}
        />
      </div>
    );
  };

  renderTextField = (
    label,
    value,
    onChange,
    key = "1",
    style = { marginTop: 0 }
  ) => {
    return (
      <TextField
        key={key}
        label={label}
        value={value}
        onChange={onChange}
        style={style}
      />
    );
  };

  render() {
    const { classes, width } = this.props;
    const { questions } = this.state;

    const body = questions.length ? (
      questions.map(this.renderQuestion)
    ) : (
      <div className={classes.empty}>
        No additional questions set yet. Click Add question below to add your
        first question.
      </div>
    );

    return (
      <div className={classes.root} style={{ width }}>
        {body}
        <AddQuestionButton onClick={() => this.addQuestion()} />
      </div>
    );
  }
}

QuestionsBuilder.propTypes = {
  classes: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

QuestionsBuilder.defaultProps = {
  width: "100%",
  questions: []
};

export { QuestionsBuilder, styles };
export default withStyles(styles)(QuestionsBuilder);
