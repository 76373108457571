import React from "react";
import { connect } from "react-redux";

import ProfileCompletionHint from "../Company/ProfileCompletionHint";
import Banner from "../common/Banner";

import CorporateDashboard from "./CorporateDashboard";
import FounderDashboard from "./FounderDashboard";
import MenubarCorporate from "./MenubarCorporate";

const Dashboard = props => {
  const { company, profile } = props;

  const dashboard = company.is_corporate ? (
    <CorporateDashboard company={company} />
  ) : company.is_founder ? (
    <FounderDashboard company={company} />
  ) : null;

  return (
    <div style={{ backgroundColor: "#F2F3F4" }}>
      <Banner title="My Dashboard" logo={company.logo_image_url} />
      {company.is_corporate && <MenubarCorporate />}

      <ProfileCompletionHint company={company} profile={profile} />
      {dashboard}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    company: state.company,
    profile: state.profile
  };
};

export default connect(mapStateToProps)(Dashboard);
