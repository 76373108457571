import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import { withStyles } from "@material-ui/core/styles";

export const styles = {
  root: {},
  appBarRoot: {
    backgroundColor: "white"
  },
  backgroundBar: {
    backgroundColor: "white",
    position: "absolute",
    left: 0,
    height: 80,
    width: "100%"
  },
  tabsRoot: {
    backgroundColor: "white",
    borderBottom: "1px solid #E6E7E9"
  },
  tabsRoot_fw: {
    backgroundColor: "white"
  },
  tabRoot: {
    color: "#2A2B2C",
    fontSize: "15px",
    fontWeight: 700,
    opacity: 0.7,
    marginRight: 40,
    "&:hover": {
      opacity: 1
    },
    "&:last-child": {
      marginRight: 0
    }
  },
  tabSelected: {
    backgroundColor: "white",
    color: "#2A2B2C",
    opacity: 1,
    fontWeight: 700
  },
  indicator_large: {
    height: "3px",
    backgroundColor: "#388CD3",
    bottom: 0
  },
  indicator_medium: {
    height: "3px",
    backgroundColor: "#388CD3",
    bottom: 0
  },
  large: {
    height: 80,
    fontSize: "16px"
  },
  medium: {
    height: 60,
    fontSize: "14px"
  },
  tabLabel: {
    fontWeight: 700
  },
  tabLabelContainer: {
    // bug with indicator sizing, leaving empty for now
    paddingLeft: 0,
    paddingRight: 0
  }
};

const abcStyles = {
  actionButtonContainer: {
    textAlign: "right",
    flexGrow: 1
  },
  large: {
    height: 80,
    fontSize: "16px",
    lineHeight: "80px"
  },
  medium: {
    height: 60,
    fontSize: "14px",
    lineHeight: "60px"
  }
};

// have to define component to remove unwanted props that get passed in from Tabs
const ActionButtonContainer = withStyles(abcStyles)(props => {
  const { classes, size, children } = props;

  const className = `${classes.actionButtonContainer} ${classes[size]}`;

  return <div className={className}>{children}</div>;
});

class TabbedContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };
  }

  componentDidMount() {
    const { location, tabHashes } = this.props;

    if (location.hash && tabHashes.length) {
      const tabId = location.hash.replace("#", "").split("?")[0];
      const index = tabHashes.indexOf(tabId);

      if (index !== -1) {
        this.setState({ value: index });
      }
    }
  }

  handleChange = (event, value) => {
    this.setState({ value }, () => {
      this.props.onChange(value);
    });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  renderContent = () => {
    const { children, labels } = this.props;
    const { value } = this.state;

    const content = React.Children.toArray(children);

    return (
      content[value] || (
        <div style={{ padding: 30 }}>no content for '{labels[value]}'</div>
      )
    );
  };

  renderTabs = () => {
    const { classes, labels, size, tabWidth } = this.props;

    return labels.map((_label, index) => {
      const label = typeof _label === "object" ? _label.label : _label;
      const other = typeof _label === "object" ? _label : {};

      return (
        <Tab
          key={index}
          classes={{
            root: `${classes.tabRoot} ${classes[size]}`,
            selected: classes.tabSelected,
            label: `${classes.tabLabel} ${classes[size]}`,
            labelContainer: classes.tabLabelContainer
          }}
          label={label}
          {...other}
          style={{ minWidth: tabWidth }}
        />
      );
    });
  };

  renderBody = () => {
    const { actionButton, classes, fullWidth, size } = this.props;

    const rootClass = fullWidth ? classes.tabsRoot_fw : classes.tabsRoot;

    return (
      <div className={classes.root}>
        <div className={fullWidth ? "body" : ""}>
          <AppBar
            position="static"
            color="default"
            classes={{ root: classes.appBarRoot }}
            elevation={0}
          >
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              classes={{
                root: `${rootClass} ${classes[size]}`,
                indicator: classes[`indicator_${size}`]
              }}
            >
              {this.renderTabs()}
              <ActionButtonContainer size={size}>
                {actionButton}
              </ActionButtonContainer>
            </Tabs>
          </AppBar>
        </div>
        {this.renderContent()}
      </div>
    );
  };

  render() {
    const { classes, fullWidth } = this.props;

    if (!fullWidth) return this.renderBody();

    return (
      <React.Fragment>
        <div className={classes.backgroundBar} />
        {this.renderBody()}
      </React.Fragment>
    );
  }
}

TabbedContainer.propTypes = {
  classes: PropTypes.object,
  content: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
  labels: PropTypes.array.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["medium", "large"]).isRequired,
  tabHashes: PropTypes.array.isRequired,
  tabWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  value: PropTypes.number
};

TabbedContainer.defaultProps = {
  content: [],
  fullWidth: false,
  labels: [],
  onChange: () => {},
  size: "medium",
  tabHashes: [],
  tabWidth: 100,
  value: 0
};

export default withRouter(withStyles(styles)(TabbedContainer));
