import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import formStyles from "../../styles/formStyles";
import GroupButton from "../common/ButtonGroup/GroupButton";

import BooleanField from "./BooleanField";
import DateTimeSelector from "./DateTimeSelector";

const styles = {
  root: {
    //outline: "1px solid red",
    marginTop: 20
  },
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10
  },
  dateTimeRoot: {
    marginBottom: 0
  },
  helperText: formStyles.helperText,
  label: formStyles.label
};

const renderButton = (label, onClick, selected) => {
  return (
    <GroupButton selected={selected} onClick={onClick}>
      {label}
    </GroupButton>
  );
};

class ApplicationOptionsSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.value
    };
  }

  setCorporatesOnly = () => {
    const state = { open_to_corporates: true, open_to_founders: false };

    this.setState(state, this.update);
  };

  setFoundersAndCorporates = () => {
    const state = { open_to_corporates: true, open_to_founders: true };

    this.setState(state, this.update);
  };

  setFoundersOnly = () => {
    const state = { open_to_corporates: false, open_to_founders: true };

    this.setState(state, this.update);
  };

  update = () => {
    this.props.onChange(this.state);
  };

  renderGroupSelector = () => {
    const { classes, label } = this.props;

    const { open_to_corporates, open_to_founders } = this.state;

    const _both = open_to_founders && open_to_corporates;
    const _foundersOnly = open_to_founders && !open_to_corporates;
    const _corporatesOnly = !open_to_founders && open_to_corporates;

    return (
      <div>
        <div className={classes.label}>{label}</div>
        <div className={classes.container}>
          {renderButton(
            "Founder Companies only",
            this.setFoundersOnly,
            _foundersOnly
          )}

          {renderButton(
            "Corporates only",
            this.setCorporatesOnly,
            _corporatesOnly
          )}

          {renderButton(
            "Both Founders and Corporates",
            this.setFoundersAndCorporates,
            _both
          )}
        </div>
        {/* helperText && (
          <FormHelperText error={error}>{`${helperText}`}</FormHelperText>
        ) */}
      </div>
    );
  };

  render() {
    const { classes, helperText, hideGuests, width } = this.props;

    const errors = helperText
      ? typeof helperText === "string"
        ? {}
        : helperText
      : {};

    return (
      <div className={classes.root}>
        {this.renderGroupSelector()}
        {!hideGuests && (
          <BooleanField
            id="open_to_guests"
            label="Limit applications to Colinked members only"
            error={Boolean(errors["open_to_guests"])}
            helperText={
              errors["open_to_guests"] ||
              "By default programs are open to public applications. Select this option to limit applications to referred founder companies and/or corporate members only."
            }
            invert={true}
            onChange={value => {
              this.setState({ open_to_guests: value }, this.update);
            }}
            value={Boolean(this.state.open_to_guests)}
            width={width}
          />
        )}
        <BooleanField
          id="nda_required"
          label="NDA Required"
          error={Boolean(errors["nda_required"])}
          helperText={
            errors["nda_required"] ||
            "Turn this on if an NDA must be signed by participants"
          }
          onChange={value => {
            this.setState({ nda_required: value }, this.update);
          }}
          value={Boolean(this.state.nda_required)}
          width={width}
        />
        <DateTimeSelector
          classes={{ root: classes.dateTimeRoot }}
          date={this.state.deadline_datetime}
          error={Boolean(errors["deadline_datetime"])}
          id="deadline_datetime"
          label="Application Deadline"
          helperText={
            errors["deadline_datetime"] ||
            "Set a deadline for applications to automatically close, or leave this blank to manually close the program to applications."
          }
          onChange={value => {
            this.setState({ deadline_datetime: value }, this.update);
          }}
          width={width}
        />
      </div>
    );
  }
}

ApplicationOptionsSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  hideGuests: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    deadline_datetime: PropTypes.string,
    open_to_corporates: PropTypes.bool,
    open_to_founders: PropTypes.bool,
    open_to_guests: PropTypes.bool,
    nda_required: PropTypes.bool
  }).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

ApplicationOptionsSelector.defaultProps = {
  label: "Who can apply?",
  width: 660
};

export default withStyles(styles)(ApplicationOptionsSelector);
