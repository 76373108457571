const styles = {
  root: {
    // marginTop: 30,
    width: "100%",
    overflowX: "auto"
  },

  table: {
    minWidth: 900,
    overflowX: "auto"
  },
  row: {
    maxHeight: 110,
    backgroundColor: "white",
    borderBottom: "1px solid #E6E7E9",
    "&:last-child": {
      borderBottom: "none"
    },
    "&:hover": {
      backgroundColor: "#F3F7FB"
    }
  },
  td: {},

  companyLabel: {
    fontSize: 13
  },
  companyLabelNoLink: {
    fontSize: 13,
    cursor: "auto",
    "&:hover": {
      cursor: "default"
    }
  },
  companyLogo: {
    marginRight: 10
  },
  companyName: {},
  companyNameGrey: {
    color: "#555B6E"
  },
  emailIcon: {
    fill: "#555B6E",
    fontSize: 24,
    marginRight: 5
  },
  recipientLink: {
    display: "flex",
    alignItems: "center",
    color: "#398DD3",
    fontSize: 14
  }
};

export default styles;
