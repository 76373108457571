import React from "react";

import CorporatesListPage from "../components/Corporates/CorporatesListPage";

import PageTemplate from "./PageTemplate";

const Corporates = props => {
  return (
    <PageTemplate>
      <CorporatesListPage />
    </PageTemplate>
  );
};

export default Corporates;
