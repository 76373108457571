import {
  validateCompany,
  validateCompanyFunding,
  validateCompanyTeam,
  validateCriteria,
  validateQuestions
} from "../Form/validate";

const COMPANY_DETAILS_ATTRIBUTES = [
  "name",
  "website",
  "location",
  "markets",
  "logo_image_url",
  "company_type",
  "company_size"
];

const pages = [
  {
    name: "Eligibility",
    formFields: [
      {
        id: "header_eligibility",
        label: "Eligibility",
        helperText:
          "In order the apply for the program all applicants must meet the following criteria. Complete the below checklist to confirm you meet the listed criteria.",
        type: "header"
      },
      {
        label: "Program Criteria",
        id: "criteria",
        type: "applicationCriteriaForm",
        validate: validateCriteria,
        section: true
      }
    ],
    nextButtonLabel: "Next: Application Questions"
  },

  {
    name: "Application Questions",
    formFields: [
      {
        id: "header_application_questions",
        label: "Application Questions",
        helperText: "Complete the below application form",
        type: "header"
      },
      {
        label: "Application Questions",
        id: "questions",
        type: "applicationQuestionsForm",
        validate: validateQuestions
      }
    ]
    // nextButtonLabel: "Next: Review Application"
  },

  // company profile section - guests only
  {
    name: "About your company",
    guestOnly: true,
    formFields: [
      {
        id: "header_company_details",
        label: "Company Details",
        helperText: "Tell the program owner about your company",
        type: "header"
      },
      {
        label: "_Company Details",
        id: "company",
        validate: validateCompany(COMPANY_DETAILS_ATTRIBUTES),
        type: "companyDetails",
        value: {}
      }
    ],
    nextButtonLabel: "Next: Team"
  },

  // company team section - guests only
  {
    name: "Team",
    guestOnly: true,
    formFields: [
      {
        id: "header_company_team",
        label: "Team",
        helperText:
          "Complete your contact details and information on the core team at your company.",
        type: "header"
      },
      {
        label: "_Company Team",
        id: "team",
        dataId: "company",
        type: "companyTeam",
        validate: validateCompanyTeam("team"),
        value: [],
        required: true
      }
    ],
    nextButtonLabel: "Next: Funding"
  },

  // company funding section - guests only
  {
    name: "Funding",
    guestOnly: true,
    formFields: [
      {
        id: "header_company_funding",
        label: "Funding",
        helperText:
          "Most programs and ThinkTanks require a certain funding stage to participate. Don’t worry, this section is kept confidential, and only the program/ThinkTank builder on our platform can view it.",
        type: "header"
      },
      {
        label: "_Company Funding",
        id: "funding",
        dataId: "company",
        validate: validateCompanyFunding,
        type: "companyFunding",
        value: {}
      }

      // {
      //   id: "header_company_investors",
      //   label: "Notable Investors",
      //   type: "header"
      // },
      // {
      //   label: "_Notable Investors",
      //   id: "investors",
      //   validate: validateCompanyInvestors,
      //   type: "companyInvestors",
      //   value: {}
      // }
    ],
    nextButtonLabel: "Next: Review"
  },

  {
    name: "Review",
    formFields: [
      {
        id: "header_review",
        label: "Review your application",
        helperText:
          "All member applications include information from your company profile. Review your completed application below, including information about your company, and make any changes required before you submit.",
        type: "header"
      },
      {
        label: "Review",
        id: "review",
        type: "applicationReview",
        section: true
      }
    ],
    nextButtonLabel: "Submit Application"
  }
];

export default pages;
