const beautyValues = {
  companies__revenue_ranges: {
    "0_500000": "0 - 500k",
    "500000_1000000": "500k - 1M",
    "1000000_2000000": "1M - 2M",
    "2000000_3000000": "2M - 3M",
    "3000000_5000000": "3M - 5M",
    "5000000_10000000": "5M - 10M",
    "10000000_20000000": "10M - 20M",
    "20000000_30000000": "20M - 30M",
    "30000000": "30M+"
  }
};

export const beautifyValues = (key, obj) => {
  const _beauty = beautyValues[key];

  if (_beauty) {
    return Object.keys(obj).reduce((newObj, key) => {
      newObj[key] = _beauty[key] || obj[key];

      return newObj;
    }, {});
  }

  return obj;
};
