import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../../Icons";
import VideoPlayer from "../../common/VideoPlayer";
import { formatFileSize } from "../../Form/utils";

const blockStyle = {
  margin: 30,
  marginBottom: 0
};

const styles = {
  root: {
    backgroundColor: "white"
  },
  block: {
    ...blockStyle
  },
  file: {
    ...blockStyle,
    marginBottom: 30,
    border: "1px solid #E6E7E9",
    fontSize: 14,
    fontWeight: 600,
    padding: 15
  },
  fileHeader: {
    color: "#AAADB6",
    fontSize: 12,
    textTransform: "uppercase"
  },
  fileIcon: {
    fill: "#6592CC",
    marginRight: 10
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    color: "#6592CC",
    marginTop: 10
  },
  fileType: {
    backgroundColor: "rgba(230,231,233,0.5)",
    color: "#AAADB6",
    fontSize: 13,
    fontWeight: 700,
    height: 28,
    lineHeight: "28px",
    borderRadius: 14,
    paddingLeft: 14,
    paddingRight: 15,
    marginLeft: 10
  },
  heading: {
    fontSize: 24,
    fontWeight: 700,
    ...blockStyle,
    //marginTop: 20,
    marginBottom: 16,
    "&:first-child": {
      marginTop: 0,
      paddingTop: 30
    },
    "&:last-child": {
      marginBottom: 0
    }
  },
  image: {
    margin: 0,
    marginTop: 20,
    width: "100%",
    "&:first-child": {
      marginTop: 0
    }
  },
  richtext: {
    ...blockStyle,
    marginTop: 16,
    "&:first-child": {
      marginTop: 0
    },
    "&:last-child": {
      marginBottom: 30
    }
  },
  video: {
    margin: 0,
    marginTop: 30,
    width: "100%",
    "&:first-child": {
      marginTop: 0
    }
  }
};

const createRichTextMarkup = markup => ({ __html: markup });

const getFileExtension = block => {
  if (block.filetype) return block.filetype;

  if (block.content.indexOf(".") !== -1) {
    return block.content
      .split(".")
      .pop()
      .toUpperCase();
  }
};

const renderBlock = (block, index, classes) => {
  const className = classes[block.type] || classes.block;

  let content = block.type;

  if (block.type === "heading") {
    content = block.content;
  } else if (block.type === "image") {
    return (
      <img key={index} src={block.content} alt="" className={classes.image} />
    );
  } else if (block.type === "file") {
    return renderFile(block, index, classes);
  } else if (block.type === "richtext") {
    return renderRichText(block, index, classes);
  } else if (block.type === "video") {
    content = <VideoPlayer url={block.content} />;
  }

  return (
    <div key={index} className={className}>
      {content}
    </div>
  );
};

const renderFile = (block, index, classes) => {
  const filename = (block.content || "").split("/").slice(-1)[0];
  const description = block.description || filename;
  const altText = `${description} ${formatFileSize(block.size)}`;
  const extension = getFileExtension(block);

  return (
    <div key={index} className={classes.file}>
      <div className={classes.fileHeader}>Attached File</div>
      <div className={classes.fileName}>
        <Icons.CloudDownload className={classes.fileIcon} />
        <a href={block.content} title={altText}>
          {description}
        </a>
        {extension && <span className={classes.fileType}>{extension}</span>}
      </div>
    </div>
  );
};

const renderRichText = (block, index, classes) => {
  return (
    <div key={index} className={classes.richtext}>
      <div
        className="rich-text-content"
        dangerouslySetInnerHTML={createRichTextMarkup(block.content)}
      />
    </div>
  );
};

const DocumentProfile = props => {
  const { classes, profile } = props;

  return (
    <div className={classes.root}>
      {profile.map((block, index) => renderBlock(block, index, classes))}
    </div>
  );
};

DocumentProfile.propTypes = {
  profile: PropTypes.array.isRequired
};

DocumentProfile.defaultProps = {
  profile: []
};

export { DocumentProfile, styles };
export default withStyles(styles)(DocumentProfile);
