import React from "react";

import CompanyPanel from "../../common/CompanyPanel";
import TagLabel from "../../common/TagLabel";

import MeetupPanel from "../../Meetup/MeetupPanel";

import DocumentBenefits from "./DocumentBenefits";
import DocumentCriteria from "./DocumentCriteria";
import DocumentParticipants from "./DocumentParticipants";
import DocumentProfile from "./DocumentProfile";
import DocumentTopics from "./DocumentTopics";

const renderBenefits = props => {
  const { classes, document } = props;
  const { benefits } = document;

  return benefits && benefits.length ? (
    <div className={classes.panel}>
      <DocumentBenefits benefits={benefits} />
    </div>
  ) : null;
};

const renderCompany = props => {
  const { classes, document, preview } = props;
  const company = preview ? props.company : document.company;

  const styles = { ...props.styles.sidebar };

  return (
    <div className={classes.sidebar} style={styles}>
      <CompanyPanel
        company={company}
        classes={{
          label: classes.companyLabel,
          name: classes.companyName,
          root: classes.companyRoot
        }}
        prefix="Organized by "
      />
    </div>
  );
};

const renderDescription = props => {
  const { classes, document, width } = props;
  const { description, program_type, topics } = document;

  const styles = { ...props.styles.main };
  if (width === "sm" || width === "xs") {
    styles.paddingLeft = 30;
  }

  return (
    <div className={classes.main} style={styles}>
      <div className={classes.description}>{description}</div>

      <DocumentTopics topics={topics}>
        {program_type && (
          <TagLabel
            label={program_type}
            color="gold"
            classes={{ root: classes.tagRoot }}
          />
        )}
      </DocumentTopics>
    </div>
  );
};

const renderMeetupPanel = props => {
  const { classes, document, preview } = props;

  return (
    <div className={classes.panel}>
      <MeetupPanel
        document={document}
        doctype={document.doctype}
        preview={preview}
      />
    </div>
  );
};

const renderParticipants = props => {
  const { classes, document, styles, preview } = props;

  return (
    <div className={classes.sidebar} style={styles.sidebar}>
      {/* TODO: check if participants are included in program data */}
      <DocumentParticipants
        doctype={document.doctype || "program"}
        id={document.id}
        //participants={document.participants}
        skipLoading={preview}
      />
    </div>
  );
};

const renderProfile = props => {
  const { classes, document, styles } = props;

  return (
    <div
      className={classes.main}
      style={{ ...styles.main, backgroundColor: "white" }}
    >
      <DocumentProfile profile={document.profile} />
    </div>
  );
};

export const renderLayout = props => {
  const { classes, document, styles } = props;

  return (
    <div className={classes.root}>
      <div className={classes.containerTop}>
        <div className={classes.row} style={styles.row}>
          {renderDescription(props)}
          {renderCompany(props)}
        </div>

        <div className={classes.row} style={{ ...styles.row, marginTop: 30 }}>
          {renderProfile(props)}
          <div
            className={classes.sidebar}
            style={{ ...styles.sidebar, paddingBottom: 30 }}
          >
            {renderMeetupPanel(props)}
            {renderBenefits(props)}
          </div>
        </div>
      </div>

      <div className={classes.containerMiddle}>
        <div className={classes.row} style={styles.row}>
          <div className={classes.main} style={styles.main}>
            <DocumentCriteria document={document} />
          </div>
          {renderParticipants(props)}
        </div>
      </div>

      {/* <div className={classes.containerBottom}>
        <div className={classes.row} style={styles.row}>
          <div className={classes.questions}>Questions / Discussion Block</div>
        </div>
  </div> */}
    </div>
  );
};

export const renderLayoutSmall = props => {
  const { classes, document, preview, styles } = props;

  return (
    <div id="layoutSmall">
      <div className={classes.row} style={styles.row}>
        {renderDescription(props)}
      </div>
      <div className={classes.row} style={styles.row}>
        {renderCompany(props)}
      </div>

      <div className={classes.row} style={styles.row}>
        {renderProfile(props)}
      </div>

      <div className={classes.row} style={styles.row}>
        <div
          className={classes.panel}
          style={{ width: "100%", marginBottom: 0 }}
        >
          <MeetupPanel
            document={document}
            doctype={document.doctype}
            preview={preview}
          />
        </div>
      </div>

      <div className={classes.row} style={styles.row}>
        <div
          className={classes.panel}
          style={{ width: "100%", marginBottom: 0 }}
        >
          <DocumentBenefits benefits={document.benefits} />
        </div>
      </div>

      <div className={classes.row} style={styles.row}>
        <div
          className={classes.panel}
          style={{ width: "100%", marginBottom: 0 }}
        >
          <DocumentCriteria document={document} />
        </div>
      </div>

      <div className={classes.row} style={styles.row}>
        <div className={classes.panel} style={{ width: "100%" }}>
          {renderParticipants(props)}
        </div>
      </div>

      {/* <div className={classes.row} style={styles.row}>
        <div className={classes.questions}>Questions / Discussion Block</div>
  </div> */}
    </div>
  );
};
