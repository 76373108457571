import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { createFlashNote } from "../../Reducers/FlashNotes";
import { loadProfile } from "../../Reducers/Profile";

import {
  clearValidationError,
  clearValidationErrors,
  setValidationError,
  setValidationErrors
} from "../../Reducers/ValidationErrors";

import BannerRegistration from "../common/BannerRegistration";
import ErrorPanel from "../common/Errors/ErrorPanel";
import FormBuilder from "../FormBuilder/FormBuilder";

import { submitRegistration, validateToken } from "./api";

import userFormSections from "./userFormSections";

const formatName = json => {
  if (!json.full_name) return json;

  const parts = json.full_name.split(" ").slice();

  return {
    ...json,
    first_name: parts.slice(0, 1)[0],
    last_name: parts.slice(1).join(" ")
  };
};

class RegistrationFormContainer extends React.Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,

    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({ token: PropTypes.string.isRequired }).isRequired
    }).isRequired,

    exitRedirect: PropTypes.string.isRequired,
    pages: PropTypes.array.isRequired,

    createFlashNote: PropTypes.func.isRequired,
    loadProfile: PropTypes.func.isRequired,
    // validationErrors display
    setValidationErrors: PropTypes.func.isRequired,
    validationErrors: PropTypes.object.isRequired,

    submitRegistration: PropTypes.func.isRequired,
    validateToken: PropTypes.func.isRequired
  };

  static defaultProps = {
    exitRedirect: "/dashboard",
    pages: userFormSections,
    submitRegistration,
    testData: {},
    url: "/api/managers/register/",
    validateToken
  };

  state = {
    document: {},
    error: null,
    loading: true
  };

  componentDidMount() {
    const { token } = this.props.match.params;
    const url = this.props.url.replace("register", "validate_token");

    this.props.clearValidationErrors();

    if (!token) {
      this.setState({
        error: new Error("No registration token found."),
        loading: false
      });
      return;
    }

    return this.props
      .validateToken(url, token.slice(6))
      .then(formatName)
      .then(json => {
        const { error, ...document } = json;
        this.setState({ document, error, loading: false });
      })
      .catch(error => this.setState({ error }));
  }

  submitRegistration = data => {
    const {
      createFlashNote,
      loadProfile,
      setValidationErrors,
      url
    } = this.props;
    const { token } = this.props.match.params;

    return this.props
      .submitRegistration(url, data, token.slice(6))
      .then(json => {
        const { error } = json;

        if (error) {
          createFlashNote(error.message, "error");
          setValidationErrors(json.validationErrors || {});
        } else {
          createFlashNote("Your registration is complete!", "success");

          // after profile is loaded redirect in main render will kick in
          loadProfile();
        }

        // return response data to FormBuilder.handleUpdate
        return json;
      });
  };

  renderError = () => {
    const { error } = this.state;

    if (!error) return null;

    return (
      <div>
        <BannerRegistration title="Welcome to Colinked!" />
        <ErrorPanel style={{ margin: 60 }} error={error} />
      </div>
    );
  };

  render() {
    const {
      exitRedirect,
      profile,
      testData,
      validateToken,
      ...otherProps
    } = this.props;

    const { document, error } = this.state;

    // after form is complete user is logged in automatically
    // redirect (to dashboard) after profile loading is complete
    if (profile.isSignedIn && exitRedirect) {
      return <Redirect to={exitRedirect} />;
    }

    if (error) return this.renderError();

    return (
      <FormBuilder
        {...otherProps}
        BannerComponent={BannerRegistration}
        MenuComponent={null}
        document={{ ...testData, ...document, doctype: "registration" }}
        publishLabel="Complete Registration!"
        stepperPosition="top"
        updateDocument={this.submitRegistration}
        isRegistrationForm={true}
      />
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
  validationErrors: state.validationErrors
});

const mapDispatchToProps = dispatch => {
  return {
    clearValidationError: (id, msg) => dispatch(clearValidationError(id, msg)),
    clearValidationErrors: () => dispatch(clearValidationErrors()),
    setValidationError: (id, msg) => dispatch(setValidationError(id, msg)),
    setValidationErrors: errors => dispatch(setValidationErrors(errors)),
    createFlashNote: (msg, type) => dispatch(createFlashNote(msg, type)),
    loadProfile: () => dispatch(loadProfile())
  };
};

export { RegistrationFormContainer };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationFormContainer);
