import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

import BooleanField from "../Form/BooleanField";
import CustomSections from "../Form/CustomSections";
import DeadlineInputSection from "../Form/DeadlineInputSection";
import ExpansionSection from "../Form/ExpansionSection";
import FormInputField from "../Form/FormInputField";
import ImageUploadField from "../Form/ImageUploadField";
import VisibilitySettingsContainer from "../Form/VisibilitySettingsContainer";

import IdSelector from "../Form/IdSelector";

import { FormTagsFieldContainer } from "../Form/Tags";
import ErrorPanel from "../common/Errors/ErrorPanel";
import LoadingPanel from "../common/LoadingPanel";

import MeetupEditView from "../Meetup/MeetupEditView";

import ArchiveDocumentLink from "./ArchiveDocument";
import { documentEdit as documentPropType } from "./propTypes";
import {
  capitaliseDoctype,
  filterDocProps,
  programDefaultState,
  thinkTankDefaultState
} from "./utils";
import { program_type_options } from "./config";

import editStyles from "./styles";

const styles = theme => ({
  ...editStyles(theme),
  button: {
    marginTop: 32,
    display: "block",
    height: 60,
    fontWeight: "bold",
    fontSize: 16,
    flexGrow: 1,
    "&:nth-of-type(1)": {
      marginRight: 8
    },
    "&:nth-of-type(2)": {
      marginLeft: 8
    }
  },
  update: {
    color: "rgba(255,255,255,0.87)",
    backgroundColor: "darkorange"
  }
});

class DocumentEditView extends React.Component {
  static propTypes = {
    createFlashNote: PropTypes.func.isRequired,
    document: documentPropType,
    doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
    error: PropTypes.object
  };

  static defaultProps = {
    //document: { ...programDefaultState }
  };

  constructor(props) {
    super(props);

    const { document, doctype } = props;
    const defaults =
      doctype === "program" ? programDefaultState : thinkTankDefaultState;
    this.state = {
      _dirty: false,
      _deadlineError: null,
      error: null,
      validationErrors: {},
      ...defaults,
      ...filterDocProps(document, doctype)
    };
  }

  componentWillReceiveProps(nextProps) {
    const { document, doctype } = nextProps;

    this.setState({ ...filterDocProps(document, doctype) });
  }

  setButtonState = () => {
    //const _dirty = compareStates(this.state, this.props.document);
    this.setState({ _dirty: true });
  };

  onResetChanges = () => {
    const { document, doctype } = this.props;

    const state = {
      _dirty: false,
      _deadlineError: null,
      error: null,
      validationErrors: {},
      ...filterDocProps(document, doctype)
    };

    this.setState({ ...state });
  };

  onSaveChanges = () => {
    const { doctype } = this.props;
    const { id } = this.props.document;

    // filter state to obtain formData to submit
    const formData = filterDocProps(this.state, doctype);

    const url =
      doctype === "program"
        ? `/api/programs/${id}/`
        : `/api/think_tanks/${id}/`;

    const redirect =
      doctype === "program" ? `/program/${id}` : `/challenge/${id}`;

    fetchWithToken(url, {
      method: "PUT",
      body: JSON.stringify(formData)
    })
      .then(response => responseJsonOrError(response))
      .then(json => {
        const { error, validationErrors } = json;

        if (error) {
          this.setState(
            {
              error,
              validationErrors,
              _dirty: false
            },
            () => this.props.createFlashNote("The update failed.", "warning")
          );
        } else {
          this.setState(
            {
              ...filterDocProps(json, doctype),
              _dirty: false,
              _deadlineError: null,
              error: null,
              validationErrors: {}
            },
            () => {
              this.props.createFlashNote("Your changes were saved.", "success");
              this.props.history.push(redirect);
            }
          );
        }
      })
      .catch(error => {
        this.setState({ error }, () =>
          this.props.createFlashNote(error.message, "error")
        );
      });
  };

  renderArchiveLink = () => {
    const { doctype, document } = this.props;

    return (
      <div
        style={{
          display: "flex",
          marginTop: 30,
          marginBottom: 30,
          maxWidth: "50%"
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <ArchiveDocumentLink
            doctype={doctype}
            document={document}
            style={{ flexGrow: 1 }}
          />
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { content, validationErrors } = this.state;
    const error = Boolean(validationErrors.content);
    const helperText = validationErrors.content || "";

    return (
      <CustomSections
        sections={content}
        update={newContent =>
          this.setState({ content: newContent }, this.setButtonState)
        }
        expansion={true}
        error={error}
        helperText={helperText}
      />
    );
  };

  renderCriteria = () => {
    const { doctype } = this.props;

    if (doctype !== "program") {
      return null;
    }

    const criteriaInput = this.renderInputField("criteria", "Criteria", 4);

    return (
      <ExpansionSection title="Criteria" panelProps={{ defaultExpanded: true }}>
        <div style={{ flexGrow: 1 }}>{criteriaInput}</div>
      </ExpansionSection>
    );
  };

  renderDeadline = () => {
    const { deadline_datetime, is_ongoing, validationErrors } = this.state;
    const error = Boolean(validationErrors.deadline_datetime);
    const helperText = validationErrors.deadline_datetime || "";

    return (
      <DeadlineInputSection
        deadline_datetime={deadline_datetime}
        doctype={this.props.doctype}
        is_ongoing={Boolean(is_ongoing)}
        state={this.state.state}
        update={state => this.setState(state, this.setButtonState)}
        error={error}
        helperText={helperText}
      />
    );
  };

  renderImages = () => {
    if (this.props.doctype !== "program") return null;

    const logoImageInput = this.renderImageField(
      "logo_image_url",
      "Logo image",
      {
        height: 200,
        width: 200,
        helperText: "Logo image should be 200x200 px"
      }
    );
    const heroImageInput = this.renderImageField(
      "hero_image_url",
      "Banner image",
      {
        height: 150,
        helperText: "Banner image should be 320px high and approx. 1500px wide"
      }
    );

    return (
      <ExpansionSection title="Images" panelProps={{ defaultExpanded: true }}>
        <div style={{ flexGrow: 1 }}>
          {logoImageInput}
          {heroImageInput}
        </div>
      </ExpansionSection>
    );
  };

  renderImageField = (id, title, options = {}) => {
    const { validationErrors } = this.state;
    const error = Boolean(validationErrors[id]);
    const _helperText = validationErrors[id] || options.helperText;
    const { helperText, ...other } = options;

    return (
      <ImageUploadField
        {...other}
        id={id}
        label={title}
        value={this.state[id]}
        onChange={value => this.setState({ [id]: value }, this.setButtonState)}
        error={error}
        helperText={_helperText}
        required={true}
      />
    );
  };

  renderInputField = (id, title, rows = 0, helperText = "") => {
    const { validationErrors } = this.state;
    const error = Boolean(validationErrors[id]);
    const _helperText = validationErrors[id] || helperText;

    return (
      <FormInputField
        id={id}
        label={title}
        value={this.state[id]}
        rows={rows}
        onChange={value => this.setState({ [id]: value }, this.setButtonState)}
        width="100%"
        error={error}
        helperText={_helperText}
      />
    );
  };

  renderTitleAndDescription = () => {
    const doctype = capitaliseDoctype(this.props.doctype);

    const titleInput = this.renderInputField(
      "name",
      `${doctype} question or title`
    );
    const descriptionInput = this.renderInputField(
      "description",
      "Description",
      8
    );

    const typeInput =
      this.props.doctype === "program" ? (
        <IdSelector
          id="program_type"
          label="Program Type"
          value={this.state.program_type}
          onChange={value =>
            this.setState({ program_type: value }, this.setButtonState)
          }
          width="100%"
          options={program_type_options.options}
          labels={program_type_options.labels}
          helperText={`Please select a suitable type for your ${
            this.props.doctype
          }`}
        />
      ) : null;

    const openToCorpsInput =
      this.props.doctype === "program" ? (
        <BooleanField
          id="is_open_to_corps"
          label="Corporations can apply to this program."
          onChange={value =>
            this.setState({ is_open_to_corps: value }, this.setButtonState)
          }
          value={Boolean(this.state.is_open_to_corps)}
        />
      ) : null;

    return (
      <ExpansionSection
        title={`About this ${doctype}`}
        panelProps={{ defaultExpanded: true }}
      >
        <div style={{ flexGrow: 1 }}>
          {titleInput}
          {descriptionInput}
          {typeInput}
          {openToCorpsInput}
        </div>
      </ExpansionSection>
    );
  };

  setTopics = value => {
    let _value = value;
    if (typeof value === "string") {
      _value = value.split(",");
    }

    this.setState({ topics: _value }, this.setButtonState);
  };

  renderTopics = () => {
    const { topics } = this.state;
    const arr = typeof topics === "string" ? topics.split(",") : topics;

    const tagsField = (
      <FormTagsFieldContainer
        id="topics"
        category="technologies"
        label="Tags help founders discover relevant programs."
        placeholder="Please select all tags that apply."
        value={topics}
        onChange={this.setTopics}
        width="100%"
      />
    );

    return (
      <ExpansionSection
        title="Topics"
        summaryText={`${(arr || []).length} selected`}
        panelProps={{ defaultExpanded: Boolean((arr || []).length) }}
      >
        <div style={{ flexGrow: 1 }}>{tagsField}</div>
      </ExpansionSection>
    );
  };

  renderUpdateButtons = () => {
    const { classes } = this.props;
    const { _dirty } = this.state;

    const options = _dirty
      ? {
          variant: "raised",
          className: `${classes.button} ${classes.update}`,
          onClick: this.onSaveChanges
        }
      : {
          disabled: true,
          className: classes.button
        };

    return (
      <div style={{ display: "flex" }}>
        <Button {...options}>Save changes</Button>
        <Button
          disabled={!_dirty}
          className={classes.button}
          onClick={this.onResetChanges}
        >
          Reset changes
        </Button>
      </div>
    );
  };

  renderVisibility = () => {
    if (this.props.doctype === "program") return null;

    return (
      <VisibilitySettingsContainer
        doctype={capitaliseDoctype(this.props.doctype)}
        onChange={object => this.setState(object, this.setButtonState)}
        state={this.state}
      />
    );
  };

  renderMeetup = () => {
    const meetupInput = (
      <MeetupEditView
        doctype={this.props.doctype}
        document={this.state}
        onChange={values => this.setState(values, this.setButtonState)}
      />
    );

    return (
      <ExpansionSection
        title="Taking part"
        panelProps={{ defaultExpanded: true }}
      >
        <div style={{ flexGrow: 1 }}>{meetupInput}</div>
      </ExpansionSection>
    );
  };

  renderDocumentContent = () => {
    if (Object.keys(this.props.document).length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        {this.renderTitleAndDescription()}
        {this.renderImages()}
        {this.renderCriteria()}
        {this.renderDeadline()}

        {this.renderContent()}
        {this.renderTopics()}
        {this.renderVisibility()}

        {this.renderMeetup()}
        {this.renderUpdateButtons()}
        {this.renderArchiveLink()}
      </React.Fragment>
    );
  };

  render() {
    const { classes, error, loading } = this.props;

    return (
      <React.Fragment>
        <div className="body" style={{ paddingTop: 48 }}>
          <Grid container spacing={40} className={classes.gridContainer}>
            <Grid item sm={12} md={8} lg={8}>
              {error && <ErrorPanel error={error} />}
              {loading && <LoadingPanel />}

              {this.renderDocumentContent()}
            </Grid>
            <Grid item sm={12} md={4} lg={4} />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DocumentEditView);
