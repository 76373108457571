import { connect } from "react-redux";

import ApplicationsListRow from "./ApplicationsListRow";

import { updateApplication } from "../../Reducers/Applications";

const mapDispatchToProps = dispatch => {
  return {
    clearError: url => dispatch(updateApplication({ url, error: null })),
    deselectApplication: url =>
      dispatch(updateApplication({ url, selected: false })),
    selectApplication: url =>
      dispatch(updateApplication({ url, selected: true }))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ApplicationsListRow);
