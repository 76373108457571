import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import { createFlashNote } from "../../Reducers/FlashNotes";

import ApplicationsListContainer from "../Applications/ApplicationsListContainer";
import ParticipantsListContainer from "../Applications/ParticipantsListContainer";
import Banner from "../common/Banner";
import TabbedContainer from "../common/TabbedContainer";

import DocumentEditView from "./DocumentEditView";

const styles = theme => ({
  root: {
    //outline: "1px solid red",
    marginTop: 0,
    marginBottom: 8 * theme.spacing.unit
  },
  iconRed: {
    fill: "darkred"
  }
});

class DocumentManageView extends React.Component {
  static propTypes = {
    activity: PropTypes.string,
    activites: PropTypes.array,
    createFlashNote: PropTypes.func.isRequired,
    doctype: PropTypes.string.isRequired,
    document: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    labels: PropTypes.array,
    subtitles: PropTypes.array
  };

  static defaultProps = {
    activity: "edit",
    activities: ["edit", "applications", "participants"],
    labels: ["Edit", "Applications", "Participants"],
    subtitles: [
      "Edit Challenge details",
      "Manage pending applications",
      "List of approved document participants"
    ]
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.activities.indexOf(props.activity)
    };
  }

  onTabChange = value => {
    const parts = this.props.location.pathname.split("/");
    parts[parts.length - 1] = this.props.activities[value];
    const url = parts.join("/");

    this.setState({ value }, () => this.props.history.replace(url));
  };

  render() {
    const { classes, doctype, document, ...otherProps } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>
        <Banner
          title={document.title || document.name || "Manage"}
          subtitle={this.props.subtitles[value]}
        />
        <div className={`body ${classes.root}`}>
          <TabbedContainer
            labels={this.props.labels}
            //actionButton={actionButton}
            onChange={this.onTabChange}
            value={value}
            fullWidth={true}
            size="large"
          >
            <DocumentEditView
              document={document}
              doctype={doctype}
              {...otherProps}
            />
            <ApplicationsListContainer
              document={document}
              doctype={doctype}
              viewtype="private"
              {...otherProps}
            />
            <ParticipantsListContainer
              document={document}
              doctype={doctype}
              viewtype="private"
              {...otherProps}
            />
          </TabbedContainer>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (message, type) => dispatch(createFlashNote(message, type))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles)(DocumentManageView)));
