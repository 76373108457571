import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";
import { formatSearchString } from "../../api/search";

const NEW_PROFILE = [
  { type: "heading", content: "" },
  { type: "richtext", content: "" }
];

// remove null values from api response
const stripNullValues = json => {
  return Object.keys(json).reduce((data, key) => {
    if (json[key] !== null) {
      data[key] = json[key];
    }

    return data;
  }, {});
};

export const addFrontendData = (json = {}) => {
  const { profile, ...service } = stripNullValues(json);

  return {
    doctype: "service",
    link: json.id ? `/marketplace/${json.id}` : null,
    ...service,

    // add minimum profile section if not provided
    profile: profile && profile.length ? profile : NEW_PROFILE,
    testimonials: json.testimonials || []
  };
};

const addFrontendDataArray = response => {
  if (!response.results) return response;

  return {
    ...response,
    results: response.results.map(addFrontendData)
  };
};

const removeFrontendData = data => {
  const { doctype, company, link, ...apiData } = data;

  return apiData;
};

const _callMarketplaceAPI = (data, method) => {
  const apiData = removeFrontendData(data);

  const url = apiData.id
    ? `/api/marketplace/${apiData.id}/`
    : "/api/marketplace/";

  return fetchWithToken(url, {
    body: JSON.stringify(apiData),
    cache: "no-cache",
    credentials: "same-origin",
    headers: {},
    method
  }).then(responseJsonOrError);
};

export const createNewService = data => {
  const { id, ...apiData } = data;

  return _callMarketplaceAPI(apiData, "POST").then(addFrontendData);
};

export const deleteService = id => {
  return _callMarketplaceAPI({ id }, "DELETE");
};

export const loadService = id => {
  return fetchWithToken(`/api/marketplace/${id}/`, {
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(addFrontendData);
};

export const loadServices = (options = []) => {
  const searchString = formatSearchString(options);
  const url = searchString
    ? `/api/marketplace/?${searchString}`
    : "/api/marketplace/";

  return fetchWithToken(url, {
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(addFrontendDataArray);
};

export const updateService = data => {
  const apiData = removeFrontendData(data);

  if (!apiData.id) {
    return createNewService(apiData);
  }

  return _callMarketplaceAPI(apiData, "PUT");
};

export const updateServices = updated_at => {
  return loadServices([{ updated_at }]);
};

export const loadServicesFromUrl = url => {
  return fetchWithToken(url, {
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(addFrontendDataArray);
};
