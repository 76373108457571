import React from "react";

import ProfileEditor from "../components/UserProfile/UserProfileEditor";

import PageTemplate from "./PageTemplate";

const UserProfileEditor = props => {
  return (
    <PageTemplate>
      <ProfileEditor {...props} />
    </PageTemplate>
  );
};

export default UserProfileEditor;
