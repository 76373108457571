import React from "react";

import ServiceDetailsViewContainer from "../components/Marketplace/ServiceDetailsViewContainer";

import PageTemplate from "./PageTemplate";

const MarketplaceService = props => {
  return (
    <PageTemplate>
      <ServiceDetailsViewContainer {...props} />
    </PageTemplate>
  );
};

export default MarketplaceService;
