import React from "react";

import PageTemplate from "./PageTemplate";

import About from "../components/About";

const Aboutpage = props => {
  return (
    <PageTemplate>
      <About />
    </PageTemplate>
  );
};

export default Aboutpage;
