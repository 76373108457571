import React from "react";
import PropTypes from "prop-types";

import MenuIcon from "@material-ui/icons/Menu";

import SubMenuButton from "../../common/Buttons/SubMenuButton";

import Avatar from "../ProfileButton/Avatar";
import { guestLinks, memberLinks } from "../links";

const getMobileLinks = props => {
  const { company, history, logout, profile } = props;

  const links = !profile.isSignedIn
    ? guestLinks
    : [
        ...memberLinks,
        {
          label: "My User Profile",
          link: "/myprofile/edit",
          icon: () => (
            <Avatar profile={profile} size={27} style={{ fontSize: "1rem" }} />
          )
        },
        {
          label: "My Company Profile",
          link: "/mycompany/edit",
          icon: () => (
            <Avatar profile={company} size={27} style={{ fontSize: "1rem" }} />
          )
        },
        {
          label: "Manage Team",
          link: "/mycompany/edit#team"
        },
        {
          label: "Log out",
          action: () => {
            history.push("/");
            logout();
          },
          active: true
        }
      ];

  return links.map(renderLink(history));
};

const onLinkClick = (history, link) => {
  if (window.ga) {
    window.ga("send", "event", "Link", "click", link);
  }

  history.push(link);
};

const renderLink = history => link => ({
  ...link,
  action: link.action ? link.action : () => onLinkClick(history, link.link)
});

const MobileLinks = props => {
  return (
    <SubMenuButton
      actions={getMobileLinks(props)}
      separator={true}
      buttonProps={{ style: { marginLeft: 15 } }}
      style={{ paddingLeft: 30, paddingRight: 30 }}
      menuWidth={300}
    >
      <MenuIcon style={{ marginRight: 5 }} /> <strong>Menu</strong>
    </SubMenuButton>
  );
};

MobileLinks.propTypes = {
  company: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  logout: PropTypes.func.isRequired,
  menuWidth: PropTypes.number.isRequired,
  profile: PropTypes.object.isRequired
};

MobileLinks.defaultProps = {
  menuWidth: 300
};

export default MobileLinks;
