import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import MailIcon from "@material-ui/icons/MailOutline";
import SendIcon from "@material-ui/icons/Send";

import SimpleDialog from "../common/SimpleDialog";
import TextInputField from "../Form/TextInputField";

import { getPrimaryButtonClasses } from "../../styles/buttonStyles";

const sendStyles = getPrimaryButtonClasses("#6592CC");
const SendButton = withStyles(sendStyles)(Button);

const styles = {
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10
  },
  comment: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300,
    marginTop: -5,
    marginBottom: 10
  },
  textInputRoot: {
    marginTop: 0
  }
};

class EmailApplicantsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: props.message,
      subject: props.subject
    };
  }

  render() {
    const { classes, onClose, onSend } = this.props;

    const title = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <MailIcon style={{ marginRight: 5 }} />
        {this.props.title}
      </div>
    );

    return (
      <SimpleDialog title={title} onClose={onClose} width={520}>
        <div className={classes.comment}>
          Email applicants with questions or updates…
        </div>
        <TextInputField
          label="Subject"
          onChange={subject => this.setState({ subject })}
          value={this.state.subject}
          width={440}
        />
        <TextInputField
          label="Message"
          rows={6}
          onChange={message => this.setState({ message })}
          value={this.state.message}
          width={520}
        />
        <div className={classes.buttons}>
          <SendButton
            disabled={!this.state.subject || !this.state.message}
            onClick={() => onSend(this.state)}
          >
            <SendIcon style={{ marginRight: 5 }} />
            Send Email
          </SendButton>
        </div>
      </SimpleDialog>
    );
  }
}

EmailApplicantsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

EmailApplicantsModal.defaultProps = {
  message: "",
  subject: "Your application for ",
  title: "Email Applicants"
};

export default withStyles(styles)(EmailApplicantsModal);
