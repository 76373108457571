import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import Banner from "../common/Banner";
import FormInputField from "../Form/FormInputField";

const statusStyle = {
  color: "white",
  fontSize: 14,
  fontWeight: 600,
  maxWidth: 320,
  textAlign: "center",
  padding: "11px 15px",
  lineHeight: "16px"
};

const styles = theme => ({
  banner: {
    height: 20
  },
  root: {
    width: "100vw",
    backgroundColor: "#F2F3F4",
    display: "flex",
    justifyContent: "center",
    paddingTop: 80,
    paddingBottom: 240
  },
  button: {
    height: 60,
    width: 330,
    marginTop: 20,
    boxShadow: "none"
  },
  error: {
    ...statusStyle,
    maxWidth: 300,
    backgroundColor: "#DB6E53"
  },
  loading: {
    ...statusStyle,
    backgroundColor: "#AAADB6"
  },
  success: {
    ...statusStyle,
    backgroundColor: "#9CB59E"
  },
  buttonLabel: {
    color: "white",
    fontSize: 16,
    fontWeight: 600
  },
  buttonRaised: {
    backgroundColor: "#6592CC",
    boxShadow: "0 10px 10px 0 rgba(0,0,0,0.1)"
  },
  formContainer: {
    backgroundColor: "white",
    padding: 30,
    boxShadow: "0 15px 10px 0 rgba(55,60,68,0.05)",
    marginBottom: 30
  },
  formTitle: {
    color: "#6592CC",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 30
  },
  passwordLink: {
    color: "#3D5A80",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "17px",
    cursor: "pointer"
  },
  testButtons: {
    maxWidth: 330,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    margin: 10,
    marginBottom: 0
  }
});

class ResetPasswordPage extends React.Component {
  static propTypes = {
    error: PropTypes.object,
    loading: PropTypes.bool,
    success: PropTypes.string,
    resetError: PropTypes.func.isRequired,
    submitPassword: PropTypes.func.isRequired
  };

  confirm_password_timeout = null;
  new_password_timeout = null;

  state = {
    disabled: true,
    confirm_password: "",
    confirm_password_error: null,
    new_password: "",
    new_password_error: null
  };

  handleKeyEvent = event => {
    // validate and submit if enter is pressed in confirm pw field
    const keyCode = event.which || event.keyCode;
    if (keyCode === 13) {
      if (this.validatePassword && this.validateConfirmation) {
        this.onSubmit();
      }
    }
  };

  setButtonState = () => {
    const password = this._validatePassword();
    const confirm = this._validateConfirmation();
    const disabled = Boolean(password || confirm);

    this.setState({ disabled });
  };

  _validateConfirmation = () => {
    const { new_password, confirm_password } = this.state;

    let error = null;
    if (new_password !== confirm_password) {
      error = "Passwords do not match.";
    }

    return error;
  };

  validateConfirmation = () => {
    this.props.resetError();
    const error = this._validateConfirmation();

    if (!error) {
      this.confirm_password_timeout &&
        clearTimeout(this.confirm_password_timeout);
      this.confirm_password_timeout = null;
      this.setState({ confirm_password_error: null });
    }

    if (error && !this.confirm_password_timeout) {
      this.confirm_password_timeout = setTimeout(
        () => this.setState({ confirm_password_error: error }),
        2000
      );
    }

    this.setButtonState();
    return !error;
  };

  _validatePassword = () => {
    if (this.state.new_password.length < 9) {
      return "Password needs to be at least 9 characters long.";
    }

    return null;
  };

  validatePassword = () => {
    this.props.resetError();
    const error = this._validatePassword();

    if (!error) {
      this.new_password_timeout && clearTimeout(this.new_password_timeout);
      this.new_password_timeout = null;
      this.setState({ new_password_error: null });
    }

    if (error && !this.new_password_timeout) {
      this.new_password_timeout = setTimeout(
        () => this.setState({ new_password_error: error }),
        2000
      );
    }

    // if confirm_password exists update error message
    if (this.state.confirm_password) {
      return !error && this.validateConfirmation();
    }

    return !error;
  };

  onSubmit = () => {
    this.props.submitPassword(
      this.state.new_password,
      this.state.confirm_password
    );
  };

  renderForm = () => {
    const { classes } = this.props;
    const {
      new_password,
      new_password_error,
      confirm_password,
      confirm_password_error,
      disabled
    } = this.state;

    return (
      <div className={classes.formContainer}>
        <div className={classes.formTitle}>Set a new password</div>

        <FormInputField
          id="new_password"
          label="Password"
          value={new_password}
          onChange={value =>
            this.setState({ new_password: value }, this.validatePassword)
          }
          width={330}
          type="password"
          error={Boolean(new_password_error)}
          helperText={new_password_error || ""}
        />
        <FormInputField
          id="confirm_password"
          label="Confirm new password"
          value={confirm_password}
          onChange={value =>
            this.setState(
              { confirm_password: value },
              this.validateConfirmation
            )
          }
          width={330}
          type="password"
          error={Boolean(confirm_password_error)}
          helperText={confirm_password_error || ""}
          onKeyPress={this.handleKeyEvent}
        />
        {this.renderFeedback()}
        <Button
          classes={{
            root: classes.button,
            raised: classes.buttonRaised,
            disabled: classes.disabled,
            label: classes.buttonLabel
          }}
          variant="raised"
          disabled={Boolean(this.props.error) || disabled}
          onClick={this.onSubmit}
        >
          Submit
        </Button>
      </div>
    );
  };

  renderFeedback = () => {
    const { classes } = this.props;
    const { error, loading, success } = this.props;

    if (error) {
      return <div className={classes.error}>Error: {error.message}</div>;
    }

    if (loading) {
      return <div className={classes.loading}>processing ...</div>;
    }

    if (success) {
      return <div className={classes.success}>{success}</div>;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Banner title="" classes={{ root: classes.banner }} />
        <div className={classes.root}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            {this.renderForm()}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ResetPasswordPage);
