import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Banner from "../common/Banner";
import ErrorPanel from "../common/Errors/ErrorPanel";
import LoadingPanel from "../common/LoadingPanel";
import TabbedContainer from "../common/TabbedContainer";

import ApplicationsListView from "./ApplicationsListViewContainer";

const styles = {
  root: {
    backgroundColor: "#F2F3F4"
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    height: 80,
    lineHeight: "80px"
  },
  tabbedContainerRoot: {
    backgroundColor: "white",
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
    marginBottom: 60
  },
  tabsRoot: {
    paddingLeft: 30
  }
};

const renderBody = props => {
  const { classes, document } = props;

  const applications = props.applications.filter(app => {
    const docId = parseInt(app.url.split("/")[3], 10);

    return docId === document.id;
  });

  const pending = applications.filter(app => app.is_approved === 1);
  const approved = applications.filter(app => app.is_approved === 2);
  const declined = applications.filter(app => app.is_approved === 0);

  const labels = [
    {
      label: `Pending Applications (${pending.length})`,
      disabled: pending.length === 0
    },
    {
      label: `Approved Applications (${approved.length})`,
      disabled: approved.length === 0
    },
    {
      label: `Declined Applications (${declined.length})`,
      disabled: declined.length === 0
    }
  ].filter(s => s.label);

  return (
    <div className={classes.root}>
      <div className="body">
        <div className={classes.title}>{applications.length} Applications</div>

        <TabbedContainer
          classes={{
            root: classes.tabbedContainerRoot,
            tabsRoot: classes.tabsRoot
          }}
          fullwidth={false}
          labels={labels}
        >
          <ApplicationsListView status="pending" applications={pending} />
          <ApplicationsListView status="approved" applications={approved} />
          <ApplicationsListView status="declined" applications={declined} />
        </TabbedContainer>
      </div>
    </div>
  );
};

const renderError = error => {
  return (
    <div className="body">
      <ErrorPanel error={error} />
    </div>
  );
};

const renderLoading = error => {
  return (
    <div className="body">
      <LoadingPanel />
    </div>
  );
};

const ApplicationsManageView = props => {
  const { document, error, loading } = props;

  const modal = null;

  const content = error
    ? renderError(error)
    : loading
    ? renderLoading()
    : renderBody(props);

  return (
    <React.Fragment>
      {modal}
      <Banner
        document={document}
        logo={document.logo_image_url}
        title="Manage Applications"
      />
      {content}
    </React.Fragment>
  );
};

ApplicationsManageView.propTypes = {
  applications: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  document: PropTypes.shape({
    doctype: PropTypes.oneOf(["program", "challenge"]),
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool
};

export default withStyles(styles)(ApplicationsManageView);
