import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { loadProgramApplications } from "../../../Reducers/Applications";
import { createFlashNote } from "../../../Reducers/FlashNotes";
import { deleteProgram, updateProgram } from "../../Programs/api";

import ManageLinks from "./ManageLinks";

const mapStateToProps = state => {
  return {
    applications: state.applications.applications,
    deleteDocument: deleteProgram,
    updateDocument: updateProgram
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => dispatch(createFlashNote(msg, type)),
    loadApplications: id => dispatch(loadProgramApplications(id))
  };
};

const ManageProgramLinks = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageLinks);

export default withRouter(ManageProgramLinks);
