import {
  RESET_COMPANIES_LIST,
  SET_COMPANIES_LIST_LOADING,
  UPDATE_COMPANIES_LIST,
  UPDATE_COMPANY
} from "./constants";

export const resetCompaniesList = () => {
  return {
    type: RESET_COMPANIES_LIST
  };
};

export const setCompaniesListLoading = (loading = true) => {
  return {
    type: SET_COMPANIES_LIST_LOADING,
    payload: loading
  };
};

export const updateCompaniesList = companies => {
  return {
    type: UPDATE_COMPANIES_LIST,
    payload: companies
  };
};

export const updateCompany = company => {
  return {
    type: UPDATE_COMPANY,
    payload: company
  };
};
