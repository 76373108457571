import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../Icons";

const styles = {
  root: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    cursor: "pointer",
    fill: "#AAADB6"
  }
};

const formatUrl = url => {
  if (!url) return null;
  if (!url.startsWith("http")) return null;

  return url;
};

const formatTwitterUrl = url => {
  if (!url) return null;
  if (url.startsWith("http")) return url;
  if (url.startsWith("@")) return `https://twitter.com/${url}`;

  return url;
};

const SocialLinks = props => {
  const { classes, spacing } = props;

  const style = { marginRight: spacing };

  const linkedinUrl = formatUrl(props.linkedin);
  const twitterUrl = formatTwitterUrl(props.twitter);
  const facebookUrl = formatUrl(props.facebook);

  return (
    <div className={classes.root}>
      {props.email &&
        props.showEmail && (
          <a href={`mailto:${props.email}`} alt="email">
            <Icons.Envelope className={classes.icon} style={{ ...style }} />
          </a>
        )}
      {linkedinUrl && (
        <a href={linkedinUrl} rel="noopener noreferrer" target="_blank">
          <Icons.LinkedIn className={classes.icon} style={{ ...style }} />
        </a>
      )}
      {twitterUrl && (
        <a href={twitterUrl} rel="noopener noreferrer" target="_blank">
          <Icons.Twitter className={classes.icon} style={{ ...style }} />
        </a>
      )}
      {facebookUrl && (
        <a href={facebookUrl} rel="noopener noreferrer" target="_blank">
          <Icons.Facebook className={classes.icon} style={{ ...style }} />
        </a>
      )}
    </div>
  );
};

SocialLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  facebook: PropTypes.string,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
  showEmail: PropTypes.bool,
  size: PropTypes.number.isRequired,
  spacing: PropTypes.number.isRequired
};

SocialLinks.defaultProps = {
  showEmail: false,
  size: 24,
  spacing: 20
};

export { SocialLinks, styles };
export default withStyles(styles)(SocialLinks);
