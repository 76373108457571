import React from "react";
import { connect } from "react-redux";

import CloudDownload from "@material-ui/icons/CloudDownload";

import { SecondaryButton } from "../common/Buttons";

import { exportApplications } from "../../Reducers/Applications";
import { createFlashNote } from "../../Reducers/FlashNotes";

class DownloadCSVButton extends React.Component {
  state = {
    data: null,
    disabled: false
  };

  startDownload = () => {
    const { createFlashNote } = this.props;

    // request download based on current url
    const path = document.location.pathname;
    const [type, id] = path.split("/").slice(1);

    exportApplications(id, type).then(({ data, error }) => {
      if (error) {
        createFlashNote(error.message, "error");
      } else {
        this.setState({ data, disabled: false }, this.saveData);
      }
    });
  };

  saveData = () => {
    const { data } = this.state;

    const a = document.getElementById("downloadlink");
    const file = new Blob([data], { type: "text/csv" });
    a.href = URL.createObjectURL(file);
    a.download = "export.csv";
    a.click();
  };

  render() {
    const { disabled } = this.state;

    return (
      <React.Fragment>
        <SecondaryButton
          disabled={disabled}
          onClick={() => this.setState({ disabled: true }, this.startDownload)}
        >
          <CloudDownload style={{ marginRight: 5, fontSize: 20 }} />
          Export
        </SecondaryButton>
        <a id="downloadlink" style={{ display: "none" }} href=".">
          download link
        </a>
      </React.Fragment>
    );
  }
}

export { DownloadCSVButton };
const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(DownloadCSVButton);
