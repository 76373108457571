import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

export const ADD_TECHNOLOGY = "ADD_TECHNOLOGY";
export const SET_TECHNOLOGIES = "SET_TECHNOLOGIES";

export const addTechnology = tag => ({ type: ADD_TECHNOLOGY, payload: tag });

export const setTechnologies = array => ({
  type: SET_TECHNOLOGIES,
  payload: array
});

export const createTechnology = tag => {
  return dispatch => {
    return fetchWithToken(`/api/technologies/`, {
      cache: "no-cache",
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify(tag)
    })
      .then(response => responseJsonOrError(response))
      .then(json => {
        if (json.error) {
          throw json.error;
        }

        return json;
      })
      .then(tag => {
        dispatch(addTechnology(tag));
      })
      .catch(error => console.log(error));
  };
};

export const fetchTechnologies = () => {
  return dispatch => {
    return fetch(`/api/technologies/?limit=10000`, {
      cache: "no-cache",
      credentials: "same-origin"
    })
      .then(response => responseJsonOrError(response))
      .then(json => {
        if (json.error) {
          throw json.error;
        }

        return json;
      })
      .then(json => (json.results ? json.results : json))
      .then(technologies => {
        technologies.sort((a, b) => {
          return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
        });

        dispatch(setTechnologies(technologies));
      })
      .catch(error => {
        if (process.env.NODE_ENV !== "test") console.log(error);
      });
  };
};
