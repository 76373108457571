import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

import LoadingPage from "../Pages/LoadingPage";
import SignInPage from "../components/Authentication/SignInPageContainer";

const PrivateRoute = props => {
  const { component: Component, profile, ...rest } = props;

  return (
    <Route
      {...rest}
      render={childProps =>
        profile.isSignedIn ? (
          <Component {...childProps} />
        ) : profile.loading ? (
          <LoadingPage path={rest.path} />
        ) : (
          <SignInPage redirect={rest.path} />
        )
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(PrivateRoute);
