import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import EditIcon from "@material-ui/icons/Edit";
import Icons from "../../Icons";

import ContactCardSmall from "../../Contacts/ContactCardSmall";
import AddTeamMemberButton from "./AddTeamMemberButton";
import EditMemberForm from "./EditMemberForm";

import { getOutlineButtonClasses } from "../../../styles/buttonStyles";

const { iconClass, ...outlineButtonStyles } = getOutlineButtonClasses(
  "#2A2B2C",
  "small"
);
const OutlineButton = withStyles(outlineButtonStyles)(Button);

const styles = {
  root: {
    width: "100%",
    overflowX: "auto",
    marginBottom: 60
  },
  card: {
    padding: 15
  },
  cardContainer: {
    position: "relative"
  },
  controls: {
    position: "relative",
    top: 10,
    height: 0,
    textAlign: "right"
  },
  deleteButton: {
    padding: 0,
    minWidth: 28
  },
  icon: {
    fontSize: 18,
    marginTop: -4,
    marginRight: 5
  },
  iconDelete: {
    fontSize: 18,
    marginTop: -1
  },
  label: {
    fontSize: 15,
    marginTop: 20,
    marginBottom: 5
  },
  input: {
    boxSizing: "border-box",
    padding: "15px 10px",
    backgroundColor: "#F2F3F4",
    width: 220,
    marginBottom: 20
  }
};

class CompanyTeamForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      team: props.company.team || []
    };
  }

  addTeamMember = () => {
    const team = [...this.state.team, { editing: true }];

    this.setState({ team });
  };

  deleteAccount = index => () => {
    const team = this.state.team.filter((_, idx) => idx !== index);

    this.setState({ team }, () => {
      this.props.onChange({ ...this.props.company, team });
    });
  };

  editAccount = index => () => {
    const team = this.state.team.map((acc, idx) => ({
      ...acc,
      editing: idx === index
    }));

    this.setState({ team });
  };

  handleChange = () => {
    const team = this.state.team.map(member => {
      const { editing, ...userData } = member;

      return userData;
    });

    this.props.onChange({ ...this.props.company, team });
  };

  updateTeamMember = index => (account, remove = false) => {
    const team = remove
      ? this.state.team.filter((_, idx) => idx !== index)
      : this.state.team.map((acc, idx) => (idx === index ? account : acc));

    this.setState({ team }, this.handleChange);
  };

  renderTeamMember = editing => (member, index) => {
    const account = index === 0 ? { ...member, primary_contact: true } : member;

    if (member.editing) {
      return (
        <EditMemberForm
          key={index}
          account={account}
          onChange={this.updateTeamMember(index)}
          primary={index === 0}
          removeOnCancel={!Boolean(account.first_name)}
        />
      );
    }

    return this.renderTeamMemberCard(account, index, editing);
  };

  renderTeamMemberCard = (account, index, editing) => {
    const { classes } = this.props;

    // show card without controls while editing is going on
    if (editing)
      return (
        <ContactCardSmall
          key={index}
          {...account}
          showEmail={true}
          showSocial={false}
        />
      );

    return (
      <div className={classes.cardContainer} key={index}>
        <div className={classes.controls}>
          <OutlineButton onClick={this.editAccount(index)}>
            <EditIcon className={classes.icon} />
            Edit
          </OutlineButton>
          <OutlineButton
            classes={{ root: classes.deleteButton }}
            onClick={this.deleteAccount(index)}
          >
            <Icons.TrashCanOutline className={classes.iconDelete} />
          </OutlineButton>
        </div>
        <ContactCardSmall {...account} showEmail={true} showSocial={false} />
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { team } = this.state;

    const editing = team.find(member => member.editing);
    const style = team.length ? {} : { marginTop: 30 };

    return (
      <div className={classes.root} style={style}>
        {team.map(this.renderTeamMember(Boolean(editing)))}

        <AddTeamMemberButton
          disabled={Boolean(editing)}
          onClick={this.addTeamMember}
        />
      </div>
    );
  }
}

CompanyTeamForm.propTypes = {
  company: PropTypes.shape({
    team: PropTypes.array
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

CompanyTeamForm.defaultProps = {
  company: { team: [] }
};

export { CompanyTeamForm, styles };
export default withStyles(styles)(CompanyTeamForm);
