import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import {
  clearValidationError,
  setValidationError
} from "../../Reducers/ValidationErrors";

import BooleanField from "./BooleanField";
import CountrySelector from "./Selectors/CountrySelector";
import TextInputField from "./TextInputField";

class LocationField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
    location: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.number,
      hideMap: PropTypes.bool,
      map: PropTypes.object,
      postcode: PropTypes.string
    }),
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    requiredFields: PropTypes.array.isRequired,
    showDisableLocation: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    clearValidationError: PropTypes.func.isRequired,
    setValidationError: PropTypes.func.isRequired,
    validationErrors: PropTypes.object.isRequired
  };

  static defaultProps = {
    doctype: "program",
    errorMessages: {},
    onBlur: () => {},
    requiredFields: ["city", "country"],
    showDisableLocation: true,
    width: 440
  };

  constructor(props) {
    super(props);

    const location = props.location || {};
    const _hasLocation =
      !props.showDisableLocation || Boolean(Object.keys(location).length);

    this.errorMessages = {
      city: "Provide a city name",
      country: "Provide a country",
      address: "Provide an address",
      postcode: "Provide a postcode",
      ...props.errorMessages
    };

    this.state = {
      _hasLocation,
      address: "",
      city: "",
      country: null,
      hideMap: true,
      map: null,
      postcode: "",
      ...location
    };
  }

  componentWillReceiveProps = nextProps => {
    const { location } = nextProps;

    if (location !== this.props.location) {
      this.setState({ ...location });
    }
  };

  handleBlur = id => () => this.validate(id, true);

  handleChange = id => value => {
    this.setState({ [id]: value }, () => this.validate(id));
  };

  isValid = () => {
    const { requiredFields } = this.props;

    return requiredFields.map(key => this.state[key]).every(v => Boolean(v));
  };

  removeValidationError = id => {
    const validationErrors = { ...this.props.validationErrors };
    delete validationErrors[id];

    if (Object.keys(validationErrors).length) {
      this.props.setValidationError(this.props.id, validationErrors);
    } else {
      this.props.clearValidationError(this.props.id);

      if (this.isValid()) {
        this.props.onChange(this.state);
        this.props.onBlur();
      }
    }
  };

  setValidationError = (id, value) => {
    const validationErrors = { ...this.props.validationErrors, [id]: value };

    this.props.setValidationError(this.props.id, validationErrors);
  };

  validate = (id, setError = false) => {
    const { _hasLocation, ...location } = this.state;

    if (!_hasLocation) {
      // remove location object if _hasLocation switch is disabled
      this.props.onChange(null);
      this.props.onBlur();
    } else {
      const error = this.validateField(id);

      if (!error) {
        this.removeValidationError(id);
        this.props.onChange(location);
      } else if (setError) {
        this.setValidationError(id, error);
      }
    }
  };

  validateField = id => {
    if (this.props.requiredFields.indexOf(id) !== -1 && !this.state[id]) {
      return this.errorMessages[id] || "This field is required";
    }

    return null;
  };

  sharedFieldProps = id => {
    const { validationErrors, width } = this.props;
    const { _hasLocation } = this.state;

    return {
      disabled: !_hasLocation,
      error: Boolean(validationErrors[id]),
      helperText: validationErrors[id],
      onBlur: this.handleBlur(id),
      onChange: this.handleChange(id),
      required: this.props.requiredFields.indexOf(id) !== -1,
      value: this.state[id],
      width: width
    };
  };

  render() {
    const { doctype, showDisableLocation, width } = this.props;
    const { _hasLocation } = this.state;

    return (
      <div>
        {showDisableLocation && (
          <BooleanField
            id="_hasLocation"
            invert={true}
            label={`This ${doctype} has no fixed location`}
            helperText={`Some ${doctype}s may not involve a physical location.`}
            onChange={this.handleChange("_hasLocation")}
            value={Boolean(this.state._hasLocation)}
            width={width}
          />
        )}
        <div style={{ opacity: _hasLocation ? 1 : 0.5 }}>
          <TextInputField
            id="address"
            label="Street Address"
            limit={255}
            {...this.sharedFieldProps("address")}
            rows={4}
            type="text"
          />
          <TextInputField
            id="city"
            label="City"
            {...this.sharedFieldProps("city")}
            limit={30}
            limitErrorText="City name must be less than 30 characters"
            type="text"
          />
          <TextInputField
            id="postcode"
            limit={30}
            label="Post / ZIP Code"
            type="text"
            {...this.sharedFieldProps("postcode")}
          />
          <CountrySelector
            id="country"
            label="Country"
            {...this.sharedFieldProps("country")}
          />
          {/* !hideMap && (
            <TextInputField
              id="map"
              label="TODO: Map"
              disabled={!_hasLocation}
              rows={4}
              onChange={this.handleChange("map")}
              value={map}
              width="440px"
            />
          ) */}
          {/* <BooleanField
            id="hideMap"
            label="Don't show a map"
            onChange={this.handleChange("hideMap")}
            value={Boolean(this.state.hideMap)}
            width={width}
          /> */}
        </div>
      </div>
    );
  }
}

export { LocationField };

const mapStateToProps = state => ({ validationErrors: state.validationErrors });
const mapDispatchToProps = dispatch => {
  return {
    clearValidationError: id => dispatch(clearValidationError(id)),
    setValidationError: (id, value) => dispatch(setValidationError(id, value))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationField);
