import React from "react";
import { PropTypes } from "prop-types";

import { responseJsonOrError } from "../../../api/response";

import Banner from "../../common/Banner";
import LocalLink from "../../common/LocalLink";
import SpinnerButton from "../../common/SpinnerButton";

class ResetPasswordPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({ token: PropTypes.string.isRequired }).isRequired
    }).isRequired
  };

  _redirectTimeout = null;

  state = {
    error: null,
    loading: false,
    success: false
  };

  componentWillUnmount() {
    if (this._redirectTimeout) {
      clearTimeout(this._redirectTimeout);
    }
  }

  onSubmit = () => {
    this.setState({ loading: true }, this._submit);
  };

  _submit = () => {
    const { token } = this.props.match.params;
    const body = JSON.stringify({
      restore_token: token.replace("restore_token=", "")
    });

    fetch("/api/users/account/restore/", {
      method: "PUT",
      body,
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => responseJsonOrError(response))
      .then(json => {
        const { error, ...other } = json;
        if (error) {
          this.setState({ loading: false, error });
        } else {
          this.setState(
            { loading: false, success: true, ...other },
            this.setRedirectTimeout
          );
        }
      })
      .catch(error => this.setState({ loading: false, error }));
  };

  setRedirectTimeout = () => {
    if (!this._redirectTimeout) {
      this._redirectTimeout = setTimeout(
        () => this.props.history.push("/signin"),
        5000
      );
    }
  };

  renderButton = () => {
    const { classes } = this.props;
    const { error, loading, success } = this.state;

    if (error || success) return null;

    return (
      <SpinnerButton
        classes={{
          root: classes.button,
          disabled: classes.disabled,
          label: classes.buttonLabel
        }}
        variant="raised"
        disabled={Boolean(error)}
        onClick={this.onSubmit}
        spinner={Boolean(loading)}
      >
        Restore account
      </SpinnerButton>
    );
  };

  renderSuccess = () => {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.success}>Your account has been restored.</div>
        <div className="body1" style={{ marginTop: 15, maxWidth: 330 }}>
          You can continue to{" "}
          <LocalLink label="sign in to Colinked." link="/signin" />
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { error, success } = this.state;

    return (
      <div>
        <Banner title="" classes={{ root: classes.banner }} />
        <div className={classes.root}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div className={classes.formContainer}>
              <div className={classes.formTitle}>Restore your account</div>
              {!success &&
                !error && (
                  <div
                    className="body1"
                    style={{ marginTop: 15, maxWidth: 330 }}
                  >
                    Please click the button below to have your account restored.
                  </div>
                )}

              {success && this.renderSuccess()}

              {error && (
                <div className={classes.error}>Error: {error.message}</div>
              )}

              {this.renderButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordPage;
