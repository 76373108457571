export const guestLinks = [
  { label: "About", link: "/about" },
  { label: "For Corporates", link: "/forcorporates" },
  { label: "For Founders", link: "/forfounders" },
  { label: "For Investors", link: "/forinvestors" },
  { label: "Programs", link: "/programs" }
  // { label: "Marketplace", link: "/marketplace" }
];

export const memberLinks = [
  { label: "Dashboard", link: "/dashboard" },
  { label: "Founders", link: "/startups" },
  { label: "Corporates", link: "/corporations" },
  { label: "My Connections", link: "/connections" },
  { label: "Programs", link: "/programs" },
  { label: "Think Tank", link: "/challenges" }
  // { label: "Marketplace", link: "/marketplace" }
];

export const profileLinks = company => [
  { label: "My User Profile", link: "/myprofile/edit" },
  { label: "My Company Profile", link: company.link },
  { label: "Manage Team", link: "/mycompany/edit#team" }
];
