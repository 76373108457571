import React from "react";
import { connect } from "react-redux";

import FormTagsFieldContainer from "./Tags/FormTagsFieldContainer";

const MarketSelector = props => {
  const { dispatch, ...other } = props;

  return <FormTagsFieldContainer {...other} />;
};

MarketSelector.defaultProps = {
  label: "Please select your markets"
};

const mapStateToProps = state => {
  return {
    options: state.countries
  };
};

export default connect(mapStateToProps)(MarketSelector);
