import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";

import IconClose from "@material-ui/icons/Close";
import IconError from "@material-ui/icons/Error";
import IconInfo from "@material-ui/icons/Info";
import IconQuestion from "@material-ui/icons/Help";
import IconWarning from "@material-ui/icons/Warning";

import {
  getPrimaryButtonClasses,
  getOutlineButtonClasses
} from "../../../styles/buttonStyles";
import { GREEN } from "../../../styles/colors";

import styles from "./styles";

const primaryStyles = getPrimaryButtonClasses(GREEN, "#ffffff", "medium");
const ButtonPrimary = withStyles(primaryStyles)(props => {
  const { classes, children, ...other } = props;
  const { iconClass, ...buttonClasses } = classes;

  return (
    <Button classes={buttonClasses} {...other}>
      {children}
    </Button>
  );
});

const secondaryStyles = getOutlineButtonClasses();
const ButtonSecondary = withStyles(secondaryStyles)(props => {
  const { classes, children, ...other } = props;
  const { iconClass, ...buttonClasses } = classes;

  return (
    <Button classes={buttonClasses} {...other}>
      {children}
    </Button>
  );
});

class ModalDialog extends React.Component {
  constructor(props) {
    super(props);

    this.id = `id_${Math.random()}`;
    this.contentRef = React.createRef();

    this.state = {
      _height: props.height,
      open: true
    };
  }

  componentDidMount() {
    // state update will trigger calculation of content height
    this.setState({ __forceHeightUpdate: true });
  }

  componentDidUpdate() {
    const content = document.getElementById(this.id);
    const height = content && content.offsetHeight;

    if (height && height !== this.state._height) {
      this.setState({ _height: height });
    }
  }

  handleClick = func => () => {
    this.setState({ open: false }, () => func());
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ open: false }, () => {
      if (onClose) onClose(this.state.status);
    });
  };

  renderCancelButton = () => {
    const { classes } = this.props;

    return (
      <div className={classes.buttonCancel} onClick={this.handleClose}>
        <IconClose />
      </div>
    );
  };

  renderButton = button => {
    // const { classes } = this.props;
    const Button = button.primary ? ButtonPrimary : ButtonSecondary;

    return (
      <Button key={button.label} onClick={this.handleClick(button.onClick)}>
        {button.label}
      </Button>
    );
  };

  renderButtons = () => {
    const { actions, classes } = this.props;

    return (
      <div className={classes.buttons}>
        {actions.slice(0, actions.length - 1).map(this.renderButton)}
        <div style={{ flexGrow: 1 }} />
        {this.renderButton(actions[actions.length - 1])}
      </div>
    );
  };

  renderTitle = () => {
    const { classes, title, type } = this.props;

    let Icon = IconInfo;
    let style = {};

    if (type === "error") {
      Icon = IconError;
      style = { color: "#DB6E53" };
    } else if (type === "question") {
      Icon = IconQuestion;
    } else if (type === "warning") {
      Icon = IconWarning;
      style = { color: "#DB6E53" };
    }

    return (
      <div className={classes.title} style={style}>
        <Icon className={classes.icon} />
        {title}
      </div>
    );
  };

  render() {
    const { classes, message, width } = this.props;
    const height = this.state._height || this.props.height;

    const marginTop = `-${(height + 60) / 2}px`;
    const marginLeft = `-${(width + 60) / 2}px`;
    const containerHeight = height - 49; // title=19, title-margin=30

    return (
      <Modal open={this.state.open} onClose={this.handleClose}>
        <div
          className={classes.paper}
          style={{ height, width, marginTop, marginLeft }}
        >
          <div id={this.id} ref={this.contentRef}>
            {this.renderTitle()}
            <div
              className={classes.container}
              style={{ _height: containerHeight }}
            >
              <div className={classes.message}>{message}</div>
              {this.renderButtons()}
            </div>
          </div>
          {this.renderCancelButton()}
        </div>
      </Modal>
    );
  }
}

ModalDialog.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      primary: PropTypes.bool
    })
  ).isRequired,
  height: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["question", "info", "warning", "error"]).isRequired,
  width: PropTypes.number.isRequired
};

ModalDialog.defaultProps = {
  actions: [],
  height: 350,
  width: 390
};

export { ModalDialog, styles };
export default withStyles(styles)(ModalDialog);
