import * as api from "./api";

export const SET_MY_APPLICATIONS = "SET_MY_APPLICATIONS";

const setMyApplications = applications => {
  return {
    type: SET_MY_APPLICATIONS,
    payload: applications
  };
};

export const loadMyApplications = () => {
  return dispatch => {
    api.loadMyApplications().then(json => {
      const { error, results } = json;
      if (error) {
        //TODO: set error state
      } else if (results) {
        Promise.all(results.map(api.loadApplicationDetails)).then(
          applications => {
            dispatch(setMyApplications(applications));
          }
        );
      }
    });
  };
};

export const resetMyApplications = () => {
  return dispatch => {
    dispatch(setMyApplications([]));
  };
};

export const updateMyApplications = () => {
  return dispatch => {};
};
