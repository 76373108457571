import { responseJsonOrError } from "../../api/response";

import { beautifyValues } from "./utils";

export const UPDATE_CONFIG = "UPDATE_CONFIG";

const CONFIG_MODULES = [
  "global",
  "companies",
  "countries",
  "featured_programs",
  "programs",
  "referrals",
  "think_tanks"
];

const updateConfig = data => {
  return {
    type: UPDATE_CONFIG,
    payload: data
  };
};

export const loadConfig = () => {
  return dispatch => {
    CONFIG_MODULES.forEach(module => {
      const url = `/api/${module}/config/`;

      fetch(url)
        .then(response => responseJsonOrError(response))
        .then(json => {
          const module_config = Object.keys(json).reduce((config, key) => {
            const newKey = `${module}__${key}`;
            config[newKey] = beautifyValues(newKey, json[key]);

            return config;
          }, {});
          dispatch(updateConfig(module_config));
        })
        .catch(error => {
          if (process.env.NODE_ENV !== "test") console.log(error);
        });
    });
  };
};
