import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

import * as testData from "./testData";

export const approveDeclineApplication = (app, action, data) => {
  const apiUrl = app.url.endsWith("/")
    ? `${app.url}${action}/`
    : `${app.url}/${action}/`;

  // make individual API request
  return fetchWithToken(apiUrl, {
    body: JSON.stringify(data),
    method: "PUT"
  })
    .then(response => responseJsonOrError(response))
    .then(json => {
      const is_approved = json.error
        ? app.is_approved
        : action === "approve"
        ? 2
        : 0;

      return {
        ...app,
        ...json,
        is_approved,
        updated_at: new Date().toISOString()
      };
    });
};

const delayedPromise = data => {
  const delay = process.env.PROMISE_DELAY || 2000;

  return new Promise(resolve => setTimeout(() => resolve(data), delay));
};

const addApplicationUrl = documentUrl => app => ({
  ...app,
  url: `${documentUrl}/participants/${app.id}/`
});

export const fetchApplications = fetchUrl => {
  // strip query params from applications list url
  const documentUrl = fetchUrl
    .split("/")
    .slice(0, 4)
    .join("/");

  return fetchWithToken(fetchUrl)
    .then(responseJsonOrError)
    .then(json => {
      // inject application url into app object
      const applications = (json.results || []).map(
        addApplicationUrl(documentUrl)
      );

      return { ...json, applications };
    });
};

export const exportApplications = (id, type) => {
  const _type = type === "program" ? "programs" : "think_tanks";
  const url = ["/api", _type, id, "participants/export/"].join("/");

  return fetchWithToken(url)
    .then(response => {
      if (!response.ok)
        return Promise.reject(new Error("Oops! Something went wrong."));

      return response.text();
    })
    .then(data => ({ data }))
    .catch(error => ({ error }));
};

export const fetchApplicationByUrl = url => {
  if (process.env.REACT_APP_MOCKAPI) {
    console.warn(`TODO: remove test data (url=${url})`);

    return delayedPromise(testData.details[url] || {});
  }

  return fetchWithToken(url)
    .then(response => responseJsonOrError(response))
    .catch(error => error);
};
