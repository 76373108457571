import React from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import { SecondaryButton } from "../../common/Buttons";

import ConnectionStatus from "./ConnectionStatus";
import PageLink from "./PageLink";

const styles = {
  root: {
    height: 80,
    backgroundColor: "white"
  },
  container: {
    height: 80,
    display: "flex",
    alignItems: "center"
  }
};

class ActionMenu extends React.Component {
  state = {
    active: "#pitch_or_ethos"
  };

  render() {
    const { classes, bodyWidth, company, history, profile } = this.props;

    const bodyStyle = bodyWidth ? { width: bodyWidth } : {};
    const button =
      profile.company === company.id ? (
        <SecondaryButton onClick={() => history.push("/mycompany/edit")}>
          Edit Profile
        </SecondaryButton>
      ) : (
        <ConnectionStatus company={company} />
      );

    const pageLinks = company.is_founder
      ? [
          { label: "Pitch", anchor: "#pitch_or_ethos" },
          { label: "About", anchor: "#company_details" },
          { label: "Funding", anchor: "#finance_or_team" }
        ]
      : [
          { label: "Ethos", anchor: "#pitch_or_ethos" },
          { label: "About", anchor: "#company_details" },
          { label: "Team", anchor: "#finance_or_team" }
        ];

    return (
      <div className={classes.root}>
        <div className={`body ${classes.container}`} style={bodyStyle}>
          {pageLinks.map(link => (
            <PageLink
              key={link.label}
              {...link}
              active={link.anchor === this.state.active}
              onClick={() => this.setState({ active: link.anchor })}
            />
          ))}
          <div style={{ flexGrow: 1 }} />

          {button}
        </div>
      </div>
    );
  }
}

ActionMenu.propTypes = {
  bodyWidth: PropTypes.number,
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  profile: PropTypes.object.isRequired
};

export { ActionMenu, styles };
export default withRouter(withStyles(styles)(ActionMenu));
