import React from "react";
import { PropTypes } from "prop-types";

import DesktopLayout from "./Layouts/DesktopLayout";
import DesktopLayoutMedium from "./Layouts/DesktopLayoutMedium";
import MobileLayout from "./Layouts/MobileLayout";

const ProfileView = props => {
  const { width } = props;

  if (width === "sm" || width === "xs") {
    return <MobileLayout {...props} />;
  }

  if (width === "md") {
    return <DesktopLayoutMedium {...props} />;
  }

  return <DesktopLayout {...props} />;
};

ProfileView.propTypes = {
  company: PropTypes.object.isRequired,
  programs: PropTypes.array.isRequired,
  challenges: PropTypes.array.isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

ProfileView.defaultProps = {
  programs: [],
  challenges: []
};

export default ProfileView;
