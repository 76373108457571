import { fetchWithToken } from "../../../api";
import { responseJsonOrError } from "../../../api/response";

export const getAccountActions = (account, update, state) => {
  const primaries = state.team.filter(account => account.is_primary_contact);
  const actions = [];

  // only option for archived accounts: restore
  if (account.is_archived) {
    return [
      {
        label: "restore account",
        action: () => update({ is_archived: false })
      }
    ];
  }

  // mark as primary contact
  if (!account.is_primary_contact) {
    actions.push({
      label: "make primary contact",
      action: () => update({ is_primary_contact: true })
    });
  } else if (primaries.length > 1) {
    actions.push({
      label: "remove as primary contact",
      action: () => update({ is_primary_contact: false })
    });
  }

  // show/hide in company profile
  if (account.is_public && !account.is_only_admin) {
    actions.push({
      label: "hide in company profile",
      action: () => update({ is_public: false })
    });
  } else {
    actions.push({
      label: "show in company profile",
      action: () => update({ is_public: true })
    });
  }

  // archive/delete only if it's not the last company admin
  if (!account.is_only_admin) {
    actions.push({
      label: "archive account",
      action: () => update({ is_archived: true })
    });
  }

  return actions;
};

export const updateUser = (url, data) => {
  const options = {
    method: "PUT",
    body: JSON.stringify(data)
  };

  return fetchWithToken(url, options).then(responseJsonOrError);
};
