import React from "react";
import { PropTypes } from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import InfoIcon from "@material-ui/icons/Info";

import imageSubmissionComplete from "../../../assets/images/homepage/colinked-form-submission-complete-icon.svg";

import { fetchWithToken } from "../../../api";
import { responseJsonOrError } from "../../../api/response";
import { GREY_MEDIUM } from "../../../styles/colors";

import TextInputField from "../../Form/TextInputField";
import { validateEmail } from "../../Form/validate";
import { PrimaryButtonLargeGreen } from "../../common/Buttons";

const styles = {
  root: {
    padding: 30
  },
  buttonRoot: {
    marginLeft: 0,
    width: "100%"
  },
  inputLabel: {
    fontSize: 15
  },
  infoIcon: {
    fontSize: 20,
    marginRight: 5
  },
  infoText: {
    fontSize: 13,
    fontStyle: "italic",
    lineHeight: "17px",
    marginTop: 2,
    marginBottom: 15
  },
  msgSentRoot: {
    padding: 30,
    textAlign: "center"
  },
  msgSentHeader: {
    color: GREY_MEDIUM,
    fontSize: 18,
    fontWeight: 700,
    padding: 0
  }
};

const MessageSent = props => {
  const { classes } = props;

  return (
    <div className={classes.msgSentRoot}>
      <img
        style={{ marginTop: 20, marginBottom: 20 }}
        src={imageSubmissionComplete}
        alt="Colinked - Request sent"
      />

      <div className={classes.msgSentHeader}>Your message has been sent!</div>
    </div>
  );
};

class MessagePanel extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.string,
    profile: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      email: props.profile.username || "",
      message: props.message || "",
      messageSent: false
    };
  }

  disableButton = () => {
    const { message, email } = this.state;

    if (!message) return true;

    // validateEmail returns a string for invalid emails, otherwise null
    if (validateEmail(email)) return true;

    return false;
  };

  submit = () => {
    const { profile, url } = this.props;
    const { message } = this.state;
    const email = profile.username || this.state.email;

    fetchWithToken(url, {
      body: JSON.stringify({ message, email, company: 69 }),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(responseJsonOrError)
      .then(json => {
        const messageSent = !json.error;

        this.setState({ ...json, pending: false, messageSent });
      });
  };

  renderEmailInputOrInfo = () => {
    const { classes, profile } = this.props;

    if (profile.username)
      return (
        <div style={{ display: "flex", alignItems: "top", marginTop: 3 }}>
          <InfoIcon className={classes.infoIcon} />
          <p className={classes.infoText}>
            {`A reply to your enquiry will be sent ${profile.username}.`}
          </p>
        </div>
      );

    return (
      <TextInputField
        id="email"
        label="Provide your email address"
        onChange={email => this.setState({ email })}
        onBlur={() => {}}
        limit={80}
        value={this.state.email}
        width="100%"
        style={{ marginTop: 10, marginBottom: 20 }}
        type="text"
        classes={{ label: classes.inputLabel }}
      />
    );
  };

  render() {
    const { classes } = this.props;

    if (this.state.messageSent) {
      return <MessageSent classes={classes} />;
    }

    return (
      <div className={classes.root}>
        <TextInputField
          id="message"
          label="Send a message with your enquiry"
          onChange={message => this.setState({ message })}
          onBlur={() => {}}
          limit={500}
          rows={5}
          value={this.state.message}
          width="100%"
          style={{ marginTop: 0 }}
          type="text"
          classes={{ label: classes.inputLabel }}
        />

        {this.renderEmailInputOrInfo()}

        <PrimaryButtonLargeGreen
          classes={{ root: classes.buttonRoot }}
          disabled={this.disableButton()}
          onClick={this.submit}
        >
          Enquire Now
        </PrimaryButtonLargeGreen>
      </div>
    );
  }
}

export { styles, MessagePanel };
export default withStyles(styles)(MessagePanel);
