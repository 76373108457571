import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import PublishButton from "../../common/Buttons/PublishButton";

const styles = {
  root: {
    backgroundColor: "white",
    height: 80
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 80,
    width: 1160,
    margin: "0 auto"
  }
};

const getStyle = width => {
  if (width === "md") return { width: 1000 };
  if (width === "sm") return { width: "90vw" };

  return {};
};

const UserProfileEditorMenu = props => {
  const { classes, document, handleUpdate, menuId, width } = props;
  const disabled = !Boolean(document._isValid && document._hasUnsavedChanges);

  return (
    <div className={classes.root}>
      <div className={classes.container} style={getStyle(width)} id={menuId}>
        <PublishButton
          label="Update"
          onClick={handleUpdate}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

UserProfileEditorMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.shape({
    doctype: PropTypes.string.isRequired,
    _hasUnsavedChanges: PropTypes.bool,
    _isUnsavedDraft: PropTypes.bool,
    isCompleted: PropTypes.bool,
    is_approved: PropTypes.number.isRequired
  }).isRequired,
  handleUpdate: PropTypes.func,
  id: PropTypes.string.isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

UserProfileEditorMenu.defaultProps = {
  document: {
    doctype: "",
    is_approved: 1
  },
  id: "formbuilder-menu"
};

export { UserProfileEditorMenu, styles };
export default withStyles(styles)(UserProfileEditorMenu);
