import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import * as colors from "../../styles/colors";

import DashboardLink from "./DashboardLink";
import DesktopLinks from "./DesktopLinks";
import MobileLinks from "./MobileLinks";
import NavigationLogo from "./NavigationLogo";
import ProfileButton from "./ProfileButton";
import SignInButton from "./SignInButton";

import { profileLinks } from "./links";

const styles = {
  root: {
    backgroundColor: "white",
    paddingTop: 0
  },
  container: {
    display: "flex",
    alignItems: "center",
    height: 70
  },
  containerBottom: {
    backgroundColor: "white",
    borderTop: `1px solid ${colors.GREY_LIGHT}`
  }
};

const renderBottomRowLinks = props => {
  const { classes, profile, width } = props;

  if (width === "xs" || width === "sm") return null;

  if (!profile.isSignedIn && width !== "md") return null;

  return (
    <div className={classes.containerBottom}>
      <div className={`body ${classes.container}`}>
        <DesktopLinks />
      </div>
    </div>
  );
};

const renderProfileButton = props => {
  const { classes, company, history, ...buttonProps } = props;

  const actions = profileLinks(company)
    .filter(link => link.link)
    .map(link => ({
      ...link,
      action: () => history.push(link.link)
    }));

  return props.profile.isSignedIn ? (
    <ProfileButton {...buttonProps} actions={actions} history={history} />
  ) : (
    <SignInButton />
  );
};

const renderTopRowMenu = props => {
  const { profile, width } = props;

  if (width === "xs" || width === "sm") {
    return (
      <React.Fragment>
        <DashboardLink />
        <SignInButton />
        <MobileLinks />
      </React.Fragment>
    );
  }

  if (width === "md" && !profile.isSignedIn) {
    return <SignInButton />;
  }

  if (profile.isSignedIn) {
    return (
      <React.Fragment>
        <DashboardLink />
        {renderProfileButton(props)}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <DesktopLinks />
      <SignInButton />
    </React.Fragment>
  );
};

const Navigation = props => {
  const { classes, history, width } = props;

  return (
    <React.Fragment>
      <div id="Nav" className={classes.root}>
        <div className={`body ${classes.container}`}>
          <NavigationLogo history={history} width={width} />
          <div style={{ flexGrow: 1 }} />
          {renderTopRowMenu(props)}
        </div>
      </div>
      {renderBottomRowLinks(props)}
    </React.Fragment>
  );
};

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number,
    membership: PropTypes.string.isRequired
  }).isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

export default withStyles(styles)(Navigation);
