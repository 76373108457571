import React from "react";
import PropTypes from "prop-types";

import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";

import formStyles from "../../../styles/formStyles";

const generateIcon = data => props => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      {data}
    </SvgIcon>
  );
};

const checkIcons = {
  multiple: {
    selected: generateIcon(
      <path d="M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,19H5V5H19V19M17,17H7V7H17V17Z" />
    ),
    unselected: generateIcon(
      <path d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
    )
  },
  single: {
    selected: generateIcon(
      <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z" />
    ),
    unselected: generateIcon(
      <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
    )
  }
};

const styles = {
  root: {
    // outline: "1px solid red",
    marginTop: 20,
    marginBottom: 10
  },
  container: {
    margin: "10px 0px",
    cursor: "pointer"
  },

  icon: {
    fill: "#AAADB6",
    fontSize: 20,
    marginRight: 10
  },
  multiLabel: {
    fontSize: 13,
    fontStyle: "italic",
    fontWeight: 300
  },
  optionRow: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    height: 30
  },
  ...formStyles
};

const toggleOption = (option, props) => () => {
  const { onChange, response, type } = props;

  const selected = response.indexOf(option) !== -1;
  let value = response;

  if (type.startsWith("single")) {
    value = selected ? [] : [option];
  } else {
    value = selected
      ? response.filter(_opt => _opt !== option)
      : [...response, option];
  }

  onChange(value);
};

const renderOption = props => (option, index) => {
  const { classes, response, type } = props;

  const icons = type.startsWith("single")
    ? props.icons.single
    : props.icons.multiple;

  const selected = response.indexOf(option) !== -1;
  const Icon = selected ? icons.selected : icons.unselected;
  const iconStyle = selected ? { fill: "#6592CC" } : {};

  return (
    <div
      className={classes.optionRow}
      key={index}
      onClick={toggleOption(option, props)}
    >
      <Icon className={classes.icon} style={iconStyle} />
      <div>{option}</div>
    </div>
  );
};

const ApplicationSelectForm = props => {
  const {
    classes,
    error,
    helperText,
    is_optional,
    label: _label,
    type,
    options,
    width
  } = props;

  const label = is_optional ? _label : `${_label} (required)`;

  const textStyle = error ? { color: "#DB6E53" } : {};

  return (
    <div className={classes.root} style={{ width }}>
      {label ? (
        <div className={classes.label} style={textStyle}>
          {label}
        </div>
      ) : null}
      {type === "multiple_choice_input" ? (
        <div className={classes.multiLabel} style={textStyle}>
          Select all that apply
        </div>
      ) : null}
      <div className={classes.container}>
        {options.map(renderOption(props))}
      </div>
      {helperText ? (
        <div className={classes.helperText} style={textStyle}>
          {helperText}
        </div>
      ) : null}
    </div>
  );
};

ApplicationSelectForm.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(["multiple_choice_input", "single_choice_input"])
    .isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ApplicationSelectForm.defaultProps = {
  classes: {},
  icons: checkIcons,
  response: [],
  width: "100%",
  options: []
};

export default withStyles(styles)(ApplicationSelectForm);
