import React from "react";
import { PropTypes } from "prop-types";

import { updatePassword } from "../../../Reducers/Profile/api";

import { LinkButtonLargeRed, PrimaryButtonLarge } from "../../common/Buttons";

import TextInputField from "../../Form/TextInputField";
import { validatePassword } from "../../Form/validate";

const formFields = [
  {
    id: "password",
    label: "Current Password",
    helperText: "Please confirm your current password."
  },
  {
    id: "new_password",
    label: "New Password",
    helperText: "must be at least 9 characters long"
  },
  {
    id: "confirm_password",
    label: "Confirm New Password",
    helperText: "must match new password"
  }
];

const getInitialState = formFields => {
  const state = formFields.reduce((state, field) => {
    state[field.id] = "";

    return state;
  }, {});

  return { ...state, validationErrors: {} };
};

class UpdatePasswordForm extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    createFlashNote: PropTypes.func.isRequired,
    formFields: PropTypes.array.isRequired
  };

  static defaultProps = {
    formFields
  };

  constructor(props) {
    super(props);

    this.state = getInitialState(props.formFields);
  }

  checkPassword = (id, value) => {
    const error = validatePassword(value);
    if (error) return error;

    if (id !== "confirm_password") return null;

    if (this.state.new_password !== this.state.confirm_password) {
      return "new password and confirmation do not match";
    }
  };

  getFormData = () => {
    const { formFields } = this.props;

    return formFields.reduce((data, field) => {
      data[field.id] = this.state[field.id];

      return data;
    }, {});
  };

  isComplete = () => {
    const { formFields } = this.props;

    return formFields.every(field => {
      const value = this.state[field.id];

      return value && !this.checkPassword(field.id, value);
    });
  };

  onBlur = id => () => {
    const error = this.checkPassword(id, this.state[id]);

    if (error) {
      this.setValidationError(id, error);
    } else {
      this.removeValidationError(id);
    }
  };

  onChange = id => value => {
    if (!this.checkPassword(id, value)) {
      this.removeValidationError(id);
    }

    this.setState({ [id]: value });
  };

  removeValidationError = id => {
    const validationErrors = { ...this.state.validationErrors };
    delete validationErrors[id];

    this.setState({ validationErrors });
  };

  setInitialState = () => {
    const state = getInitialState(this.props.formFields);

    this.setState(state);
  };

  setValidationError = (id, error) => {
    const validationErrors = { ...this.state.validationErrors };
    validationErrors[id] = error;

    this.setState({ validationErrors });
  };

  updatePassword = () => {
    const formData = this.getFormData();

    return updatePassword(formData)
      .then(json => {
        const { error, validationErrors } = json;

        if (error) {
          this.props.createFlashNote(error.message, "error");
          this.setState({
            validationErrors
          });
        } else {
          this.props.createFlashNote(
            "Your password has been updated.",
            "success"
          );

          this.setInitialState();
        }
      })
      .catch(error => {
        this.props.createFlashNote(error.message, "error");
      });
  };

  renderButtons = () => {
    const { classes } = this.props;
    const { validationErrors } = this.state;
    const disabled =
      !this.isComplete() || Boolean(Object.keys(validationErrors).length);

    return (
      <div className={classes.buttons}>
        <PrimaryButtonLarge disabled={disabled} onClick={this.updatePassword}>
          Update Password
        </PrimaryButtonLarge>
        <LinkButtonLargeRed onClick={this.setInitialState}>
          Cancel
        </LinkButtonLargeRed>
      </div>
    );
  };

  renderTextField = ({ id, label, helperText }) => {
    const value = this.state[id];
    const validationError = this.state.validationErrors[id];

    return (
      <TextInputField
        key={id}
        id={id}
        error={Boolean(validationError)}
        helperText={validationError || helperText}
        label={label}
        limit={80}
        limitErrorText={"Must be less than 80 characters."}
        onBlur={this.onBlur(id)}
        onChange={this.onChange(id)}
        required={true}
        type={"password"}
        value={value}
        width={440}
      />
    );
  };

  render() {
    const { formFields } = this.props;

    return (
      <div>
        {formFields.map(this.renderTextField)}
        {this.renderButtons()}
      </div>
    );
  }
}

export default UpdatePasswordForm;
