import React from "react";

import FeaturedProgramCard from "./FeaturedProgramCard";
import { responseJsonOrError } from "../../api/response";

class FeaturedPrograms extends React.Component {
  is_unmounting = false;
  state = {
    featured: []
  };

  componentDidMount() {
    fetch("/api/programs/featured/")
      .then(responseJsonOrError)
      .then(json => {
        const { error, results } = json;

        return error ? [] : results;
      })
      .then(campaigns => {
        return campaigns.map(campaign => ({
          ...campaign.program,
          campaign_image_url: campaign.image_url
        }));
      })
      .then(featured => {
        if (featured.length && !this.is_unmounting) {
          this.setState({ featured });
        }
      });
  }

  componentWillUnmount() {
    this.is_unmounting = true;
  }

  render() {
    const { featured } = this.state;
    const style =
      featured.length === 4 ? { justifyContent: "space-between" } : {};

    if (!featured.length) return null;

    return (
      <section className="programs">
        <div className="title">
          <h2>Featured Programs</h2>
        </div>
        <section className="featured-programs" style={style}>
          {featured.map((program, index) => (
            <FeaturedProgramCard key={index} program={program} />
          ))}
        </section>
      </section>
    );
  }
}

export default FeaturedPrograms;
