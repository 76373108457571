import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import InputFieldWrapper from "../Form/InputFieldWrapper";

const styles = {
  root: {
    backgroundColor: "white",
    width: 800,
    margin: 0

    //paddingTop: 40
  },
  pageIndex: {
    color: "#AAADB6",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10
  },
  section: {
    //outline: "1px solid red",
    borderBottom: "1px solid #E6E7E9",
    padding: 30,
    // paddingBottom: 20,
    "&:last-child": {
      borderBottom: "none"
    }
  },
  sectionContainer: {
    padding: 0,
    borderBottom: "1px solid #E6E7E9",
    "&:last-child": {
      borderBottom: "none"
    }
  },
  sectionTitle: {
    color: "#2A2B2C",
    fontSize: 16,
    fontWeight: 700
  }
};

const groupInSections = fields => {
  if (!fields.length) return [[]];

  const _fields = [...fields];
  const sections = [];

  let section = [_fields.shift()];
  for (let field of _fields) {
    if (field.type === "header" || field.section === true) {
      sections.push(section);
      section = [field];
    } else {
      section.push(field);
    }
  }

  if (section.length) sections.push(section);

  return sections;
};

const renderField = (field, props) => {
  const {
    handleBlur,
    handleChange,
    state,
    setActivePage,
    validationErrors
  } = props;
  const { section, ...fieldSpec } = field;

  return (
    <InputFieldWrapper
      key={field.id}
      id={field.id}
      errors={validationErrors}
      fieldSpec={fieldSpec}
      handleChange={handleChange}
      handleBlur={handleBlur}
      state={state}
      setActivePage={setActivePage}
    />
  );
};

const renderPageIndex = props => {
  const { classes, index } = props;

  if (!index) return null;

  return <div className={classes.pageIndex}>{index}</div>;
};

const renderSection = props => (section, index) => {
  const { classes } = props;

  if (section.length && section[0].section === true) {
    return (
      <div className={classes.sectionContainer} key={index}>
        {section.map(field => renderField(field, props))}
      </div>
    );
  }

  return (
    <div className={classes.section} key={index}>
      {index === 0 && renderPageIndex(props)}
      {/* error && <ErrorPanelSmall error={error} /> */}
      {/* renderValidationErrors(props) */}
      {section.map(field => renderField(field, props))}
    </div>
  );
};

const FormBuilderForm = props => {
  const { children, classes, formFields, width } = props;

  const style = width === "md" ? { width: 730 } : {};

  const sections = groupInSections(formFields);

  return (
    <div className={classes.root} style={style}>
      {sections.map(renderSection(props))}
      {children}
    </div>
  );
};

const fieldTypeProp = PropTypes.shape({
  id: PropTypes.string.isRequired,
  fieldSpec: PropTypes.object
});

FormBuilderForm.propTypes = {
  classes: PropTypes.object.isRequired,
  formFields: PropTypes.arrayOf(fieldTypeProp).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  index: PropTypes.string,
  nextButtonLabel: PropTypes.string.isRequired,
  setActivePage: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  validationErrors: PropTypes.object.isRequired,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired
};

FormBuilderForm.defaultProps = {
  formFields: [],
  nextButtonLabel: "Next",
  validationErrors: {}
};

export { FormBuilderForm, styles };
export default withStyles(styles, { name: "FormBuilderForm" })(FormBuilderForm);
