import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import IconArchive from "@material-ui/icons/Archive";
import IconBlock from "@material-ui/icons/Block";
import IconPen from "@material-ui/icons/Create";
import IconPublish from "@material-ui/icons/Publish";
import IconSettings from "@material-ui/icons/Settings";
import IconUnarchive from "@material-ui/icons/Unarchive";

import {
  BLUE,
  BODY_FONT_BLACK,
  GREY_MEDIUM,
  RED
} from "../../../styles/colors";
import TrashCanIcon from "../../Icons/TrashCanOutline";

import TagLabel from "../../common/TagLabel";

const rowStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: 14,

  borderBottom: "1px solid #E6E7E9"
};

const linkStyle = {
  ...rowStyle,
  color: BLUE,
  height: 40,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
};

const styles = {
  root: {
    backgroundColor: "white"
  },
  icon: {
    marginRight: 10,
    fill: BODY_FONT_BLACK
  },
  iconDelete: {
    marginRight: 10,
    fill: RED
  },
  title: {
    ...rowStyle,
    fontWeight: 700,
    paddingBottom: 20
  },
  link: {
    ...linkStyle
  },
  linkDelete: {
    ...linkStyle,
    color: RED
  },
  linkDisabled: {
    ...rowStyle,
    color: GREY_MEDIUM,
    height: 40
  },
  linksList: {
    margin: 0,
    padding: 0
  }
};

class ServiceLinks extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    createFlashNote: PropTypes.func.isRequired,
    document: PropTypes.shape({
      doctype: PropTypes.oneOf(["service"]).isRequired
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    updateDocument: PropTypes.func.isRequired
  };

  static defaultProps = {
    deleteDocument: () => console.log("TODO: deleteDocument")
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  archiveDocument = () => {
    const { document } = this.props;

    this.props.updateDocument({ id: document.id, is_approved: 0 });
  };

  confirmDelete = () => {
    const { deleteDocument, document, history } = this.props;

    if (
      window.confirm(`Do you really want to delete '${document.description}'?`)
    ) {
      deleteDocument(document.id);
      history.push("/marketplace");
    }
  };

  toggleArchive = () => {
    const { document } = this.props;
    const { is_archived } = document;

    this.props.updateDocument({ ...document, is_archived: !is_archived });
  };

  togglePublish = () => {
    const { document } = this.props;
    const { id, is_approved } = document;

    const value = is_approved === 1 ? 2 : 1;

    this.props.updateDocument({ id, is_approved: value });
  };

  renderBadge = () => {
    const document = this.props.document || {};
    const { is_approved, is_archived } = document;
    const status = ["--", "Draft", "Active"][document.is_approved];
    const color = ["grey", "red", "green"][document.is_approved];

    if (is_archived) return <TagLabel label="Archived" color="grey" />;

    return (
      <TagLabel label={status || ""} color={color} solid={is_approved === 1} />
    );
  };

  render() {
    const { classes, document, history } = this.props;
    const { is_approved, is_archived } = document;

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <IconSettings className={classes.icon} />
          Manage Service
          <div style={{ flexGrow: 1 }} />
          {this.renderBadge()}
        </div>

        <ul className={classes.linksList}>
          <li
            className={classes.link}
            onClick={() => history.push(`/marketplace/${document.id}/edit`)}
          >
            <IconPen className={classes.icon} />
            {`Edit service details`}
          </li>

          {/* publish/withdraw - only if document is not archived */}
          {!is_archived && (
            <li className={classes.link} onClick={this.togglePublish}>
              {is_approved === 1 ? (
                <IconPublish className={classes.icon} />
              ) : (
                <IconBlock className={classes.icon} />
              )}
              {is_approved === 1 ? "Publish" : "Withdraw"}
            </li>
          )}

          {/* archive */}
          <li className={classes.link} onClick={this.toggleArchive}>
            {is_archived ? (
              <IconUnarchive className={classes.icon} />
            ) : (
              <IconArchive className={classes.icon} />
            )}
            {is_archived ? "Restore" : "Archive"}
          </li>

          {/* delete */}
          <li className={classes.linkDelete} onClick={this.confirmDelete}>
            {<TrashCanIcon className={classes.iconDelete} />}
            Delete
          </li>
        </ul>
      </div>
    );
  }
}

export { ServiceLinks, styles };
export default withStyles(styles)(ServiceLinks);
