import { connect } from "react-redux";

import {
  clearValidationError,
  setValidationError
} from "../../../Reducers/ValidationErrors";
import CompanyDetailsForm from "./CompanyDetailsForm";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.id;

  return { validationErrors: state.validationErrors[id] || {} };
};

const mapDispatchToProps = dispatch => {
  return {
    clearValidationError: id => {
      dispatch(clearValidationError(id));
    },
    setValidationError: (id, value) => {
      dispatch(setValidationError(id, value));
    }
  };
};

const CompanyDetailsFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDetailsForm);

export default CompanyDetailsFormContainer;
