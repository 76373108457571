import React from "react";
import { PropTypes } from "prop-types";

import { throttle } from "underscore";

import { withStyles } from "@material-ui/core/styles";

import FormSelectField from "../Selectors/FormSelectField";
import { FormTagsFieldContainer } from "../Tags";
import MarketsSelector from "../MarketsSelector";

import TextInputField from "../TextInputField";
import AddCriteriaButton from "./AddCriteriaButton";
import Controls from "./Controls";

import styles from "./styles";

import { DISPLAY_LABELS, foundingStagesOptions } from "./config";

const propsCriteriaToState = criteria => {
  return criteria.map(c => ({ ...c, key: `key_${Math.random()}` }));
};

class CriteriaSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      criteria: propsCriteriaToState(props.criteria)
    };

    this.updateState = throttle(this._updateState, 400).bind(this);
  }

  addCriteria = label => {
    const criteria = [
      ...this.state.criteria,
      { label, key: `key_${Math.random()}` }
    ];

    this.setState({ criteria });
  };

  deleteCriteria = index => {
    const { criteria } = this.state;

    const before = criteria.slice(0, index);
    const after = criteria.slice(index + 1);

    this.setState(
      {
        criteria: [...before, ...after]
      },
      this.updateState
    );
  };

  moveDown = index => {
    const { criteria } = this.state;

    if (index === criteria.length - 1) return;

    const before = criteria.slice(0, index);
    const after = criteria.slice(index + 2);

    this.setState(
      {
        criteria: [...before, criteria[index + 1], criteria[index], ...after]
      },
      this.updateState
    );
  };

  moveUp = index => {
    const { criteria } = this.state;

    if (index === 0) return;

    const before = criteria.slice(0, index - 1);
    const after = criteria.slice(index + 1);

    const _criteria = [
      ...before,
      criteria[index],
      criteria[index - 1],
      ...after
    ];

    this.setState(
      {
        criteria: _criteria
      },
      this.updateState
    );
  };

  onChange = index => rawContent => {
    const { criteria } = this.state;

    const before = criteria.slice(0, index);
    const after = criteria.slice(index + 1);
    const content = { value: rawContent };

    const section = { ...criteria[index], ...content };
    const _criteria = [...before, section, ...after];

    this.setState({ criteria: _criteria }, this.updateState);
  };

  _updateState = () => {
    // filter out empty selections and text values
    const value = this.state.criteria
      .map(obj => {
        const { label, value } = obj;
        if (!value || !value.length) return null;

        return { label, value };
      })
      .filter(obj => obj);

    this.props.onChange(value);
  };

  renderControls = index => {
    const { classes } = this.props;

    const moveDown =
      index !== this.props.criteria.length - 1
        ? () => this.moveDown(index)
        : null;
    const moveUp = index !== 0 ? () => this.moveUp(index) : null;

    return (
      <Controls
        classes={{ root: classes.controlsRoot }}
        deleteCriteria={() => this.deleteCriteria(index)}
        moveDown={moveDown}
        moveUp={moveUp}
      />
    );
  };

  renderCriterion = (criterion, index) => {
    const { classes } = this.props;

    const { label } = criterion;
    const displayLabel = DISPLAY_LABELS[label] || label;

    return (
      <div key={index} className={classes.row}>
        <div className={classes.type}>
          <div className={classes.label}>Criteria Type</div>
          <div className={classes.selection}>{displayLabel}</div>
        </div>
        {this.renderSelector(criterion, index)}

        {this.renderControls(index)}
      </div>
    );
  };

  renderSelector = (criteria, index) => {
    const { classes } = this.props;
    const { key, label, value } = criteria;

    const selectLabel =
      DISPLAY_LABELS[`${label}_select`] || "Please select ...";

    const selectorClasses = {
      root: classes.formTagsRoot
    };

    if (label === "countries") {
      return (
        <MarketsSelector
          id="countries"
          {...criteria}
          label={selectLabel}
          onChange={this.onChange(index)}
          classes={selectorClasses}
          width={440}
        />
      );
    }

    if (label === "markets") {
      return (
        <FormTagsFieldContainer
          id="markets"
          {...criteria}
          label={selectLabel}
          onChange={this.onChange(index)}
          classes={selectorClasses}
          width={440}
        />
      );
    }

    if (label === "stages") {
      const _value =
        value && value.length
          ? value
              .map(v =>
                foundingStagesOptions.find(option => option.value === v)
              )
              .filter(v => v)
          : [];

      return (
        <FormSelectField
          classes={selectorClasses}
          label={selectLabel}
          onChange={selection => {
            this.onChange(index)(selection.map(s => s.value));
          }}
          options={foundingStagesOptions}
          value={_value}
          width={440}
        />
      );
    }

    if (label === "other") {
      return (
        <TextInputField
          id={key}
          {...criteria}
          classes={{ root: classes.textInput }}
          label={selectLabel}
          onChange={this.onChange(index)}
          style={{ marginTop: 0, marginBottom: 0 }}
          width={440}
        />
      );
    }

    return (
      <div className={classes.value}>
        <div className={classes.label}>{selectLabel}</div>
        <div className={classes.valueInput}>{value}</div>
      </div>
    );
  };

  render() {
    const { classes, width } = this.props;
    const { criteria } = this.state;

    const options = ["countries", "markets", "stages"]
      .filter(option => !criteria.find(c => c.label === option))
      .concat(["other"]);

    return (
      <div className={classes.root} style={{ width }}>
        {criteria.map(this.renderCriterion)}
        <AddCriteriaButton options={options} onClick={this.addCriteria} />
      </div>
    );
  }
}

CriteriaSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  criteria: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

CriteriaSelector.defaultProps = {
  width: "100%"
};

export { CriteriaSelector, styles };
export default withStyles(styles)(CriteriaSelector);
