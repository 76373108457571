import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import * as Pages from "../Pages";

const Routes = props => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/dashboard" component={Pages.Dashboard} />

        {/* Marketplace routes */}
        <Route exact path="/marketplace" component={Pages.Marketplace} />
        <PrivateRoute
          exact
          path="/marketplace/new"
          component={Pages.MarketplaceBuilder}
        />
        <Route
          exact
          path="/marketplace/:documentId"
          component={Pages.MarketplaceService}
        />
        <PrivateRoute
          exact
          path="/marketplace/:documentId/edit"
          component={Pages.MarketplaceBuilder}
        />

        {/* Program routes */}
        <Route exact path="/programs" component={Pages.Programs} />
        <PrivateRoute
          exact
          path="/programs/new"
          component={Pages.ProgramBuilder}
        />
        <PrivateRoute
          exact
          path="/programs/build"
          component={Pages.ProgramBuilder}
        />

        <Route path="/program/:documentId" exact component={Pages.Program} />
        <Route
          exact
          path="/program/:documentId/apply"
          component={Pages.ProgramApplication}
        />
        <PrivateRoute
          exact
          path="/program/:documentId/applications"
          component={Pages.ProgramApplicationsManagement}
        />
        <PrivateRoute
          exact
          path="/program/:documentId/edit"
          component={Pages.ProgramBuilder}
        />

        <PrivateRoute
          path="/program/:documentId/:activity?"
          component={Pages.Program}
        />

        {/* Think Tanks list and create */}
        <PrivateRoute exact path="/challenges" component={Pages.Challenges} />
        <PrivateRoute
          exact
          path="/challenges/new"
          component={Pages.ThinkTankBuilder}
        />
        <PrivateRoute
          exact
          path="/challenges/build"
          component={Pages.ThinkTankBuilder}
        />

        {/* Think Tank view/manage, apply, edit */}
        <PrivateRoute
          path="/challenge/:documentId"
          exact
          component={Pages.Challenge}
        />
        <Route
          exact
          path="/challenge/:documentId/apply"
          component={Pages.ThinkTankApplication}
        />
        <PrivateRoute
          exact
          path="/challenge/:documentId/applications"
          component={Pages.ThinkTankApplicationsManagement}
        />
        <PrivateRoute
          exact
          path="/challenge/:documentId/edit"
          component={Pages.ThinkTankBuilder}
        />

        {/* Connections list */}
        <PrivateRoute exact path="/connections" component={Pages.Connections} />

        {/* Company Profile */}
        <PrivateRoute
          exact
          path="/mycompany/edit"
          component={Pages.MyCompanyEdit}
        />
        {/* User Profile */}
        <PrivateRoute
          exact
          path="/myprofile/edit"
          component={Pages.UserProfileEditor}
        />

        {/* Registration */}
        <Route
          path="/registration/company/:token"
          component={Pages.RegistrationCompany}
        />
        <Route
          path="/registration/user/:token"
          component={Pages.RegistrationUser}
        />

        <PrivateRoute path="/startups" exact component={Pages.Startups} />
        <Route path="/startup/:companyId" component={Pages.CompanyProfile} />

        <PrivateRoute path="/corporations" exact component={Pages.Corporates} />
        <Route path="/corporate/:companyId" component={Pages.CompanyProfile} />

        <Route
          path="/acceptable_use_policy"
          component={Pages.AcceptableUsePolicy}
        />
        <Route path="/pricing" component={Pages.Pricing} />
        <Route path="/privacy_policy" component={Pages.PrivacyPolicy} />
        <Route path="/terms_of_supply" component={Pages.TermsOfSupply} />
        <Route path="/terms_of_use" component={Pages.TermsOfUse} />

        {/* https://fuse.online/password_reset/reset_token=1a84d900-83b8-4f2e-baa9-018edbdd1827 */}
        <Route
          path="/password_reset/:token"
          exact
          component={Pages.ResetPassword}
        />

        {/* https://fuse.online/restore_account/restore_token=1a84d900-83b8-4f2e-baa9-018edbdd1827 */}
        <Route
          path="/restore_account/:token"
          exact
          component={Pages.RestoreAccount}
        />

        <Route path="/signin" component={Pages.Signin} />
        <Route path="/" exact component={Pages.Home} />
        <Route path="/about" exact component={Pages.About} />
        <Route path="/faqs" exact component={Pages.FAQs} />
        <Route path="/forcorporates" exact component={Pages.ForCorporates} />
        <Route path="/forfounders" exact component={Pages.ForFounders} />
        <Route path="/forinvestors" exact component={Pages.ForInvestors} />
        <Route component={Pages.NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
