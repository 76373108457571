import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ErrorGraphic from "./ErrorGraphic";
import NotFound from "./NotFound";

const styles = {
  root: {
    //padding: 16,
    border: "1px solid red",
    color: "red",
    marginBottom: 20
  },
  title: {
    padding: 10,
    backgroundColor: "#F2F3F4",
    fontWeight: "bold"
  },
  message: {
    backgroundColor: "#F9EFED",
    border: "1px solid #DB6E53",
    borderRadius: 3,
    color: "#DB6E53",
    fontWeight: 600,
    marginTop: 15,
    padding: "10px 15px"
  },
  validation: {
    fontSize: 12,
    padding: 10
  }
};

const ErrorPanel = props => {
  const { classes, error, validationErrors } = props;

  // 404 errors
  if (error.message.startsWith("404")) {
    const resource = error.message.slice(21, -2);

    return <NotFound resource={resource} />;
  }

  const body = error.message.split("\n").map((line, index) => (
    <div key={index} className={classes.message}>
      {line}
    </div>
  ));

  const validation = Object.keys(validationErrors || {}).map(key => {
    const text = validationErrors[key];

    return (
      <div key={key} className={classes.validation}>
        <strong>{key}:</strong> {text}
      </div>
    );
  });

  return (
    <ErrorGraphic>
      {body}
      {validation}
    </ErrorGraphic>
  );
  // return (
  //   <div className={classes.root} style={style}>
  //     <div className={classes.title}>Error</div>
  //     {body}
  //     {validation}
  //   </div>
  // );
};

ErrorPanel.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string.isRequired }).isRequired
};

ErrorPanel.defaultProps = {
  style: {}
};

export default withStyles(styles)(ErrorPanel);
