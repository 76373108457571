import React from "react";

import PageTemplate from "./PageTemplate";

import Home from "../components/Home";

const Homepage = props => {
  return (
    <PageTemplate>
      <Home />
    </PageTemplate>
  );
};

export default Homepage;
