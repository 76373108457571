import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { toggleConnectionContacts } from "../../Reducers/Connections/connectionsActions";

import { SecondaryButton } from "../common/Buttons";

const ContactsButton = props => {
  const { id, showContacts, toggleConnectionContacts } = props;

  return (
    <SecondaryButton
      onClick={() => toggleConnectionContacts(id, !showContacts)}
    >
      {showContacts ? "Hide Contacts" : "Show Contacts"}
    </SecondaryButton>
  );
};

ContactsButton.propTypes = {
  id: PropTypes.number.isRequired,
  showContacts: PropTypes.bool,
  toggleConnectionContacts: PropTypes.func.isRequired
};

ContactsButton.defaultProps = {};

const mapDispatchToProps = dispatch => {
  return {
    toggleConnectionContacts: (id, value) =>
      dispatch(toggleConnectionContacts(id, value))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ContactsButton);
