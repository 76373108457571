import React from "react";
import PropTypes from "prop-types";

import FormSelectField from "./Selectors/FormSelectField";

const IdSelector = props => {
  const { onChange, options, labels, value, ...otherProps } = props;

  if (!options.length) return null;

  // convert options and labels arrays to array of option objects
  const _options = labels.length
    ? options.map((value, index) => ({
        value,
        label: labels[index]
      }))
    : options.slice();

  const _value = _options.find(opt => opt.value === value);

  return (
    <FormSelectField
      options={_options}
      value={_value}
      {...otherProps}
      isMulti={false}
      onChange={country => onChange(country.value)}
    />
  );
};

IdSelector.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  labels: PropTypes.array
};

IdSelector.defaultProps = {
  label: "Please select",
  labels: []
};

export default IdSelector;
