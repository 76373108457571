import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { BLUE_PRIMARY } from "../../../styles/colors";

import * as propTypes from "./propTypes";

const styles = {
  root: {
    borderLeft: `3px solid ${BLUE_PRIMARY}`,
    paddingLeft: 20
  },
  author: {
    fontWeight: 700,
    marginTop: 10
  },
  company: {
    marginTop: 4
  },
  paragraph: {
    fontStyle: "italic",
    marginBottom: 0,
    marginTop: 10,
    "&:first-child": {
      marginTop: 0
    }
  },
  textContainer: {
    minHeight: 84,
    overflow: "hidden"
  }
};

const Testimonial = props => {
  const { classes, testimonial } = props;
  const { content, author, author_job_title, author_company } = testimonial;

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        {content.split("\n").map((s, idx) => (
          <p className={classes.paragraph} key={idx}>
            {s}
          </p>
        ))}
      </div>
      <div className={classes.author}>{author}</div>
      <div className={classes.company}>
        {author_job_title
          ? `${author_job_title}, ${author_company}`
          : author_company}
      </div>
    </div>
  );
};

Testimonial.propTypes = {
  classes: PropTypes.object.isRequired,
  testimonial: propTypes.testimonial.isRequired
};

export { styles, Testimonial };
export default withStyles(styles)(Testimonial);
