import React from "react";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Section from "../common/Section";
import VideoContainer from "../common/VideoContainer";
import ProfileTable from "../Company/ProfileTable";
import DocumentTopics from "../Document/DocumentTopics";

import ProfileContactsSection from "./ProfileContactsSection";

const styles = theme => ({
  root: {
    marginTop: 0
  },
  description: {
    marginBottom: 4 * theme.spacing.unit
  },
  founderBio: {
    margin: 3 * theme.spacing.unit,
    marginBottom: 0,
    outline: "1px solid lightgrey",
    minHeight: 80
  },
  logo: { width: 64, height: 64, verticalAlign: "middle" },
  outline: {
    outline: "1px solid lightgrey",
    minHeight: 120,
    marginBottom: 24,
    padding: 12
  },
  panelContent: {
    marginBottom: theme.spacing.unit
  },
  panelHeader: { marginBottom: theme.spacing.unit },
  title: {
    height: 3 * theme.spacing.unit,
    marginLeft: 2 * theme.spacing.unit,
    verticalAlign: "middle"
  },
  videoContainer: {
    marginBottom: 4 * theme.spacing.unit
  },
  body1: {
    fontSize: 14,
    color: "#2A2B2C"
  }
});

const renderText = (text, classes) => {
  const paragraphs = (text || "").split("\n").filter(p => p);

  return paragraphs.map((paragraph, index) => (
    <Typography
      key={index}
      paragraph
      variant="body1"
      classes={{ body1: classes.body1 }}
    >
      {paragraph}
    </Typography>
  ));
};

const renderConditionalSection = (content, title, classes) => {
  if (!content) return null;

  return (
    <Section
      variant="subheading"
      title={title}
      fullwidth={false}
      style={{
        marginTop: "32px"
      }}
    >
      {renderText(content, classes)}
    </Section>
  );
};

const StartupProfile = props => {
  const { classes, startup, showFounders, ...other } = props;

  if (Object.keys(startup).length === 0) return null;

  const video = startup.pitch_video_url ? (
    <div className={classes.videoContainer}>
      <VideoContainer src={startup.pitch_video_url} />
    </div>
  ) : null;

  return (
    <div className={classes.root} {...other}>
      {video}

      {renderConditionalSection(
        startup.pitch_text,
        startup.tagline || "Company pitch",
        classes
      )}
      {renderConditionalSection(startup.description, "Description", classes)}

      {showFounders && (
        <ProfileContactsSection contacts={startup.founders} title="Founders" />
      )}
      <DocumentTopics
        title="Markets"
        topics={startup.markets}
        style={{
          borderBottom: "1px solid #E6E7E9",
          borderTop: "none",
          paddingTop: 0,
          paddingBottom: 32,
          marginTop: 32,
          marginBottom: 0
        }}
      />
      <Section
        title="Company Details"
        fullwidth={false}
        style={{
          marginTop: "32px",
          borderBottom: "none",
          marginBottom: 0,
          paddingBottom: 0
        }}
      >
        <ProfileTable startup={startup} />
      </Section>
    </div>
  );
};

StartupProfile.defaultProps = {
  startup: {},
  showFounders: true
};

export default withStyles(styles)(StartupProfile);
