import React from "react";

const AcceptableUsePolicy = props => {
  return (
    <div>
      <section className="page-header-aligned-left">
        <h1>
          Colinked <span>Acceptable Use Policy</span>
        </h1>
      </section>

      <section className="content-padding-small">
        <section className="content">
          <p>
            <strong>
              Please read the terms of this policy carefully before using the
              site:
            </strong>
          </p>

          <h2>What's in these terms?</h2>

          <p>
            This acceptable use policy sets out the content standards that apply
            when you upload content to our site, make contact with other users
            on our site, link to our site, or interact with our site in any
            other way.
          </p>

          <h2>Who we are and how to contact us</h2>

          <ol>
            <li>
              <strong>colinked.io</strong> is a site operated by Whitespace
              Ventures Limited (<strong>"We"</strong>
              ). We are registered in England and Wales under company number
              09354350 and have our registered office at 58 City Road, Runway
              East, London, England, EC1Y 2AL.
            </li>

            <li>We are a limited company.</li>
          </ol>

          <p>
            To contact us, please email:{" "}
            <a href="mailto:support@colinked.io">support@colinked.io</a>
          </p>

          <h2>By using our site you accept these terms</h2>

          <ol>
            <li>
              By using our site, you confirm that you accept the terms of this
              policy and that you agree to comply with them.
            </li>

            <li>
              If you do not agree to these terms, you must not use our site.
            </li>

            <li>
              We recommend that you print a copy of these terms for future
              reference.
            </li>
          </ol>

          <h2>There are other terms that may apply to you</h2>

          <p>
            Our{" "}
            <a href="/terms_of_use" alt="Link to our term of use page">
              Terms of Website
            </a>{" "}
            use also apply to your use of our site.
          </p>

          <h2>We may make changes to the terms of this policy</h2>

          <p>
            We amend these terms from time to time. Every time you wish to use
            our site, please check these terms to ensure you understand the
            terms that apply at that time. These terms were most recently
            updated on 26 th July 2018.
          </p>

          <h2>Prohibited uses</h2>

          <p>
            You may use our site only for lawful purposes. You may not use our
            site:
          </p>

          <ul>
            <li>
              In any way that breaches any applicable local, national or
              international law or regulation.
            </li>

            <li>
              In any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect.
            </li>

            <li>
              For the purpose of harming or attempting to harm minors in any
              way.
            </li>

            <li>
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with our{" "}
              <a
                href="/acceptable_use_policy"
                alt="Link to our contents standards page"
              >
                content standards
              </a>
              .
            </li>

            <li>
              To transmit, or procure the sending of, any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation (spam).
            </li>

            <li>
              To knowingly transmit any data, send or upload any material that
              contains viruses, Trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware.
            </li>
          </ul>

          <p>
            <strong>You also agree:</strong>
          </p>

          <ul>
            <li>
              Not to reproduce, duplicate, copy or re-sell any part of our site
              in contravention of the provisions of our{" "}
              <a href="/terms_of_use">terms of website</a> use.
            </li>

            <li>
              Not to access without authority, interfere with, damage or
              disrupt:
              <ul>
                <li>any part of our site;</li>
                <li>any equipment or network on which our site is stored;</li>
                <li>any software used in the provision of our site; or</li>
                <li>
                  any equipment or network or software owned or used by any
                  third party
                </li>
              </ul>
            </li>
          </ul>

          <h2>Interaction Services</h2>

          <p>
            We may from time to time provide interactive services on our site,
            including, without limitation:
          </p>

          <ul>
            <li>Chat rooms</li>
            <li>Bulletin boards</li>
            <li>(interactive services)</li>
          </ul>

          <p>
            Where we do provide any interactive service, we will provide clear
            information to you about the kind of service offered, if it is
            moderated and what form of moderation is used (including whether it
            is human or technical).
          </p>

          <p>
            We will do our best to assess any possible risks for users (and in
            particular, for children) from third parties when they use any
            interactive service provided on our site, and we will decide in each
            case whether it is appropriate to use moderation of the relevant
            service (including what kind of moderation to use) in the light of
            those risks. However, we are under no obligation to oversee, monitor
            or moderate any interactive service we provide on our site, and we
            expressly exclude our liability for any loss or damage arising from
            the use of any interactive service by a user in contravention of our
            content standards, whether the service is moderated or not.
          </p>

          <p>
            The use of any of our interactive services by a minor is subject to
            the consent of their parent or guardian. We advise parents who
            permit their children to use an interactive service that it is
            important that they communicate with their children about their
            safety online, as moderation is not fool proof. Minors who are using
            any interactive service should be made aware of the potential risks
            to them.
          </p>

          <p>
            Where we do moderate an interactive service, we will normally
            provide you with a means of contacting the moderator, should a
            concern or difficulty arise.
          </p>

          <h2>Content standards</h2>

          <p>
            These content standards apply to any and all material which you
            contribute to our site <strong>(Contribution)</strong>, and to any
            interactive services associated with it.
          </p>

          <p>
            The Content Standards must be complied with in spirit as well as to
            the letter. The standards apply to each part of any Contribution as
            well as to its whole.
          </p>

          <p>
            Whitespace will determine, in its discretion, whether a Contribution
            breaches the Content Standards.
          </p>

          <p>
            <strong>A Contribution must:</strong>
          </p>

          <ul>
            <li>Be accurate (where it states facts).</li>
            <li>Be genuinely held (where it states opinions).</li>
            <li>
              Comply with the law applicable in England and Wales and in any
              country from which it is posted.
            </li>
          </ul>

          <p>
            <strong>A Contribution must not:</strong>
          </p>

          <ul>
            <li>Be defamatory of any person.</li>

            <li>Be obscene, offensive, hateful or inflammatory.</li>

            <li>Promote sexually explicit material.</li>

            <li>Promote violence.</li>

            <li>
              Promote discrimination based on race, sex, religion, nationality,
              disability, sexual orientation or age.
            </li>

            <li>
              Infringe any copyright, database right or trade mark of any other
              person.
            </li>
            <li>Be likely to deceive any person.</li>

            <li>
              Breach any legal duty owed to a third party, such as a contractual
              duty or a duty of confidence.
            </li>

            <li>Promote any illegal activity.</li>

            <li>Be in contempt of court.</li>

            <li>
              Be threatening, abuse or invade another's privacy, or cause
              annoyance, inconvenience or needless anxiety.
            </li>
            <li>
              Be likely to harass, upset, embarrass, alarm or annoy any other
              person.
            </li>

            <li>
              Impersonate any person, or misrepresent your identity or
              affiliation with any person.
            </li>

            <li>
              Give the impression that the Contribution emanates from Whitespace
              Ventures Limited or any of its connected persons, affiliates or
              partners, if this is not the case.
            </li>

            <li>
              Advocate, promote, incite any party to commit, or assist any
              unlawful or criminal act such as (by way of example only)
              copyright infringement or computer misuse.
            </li>

            <li>
              Contain a statement which you know or believe, or have reasonable
              grounds for believing, that members of the public to whom the
              statement is, or is to be, published are likely to understand as a
              direct or indirect encouragement or other inducement to the
              commission, preparation or instigation of acts of terrorism.
            </li>
          </ul>

          <h2>Breach of this policy</h2>

          <p>
            When we consider that a breach of this acceptable use policy has
            occurred, we may take such action as we deem appropriate.
          </p>

          <p>
            Failure to comply with this acceptable use policy constitutes a
            material breach of the{" "}
            <a href="/terms_of_use" alt="Link to our term of use page">
              terms of use
            </a>{" "}
            upon which you are permitted to use our site, and may result in our
            taking all or any of the following actions:
          </p>

          <ul>
            <li>
              Immediate, temporary or permanent withdrawal of your right to use
              our site.
            </li>

            <li>
              Immediate, temporary or permanent removal of any Contribution
              uploaded by you to our site.
            </li>

            <li>Issue of a warning to you.</li>

            <li>
              Legal proceedings against you for reimbursement of all costs on an
              indemnity basis (including, but not limited to, reasonable
              administrative and legal costs) resulting from the breach.
            </li>

            <li>Further legal action against you.</li>

            <li>
              Disclosure of such information to law enforcement authorities as
              we reasonably feel is necessary or as required by law.
            </li>
          </ul>

          <p>
            We exclude our liability for all action we may take in response to
            breaches of this acceptable use policy. The actions we may take are
            not limited to those described above, and we may take any other
            action we reasonably deem appropriate.
          </p>

          <h2>Which country's laws apply to any disputes?</h2>

          <ol>
            <li>
              If you are a consumer, please note that the terms of this policy,
              its subject matter and its formation are governed by English law.
              You and we both agree that the courts of England and Wales will
              have exclusive jurisdiction except that if you are a resident of
              Northern Ireland you may also bring proceedings in Northern
              Ireland, and if you are resident of Scotland, you may also bring
              proceedings in Scotland.
            </li>

            <li>
              If you are a business, the terms of this policy, its subject
              matter and its formation (and any non-contractual disputes or
              claims) are governed by English law. We both agree to the
              exclusive jurisdiction of the courts of England and Wales.
            </li>
          </ol>
        </section>
      </section>

      <section className="call-to-action">
        <div className="container">
          <div className="content">
            <h2>Our Valued Members</h2>
            <p>
              In our beta, we signed up some of the biggest corporates and
              brightest founder teams, you can join our movement here:
            </p>
            <button className="btn">For Corporates</button>{" "}
            <button className="btn">For Founders</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AcceptableUsePolicy;
