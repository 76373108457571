const cellStyle = {
  flexGrow: 1,
  padding: 8
};

const headerCell = {
  ...cellStyle,
  padding: "0 8px",
  lineHeight: "40px",
  color: "rgba(255,255,255,0.87)"
};

const styles = {
  root: {
    marginTop: 12
  },
  header: {
    padding: 8,
    height: 56,
    lineHeight: "56px",
    fontWeight: 600
  },
  listHeader: {
    display: "flex",
    height: 40,
    lineHeight: "40px",
    backgroundColor: "lightgrey",
    color: "rgba(255,255,255,0.87)",
    fontWeight: 600,
    padding: 8
  },
  row: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F2F3F4"
    }
  },
  cell: {
    ...cellStyle
  },
  headerCell: {
    ...headerCell
  },
  companyHeader: {
    ...headerCell,
    flexGrow: 0,
    width: 350,
    padding: 0
  },
  companyType: {
    ...headerCell,
    flexGrow: 0,
    width: 100
  },
  statusHeader: {
    ...headerCell,
    flexGrow: 0,
    minWidth: 240
  },
  errorRoot: {
    padding: 0
  }
};

export default styles;
