export const getRecentReferrals = (referrals, recent) => {
  const accepted = referrals
    .filter(r => r.is_registered)
    .sort(sortLatestFirst)
    .slice(0, recent);

  const pending = referrals
    .filter(r => !r.is_registered)
    .sort(sortLatestFirst)
    .slice(0, recent);

  return [...accepted, ...pending];
};

export const sortByCompanyName = (a, b) => {
  if (!a.recipient_company || !b.recipient_company) return 0;

  const aName = a.recipient_company.name || "";
  const bName = b.recipient_company.name || "";

  return aName.localeCompare(bName, "en", { sensitivity: "base" });
};

export const sortLatestFirst = (a, b) => {
  return new Date(b.updated_at) - new Date(a.updated_at);
};
