import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { transformCompanyData } from "./utils";

import { specCorporates, specFounders } from "./tableSpecs";

const styles = {
  root: {
    fontSize: 14,
    marginTop: 30
  },
  row: {
    display: "flex",
    borderTop: "1px solid #EDEDEF",
    paddingTop: 12,
    paddingBottom: 8
  },
  icon: {
    marginLeft: 15,
    marginRight: 15,
    fill: "#2A2B2C",
    stroke: "none"
  },
  label: {
    color: "#2A2B2C",
    fontWeight: 600,
    width: 140,
    minWidth: 140,
    flexShrink: 0
  },
  value: {
    flexShrink: 1,
    marginRight: 15
  }
};

const renderRow = (spec, data, classes) => {
  const { key, label, Icon } = spec;

  // remove empty lines from table
  const value = data[key];
  if (!value) return null;

  return (
    <div key={key} className={classes.row}>
      <Icon className={classes.icon} />
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

const ProfileTable = props => {
  const { classes, company } = props;
  const data = transformCompanyData(company);

  const dataSpec =
    props.dataSpec || company.is_corporate ? specCorporates : specFounders;

  return (
    <div className={classes.root}>
      {dataSpec.map(spec => renderRow(spec, data, classes))}
    </div>
  );
};

ProfileTable.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  dataSpec: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      Icon: PropTypes.func.isRequired
    }).isRequired
  )
};

export default withStyles(styles)(ProfileTable);
