import React from "react";

import Whatshot from "@material-ui/icons/Whatshot";

import TagLabel from "../common/TagLabel";

const PopularTag = props => {
  const { style } = props;

  return (
    <TagLabel label="Popular" color="gold" IconLeft={Whatshot} style={style} />
  );
};

export default PopularTag;
