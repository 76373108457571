import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Whatshot from "@material-ui/icons/Whatshot";
import { withStyles } from "@material-ui/core/styles";
import TagLabel from "../../common/TagLabel";

import { GREY_LIGHT, BODY_FONT_BLACK } from "../../../styles/colors";
import * as STYLES from "../../../styles";

const styles = {
  root: {
    backgroundColor: "white",
    width: 300,
    height: 370,
    overflow: "hidden",
    marginTop: 20,
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
    position: "relative"
  },
  companyName: {
    ...STYLES.linkStyle,
    color: BODY_FONT_BLACK,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "19px",
    marginTop: 5
  },
  description: {
    ...STYLES.linkStyle
  },
  image: {
    height: 150,
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  companyLogo: {
    position: "absolute",
    top: 110,
    left: 110,
    width: 80,
    height: 80,
    borderRadius: "50%",
    backgroundColor: "white",
    zIndex: 1,
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1)",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  textContainer: {
    marginTop: 40,
    boxSizing: "border-box",
    height: 125,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 25,
    textAlign: "center"
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "nowrap",
    padding: 15,
    borderTop: `1px solid ${GREY_LIGHT}`
  }
};

const GridCard = props => {
  const { classes, document } = props;

  return (
    <div className={classes.root}>
      {document.is_popular && (
        <TagLabel
          label="Popular"
          color="gold"
          IconLeft={Whatshot}
          style={{ position: "absolute", right: 15, top: 15 }}
        />
      )}

      <div
        className={classes.image}
        style={{ backgroundImage: `url("${document.card_image_url}")` }}
      />
      <a
        href={document.company_url}
        alt={document.company_name}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={classes.companyLogo}
          style={{ backgroundImage: `url("${document.company_logo_url}")` }}
        />
      </a>
      <div className={classes.textContainer}>
        <Link className={classes.description} to={document.link}>
          {document.description}
        </Link>
        <a
          className={classes.companyName}
          href={document.company_url}
          alt={document.company_name}
          target="_blank"
          rel="noopener noreferrer"
        >
          by {document.company_name}
        </a>
      </div>
      <div className={classes.tagsContainer}>
        {document.tags.slice(0, 3).map(tag => (
          <TagLabel key={tag} label={tag} />
        ))}
      </div>
      {/* <p style={{ whiteSpace: "pre" }}>{JSON.stringify(document, null, 2)}</p> */}
    </div>
  );
};

GridCard.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired
};

export { GridCard, styles };
export default withStyles(styles)(GridCard);
