import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ManageChart from "./ManageChart";
import ProgramLinks from "./ProgramLinksContainer";
import ThinkTankLinks from "./ThinkTankLinksContainer";

const panelStyle = {
  boxSizing: "border-box",
  backgroundColor: "white",
  padding: 30,
  height: 280
};

const styles = {
  root: {
    boxSizing: "border-box",
    backgroundColor: "#AAADB6",
    padding: 30
  },
  container: {
    display: "flex"
  },
  panelChart: {
    ...panelStyle,
    padding: 0,
    flexGrow: 1
  },
  panelLinks: {
    ...panelStyle,
    width: 320,
    marginRight: 30
  }
};

const shouldRender = (company, document) => {
  if (!company || !company.id) return false;

  // allow __service__ management for superadmin users only
  if (document.doctype === "service" && company.id === 69) {
    return true;
  }

  if (!document.company || !document.company.id) return false;

  // allow program/TT management for owner
  if (company.id === document.company.id) return true;

  return false;
};

const renderLinks = (doctype, document) => {
  if (doctype === "program") return <ProgramLinks document={{ ...document }} />;
  if (doctype === "challenge")
    return <ThinkTankLinks document={{ ...document }} />;

  return null;
};

const ManagePanel = props => {
  const { classes, company, document } = props;
  const { doctype } = document;

  if (!shouldRender(company, document)) return null;

  return (
    <div className={classes.root}>
      <div className={`body ${classes.container}`}>
        <div className={classes.panelLinks}>
          {renderLinks(doctype, document)}
        </div>
        <div className={classes.panelChart}>
          <ManageChart />
        </div>
      </div>
    </div>
  );
};

ManagePanel.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  document: PropTypes.shape({
    doctype: PropTypes.oneOf(["program", "challenge"])
  }).isRequired
};

export { ManagePanel, styles };
export default withStyles(styles)(ManagePanel);
