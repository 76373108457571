import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "material-ui";

const styles = {
  root: {
    backgroundColor: "white",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    marginRight: 20
  }
};

const LogoImage = props => {
  const { classes, url, radius, size } = props;

  const rootStyle = {
    width: size,
    height: size,
    minWidth: size,
    minHeight: size,
    backgroundImage: `url("${url}")`,
    borderRadius: radius
  };

  return <div className={classes.root} style={rootStyle} />;
};

LogoImage.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired
};

LogoImage.defaultProps = {
  radius: 0,
  size: 60
};

export default withStyles(styles)(LogoImage);
