import React from "react";

import imgCorpFind from "../assets/images/for-corporates/colinked-for-corporates-find.png";
import imgCorpPromote from "../assets/images/for-corporates/colinked-for-corporates-promote.png";
import imgCorpEngage from "../assets/images/for-corporates/colinked-for-corporates-engage.png";
import imgFormCharacters from "../assets/images/homepage/colinked-homepage-form-characters.png";

import logosGrid from "../assets/images/for-corporates/colinked-for-corporates-logos.png";

import SignupForm from "./common/SignupForm";

const ForCorporates = () => {
  return (
    <div>
      <section className="page-header-centered">
        <p className="uppercase">For Corporates</p>
        <h1>
          <span>Augment</span> your innovation pipeline and <span>connect</span>{" "}
          with other corporates
        </h1>
      </section>

      <section className="content-padding-large">
        <section className="benefit">
          <div className="image left">
            <img
              src={imgCorpFind}
              alt="Colinked - Illustration which includes icons showing how corporates can find founders"
            />
          </div>

          <div className="text">
            <h2>Find vetted scale-up companies</h2>
            <p>
              Every founder on Colinked is referred by another corporate member.
              Whether a co-selling agreement or direct investment, we ensure
              founder quality as they are already working directly with other
              corporate members.
            </p>
          </div>
        </section>

        <section className="benefit reverse">
          <div className="text left">
            <h2>Promote your engagement programs</h2>
            <p>
              Post your corporate programs, anything from accelerators to
              hackathons to our database of referred founders. Build your
              programs with our smart form builder and customise it to your
              needs. With smart filters and sorting we can ensure you only have
              to review relevant applicants.
            </p>
          </div>

          <div className="image">
            <img
              src={imgCorpPromote}
              alt="Colinked - Illustration which includes icons showing how corporates can promote their programs"
            />
          </div>
        </section>

        <section className="benefit">
          <div className="image left">
            <img
              src={imgCorpEngage}
              alt="Colinked - Illustration which includes icons showing how corporates can engage with founders"
            />
          </div>

          <div className="text">
            <h2>Engage with other Corporates </h2>
            <p>
              Interested in discovering new markets and exploring new
              technologies / best practices with other corporates? Build a
              ThinkTank, an exploratory event other corporates can be part of.
              Alternatively, you can directly reach out to other corporates with
              RFPs.
            </p>
          </div>
        </section>
      </section>

      <section className="corp-logos">
        <h2>The Colinked Community of Corporates</h2>
        <p>Truly global and diverse</p>

        <img src={logosGrid} alt="Colinked - Corporate Partner Logos" />
      </section>

      <section className="form-section">
        <div className="form-container" id="join">
          <h2>Become a Colinked Member</h2>
          <p>Sign up to and join our movement today:</p>

          <SignupForm
            url="/api/companies/corporations/apply/"
            showLinkedin={true}
          />

          <img
            src={imgFormCharacters}
            alt="Colinked - Character avatars shaking hands"
            width={243}
            height={198}
          />
        </div>
      </section>
    </div>
  );
};

export default ForCorporates;
