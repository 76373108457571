import React from "react";
import { PropTypes } from "prop-types";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../Icons";
import styles from "./styles";

const DateOngoing = props => {
  const { classes, doctype, deadline } = props;

  const text = deadline
    ? `Apply by ${moment(deadline).format("Do MMMM")}`
    : doctype === "program"
    ? "Year-round program"
    : "Year-round challenge";

  return (
    <div className={classes.detailsRow}>
      <Icons.CalendarRange className={classes.inlineIcon} />
      <div className={classes.detailsBlock}>
        <div className={classes.detailsLine}>{text}</div>
      </div>
    </div>
  );
};

DateOngoing.propTypes = {
  classes: PropTypes.object.isRequired,
  deadline: PropTypes.string,
  doctype: PropTypes.oneOf(["program", "challenge"]).isRequired
};

export default withStyles(styles)(DateOngoing);
