import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import ActionDialog from "../common/ActionDialog";

const ReferStartupDialog = props => {
  const { config, message, profile, referrals, ...other } = props;

  let leadText = `You can refer up to ${
    config.referrals__max_corp_referrals
  } founder teams.`;

  if (referrals.length === 1) {
    leadText += ` You have already referred ${referrals.length} founder teams.`;
  } else if (referrals.length > 1) {
    leadText += ` You have already referred ${referrals.length} founder team.`;
  }

  return (
    <ActionDialog
      fields={[
        {
          name: "",
          helperText: leadText
        },
        {
          name: "recipient_company_name",
          label: "Company Name",
          value: props.recipient_company_name || "",
          helperText: "What are the founder team called?"
        },
        {
          name: "recipient_name",
          label: "Contact Name",
          value: props.recipient_name || "",
          helperText: "Who at the company will receive the invite?"
        },
        {
          name: "recipient",
          label: "Email address",
          value: props.recipient || "",
          helperText: "Where should we send the invite to?"
        },
        {
          name: "relationship",
          label: "How have you worked with this company?",
          type: "select",
          options: [
            "Part of Accelerator",
            "Part of Open Innovation Program",
            "Investee Company",
            "Co-sale Agreement",
            "Preferred Supplier",
            "Ex-Employees",
            "other"
          ],
          value: "Part of Accelerator",
          width: "100%"
        }
      ]}
      height={400}
      width={420}
      buttonLabel="Refer Founder Team"
      {...other}
    />
  );
};

ReferStartupDialog.propTypes = {
  message: PropTypes.string,
  postURL: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired
};

ReferStartupDialog.defaultProps = {
  title: "Refer a Founder Team",
  message: "Please join us on the Colinked platform.",
  postURL: "/api/referrals/"
};

const mapStateToProps = state => {
  return {
    config: state.config,
    profile: state.profile,
    referrals: state.referrals
  };
};

export default connect(mapStateToProps)(ReferStartupDialog);
