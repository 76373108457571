import React from "react";
import { PropTypes } from "prop-types";

import Checkbox from "@material-ui/core/Checkbox";

const currencies = ["GBP (£)", "USD ($)", "EUR (\u20AC)"];

const BenefitValueInput = props => {
  const { benefit, classes, label, onChange, toggleBenefit } = props;

  const checked = Boolean(benefit);
  const style = checked ? { backgroundColor: "#E8EFF8" } : {};

  const unit = (benefit && benefit.unit) || "GBP (£)";
  const value = (benefit && benefit.value) || "0";

  return (
    <div className={classes.amountRoot} style={style}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          className={classes.checkbox}
          checked={checked}
          onChange={() => toggleBenefit(label)}
          value="value"
        />
        {label}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <select
          className={classes.input}
          disabled={!checked}
          style={{ width: 110, height: 40 }}
          value={unit}
          onChange={event => {
            onChange({
              label,
              unit: event.target.value,
              value
            });
          }}
        >
          {currencies.map(c => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </select>

        <input
          className={classes.input}
          disabled={!checked}
          type="number"
          style={{ width: 194 }}
          value={`${parseFloat(value)}`}
          onChange={event => {
            onChange({
              label,
              unit,
              value: event.target.value
            });
          }}
        />
      </div>
    </div>
  );
};

BenefitValueInput.propTypes = {
  label: PropTypes.string.isRequired,
  benefits: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  toggleBenefit: PropTypes.func.isRequired
};

export default BenefitValueInput;
