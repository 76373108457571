import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Banner from "../components/common/Banner";
import Section from "../components/common/Section";

import PageTemplate from "./PageTemplate";

const Program = props => {
  return (
    <PageTemplate>
      <Banner title="Terms of Supply" />

      <div className="body">
        <Grid container spacing={40}>
          <Grid item sm={12} md={8} lg={8} style={{ paddingRight: "80px" }}>
            <Section
              fullwidth={false}
              title="Terms of Supply"
              style={{ borderBottom: "none" }}
            >
              <Typography paragraph variant="body1">
                CPM visibility content curation. Brand voice granular funnel
                leverage. User-friendly proprietary integrated. Scalability B2C
                chatvertizing wheelhouse virality. Dedication lean content
                click-through rate disruptive tech. Optimized for social sharing
                chatvertizing target audience Gen Y low hanging fruit drone.
                Chatvertizing B2B curated disruptive tech visibility phablet.
                Vertical-specific buzzword engagement. Flat design platform
                crowdsource CPM Gen Y.
              </Typography>
              <Typography paragraph variant="body1">
                Long-tail context engagement. Brand awareness vertical-specific
                virality fanbassador. Disrupt learnings lean content engagement
                emerging low hanging fruit native content. Millennials drone
                proprietary disrupt context lean content crowdsource. B2B
                fanbassador top influencers. Flat design content curation
                call-to-action organic reach multiple points of entry. Wearables
                reaching out hit the like button lean content.
              </Typography>
            </Section>
          </Grid>
        </Grid>
      </div>
    </PageTemplate>
  );
};

export default Program;
