import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";

import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";
import { createFlashNote } from "../../Reducers/FlashNotes";

import ErrorPanelSmall from "../common/Errors/ErrorPanelSmall";
import SpinnerButton from "../common/SpinnerButton";
import { validateEmail } from "../Form/validate";
import TextInputField from "../Form/TextInputField";

import CloseIcon from "@material-ui/icons/Close";

import styles from "./dialogStyles";

class ActionDialog extends React.Component {
  constructor(props) {
    super(props);

    this.id = `id_${Math.random()}`;
    this.contentRef = React.createRef();

    const state = props.fields
      .filter(field => field.name)
      .reduce((state, field) => {
        state[field.name] = field.value || "";
        return state;
      }, {});

    this.state = {
      ...state,
      _height: props.height,
      open: true,
      loading: false,
      error: null,
      validationErrors: {}
    };
  }

  componentDidMount() {
    this.setState({ __forceHeightUpdate: true });
  }

  componentDidUpdate() {
    const content = document.getElementById(this.id);
    const height = content && content.offsetHeight;

    if (height && height !== this.state._height) {
      this.setState({ _height: height });
    }
  }

  onSubmit = () => {
    const { fields, postURL } = this.props;

    const data = fields
      .filter(field => field.name)
      .map(field => field.name)
      .reduce((data, name) => {
        data[name] = this.state[name];
        return data;
      }, {});

    const options = {
      method: "POST",
      body: JSON.stringify(data)
    };

    fetchWithToken(postURL, options)
      .then(responseJsonOrError)
      .then(json => {
        const { error, validationErrors, ...other } = json;
        if (error) {
          this.props.createFlashNote("Oops! Something went wrong.", "error");
          this.setState({ loading: false, error, validationErrors, ...other });
        } else {
          this.props.createFlashNote("Your request has been received.", "info");
          this.setState({ loading: false, ...other }, this.handleClose);
        }
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  };

  handleClose = () => {
    const { history, onClose, redirect } = this.props;

    this.setState({ open: false }, () => {
      if (onClose) onClose();
      if (redirect) history.push(redirect);
    });
  };

  handleValueChange = (name, value) => {
    this.setState({
      [name]: value,
      error: null,
      validationErrors: {}
    });
  };

  renderCancelButton = () => {
    const { classes } = this.props;

    return (
      <div className={classes.buttonCancel} onClick={this.handleClose}>
        <CloseIcon />
      </div>
    );
  };

  renderError = () => {
    if (!this.state.error) return null;

    const { classes } = this.props;

    return (
      <ErrorPanelSmall
        classes={{
          root: classes.errorRoot,
          title: classes.errorTitle,
          message: classes.errorMessage
        }}
        error={this.state.error}
      />
    );
  };

  renderForm = () => {
    const { classes, fields } = this.props;

    return (
      <React.Fragment>
        {fields.map((spec, index) => {
          const { name, label, helperText } = spec;
          const validationError = this.state.validationErrors[name];

          return name !== "" ? (
            <TextInputField
              key={name}
              id={name}
              label={label || name}
              value={this.state[name]}
              onChange={value => this.handleValueChange(name, value)}
              width="100%"
              classes={{ root: classes.formRoot }}
              helperText={validationError || helperText}
              error={Boolean(validationError)}
            />
          ) : (
            <div key={index} className={classes.helperText}>
              {helperText}
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  renderSubmitButton = () => {
    const { error, loading } = this.state;

    const disabled =
      Boolean(error) || Boolean(validateEmail(this.state["email"]));

    return (
      <SpinnerButton
        disabled={disabled}
        onClick={() => this.setState({ loading: true }, this.onSubmit)}
        spinner={loading}
      >
        Submit
      </SpinnerButton>
    );
  };

  render() {
    const { classes, title, width } = this.props;
    const height = this.state._height || this.props.height;

    const marginTop = `-${(height + 60) / 2}px`;
    const marginLeft = `-${(width + 60) / 2}px`;
    const containerHeight = height - 49; // title=19, title-margin=30

    return (
      <Modal open={this.state.open} onClose={this.handleClose}>
        <div
          className={classes.paper}
          style={{ height, width, marginTop, marginLeft }}
        >
          <div id={this.id} ref={this.contentRef}>
            <div className={classes.title}>{title}</div>
            <div
              className={classes.container}
              style={{ _height: containerHeight }}
            >
              {this.renderForm()}

              {this.renderSubmitButton()}
            </div>
          </div>
          {this.renderCancelButton()}
        </div>
      </Modal>
    );
  }
}

ActionDialog.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      rows: PropTypes.number,
      value: PropTypes.string
    })
  ),
  createFlashNote: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  postURL: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  redirect: PropTypes.string,
  onClose: PropTypes.func
};

ActionDialog.defaultProps = {
  title: "Request password reset",
  height: 350,
  width: 390,
  fields: [
    {
      name: "",
      helperText:
        "Here you can request a reset of your password. An email will be sent to your address with a link to access Colinked and reset your password."
    },
    {
      name: "email",
      label: "Your email address",
      value: "",
      helperText: "Provide the email address you use to sign in to Colinked."
    }
  ],
  postURL: "/api/users/account/reset_password/"
};

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles)(ActionDialog)));
