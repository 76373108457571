import { connect } from "react-redux";

import ApplicationsManageViewContainer from "../ApplicationsManagement/ApplicationsManageViewContainer";

import { loadChallenge } from "./api.js";
import { loadThinkTankApplications } from "../../Reducers/Applications";

const mapStateToProps = state => ({
  doctype: "challenge",

  loadDocument: loadChallenge,
  applications: state.applications.applications
});

const mapDispatchToProps = dispatch => {
  return {
    loadApplications: id => dispatch(loadThinkTankApplications(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsManageViewContainer);
