import React from "react";
import { connect } from "react-redux";

import { clearFlashNote } from "../../Reducers/FlashNotes";

import Snackbar from "@material-ui/core/Snackbar";

import FlashNote from "./FlashNote";

class FlashNotes extends React.Component {
  queue = [];

  constructor(props) {
    super(props);

    this.state = {
      open: Boolean(props.flashNotes.length)
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.flashNotes.length) {
      this.setState({ open: true });
    }
  }

  handleClick = message => () => {
    const { createFlashNote } = this.props;
    createFlashNote(message, message);

    if (this.state.open) {
      // immediately begin dismissing current message
      // to start showing new one
      this.setState({ open: false });
    }
  };

  handleClose = (event, reason) => {
    // clickaway: user clicked outside the snackbar
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false }, this.clearCurrentMessage);
  };

  clearCurrentMessage = () => {
    if (this.props.flashNotes.length) {
      const current = this.props.flashNotes[0];

      this.props.clearFlashNote(current.id);
    }
  };

  render() {
    const { flashNotes } = this.props;
    const messageInfo = flashNotes.length && flashNotes[0];

    return (
      <div>
        <Snackbar
          key={messageInfo.id}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.open}
          autoHideDuration={4000}
          onClose={this.handleClose}
          onExited={this.handleExited}
        >
          <FlashNote
            onClose={this.handleClose}
            messageInfo={messageInfo}
            variant={messageInfo.type}
          />
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    flashNotes: state.flashNotes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearFlashNote: id => dispatch(clearFlashNote(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlashNotes);
