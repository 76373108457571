const styles = {
  root: {
    backgroundColor: "white",
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 900
  },
  row: {
    borderBottom: "1px solid #E6E7E9",
    "&:last-child": {
      borderBottom: "none"
    },
    "&:hover": {
      backgroundColor: "#F3F7FB"
    }
  }
};

export default styles;
