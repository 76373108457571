import { fetchWithToken } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";
import { formatSearchString } from "../../api/search";

const NEW_PROFILE = [
  { type: "heading", content: "" },
  { type: "richtext", content: "" }
];

const stripNullValues = json => {
  return Object.keys(json).reduce((data, key) => {
    if (json[key] !== null) {
      data[key] = json[key];
    }

    return data;
  }, {});
};

const fixMinimumData = json => {
  return {
    doctype: "program",
    link: `/program/${json.id}`,
    ...programApiFixes(json)
  };
};

export const fixMiniumDataArray = response => {
  if (!response.results) return response;

  return {
    ...response,
    results: response.results.map(fixMinimumData)
  };
};

const programApiFixes = json => {
  const { application, criteria, profile, ...program } = stripNullValues(json);

  return {
    // defaults
    ...programTemplate,

    // values from api program
    ...program,

    // fixes for temp api changes
    application: {
      ...programTemplate.application,
      ...application
    },
    criteria: Array.isArray(criteria) ? criteria : [],
    profile: profile && profile.length ? profile : NEW_PROFILE
  };
};

const programTemplate = {
  name: "undefined",
  doctype: "program",
  program_type: "accelerator",
  application: {
    open_to_founders: true,
    open_to_corporates: false,
    open_to_guests: false,
    nda_required: false,
    deadline_datetime: ""
  },
  benefits: [],
  criteria: [],
  description: "",
  is_archived: false,

  is_location_based: true,
  is_ongoing: false,
  is_published: false,
  // location: { city: "", country: 1 },
  logo_image_url: "",
  profile: [],
  questions: [],
  topics: [],
  start_datetime: ""
};

const _callProgramsAPI = (data, method) => {
  const url = data.id ? `/api/programs/${data.id}/` : "/api/programs/";

  return fetchWithToken(url, {
    body: JSON.stringify(data),
    cache: "no-cache",
    credentials: "same-origin",
    headers: {},
    method
  }).then(responseJsonOrError);
};

export const createNewProgram = () => {
  return fetchWithToken(`/api/programs/`, {
    method: "POST",
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(fixMinimumData)
    .then(json => ({ ...json, name: "" }));
};

export const deleteProgram = id => {
  return _callProgramsAPI({ id }, "DELETE");
};

export const duplicateProgram = id => {
  return Promise.all([loadProgram(id), createNewProgram()])
    .then(results => {
      const [old, _new] = results;

      return {
        ..._new,
        ...old,
        id: _new.id,
        link: `/program/${_new.id}`,
        name: `${old.name} (copy)`,
        is_approved: 1
      };
    })
    .then(program => {
      // apparently need to strip logo_image_url because of BE validation ...
      const { company, doctype, logo_image_url, ...data } = program;

      return updateProgram(data).then(result => {
        if (result.error) return result;

        return program;
      });
    })
    .catch(error => ({ error }));
};

export const updateProgram = data => {
  const { doctype, link, ...apiData } = data;

  return _callProgramsAPI(apiData, "PUT");
};

export const loadProgram = id => {
  return fetchWithToken(`/api/programs/${id}/`, {
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(fixMinimumData);
};

export const loadPrograms = (options = []) => {
  const searchString = formatSearchString(options);
  const url = searchString
    ? `/api/programs/?${searchString}`
    : "/api/programs/";

  return fetchWithToken(url, {
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(fixMiniumDataArray);
};

export const loadProgramsFromUrl = url => {
  return fetchWithToken(url, {
    cache: "no-cache",
    credentials: "same-origin"
  })
    .then(responseJsonOrError)
    .then(fixMiniumDataArray);
};

export const loadMyPrograms = id => (options = []) => {
  return loadPrograms([...options, { company: id }]);
};

export const submitApplication = (programId, data) => {
  const url = `/api/programs/${programId}/register/`;

  // console.warn("TODO: submitApplication api");
  // console.log(JSON.stringify(data, null, 2));

  return fetchWithToken(url, {
    method: "POST",
    body: JSON.stringify(data),
    cache: "no-cache",
    credentials: "same-origin"
  }).then(responseJsonOrError);
};
