import React from "react";
import { PropTypes } from "prop-types";

import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import {
  approveConnection,
  ignoreConnection
} from "../../../../Reducers/Connections";

import SimpleDialog from "../../../common/SimpleDialog";

import { PrimaryButtonGreen } from "../../../common/Buttons";
import { SecondaryButton } from "../../../common/Buttons";

const styles = {
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20
  },
  message: {
    marginBottom: 20
  },
  paragraph: {
    marginBottom: 5,
    "&:last-child": {
      marginBottom: 0
    }
  }
};

class ConnectionReviewDialog extends React.Component {
  static propTypes = {
    approveConnection: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    connection: PropTypes.object.isRequired,
    ignoreConnection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
  };

  renderIntro = () => {
    const { classes, connection } = this.props;
    const { request_company } = connection;

    return (
      <div className={classes.message}>{`${
        request_company.name
      } would like to connect and left you this note:`}</div>
    );
  };

  renderMessage = () => {
    const { classes, connection } = this.props;

    const paragraphs = connection.message
      .split("\n")
      .filter(s => s)
      .map((text, index) => (
        <p key={index} className={classes.paragraph}>
          {text}
        </p>
      ));

    return <div className={classes.message}>{paragraphs}</div>;
  };

  render = () => {
    const {
      classes,
      connection,
      approveConnection,
      ignoreConnection,
      onClose,
      title
    } = this.props;

    return (
      <SimpleDialog title={title} onClose={onClose} width={440}>
        {this.renderIntro()}
        {this.renderMessage()}

        <div className={classes.buttons}>
          <PrimaryButtonGreen
            onClick={() => {
              approveConnection(connection.id);
              onClose();
            }}
          >
            Connect
          </PrimaryButtonGreen>
          <SecondaryButton
            onClick={() => {
              ignoreConnection(connection.id);
              onClose();
            }}
          >
            Ignore
          </SecondaryButton>
        </div>
      </SimpleDialog>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return {
    approveConnection: id => dispatch(approveConnection(id)),
    ignoreConnection: id => dispatch(ignoreConnection(id))
  };
};

export { ConnectionReviewDialog, styles };
export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ConnectionReviewDialog));
