import React from "react";
import { PropTypes } from "prop-types";

import Dropzone from "react-dropzone";

import { withStyles } from "@material-ui/core/styles";

import { uploadImage } from "../../api/tokenApi";
import { responseJsonOrError } from "../../api/response";

import formStyles from "./formStyles";

const imageUpload = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  border: "2px dashed #E6E7E9",
  cursor: "pointer"
};

const buttonStyle = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",

  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.1)"
  },
  fontWeight: 600,

  // color
  border: "1px solid #2A2B2C",
  color: "#2A2B2C",

  // button size
  fontSize: 12,
  height: 28,
  marginLeft: 10,
  paddingLeft: 15,
  paddingRight: 15,
  borderRadius: 3
};

const styles = {
  ...formStyles,
  root: {
    marginTop: 20
  },
  fullWidth: {
    width: "100%"
  },
  imageUpload,
  imageUploadActive: {
    ...imageUpload,
    backgroundColor: "E8EFF8"
  },
  imageUploadText: {
    color: "#2A2B2C",
    fontSize: 12,
    textAlign: "center",
    width: 70
  },
  image: {
    display: "block"
  },
  imageContainer: {
    border: "1px solid #ced4da"
  },
  label: {
    color: "#2A2B2C",
    marginBottom: 5,
    fontWeight: 300
  },
  error: {
    color: "#DB6E53"
  },
  helperText: {
    color: "#555B6E",
    fontSize: 13,
    fontWeight: 300,
    marginTop: 5
  },
  selectButton: {
    ...buttonStyle
  },
  removeButton: {
    ...buttonStyle,
    color: "#DB6E53",
    border: "1px solid #DB6E53"
  },
  wrapper: {
    marginTop: 4,
    display: "flex",
    alignItems: "center"
  }
};

class ImageUploadField extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    error: PropTypes.bool,
    height: PropTypes.any,
    helperText: PropTypes.string,
    helperTextClassName: PropTypes.string,
    hintText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    id: PropTypes.string.isRequired,
    InputLabelProps: PropTypes.object,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    radius: PropTypes.number.isRequired,
    required: PropTypes.bool,
    width: PropTypes.any
  };

  static defaultProps = {
    className: "",
    error: false,
    fullWidth: false,
    height: "150px",
    label: "",
    labelClassName: "",
    InputLabelProps: {},
    helperText: "",
    helperTextClassName: "",
    FormHelperTextProps: {},
    radius: 0,
    required: false,
    width: "100%"
  };

  constructor(props) {
    super(props);

    this.id = `id_${Math.random()}`;
    this.state = {
      dataURL: null,
      imageWidth: 0,
      imageHeight: 0,
      error: null,
      pending: false,
      url: props.value,
      validationErrors: {}
    };
    this.value = null;
  }

  componentDidMount() {
    if (this.props.value) {
      this.getSizeFromImage(this.props.value);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.value !== nextProps.value) {
      this.value = nextProps.value;
      this.getSizeFromImage(nextProps.value);
      if (!this.state.url) {
        this.setState({ url: nextProps.value }, () =>
          this.getSizeFromImage(nextProps.value)
        );
      }
    }
  }

  getImageSizeAsStyle = () => {
    const { imageHeight, imageWidth } = this.state;
    if (!imageHeight || !imageWidth) return {};

    const content = document.getElementById(this.id);
    if (!content) return {};

    const maxHeight = content.offsetHeight - 2;
    const maxWidth = content.offsetWidth - 2;

    const ratioH = maxHeight / imageHeight;
    const ratioW = maxWidth / imageWidth;
    const ratio = ratioH < ratioW ? ratioH : ratioW;

    const height = imageHeight * ratio;
    const width = imageWidth * ratio;
    const marginTop = (maxHeight - height) / 2;
    const marginLeft = (maxWidth - width) / 2;

    return { width, height, marginLeft, marginTop };
  };

  getSizeAsStyle = () => {
    const { height, width } = this.props;
    const { pending } = this.state;

    const styleHeight = typeof height === "string" ? height : `${height}px`;
    const styleWidth = typeof width === "string" ? width : `${width}px`;

    return {
      height: styleHeight,
      width: styleWidth,
      opacity: pending ? 0.5 : 1.0
    };
  };

  getSizeFromImage = src => {
    if (!src) return;

    const img = new Image();

    img.onload = () => {
      this.setState({ imageWidth: img.width, imageHeight: img.height });
    };

    img.src = src;
  };

  handleDrop = (files, event) => {
    files.length && this.readFileData(files[0]);
  };

  readFileData = file => {
    const reader = new FileReader();

    reader.onload = event => {
      const dataURL = reader.result;
      this.getSizeFromImage(dataURL);

      this.setState({ dataURL, pending: true, url: null }, () =>
        this.uploadFile(dataURL, file)
      );
    };

    reader.readAsDataURL(file);
  };

  uploadFile = (dataURL, file) => {
    const formData = new FormData();

    formData.append("image", file);
    formData.append("data", dataURL);
    formData.append("name", file.name);
    formData.append("type", file.type);

    const errorState = {
      dataURL: null,
      pending: false,
      url: this.props.value
    };

    uploadImage("/api/uploads/", {
      method: "POST",
      credentials: "same-origin",
      body: formData
    })
      .then(responseJsonOrError)
      .then(json => {
        const { error, url_filepath, ...other } = json;

        if (error) {
          this.setState({
            ...json,
            ...errorState
          });
        } else if (!url_filepath) {
          this.setState({
            ...json,
            error: new Error("No url received from server."),
            ...errorState
          });
        } else {
          this.setState({ url: url_filepath, ...other, pending: false }, () =>
            this.props.onChange(url_filepath)
          );
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error,
          ...errorState
        });
      });
  };

  startFileSelector = id => {
    const input = document.getElementById(id);
    if (!input) return;

    input.addEventListener("change", event =>
      this.handleDrop(event.target.files)
    );
    input.click();
  };

  renderError = () => {
    const { classes, error } = this.props;

    return <div className={classes.error}>Error: {error.message}</div>;
  };

  renderLabel = () => {
    const { classes, error, InputLabelProps, required } = this.props;
    const text = this.props.label || "Image upload";

    const className = error
      ? `${classes.formImageLabel} ${classes.error}`
      : classes.formImageLabel;

    return (
      <div className={className} {...InputLabelProps}>
        {required ? `${text} (required)` : text}
      </div>
    );
  };

  renderText = () => {
    const { classes, hintText } = this.props;

    return hintText ? (
      hintText
    ) : (
      <div className={classes.imageUploadText}>
        Drag here or{" "}
        <span
          style={{
            color: "#398DD3"
          }}
        >
          browse
        </span>
      </div>
    );
  };

  renderUploadForm = () => {
    const { classes, id, radius } = this.props;

    return (
      <div className={classes.wrapper}>
        <Dropzone
          className={classes.imageUpload}
          activeClassName={classes.imageUploadActive}
          onDrop={this.handleDrop}
          style={{ ...this.getSizeAsStyle(), borderRadius: radius }}
        >
          {this.renderText()}
        </Dropzone>
        <div
          className={classes.selectButton}
          onClick={() => this.startFileSelector(`${id}-selector`)}
        >
          Select Image
        </div>
        <input id={`${id}-selector`} type="file" style={{ display: "none" }} />
      </div>
    );
  };

  renderImage = () => {
    const { classes, id, radius } = this.props;
    const { dataURL, url } = this.state;

    const imgData = url || dataURL;
    const style = { ...this.getSizeAsStyle(), borderRadius: radius };
    const imgStyle = { ...this.getImageSizeAsStyle(), borderRadius: radius };

    const image = imgStyle.height ? (
      <img
        id={id}
        src={imgData}
        alt=""
        className={classes.image}
        style={imgStyle}
        onClick={() => this.startFileSelector(`${id}-selector`)}
      />
    ) : null;

    return (
      <div className={classes.wrapper}>
        <div id={this.id} className={classes.imageContainer} style={style}>
          <input
            id={`${id}-selector`}
            type="file"
            style={{ display: "none" }}
          />
          {image}
        </div>
        <div
          className={classes.removeButton}
          onClick={() => {
            this.setState({ url: "", dataURL: "" }, () =>
              this.props.onChange("")
            );
          }}
        >
          Remove Image
        </div>
      </div>
    );
  };

  renderHelperText = () => {
    const { classes, helperTextClassName, FormHelperTextProps } = this.props;
    const error = Boolean(this.props.error || this.state.error);

    const helperText = this.state.error
      ? this.state.error.message
      : this.props.helperText;

    if (!helperText) return null;

    const className = `${classes.helperText} ${helperTextClassName}`;
    const style = error ? { color: "#DB6E53" } : {};

    return (
      <div className={className} {...FormHelperTextProps} style={style}>
        {helperText}
      </div>
    );
  };

  renderValidationErrors = () => {
    const { classes } = this.props;
    const { validationErrors } = this.state;

    return Object.keys(validationErrors).map(key => (
      <div
        key={key}
        className={classes.helperText}
        style={{ color: "#DB6E53" }}
      >
        {key}: {validationErrors[key]}
      </div>
    ));
  };

  render() {
    const {
      classes,
      className,
      error,
      fullWidth,
      id,
      labelClassName,
      InputLabelProps,
      helperText,
      helperTextClassName,
      FormHelperTextProps,
      children,
      margin,
      onBlur,
      onChange,
      required,
      _setActivePage,
      ...other
    } = this.props;

    const imgData = this.state.url || this.state.dataURL;

    const _className = [
      classes.root,
      fullWidth ? classes.fullWidth : "",
      className
    ]
      .filter(string => string)
      .join(" ");

    return (
      <div
        className={_className}
        {...other}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        {this.renderLabel()}

        {/* error && this.renderError() */}

        {imgData ? this.renderImage() : this.renderUploadForm()}

        {this.renderHelperText()}
        {this.renderValidationErrors()}
      </div>
    );
  }
}

export default withStyles(styles)(ImageUploadField);
