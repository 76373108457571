import {
  validateTags,
  validateURL,
  validateURLOptional
} from "../../Form/validate";
import * as options from "../config";

const pages = [
  {
    name: "About your Company",
    hash: "#about",
    formFields: [
      {
        id: "about_the_program_sep",
        label: "About your Company",
        helperText:
          "It’s optional, but completing the fields below will help Founder teams to learn more about your company.",
        type: "header"
      },

      {
        label: "Company Name",
        id: "name",
        limit: 80,
        required: true,
        type: "text",
        value: "",
        width: 440
      },
      {
        label: "Company Description",
        id: "description",
        limit: 1000,
        limitErrorText:
          "1000 character max! Try to keep your description short and snappy. Go into detail about your company in the 'Pitch Builder'.",
        placeholder:
          "Tell others about your company's experience and expertise",
        required: true,
        rows: 4,
        value: "",
        width: 440
      },
      {
        id: "website",
        label: "Company Website URL",
        limit: 120,
        width: 440,
        validate: validateURL,
        placeholder: "https://",
        helperText: "Must begin with 'http://' or 'https://'"
      },

      {
        id: "company_type",
        label: "Company type",
        type: "idselect",
        options: options.company_type.options,
        labels: options.company_type.labels,
        value: options.company_type.options[1],
        width: 440
      },
      {
        id: "size",
        label: "Company Size",
        type: "idselect",
        options: options.company_size.options,
        labels: options.company_size.labels,
        width: 440
      },
      {
        label: "Year Founded",
        id: "year_founded",
        type: "number",
        width: 440
      },
      {
        id: "active_in",
        label: "Territories Active In",
        type: "countries",
        helperText: "Search for and select all tags that apply.",
        value: [],
        width: 660
      },
      {
        id: "markets",
        label: "Markets, interests and areas of expertise",
        type: "tags",
        helperText:
          "Search for and select tags that apply. Tags help you connect with relevant founder teams.",
        value: [],
        width: 660,
        validate: validateTags(1, "markets")
      },
      {
        id: "technologies",
        label: "Technologies",
        type: "technologies",
        helperText:
          "If applicable, list your technology stack. You can also create new entries by typing.",
        limit: 400,
        width: 660
      },

      {
        id: "location_sep",
        label: "Company Address",
        type: "header"
      },
      {
        id: "location",
        label: "",
        required: true,
        type: "location",
        showDisableLocation: false,
        width: 440
      },

      {
        id: "location_sep",
        label: "Social Media",
        type: "header"
      },
      {
        label: "LinkedIn Profile URL",
        id: "linkedin",
        limit: 140,
        width: 440,
        value: "",
        helperText:
          "Sharing your LinkedIn profile allows others to connect, and verify who you are. Must begin with 'https://'",
        validate: validateURLOptional,
        placeholder: "https://linkedin.com/"
      },
      {
        label: "Twitter Profile URL",
        id: "twitter",
        limit: 140,
        width: 440,
        value: "",
        helperText:
          "Sharing your Twitter profile allows others to connect, and verify who you are. Must begin with 'https://'",
        validate: validateURLOptional,
        placeholder: "https://twitter.com/@"
      }
    ],
    nextButtonLabel: "Next: Branding"
  },
  {
    name: "Branding",
    hash: "#branding",
    formFields: [
      {
        id: "branding_header",
        label: "Branding",
        helperText:
          "Make your listing stand out by adding a short mission statement and company logo.",
        type: "header"
      },
      {
        id: "mission_statement",
        label: "Company Mission Statement",
        helperText:
          "This one-line description is displayed at the beginning of your company’s profile page. Try a short, snappy description to help others quickly learn about your company. Max 140 characters",
        limit: 140,
        limitErrorText:
          "Your mission statement should be less than 140 characters!",
        required: true,
        value: "",
        width: 660
      },
      {
        id: "logo_image_url",
        label: "Upload your company logo",
        helperText:
          "Company logos should be square and a minimum 400 x 400 pixels. Max 2mb",
        required: true,
        type: "image",
        height: 200,
        width: 200
      }
    ],
    nextButtonLabel: "Next: Pitch"
  },

  {
    name: "Company Pitch",
    hash: "#pitch",
    formFields: [
      {
        id: "header_pitch",
        label: "Company Pitch",
        helperText:
          "Build out your pitch so corporates and investors can truly understand your business. You can use a combination of text, image and video and even attach files.",
        type: "header"
      },
      {
        id: "profile",
        label: "Build program profile",
        type: "profile",
        section: true
        // validate: validateProfile
      }
    ],
    nextButtonLabel: "Next: Funding"
  },
  {
    name: "Funding",
    hash: "#funding",
    guestOnly: true,
    formFields: [
      {
        id: "header_company_funding",
        label: "Funding",
        helperText:
          "Most programs and ThinkTanks require a certain funding stage to participate. Don’t worry, this section is kept confidential, and only the program/ThinkTank builder on our platform can view it.",
        type: "header"
      },
      {
        label: "_Company Funding",
        id: "funding",
        type: "funding"
        // validate: validateCompanyFunding,
      }

      // {
      //   id: "header_company_investors",
      //   label: "Notable Investors",
      //   type: "header"
      // },
      // {
      //   label: "_Notable Investors",
      //   id: "investors",
      //   validate: validateCompanyInvestors,
      //   type: "companyInvestors",
      //   value: {}
      // }
    ],
    nextButtonLabel: "Next: Team"
  },
  {
    name: "Team",
    hash: "#team",
    formFields: [
      {
        id: "header_company_team",
        label: "Team",
        helperText:
          "Complete your contact details and information on the core team at your company.",
        type: "header"
      },
      {
        id: "managers",
        label: "_team editor",
        section: true,
        type: "team",
        // do not send back updates to company object
        updateParent: false
      }
    ]
  }
];

export default pages;
