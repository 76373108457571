import React from "react";
import { PropTypes } from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { OutlineButton } from "../Buttons";

import Testimonial from "./Testimonial";
import * as propTypes from "./propTypes";

const styles = {
  root: {
    fontSize: 15,
    lineHeight: "21px",
    padding: 30
  },
  button: {
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 40
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  }
};

class TestimonialsContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    testimonials: propTypes.testimonials,
    style: PropTypes.object.isRequired,
    timeout: PropTypes.number.isRequired
  };

  static defaultProps = {
    style: {},
    timeout: 8000
  };

  state = {
    current: 0
  };
  interval = null;

  componentDidMount() {
    if (this.props.testimonials.length <= 1) return;

    this.interval = setInterval(this.next, this.props.timeout);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  next = () => {
    const { testimonials } = this.props;
    const current = (this.state.current + 1) % testimonials.length;

    this.setState({ current }, this.resetInterval);
  };

  previous = () => {
    const { testimonials } = this.props;
    const { current } = this.state;

    const _current = current === 0 ? testimonials.length - 1 : current - 1;

    this.setState({ current: _current }, this.resetInterval);
  };

  resetInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(this.next, this.props.timeout);
  };

  renderButtons = () => {
    const { classes, testimonials } = this.props;

    if (testimonials.length <= 1) return null;

    return (
      <div className={classes.buttons}>
        <OutlineButton className={classes.button} onClick={this.previous}>
          <KeyboardArrowLeft />
        </OutlineButton>
        <OutlineButton className={classes.button} onClick={this.next}>
          <KeyboardArrowRight />
        </OutlineButton>
      </div>
    );
  };

  render() {
    const { classes, style, testimonials } = this.props;
    const testimonial = testimonials[this.state.current];

    if (!testimonials.length) return null;

    return (
      <div className={classes.root} style={style}>
        <h3>Testimonials</h3>
        <Testimonial testimonial={testimonial} />
        {this.renderButtons()}
      </div>
    );
  }
}

export { styles, TestimonialsContainer };
export default withStyles(styles)(TestimonialsContainer);
