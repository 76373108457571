import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import {
  approveApplication,
  declineApplication
} from "../../Reducers/Applications";

import CallbackDialog from "../common/CallbackDialog";

const ApproveDeclineDialog = props => {
  const {
    action,
    applications,
    applicationUrl,
    companyName,
    message,
    ...other
  } = props;

  const application = applications.find(app =>
    app.url.startsWith(applicationUrl)
  );

  const { error, _pending, _statusText } = application;
  const onSubmit =
    action === "approve"
      ? data => props.approveApplication(applicationUrl, data)
      : data => props.declineApplication(applicationUrl, data);

  return (
    <CallbackDialog
      error={error}
      loading={_pending}
      fields={[
        {
          name: "response_note",
          label: `Message for ${companyName}`,
          rows: 9,
          value: message
        }
      ]}
      onSubmit={onSubmit}
      statusText={_statusText}
      {...other}
    />
  );
};

ApproveDeclineDialog.propTypes = {
  action: PropTypes.oneOf(["approve", "decline"]),
  applications: PropTypes.array.isRequired,
  applicationUrl: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  message: PropTypes.string
};

const mapStateToProps = state => {
  return {
    applications: state.applications.applications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    approveApplication: (url, data) => dispatch(approveApplication(url, data)),
    declineApplication: (url, data) => dispatch(declineApplication(url, data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveDeclineDialog);
