export const BOX_SHADOW = "0 4px 10px 0 rgba(0,0,0,0.1)";
export const BOX_SHADOW_HOVER = "0 5px 10px 0 rgba(0,0,0,0.1)";

export const BODY_FONT_BLACK = "#2A2B2C";
export const PAGE_BACKGROUND = "#F2F3F4";

export const BLUE = "#398DD3";
export const BLUE_PRIMARY = BLUE;
export const BLUE_SECONDARY = "#1D71B8";
export const BLUE_TERTIARY = "#265FBC";
export const BLUE_HOVER = BLUE_SECONDARY;
export const BLUE_INPUT = "#E8EFE8";
export const INPUT_BLUE = BLUE_INPUT;

export const GREY_DARK = "#555B6E";
export const GREY_MEDIUM = "#AAADB6";
export const GREY_LIGHT = "#E6E7E9";
export const GREY_INPUT = "#EDEDEF";
export const INPUT_GREY = GREY_INPUT;
export const LIGHT_GREY = GREY_LIGHT;

export const GREEN = "#85C97C";
export const GREEN_HOVER = "#6EA367";

export const RED = "#DB6E53";
export const RED_HOVER = "#B05A45";

export const MUSTARD = "#CEA751";

export const WHITE = "#FFFFFF";
