import React from "react";

import Banner from "../components/common/Banner";
import LoadingPanel from "../components/common/LoadingPanel";

import PageTemplate from "./PageTemplate";

const titleMap = {
  dashboard: "My Dashboard",
  connections: "My Connections",
  mycompany: "Company Profile",
  myprofile: "User Profile",

  corporates: "Corporates",
  startups: "Founder Teams",

  marketplace: "Marketplace",
  programs: "Programs",
  challenges: "Think Tanks",
  challenge: "Think Tank"
};

const LoadingPage = props => {
  const { path } = props;

  const basepath = path.split("/")[1] || "";
  const title = titleMap[basepath] || "Colinked";

  return (
    <PageTemplate>
      <Banner title={title} subtitle="...loading" />
      <LoadingPanel />
    </PageTemplate>
  );
};

LoadingPage.defaultProps = {
  path: ""
};

export default LoadingPage;
