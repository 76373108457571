import CompaniesReducer from "./CompaniesReducer";
export default CompaniesReducer;

export { COMPANY_TYPE_CORP, COMPANY_TYPE_FOUNDER } from "./constants";

export {
  loadCompanyDetails,
  loadCorporates,
  loadFounders,
  updateCompany,
  updateCorporates,
  updateFounders
} from "./CompaniesActions";
