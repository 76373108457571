import { combineReducers } from "redux";

import applicatonsReducer from "./Applications";
import companiesReducer from "./Companies";
import companyReducer from "./Company";
import configReducer from "./Config";
import connectionsReducer from "./Connections";
// import corporationsReducer from "./Corporations";
import countriesReducer from "./Countries";
import flashNotesReducer from "./FlashNotes";
import marketplace from "./Marketplace";
import myApplicationsReducer from "./MyApplications";
import notificationsReducer from "./Notifications";
import profileReducer from "./Profile";
import redirectReducer from "./Redirect";
import referralsReducer from "./Referrals";
import scrollviewReducer from "./ScrollView";
import startupsReducer from "./Startups";
import tagsReducer from "./Tags";
import technologiesReducer from "./Technologies";
import validationErrorsReducer from "./ValidationErrors";

export default combineReducers({
  applications: applicatonsReducer,
  companies: companiesReducer,
  company: companyReducer,
  config: configReducer,
  connections: connectionsReducer,
  // corporation: corporationsReducer,
  countries: countriesReducer,
  flashNotes: flashNotesReducer,
  marketplace: marketplace,
  myApplications: myApplicationsReducer,
  notifications: notificationsReducer,
  profile: profileReducer,
  redirect: redirectReducer,
  referrals: referralsReducer,
  scrollview: scrollviewReducer,
  startups: startupsReducer,
  tags: tagsReducer,
  technologies: technologiesReducer,
  validationErrors: validationErrorsReducer
});
