import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import finalImage from "../../assets/member_application_final.svg";

import { getOutlineButtonClasses } from "../../styles/buttonStyles";

const buttonStyles = getOutlineButtonClasses("#2A2B2C", "large");

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    fontSize: 16,
    textAlign: "center",
    width: 500,
    margin: "100px auto"
  },
  bold: {
    fontWeight: 700,
    marginTop: 15,
    marginBottom: 30
  },
  green: {
    color: "#85C97C",
    fontSize: 32,
    fontWeight: 600
  },
  image: {
    width: 156,
    height: 119,
    marginBottom: 30
  },

  buttonRoot: {
    ...buttonStyles.root,
    marginTop: 30
  },
  buttonLabel: {
    ...buttonStyles.label
  }
};

const ApplicationCompleted = props => {
  const { classes, doctype, onClick } = props;

  const buttonLabel =
    doctype === "program" ? "View Program" : "View Think Tank";
  const _doctype = doctype === "program" ? "program" : "Think Tank";

  return (
    <div className={classes.root}>
      <img src={finalImage} alt="" className={classes.image} />
      <div className={classes.green}>Woohoo!</div>
      <div className={classes.bold}>
        {`Your ${_doctype} application has been submitted!`}
      </div>
      <div>
        {`The ${_doctype} owner will now review your application and respond with a
        decision in due course. Good luck!`}
      </div>

      {onClick && (
        <Button
          classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
          onClick={onClick}
        >
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

ApplicationCompleted.propTypes = {
  classes: PropTypes.object.isRequired,
  doctype: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

ApplicationCompleted.defaultProps = {
  doctype: ""
};

export { ApplicationCompleted, styles };
export default withStyles(styles)(ApplicationCompleted);
