import React from "react";
import { PropTypes } from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import placeholderLogo from "../../../assets/corp_logo_placeholder.png";

import { BODY_FONT_BLACK } from "../../../styles/colors";

const styles = {
  root: {
    fontSize: 15,
    lineHeight: "18px",
    padding: 30
  },
  logo: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    boxShadow: "0 2px 20px 0 rgba(0,0,0,0.1)",
    marginRight: 15
  },
  mission: {
    marginLeft: 50
  },
  name: {
    color: BODY_FONT_BLACK,
    fontWeight: 700,
    "&:hover": {
      color: BODY_FONT_BLACK,
      textDecoration: "underline"
    }
  }
};

const CompanyBranding = props => {
  const { classes, company, placeholderLogo } = props;
  const { logo_image_url, mission_statement, name, website } = company;

  const companyLink = website ? (
    <a
      className={classes.name}
      href={website}
      alt={name}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  ) : (
    <strong>{name}</strong>
  );

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          className={classes.logo}
          src={logo_image_url || placeholderLogo}
          alt="company logo"
        />
        <div>Provided by {companyLink}</div>
      </div>
      {mission_statement && (
        <div className={classes.mission}>{mission_statement}</div>
      )}
    </div>
  );
};

CompanyBranding.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.shape({
    logo_image_url: PropTypes.string,
    mission_statement: PropTypes.string,
    name: PropTypes.string.isRequired,
    website: PropTypes.string
  }).isRequired,
  placeholderLogo: PropTypes.string
};

CompanyBranding.defaultProps = {
  placeholderLogo
};

export { styles, CompanyBranding };
export default withStyles(styles)(CompanyBranding);
