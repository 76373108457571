import React from "react";
import { PropTypes } from "prop-types";

import emailvalidator from "email-validator";

import { withStyles } from "@material-ui/core/styles";

import imageSubmissionComplete from "../../assets/images/homepage/colinked-form-submission-complete-icon.svg";

import { responseJsonOrError } from "../../api/response";
import ErrorPanel from "../common/Errors/ErrorPanel";

export const styles = {
  appBarRoot: { paddingTop: 5, paddingBottom: 0 },
  tabsRoot: { fontSize: "16px", padding: 0 },
  tabRoot: { fontSize: "16px", marginRight: 0 },
  medium: { fontSize: "16px" }
};

class SignupForm extends React.Component {
  static propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    showLinkedin: PropTypes.bool,
    testProps: PropTypes.object.isRequired,
    url: PropTypes.oneOf([
      "/api/companies/corporations/apply/",
      "/api/companies/founders/apply/",
      "/api/companies/investors/apply/"
    ]).isRequired
  };

  static defaultProps = {
    buttonLabel: "Join Now",
    testProps: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      pending: false,
      success: false,
      linkedin_url: "",
      validationErrors: {},

      // allow setting of state for testing
      ...props.testProps
    };
  }

  is_incomplete = () => {
    if (!this.state.company_name) return true;
    if (!this.state.email) return true;
    if (!this.state.first_name) return true;
    if (!this.state.last_name) return true;

    if (!emailvalidator.validate(this.state.email)) return true;

    return false;
  };

  submit = evt => {
    const { url } = this.props;
    const { error, pending, ...data } = this.state;

    evt && evt.preventDefault();

    fetch(url, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(responseJsonOrError)
      .then(json => {
        const success = !json.error;

        this.setState({ ...json, pending: false, success });
      });
  };

  validateField = (name, value, required) => {
    const { validationErrors: ve } = this.state;

    if (!required) return;

    if (!value.trim()) {
      this.setState({
        validationErrors: { ...ve, [name]: "This field is required." }
      });
    } else if (name === "email" && !emailvalidator.validate(this.state.email)) {
      this.setState({
        validationErrors: {
          ...ve,
          email: "Enter a valid email address"
        }
      });
    } else {
      delete ve[name];

      this.setState({ validationErrors: { ...ve } });
    }
  };

  renderField = (name, label, required = false) => {
    const { validationErrors } = this.state;
    const error = validationErrors[name];

    const style = error
      ? {
          color: "#DB6E53",
          backgroundColor: "#F9EFED",
          border: "1px solid #DB6e53"
        }
      : {};

    return (
      <div>
        <label style={{ color: style.color }}>{label}</label>
        <input
          style={style}
          type="text"
          name={name}
          value={this.state[name] || ""}
          onBlur={evt => this.validateField(name, evt.target.value, required)}
          onChange={evt => this.setState({ [name]: evt.target.value })}
        />
        {error && (
          <span className="helperText" style={{ color: style.color }}>
            {error}
          </span>
        )}
      </div>
    );
  };

  renderFormFields = () => {
    const { buttonLabel, showLinkedin } = this.props;
    const { error, pending } = this.state;

    const disabled = Boolean(error) || pending || this.is_incomplete();

    return (
      <div className="tabbertab">
        <div className="form">
          {this.renderField("company_name", "Company Name", true)}
          {this.renderField("email", "Work Email Address", true)}
          {this.renderField("first_name", "First Name", true)}
          {this.renderField("last_name", "Last Name", true)}
          {this.renderField("job_title", "Job Title / Position")}

          {showLinkedin && this.renderLinkedIn()}

          <button
            className="btn"
            disabled={disabled}
            onClick={() => this.setState({ pending: true }, this.submit)}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    );
  };

  renderLinkedIn = () => {
    return (
      <React.Fragment>
        {this.renderField("linkedin_url", "LinkedIn Profile")}
        <p className="guidance">
          Optional - Your LinkedIn profile will help us verify your Colinked
          application more quickly
        </p>
      </React.Fragment>
    );
  };

  render() {
    const { error, success } = this.state;

    if (success) {
      return (
        <div style={{ textAlign: "center", marginBottom: 100 }}>
          <img
            style={{ marginTop: 60, marginBottom: 20 }}
            src={imageSubmissionComplete}
            alt="Colinked - Character avatars shaking hands"
          />

          <h2>We have received your sign up request!</h2>
          <p>
            Thanks for signing up! Once we have reviewed your details we will
            email you with information on how to complete your Colinked
            registration.
          </p>
        </div>
      );
    }

    return (
      <div>
        {error && <ErrorPanel error={error} />}
        {!error && this.renderFormFields()}
      </div>
    );
  }
}

export default withStyles(styles)(SignupForm);
