import React from "react";
import { PropTypes } from "prop-types";

import { validate } from "../Form/validate";

import FormBuilderPage from "./FormBuilderPage";

import {
  compareDocuments,
  getCompletedPages,
  getFirstPageWithErrors,
  state2document,
  scrollElementIntoView
} from "./utils";

class FormBuilder extends React.Component {
  static propTypes = {
    PreviewComponent: PropTypes.func,

    createFlashNote: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.shape({
      hash: PropTypes.string.isRequired
    }).isRequired,

    duplicateDocument: PropTypes.func,
    deleteDocument: PropTypes.func,
    updateDocument: PropTypes.func.isRequired,

    handleSave: PropTypes.func,

    document: PropTypes.object.isRequired,
    state2document: PropTypes.func.isRequired,

    pages: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        formFields: PropTypes.array.isRequired,
        nextButtonLabel: PropTypes.string
      })
    ).isRequired,

    clearValidationError: PropTypes.func.isRequired,
    setValidationError: PropTypes.func.isRequired,
    setValidationErrors: PropTypes.func.isRequired,
    validationErrors: PropTypes.object.isRequired,

    isRegistrationForm: PropTypes.bool,
    menuId: PropTypes.string.isRequired,
    redirectOnPublish: PropTypes.bool,
    validate: PropTypes.func.isRequired
  };

  static defaultProps = {
    isRegistrationForm: false,
    menuId: "formbuilder-menu",
    pages: [],
    state2document,
    validate,
    validationErrors: {}
  };

  _unmounting = false;

  constructor(props) {
    super(props);

    const { document } = props;

    this.previewHeaderId = "PreviewHeader";

    this.state = {
      // dynamic state (local to FormBuilder)
      _activePage: 0,
      _completedPages: getCompletedPages(props.pages, document),
      _hasUnsavedChanges: false,

      // use _isUnsavedDraft flag to inititialize visited pages
      // for new documents; existing docs won't have it set so all
      // pages are marked as visited
      _visitedPages: props.pages.map((p, _idx) =>
        _idx === 0 ? true : !document._isUnsavedDraft
      ),

      // part of document
      _isUnsavedDraft: false,
      is_approved: 1,

      // set document data as state
      ...document
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.hash !== "") {
      // try to find targe page form location hash
      const hashes = this.props.pages.map(page => page.hash);
      const _activePage = hashes.indexOf(location.hash);

      if (_activePage >= 0) {
        this.setState({ _activePage });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.document !== this.props.document) {
      this.setState({ ...nextProps.document });
    }
  }

  componentWillUnmount() {
    this._unmounting = true;
  }

  formIsCompleted = () => {
    const { _completedPages, _visitedPages } = this.state;

    return _completedPages.every(v => v) && _visitedPages.every(v => v);
  };

  getPreviewAction = () => {
    const { is_approved, _hasUnsavedChanges, _isUnsavedDraft } = this.state;

    if (_isUnsavedDraft) return this.handlePublish;

    if (!_hasUnsavedChanges) return null;

    return is_approved === 2 ? this.handleUpdate : this.handlePublish;
  };

  handleBlur = id => () => {
    this.validate(id, true);
  };

  handleChange = id => value => {
    this.setState({ [id]: value }, () => this.validate(id));
  };

  handleDelete = () => {
    const { createFlashNote, document } = this.props;

    if (!this.props.deleteDocument) return;

    return this.props.deleteDocument(document.id).catch(error => {
      createFlashNote(error.message, "error");
    });
  };

  handleDuplicate = () => {
    if (!this.props.duplicateDocument) return;

    const { createFlashNote, history } = this.props;
    const { doctype, id } = this.state;

    // return promise here just to be able to test it ...
    return this.props
      .duplicateDocument(id)
      .then(result => {
        const { error } = result;

        if (error) {
          createFlashNote(error.message, "error");
          return;
        }

        createFlashNote(`duplicate ${doctype} created`);

        // update url
        history.push(`/${doctype}/${result.id}/edit`);

        // manually update state because FormBuilder won't reload page
        // on url change to /{doctype}/{id}/edit
        const data = {
          // editor state
          _activePage: 0,
          _hasUnsavedChanges: false,
          _isUnsavedDraft: false,

          // new document data
          ...result
        };

        this.setState(data);
      })
      .catch(error => {
        createFlashNote(error.message, "error");
      });
  };

  handlePublish = () => {
    const link = this.props.redirectOnPublish ? this.state.link : null;

    this.setState({ is_approved: 2 }, () => this.handleUpdate(null, link));
  };

  handleSave = () => {
    this.setState({ is_approved: 1 }, this.handleUpdate);
  };

  handleUpdate = (evt, redirect) => {
    const data = this.props.state2document(this.state);

    // return promise here just to be able to test it ...
    return this.props
      .updateDocument(data)
      .then(results => {
        if (results.error) {
          const activePage = getFirstPageWithErrors(
            results.validationErrors || {},
            this.props.pages
          );

          this.setActivePage(activePage);
        } else {
          // reset form state
          !this._unmounting &&
            this.setState({
              _hasUnsavedChanges: false,
              _isUnsavedDraft: false
            });

          if (redirect) {
            this.props.history.push(redirect);
          }
        }
      })
      .catch(error => console.log(error));
  };

  getCompletedPages = () => {
    return this.props.pages.map(this.isPageComplete);
  };

  getVisitedPages = page => {
    const _visitedPages = this.state._visitedPages.map((value, index) =>
      index === page ? true : value
    );

    return _visitedPages;
  };

  isPageComplete = page => {
    const validationErrors = this.props.validate(this.state, page.formFields);

    return Boolean(!Object.keys(validationErrors).length);
  };

  scrollIntoView = () => {
    const { menuId, pages, validationErrors } = this.props;

    const fieldIds = pages[this.state._activePage].formFields
      .filter(field => field.type !== "header")
      .filter(field => field.type !== "separator")
      .map(field => field.id)
      .filter(id => validationErrors[id]);

    // break here if run during test
    if (!window.scrollTo) return;

    // scroll to first error on page
    if (fieldIds.length && scrollElementIntoView(fieldIds[0])) return;

    // try to scroll to top of menu bar
    if (scrollElementIntoView(menuId)) return;

    // try to scroll to top of stepper
    if (scrollElementIntoView("formStepper")) return;

    // if nothing else works
    window.scrollTo(0, 0);
  };

  setActivePage = _activePage => {
    if (_activePage < 0) return;
    if (_activePage >= this.props.pages.length) return;

    const _visitedPages = this.state._visitedPages.map((p, _idx) =>
      _idx === _activePage ? true : p
    );

    this.setState({ _activePage, _visitedPages }, this.scrollIntoView);
  };

  validate = (id, setError = false) => {
    const { _activePage } = this.state;
    const _completedPages = [...this.state._completedPages];
    const page = this.props.pages[_activePage];

    const fields = page.formFields.filter(field => field.id === id);

    const validationErrors = this.props.validate(this.state, fields);

    if (!validationErrors[id]) {
      this.props.clearValidationError(id);
    } else if (setError) {
      this.props.setValidationError(id, validationErrors[id]);
    }

    // validate all fields in page to set completed state
    _completedPages[_activePage] = this.isPageComplete(page);

    // check that all requried fields are provided
    const _isValid = this.validateDocumentFields(page.formFields);

    // check that current state differs from props.document (original)
    const _hasUnsavedChanges = compareDocuments(
      this.props.document,
      this.state
    );

    // mark current page as visited (for stepper icons)
    const _visitedPages = this.getVisitedPages();

    this.setState({
      _completedPages,
      _hasUnsavedChanges,
      _isValid,
      _visitedPages
    });
  };

  validateDocumentFields = fields => {
    const validationErrors = this.props.validate(this.state, fields);

    if (Object.keys(validationErrors).length) {
      return false;
    }

    return true;
  };

  render() {
    return (
      <FormBuilderPage
        {...this.props}
        activePage={this.state._activePage}
        visitedPages={this.state._visitedPages}
        document={{ ...this.state, isCompleted: this.formIsCompleted() }}
        handleBlur={this.handleBlur}
        handleChange={this.handleChange}
        handleDelete={this.props.deleteDocument ? this.handleDelete : null}
        handleDuplicate={
          this.props.duplicateDocument ? this.handleDuplicate : null
        }
        handlePublish={this.handlePublish}
        handleSave={this.props.handleSave || this.handleSave}
        handleUpdate={this.handleUpdate}
        setActivePage={this.setActivePage}
      />
    );
  }
}

export default FormBuilder;
