import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../../Icons";

const styles = {
  root: {
    position: "absolute",
    top: 8,
    right: 13,
    height: 20,
    padding: 2
  },
  disabled: {
    fill: "#AAADB6",
    fontSize: 20,
    marginLeft: 10
  },
  icon: {
    fill: "#2A2B2C",
    fontSize: 20,
    cursor: "pointer",
    marginLeft: 10,
    "&:first-child": {
      marginLeft: 0
    }
  }
};

const Controls = props => {
  const { classes, deleteFrame, moveDown, moveUp, style } = props;

  return (
    <div className={classes.root} style={style}>
      <Icons.ArrowUp
        className={moveUp ? classes.icon : classes.disabled}
        onClick={moveUp}
      />
      <Icons.ArrowDown
        className={moveDown ? classes.icon : classes.disabled}
        onClick={moveDown}
      />
      <Icons.TrashCanOutline
        className={deleteFrame ? classes.icon : classes.disabled}
        onClick={deleteFrame}
      />
    </div>
  );
};

Controls.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteFrame: PropTypes.func,
  moveDown: PropTypes.func,
  moveUp: PropTypes.func,
  style: PropTypes.object.isRequired
};

Controls.defaultProps = {
  style: {}
};

export { Controls, styles };
export default withStyles(styles)(Controls);
