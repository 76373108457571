import React from "react";
import PropTypes from "prop-types";

import Avatar from "@material-ui/core/Avatar";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../Icons";

import SocialLinks from "../common/SocialLinks";
import TagLabel from "../common/TagLabel";

const styles = {
  root: {
    //outline: "1px solid red",
    display: "flex",
    marginTop: 20,
    minWidth: 350
  },
  avatar: {
    marginRight: 20,
    marginLeft: 0,
    width: 64,
    height: 64
  },
  avatarPlaceholder: {
    marginRight: 20,
    width: 64,
    height: 64,
    fill: "lightgrey"
  },
  bio: {
    marginTop: 10
  },
  content: {
    color: "#2A2B2C",
    fontSize: 14,
    fontWeight: 300
  },
  controls: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end"
  },

  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  emailLink: {
    color: "#398DD3",
    fontSize: 14,
    fontWeight: 700,
    cursor: "pointer",
    marginTop: 10,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  icon: {
    width: 20,
    height: 20,
    fill: "#AAADB6"
  },
  name: {
    display: "flex",
    fontWeight: 700
  },
  job_title: {
    fontStyle: "italic",
    marginTop: 8
  },
  social: {
    marginTop: 10
  },
  tagRoot: {
    marginLeft: 10
  }
};

const renderTags = props => {
  const {
    classes,
    editView,
    is_archived,
    is_public,
    is_company_founder,
    is_primary_contact,
    role
  } = props;

  const tagOptions = {
    classes: { root: classes.tagRoot },
    solid: true
  };

  if (!editView) {
    return is_company_founder ? (
      <TagLabel {...tagOptions} solid={false} color="blue" label="Founder" />
    ) : null;
  }

  return (
    <React.Fragment>
      {role && (
        <TagLabel
          {...tagOptions}
          color="grey"
          label={role.replace(/^\w/, c => c.toUpperCase())}
        />
      )}

      {is_archived && (
        <TagLabel {...tagOptions} color="grey" label="Archived" />
      )}

      {is_company_founder && (
        <TagLabel {...tagOptions} solid={false} color="blue" label="Founder" />
      )}

      {is_primary_contact && (
        <TagLabel {...tagOptions} color="green" label="Primary Contact" />
      )}

      {!is_public && <TagLabel {...tagOptions} color="red" label="Hidden" />}
    </React.Fragment>
  );
};

const ContactCardSmall = props => {
  const {
    bio,
    classes,
    controls,
    email,
    first_name,
    last_name,
    job_title,
    showEmail,
    showSocial
  } = props;

  const name = `${first_name} ${last_name}`;

  const avatar =
    props.avatar_image_url || props.avatar_image_url ? (
      <Avatar
        alt={last_name}
        src={props.avatar_image_url || props.avatar_image_url}
        className={classes.avatar}
      />
    ) : (
      <Icons.AccountCircle className={classes.avatarPlaceholder} />
    );

  const _email = showEmail ? (
    <div className={classes.emailLink}>
      <a href={`mailto:${email}`} alt={email}>
        {email}
      </a>
    </div>
  ) : null;

  const social = showSocial ? (
    <SocialLinks
      {...props}
      classes={{ root: classes.social }}
      linkedin={props.linkedin || props.social_linkedin}
      twitter={props.twitter || props.social_twitter}
      spacing={10}
    />
  ) : null;

  return (
    <div className={classes.root}>
      {avatar}
      <div className={classes.details}>
        <div className={classes.content}>
          <div className={classes.name}>
            {name}
            {renderTags(props)}
            {controls && <div className={classes.controls}>{controls}</div>}
          </div>
          <div className={classes.job_title}>{job_title}</div>
          <div className={classes.bio}>{bio}</div>
          {_email}
          {social}
        </div>
      </div>
    </div>
  );
};

ContactCardSmall.propTypes = {
  // avatar_image_url: null
  // email: "bdo@white.space"
  // first_name: "Mark"
  // id: 2
  // is_archived: false
  // is_company_founder: false
  // is_only_admin: true
  // is_public: false
  // last_name: "Jones"
  // role: "admin"
  // username: "bdo@white.space"

  classes: PropTypes.object.isRequired,

  // user account
  id: PropTypes.number,

  avatar_image_url: PropTypes.string,
  bio: PropTypes.string,
  email: PropTypes.string,

  first_name: PropTypes.string,
  last_name: PropTypes.string,
  job_title: PropTypes.string,
  username: PropTypes.string,

  linkedin: PropTypes.string,
  twitter: PropTypes.string,

  is_archived: PropTypes.bool,
  is_company_founder: PropTypes.bool,
  is_primary_contact: PropTypes.bool,
  is_public: PropTypes.bool,
  is_only_admin: PropTypes.bool,
  role: PropTypes.string,

  // UI state
  controls: PropTypes.node,
  showEmail: PropTypes.bool,
  showSocial: PropTypes.bool
};

ContactCardSmall.defaultProps = {
  avatar_image_url: "",
  bio: "",
  email: "",

  first_name: "",
  last_name: "",
  job_title: "",
  username: "",

  is_archived: false,
  is_company_founder: false,
  is_primary_contact: false,
  is_public: true,
  is_only_admin: false,
  role: "",

  linkedin: "",
  twitter: "",

  editView: false,
  showEmail: false,
  showSocial: false
};

export default withStyles(styles)(ContactCardSmall);
