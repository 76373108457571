function DelayPromise(delay) {
  //return a function that accepts a single variable
  return function(data) {
    //this function returns a promise.
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        //a promise that is resolved after "delay" milliseconds with the data provided
        resolve(data);
      }, delay);
    });
  };
}

export const delayedResponse = (resolveData, rejectData = null, delay = 1500) =>
  new Promise((resolve, reject) => {
    if (rejectData) {
      console.log("reject");
      return reject(rejectData);
    } else {
      console.log("resolve");
      return resolve(resolveData);
    }
  }).then(DelayPromise(delay));

//
// merge lists: update existing objects, add new
//
export const mergeListsById = (oldList, newList) => {
  // build oldMap indexed by obj.id based on oldList
  const oldMap = oldList.reduce((byId, obj) => {
    byId[obj.id] = obj;

    return byId;
  }, {});

  // update or add to companies in oldMap
  const newMap = newList.reduce((byId, obj) => {
    const existing = byId[obj.id];

    if (existing) {
      byId[obj.id] = { ...existing, ...obj };
    } else {
      byId[obj.id] = obj;
    }

    return byId;
  }, oldMap);

  // return map values as array
  return Object.values(newMap);
};

export const updateElementById = (oldList, obj) => {
  if (oldList.find(_o => _o.id === obj.id)) {
    return [...oldList].map(_o => (_o.id !== obj.id ? _o : { ..._o, ...obj }));
  } else {
    return [...oldList, obj];
  }
};
