import React from "react";
import { PropTypes } from "prop-types";

import { PrimaryButtonLargeRed } from "../../common/Buttons";

import CallbackDialog from "../../common/CallbackDialog";

export const HELPER_TEXT_DISABLED =
  "You can't delete your account because you are the sole administrator for your company. If you wish to delete your company from Colinked, please contact the Colinked admin team.";

export const HELPER_TEXT =
  "Here you can delete your account from Colinked. Your account can be restored within the next 7 days. To restore an account contact an administrative user of your company.";

class DeleteAccountForm extends React.Component {
  static propTypse = {
    classes: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    deleteAccount: PropTypes.func.isRequired,
    helperText: PropTypes.string.isRequired,
    helperTextDisabled: PropTypes.string.isRequired
  };

  static defaultProps = {
    helperText: HELPER_TEXT,
    helperTextDisabled: HELPER_TEXT_DISABLED
  };

  state = {
    modal: false
  };

  renderDialog = () => {
    const { deleteAccount } = this.props;

    const fields = [
      {
        name: "",
        helperText:
          "You are about to delete your account from Colinked. Do you want to continue?"
      },
      {
        name: "password",
        label: "Please confirm your password",
        type: "password"
      }
    ];

    return (
      <CallbackDialog
        buttonLabel="Delete Account"
        buttonStyle="warning"
        fields={fields}
        onSubmit={data => {
          const { password } = data;

          deleteAccount(password);
          this.setState({ modal: false });
        }}
        onClose={() => this.setState({ modal: false })}
        title="Delete Colinked Account"
      />
    );
  };

  render() {
    const { classes, helperTextDisabled, profile } = this.props;

    const disabled = Boolean(profile.is_last_admin);
    const helperText = disabled ? helperTextDisabled : this.props.helperText;

    return (
      <div className={classes.root}>
        {this.state.modal && this.renderDialog()}
        <PrimaryButtonLargeRed
          className={classes.buttonDelete}
          disabled={disabled}
          onClick={() => this.setState({ modal: true })}
        >
          Delete Account
        </PrimaryButtonLargeRed>
        <div className={classes.helperText} style={{ marginLeft: 30 }}>
          {helperText}
        </div>
      </div>
    );
  }
}

export default DeleteAccountForm;
