import React from "react";
import { PropTypes } from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import { buttonPrimaryClasses } from "../../../styles/buttonStyles";

import Icons from "../../Icons";

const styles = {
  ...buttonPrimaryClasses,
  root: {
    ...buttonPrimaryClasses.root,
    marginLeft: 0,
    marginTop: 30,
    "&:first-child": {
      marginTop: 0
    }
  },
  disabled: {
    ...buttonPrimaryClasses.disabled,
    marginLeft: 0,
    marginTop: 30
  },
  iconClass: {
    fill: "white",
    marginRight: 5
  }
};

const InviteTeamMemberButton = props => {
  const { classes, Icon, label, children, onClick, ...other } = props;

  const buttonClasses = {
    root: classes.root,
    disabled: classes.disabled,
    label: classes.label
  };

  return (
    <Button classes={buttonClasses} {...other} onClick={onClick}>
      <Icon className={classes.iconClass} />
      {label || children}
    </Button>
  );
};

InviteTeamMemberButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  Icon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

InviteTeamMemberButton.defaultProps = {
  label: "Invite Team Member",
  Icon: Icons.PlusCircleOutline
};

export { InviteTeamMemberButton, styles };
export default withStyles(styles)(InviteTeamMemberButton);
