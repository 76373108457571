import React from "react";
import { withRouter } from "react-router-dom";

import LogoContainer from "./LogoContainer";

const PartnersSection = props => {
  const { history } = props;

  return (
    <section className="homepage-customers">
      <section className="customer-block">
        <div className="text left">
          <h2>Colinked for Corporates</h2>
          <p>
            We know being innovative and engaging with scale-ups is extremely
            challenging, especially scouting and discovery. We’ve taken care of
            that and ensure you interact with vetted companies that match what
            your criteria. Through Colinked, you can:
          </p>
          <ul>
            <li className="post">
              Post your existing corporate programs from Accelerators to
              Hackathons
            </li>
            <li className="find">
              Find quality scale ups - all referred by other corporates
            </li>
            <li className="engage">
              Engage with other corporates with ThinkTanks
            </li>
          </ul>
          <button
            className="btn"
            onClick={() => history.push("/forcorporates")}
          >
            Learn More <span>➔</span>
          </button>
        </div>
        <LogoContainer url="/api/companies/corporations/" />
      </section>

      <section className="customer-block reverse">
        <LogoContainer
          left={true}
          url="/api/companies/founders/"
          duration={1300}
        />

        <div className="text">
          <h2>Colinked for Founders</h2>
          <p>
            Through Colinked you can engage with not just the right corporates,
            but the right people in those corporates. Engage with corporate
            members searching for your solution and build out your market.
            Through Colinked, you can:
          </p>
          <ul>
            <li className="discover">
              Discover corporate engagement programs, from incubators to
              conferences
            </li>
            <li className="reach">
              Reach out to specific corporate members - currently 90+ global
              clients
            </li>
            <li className="invest">
              Get your company investment ready through our InvestReady? Service
            </li>
          </ul>
          <button className="btn" onClick={() => history.push("/forfounders")}>
            Learn More <span>➔</span>
          </button>
        </div>
      </section>
    </section>
  );
};

export default withRouter(PartnersSection);
