import React from "react";
import { PropTypes } from "prop-types";

// import Link from "react-router-com"

import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

// <Link to={this.props.myroute} onClick='hello()'>Here</Link>

const styles = {
  root: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  }
};

const LocalLink = props => {
  const {
    children,
    classes,
    history,
    label,
    link,
    location,
    match,
    staticContext,
    ...other
  } = props;

  const onClick = () => {
    if (window.ga) {
      //console.log(`ga("send", "event", "Link", "click", "${link}")`);
      window.ga("send", "event", "Link", "click", link);
    }
    history.push(link);
  };

  return (
    <span className={classes.root} onClick={onClick} {...other}>
      {label || children}
    </span>
  );
};

LocalLink.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  label: PropTypes.string,
  link: PropTypes.string.isRequired
};

export default withRouter(withStyles(styles)(LocalLink));
