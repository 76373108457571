import { connect } from "react-redux";

import DocumentDetailsViewConnected from "../Document/DocumentDetailsViewConnected";

import ServiceBanner from "./ServiceBanner";
import ServiceDetailsView from "./ServiceDetailsView";
import ServiceManagePanel from "./ServiceManagePanel";

import { loadService } from "../../Reducers/Marketplace";

const mapStateToProps = state => {
  return {
    BannerComponent: ServiceBanner,
    ManagePanelComponent: ServiceManagePanel,
    MenuComponent: null,
    ViewComponent: ServiceDetailsView,
    company: state.company,
    doctype: "service",
    documents: state.marketplace,

    profile: state.profile,
    setWindowTitle: doc => {
      window.document.title = `${doc.description} | Colinked`;
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadDocument: id => dispatch(loadService(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentDetailsViewConnected);
