export const SET_CONNECTIONS = "SET_CONNECTIONS";
export const SET_CONNECTIONS_LOADING = "SET_CONNECTIONS_LOADING";
export const TOGGLE_CONNECTION_CONTACTS = "TOGGLE_CONNECTION_CONTACTS";
export const UPDATE_CONNECTION = "UPDATE_CONNECTION";
export const UPDATE_CONNECTIONS = "UPDATE_CONNECTIONS";

export const setConnections = connections => {
  return { type: SET_CONNECTIONS, payload: connections };
};

export const setConnectionsLoading = () => {
  return { type: SET_CONNECTIONS_LOADING };
};

export const toggleConnectionContacts = (id, value) => {
  return { type: TOGGLE_CONNECTION_CONTACTS, payload: { id, value } };
};

export const updateConnectionAction = (id, data) => {
  return { type: UPDATE_CONNECTION, payload: { ...data, id } };
};

export const updateConnectionsList = connections => {
  return { type: UPDATE_CONNECTIONS, payload: connections };
};
