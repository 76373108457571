import React from "react";
import { PropTypes } from "prop-types";

import { loadParticipants } from "../../../Applications/api";

import DocumentParticipants from "./DocumentParticipants";

class DocumentParticipantsContainer extends React.Component {
  static propTypes = {
    doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    skipLoading: PropTypes.bool
  };

  // flag to stop setState during unmount
  _isUnloading = false;

  state = {
    participants: []
  };

  componentDidMount() {
    const { doctype, id, skipLoading } = this.props;

    // don't load participants during preview
    if (skipLoading) return;

    loadParticipants(doctype, id)
      .then(res => this.setParticipants(res.results))
      .catch(error => this.setState({ error }));
  }

  setParticipants = results => {
    if (this._isUnloading) return;

    const participants = results.filter(p => p.is_public).map(p => p.company);

    this.setState({ participants });
  };

  componentWillUnmount() {
    this._isUnloading = true;
  }

  render() {
    const { participants } = this.state;

    return <DocumentParticipants participants={participants} />;
  }
}

export default DocumentParticipantsContainer;
