import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import ChevronDown from "@material-ui/icons/KeyboardArrowDown";
import ClearIcon from "@material-ui/icons/Clear";

import { createFlashNote } from "../../../Reducers/FlashNotes";
import { getOutlineButtonClasses } from "../../../styles/buttonStyles";
import SubMenuButton from "../../common/Buttons/SubMenuButton";
import ContactCardSmall from "../../Contacts/ContactCardSmall";

import InviteTeamMemberButton from "./InviteTeamMemberButton";
import InviteTeamMemberDialog from "./InviteTeamMemberDialog";
import { getAccountActions, updateUser } from "./utils";
import styles from "./styles";

const { iconClass, ...outlineButtonStyles } = getOutlineButtonClasses(
  "#2A2B2C",
  "small"
);
const OutlineButton = withStyles(outlineButtonStyles)(Button);

class TeamManagement extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    createFlashNote: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    profile: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
    team: PropTypes.array.isRequired,
    updateParent: PropTypes.bool,
    updateUser: PropTypes.func.isRequired
  };

  static defaultProps = {
    team: [],
    updateParent: true,
    updateUser
  };

  constructor(props) {
    super(props);

    this.state = {
      team: props.team || [],
      invite: false
    };
  }

  deleteAccount = index => () => {
    const team = this.state.team.filter((_, idx) => idx !== index);

    this.setState({ team });
  };

  getUserData = member => {
    const { error, pending, ...user } = member;

    return user;
  };

  handleChange = () => {
    if (!this.props.updateParent) return;

    const team = this.state.team.map(this.getUserData);

    this.props.onChange(team);
  };

  updateTeamMember = index => data => {
    const user = this.getUserData(this.state.team[index]);

    // set pending state
    this._updateTeamMember(index, { pending: true });

    const url = `/api/managers/${user.id}/`;
    this.props
      .updateUser(url, data)
      .then(json => {
        const { error } = json;

        if (error) {
          this.props.createFlashNote(error.message, "error");
          this._updateTeamMember(index, { error, pending: false });
        } else {
          this._updateTeamMember(index, { ...data, pending: false });
        }
      })
      .catch(error => {
        this.props.createFlashNote(error.message, "error");
        this._updateTeamMember(index, { error, pending: false });
      });
  };

  _updateTeamMember = (index, data = {}) => {
    const team = this.state.team.map((acc, idx) =>
      idx === index ? { ...acc, ...data } : acc
    );

    this.setState({ team }, this.handleChange);
  };

  renderInviteButton = () => {
    const { classes } = this.props;
    const { invite } = this.state;

    return (
      <div
        className={classes.divider}
        style={{
          paddingTop: 30
        }}
      >
        {invite && (
          <InviteTeamMemberDialog
            onCancel={() => this.setState({ invite: false })}
          />
        )}
        <InviteTeamMemberButton
          disabled={invite}
          onClick={() => this.setState({ invite: true })}
        />
      </div>
    );
  };

  renderAccountError = (error, index) => {
    const { classes } = this.props;

    if (!error) return null;

    return (
      <div className={classes.error}>
        <div className={classes.errorMessage}>{error.message}</div>
        <ClearIcon
          className={classes.clearError}
          onClick={() => this._updateTeamMember(index, { error: null })}
        />
      </div>
    );
  };

  renderCurrentUser = index => {
    const { classes } = this.props;
    const { team } = this.state;

    // if user was not found don't render profile and buttons
    if (index === -1) return null;

    return (
      <React.Fragment>
        <div
          className={classes.divider}
          style={{
            borderTop: 0
          }}
        >
          Your Details
        </div>
        <div>{this.renderTeamMember(team[index], index, [], true)}</div>
      </React.Fragment>
    );
  };

  renderTeamMember = (account, index, team, currentUser = false) => {
    const { classes, profile } = this.props;
    const { error } = account;

    if (account.username === profile.username && !currentUser) return null;

    const accountActions =
      index > -1
        ? getAccountActions(
            account,
            this.updateTeamMember(index),
            this.state,
            currentUser
          )
        : [];

    // show card with dropdown edit menu
    const subMenu = error ? null : accountActions.length ? (
      <SubMenuButton actions={accountActions} ButtonComponent={OutlineButton}>
        Manage user <ChevronDown className={classes.icon} />
      </SubMenuButton>
    ) : null;

    const style = account.archived ? { opacity: 0.5 } : {};

    return (
      <div className={classes.cardContainer} key={index} style={style}>
        {this.renderAccountError(error, index)}

        <ContactCardSmall
          classes={{ bio: classes.contactBio }}
          {...account}
          controls={subMenu}
          editView={true}
          showEmail={true}
          showSocial={true}
        />
      </div>
    );
  };

  render() {
    const { classes, profile } = this.props;
    const { team } = this.state;

    const currentUserIndex = team.findIndex(
      account => account.username === profile.username
    );

    return (
      <div className={classes.root}>
        {this.renderCurrentUser(currentUserIndex)}

        {team.length > 1 ? (
          <div className={classes.divider}>Team Members</div>
        ) : null}
        <div>{team.map(this.renderTeamMember)}</div>

        {this.renderInviteButton()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createFlashNote: (msg, type) => {
      dispatch(createFlashNote(msg, type));
    }
  };
};

export { TeamManagement, styles };
export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(TeamManagement));
