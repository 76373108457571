import React from "react";
import { PropTypes } from "prop-types";

import ApplicationsManageView from "./ApplicationsManageView";

class ApplicationsManageViewContainer extends React.Component {
  static propTypes = {
    doctype: PropTypes.oneOf(["program", "challenge"]).isRequired,
    loadApplications: PropTypes.func.isRequired,
    loadDocument: PropTypes.func.isRequired,
    applications: PropTypes.array.isRequired,

    match: PropTypes.shape({
      params: PropTypes.shape({ documentId: PropTypes.string.isRequired })
        .isRequired
    }).isRequired
  };

  state = {
    error: null,
    loading: false,
    document: {},
    documentId: null
  };

  componentDidMount() {
    this.parseLocation(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.parseLocation(nextProps);
  }

  parseLocation = props => {
    const { documentId } = props.match.params;

    if (documentId !== this.state.documentId) {
      this.load(documentId);
    }
  };

  load = documentId => {
    if (this.state.loading) return;
    if (this.state.documentId === documentId) return;

    this.setState({ loading: true }, () => this._load(documentId));
  };

  _load = documentId => {
    this.props
      .loadDocument(documentId)
      .then(document => {
        const { error } = document;

        if (error) {
          this.setState({ error, documentId, loading: false });
        } else {
          this.setState({ document, documentId, loading: false });
        }
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });

    this.props.loadApplications(documentId);
  };

  render() {
    const { loadApplications, loadDocument, ...otherProps } = this.props;

    return (
      <ApplicationsManageView
        {...otherProps}
        {...this.state}
        loading={Boolean(this.state.loading)}
      />
    );
  }
}

export default ApplicationsManageViewContainer;
