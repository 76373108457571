import React from "react";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    width: 24,
    height: 24,
    verticalAlign: "middle"
  },

  pulseIcon: {
    width: 12,
    height: 12,
    marginLeft: -6,
    marginTop: -6,

    backgroundColor: "rgba(156, 181, 158, 1)",
    boxShadow: "0 0 0 0 rgba(156, 181, 158, 0.5)",

    color: "white",
    position: "relative",
    top: "50%",
    left: "50%",
    display: "block",
    textAlign: "center",
    border: "none",
    borderRadius: "50%",
    //cursor: "pointer",

    animation: "pulse 2.5s infinite",
    "&:hover": {
      animation: "none"
    }
  },

  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.7)"
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 12px rgba(156, 181, 158, 0)"
    },
    "100%": {
      transform: "scale(0.7)",
      boxShadow: "0 0 0 0 rgba(156, 181, 158, 0)"
    }
  }
};

const getDimensionsAsStyle = size => ({
  width: size / 2,
  height: size / 2,
  marginLeft: -1 * (size / 4),
  marginTop: -1 * (size / 4)
});

const getColorKeyframes = (color, size) => {
  const { red, green, blue } = color;

  return {
    backgroundColor: `rgba(${red}, ${green}, ${blue}, 1)`,
    boxShadow: `0 0 0 0 rgba(${red}, ${green}, ${blue}, 0.7)`,
    "@keyframes pulse": {
      "0%": {
        transform: "scale(0.7)"
      },
      "70%": {
        transform: "scale(1)",
        boxShadow: `0 0 0 ${size}px rgba(${red}, ${green}, ${blue}, 0)`
      },
      "100%": {
        transform: "scale(0.7)",
        boxShadow: `0 0 0 0 rgba(${red}, ${green}, ${blue}, 0)`
      }
    }
  };
};

const hex2rgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
      }
    : { red: 156, green: 181, blue: 158 };
};

const PulseIcon = props => {
  const { classes, color, width, height, pulse, ...other } = props;

  const size = width < height ? width : height;

  // define state as style overrides
  const animation = pulse ? {} : { animation: "none" };
  const dimensions = getDimensionsAsStyle(size);
  const keyframes = getColorKeyframes(hex2rgb(color), size);

  const style = {
    ...animation,
    ...dimensions,
    ...keyframes
  };

  return (
    <div className={classes.root} style={{ width, height }} {...other}>
      <span className={classes.pulseIcon} style={style} />
    </div>
  );
};

PulseIcon.defaultProps = {
  color: "#85C97C",
  pulse: true,
  width: 24,
  height: 24
};
export default withStyles(styles)(PulseIcon);
