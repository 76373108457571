import React from "react";
import { PropTypes } from "prop-types";

import Banner from "../common/Banner";

const ApplicationBanner = props => {
  const { document, loading } = props;

  const title = loading
    ? "loading ..."
    : document.doctype === "program"
    ? "Apply"
    : "Register your interest";

  return (
    <Banner document={document} title={title} logo={document.logo_image_url} />
  );
};

ApplicationBanner.propTypes = {
  document: PropTypes.shape({
    doctype: PropTypes.oneOf(["program", "challenge"]).isRequired
  }).isRequired,
  loading: PropTypes.bool
};

export default ApplicationBanner;
