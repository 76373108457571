import { connect } from "react-redux";

import MyApplicationsListView from "../Document/DocumentsListView/MyApplicationsListView";

// import MyApplicationsList from "./MyApplicationsList";

const _addCountryData = (obj, countries) => {
  if (!obj || !obj.country) return obj;

  const country = countries.find(country => country.id === obj.country);
  if (country) {
    return { ...obj, country };
  }

  return obj;
};

const addCountryData = (applications, countries) => {
  return applications.map(application => {
    const program = _addCountryData(application.program, countries);
    const think_tank = _addCountryData(application.think_tank, countries);

    return { ...application, program, think_tank };
  });
};

const mapStateToProps = state => ({
  applications: addCountryData(state.myApplications, state.countries),
  documents: addCountryData(state.myApplications, state.countries)
});

export default connect(mapStateToProps)(MyApplicationsListView);
