import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Icons from "../../Icons";

const styles = {
  root: {
    height: 20,
    padding: 2
  },
  disabled: {
    fill: "#AAADB6",
    fontSize: 20,
    marginLeft: 10
  },
  icon: {
    fill: "#2A2B2C",
    fontSize: 20,
    cursor: "pointer",
    marginLeft: 10
  }
};

const deleteItem = (items, index) => {
  const before = items.slice(0, index);
  const after = items.slice(index + 1);

  return [...before, ...after];
};

const moveDown = (items, index) => {
  if (index === items.length - 1) return;

  const before = items.slice(0, index);
  const after = items.slice(index + 2);

  return [...before, items[index + 1], items[index], ...after];
};

const moveUp = (items, index) => {
  if (index === 0) return;

  const before = items.slice(0, index - 1);
  const after = items.slice(index + 1);

  return [...before, items[index], items[index - 1], ...after];
};

const Controls = props => {
  const { classes, index, items, updateItems, style } = props;

  const _deleteItem = () => updateItems(deleteItem(items, index));
  const _moveDown =
    index !== items.length - 1
      ? () => updateItems(moveDown(items, index))
      : null;
  const _moveUp = index !== 0 ? () => updateItems(moveUp(items, index)) : null;

  return (
    <div className={classes.root} style={style}>
      <Icons.ArrowUp
        className={_moveUp ? classes.icon : classes.disabled}
        onClick={_moveUp}
      />
      <Icons.ArrowDown
        className={_moveDown ? classes.icon : classes.disabled}
        onClick={_moveDown}
      />
      <Icons.TrashCanOutline className={classes.icon} onClick={_deleteItem} />
    </div>
  );
};

Controls.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired
};

Controls.defaultProps = {
  style: {}
};

export { Controls, styles };
export default withStyles(styles)(Controls);
