import React from "react";
import { PropTypes } from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Button from "../common/Buttons/Button";
import Icons from "../Icons";

import {
  buttonOutlineLargeClasses,
  buttonPrimaryLargeClasses
} from "../../styles/buttonStyles";
import { colorize } from "../../styles/utils";

import { GREEN } from "../../styles/colors";

const styles = {
  root: {
    //borderTop: "1px solid lightgrey",
    padding: 30,
    display: "flex",
    alignItems: "center"
  }
};

const nextClasses = {
  ...buttonPrimaryLargeClasses,
  iconClass: {
    ...buttonPrimaryLargeClasses.iconClass,
    marginRight: 0,
    marginLeft: 6
  }
};
const NextButton = withStyles(nextClasses)(Button);
const PreviousButton = withStyles(buttonOutlineLargeClasses)(Button);

const publishButtonStyles = colorize(buttonPrimaryLargeClasses, GREEN);
const PublishButton = withStyles(publishButtonStyles)(Button);

const renderNextButton = props => {
  const { next, nextDisabled, nextIcon, nextLabel } = props;

  return (
    <NextButton
      onClick={next}
      disabled={nextDisabled}
      label={nextLabel}
      IconRight={nextIcon}
    />
  );
};

const renderPreviousButton = props => {
  const { previous, previousIcon, previousLabel } = props;

  return (
    <PreviousButton
      onClick={previous}
      label={previousLabel}
      IconLeft={previousIcon}
    />
  );
};

const renderPublishButton = props => {
  const {
    isComplete,
    publish,
    publishDisabled,
    publishIcon,
    publishLabel
  } = props;

  // don't render button is form is incomplete
  // if disabled is set render disabled button as hint for user
  if (!isComplete && !publishDisabled) return null;

  return (
    <PublishButton
      disabled={publishDisabled}
      onClick={publish}
      label={publishLabel}
      IconLeft={publishIcon}
    />
  );
};

const FormBuilderFormButtons = props => {
  const { classes, next, previous, publish } = props;

  return (
    <div className={classes.root}>
      {previous && renderPreviousButton(props)}
      {next && renderNextButton(props)}
      <div style={{ flexGrow: 1 }} />
      {publish && renderPublishButton(props)}
    </div>
  );
};

FormBuilderFormButtons.propTypes = {
  classes: PropTypes.object.isRequired,

  isComplete: PropTypes.bool,

  next: PropTypes.func,
  nextDisabled: PropTypes.bool,
  nextIcon: PropTypes.any,
  nextLabel: PropTypes.string,

  previous: PropTypes.func,
  previousIcon: PropTypes.any,
  previousLabel: PropTypes.string,

  publish: PropTypes.func,
  publishDisabled: PropTypes.bool,
  publishIcon: PropTypes.any,
  publishLabel: PropTypes.string
};

FormBuilderFormButtons.defaultProps = {
  classes: PropTypes.object.isRequired,

  nextDisabled: false,
  nextIcon: Icons.ArrowRight,
  nextLabel: "Next",

  previousIcon: Icons.ArrowLeft,
  previousLabel: "Previous",

  publishIcon: Icons.Upload,
  publishLabel: "Publish"
};

export { FormBuilderFormButtons, styles };
export default withStyles(styles)(FormBuilderFormButtons);
