const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
    maxWidth: 660
  },
  buttonDelete: {
    flexShrink: 0
  },
  helperText: {
    fontSize: 13,
    lineHeight: "16px",
    color: "grey"
  }
};

export default styles;
