const SET_SCROLLVIEW_STATE = "SET_SCROLLVIEW_STATE";

const _setScrollviewState = data => {
  return {
    type: SET_SCROLLVIEW_STATE,
    payload: data
  };
};

export const setScrollviewState = (key, value) => {
  return dispatch => {
    dispatch(_setScrollviewState({ [key]: value }));
  };
};

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SCROLLVIEW_STATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
